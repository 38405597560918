import { Box, Card, CardContent, AccordionDetails, AccordionSummary, Accordion } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { MarkPublicDialog } from '_core/components/dialogs/UpdateRelationshipPrivacy'

type PrivateIdentifierItemProps = {
  identifiers: {
    main: string
    other: string[]
  }
  handleOpenDialog: () => void
}

const useStyles = makeStyles()((theme) => ({
  card: {
    textAlign: 'center',
    background: theme.palette.background.light,
    position: 'relative',
    borderRadius: 10
  },
  cardSecondary: {
    '& .MuiCardContent-root:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  cardContent: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(1)}`
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  token: {
    letterSpacing: '0.25px',
    minHeight: theme.spacing(3),
    maxWidth: `calc(100% - ${theme.spacing(5)})`,
    minWidth: theme.spacing(20)
  },
  cause: {
    lineHeight: theme.spacing(3),
    letterSpacing: '0.25px'
  },
  hidden: {
    display: 'none'
  },
  tokenText: {
    maxWidth: '75vw',
    lineHeight: '36px'
  },
  details: {
    padding: 0,
    justifyContent: 'center'
  },
  accordion: {
    '&:before': {
      height: 0
    }
  },
  summary: {
    display: 'grid!important',
    gridTemplateColumns: 'minmax(0, auto) 39px',
    padding: 0
  }
}))

const PrivateIdentifierItem = ({ identifiers, handleOpenDialog }: PrivateIdentifierItemProps) => {
  const { classes, cx } = useStyles()

  const token1 = identifiers?.main
  const token2 = identifiers?.other?.[0] || ''
  const token3 = identifiers?.other?.slice(1)

  return (
    <Card variant="outlined" classes={{ root: cx(classes.card, classes.cardSecondary) }}>
      <CardContent classes={{ root: classes.cardContent }}>
        <Box className={classes.content}>
          <Typography variant="h4" classes={{ root: classes.token }} style={{ maxWidth: '60%' }} noWrap>
            <Skeleton condition={!token1} width={200} height={26}>
              {token1}
            </Skeleton>
          </Typography>
          {token3?.length ? (
            <Accordion classes={{ root: classes.accordion }}>
              <AccordionSummary
                expandIcon={<IconButton icon={['far', 'chevron-down']} size="small" disablePY />}
                aria-label="Expand"
                aria-controls={`${token2}-content`}
                id={`${token2}-header`}
                classes={{ root: classes.summary }}
              >
                <Typography classes={{ root: classes.cause }} color="text.secondary" noWrap>
                  {token2}
                </Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.details }}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  {token3.map((item, i: number) => (
                    <Typography style={{ maxWidth: '75vw' }} className={classes.cause} key={i} noWrap>
                      {item}
                    </Typography>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          ) : (
            <Skeleton condition={!token2 && !token1} width={150} height={26}>
              <Typography variant="h4" classes={{ root: cx(classes.cause, classes.tokenText, { [classes.hidden]: !token2.length }) }} noWrap>
                {token2}
              </Typography>
            </Skeleton>
          )}
        </Box>
        <MarkPublicDialog.TriggerIcon
          disabled={!token1}
          disablePadding
          color="secondary"
          onClick={handleOpenDialog}
          hint={`Mark relationship with ${token1} as public`}
          size="small"
        />
      </CardContent>
    </Card>
  )
}

export default PrivateIdentifierItem
