const Paths = {
  login: '/aad-auth',
  dashboard: '/dashboard',
  companyProfile: '/companies/:id',
  personProfile: '/people/:id',
  userProfile: '/user/:id',
  dataSources: '/data-sources',
  protected: '/protected',

  // newui
  _people: '/people',
  _companies: '/companies',
  _relationships: '/relationships',
  _manualEdits: '/manualedits',
  _merge: '/merge',
  _salesforce: '/salesforce',
  _settings: '/settings',
  _adminSettings: '/admin-settings',
  _search: '/search',
  _logout: '/logout',
  _logoutPage: '/logout-page',
  _analyzeLeads: '/leads',
  _messages: '/messages',
  _home: '/',
  _apps: '/apps',
  _events: '/events',
  _teams: '/teams',
  _teamProfile: '/teams/:id',
  _welcome: '/welcome',
  _dataAdmin: '/data-administration',
  _deals: '/deals',
  _activities: '/activities',
  _introductions: '/introductions',
  _tags: '/tags',
  _meetings: '/meetings'
} as const

export const DataAdminSubPaths = {
  dataUploads: `${Paths._dataAdmin}/data-uploads`,
  privilegedCompanies: `${Paths._dataAdmin}/privileged-companies`,
  sync: `${Paths._dataAdmin}/sync`,
  dataQuality: `${Paths._dataAdmin}/data-quality`
} as const

export default Paths
