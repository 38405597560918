import React, { ComponentProps } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar from '_shared/Avatar'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import Widget from '_core/components/widgets'

const TeamsWidget = (props: { items: ComponentProps<typeof Avatar>[]; loading: boolean; count?: number }) => (
  <Widget>
    <Heading underlined title="Teams" count={props.count} icon={['fas', 'users-class']} />
    <Repeater
      direction="horizontal"
      component={Avatar}
      empty={<Empty subTitle="No teams" icon={<FontAwesomeIcon size="3x" icon={['fat', 'users-class']} style={{ color: '#A7A7A7' }} />} />}
      skeleton={{ size: 3, loading: props.loading }}
      items={props.items}
    />
  </Widget>
)

export default TeamsWidget
