import React, { ComponentProps, PropsWithChildren, ReactNode } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { AvatarWrapper } from '_shared/Avatar'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { AddTagTriggerEl, ExtraTagsPopover, InternalTagPopover, ShowAllTagsLink } from '_core/components/InternalTag'
import { useWide, WideStrict, NarrowStrict, Middle } from '_core/components/layout'
import SidepanelLink from '_core/components/SidepanelLink'
import TagsGroup from '_core/components/TagsGroup'

import { groupTags } from '_core/hooks/useTagsManager'

const useStyles = makeStyles<{ flip?: boolean } | void>()((theme, props) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: props?.flip ? 'row-reverse' : 'row',
    margin: '0px auto',
    maxWidth: '90%',
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('phone')]: {
      maxWidth: '80%'
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
      justifyContent: 'flex-start',
      marginTop: 0,
      position: 'relative',
      minWidth: '32%',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '0.5px',
        height: '100%',
        background: '#ECEEF0',
        left: props?.flip ? 'inherit' : 100,
        right: props?.flip ? 100 : 'inherit'
      }
    }
  },
  actionsWrapper: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexGrow: 1,
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      position: 'unset'
    }
  },
  container: {
    marginLeft: props?.flip ? 0 : theme.spacing(3),
    marginRight: props?.flip ? theme.spacing(3) : 0,
    maxWidth: `calc(100% - 80px - ${theme.spacing(3)})`,
    '& > p': {
      paddingTop: '4px'
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: props?.flip ? 0 : theme.spacing(5)
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
      justifyContent: 'start'
    }
  },
  action: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.up('phone')]: {
      marginRight: theme.spacing(4)
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(3)
    },
    '&:last-of-type': {
      marginRight: 0
    }
  },
  avatarWrapper: {
    width: '80px',
    display: 'flex',
    justifyContent: props?.flip ? 'flex-end' : 'flex-start',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: '100px',
      marginTop: 0
    }
  },
  name: {
    fontSize: 18,
    marginBottom: 0,
    wordBreak: 'break-word'
  },
  nameWrapper: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto auto 1fr'
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '80%',
    margin: '16px auto 0',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
      marginTop: 0,
      position: 'absolute',
      right: props?.flip ? 'auto' : -8,
      left: props?.flip ? -8 : 'auto',
      bottom: -12
    },
    [theme.breakpoints.up('lg')]: {
      bottom: 0
    },
    '& :not(:last-child) .MuiButton-root': {
      [theme.breakpoints.up('tablet')]: {
        marginRight: theme.spacing(1)
      }
    }
  }
}))

const Actions = ({ actions, children }: { actions: JSX.Element[]; children?: ReactNode }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.actions}>
      {actions.map((action: any, index: number) => (
        <Box className={classes.action} key={index}>
          {action}
        </Box>
      ))}
      {children}
    </Box>
  )
}

const ProfileSummaryLayout = ({
  children,
  buttons,
  actions,
  statusIcons,
  flip
}: PropsWithChildren<Pick<ProfileSummaryProps, 'buttons' | 'actions' | 'statusIcons' | 'flip'>>) => {
  const { classes } = useStyles({ flip })

  return (
    <>
      <Box className={classes.wrapper}>
        {children}
        <WideStrict>
          <Box className={classes.actionsWrapper}>
            {statusIcons}
            {buttons && <Box className={classes.buttons}>{buttons}</Box>}
          </Box>
        </WideStrict>
        <Middle>
          <Box className={classes.actionsWrapper}>
            {statusIcons}
            {buttons && <Box className={classes.buttons}>{buttons}</Box>}
          </Box>
        </Middle>
      </Box>
      <NarrowStrict>
        <Box className={classes.actionsWrapper}>{statusIcons}</Box>
        {buttons && (
          <Actions actions={actions}>
            <Box>{buttons}</Box>
          </Actions>
        )}
      </NarrowStrict>
    </>
  )
}

export const ProfileContainer = (
  props: { children: ReactNode; className: string } & Pick<
    ProfileSummaryProps,
    'title' | 'loading' | 'budge' | 'editTagsLink' | 'showAllTagsLink' | 'profileLink' | 'score' | 'userKey' | 'url' | 'tags' | 'flip'
  >
) => {
  const wide = useWide()
  const { flip, title, loading, budge, editTagsLink, showAllTagsLink, profileLink, score, userKey, url, children, className } = props
  const { classes } = useStyles({ flip })

  const Tags =
    editTagsLink && showAllTagsLink ? (
      <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
        items={props.tags}
        tagComponent={InternalTagPopover}
        renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
          <ExtraTagsPopover
            triggerElement={<ShowAllTagsLink extraTagsAmount={extraTagsAmount} link={showAllTagsLink} />}
            items={props.tags ? groupTags(props.tags) : props.tags}
          />
        )}
        addTagTriggerEl={<AddTagTriggerEl link={editTagsLink} hasAny={!!props.tags?.length} />}
      />
    ) : null

  return (
    <>
      <Box className={classes.avatarWrapper}>
        <SidepanelLink linkProps={{ to: title && profileLink ? profileLink : '' }}>
          <AvatarWrapper name={loading ? '' : title} score={score} userKey={userKey} size="lg" mode="dark" logoUrl={url} hideName />
        </SidepanelLink>
      </Box>

      <Box className={className}>
        <Box className={classes.nameWrapper}>
          {(loading || title) && (
            <Skeleton condition={!title} width="100%" height={30}>
              <Tooltip title={title} placement="bottom-start" disabled={wide}>
                <Typography variant="h4" className={classes.name} noWrap semiBold>
                  {title || 'Placeholder Name'}
                </Typography>
              </Tooltip>
            </Skeleton>
          )}
          {!loading && budge}
          {!loading && editTagsLink && showAllTagsLink && (
            <>
              <WideStrict>
                <Box ml={1.5}>{Tags}</Box>
              </WideStrict>
              <Middle>
                <Box ml={1.5}>{Tags}</Box>
              </Middle>
            </>
          )}
        </Box>
        {!loading && editTagsLink && showAllTagsLink && (
          <NarrowStrict>
            <Box mt={1}>{Tags}</Box>
          </NarrowStrict>
        )}
        {children}
      </Box>
    </>
  )
}

const DefaultProfileSummary = ({
  title,
  score,
  userKey,
  url,
  budge,
  loading,
  editTagsLink,
  showAllTagsLink,
  profileLink,
  tags,
  byline,
  byline2,
  buttons,
  actions,
  flip,
  statusIcons
}: ProfileSummaryProps) => {
  const { classes } = useStyles({ flip })

  return (
    <ProfileSummaryLayout buttons={buttons} actions={actions} flip={!!flip} statusIcons={statusIcons}>
      <ProfileContainer
        className={classes.container}
        title={title}
        loading={loading}
        budge={budge}
        editTagsLink={editTagsLink}
        showAllTagsLink={showAllTagsLink}
        profileLink={profileLink}
        score={score}
        userKey={userKey}
        url={url}
        tags={tags}
        flip={flip}
      >
        {byline}
        {byline2}
        <Middle>
          <Actions actions={actions} />
        </Middle>
        <WideStrict>
          <Actions actions={actions} />
        </WideStrict>
      </ProfileContainer>
    </ProfileSummaryLayout>
  )
}

export default DefaultProfileSummary
