import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import HelpLinkButton from '_core/components/HelpLinkButton'
import { Column, Columns, useWide } from '_core/components/layout'
import MarketDataExternalTags from '_core/components/MarketDataExternalTags'
import Line, { LinkLine } from '_core/components/ProfileLine'
import Widget, { SeeAllAction } from '_core/components/widgets'
import useWidgetStyles from '_core/components/widgets/styles'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

const PersonMarketDataWidget = (
  props: { loading: boolean; name: string; md5: string; marketData: boolean } & Partial<
    Pick<
      PersonMarketDataType,
      | 'locationName'
      | 'industry'
      | 'jobCompanyIndustry'
      | 'skills'
      | 'interests'
      | 'experience'
      | 'education'
      | 'workEmail'
      | 'id'
      | 'jobCompanyWebsite'
      | 'jobCompanyName'
      | 'jobTitle'
    >
  >
) => {
  const { classes } = useWidgetStyles()

  const {
    md5,
    locationName,
    industry,
    jobCompanyIndustry,
    skills,
    interests,
    experience,
    education,
    workEmail,
    id,
    jobTitle,
    jobCompanyName,
    jobCompanyWebsite
  } = props

  const noData = props.marketData && !id
  const noWidgetData = id && !locationName && (!jobCompanyIndustry || !industry) && !skills?.length && !interests?.length
  const notEnoughData = noWidgetData && !experience?.length && !education?.length && !workEmail
  const matchPhoneWidth = useWide('phone')

  return (
    <Widget className={classes.widget}>
      <Heading
        underlined
        title="Market data"
        icon={['fas', 'chart-column']}
        action={
          <>
            <Box flex={1}>
              <HelpLinkButton
                hint="Learn more about market data"
                href="https://help.dotalign.com/article/x8f8c6vw1i-dot-align-market-data"
                size="small"
                disablePY
              />
            </Box>
            <SeeAllAction sidepanel link={!noData && !notEnoughData ? `${Paths._people}/${props.md5}/market-data` : ''} />
          </>
        }
      />
      {(noData || notEnoughData || (noWidgetData && !notEnoughData)) && (
        <Empty
          className={classes.empty}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'chart-column']} style={{ color: '#A7A7A7' }} />}
          subTitle={
            <>
              {noData && 'No details are available in market data'}
              {notEnoughData && 'Not enough details are available in market data'}
              {noWidgetData && !notEnoughData && 'No skill, interest, location or industry info is available, click "see all" for other market data'}
            </>
          }
        />
      )}
      <Box mt={-1}>
        <Columns spacing={0} columnSpacing={2}>
          <Column xs={matchPhoneWidth ? 6 : 12} md={12}>
            <Line icon={['fas', 'user-tie']} loading={!props.marketData} value={jobTitle} />
          </Column>
          <Column xs={matchPhoneWidth ? 6 : 12} md={12}>
            <LinkLine
              icon={['fas', 'building']}
              loading={!props.marketData}
              value={jobCompanyName}
              sidepanel
              to={mergeUrlWithParams(`${Paths._people}/${md5}/market-data/company/${jobCompanyName}`, {
                website: jobCompanyWebsite
              })}
            />
          </Column>
          <Column xs={matchPhoneWidth ? 6 : 12} md={12}>
            <Line icon={['fas', 'location-dot']} loading={!props.marketData} value={locationName} />
          </Column>
          <Column xs={matchPhoneWidth ? 6 : 12} md={12}>
            <Line icon={['fas', 'industry']} loading={!props.marketData} value={jobCompanyIndustry || industry} />
          </Column>
        </Columns>

        {(!props.marketData || !!skills?.length) && (
          <MarketDataExternalTags marketData={props.marketData} name={props.name} items={skills} title="Skills" />
        )}
        {(!props.marketData || !!interests?.length) && (
          <MarketDataExternalTags marketData={props.marketData} name={props.name} items={interests} title="Interests" />
        )}
      </Box>
    </Widget>
  )
}

export default PersonMarketDataWidget
