import React, { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import TupleSourcesList from '_core/components/audit/TupleSourcesList'
import Topbar from '_core/components/Topbar'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const TupleSourcesPage = () => {
  const { tuple1, tuple2 } = useParams<TuplesSourcesPageParams>()
  const {
    teamContextValue: { teamNumber }
  } = useContext(TeamContext)
  const { setMobileHeader, setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Tuple sources')
  }, [setMobileHeader])

  useEffect(() => {
    setSubHeader(`${decodeURIComponent(tuple1)} associated with ${decodeURIComponent(tuple2)}`)
    return () => setSubHeader('')
  }, [setSubHeader, tuple1, tuple2])

  return (
    <>
      <Topbar nativeBack />
      <DynamicEntity
        component={TupleSourcesList}
        url={`/dataquality/companytuplesources/${tuple1}/to/${tuple2}?teamNumber=${teamNumber}`}
        pageSize={10}
        keepMounted
        list
        infinite
        id="company_tuple_sources_list"
      />
    </>
  )
}

export default TupleSourcesPage
