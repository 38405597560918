import { Box } from '@mui/material'

import { Step } from '_core/context/Onboarding'

import Typography from '_shared/Typography'

export const expandSidebarOnboardingTarget = 'expand-sidebar-onboarding'

const Content = (
  <Box maxWidth="125px">
    <Typography>Click here to expand the profile to full view.</Typography>
  </Box>
)

const steps: Step[] = [
  {
    disableBeacon: true,
    title: 'Expand sidepanel',
    content: Content,
    target: `.${expandSidebarOnboardingTarget}`
  }
]

export default steps
