import { Box } from '@mui/material'

import ResetDialog from '_core/components/dialogs/Reset'

import useAuditEntities from '_core/hooks/useAuditEntities'
import useDialog from '_core/hooks/useDialog'

const AuditTopBarActions = (props: { reset: () => void }) => {
  const { isDialogOpened, openDialog, closeDialog } = useDialog(false)
  const { isDirty } = useAuditEntities()

  const undoChanges = () => {
    props.reset()
    closeDialog()
  }

  return (
    <>
      <Box display="flex" alignItems="center" margin="-10px 0px">
        <ResetDialog.TriggerEl open={openDialog} disabled={!isDirty} />
      </Box>
      <ResetDialog isOpened={isDialogOpened} close={closeDialog} undo={undoChanges} />
    </>
  )
}

export default AuditTopBarActions
