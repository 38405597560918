import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, Box, List, ListItem, MenuItem, Select } from '@mui/material'
import * as _ from 'lodash'
import { useSnackbar } from 'notistack'
import { makeStyles } from 'tss-react/mui'

import { IconButton, Button } from '_shared/buttons'
import FloatingButton from '_shared/buttons/FloatingButton'
import Dialog, { DialogActions, DialogTitle, DialogContent } from '_shared/Dialog'
import Combobox from '_shared/forms/Combobox'
import Typography from '_shared/Typography'

import { Narrow, Wide } from '_core/components/layout'
import MemberOption from '_core/components/MemberOption'
import { addMembersOnboardingTarget } from '_core/components/onboarding/TeamProfile'
import ProfileItem, { useStyles as useProfileStyles } from '_core/components/ProfileItem'
import { SummaryContent } from '_core/components/SummaryItem'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { ActiveDirLookup, useLookUpActiveDirectoryMembers } from '_core/hooks/useLookup'
import useTeam from '_core/hooks/useTeam'

const useStyles = makeStyles()((theme) => ({
  list: {
    width: '100%',
    maxHeight: '400px',
    overflowY: 'auto',
    '& > div:last-of-type li': {
      borderBottom: 'none'
    }
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'flex-start',
    borderBottom: `1px ${theme.palette.text.secondary} solid`
  },
  groupIcon: {
    fontSize: theme.spacing(2),
    width: 46,
    height: 46,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    border: `1px solid ${theme.palette.text.secondary}`,
    color: theme.palette.text.secondary
  },
  wrapper: {
    marginLeft: theme.spacing(2)
  },
  addButton: {
    marginLeft: theme.spacing(2)
  },
  fab: {
    right: theme.spacing(2),
    bottom: theme.spacing(2)
  },
  outlined: {
    padding: '10px 12px'
  },
  profileItem: {
    padding: 0
  }
}))

export type OptionType = {
  label: string
  value: string
  type: 'user' | 'group' | 'contact' | 'account'
  description?: string
  groupId?: string
  id?: string
  isGroup?: boolean
  url?: string
}

const resultsToOptions = (resp: ActiveDirLookup): OptionType[] => {
  const result = resp.users
    .map(
      (user) =>
        ({
          value: user.emailAddress,
          label: user.displayName,
          type: 'user'
        }) as OptionType
    )
    .concat(
      resp.groups.map(
        (group) =>
          ({
            value: group.displayName,
            label: group.displayName,
            type: 'group',
            description: group.members.map((m) => m.displayName).join(', '),
            isGroup: true,
            groupId: group.id,
            count: group.members.length
          }) as OptionType
      )
    )

  return result
}

const TriggerEl = ({ open, disabled }: { open: () => void; disabled: boolean }) => {
  const { classes, cx } = useStyles()

  return (
    <>
      <Wide>
        <Button
          onClick={open}
          variant="outlined"
          color="primary"
          disabled={disabled}
          className={cx(classes.addButton, addMembersOnboardingTarget)}
          startIcon={<FontAwesomeIcon icon={['far', 'user-plus']} style={{ fontSize: 14 }} />}
        >
          Add members
        </Button>
      </Wide>
      <Narrow>
        <FloatingButton onClick={open} className={cx(classes.fab, addMembersOnboardingTarget)}>
          <FontAwesomeIcon icon={['far', 'user-plus']} size="lg" />
        </FloatingButton>
      </Narrow>
    </>
  )
}

export type AddTeamMemberDialogProps = {
  admin?: boolean
  team: Team
  reload: () => void
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  isOpened: boolean
  close: () => void
}

const AddTeamMemberDialog = (props: AddTeamMemberDialogProps) => {
  const { team, reload, loading, setLoading, isOpened, close } = props

  const [roles, setRoles] = useState<any>()
  const [roleList, setRoleList] = useState<any[]>([])
  const [appAccessList, setAppAccessList] = useState<any[]>([])

  const { enqueueSnackbar } = useSnackbar()
  const { addTeamMembers, getRenamedRoles, renameContributorRole } = useTeam()
  const { classes, cx } = useStyles()
  const { classes: profileClasses } = useProfileStyles()

  const { lookUpActiveDirectoryMembers, forceAbort } = useLookUpActiveDirectoryMembers()

  const {
    inputValue,
    value: membersList = [],
    open,
    options,
    optionsLoading,
    handleClose,
    handleOpen,
    handleFocus,
    handleInputChange,
    handleValueChange,
    updateValue: updateMembersList,
    filterOptions
  } = useAsyncCombobox<OptionType, true>({
    loadOptions: useCallback(
      async (searchTerm, membersList) => {
        const result = await lookUpActiveDirectoryMembers(
          searchTerm,
          membersList?.map(({ value }) => value)
        )
        if (result) {
          return resultsToOptions(result)
        }
      },
      [lookUpActiveDirectoryMembers]
    ),
    forceAbort
  })

  const defaultRole = 'Contributor'

  useEffect(() => {
    if (isOpened) {
      updateMembersList([])
      getRenamedRoles()
        .then(setRoles)
        .catch((error) => console.log(error))
    }
  }, [isOpened])

  const findContributorRoleToRename = (value: string) => {
    const role = (roleList.find((r) => r.id === value) || { role: defaultRole }).role
    return renameContributorRole(role)
  }

  const getProhibitAppAccess = (value: string) => {
    const prohibitAppAccess = appAccessList.find((p) => p.id === value)?.prohibitAppAccess
    return typeof prohibitAppAccess === 'undefined' ? false : prohibitAppAccess
  }

  const closeHandler = () => {
    close()
    setLoading(false)
  }

  const addUsers = (accounts: { users: any[]; groups: any[] }) => {
    setLoading(true)
    addTeamMembers(team.id.toString(), accounts)
      .then(() => {
        enqueueSnackbar(`${accounts.users?.length ? `The specified people were added as members of "${team.name}".` : ''}
          ${accounts.groups?.length ? 'The group members will appear in the list after syncing is complete' : ''}`)
        close()
        reload()
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const submit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (membersList && membersList.length > 0) {
      addUsers({
        users: membersList
          .filter((m) => !m || m.type !== 'group')
          .map((m: any) => {
            const role = findContributorRoleToRename(m.value)
            return {
              emailAddress: m.value,
              role: role,
              ...(role === 'Member' && { prohibitAppAccess: getProhibitAppAccess(m.value) })
            }
          }),
        groups: membersList
          .filter((m) => m && m.type === 'group')
          .map((m: any) => {
            const role = findContributorRoleToRename(m.value)
            return {
              credentialId: m.groupId,
              role: role,
              ...(role === 'Member' && { prohibitAppAccess: getProhibitAppAccess(m.value) })
            }
          })
      })
    }
    e.stopPropagation()
  }

  const handleDeleteClick = (member: OptionType) => {
    updateMembersList(_.without(membersList, member))
  }

  const handleAccess = (row: OptionType, allowAccess: boolean) => {
    setAppAccessList([
      ..._.without(
        appAccessList,
        appAccessList.find((r) => r.id === row.value)
      ),
      { id: row.value, prohibitAppAccess: !allowAccess }
    ])
  }

  const handleRole = (row: OptionType, role: any) => {
    setRoleList([
      ..._.without(
        roleList,
        roleList.find((r) => r.id === row.value)
      ),
      { id: row.value, role }
    ])
  }

  return (
    <Dialog loading={loading} open={isOpened} onClose={closeHandler} title={<DialogTitle title="Add users and groups" />} maxWidth={500}>
      <DialogContent>
        <Combobox<OptionType, true>
          multiple
          autoFocus
          open={open}
          inputValue={inputValue}
          loading={optionsLoading}
          options={options}
          value={membersList || []}
          placeholder="Search by name or email address"
          onChange={handleValueChange}
          onInputChange={handleInputChange}
          onClose={handleClose}
          onOpen={handleOpen}
          onFocus={handleFocus}
          renderOption={MemberOption}
          filterOptions={filterOptions}
        />

        <List className={classes.list} style={membersList && membersList.length ? { padding: '8px 0 0 0' } : { padding: 0 }}>
          {membersList?.map((member: any) => {
            const currentRole = roleList.find((r) => r.id === member.value)?.role
            return (
              <Box key={member.label}>
                <ListItem disableGutters component="li" className={classes.listItem}>
                  <Box display="flex" alignItems="center" justifyContent="space-between" width={1}>
                    {member.isGroup ? (
                      <Box className={cx(profileClasses.item, classes.profileItem)}>
                        <Box className={profileClasses.itemContent}>
                          <Box className={classes.groupIcon}>
                            <FontAwesomeIcon icon={['far', 'users']} title="Group" />
                          </Box>
                          <SummaryContent title={member.label} byline={member.value} className={profileClasses.wrapper} />
                        </Box>
                      </Box>
                    ) : (
                      <ProfileItem name={member.label} userKey={member.value} byline={member.value} className={classes.profileItem} />
                    )}
                    <Box display="flex" alignItems="center">
                      <Wide>
                        <Select
                          defaultValue={[defaultRole]}
                          variant="outlined"
                          classes={{ outlined: classes.outlined }}
                          onChange={(e) => handleRole(member, e.target.value)}
                          style={{ overflow: 'hidden', width: '160px', marginRight: 8, marginLeft: 8 }}
                        >
                          {roles.map((role: any) => (
                            <MenuItem key={role} value={role}>
                              {role}
                            </MenuItem>
                          ))}
                        </Select>
                      </Wide>
                      <IconButton
                        hint="Remove"
                        icon={['fas', 'times']}
                        style={{ fontSize: 14 }}
                        onClick={() => handleDeleteClick(member)}
                        size="small"
                      />
                    </Box>
                  </Box>
                  <Narrow>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" maxWidth={{ sm: 200 }} mt={1.5}>
                      <Select
                        defaultValue={[defaultRole]}
                        onChange={(e) => handleRole(member, e.target.value)}
                        style={{ overflow: 'hidden', width: '100%' }}
                        variant="outlined"
                        classes={{ outlined: classes.outlined }}
                      >
                        {roles.map((role: any) => (
                          <MenuItem key={role} value={role}>
                            {role}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Narrow>
                  {currentRole !== 'Curator' && currentRole !== 'Reader' && (
                    <Box display="flex" alignItems="center" mt={1}>
                      <Checkbox
                        style={{ padding: 0, marginRight: 8 }}
                        defaultChecked={true}
                        onChange={(e) => handleAccess(member, e.target.checked)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <Typography variant="body1">Allow access to app</Typography>
                    </Box>
                  )}
                </ListItem>
              </Box>
            )
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeHandler} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={submit} disablePR disabled={!membersList || membersList.length === 0}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddTeamMemberDialog.TriggerEl = TriggerEl

export default AddTeamMemberDialog
