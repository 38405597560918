import React from 'react'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100%',
    boxSizing: 'border-box'
  },
  wrapper: {
    padding: 0,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2)
    }
  }
}))

const Page = ({ children, className, variant }: { children: React.ReactNode; className?: string; variant?: 'core' }) => {
  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(classes.root, className, {
        [classes.wrapper]: variant === 'core'
      })}
    >
      {children}
    </div>
  )
}

export default Page
