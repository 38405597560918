import { useEffect, useContext, useState, useRef, useMemo, ComponentProps } from 'react'

import { useForm } from 'react-hook-form'
import { useParams, useLocation, matchPath } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import { ActivityStatsPeriodMsg } from '_core/components/charts/ActivityStats'
import DownloadBulkDialog, { DownloadTemplateControl, templates } from '_core/components/dialogs/DownloadBulk'
import Filters, { getColleagueFlag } from '_core/components/filters/People'
import {
  companyColumn,
  jobTitleColumn,
  nameColumn,
  bestIntroducerColumn,
  scoreColumn,
  lastMeetingColumn,
  lastInboundColumn,
  lastOutboundColumn,
  nextFutureMeetingColumn,
  nameWithByLinesColumn,
  inboundCountColumn,
  outboundCountColumn,
  meetingsCountColumn
} from '_core/components/grid/columns'
import GridPageFrame from '_core/components/GridPageFrame'
import { Narrow, useWide, Wide } from '_core/components/layout'
import PeopleList, { PeopleListProps, Heading } from '_core/components/PeopleList'
import { sortMap } from '_core/components/sort'
import Topbar from '_core/components/Topbar'

import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useActivitiesStats, { ActivityStatsMonth } from '_core/hooks/useActivitiesStats'
import useActivityStatsPeriod from '_core/hooks/useActivityStatsPeriod'
import useAdmin from '_core/hooks/useAdmin'
import useContactActivitiesPayloads from '_core/hooks/useContactActivitiesPayloads'
import useDialog from '_core/hooks/useDialog'
import useDownloadBulk, { DownloadBulkType, DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useEnableDownloading from '_core/hooks/useEnableDownloading'
import useInteractionsPeriodEndpointParams from '_core/hooks/useInteractionsPeriodEndpointParams'
import usePeopleUserSettings from '_core/hooks/usePeopleUserSettings'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSortStatsEndpointParams from '_core/hooks/useSortStatsEndpointParams'
import useTagsEndpointParams from '_core/hooks/useTagsEndpointParams'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

import DynamicEntity from '_core/DynamicEntity'
import UserSettings from '_core/UserSettings'

import { getBinary, mergeUrlWithParams, put } from 'utils/httpUtils'
import { getUTC } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type DownLoadBulkInit = { compactTemplate: boolean; numberOfRows: number; startingRow: number }

const DownloadAllControl = ({
  total,
  loading,
  download
}: {
  total: number
  loading: boolean
  download: {
    fileName: DownloadBulkType['fileName']
    requestBinary: (params: DownloadBulkParams & { template: 'default' | 'upload' }) => Promise<Blob | undefined>
  }
}) => {
  const { openDialog, closeDialog, isDialogOpened } = useDialog(false)

  const {
    control,
    setValue: setTemplateValue,
    getValues: getTemplateValue
  } = useForm<{
    template: (typeof templates)[number]['value']
  }>({ mode: 'onChange', defaultValues: { template: 'default' } })

  const {
    register,
    getValues,
    setValue,
    watch,
    errors,
    handleSubmit: downloadSubmit
  } = useDownloadBulk({
    defaultValues: { count: undefined, rows: undefined },
    fileName: download.fileName,
    shouldUnregister: false,
    requestBinary: (params: DownloadBulkParams) => download.requestBinary({ ...params, template: getTemplateValue('template') })
  })

  const { count } = watch()

  const setInitial = ({ compactTemplate, numberOfRows, startingRow }: DownLoadBulkInit) => {
    setValue('count', numberOfRows || 500)
    setValue('rows', startingRow || 1)
    setTemplateValue('template', compactTemplate ? 'upload' : 'default')
  }

  const handleSubmit = async (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => {
    e?.preventDefault()
    closeDialog()
    await put<DownLoadBulkInit>('/usersettings/bulkdownload', {
      compactTemplate: getTemplateValue('template') === 'upload',
      numberOfRows: getValues('count'),
      startingRow: getValues('rows')
    })
    return downloadSubmit(e)
  }

  return (
    <UserSettings setInitial={setInitial} endpoint="/usersettings/bulkdownload">
      <DownloadBulkDialog.TriggerEl open={openDialog} disabled={loading || typeof count === 'undefined'} />
      <DownloadBulkDialog
        totalRows={total}
        register={register}
        getValues={getValues}
        errors={errors}
        handleSubmit={handleSubmit}
        isOpened={isDialogOpened}
        close={closeDialog}
        templateControl={<DownloadTemplateControl control={control} />}
      />
    </UserSettings>
  )
}

const People = ({
  download,
  ...props
}: Modify<PeopleListProps, { items: UserPeopleListItem[] }> & {
  months: ActivityStatsMonth[]
  download: {
    fileName: DownloadBulkType['fileName']
    requestBinary: (params: DownloadBulkParams & { template: 'default' | 'upload' }) => Promise<Blob | undefined>
  }
}) => {
  const { id } = useParams<{ id: string }>()
  const { setSubHeader } = useContext(LayoutContext)
  const { pathname } = useLocation()

  const upSmall = useWide('sm')
  const wideStrict = useWide('lg')

  const { isExact: matchActivityStatsPath } = matchPath(pathname, `${Paths._people}/:id/activityStats`) || {}

  const md5s = useMemo(() => props.items.map(({ PersonMd5 }) => PersonMd5), [props.loading, props.items.length])

  const statsPayloads = useContactActivitiesPayloads(md5s, id)
  const { stats } = useActivitiesStats(md5s ? statsPayloads : null, md5s, props.months)

  const items = useMemo(
    () =>
      props.items?.map((person, idx) => {
        const workExperience = {
          companyIdentity: person.BestJobCompanyMd5 || '',
          companyName: person.BestJobMatchedCompanyName || person.BestJobCorpLevelCompanyName || '',
          jobTitle: person.BestJobTitleText || '',
          currentAsOf: person.BestJobCurrentAsOf || ''
        }

        const formerJob = !!person.BestJobIsFormer
        const id = person.MyUserKeyMd5 || person.PersonMd5 || person.BestEmailAddrText?.replaceAll("'", '%27')
        const lastInbound = person.Stats?.LastInboundMsg || ''
        const lastOutbound = person.Stats?.LastOutboundMsg || ''
        const lastMeeting = person.Stats?.LastMeeting || ''
        const nextFutureMeeting = person.Stats?.NextFutureMeeting || ''

        const { inbound, outbound, meetings } = stats?.[idx] || {}

        const isActivityStatsVisible = !!props.columns.find(({ field }) => field === 'inboundCount')
        const isStatsDatesVisible = !!props.columns.find(({ field }) => field === 'lastInboundMsg')

        return {
          id,
          name: person.PersonNameText || person.BestEmailAddrText,
          byline: person.BestJobTitleText || '',
          byline2: person.BestJobMatchedCompanyName || person.BestJobCorpLevelCompanyName || '',
          title: person.BestJobTitleText || '',
          score: person.Score,
          link: `${Paths._relationships}/${person.UserKeyMd5}/people/${person.PersonMd5}`,
          email: person.BestEmailAddrText,
          company: {
            name: person.BestJobMatchedCompanyName || person.BestJobCorpLevelCompanyName || '',
            link: person.BestJobCompanyMd5 ? `${Paths._companies}/${person.BestJobCompanyMd5}` : '',
            sidepanel: true as SidepanelType
          },
          bestIntroducer: {
            name: person.BestKnowerNameText || '',
            link: person.BestKnowerUserKeyMd5 ? `${Paths._relationships}/${person.BestKnowerUserKeyMd5}/people/${id}` : '',
            sidepanel: true as SidepanelType
          },
          formerJob,
          workExperience,
          sidepanel: true as SidepanelType,
          variant: 'expandable',
          privacy: person.Privacy,
          tags: person.Tags || [],
          ...(isActivityStatsVisible
            ? {
                inbound: stats ? inbound?.count || null : -1,
                outbound: stats ? outbound?.count || null : -1,
                meetings: stats ? meetings?.count || null : -1
              }
            : {}),
          ...(isStatsDatesVisible ? { lastInbound, lastOutbound, lastMeeting, nextFutureMeeting } : {})
        }
      }),
    [props.loading, props.items.length, stats]
  )

  useEffect(() => {
    setSubHeader(`People ${props.total ? `· ${props.total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, props.total])

  return (
    <PeopleList
      {...props}
      rowHeight={upSmall && !wideStrict ? 80 : 52}
      items={items}
      downloadAllControl={<DownloadAllControl total={props.total} loading={props.loading} download={download} />}
      enableMiddleView={matchActivityStatsPath}
    />
  )
}

type PpleListProps = {
  queryParams: Modify<ContributorsPeoplePageParams, { checked?: IncludePeopleType[] }>
  enableDownloading: PeopleListProps['enableDownloading']
} & Pick<ComponentProps<typeof DynamicEntity>, 'onLoading' | 'autoHideOnScroll' | 'infinite' | 'updatePageSize'> &
  Pick<ComponentProps<typeof People>, 'updateSort' | 'columns' | 'rowsPerPageOptions' | 'months'>

export const PpleList = (props: PpleListProps) => {
  const { id } = useParams<{ id: string }>()
  const { search } = useLocation()
  const { teamContextValue } = useContext(TeamContext)
  const [forceContentLoading, setForceContentLoading] = useState<boolean>(false)

  const { checked = [], sort, viewMode, rowsPerPage = '10', keyword, and, includeTags, excludeTags } = props.queryParams

  const colleagueFlag = getColleagueFlag(checked)
  const prevColleagueFlag = useRef<ColleagueFlag>()
  useEffect(() => {
    if (prevColleagueFlag.current === colleagueFlag) {
      setForceContentLoading(true)
      setTimeout(() => setForceContentLoading(false), 1000)
    }
    prevColleagueFlag.current = colleagueFlag
  }, [checked.length, colleagueFlag, setForceContentLoading])

  const interactionsPeriodParams = useInteractionsPeriodEndpointParams(props.queryParams)
  const sortStatsParams = useSortStatsEndpointParams(sort)
  const tagsParams = useTagsEndpointParams('people', includeTags, excludeTags)

  const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)

  const params: { name: string; value?: any }[] = [
    { name: 'TeamNumber', value: teamContextValue?.teamNumber },
    { name: 'ColleagueFlag', value: colleagueFlag },
    { name: 'WhereNoneNextFutureMeeting', value: !!and },
    { name: 'IncludeStats', value: true },
    { name: 'WithPersonTags', value: true },
    ...sortStatsParams,
    ...interactionsPeriodParams,
    ...tagsParams
  ]

  const payload = params
    .filter(({ value }) => !!value)
    .reduce((acc, { name, value }) => ({ ...acc, [name]: `${value}` }), {} as { [key: string]: string })

  const url = search ? mergeUrlWithParams(`/users/${id}/people`, payload) : null

  const download = {
    fileName: 'user_people',
    requestBinary: ({ template, ...downloadParams }: DownloadBulkParams & { template: 'default' | 'upload' }) => {
      return getBinary('/people/xl', {
        ...payload,
        ...downloadParams,
        relationOfUserKey: id,
        numIntroducers: '5',
        numEmails: '5',
        numPhones: '3',
        numJobs: '3',
        returnInUploaderTemplate: `${template === 'upload'}`
      })
    }
  }

  return (
    <DynamicEntity<{
      extraProps: {
        addprops: Pick<
          ComponentProps<typeof People>,
          | 'sort'
          | 'sortByField'
          | 'updateSort'
          | 'viewMode'
          | 'download'
          | 'rowsPerPageOptions'
          | 'months'
          | 'columns'
          | 'forceLoading'
          | 'enableDownloading'
        >
      }
    }>
      url={url}
      addprops={{
        forceLoading: forceContentLoading,
        sort,
        enableDownloading: props.enableDownloading,
        updateSort: props.updateSort,
        rowsPerPageOptions: props.rowsPerPageOptions,
        sortByField: sortByField || 'score',
        viewMode: viewMode || 'collapsed',
        columns: props.columns,
        months: props.months,
        download
      }}
      empty="No results found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      updatePageSize={props.updatePageSize}
      pageSize={+rowsPerPage}
      onLoading={props.onLoading}
      component={People}
      keepMounted
      autoHideOnScroll={props.autoHideOnScroll}
      list
      infinite={props.infinite}
      search={keyword}
      id="person_people"
    />
  )
}

const UserPeople = () => {
  const { id } = useParams<{ id: string }>()
  const { queryParams, updateQuery } = useSearchQuery<
    ContributorsPeoplePageParams & Pick<ActivityStatsPageParams, 'from' | 'to'>,
    { modifyProps: [{ checked: IncludePeopleType[] }] }
  >(['checked'])
  const wideStrict = useWide('lg')

  const [total, setTotal] = useState<number>()
  const [contentLoading, setContentLoading] = useState<boolean>(false)

  const { userActivitiesAccess } = useActivitiesAccess([id]) || {}
  const { isStatsWidgetVisible } = userActivitiesAccess || {}

  const { sort, viewMode, excludeEmpty, from, to } = queryParams

  const chartData = useActivityStatsPeriod({
    fromUTC: from ? getUTC(decodeURIComponent(from)) : null,
    toUTC: to ? getUTC(decodeURIComponent(to)) : null
  })

  const { dataVisibility } = useUserDataVisibility()
  const { allowFilteringByInteractions, preventNonAdminDownload } = dataVisibility || {}

  const admin = useAdmin()
  const enableDownloading = useEnableDownloading(admin, preventNonAdminDownload)

  const {
    setInitial,
    reset,
    interactionsFiltersShown,
    interactionsColumnsShown,
    handleChange,
    toggleInteractionSwitch,
    loading: filtersLoading,
    opened,
    toggleOpen: toggleFilterOpen
  } = usePeopleUserSettings({
    allowFilteringByInteractions,
    isContributor: true,
    showStatsColumns: isStatsWidgetVisible
  })

  const disabled = filtersLoading || interactionsFiltersShown === null

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    handleChange({ rowsPerPage })
  }

  const updateSort = (sort: ScoreType | StatSortType) => {
    handleChange({ sort })
  }

  const toggleExclude = () => {
    updateQuery({ excludeEmpty: excludeEmpty === 'true' ? 'false' : 'true' })
  }

  const updateViewMode = (viewMode: ViewModeType) => {
    handleChange({ viewMode })
  }

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    setContentLoading(loading)
    setTotal(result?.total_item_count)
  }

  const filtersProps = {
    isContributor: true,
    toggleOpen: toggleFilterOpen,
    interactionsFiltersShown: !!interactionsFiltersShown,
    disabled,
    opened,
    total,
    contentLoading,
    reset,
    queryData: queryParams,
    handleChange,
    toggleInteractionSwitch
  }

  const columns = useMemo(
    () =>
      [
        { column: wideStrict ? { ...nameColumn, minWidth: 200, width: 200 } : { ...nameWithByLinesColumn, minWidth: 281, width: 281 } },
        { column: jobTitleColumn, condition: wideStrict },
        { column: companyColumn, condition: wideStrict },
        { column: bestIntroducerColumn },
        { column: inboundCountColumn, condition: !!isStatsWidgetVisible },
        { column: outboundCountColumn, condition: !!isStatsWidgetVisible },
        { column: meetingsCountColumn, condition: !!isStatsWidgetVisible },
        { column: lastInboundColumn, condition: !!interactionsColumnsShown },
        { column: lastOutboundColumn, condition: !!interactionsColumnsShown },
        { column: lastMeetingColumn, condition: !!interactionsColumnsShown },
        { column: nextFutureMeetingColumn, condition: !!interactionsColumnsShown },
        { column: scoreColumn }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || !!condition)
        .map(({ column }) => column),
    [interactionsColumnsShown, isStatsWidgetVisible, wideStrict]
  )

  const sortItems = columns.filter(({ sortable }) => sortable).map(({ headerName, field }) => ({ label: headerName || '', field }))

  const searchPlaceholder = 'Search for people'
  return (
    <UserSettings endpoint="/usersettings/peoplesearchfilter" setInitial={setInitial}>
      <Narrow>
        <Topbar nativeBack autoHideOnScroll />
      </Narrow>
      <GridPageFrame
        loading={typeof total !== 'number'}
        gridTitle="People"
        gridHeadingIcon={['far', 'user']}
        searchPlaceholder={searchPlaceholder}
        disabledSearch={disabled}
        filters={<Filters {...filtersProps} />}
        heading={
          <Heading
            filters={<Filters {...filtersProps} />}
            filtersProps={{ opened: filtersProps.opened, toggleOpen: filtersProps.toggleOpen, disabled: filtersProps.disabled }}
            sortProps={{ sort, updateSort, items: sortItems, toggleExclude, excludeEmpty: excludeEmpty === 'true' }}
            viewProps={{ viewMode, updateViewMode }}
            searchPlaceholder={searchPlaceholder}
          >
            {viewMode === 'expanded' && isStatsWidgetVisible && <ActivityStatsPeriodMsg title={chartData.gridMessage} />}
          </Heading>
        }
        component={
          <>
            {isStatsWidgetVisible && (
              <Wide>
                <ActivityStatsPeriodMsg title={chartData.gridMessage} />
              </Wide>
            )}
            <PpleList
              queryParams={queryParams}
              enableDownloading={enableDownloading}
              columns={columns}
              onLoading={onLoading}
              updateSort={updateSort}
              updatePageSize={onPageSizeChange}
              months={chartData.months}
              autoHideOnScroll
              infinite
            />
          </>
        }
      />
    </UserSettings>
  )
}

export default UserPeople
