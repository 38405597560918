import { useMemo } from 'react'

import { IntroductionsSettings } from '_core/components/settings/admin'

import DynamicEntity from '_core/DynamicEntity'

const IntroductionsSettingsPage = () => {
  const introductionsUrls = useMemo(
    () => [
      { key: 'list', url: '/prospecting/managers?role=ProspectingManager' },
      { key: 'defaultManager', url: '/prospecting/defaultmanager?role=ProspectingManager' },
      { key: 'settings', url: '/adminsettings/introductions' },
      { key: 'profile', url: '/me/profile' }
    ],
    []
  )

  return <DynamicEntity urls={introductionsUrls} component={IntroductionsSettings} id="admin_introductions" />
}

export default IntroductionsSettingsPage
