import { Box, Divider } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import ExternalTag from '_core/components/ExternalTag'
import Heading from '_core/components/Heading'
import Line, { AnchorLinkLine, LinkLine } from '_core/components/ProfileLine'

import { addMissedProtocol } from '_core/helpers/string'

const useStyles = makeStyles()((theme) => ({
  chip: {
    maxWidth: '100%',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1)
  },
  iconButton: {
    paddingTop: theme.spacing(2)
  }
}))

const CompanyData = ({ data, profileLink }: { data: CompanyMarketDataType | null; profileLink?: string }) => {
  const { classes } = useStyles()
  const { id, name, location, founded, website, employeeCount, facebookUrl, twitterUrl, linkedinUrl, industry, type, tags, summary } = data || {}
  const showDivider = id && (!!tags?.length || summary)

  return (
    <>
      {profileLink ? (
        <LinkLine loading={!id} to={profileLink} value={name} icon={['fas', 'building']} hint="View profile data from DotAlign" />
      ) : (
        <Line loading={!id} value={name} icon={['fas', 'building']} />
      )}
      <AnchorLinkLine
        loading={!id}
        value={website}
        icon={['far', 'globe']}
        href={website ? addMissedProtocol(website) : ''}
        target="_blank"
        rel="noopener noreferrer"
      />
      <Line loading={!id} value={location?.name} icon={['fas', 'location-dot']} />
      <Line loading={!id} value={industry} icon={['fas', 'industry']} />
      <Line loading={!id} value={employeeCount ? `${employeeCount}+ employees` : ''} icon={['fas', 'users']} />
      <Line loading={!id} value={founded ? `Since ${founded}` : ''} icon={['fas', 'briefcase']} />
      <Line loading={!id} value={type} icon={['fas', 'handshake']} />

      <Box display="flex" justifyContent="center">
        {(!id || facebookUrl) && (
          <IconButton<'a'>
            component="a"
            className={classes.iconButton}
            icon={['fab', 'facebook']}
            color="primary"
            hint="Show company profile on Facebook"
            href={facebookUrl ? addMissedProtocol(facebookUrl) : ''}
            loading={!id}
            target="_blank"
            rel="noopener noreferrer"
            size="small"
          />
        )}
        {(!id || twitterUrl) && (
          <IconButton<'a'>
            component="a"
            className={classes.iconButton}
            icon={['fab', 'twitter']}
            color="primary"
            hint="Show company profile on Twitter"
            href={twitterUrl ? addMissedProtocol(twitterUrl) : ''}
            loading={!id}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
        {(!id || linkedinUrl) && (
          <IconButton<'a'>
            component="a"
            className={classes.iconButton}
            icon={['fab', 'linkedin']}
            color="primary"
            hint="Show company profile on LinkedIn"
            href={linkedinUrl ? addMissedProtocol(linkedinUrl) : ''}
            loading={!id}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
        {(!id || website) && (
          <IconButton<'a'>
            component="a"
            className={classes.iconButton}
            icon={['far', 'globe']}
            color="primary"
            hint="Show website"
            href={website ? addMissedProtocol(website) : ''}
            loading={!id}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
      </Box>
      {(!id || showDivider) && (
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
      )}
      {(!id || (id && !!tags?.length)) && (
        <Skeleton condition={!tags?.length} width={120} height={29}>
          <Box display="flex" alignItems="center" mt={showDivider ? 0 : 2}>
            <Heading icon={['fas', 'hashtag']} title="Tags" />
          </Box>
          {!!tags?.length && <Box>{tags?.map((tag: string) => <ExternalTag tagData={tag} className={classes.chip} key={tag} />)}</Box>}
        </Skeleton>
      )}
      {(!id || (id && summary)) && (
        <Skeleton condition={!summary} width={120}>
          <Box display="flex" alignItems="center" mt={2}>
            <Heading icon={['fas', 'hashtag']} title="Summary" />
          </Box>
          {summary && (
            <Box>
              <Typography>{summary}</Typography>
            </Box>
          )}
        </Skeleton>
      )}
    </>
  )
}

export default CompanyData
