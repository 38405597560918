import React, { isValidElement, ReactElement, ReactNode } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Alert } from '@mui/material'
import { DropzoneState } from 'react-dropzone'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Typography from '_shared/Typography'

import FormLayout, { FormLayoutActions, FormLayoutContent } from '_core/components/FormLayout'
import { FILE_UPLOADING_STATUS, UploadingProgressBar } from '_core/components/upload/UploadingProgressBar'

const useStyles = makeStyles()((theme) => ({
  centeredLine: {
    justifyContent: 'center',
    display: 'flex'
  },
  dropZone: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    backgroundImage:
      'linear-gradient(to right, #818181 50%, transparent 50%), linear-gradient(to right, #818181 50%, transparent 50%), linear-gradient(to bottom, #818181 50%, transparent 50%), linear-gradient(to bottom, #818181 50%, transparent 50%)',
    backgroundPosition: 'left top, left bottom, left top, right top',
    backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
    backgroundSize: '20px 1px, 20px 1px, 1px 20px, 1px 20px'
  },
  highlightedDropZone: {
    background: '#ECEFF0'
  },
  highlightedText: {
    fontWeight: 600,
    color: theme.palette.secondary.main,
    cursor: 'pointer'
  },
  heading: {
    textAlign: 'center',
    fontSize: '20px',
    lineHeight: '32px',
    marginTop: theme.spacing(1)
  }
}))

export const Dragzone = (props: {
  uploadStatus?: FILE_UPLOADING_STATUS
  dragZoneState: DropzoneState
  file: File | null
  error?: string
  addContent?: ReactNode
  accessibleFormats: string[]
}) => {
  const { classes } = useStyles()
  return (
    <Box>
      {props.uploadStatus !== FILE_UPLOADING_STATUS.FILE_HAD_UPLOADED && (
        <>
          <Box className={classes.centeredLine} mt="1px">
            <DragZone dragZoneState={props.dragZoneState} accessibleFormats={props.accessibleFormats} />
          </Box>

          {props.file && (
            <Box className={`${classes.centeredLine}`} mt={2}>
              <UploadingProgressBar fileName={props.file!.name} status={props.uploadStatus} errorMessage={props.error} />
            </Box>
          )}

          {isValidElement(props.addContent) && props.addContent}
        </>
      )}
    </Box>
  )
}

const UploadingContent = (props: {
  uploadStatus?: FILE_UPLOADING_STATUS
  handleUploadButton: () => void
  uploadButtonIsDisabled: boolean
  close: () => void
  alertTitle?: ReactNode
  children: ReactElement
}) => {
  const { children: dragZone } = props

  return (
    <FormLayout>
      <FormLayoutContent>
        {props.uploadButtonIsDisabled && !props.uploadStatus && isValidElement(props.alertTitle) && (
          <Alert style={{ marginBottom: 16 }} severity="info">
            {props.alertTitle}
          </Alert>
        )}

        {dragZone}
      </FormLayoutContent>
      <FormLayoutActions>
        <Button variant="text" color="secondary" onClick={props.close}>
          Cancel
        </Button>
        <Button variant="text" onClick={props.handleUploadButton} disabled={props.uploadButtonIsDisabled} disablePR>
          Upload
        </Button>
      </FormLayoutActions>
    </FormLayout>
  )
}

export default UploadingContent

const DragZone = (props: { dragZoneState: DropzoneState; accessibleFormats?: string[] }) => {
  const { classes, cx } = useStyles()

  return (
    <Box {...props.dragZoneState.getRootProps()} width="100%">
      <Box
        className={cx({
          [classes.dropZone]: true,
          [classes.highlightedDropZone]: props.dragZoneState.isDragActive
        })}
      >
        <Box padding={3}>
          <Box justifyContent="center" display="flex">
            <FontAwesomeIcon icon={['fas', 'cloud-arrow-up']} size="sm" color="#1B95BB" style={{ fontSize: '40px' }} />
          </Box>
          <Typography variant="h3" className={classes.heading}>
            Drag &amp; Drop
          </Typography>
          <Typography color="text.secondary" align="center">
            or <span className={classes.highlightedText}> browse </span> files with extension {props.accessibleFormats?.join(', ')}.
          </Typography>
        </Box>
      </Box>
      <input {...props.dragZoneState.getInputProps()} />
    </Box>
  )
}
