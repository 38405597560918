import { ComponentProps, useContext } from 'react'

import { useMsal } from '@azure/msal-react'
import { Box } from '@mui/material'
import { Route, Switch, useLocation, useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Event, { TimeMessage, UserEventActions, UserEventDetails } from '_core/components/Event'
import { InternalTagPopover, ShowAllTagsLink, AddTagTriggerEl, ExtraTagsPopover } from '_core/components/InternalTag'
import Repeater from '_core/components/lists/Repeater'
import NoAccess from '_core/components/MailboxNoAccess'
import ProfileItem from '_core/components/ProfileItem'
import TagsGroup from '_core/components/TagsGroup'
import Topbar from '_core/components/Topbar'

import useEventTime from '_core/hooks/useEventTime'
import { groupTags } from '_core/hooks/useTagsManager'

import DynamicEntity from '_core/DynamicEntity'
import { parseUrl, stringifyUrl } from '_core/helpers/browser'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

const baseURL = `${Paths._events}/:eventId`

const Component = (
  props:
    | ({ loading: true } & Partial<EventType>)
    | ({ loading: false } & Required<EventType> & {
          error?: { clientRequestId: string; code: string; errorMessage: string }
        })
) => {
  const { accounts } = useMsal()
  const loggedInUser = accounts[0]
  const { startsIn = -1 } = useEventTime({ startTimeUTC: props.startTimeUTC, endTimeUTC: props.endTimeUTC, isCancelled: props.isCancelled })

  if (!props.loading && props.error) return <NoAccess />

  const participantsWithPeople = props.loading
    ? []
    : props.participantsWithPeople.map((item) => ({
        ...item.person,
        PersonMd5: item.person?.PersonMd5 || '',
        PersonNameText: item.person?.PersonNameText || item.participant?.name || '',
        BestEmailAddrText: item.participant?.email.toLowerCase() || '',
        link: stringifyUrl(`${Paths._people}/${item.person?.PersonMd5 || item.participant?.email.toLowerCase()}`, {
          name: item.person?.PersonNameText || item.participant?.name,
          email: item.participant?.email.toLowerCase()
        })
      }))

  const companies = props.loading
    ? []
    : props.companies.map((item) => ({
        name: item.corpLevelCompanyName,
        logoUrl: item.matchedUrlText,
        link: `${Paths._companies}/${item.companyMd5}`
      }))

  const loggedInUserIndex = props.participantsWithPeople?.findIndex(
    ({ participant, person }) => participant?.email.toLowerCase() || '' === loggedInUser.username || person.UserInput === loggedInUser.username
  )

  const organizer = participantsWithPeople
    ?.filter((p) => p?.BestEmailAddrText === props.senderEmail || p?.UserInput === props.senderEmail)
    .map((p) => ({ name: p.PersonNameText, email: p.BestEmailAddrText, md5: p.PersonMd5 }))[0]
  const item = !props.loading && organizer ? { organizer, ...props } : undefined
  const isAppointment = participantsWithPeople?.length === 1 && organizer?.md5 === participantsWithPeople[0]?.PersonMd5
  const editStatusEnabled = startsIn > 0

  if (typeof loggedInUserIndex === 'number' && loggedInUserIndex > -1) {
    participantsWithPeople?.push(participantsWithPeople.splice(loggedInUserIndex, 1)[0])
  }

  const actionsEl = (
    <UserEventActions
      loading={props.loading}
      subject={props.subject}
      sourceKey={props.sourceKey}
      appointmentUID={props.appointmentUID}
      onlineMeetingJoinUrl={props.onlineMeetingJoinUrl}
      organizer={organizer}
      editStatusEnabled={editStatusEnabled}
      isAppointment={isAppointment}
      responseType={props.responseStatus?.responseType}
      item={item}
    />
  )

  const detailsEl = (
    <UserEventDetails
      body={props.body}
      loading={props.loading}
      organizer={organizer}
      sourceKey={props.sourceKey}
      webLink={props.webLink}
      subject={props.subject}
      location={props.location}
      onlineMeetingJoinUrl={props.onlineMeetingJoinUrl}
    />
  )

  return (
    <Switch>
      <Route path={`${baseURL}/participants`}>
        <Topbar nativeBack title={props.subject} sub={`Participants · ${participantsWithPeople.length || 0}`} />
        <Repeater
          direction="vertical"
          variant="list"
          component={ProfileItem}
          skeleton={{ size: 10, loading: props.loading }}
          items={
            props.participantsWithPeople?.map((item) => ({
              name: item.person?.PersonNameText || item.participant?.name || '',
              userKey: item.participant?.email.toLowerCase() || '',
              link: stringifyUrl(`${Paths._people}/${item.person?.PersonMd5 || item.participant?.email.toLowerCase()}`, {
                name: item.person?.PersonNameText || item.participant?.name,
                email: item.participant?.email.toLowerCase()
              }),
              byline: item.participant?.email.toLowerCase() || ''
            })) || []
          }
        />
      </Route>
      <Route path={`${baseURL}/companies`}>
        <Topbar nativeBack title={props.subject} sub={`Companies · ${props.companies?.length || '0'}`} />
        <Repeater
          direction="vertical"
          variant="list"
          component={ProfileItem}
          skeleton={{ size: 10, loading: props.loading }}
          items={companies?.map((item) => ({ ...item, byline: item.logoUrl })) || []}
        />
      </Route>

      <Route>
        {props.loading ? (
          <Event loading={true} actions={actionsEl} details={detailsEl} />
        ) : (
          <Event
            loading={false}
            subject={props.subject}
            startTimeUTC={props.startTimeUTC}
            endTimeUTC={props.endTimeUTC}
            companies={companies}
            participantsWithPeople={props.participantsWithPeople?.map((item) => ({
              name: item.person.PersonNameText || item.participant.name || '',
              userKey: item.participant?.email.toLowerCase() || '',
              link: stringifyUrl(`${Paths._people}/${item.person.PersonMd5 || item.participant.email.toLowerCase()}`, {
                name: item.person.PersonNameText || item.participant.name,
                email: item.participant.email.toLowerCase()
              })
            }))}
            actions={actionsEl}
            details={detailsEl}
            startsInMessage={<TimeMessage isCancelled={props.isCancelled} startTimeUTC={props.startTimeUTC} endTimeUTC={props.endTimeUTC} />}
            tags={
              props.tags.length ? (
                <Box mt={1} width={1}>
                  <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
                    items={props.tags}
                    tagComponent={InternalTagPopover}
                    renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
                      <ExtraTagsPopover
                        triggerElement={
                          <ShowAllTagsLink
                            sidepanel="preview"
                            extraTagsAmount={extraTagsAmount}
                            link={mergeUrlWithParams(`${Paths._events}/${props.sourceKey}/tags`, {
                              name: props.subject,
                              appointmentUID: props.appointmentUID
                            })}
                          />
                        }
                        items={groupTags(props.tags)}
                      />
                    )}
                    addTagTriggerEl={
                      <AddTagTriggerEl
                        sidepanel="preview"
                        link={mergeUrlWithParams(`${Paths._events}/${props.sourceKey}/tags/edit`, {
                          name: props.subject,
                          appointmentUID: props.appointmentUID
                        })}
                        hasAny={!!props.tags?.length}
                      />
                    }
                    max={3}
                  />
                </Box>
              ) : (
                <></>
              )
            }
          />
        )}
      </Route>
    </Switch>
  )
}

const EventPage = () => {
  const { teamContextValue } = useContext(TeamContext)
  const { eventId } = useParams<{ eventId: string }>()
  const { search } = useLocation()
  const { idType = 'GraphId' } = parseUrl(search) as { idType?: 'GraphId' | 'CalGlobalKey' }

  return (
    <DynamicEntity
      url={`/meetings/meetingswithparticipants/${eventId}?TeamNumber=${teamContextValue.teamNumber}&IdType=${idType}&WithTags=true`}
      component={Component}
      empty="No results found"
      emptySubtitle="No data"
      id="meeting"
    />
  )
}

export default EventPage
