import React, { HTMLAttributes, SyntheticEvent, useCallback, useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import { Button, IconButton } from '_shared/buttons'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import Combobox from '_shared/forms/Combobox'

import PickSuggestionsList from '_core/components/PickSuggestionsList'
import ProfileItem from '_core/components/ProfileItem'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useAsyncCombobox from '_core/hooks/useAsyncCombobox'

import { mergeUrlWithParams } from 'utils/httpUtils'

const useStyles = makeStyles()((theme) => ({
  pickerButton: {
    background: theme.palette.secondary.light
  }
}))

const useLookUpAuditIdentifiers = (endpoint: string) => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue

  const { fetchWithAbort, loading, result, setForceLoading, forceAbort, reset } = useAbortableFetch<{
    entities?: {
      name: AuditEntity['name']
      identifiers: Omit<AuditIdentifier, 'invalidReason'>[]
      entityInfo: { md5: string; personInfo?: never; companyInfo?: never }
    }[]
  }>()

  const lookUpIdentifiers = useCallback(
    async (searchTerm?: string, take?: number) => {
      const result = await fetchWithAbort({
        url: mergeUrlWithParams(endpoint, { teamNumber: `${teamNumber}`, searchWithin: searchTerm, take: `${take || 10}` })
      })
      return result?.entities ? result.entities[0].identifiers : undefined
    },
    [fetchWithAbort, teamNumber]
  )

  return {
    lookUpIdentifiers,
    loading,
    result,
    reset,
    setForceLoading,
    forceAbort
  }
}

const AuditIdentifierPickerDialog = ({
  title,
  isOpened,
  close,
  pickedId,
  lookUpEndpoint,
  onChange
}: {
  title: string
  isOpened: boolean
  close: () => void
  pickedId: string
  lookUpEndpoint: string
  onChange: (event: SyntheticEvent<Element, Event>, value: AuditIdentifier) => void
}) => {
  const { sourceId, targetId } = useParams<TuplesPageParams>()
  const { lookUpIdentifiers, forceAbort } = useLookUpAuditIdentifiers(lookUpEndpoint)
  const { lookUpIdentifiers: lookUpSuggestions, result: suggestionsResult, loading: suggestionsLoading } = useLookUpAuditIdentifiers(lookUpEndpoint)

  useEffect(() => {
    if (isOpened) {
      lookUpSuggestions('', 4)
    }
  }, [isOpened, lookUpSuggestions])

  const loadOptions = useCallback(
    async (searchTerm: string) => {
      const options = await lookUpIdentifiers(searchTerm)
      return options?.filter((identifier) => identifier.text !== pickedId)
    },
    [lookUpIdentifiers, pickedId]
  )

  const loadOnFocus = false

  const {
    inputValue,
    open,
    options,
    optionsLoading,
    handleClose: handleComboboxClose,
    handleOpen,
    handleFocus,
    handleInputChange
  } = useAsyncCombobox<AuditIdentifier>({
    loadOnFocus,
    loadOptions,
    forceAbort
  })

  const getOptionLabel = (option: AuditIdentifier) => option.distilledKeyText || option.text

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, identifier: AuditIdentifier) => {
    return (
      <li {...props}>
        <ProfileItem name={identifier.text} />
      </li>
    )
  }

  return (
    <Dialog open={isOpened} onClose={close} title={<DialogTitle title={title} />}>
      <DialogContent>
        <Combobox<AuditIdentifier, false, true>
          forcePopupIcon={loadOnFocus}
          placeholder="Search for identifier"
          icon={['far', 'search']}
          value={options?.find((el) => el.text === pickedId)}
          options={options}
          open={open}
          loading={optionsLoading}
          inputValue={inputValue}
          onChange={onChange}
          onInputChange={handleInputChange}
          onOpen={handleOpen}
          onClose={handleComboboxClose}
          onFocus={handleFocus}
          isOptionEqualToValue={(option, value) => option.text === value.text}
          getOptionLabel={getOptionLabel}
          disableClearable
          blurOnSelect
          renderOption={renderOption}
        />

        <PickSuggestionsList
          loading={suggestionsLoading}
          suggestions={
            suggestionsResult?.entities
              ? suggestionsResult.entities[0].identifiers
                  ?.filter((identifier) => identifier.text !== sourceId && identifier.text !== targetId)
                  ?.map((identifier) => ({
                    name: identifier.text,
                    userKey: identifier.text,
                    onClick: (e) => onChange(e, identifier)
                  }))
              : []
          }
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={close} disablePR>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const TriggerEl = (props: { open: () => void }) => {
  const { classes } = useStyles()
  return <IconButton onClick={props.open} color="primary" icon={['far', 'search']} size="small" classes={{ root: classes.pickerButton }} />
}

AuditIdentifierPickerDialog.TriggerEl = TriggerEl

export default AuditIdentifierPickerDialog
