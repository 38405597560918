import { PropsWithChildren } from 'react'

import useStickyStyles from './useStyles'

const Sticky = ({ children, sticky, className }: PropsWithChildren<{ sticky?: number; className?: string }>) => {
  const { classes, cx } = useStickyStyles({ sticky })

  return <div className={cx(classes.root, className)}>{children}</div>
}

export { useStickyStyles }
export default Sticky
