import { ComponentProps } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'

import Skeleton from '_shared/Skeleton'

import Heading from '_core/components/Heading'
import TitleDescription from '_core/components/TitleDescription'
import Widget from '_core/components/widgets'

import { getLocal } from 'utils/Utils'

const Connected = ({ loading, stats }: { loading: boolean; stats: Partial<StatsType<{ CompanyMd5: string; UserKeyMd5: string }>> }) => (
  <TitleDescription
    title="Connected"
    description={
      loading || (!loading && stats?.FirstInboundMsgContactName) ? (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={['far', 'user']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
          <Skeleton condition={loading} width={120}>
            {!loading && stats?.FirstInboundMsgContactName ? stats?.FirstInboundMsgContactName : <></>}
          </Skeleton>
        </span>
      ) : (
        <span>No connection found</span>
      )
    }
    blurb={
      <Skeleton condition={loading} width={140}>
        {!loading && stats?.FirstInboundMsg ? getLocal(stats.FirstInboundMsg).format('DD MMMM YYYY') : <></>}
      </Skeleton>
    }
  />
)

const Info = (props: { totalRelationships?: number; totalFormerAffiliations?: number; loading: boolean }) => (
  <TitleDescription
    title="Relationships"
    description={
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon icon={['fas', 'chart-network']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
        <Skeleton condition={!props.totalRelationships} width={160}>
          {props.totalRelationships ? <>{props.totalRelationships} relationships</> : <></>}
        </Skeleton>
      </span>
    }
    blurb={
      <Skeleton condition={props.loading} width={140}>
        {!props.loading ? `Including ${props.totalFormerAffiliations} former affiliation${props.totalFormerAffiliations !== 1 ? 's' : ''}` : ``}
      </Skeleton>
    }
  />
)

const QuickFactsWidget = ({
  loading,
  stats,
  totalRelationships,
  totalFormerAffiliations
}: { stats: Partial<StatsType<{ ContactMd5: string }>> } & ComponentProps<typeof Info>) => {
  return (
    <Widget scope="stack">
      <Heading icon={['far', 'bolt']} underlined title="Quick facts" />
      <Box py={1} mt={1}>
        <Connected loading={loading} stats={stats} />
      </Box>
      <Box py={1}>
        <Info totalRelationships={totalRelationships} totalFormerAffiliations={totalFormerAffiliations} loading={loading} />
      </Box>
    </Widget>
  )
}

export default QuickFactsWidget
