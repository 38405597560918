import { makeStyles } from 'tss-react/mui'

import { widgetSubTitle } from 'AppTheme'

const useStyles = makeStyles()((theme) => ({
  heading: widgetSubTitle(theme),
  widget: {
    [theme.breakpoints.up('md')]: {
      minHeight: 275
    }
  },
  empty: {
    '& > div:last-child': {
      '& .MuiTypography-root': {
        fontSize: '14px !important'
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5)
      }
    }
  }
}))

export default useStyles