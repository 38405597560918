import React, { useContext, useEffect, useMemo } from 'react'

import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Page from '_shared/Page'

import FormLayout, { FormLayoutActions, FormLayoutContent } from '_core/components/FormLayout'
import IntroductionDetails, { IntroductionProps } from '_core/components/introductions/IntroductionDetails'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import useSidepanelClose from '_core/hooks/useSidepanelClose'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const useStyles = makeStyles()(() => ({
  content: {
    paddingLeft: 0,
    paddingRight: 0
  }
}))

const IntroductionPage = () => {
  const { id, contactId } = useParams<{ id: string; contactId: string }>()
  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader('Introduction timeline')
    return () => setSubHeader('')
  }, [setSubHeader])

  const memoUrls = useMemo(
    () => [
      {
        key: 'introduction',
        url: `/prospecting/targetedcontacts?PlanUids=${id}&EntityIdentifiers=${contactId}&WithEvents=true&ClosedFilter=All`
      },
      {
        key: 'statuses',
        url: '/prospecting/introducerstatuses'
      }
    ],
    [id, contactId]
  )

  return (
    <Page>
      <Narrow>
        <DynamicEntity urls={memoUrls} component={Component} id="introd_details" keepMounted />
      </Narrow>
    </Page>
  )
}

const Component = (props: IntroductionProps) => {
  const handleClose = useSidepanelClose(Paths._introductions)
  const { classes } = useStyles()

  return (
    <FormLayout>
      <Topbar nativeBack />
      <FormLayoutContent className={classes.content}>
        <IntroductionDetails {...props} />
      </FormLayoutContent>
      <FormLayoutActions>
        <Button onClick={handleClose} variant="text" color="secondary" disablePR>
          Close
        </Button>
      </FormLayoutActions>
    </FormLayout>
  )
}

export default IntroductionPage
