import React, { ReactElement, ReactNode } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { makeStyles } from 'tss-react/mui'

import Icon from '_shared/Icon'
import Typography from '_shared/Typography'

import { ellipsis } from 'AppTheme'

export const useStyles = makeStyles<{ underlined?: HeadingType['underlined']; hasAction: boolean } | void>()((theme, props) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    '&:first-of-type': {
      marginTop: 0
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(1.5),
      borderBottom: props?.underlined ? '0.6px #ECEEF0 solid' : '',
      marginBottom: theme.spacing(2)
    }
  },
  mainWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '22px'
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'auto'
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 14,
    color: theme.palette.text.secondary,
    letterSpacing: '0.15px',
    minHeight: 24,
    margin: 0,
    padding: 0,
    '& > span': {
      display: 'inline-block',
      ...ellipsis,
      maxWidth: props?.hasAction ? '80%' : '100%'
    }
  },
  widget: {
    fontSize: theme.spacing(2),
    marginBottom: 0,
    ...ellipsis
  },
  main: {
    fontSize: 20,
    marginTop: 0,
    marginBottom: 0,
    alignSelf: 'center'
  },
  icon: {
    borderRadius: '100%',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1)
  },
  mainIcon: {
    width: '30px',
    height: '30px'
  },
  count: {
    color: theme.palette.text.secondary
  }
}))

type HeadingType = {
  title: string | ReactNode
  count?: number
  icon?: IconProp
  underlined?: boolean
  action?: ReactElement | ReactNode | null
  variant?: 'none' | 'widget' | 'main'
  className?: string
}

const Heading = ({ title, icon, count, variant = 'widget', underlined, action, className }: HeadingType) => {
  const { classes, cx } = useStyles({ underlined, hasAction: !!action })

  return (
    <div className={cx({ [classes.wrapper]: variant === 'widget', [classes.mainWrapper]: variant === 'main' }, className)}>
      <div className={classes.heading}>
        {icon && <Icon mr={1} icon={icon} contained className={cx({ [classes.mainIcon]: variant === 'main' })} />}
        <Typography variant="h4" className={variant === 'none' ? classes.title : classes[variant as keyof typeof classes]} semiBold>
          {title}{' '}
          {typeof count === 'number' && count > 0 && (
            <>
              · <span className={classes.count}>{count}</span>
            </>
          )}
        </Typography>
      </div>
      {action}
    </div>
  )
}

export default Heading
