import { useContext, useEffect } from 'react'

import Page from '_shared/Page'

import Heading from '_core/components/Heading'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import Topbar from '_core/components/Topbar'
import { AliasesWidget, DomainsWidget } from '_core/components/widgets/AliasesAndDomains'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const Aliases = (props: { companyName: string }) => {
  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader('Aliases and domains')
    return () => setSubHeader('')
  }, [setSubHeader])

  const wide = useWide()

  return (
    <Page variant="core">
      <Wide>
        <Heading title="Aliases and domains" icon={['far', 'fingerprint']} variant="main" />
      </Wide>
      <Narrow>
        <Topbar nativeBack />
      </Narrow>
      <Columns spacing={wide ? 1 : 0}>
        <Column two>
          <AliasesWidget companyName={props.companyName} />
        </Column>
        <Column two>
          <DomainsWidget companyName={props.companyName} />
        </Column>
      </Columns>
    </Page>
  )
}

export default Aliases
