import { ComponentProps } from 'react'

import Avatar from '_shared/Avatar'

import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import Widget, { SeeAllAction } from '_core/components/widgets'

const InteractionParticipants = ({
  total,
  link,
  items,
  loading
}: {
  total: number
  link: string
  items: ComponentProps<typeof Avatar>[]
  loading: boolean
}) => {
  return (
    <Widget>
      <Heading underlined title="Participants" count={total} icon={['far', 'user']} action={<SeeAllAction link={total ? link : ''} />} />
      <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 5, loading }} items={items} />
    </Widget>
  )
}

export default InteractionParticipants
