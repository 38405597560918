import React from 'react'

import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import { IconButton } from '_shared/buttons'
import Button from '_shared/buttons/Button'
import Skeleton from '_shared/Skeleton'

import useMarketDataIntegration from '_core/hooks/useMarketDataIntegration'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

const MarketDataSearchLink = ({
  entity,
  variant,
  ...props
}: {
  entity: SearchEntities
  variant?: 'icon'
} & Pick<SearchPageParams, 'peopleFilters' | 'companiesFilters' | 'keyword'>) => {
  const { enabled: isMarketDataEnabled } = useMarketDataIntegration(!!entity)
  const link = mergeUrlWithParams(Paths._search, {
    scope: 'MarketData',
    rowsPerPage: '10',
    viewMode: 'collapsed',
    selectedEntity: entity,
    keyword: props.keyword,
    peopleFilters: props.peopleFilters,
    companiesFilters: props.companiesFilters
  })

  if (isMarketDataEnabled === false) return null

  if (variant === 'icon') {
    return (
      <IconButton<typeof Link>
        icon={['far', 'search']}
        disabled={!isMarketDataEnabled}
        hint="Search external market data"
        component={Link}
        to={link}
        size="small"
      />
    )
  }

  return (
    <Box display="flex" alignSelf="center">
      <Skeleton condition={!isMarketDataEnabled}>
        <Button<typeof Link> variant="link" disablePadding component={Link} to={link}>
          search external market data
        </Button>
      </Skeleton>
    </Box>
  )
}

export default MarketDataSearchLink
