import React, { useContext, useMemo } from 'react'

import { Route, Switch } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import AddPrivilegedCompaniesPage from '_pages/data-administration/privileged-companies/add'
import PrivilegedContactsPage from '_pages/data-administration/privileged-companies/contacts'
import AddPrivilegedContactsPage from '_pages/data-administration/privileged-companies/contacts/add'
import PrivilegedMembersPage from '_pages/data-administration/privileged-companies/members'
import AddPrivilegedMembersPage from '_pages/data-administration/privileged-companies/members/add'

import { TeamContext } from '_core/context/TeamContext'

import { IMenuAction } from '_shared/buttons/FloatingMenuButton'
import Page from '_shared/Page'

import FeatureNoAccess from '_core/components/FeatureNoAccess'
import GridPageFrame from '_core/components/GridPageFrame'
import CompaniesList, { CompaniesProps } from '_core/components/PrivilegedCompaniesList'
import Heading, { Buttons, baseUrl } from '_core/components/PrivilegedHeading'

import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import usePrivilegedPage from '_core/hooks/usePrivilegedPage'

import DynamicEntity from '_core/DynamicEntity'

const useStyles = makeStyles()(() => ({
  widget: {
    minHeight: 'calc(100vh - 255px)'
  }
}))

const Action = () => {
  const action: IMenuAction = {
    label: 'Add companies',
    icon: ['far', 'user-plus'],
    link: `${baseUrl}/add`,
    sidepanel: true
  }

  return <Buttons action={action} />
}

const PrivilegedCompaniesPage = () => {
  const {
    teamContextValue: { teamNumber }
  } = useContext(TeamContext)
  const { onLoading, onPageSizeChange, reload, handleReload, loading, rowsPerPage, keyword } = usePrivilegedPage()
  const memoUrl = useMemo(() => `/companies/privcos?teamNumber=${teamNumber}`, [teamNumber])
  const { classes } = useStyles()

  const Component = (
    <DynamicEntity<{ extraProps: { addprops: Pick<CompaniesProps, 'handleReload' | 'teamNumber'> } }>
      url={!reload ? memoUrl : null}
      component={CompaniesList}
      addprops={{ handleReload, teamNumber }}
      onLoading={onLoading}
      pageSize={+rowsPerPage}
      updatePageSize={onPageSizeChange}
      empty="No companies found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      list
      infinite
      search
      keepMounted
      id="priv-companies"
    />
  )

  return (
    <GridPageFrame
      loading={loading}
      gridTitle="Companies"
      searchPlaceholder="Search for companies by website"
      filters={<></>}
      disabledSearch={loading}
      heading={<></>}
      headingAdornmentEnd={Action}
      component={Component}
      widgetClassName={classes.widget}
    />
  )
}

const PrivilegedCompaniesPages = () => {
  const { result } = useEntityEndpoint<{ results: boolean }>('/adminsettings/privcoactive')
  const hasAccess = result?.results

  if (hasAccess === false) {
    return <FeatureNoAccess />
  }

  return (
    <Page>
      <Heading loading={!hasAccess} />
      <Switch>
        <Route exact path={baseUrl} component={PrivilegedCompaniesPage} />
        <Route exact path={`${baseUrl}/add`} component={AddPrivilegedCompaniesPage} />
        <Route exact path={`${baseUrl}/members`} component={PrivilegedMembersPage} />
        <Route exact path={`${baseUrl}/members/add`} component={AddPrivilegedMembersPage} />
        <Route exact path={`${baseUrl}/contacts`} component={PrivilegedContactsPage} />
        <Route exact path={`${baseUrl}/contacts/add`} component={AddPrivilegedContactsPage} />
      </Switch>
    </Page>
  )
}

export default PrivilegedCompaniesPages
