import React, { ComponentProps, useContext, useEffect } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Divider } from '@mui/material'
import { matchPath, Route, Switch, useLocation, useParams } from 'react-router-dom'

import ActivitiesPage from '_pages/people/[id]/activities'
import PersonActivityStatisticsPage from '_pages/people/[id]/activityStats'
import AffiliationsPage, { AffiliationsList } from '_pages/people/[id]/affiliations'
import UserCompanies from '_pages/people/[id]/companies'
import ExternalKeysPage, { ExternalKeysList, ExternalKeysListPropsType } from '_pages/people/[id]/externalKeys'
import ContactInfo from '_pages/people/[id]/info'
import ContactInfoEmails from '_pages/people/[id]/info/emails'
import ContactInfoPhones from '_pages/people/[id]/info/phones'
import UserInteractions from '_pages/people/[id]/interactions'
import ContactIntroducers from '_pages/people/[id]/introducers'
import MarketDataPage from '_pages/people/[id]/market-data'
import CompanyMarketDataPage from '_pages/people/[id]/market-data/company'
import PersonMeetingsPage from '_pages/people/[id]/meetings'
import UserPeople from '_pages/people/[id]/people'
import Profile, { checkContributor, ProfileAddProps } from '_pages/people/[id]/profile'
import SimilarPeople from '_pages/people/[id]/similar'
import SimilarPersonPage from '_pages/people/[id]/similar/[id]'
import Touchpoints from '_pages/people/[id]/touchpoints'

import { TeamContext } from '_core/context/TeamContext'

import Card, { CardContent } from '_shared/Card'
import Page from '_shared/Page'
import Typography from '_shared/Typography'

import Dashboard, { DashboardLinkType } from '_core/components/Dashboard'
import Empty from '_core/components/Empty'
import { Wide } from '_core/components/layout'
import PersonMarketData from '_core/components/market-data/PersonMarketData'
import PersonHeader from '_core/components/PersonHeader'
import PrivateRoute from '_core/components/PrivateRoute'
import UnknownProfileSummary from '_core/components/UnknownProfileSummary'

import useAdmin from '_core/hooks/useAdmin'
import usePersonProfile from '_core/hooks/usePersonProfile'
import usePresetScroll from '_core/hooks/usePresetScroll'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const url = Paths._people

const PersonProfile = (props: ComponentProps<typeof Profile>) => {
  const { pathname } = useLocation()
  const { queryParams } = useSearchQuery<PersonProfilePageParams>()
  const { setMobileHeader } = useContext(LayoutContext)
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue

  const admin = useAdmin()

  const { isExact: matchProfile } = matchPath(pathname, { path: Paths.personProfile }) || {}

  const enableScroll =
    props.interactions !== undefined && props.isStatsWidgetVisible !== undefined && !props.loading && !!props.PersonMd5 && matchProfile

  usePresetScroll(enableScroll)

  const prohibitedMarketData = typeof props.enabledMarketData === 'boolean' && !props.enabledMarketData

  const contributor = checkContributor(
    props.loading,
    props.BestEmailAddrText,
    props.MyUserKeyMd5 || '',
    props.UserKey || '',
    !!props.hideColleagueRelationships
  )

  const personName = props.PersonNameText || props.BestEmailAddrText
  const md5 = props.MyUserKeyMd5 || props.PersonMd5
  const profileLink = `${Paths._people}/${md5}`

  const dashboardLinks: DashboardLinkType[] = [
    {
      name: 'Dashboard',
      link: profileLink,
      condition: Boolean(props.loading || props.MyUserKeyMd5 || props.PersonMd5),
      icon: ['far', 'th'] as IconProp
    },
    {
      name: 'Activity statistics',
      link: `${profileLink}/activityStats`,
      condition: props.isStatsWidgetVisible && !contributor,
      icon: ['far', 'chart-simple'] as IconProp
    },
    {
      name: 'Meetings',
      link: `${profileLink}/meetings`,
      condition: admin && props.isMeetingsTabVisible,
      icon: ['far', 'calendar-alt'] as IconProp
    },
    {
      name: 'Introducers',
      link: `${profileLink}/introducers`,
      condition: !contributor,
      icon: ['far', 'address-book'] as IconProp
    },
    {
      name: 'People',
      link: `${profileLink}/people`,
      condition: !!contributor,
      icon: ['far', 'user'] as IconProp
    },
    {
      name: 'Companies',
      link: `${profileLink}/companies`,
      condition: !!contributor,
      icon: ['far', 'building'] as IconProp
    },
    {
      name: 'Interactions',
      link: `${profileLink}/interactions`,
      condition: props.showInteractionsInProfile,
      icon: ['far', 'envelope'] as IconProp
    },
    {
      name: 'Touchpoints',
      link: `${profileLink}/touchpoints`,
      condition: Boolean(props.loading || props.PersonMd5),
      icon: ['far', 'handshake'] as IconProp
    },
    {
      name: 'Affiliations',
      link: `${profileLink}/affiliations`,
      condition: Boolean(props.loading || props.PersonMd5),
      icon: ['far', 'suitcase'] as IconProp
    },
    {
      name: 'Contact Information',
      link: `${profileLink}/info`,
      condition: Boolean(props.loading || props.PersonMd5),
      icon: ['far', 'address-card'] as IconProp
    },
    {
      name: 'External Keys',
      link: `${profileLink}/keys`,
      condition: !!props.CrmSourceKeys?.data?.length,
      icon: ['fas', 'share-alt'] as IconProp
    }
  ].filter(({ condition }) => condition)

  const alternateMobileHeader = matchPath(pathname, {
    path: [`${Paths.personProfile}/similar/:similarId`, `${Paths.personProfile}/market-data/company/:companyId`]
  })?.isExact

  React.useEffect(() => {
    if (!alternateMobileHeader) {
      setMobileHeader(!props.loading ? queryParams.name || personName : '', props.loading)
    }
  }, [personName, setMobileHeader, alternateMobileHeader, props.loading, queryParams.name])

  if (!props.loading && !props.PersonMd5) {
    return queryParams.name ? (
      <>
        {props.enabledMarketData && (queryParams.email || queryParams.company) ? (
          <PersonMarketData person={props.marketData} />
        ) : (
          <>
            <Card elevation={0} square>
              <CardContent>
                <UnknownProfileSummary title={queryParams.name} userKey={queryParams.email} loading={props.loading} />
              </CardContent>
            </Card>
            <Divider />
            <Empty title="Profile not found in DotAlign" />
          </>
        )}
      </>
    ) : (
      <Empty title="Profile not found" closeExtension />
    )
  }

  const interactionsHeight = dashboardLinks.length * 78

  const Header = <PersonHeader {...props} />

  return (
    <>
      <Switch>
        <Route exact path={`${url}/:id`}>
          {Header}
        </Route>

        <Route exact path={`${url}/:id/*`}>
          <Wide>{Header}</Wide>
        </Route>
      </Switch>

      <Dashboard loading={props.loading} links={dashboardLinks}>
        <Switch>
          <Route exact path={`${url}/:id`}>
            <Profile {...props} />
          </Route>

          <PrivateRoute
            hasAccess={props.isStatsWidgetVisible && props.isDetailedActivityFromStatsWidgetVisible}
            exact
            path={`${url}/:id/activities`}
            render={() => <ActivitiesPage personName={personName} />}
          />
          <PrivateRoute
            hasAccess={props.isStatsWidgetVisible}
            path={`${url}/:id/activityStats`}
            render={() => <PersonActivityStatisticsPage personName={personName} isContributor={contributor} />}
          />
          <PrivateRoute hasAccess={admin && props.isMeetingsTabVisible} path={`${url}/:id/meetings`} component={PersonMeetingsPage} />
          <Route exact path={`${url}/:id/companies`} component={UserCompanies} />
          <Route exact path={`${url}/:id/people`} component={UserPeople} />
          <Route exact path={`${url}/:id/introducers`} component={ContactIntroducers} />
          <Route exact path={`${url}/:id/info/phones`} render={() => <ContactInfoPhones name={personName} />} />
          <Route exact path={`${url}/:id/info/emails`} render={() => <ContactInfoEmails name={personName} />} />
          <Route exact path={`${url}/:id/info`} render={() => <ContactInfo name={personName} />} />
          <Route
            exact
            path={`${url}/:id/keys`}
            render={() => (
              <ExternalKeysPage>
                <DynamicEntity
                  url={md5 ? `/people/${md5}/externalkeys?TeamNumber=${teamNumber}` : ''}
                  component={ExternalKeysList}
                  list
                  infinite
                  forceNarrow
                  nativeBack
                  keepMounted
                  subHeader={(props: ExternalKeysListPropsType) => (
                    <Typography title={`External keys · ${props.total_item_count}`} variant="body1" style={{ maxWidth: '100%' }} noWrap>
                      External keys · {props.total_item_count}
                    </Typography>
                  )}
                  id="person_external_keys"
                />
              </ExternalKeysPage>
            )}
          />
          <Route
            exact
            path={`${url}/:id/affiliations`}
            render={() => (
              <AffiliationsPage>
                <DynamicEntity
                  url={md5 ? `/personjobs/${md5}?TeamNumber=${teamNumber}` : ''}
                  component={AffiliationsList}
                  list
                  infinite
                  forceNarrow
                  nativeBack
                  subHeader={(props: any) => (
                    <Typography title={`Affiliations · ${props.total_item_count}`} variant="body1" style={{ maxWidth: '100%' }} noWrap>
                      Affiliations · {props.total_item_count}
                    </Typography>
                  )}
                  id="person_affiliations"
                />
              </AffiliationsPage>
            )}
          />

          <Route
            exact
            path={`${url}/:id/touchpoints`}
            render={() => (
              <DynamicEntity
                url={md5 ? `/people/${md5}?teamNumber=${teamNumber}&includeStats=true` : ''}
                component={Touchpoints}
                nativeBack
                subHeader={() => (
                  <Typography title="Key touch points" variant="body1" style={{ maxWidth: '100%' }} noWrap>
                    Key touch points
                  </Typography>
                )}
                id="person_touchpoints"
              />
            )}
          />
          <PrivateRoute exact path={`${Paths._people}/:id/market-data`} hasAccess={!prohibitedMarketData}>
            <MarketDataPage marketData={props.marketData} />
          </PrivateRoute>
          <PrivateRoute exact path={`${url}/:id/similar`} hasAccess={!prohibitedMarketData}>
            <SimilarPeople similar={props.similar} getSimilar={props.getSimilar} />
          </PrivateRoute>
          <PrivateRoute exact path={`${url}/:id/similar/:similarId`} hasAccess={!prohibitedMarketData}>
            <SimilarPersonPage getPersonDetails={props.getPersonDetails} />
          </PrivateRoute>
          <PrivateRoute exact path={`${url}/:id/market-data/company/:companyId`} hasAccess={!prohibitedMarketData}>
            <CompanyMarketDataPage />
          </PrivateRoute>
          <PrivateRoute hasAccess={props.showInteractionsInProfile} exact path={`${url}/:id/interactions`}>
            <UserInteractions minHeight={interactionsHeight} PersonMd5={props.PersonMd5} />
          </PrivateRoute>
        </Switch>
      </Dashboard>
    </>
  )
}

const PersonPage = () => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { pathname } = useLocation()

  const { onProfileLoading, ...personProfileData } = usePersonProfile()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Page>
      <DynamicEntity<{ extraProps: { addprops: ProfileAddProps } }>
        url={`/people/${id}?teamNumber=${teamNumber}&includeTeamMembership=true&numCompanies=4&numUserContacts=4&numIntroducers=8&numAliases=5&numJobs=4&numPhones=2&numEmails=2&includeStats=true&sortBy=ScoreDesc&WithPersonTags=true&NumCrmSourceKeys=3`}
        component={PersonProfile}
        onLoading={onProfileLoading}
        keepMounted
        id="person_profile"
        addprops={personProfileData}
      />
    </Page>
  )
}

export default PersonPage
