import { useState, useCallback } from 'react'

type IdentifierSourceTypes = Record<AuditIdentifier['type'], AuditIdentifierSourceRequestType>

type IdentifierSourceTypeKey = keyof IdentifierSourceTypes

export type IdentifierSourceType = IdentifierSourceTypes[IdentifierSourceTypeKey]

const useIdentifiersSourceViewToggle = () => {
  const [identifierSources, setIdentifierSources] = useState<null | {
    text: AuditIdentifier['text']
    type: IdentifierSourceType
  }>(null)

  const openSources = useCallback((identifier: Pick<AuditIdentifier, 'text' | 'type'>) => {
    const typesMap: {
      [key in Extract<
        IdentifierSourceTypeKey,
        'PersonCompleteName' | 'PersonTwoPartName' | 'EmailAddress' | 'CompanyName' | 'DomainUrl'
      >]: IdentifierSourceType
    } = {
      PersonCompleteName: 'PersonName',
      PersonTwoPartName: 'PersonName',
      EmailAddress: 'PersonEmail',
      CompanyName: 'CompanyName',
      DomainUrl: 'CompanyUrl'
    }
    const type = typesMap[identifier.type as keyof typeof typesMap]
    if (type) {
      setIdentifierSources({ text: identifier.text, type })
    }
  }, [])

  const closeSources = () => {
    setIdentifierSources(null)
  }

  return {
    identifierSources,
    openSources,
    closeSources
  }
}

export default useIdentifiersSourceViewToggle
