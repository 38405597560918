import { useEffect, useRef, useState } from 'react'

import { getGraphPhoto } from 'utils/graphUtils'

const useAvatarImage = (name?: string, userKey?: string, logoUrl?: string) => {
  const [userImg, setUserImg] = useState('')
  const demo = window.localStorage.getItem('demo_enabled') === 'true'
  const logo = !demo && logoUrl ? `//dotaligncloudhub.azurewebsites.net/logos/${logoUrl}?v2` : ''

  const isMounted = useRef<boolean>()

  const setImg = (src: string) => {
    if (isMounted.current) {
      setUserImg(src)
    }
  }

  useEffect(() => {
    if (!demo && userKey && !logoUrl) {
      isMounted.current = true
      const getUserImage = async () => {
        try {
          const resp = await getGraphPhoto(userKey)
          setImg(URL.createObjectURL(resp))
        } catch {
          setImg('')
        }
      }
      getUserImage()

      return () => {
        setUserImg('')
        isMounted.current = false
      }
    }
  }, [demo, logoUrl, userKey])

  useEffect(() => {
    if (demo && name && !logoUrl) {
      isMounted.current = true
      setImg(`https://i.pravatar.cc/100?u=${name}`)
      return () => {
        setUserImg('')
        isMounted.current = false
      }
    }
  }, [demo, logoUrl, name])

  return logo || userImg
}

export default useAvatarImage
