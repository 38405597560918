import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/material'
import { GridSelectionModel, GridSortModel } from '@mui/x-data-grid-pro'

import Typography from '_shared/Typography'

import DeleteIntroductionRequestDialog from '_core/components/dialogs/DeleteIntroductionRequest'
import UndeleteIntroductionRequestDialog from '_core/components/dialogs/UndeleteIntroductionRequest'
import { DataGrid, GridPaginationType } from '_core/components/grid'
import GridHeadingButtonsContent, { GridHeadingButtonType } from '_core/components/GridHeadingButtons'
import {
  assigneeColumn,
  beneficiaryColumn,
  createdColumn,
  creatorColumn,
  lastUpdateColumn,
  outcomeColumn,
  requesterColumn,
  statusColumn,
  summaryColumn
} from '_core/components/introductions/columns'
import ExpandableDetails from '_core/components/introductions/ExpandableDetails'
import StatusChip, { defineStatus } from '_core/components/introductions/StatusChip'
import { Narrow, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import { getSortByField, getSortModels, updateSort } from '_core/components/sort/Introductions'
import SummaryItem from '_core/components/SummaryItem'

import useDialog from '_core/hooks/useDialog'
import useIntroductionReason from '_core/hooks/useIntroductionReason'
import { BeneficiaryType } from '_core/hooks/useIntroductionRequestForm'
import useProspectingManager from '_core/hooks/useProspectingManager'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { ellipsisText, formatDateTime } from 'utils/Utils'

import Paths from 'Paths'

type ListProps = {
  items: IntroductionRequestResp[]
  loading: any
  setReload: Dispatch<SetStateAction<boolean>>
  updateSort: (val: IntroductionsSortType) => void
} & GridPaginationType

export const GridHeadingButtons = () => {
  const isManager = useProspectingManager()

  const actions: GridHeadingButtonType[] = [
    {
      label: 'Create',
      icon: ['far', 'plus'],
      link: `${Paths._introductions}/create`
    },
    {
      label: 'Report',
      icon: ['far', 'message-exclamation'],
      link: `${Paths._introductions}/report`,
      condition: !!isManager
    }
  ]

  return <GridHeadingButtonsContent actions={actions.filter((action) => (typeof action.condition === 'boolean' ? action.condition : true))} />
}

const RequestsList = (props: ListProps) => {
  const { queryParams } = useSearchQuery<IntroductionsPageParams>()
  const { dialogContentProps: openedDialog, closeDialog, openDialog, openSuccess, successMode } = useDialog<'delete' | 'undelete' | null>(null)
  const [items, setItems] = useState<{ [key: string]: any }[]>()
  const [selected, setSelected] = useState<string[]>([])
  const { payloads } = useSidepanelPayloads()
  const { sort, mode, viewMode } = queryParams
  const getReason = useIntroductionReason(!mode)
  const isExpandedView = viewMode === 'expanded'

  useEffect(() => {
    if (!props.loading) {
      setItems(
        props.items?.map(({ plan, contacts, querierIsLtnManager, queriedByAppUserKey }) => {
          const beneficiaryType: BeneficiaryType = plan.beneficiaryCompanyName ? 'company' : 'person'
          const isCreator = plan.planUid && queriedByAppUserKey === plan.creatorDotAppUserKey
          return {
            id: plan.planUid,
            summary: plan.planSummary,
            description: plan.planDescription,
            reason: getReason(plan.reason),
            requestLink: `${Paths._introductions}/${plan.planUid}`,
            status: defineStatus(plan.planUid, !!plan.closedOut) || 'active',
            sidepanel: true,
            isRowSelectable: querierIsLtnManager || isCreator,
            outcome: {
              rating: plan.outcomeRating,
              usd: plan.outcomeUsd
            },
            requester: {
              name: plan.requesterName,
              email: plan.requesterEmail,
              link: `${Paths._people}/${plan.requesterMd5}`
            },
            creator: {
              name: plan.creatorDotAppUserKey === plan.requesterEmail ? plan.requesterName : plan.creatorDotAppUserKey,
              email: plan.creatorDotAppUserKey,
              link: `${Paths._people}/${plan.creatorDotAppUserKey}`
            },
            assignee: {
              name: plan.assignedToAppUserKey,
              email: plan.assignedToAppUserKey,
              link: `${Paths._people}/${plan.assignedToAppUserKey}`
            },
            beneficiary: {
              name: plan.beneficiaryCompanyName || plan.beneficiaryPersonName,
              url: plan.beneficiaryCompanyUrl || plan.beneficiaryPersonEmail,
              link: plan.beneficiaryCompanyName
                ? `${Paths._companies}/${plan.beneficiaryCompanyUrl}`
                : `${Paths._people}/${plan.beneficiaryPersonEmail}`
            },
            created: plan.created,
            closedOut: plan.closedOut,
            updated: plan.lastAlive,
            readyForReview: plan.readyForReview,
            isCreator,
            beneficiaryType,
            querierIsLtnManager,
            contacts
          }
        })
      )
    } else {
      setItems(undefined)
    }
  }, [props.loading])

  useEffect(() => {
    if (payloads && payloads.action === 'UPDATE_INTRODUCTION_DETAILS' && items?.length) {
      const { planUid, contactId, ...rest } = payloads.value
      const transformed: { [key: string]: any } = {}

      'payload' in rest
        ? rest.payload.forEach(({ fieldName, value }) => {
            transformed[fieldName] = value
          })
        : (transformed[rest.fieldName] = rest.value)

      const updatedList = items.map((item) => {
        if (item.id === planUid) {
          return {
            ...item,
            ...transformed
          }
        } else {
          return item
        }
      })
      setItems(updatedList)
    }
  }, [payloads])

  const reload = () => props.setReload(true)
  const handleSelect = (selectionModel: GridSelectionModel) => {
    setSelected(selectionModel as string[])
  }
  const models: GridSortModel = useMemo(() => getSortModels(sort), [sort])
  const sortByField = useMemo(() => getSortByField(sort), [sort])
  const DeleteRequests = <DeleteIntroductionRequestDialog.TriggerEl open={() => openDialog('delete')} disabled={!selected.length || props.loading} />
  const UndeleteRequests = (
    <UndeleteIntroductionRequestDialog.TriggerEl open={() => openDialog('undelete')} disabled={!selected.length || props.loading} />
  )
  const handleSortModel = (model: GridSortModel) => updateSort(model, sort, props.updateSort)
  const controls = [DeleteRequests, UndeleteRequests]

  return (
    <>
      <Wide>
        <DataGrid
          rows={items || []}
          columns={
            mode === 'requests'
              ? [
                  summaryColumn,
                  requesterColumn,
                  createdColumn,
                  lastUpdateColumn,
                  statusColumn,
                  beneficiaryColumn,
                  assigneeColumn,
                  outcomeColumn,
                  creatorColumn
                ]
              : []
          }
          isRowSelectable={(params) => params.row.isRowSelectable}
          checkboxSelection={!!controls.length}
          onSelect={handleSelect}
          controls={mode === 'requests' ? controls : []}
          loading={props.loading || !items}
          setPageSize={props.setPageSize}
          paging={props.paging}
          total={items ? props.total : undefined}
          setSortModel={handleSortModel}
          sortModel={models.filter((m) => m.field === sortByField)}
        />
      </Wide>
      <Narrow>
        <Box px={2}>
          <Repeater
            direction="vertical"
            variant="card"
            component={SummaryItem}
            skeleton={{ size: 20, loading: props.loading || !items }}
            items={
              items?.length
                ? items.map(
                    ({ status, summary, requestLink, assignee, created, introducer, requester, updated, closedOut, reason, description }) => ({
                      title: summary,
                      priorLine: reason,
                      variant: 'expandable',
                      icons: <StatusChip status={status} />,
                      link: requestLink,
                      byline: (
                        <Box display="flex" flexDirection="column">
                          {description && isExpandedView && <Typography>{ellipsisText(description, 60)}</Typography>}
                          <Typography>{formatDateTime(created)}</Typography>
                        </Box>
                      ),
                      byline3: (
                        <ExpandableDetails
                          isExpandedView={isExpandedView}
                          sortByField={sortByField}
                          assignee={assignee}
                          introducer={introducer}
                          requester={requester}
                          updated={updated}
                          closedOut={closedOut}
                        />
                      )
                    })
                  )
                : []
            }
          />
        </Box>
      </Narrow>
      <DeleteIntroductionRequestDialog
        isOpened={openedDialog === 'delete'}
        closeDialog={closeDialog}
        items={selected}
        reload={reload}
        openSuccess={openSuccess}
        successMode={successMode}
      />
      <UndeleteIntroductionRequestDialog
        isOpened={openedDialog === 'undelete'}
        close={closeDialog}
        items={selected}
        reload={reload}
        openSuccess={openSuccess}
        successMode={successMode}
      />
    </>
  )
}

export default RequestsList
