import { createContext, ReactNode, useEffect, useState } from 'react'

import { getGraph } from 'utils/graphUtils'

type ContextType = {
  isOutlook: boolean | undefined
}

export const OutlookContext = createContext({} as ContextType)

window.Office = {}

const OutlookProvider = (props: { children: ReactNode }) => {
  const [isOutlook, setIsOutlook] = useState<boolean>()

  useEffect(() => {
    if (window.location.search.includes('Win')) {
      window.localStorage.setItem('platform', 'outlook')
    }

    const outlook =
      window.location.search.includes('Outlook$') || window.localStorage.getItem('platform') === 'outlook' || window.location.search.includes('et=')

    if (!outlook) {
      return setIsOutlook(false)
    }

    console.log('Office API Loading...')

    window.backupHistoryFunctions = {
      pushState: window.history.pushState,
      replaceState: window.history.replaceState
    }

    const officeJS = document.createElement('script')
    officeJS.src = 'https://appsforoffice.microsoft.com/lib/1/hosted/office.js'
    officeJS.async = false
    officeJS.onload = () => {
      console.log('Office API Loaded')

      window.history.pushState = window.backupHistoryFunctions!.pushState
      window.history.replaceState = window.backupHistoryFunctions!.replaceState

      window.Office?.onReady(() => {
        console.log('Office API Ready')
        setIsOutlook(true)
        const mailbox = window.Office?.context?.mailbox
        if (mailbox) {
          mailbox.addHandlerAsync(window.Office.EventType.ItemChanged, selectedMailItemChanged)
          if (mailbox.item) {
            const wait = setInterval(() => {
              if (!document.querySelector('.spinner__container')) {
                clearInterval(wait)
                loadProfile(mailbox.item)
              }
            }, 50)
          }
        }
      })
    }

    document.head.prepend(officeJS)
  }, [])

  const getFolderName = async (itemId: string): Promise<string | null> => {
    try {
      const messageResponse = await getGraph(`/me/messages/${itemId}`)
      const message = await messageResponse.json()

      if (!message.parentFolderId) return null

      const folderResponse = await getGraph(`/me/mailFolders/${message.parentFolderId}`)
      const folder = await folderResponse.json()

      return folder.displayName ?? null
    } catch (error) {
      console.error('Error fetching folder name:', error)
      return null
    }
  }

  const removePronounsFromName = (name: string): string => {
    const regex = /\s*\((?:she|he|they)\/(?:her|him|them)(?:\/(?:hers|his|theirs))?\)\s*/g
    return name.replace(regex, '')
  }

  const loadProfile = async (item: { itemId: string; to?: { displayName: string }[]; from?: { displayName: string } }) => {
    const folderName = await getFolderName(item.itemId)
    if (!folderName) return

    console.log(folderName)
    const displayName = folderName.toLowerCase() === 'sentitems' ? item.to?.[0]?.displayName : item.from?.displayName
    if (!displayName) return

    const cleanedName = removePronounsFromName(displayName)
    const url = `/people/${encodeURIComponent(cleanedName)}`
    window.postMessage(`url:${url}`, '*')
  }

  const selectedMailItemChanged = () => {
    console.log('Office API Item Changed fired')
    const mailbox = window.Office?.context?.mailbox
    if (mailbox?.item) {
      loadProfile(mailbox.item)
    } else {
      mailbox?.removeHandlerAsync(window.Office.EventType.ItemChanged, { handler: selectedMailItemChanged }, () => {
        mailbox?.addHandlerAsync(window.Office.EventType.ItemChanged, selectedMailItemChanged)
      })
    }
  }

  const ctx = { isOutlook }

  return <OutlookContext.Provider value={ctx}>{props.children}</OutlookContext.Provider>
}

export default OutlookProvider
