import React from 'react'

import { IconButton } from '_shared/buttons'
import { IconButtonProps } from '_shared/buttons/Icon'

const HelpLinkButton = (props: Omit<IconButtonProps<'a'>, 'icon' | 'component' | 'children' | 'ref' | 'rel' | 'target'>) => {
  return <IconButton<'a'> component="a" icon={['far', 'question-circle']} target="_blank" rel="noopener noreferrer" {...props} />
}

export default HelpLinkButton
