import { useEffect } from 'react'

const useResizeObserver = (refs: { [key: string]: HTMLInputElement | null }) => {
  useEffect(() => {
    let resizeObserverEntries: ResizeObserverEntry[] = []

    const observer = new ResizeObserver((entries) => {
      resizeObserverEntries = entries
    })

    Object.values(refs).forEach((ref) => {
      if (ref) {
        observer.observe(ref)
      }
    })

    return () => {
      resizeObserverEntries.forEach((entry) => entry.target.remove())
      observer.disconnect()
    }
  }, [])
}

export default useResizeObserver
