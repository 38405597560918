import React, { ComponentProps, useContext, useEffect } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Divider } from '@mui/material'
import { Route, Switch, useParams, useLocation, matchPath } from 'react-router-dom'

import ActivitiesPage from '_pages/companies/[id]/activities'
import ActivityStatsPages from '_pages/companies/[id]/activityStats'
import CSuite from '_pages/companies/[id]/c-suite'
import CSuitePage from '_pages/companies/[id]/c-suite/[id]'
import ExternalKeysPage, { ExternalKeysList, ExternalKeysListPropsType } from '_pages/companies/[id]/externalKeys'
import MarketDataPage from '_pages/companies/[id]/market-data'
import CompanyMarketDataPage from '_pages/companies/[id]/market-data/company'
import SimilarCompanies from '_pages/companies/[id]/similar'
import SimilarCompanyPage from '_pages/companies/[id]/similar/[id]'

import { TeamContext } from '_core/context/TeamContext'

import Card, { CardContent } from '_shared/Card'
import Page from '_shared/Page'
import Typography from '_shared/Typography'

import CompanyHeader from '_core/components/CompanyHeader'
import Dashboard, { DashboardLinkType } from '_core/components/Dashboard'
import Empty from '_core/components/Empty'
import { Wide } from '_core/components/layout'
import CompanyMarketData from '_core/components/market-data/CompanyMarketData'
import PrivateRoute from '_core/components/PrivateRoute'
import UnknownProfileSummary from '_core/components/UnknownProfileSummary'

import useCompanyProfile from '_core/hooks/useCompanyProfile'
import usePresetScroll from '_core/hooks/usePresetScroll'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

import Aliases from './aliases'
import AliasesNames from './aliases/names'
import AliasesDomains from './aliases/urls'
import Contacts from './contacts'
import Interactions from './interactions'
import Introducers from './introducers'
import Profile, { ProfileAddProps } from './profile'
import Relationships from './relationships'
import Touchpoints from './touchpoints'

const url = Paths._companies

const CompanyProfile = (props: ComponentProps<typeof Profile>) => {
  const { id } = useParams<{ id: string }>()
  const { setMobileHeader } = useContext(LayoutContext)
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { pathname } = useLocation()
  const { queryParams } = useSearchQuery<CompanyProfilePageParams>()
  const { isExact: matchProfile } = matchPath(pathname, { path: Paths.companyProfile }) || {}

  const enableScroll =
    props.interactions !== undefined && props.isStatsWidgetVisible !== undefined && !props.loading && !!props.CompanyMd5 && matchProfile

  usePresetScroll(enableScroll)

  const prohibitedMarketData = typeof props.enabledMarketData === 'boolean' && !props.enabledMarketData

  const companyName = props.CompanyNameText || props.BestUrlText

  const alternateMobileHeader = matchPath(pathname, {
    path: [
      `${Paths.companyProfile}/similar/:similarId`,
      `${Paths.companyProfile}/c-suite/:cSuiteId`,
      `${Paths.companyProfile}/market-data/company/:companyId`
    ]
  })?.isExact

  useEffect(() => {
    if (!alternateMobileHeader) {
      setMobileHeader(!props.loading ? queryParams.name || companyName : '', props.loading)
    }
  }, [companyName, setMobileHeader, alternateMobileHeader, props.loading, queryParams.name])

  const dashboardLinks: DashboardLinkType[] = [
    {
      name: 'Dashboard',
      link: `${url}/${props.CompanyMd5}`,
      condition: Boolean(props.loading || props.CompanyMd5),
      icon: ['far', 'th'] as IconProp
    },
    {
      name: 'Activity statistics',
      link: `${url}/${props.CompanyMd5}/activityStats`,
      condition: props.isStatsWidgetVisible,
      icon: ['far', 'chart-simple'] as IconProp
    },
    {
      name: 'Introducers',
      link: `${url}/${props.CompanyMd5}/introducers`,
      condition: Boolean(props.loading || props.CompanyMd5),
      icon: ['far', 'address-book'] as IconProp
    },
    {
      name: 'Interactions',
      link: `${url}/${props.CompanyMd5}/interactions`,
      condition: props.showInteractionsInProfile,
      icon: ['far', 'envelope'] as IconProp
    },
    {
      name: 'Contacts',
      link: `${url}/${props.CompanyMd5}/contacts`,
      condition: Boolean(props.loading || props.CompanyMd5),
      icon: ['far', 'user'] as IconProp
    },
    {
      name: 'Touchpoints',
      link: `${url}/${props.CompanyMd5}/touchpoints`,
      condition: Boolean(props.loading || props.CompanyMd5),
      icon: ['far', 'handshake'] as IconProp
    },
    {
      name: 'Key Relationships',
      link: `${url}/${props.CompanyMd5}/relationships`,
      condition: Boolean(props.loading || props.CompanyMd5),
      icon: ['far', 'star'] as IconProp
    },
    {
      name: 'Aliases and domains',
      link: `${url}/${props.CompanyMd5}/aliases`,
      condition: Boolean(props.loading || props.CompanyMd5),
      icon: ['far', 'fingerprint'] as IconProp
    },
    {
      name: 'External Keys',
      link: `${url}/${props.CompanyMd5}/keys`,
      condition: !!props.CrmSourceKeys?.data?.length,
      icon: ['fas', 'share-alt'] as IconProp
    }
  ].filter(({ condition }) => condition)

  const interactionsHeight = dashboardLinks.length * 78

  if (!props.loading && !props.CompanyMd5) {
    return queryParams.name ? (
      <>
        {props.enabledMarketData && queryParams.website ? (
          <CompanyMarketData company={props.marketData} />
        ) : (
          <>
            <Card elevation={0} square>
              <CardContent>
                <UnknownProfileSummary loading={props.loading} title={queryParams.name} url={queryParams.website} />
              </CardContent>
            </Card>
            <Divider />
            <Empty title="Profile not found in DotAlign" />
          </>
        )}
      </>
    ) : (
      <Empty title="Profile not found" closeExtension />
    )
  }

  const Header = <CompanyHeader {...props} />

  return (
    <>
      <Switch>
        <Route exact path={`${url}/:id`}>
          {Header}
        </Route>
        <Route exact path={`${url}/:id/*`}>
          <Wide>{Header}</Wide>
        </Route>
      </Switch>

      <Dashboard loading={props.loading} links={dashboardLinks}>
        <Switch>
          <Route exact path={`${url}/:id`}>
            <Profile {...props} />
          </Route>

          <PrivateRoute
            hasAccess={props.isStatsWidgetVisible && props.isDetailedActivityFromStatsWidgetVisible}
            exact
            path={`${url}/:id/activities`}
            render={() => <ActivitiesPage companyName={companyName} />}
          />
          <PrivateRoute
            hasAccess={props.isStatsWidgetVisible}
            exact
            path={`${url}/:id/activityStats`}
            render={() => <ActivityStatsPages companyName={companyName} />}
          />

          <Route exact path={`${url}/:id/relationships`}>
            <DynamicEntity
              url={`/companies/${id}/people?TeamNumber=${teamNumber}&SortBy=ScoreDesc&employeeType=Current`}
              component={Relationships}
              list={true}
              infinite={true}
              nativeBack
              subHeader={(props: any) => (
                <Typography title={`Key relationships · ${props.total_item_count}`} variant="body1" style={{ maxWidth: '100%' }} noWrap>
                  Key relationships · {props.total_item_count}
                </Typography>
              )}
              id="company_relationships"
            />
          </Route>

          <Route exact path={`${url}/:id/introducers`} component={Introducers} />

          <Route
            exact
            path={`${url}/:id/touchpoints`}
            render={() => (
              <DynamicEntity
                url={props.CompanyMd5 ? `/companies/${props.CompanyMd5}?teamNumber=${teamNumber}&includeStats=true` : ''}
                component={Touchpoints}
                nativeBack
                subHeader={() => (
                  <Typography title="Key touch points" variant="body1" style={{ maxWidth: '100%' }} noWrap>
                    Key touch points
                  </Typography>
                )}
                id="company_touchpoints"
              />
            )}
          />
          <Route exact path={`${url}/:id/contacts`} component={Contacts} />
          <Route exact path={`${url}/:id/aliases/urls`} render={() => <AliasesDomains companyName={companyName} />} />
          <Route exact path={`${url}/:id/aliases/names`} render={() => <AliasesNames companyName={companyName} />} />
          <Route exact path={`${url}/:id/aliases`} render={() => <Aliases companyName={companyName} />} />
          <PrivateRoute exact path={`${url}/:id/similar`} hasAccess={!prohibitedMarketData}>
            <SimilarCompanies similar={props.similar} getSimilar={props.getSimilar} />
          </PrivateRoute>
          <PrivateRoute exact path={`${url}/:id/similar/:similarId`} hasAccess={!prohibitedMarketData}>
            <SimilarCompanyPage getCompanyDetails={props.getCompanyDetails} />
          </PrivateRoute>
          <PrivateRoute exact path={`${url}/:id/c-suite`} hasAccess={!prohibitedMarketData}>
            <CSuite cSuite={props.cSuite} getCSuite={props.getCSuite} />
          </PrivateRoute>
          <PrivateRoute exact path={`${url}/:id/c-suite/:cSuiteId`} hasAccess={!prohibitedMarketData}>
            <CSuitePage getPersonDetails={props.getPersonDetails} />
          </PrivateRoute>
          <PrivateRoute exact path={`${url}/:id/market-data`} hasAccess={!prohibitedMarketData}>
            <MarketDataPage marketData={props.marketData} />
          </PrivateRoute>
          <PrivateRoute exact path={`${url}/:id/market-data/company/:companyId`} hasAccess={!prohibitedMarketData}>
            <CompanyMarketDataPage />
          </PrivateRoute>
          <PrivateRoute hasAccess={props.showInteractionsInProfile} exact path={`${url}/:id/interactions`}>
            <Interactions minHeight={interactionsHeight} CompanyMd5={props.CompanyMd5} />
          </PrivateRoute>
          <Route
            exact
            path={`${url}/:id/keys`}
            render={() => (
              <ExternalKeysPage>
                <DynamicEntity
                  url={props.CompanyMd5 ? `/companies/${props.CompanyMd5}/externalkeys?TeamNumber=${teamNumber}` : ''}
                  component={ExternalKeysList}
                  list
                  infinite
                  forceNarrow
                  nativeBack
                  keepMounted
                  subHeader={(props: ExternalKeysListPropsType) => (
                    <Typography title={`External keys · ${props.total_item_count}`} variant="body1" style={{ maxWidth: '100%' }} noWrap>
                      External keys · {props.total_item_count}
                    </Typography>
                  )}
                  id="company_external_keys"
                />
              </ExternalKeysPage>
            )}
          />
        </Switch>
      </Dashboard>
    </>
  )
}

const CompanyPage = () => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { pathname } = useLocation()

  const { onProfileLoading, ...companyProfileData } = useCompanyProfile()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Page>
      <DynamicEntity<{ extraProps: { addprops: ProfileAddProps } }>
        url={`/companies/${id}?teamNumber=${teamNumber}&numIntroducers=5&numEmployees=7&numUrls=4&numAliases=4&includeStats=true&excludeFormerRoles=true&WithCompanyTags=true&NumCrmSourceKeys=3`}
        component={CompanyProfile}
        onLoading={onProfileLoading}
        keepMounted
        id="company_profile"
        addprops={companyProfileData}
      />
    </Page>
  )
}

export default CompanyPage
