import { useContext, useEffect, useMemo, useState } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import { getSortField } from '_core/components/sort/ManualEdits'

import { mergeUrlWithParams } from 'utils/httpUtils'

const sortParams: { [key: string]: { regular: string; administration: string } } = {
  date: {
    regular: 'ManuallyAssertedWhen',
    administration: 'AdminEditedWhen'
  }
}

const useManualEditsListUrl = (
  entityType: ManualEditsPageParams['entityType'],
  contributor: string,
  assertionFilter: string | undefined,
  sort: ManualEditsPageParams['sort']
) => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const [reload, setReload] = useState(false)

  useEffect(() => {
    if (reload) setReload(false)
  }, [reload])

  const sortBy = useMemo(() => {
    const field = getSortField(sort)
    return sortParams[field]
  }, [sort])

  const url = useMemo(() => {
    if (!teamNumber || !entityType) return null

    const direction = sort?.includes('Asc') ? 'Asc' : 'Desc'

    const commonParams = {
      TeamNumber: `${teamNumber}`
    }

    const administrationParams = {
      EntityMd5s: contributor ? [contributor] : '',
      Type: entityType?.includes('Company') ? 'Company' : 'Person',
      Direction: `${direction}ending`,
      SortBy: sortBy.administration
    }

    if (entityType.includes('DisqualifierAdministration')) {
      return mergeUrlWithParams('/identities/invalidids', {
        ...commonParams,
        ...administrationParams
      })
    }

    if (entityType.includes('SplitAdministration')) {
      return mergeUrlWithParams('/identities/splits', {
        ...commonParams,
        ...administrationParams
      })
    }

    return mergeUrlWithParams('/teams/extractents', {
      ...commonParams,
      Entity: entityType,
      JustThisContributorKey: contributor,
      AssertionFilter: assertionFilter,
      SortBy: `${sortBy.regular}${direction}`
    })
  }, [teamNumber, entityType, contributor, assertionFilter, sort])

  const handleReload = () => setReload(true)

  return {
    url: reload ? null : url,
    handleReload
  }
}

export default useManualEditsListUrl
