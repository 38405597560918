import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

import Empty from '_core/components/Empty'
import Heading, { useStyles as useHeadingStyles } from '_core/components/Heading'
import { getTime, Interaction } from '_core/components/Interactions'
import InteractionsPrivacyMsg from '_core/components/InteractionsPrivacy'
import Timeline, { TimelineList } from '_core/components/lists/Timeline'
import NameLink from '_core/components/NameLink'
import Widget, { SeeAllAction } from '_core/components/widgets'

import { stringifyUrl } from '_core/helpers/browser'

import Paths from 'Paths'

import useCommonStyles from './styles'

const useStyles = makeStyles()((theme) => ({
  timeline: {
    marginTop: theme.spacing(1),
    '& .MuiTimelineSeparator-root': {
      marginTop: 16,
      marginBottom: -16
    },
    '& .MuiTimelineDot-root': {
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      padding: 0,
      color: theme.palette.primary.main
    }
  },
  title: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

export type InteractionType = {
  attachments: []
  body: string
  conversationIndex: string
  delimCategory: string
  folderSourceKey: string
  hasAttachments: boolean
  important: boolean
  inetMsgId: string
  isDraft: boolean
  isValid: boolean
  lastModifiedDateTime: string
  participants: {
    addresseeRole: string
    email: string
    name: string
  }[]
  rawSubject: string
  receivedDateTime: Date
  returnPath: string
  searchKey: string
  senderEmail: string
  senderName: string
  senderSmtpEmail: string
  sentDateTime: string
  sentRepresentingEmailAddress: string
  sentRepresentingName: string
  sourceKey: string
  transportHeaders: object
  webLink: string
}

const InteractionsWidget = (props: {
  loading: boolean
  interactions?: InteractionType[]
  errorMessage?: string
  link: string
  entityName?: string
}) => {
  const { classes } = useStyles()
  const { classes: commonClasses } = useCommonStyles()
  const { pathname } = useLocation()
  const loading = props.loading || (!props.interactions && !props.errorMessage)

  const { classes: headingClasses } = useHeadingStyles()

  return (
    <Widget>
      <Heading
        underlined
        title={
          <Box className={classes.title}>
            <Typography variant="h4" className={headingClasses.widget} semiBold>
              Interactions
            </Typography>
            <InteractionsPrivacyMsg />
          </Box>
        }
        icon={['far', 'envelope']}
        action={<SeeAllAction link={props.interactions?.length ? props.link : ''} />}
      />
      {props.errorMessage ? (
        <Empty
          className={commonClasses.empty}
          icon={<FontAwesomeIcon size="4x" style={{ color: '#A7A7A7' }} icon={['fat', 'envelope-open-text']} />}
          subTitle={props.errorMessage}
        />
      ) : (
        <>
          {(loading || !!props.interactions?.length) && (
            <Timeline scope="widget" className={classes.timeline} loading={loading}>
              <TimelineList
                items={
                  props.interactions
                    ?.map((item) => ({
                      time: getTime(item.receivedDateTime),
                      title: item.rawSubject || '(No subject)',
                      byline: item.body,
                      byline2: (
                        <NameLink
                          variant="light"
                          url={stringifyUrl(`${Paths._people}/${item.senderEmail}`, { name: item.senderName, email: item.senderEmail })}
                          name={item.senderName}
                        />
                      ),
                      dotIcon: <FontAwesomeIcon icon={['fas', 'envelope']} style={{ fontSize: 14 }} />,
                      sidepanel: true,
                      link: stringifyUrl(`${Paths._messages}/${item.sourceKey}`, { deleteBackLink: pathname }),
                      component: 'div'
                    }))
                    .slice(0, 5) || []
                }
                skeleton={{ size: 5, loading }}
                component={Interaction}
              />
            </Timeline>
          )}
          {!loading && !props.interactions?.length && (
            <Empty
              className={commonClasses.empty}
              subTitle={`You have not exchanged any email messages with ${props.entityName}`}
              icon={<FontAwesomeIcon size="3x" icon={['fat', 'envelope-open-text']} style={{ color: '#A7A7A7' }} />}
            />
          )}
        </>
      )}
    </Widget>
  )
}

export default InteractionsWidget
