import { useEffect } from 'react'

import { Route, RouteProps } from 'react-router-dom'

import Typography from '_shared/Typography'

const DefaultDeniedComponent = () => {
  useEffect(() => {
    const type: keyof CustomErrorType = 'accessDenied'
    throw { type }
  }, [])

  return null
}

const PrivateRoute = ({
  key,
  component,
  children,
  render,
  hasAccess,
  deniedComponent: DeniedComponent = DefaultDeniedComponent,
  ...props
}: RouteProps & { key?: string; hasAccess: boolean | undefined; deniedComponent?: () => JSX.Element | null }) => {
  if (typeof hasAccess !== 'boolean') {
    return (
      <Route key={key} {...props}>
        <Typography>Loading...</Typography>
      </Route>
    )
  }

  return (
    <Route
      key={key}
      {...props}
      {...(component ? { component: hasAccess ? component : DeniedComponent } : {})}
      {...(render ? { render: hasAccess ? render : () => <DeniedComponent /> } : {})}
    >
      {children ? hasAccess ? children : <DeniedComponent /> : children}
    </Route>
  )
}

export default PrivateRoute
