import { Accordion, AccordionDetails, AccordionSummary, Box, Divider } from '@mui/material'
import { parsePhoneNumber } from 'libphonenumber-js'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Avatar from '_shared/Avatar'
import { IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import ExternalTag from '_core/components/ExternalTag'
import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import Line, { AnchorLinkLine, LinkLine } from '_core/components/ProfileLine'

import { addMissedProtocol } from '_core/helpers/string'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  chip: {
    maxWidth: '100%',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1)
  },
  iconButton: {
    paddingTop: theme.spacing(2)
  },
  accordion: {
    '&:before': {
      height: 0
    },
    '&[class*="expanded"]': {
      margin: 0
    }
  },
  summary: {
    padding: 0,
    alignItems: 'end'
  },
  accordionContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  accordionDetails: {
    padding: 0,
    '& > div': {
      gap: theme.spacing(1)
    }
  }
}))

type PersonDataProps = {
  urlType?: 'people' | 'companies'
  data: PersonMarketDataType | null
}

const urls = {
  companies: Paths._companies,
  people: Paths._people
}

const format = 'MM/YYYY'

const PersonData = ({ data, urlType }: PersonDataProps) => {
  const { id: entityId } = useParams<{ id: string }>()
  const { classes } = useStyles()

  const {
    id,
    locationName,
    industry,
    linkedinUrl,
    twitterUrl,
    jobTitle,
    jobCompanyMd5,
    jobCompanyName,
    jobCompanyWebsite,
    jobCompanySize,
    jobCompanyLocationName,
    jobCompanyIndustry,
    jobCompanyFacebookUrl,
    jobCompanyTwitterUrl,
    jobCompanyLinkedinUrl,
    workEmail,
    skills,
    interests,
    mobilePhone,
    phoneNumbers,
    experience,
    education
  } = data || {}

  const transformedPhoneNumbers = phoneNumbers?.filter((phone) => phone !== mobilePhone)
  const transformedExperience = experience
    ?.filter((item) => item?.company?.name)
    ?.map((data) => {
      const { company, title, startDate, endDate } = data || {}
      const formattedStartDate = moment(startDate).format(format)
      const formattedEndDate = moment(endDate).format(format)

      return {
        name: company.name,
        link: company.name
          ? urlType
            ? mergeUrlWithParams(`${urls[urlType]}/${entityId}/market-data/company/${company.name}`, { website: company.website })
            : `${Paths._companies}/${company.website || company.name}/market-data?name=${company.name}&website=${company.website}`
          : '',
        logoUrl: company.website,
        byline: company.website,
        byline2: (
          <>
            {title?.name && (
              <Tooltip title={title.name}>
                <Typography noWrap>{title.name}</Typography>
              </Tooltip>
            )}
            {startDate && endDate && (
              <Typography color="text.secondary">
                {formattedStartDate} - {formattedEndDate}
              </Typography>
            )}
            {startDate && !endDate && <Typography color="text.secondary">from {formattedStartDate}</Typography>}
            {endDate && !startDate && <Typography color="text.secondary">until {formattedEndDate}</Typography>}
          </>
        ),
        variant: 'expandable',
        startDate: startDate ? new Date(startDate) : new Date(endDate ? 1 : 0)
      }
    })
    .sort((a, b) => b.startDate.getTime() - a.startDate.getTime())

  const transformedEducation = education
    ?.filter((item) => item?.school?.name)
    ?.map((data) => {
      const { school, startDate, endDate, degrees, majors } = data || {}
      const formattedStartDate = moment(startDate).format(format)
      const formattedEndDate = moment(endDate).format(format)

      const details = [degrees?.[0], majors?.[0]].filter(Boolean).join(', ')

      return {
        name: school.name,
        link: school.name
          ? urlType
            ? mergeUrlWithParams(`${urls[urlType]}/${entityId}/market-data/company/${school.name}`, { website: school.website })
            : `${Paths._companies}/${school.website || school.name}/market-data?name=${school.name}&website=${school.website}`
          : '',
        logoUrl: school.website,
        byline: school.website,
        byline2: (
          <>
            {school.locationName && (
              <Tooltip title={school.locationName}>
                <Typography noWrap>{school.locationName}</Typography>
              </Tooltip>
            )}
            {details && (
              <Tooltip title={details}>
                <Typography noWrap>{details}</Typography>
              </Tooltip>
            )}
            {startDate && endDate && (
              <Typography color="text.secondary">
                {formattedStartDate} - {formattedEndDate}
              </Typography>
            )}
            {startDate && !endDate && <Typography color="text.secondary">from {formattedStartDate}</Typography>}
            {endDate && !startDate && <Typography color="text.secondary">until {formattedEndDate}</Typography>}
          </>
        ),
        variant: 'expandable',
        startDate: startDate ? new Date(startDate) : new Date(endDate ? 1 : 0)
      }
    })
    .sort((a, b) => b.startDate.getTime() - a.startDate.getTime())

  const showCompanyBlock = jobCompanyLocationName || jobCompanyIndustry || jobCompanySize || jobCompanyWebsite
  const jobCompanyLink = jobCompanyMd5
    ? `${Paths._companies}/${jobCompanyMd5}`
    : urlType
      ? mergeUrlWithParams(`${urls[urlType]}/${entityId}/market-data/company/${jobCompanyName}`, {
          website: jobCompanyWebsite
        })
      : `${Paths._companies}/${jobCompanyWebsite || jobCompanyName}/market-data?name=${jobCompanyName}&website=${jobCompanyWebsite}`

  return (
    <>
      <Line loading={!id} icon={['fas', 'user-tie']} value={jobTitle} />
      <LinkLine loading={!id} icon={['fas', 'building']} value={jobCompanyName} to={jobCompanyLink} />
      <AnchorLinkLine icon={['fas', 'envelope']} href={`mailto:${workEmail}`} hint={`Mail to ${workEmail}`} loading={!id} value={workEmail} />

      {transformedPhoneNumbers && transformedPhoneNumbers.length > 3 && (
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary
            expandIcon={<IconButton icon={['far', 'chevron-down']} size="small" color="inherit" disablePX disablePT />}
            aria-label="Expand"
            aria-controls="Phone numbers"
            id="Phone numbers"
            classes={{ root: classes.summary, content: classes.accordionContent }}
          >
            {transformedPhoneNumbers.slice(0, 3).map((phone) => (
              <PhoneNumber phone={phone} key={phone} />
            ))}
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetails }}>
            <Box display="flex" flexDirection="column" alignItems="start" width="100%">
              {transformedPhoneNumbers.slice(4).map((phone) => (
                <PhoneNumber phone={phone} key={phone} />
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      {transformedPhoneNumbers &&
        transformedPhoneNumbers.length < 3 &&
        transformedPhoneNumbers.map((phone) => <PhoneNumber phone={phone} key={phone} />)}
      {(!id || locationName) && <Line loading={!id} icon={['fas', 'location-dot']} value={locationName} />}
      {(!id || industry) && <Line loading={!id} icon={['fas', 'industry']} value={industry} />}

      <Box display="flex" justifyContent="center">
        {(!id || twitterUrl) && (
          <IconButton<'a'>
            component="a"
            className={classes.iconButton}
            icon={['fab', 'twitter']}
            color="primary"
            hint="Show user profile on Twitter"
            href={twitterUrl ? addMissedProtocol(twitterUrl) : ''}
            loading={!id}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
        {(!id || linkedinUrl) && (
          <IconButton<'a'>
            component="a"
            className={classes.iconButton}
            icon={['fab', 'linkedin']}
            color="primary"
            hint="Show user profile on LinkedIn"
            href={linkedinUrl ? addMissedProtocol(linkedinUrl) : ''}
            loading={!id}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
      </Box>

      {(!id || showCompanyBlock) && (
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
      )}

      {(!id || (jobCompanyName && showCompanyBlock)) && (
        <Box display="flex" alignItems="center" mt={2} justifyContent="center">
          <Avatar name={jobCompanyName || ''} logoUrl={jobCompanyWebsite} size="xs" hideName />
          <Box ml={1}>
            <Skeleton condition={!jobCompanyName}>
              <Link to={jobCompanyLink}>
                <Typography semiBold>{jobCompanyName || 'Placeholder name'}</Typography>
              </Link>
            </Skeleton>
          </Box>
        </Box>
      )}

      {(!id || jobCompanyLocationName) && <Line loading={!id} icon={['fas', 'location-dot']} value={jobCompanyLocationName} />}
      {(!id || jobCompanyIndustry) && <Line loading={!id} icon={['fas', 'industry']} value={jobCompanyIndustry} />}
      {(!id || jobCompanySize) && <Line loading={!id} icon={['fas', 'users']} value={jobCompanySize ? `${jobCompanySize}+ employees` : ''} />}
      <Box display="flex" justifyContent="center">
        {(!id || jobCompanyFacebookUrl) && (
          <IconButton<'a'>
            component="a"
            className={classes.iconButton}
            icon={['fab', 'facebook']}
            color="primary"
            hint="Show company profile on Facebook"
            href={jobCompanyFacebookUrl ? addMissedProtocol(jobCompanyFacebookUrl) : ''}
            loading={!id}
            target="_blank"
            rel="noopener noreferrer"
            size="small"
          />
        )}
        {(!id || jobCompanyTwitterUrl) && (
          <IconButton<'a'>
            component="a"
            className={classes.iconButton}
            icon={['fab', 'twitter']}
            color="primary"
            hint="Show company profile on Twitter"
            href={jobCompanyTwitterUrl ? addMissedProtocol(jobCompanyTwitterUrl) : ''}
            loading={!id}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
        {(!id || jobCompanyLinkedinUrl) && (
          <IconButton<'a'>
            component="a"
            className={classes.iconButton}
            icon={['fab', 'linkedin']}
            color="primary"
            hint="Show company profile on Linkedin"
            href={jobCompanyLinkedinUrl ? addMissedProtocol(jobCompanyLinkedinUrl) : ''}
            loading={!id}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
        {(!id || jobCompanyWebsite) && (
          <IconButton<'a'>
            component="a"
            className={classes.iconButton}
            icon={['far', 'globe']}
            color="primary"
            hint="Show company website"
            href={jobCompanyWebsite ? addMissedProtocol(jobCompanyWebsite) : ''}
            loading={!id}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
      </Box>
      {(!id || showCompanyBlock) && (
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
      )}
      {(!id || !!skills?.length) && (
        <Skeleton condition={!skills?.length} width={120} height={29}>
          <Box mt={showCompanyBlock ? 0 : 2}>
            <Heading icon={['fas', 'hashtag']} title="Skills" />
          </Box>
          {!!skills?.length && <Box>{skills?.map((tag: string) => <ExternalTag tagData={tag} className={classes.chip} key={tag} />)}</Box>}
        </Skeleton>
      )}
      {(!id || !!interests?.length) && (
        <Skeleton condition={!interests?.length} width={120} height={29}>
          <Box mt={2}>
            <Heading icon={['fas', 'hashtag']} title="Interests" />
          </Box>
          {!!interests?.length && <Box>{interests?.map((tag: string) => <ExternalTag tagData={tag} className={classes.chip} key={tag} />)}</Box>}
        </Skeleton>
      )}
      {(!id || !!transformedExperience?.length) && (
        <>
          <Skeleton condition={!transformedExperience?.length} width={120} height={29}>
            <Box mt={2}>
              <Heading icon={['fas', 'briefcase']} title="Experience" />
            </Box>
          </Skeleton>
          <Box ml={-2} mr={-2}>
            <Repeater
              direction="vertical"
              variant="list"
              component={ProfileItem}
              skeleton={{ size: 5, loading: !id }}
              items={transformedExperience || []}
            />
          </Box>
        </>
      )}
      {(!id || !!transformedEducation?.length) && (
        <>
          <Skeleton condition={!transformedEducation?.length} width={120} height={29}>
            <Box mt={2}>
              <Heading icon={['fas', 'graduation-cap']} title="Education" />
            </Box>
          </Skeleton>
          <Box ml={-2} mr={-2}>
            <Repeater
              direction="vertical"
              variant="list"
              component={ProfileItem}
              skeleton={{ size: 5, loading: !id }}
              items={transformedEducation || []}
            />
          </Box>
        </>
      )}
    </>
  )
}

export default PersonData

const PhoneNumber = ({ phone }: { phone: string }) => {
  const phoneNumber = parsePhoneNumber(phone)?.formatInternational()

  return (
    <AnchorLinkLine icon={['fas', 'phone']} href={`tel:${phone}`} hint={`Make a call to ${phoneNumber}`} loading={!phoneNumber} value={phoneNumber} />
  )
}
