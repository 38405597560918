import { useEffect, useContext, useState } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'

import DealsList, {
  GridHeadingButtons,
  Heading,
  DealsListProps,
  synopsisColumn,
  colleaguesColumn,
  dealType,
  dealStageColumn,
  ourRoleColumn,
  valueUSColumn,
  engagedColumn,
  closedColumn,
  renewalColumn
} from '_core/components/DealsList'
import Filters from '_core/components/filters/Deals'
import { companyColumn } from '_core/components/grid/columns'
import GridPageFrame from '_core/components/GridPageFrame'
import { sortMap } from '_core/components/sort/Deals'

import useDealsPeriodEndpointParams from '_core/hooks/useDealsEndpointParams'
import useDealsUsersSettings from '_core/hooks/useDealsUserSettings'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'
import { capitalizeWord } from '_core/helpers/string'
import UserSettings from '_core/UserSettings'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

type ComponentProps = {
  onLoading: (loading: boolean, result: { total_item_count: number } | undefined) => void
  onPageSizeChange: (rowsPerPage: NumberToString<RowPerPageOptionsType>) => void
  updateSort: (sort: DealsSortType) => void
}

type AddProps = {
  extraProps: {
    addprops: Pick<DealsListProps, 'updateSort' | 'columns'>
  }
}

const columns = [
  synopsisColumn,
  colleaguesColumn,
  companyColumn,
  dealType,
  dealStageColumn,
  ourRoleColumn,
  valueUSColumn,
  engagedColumn,
  closedColumn,
  renewalColumn
]

const Component = (props: ComponentProps) => {
  const { teamContextValue } = useContext(TeamContext)
  const { queryParams } = useSearchQuery<
    DealsPageParams,
    { modifyProps: [{ roles: IncludeDeals[]; stages: IncludeDeals[]; types: IncludeDeals[] }] }
  >(['roles', 'stages', 'types'])

  const { rowsPerPage = '20', keyword, sort, stages = [], roles = [], types = [], viewMode } = queryParams
  const dealsPeriodParams = useDealsPeriodEndpointParams()

  const sortBy = (Object.keys(sortMap) as (keyof typeof sortMap)[])
    .map((sortKey) => {
      const { asc, desc } = sortMap[sortKey]
      return { value: capitalizeWord(sortKey), condition: sort === asc || sort === desc }
    })
    .find((sort) => sort.condition)?.value

  const params: { name: string; value?: string | number }[] = [
    { name: 'TeamNumber', value: teamContextValue?.teamNumber },
    { name: 'SortBy', value: sortBy },
    { name: 'Direction', value: sort && (sort?.includes('Asc') ? 'Ascending' : 'Descending') },
    { name: 'DealStage', value: stages[0] },
    { name: 'OurRole', value: roles[0] },
    { name: 'DealType', value: types[0] },
    ...dealsPeriodParams
  ]

  const url = viewMode
    ? mergeUrlWithParams(
        `/ourDeals`,
        params.filter(({ value }) => !!value).reduce((acc, { name, value }) => ({ ...acc, [name]: `${value}` }), {})
      )
    : null

  return (
    <DynamicEntity<AddProps>
      id="deals_list"
      url={url}
      addprops={{
        updateSort: props.updateSort,
        columns
      }}
      onLoading={props.onLoading}
      pageSize={+rowsPerPage}
      updatePageSize={props.onPageSizeChange}
      autoHideOnScroll
      keepMounted
      list
      infinite
      search
      component={DealsList}
      empty="No results found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
    />
  )
}

const DealsPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)

  const [total, setTotal] = useState<number | undefined>()
  const [contentLoading, setContentLoading] = useState<boolean>(false)
  const {
    setInitial,
    handleChange,
    reset,
    range,
    handleDateChange,
    loading: filtersLoading,
    opened,
    toggleOpen: toggleFilterOpen
  } = useDealsUsersSettings()

  const { queryParams } = useSearchQuery<
    DealsPageParams,
    { modifyProps: [{ roles: IncludeDeals[]; stages: IncludeDeals[]; types: IncludeDeals[] }] }
  >(['roles', 'stages', 'types'])
  const { sort } = queryParams

  useEffect(() => {
    setMobileHeader('Deals')
  }, [setMobileHeader])

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    setContentLoading(loading)
    setTotal(result?.total_item_count)
  }

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    handleChange({ rowsPerPage })
  }

  const updateSort = (sort: DealsSortType) => {
    handleChange({ sort })
  }

  const filtersProps = {
    range,
    opened,
    total,
    contentLoading,
    disabled: filtersLoading,
    toggleOpen: toggleFilterOpen,
    handleChange,
    handleDateChange,
    reset
  }

  const sortItems = columns.filter(({ sortable }) => sortable).map(({ headerName, field }) => ({ label: headerName || '', field }))
  const searchPlaceholder = 'Search for deals'
  return (
    <Page>
      <UserSettings endpoint="/usersettings/dealsfilter" setInitial={setInitial}>
        <GridPageFrame
          loading={typeof total === 'undefined'}
          gridTitle="Deals"
          searchPlaceholder={searchPlaceholder}
          disabledSearch={filtersLoading}
          headingAdornmentEnd={GridHeadingButtons}
          filters={<Filters {...filtersProps} />}
          heading={
            <Heading
              filters={<Filters {...filtersProps} />}
              sortProps={{ value: sort, update: updateSort, items: sortItems }}
              filtersProps={filtersProps}
              searchPlaceholder={searchPlaceholder}
            />
          }
          component={<Component onLoading={onLoading} onPageSizeChange={onPageSizeChange} updateSort={updateSort} />}
        />
      </UserSettings>
    </Page>
  )
}

export default DealsPage
