import { useState, useRef, useEffect, forwardRef, ChangeEvent, MouseEvent } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, InputAdornment } from '@mui/material'
import * as _ from 'lodash'
import { useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import TextField from '_shared/forms/TextField'

import useSearchQuery from '_core/hooks/useSearchQuery'

import { stringifyUrl } from '_core/helpers/browser'

const TIME = 300

const useStyles = makeStyles<{ fullWidth: boolean } | void>()((theme, params) => ({
  outline: {
    border: 'none'
  },
  underlined: {
    borderBottom: '1px solid #f2f2f2'
  },
  input: {
    '&&': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      fontWeight: 600,
      fontSize: 20,
      height: '24px',
      '&::placeholder': {
        fontSize: 18
      }
    }
  },
  icon: {
    fontSize: 17
  },
  searchIcon: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(0.5)
  },
  iconButton: {
    marginRight: -6,
    marginTop: `-${theme.spacing(1.25)}`,
    marginBottom: `-${theme.spacing(1.25)}`,
    fontSize: 18,
    '&.opened': {
      margin: `-${theme.spacing(1)}`,
      opacity: 0,
      width: 0,
      padding: 0,
      pointerEvents: 'none'
    }
  },
  collapsed: {
    width: 0,
    boxSizing: 'border-box',
    opacity: 0,
    transition: '300ms width ease-in-out, 500ms opacity ease-in-out',
    '&.opened': {
      width: '100%',
      opacity: 1,
      [theme.breakpoints.up('md')]: {
        width: params?.fullWidth ? '100%' : 300
      }
    }
  }
}))

type InputType = {
  value?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  clearInput: (e: MouseEvent<HTMLButtonElement>) => void
  placeholder?: string
  className?: string
  autoFocus?: boolean
  disabled?: boolean
  fullWidth?: boolean
}

export const Collapsed = ({
  opened: initialOpened,
  value,
  onChange,
  clearInput,
  placeholder,
  className,
  wrapperClassName,
  autoFocus = false,
  fullWidth = false,
  disabled
}: InputType & { opened?: boolean; wrapperClassName?: string }) => {
  const { classes, cx } = useStyles({ fullWidth })
  const inputRef = useRef<any>()
  const [opened, setOpen] = useState(initialOpened || false)

  useEffect(() => {
    if (!disabled) {
      inputRef?.current && autoFocus && inputRef.current.focus()
    }
  }, [autoFocus, disabled])

  const extendSearch = () => setOpen(true)

  return (
    <Box display="flex" alignItems="center" flex={1} className={wrapperClassName}>
      <IconButton onClick={extendSearch} classes={{ root: cx(classes.iconButton, { opened }) }} icon={['far', 'search']} />
      <TextField
        disabled={disabled}
        value={value || ''}
        inputRef={inputRef}
        placeholder={placeholder}
        className={className}
        fullWidth={fullWidth}
        classes={{ root: cx(classes.collapsed, { opened }) }}
        onChange={onChange}
        InputProps={
          opened
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={['far', 'search']} className={classes.icon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    onClick={clearInput}
                    size="small"
                    style={{ pointerEvents: !value ? 'inherit' : 'all', display: !value ? 'none' : 'inline-flex' }}
                    classes={{ root: classes.icon }}
                    icon={['far', 'times']}
                    disablePY={!!value}
                    disablePR={!!value}
                  />
                )
              }
            : {}
        }
      />
    </Box>
  )
}

export const Input = ({
  value,
  onChange,
  clearInput,
  placeholder,
  autoFocus = false,
  disabled,
  className,
  variant
}: InputType & { variant?: 'underlined' }) => {
  const { classes, cx } = useStyles()
  const inputRef = useRef<any>()

  useEffect(() => {
    if (!disabled) {
      inputRef?.current && autoFocus && inputRef.current.focus()
    }
  }, [autoFocus, disabled])

  return (
    <TextField
      disabled={disabled}
      className={cx({ [classes.underlined]: variant === 'underlined' }, className)}
      inputRef={inputRef}
      placeholder={placeholder}
      value={value || ''}
      onChange={onChange}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FontAwesomeIcon icon={['far', 'search']} className={cx(classes.searchIcon, classes.icon)} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={clearInput} icon={['far', 'times']} classes={{ root: classes.icon }} size="small" disablePadding />
          </InputAdornment>
        ),
        classes: {
          notchedOutline: classes.outline,
          input: classes.input
        }
      }}
    />
  )
}

type ConditionType =
  | { variant: 'collapsed'; opened: boolean; wrapperClassName?: string }
  | { variant?: 'underlined'; opened?: never; wrapperClassName?: never }

type SearchType<T> = {
  disabled?: boolean
  placeholder?: string
  className?: string
  autoFocus?: boolean
  searchKey?: T
} & ConditionType

const Search = forwardRef(
  <T extends string>(
    { disabled, placeholder, className, opened, variant, autoFocus = false, wrapperClassName, searchKey = 'keyword' as T }: SearchType<T>,
    lastInputValue: any
  ) => {
    const [inputValue, setInputValue] = useState<string | undefined>()
    const { queryParams } = useSearchQuery<GridParams<ScoreType | StatSortType, T> & { allowSearchUpdate?: 'true' }>()
    const { allowSearchUpdate, ...searchParams } = queryParams

    const location = useLocation<any>()
    const history = useHistory()

    const debouncedUpdateUrl = useRef(
      _.debounce(({ searchQuery, location, searchParams }: any) => {
        const finalQuery = { ...searchParams, [searchKey]: searchQuery }
        const newPathname = stringifyUrl(location.pathname, finalQuery)
        history.replace(newPathname, { ...location.state, ...(allowSearchUpdate ? { allowSearchUpdate: true } : {}) })
      }, TIME)
    ).current

    useEffect(() => {
      if ((typeof inputValue !== 'string' && queryParams[searchKey]) || allowSearchUpdate || location.state?.allowSearchUpdate) {
        setInputValue(queryParams[searchKey] || '')
      }
    }, [queryParams[searchKey]])

    useEffect(() => {
      return () => {
        debouncedUpdateUrl.cancel()
      }
    }, [debouncedUpdateUrl, location.pathname])

    useEffect(() => {
      if (typeof inputValue === 'string') {
        const keyword = queryParams[searchKey] || ''
        const page = inputValue !== keyword ? null : searchParams.page
        debouncedUpdateUrl({ searchQuery: inputValue, location, searchParams: { ...searchParams, page } })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue])

    useEffect(() => {
      return () => {
        debouncedUpdateUrl.cancel()
      }
    }, [debouncedUpdateUrl, location.pathname])

    useEffect(() => {
      if (typeof inputValue === 'string') {
        if (lastInputValue) {
          lastInputValue.current = inputValue
        }
        const keyword = queryParams[searchKey] || ''
        const page = inputValue !== keyword ? null : searchParams.page
        debouncedUpdateUrl({ searchQuery: inputValue, location, searchParams: { ...searchParams, page } })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue])

    const clearInput = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      setInputValue('')
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      setInputValue(value)
    }

    const props = {
      disabled,
      className,
      autoFocus,
      placeholder,
      clearInput,
      value: inputValue,
      onChange: handleChange
    }

    if (variant === 'collapsed') return <Collapsed opened={opened} wrapperClassName={wrapperClassName} {...props} />

    return <Input {...props} variant={variant} />
  }
)

export default Search
