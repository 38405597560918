import React, { ChangeEvent, Dispatch, MouseEvent, SetStateAction, SyntheticEvent, useContext, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Card, Accordion, AccordionDetails, AccordionSummary, Switch } from '@mui/material'
import { TimeValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useTimeValidation'
import moment, { Moment } from 'moment'
import { useSnackbar } from 'notistack'
import { Control, Controller, useFieldArray, useForm, UseFormRegister, useWatch } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import { Button, IconButton } from '_shared/buttons'
import { CardContent } from '_shared/Card'
import Checkbox from '_shared/forms/Checkbox'
import Combobox from '_shared/forms/Combobox'
import TextField from '_shared/forms/TextField'
import TimePicker from '_shared/TimePicker'
import Typography from '_shared/Typography'

import { put } from 'utils/httpUtils'

const useStyles = makeStyles()((theme) => ({
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: `-${theme.spacing(1)}`
  },
  content: {
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    width: '100%'
  },
  fields: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    columnGap: theme.spacing(4),
    rowGap: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    paddingBottom: theme.spacing(2)
  },
  switch: {
    cursor: 'pointer',
    pointerEvents: 'auto'
  },
  noClick: {
    cursor: 'none',
    pointerEvents: 'none'
  },
  accordion: {
    '&.Mui-disabled': {
      backgroundColor: 'inherit'
    }
  },
  details: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(auto-fill, minmax(165px, 1fr))',
    columnGap: theme.spacing(4),
    rowGap: theme.spacing(2)
  },
  cron: {
    position: 'relative',
    marginBottom: theme.spacing(2),
    minHeight: 300
  },
  removeCronBtn: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  summary: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  switcher: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: `-${theme.spacing(1.5)}`,
    marginRight: theme.spacing(1.5)
  },
  slots: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5)
  }
}))

type FormType = { connectionStr: string; crons: { activated: boolean; batchSize: number; minRestMinutes: number; teamNumber: number }[] }
type Team = Pick<TeamDataRes, 'name' | 'number'>
type Day = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday'
type DayType = { label: Day; value: number }
type ScheduleItemType = { day: DayType; time: Moment[]; active: boolean }
type ScheduleType = { [key: number]: ScheduleItemType[] }
type KickOffTime = {
  day: number
  hour: number
  minute: number
}

const daysOptions: DayType[] = [
  { label: 'Sunday', value: 0 },
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 }
]

const defaultBatchSize = 100
const defaultRestMins = 60 * 24
const initTeam = 0

const defaultCron = {
  activated: false,
  batchSize: defaultBatchSize,
  minRestMinutes: defaultRestMins,
  teamNumber: initTeam
}

const getLocalDayStart = (day: number) => moment().local().day(day).startOf('day')

const SyncSettings = ({
  loading,
  settings,
  teams
}: {
  loading: boolean
  teams: TeamDataRes[]
  settings: {
    destDbConnStr: string
    crons?: {
      syncType: 'Profiles'
      teamNumber: number
      activated: boolean
      kickoffTimes: KickOffTime[]
      minRestMinutes: number
      batchSize: number
    }[]
  }
}) => {
  const {
    register,
    control,
    getValues,
    formState: { errors }
  } = useForm<FormType>({
    mode: 'onChange',
    defaultValues: loading
      ? {
          connectionStr: '',
          crons: [defaultCron]
        }
      : {
          connectionStr: settings.destDbConnStr,
          crons: teams.map(({ number }) => {
            const data = settings.crons?.find(({ teamNumber }) => teamNumber === number)
            return data
              ? {
                  activated: data.activated,
                  batchSize: data.batchSize || defaultBatchSize,
                  minRestMinutes: data.minRestMinutes || defaultRestMins,
                  teamNumber: data.teamNumber
                }
              : { ...defaultCron, teamNumber: number }
          })
        }
  })
  const { fields } = useFieldArray({
    control,
    name: 'crons'
  })
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const [isSaving, setSaving] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)
  const initSchedule = daysOptions.map((day) => ({ day, time: [getLocalDayStart(day.value)], active: false }))
  const [schedule, setSchedule] = useState<ScheduleType>({ [initTeam]: initSchedule })
  const { enqueueSnackbar } = useSnackbar()
  const { classes } = useStyles()

  useEffect(() => {
    if (!selectedTeam && teamNumber && teams?.length) {
      const contextTeam = teams.find((team) => team.number === teamNumber)
      if (contextTeam) {
        setSelectedTeam(contextTeam)
      }
    }
  }, [teams?.length, selectedTeam, teamNumber])

  useEffect(() => {
    if (teams?.length) {
      const newSchedule = teams.reduce<ScheduleType>((acc, { number: teamNumber }) => {
        const teamSetting = settings.crons?.find((cron) => cron.teamNumber === teamNumber)
        const transformUtcTimeToLocal = ({ day, hour, minute }: KickOffTime) => {
          const utcMoment = moment().utc().day(day).hour(hour).minute(minute)
          return utcMoment.local()
        }
        const scheduleItem: ScheduleItemType[] = teamSetting
          ? daysOptions.map((day) => {
              const kickoffLocalTimes = teamSetting.kickoffTimes
                .map((kikoff) => transformUtcTimeToLocal(kikoff))
                .filter((kikoff) => kikoff.day() === day.value)

              return {
                day,
                time: kickoffLocalTimes.length ? kickoffLocalTimes : [getLocalDayStart(day.value)],
                active: !!kickoffLocalTimes.length
              }
            })
          : initSchedule

        acc[teamNumber] = scheduleItem
        return acc
      }, {})

      setSchedule(newSchedule)
    }
  }, [teams?.length])

  const selectedTeamNumber = selectedTeam ? selectedTeam.number : initTeam
  const load = isSaving || loading
  const disabled = load || !!Object.keys(errors).length

  const handleSave = async () => {
    if (schedule) {
      setSaving(true)
      const { connectionStr, crons } = getValues()
      const filterUniqueMoments = (moments: Moment[]) => {
        return moments.filter((currentMoment, index, self) => {
          return self.findIndex((moment) => moment.isSame(currentMoment, 'minutes')) === index
        })
      }

      const getKickoffTimes = (teamNumber: number): KickOffTime[] => {
        const activeItems = schedule[teamNumber].filter((item) => item.active)
        return activeItems.flatMap(({ time }: ScheduleItemType) => {
          const uniqueSlots = filterUniqueMoments(time)
          return uniqueSlots.map((slot) => ({
            day: moment.utc(slot).day(),
            hour: moment.utc(slot).hour(),
            minute: moment.utc(slot).minute()
          }))
        })
      }

      const data = {
        destDbConnStr: connectionStr,
        crons: crons.map((cron) => ({
          syncType: 'Profiles',
          teamNumber: cron.teamNumber,
          activated: cron.activated,
          minRestMinutes: cron.minRestMinutes,
          batchSize: cron.batchSize,
          kickoffTimes: getKickoffTimes(cron.teamNumber)
        }))
      }
      await put('/adminsettings/sqlServerSync', data)
      enqueueSnackbar('Sync settings have been updated')
      setSaving(false)
    }
  }

  return (
    <Box sx={{ px: { sm: 2, md: 0 }, pb: { sm: 2, md: 0 } }}>
      <Card variant="outlined">
        <CardContent>
          <ConnectionControl register={register} disabled={load} />
          <Box mt={2}>
            <TeamControl
              team={selectedTeam}
              setTeam={setSelectedTeam}
              disabled={disabled}
              options={teams?.map(({ name, number }) => ({ name, number })) || []}
            />
          </Box>
          <Box mt={2}>
            {fields.map((item, index) => (
              <Box key={item.id}>
                {item.teamNumber === selectedTeamNumber && (
                  <Card className={classes.cron}>
                    <CardContent>
                      <EnableControl index={index} control={control} loading={load} />
                      <Box className={classes.fields}>
                        <BatchSizeControl
                          index={index}
                          error={errors?.crons?.[index]?.batchSize?.message}
                          control={control}
                          register={register}
                          loading={load}
                        />
                        <MinRestControl
                          index={index}
                          error={errors?.crons?.[index]?.minRestMinutes?.message}
                          control={control}
                          register={register}
                          loading={load}
                        />
                      </Box>
                      <ScheduleControl
                        team={item.teamNumber}
                        schedule={schedule}
                        setSchedule={setSchedule}
                        loading={load}
                        control={control}
                        index={index}
                      />
                    </CardContent>
                  </Card>
                )}
              </Box>
            ))}
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Button disabled={disabled} onClick={handleSave} variant="contained">
              Save
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

const EnableControl = ({ control, loading, index }: { index: number; control: Control<FormType>; loading: boolean }) => {
  const { classes } = useStyles()

  return (
    <Controller
      name={`crons.${index}.activated`}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Checkbox checked={!!value} onChange={onChange} disabled={loading} classes={{ root: classes.checkbox }} label="Enable sync to SQL Server" />
      )}
    />
  )
}

const TeamControl = ({
  team,
  setTeam,
  options,
  disabled
}: {
  team: Team | null
  setTeam: Dispatch<SetStateAction<Team | null>>
  options: Team[]
  disabled: boolean
}) => {
  const handleChange = (e: SyntheticEvent<Element, Event>, newValue: Team | null) => setTeam(newValue)

  return (
    <Combobox<Team | null, false, boolean>
      label="Team"
      value={team}
      onChange={handleChange}
      options={options}
      disabled={disabled}
      disableClearable
      getOptionLabel={(option: typeof team) => option?.name || ''}
      isOptionEqualToValue={(option: typeof team, value: typeof team) => option?.number === value?.number}
    />
  )
}

const ConnectionControl = ({ register, disabled }: { register: UseFormRegister<FormType>; disabled: boolean }) => {
  const [isConnectionShown, setConnectionShown] = useState<boolean>(false)
  const toggleConnectionShow = () => setConnectionShown((prev) => !prev)

  return (
    <TextField
      {...register('connectionStr')}
      label="Connection string"
      type={isConnectionShown ? 'text' : 'password'}
      placeholder="Enter connection string"
      fullWidth
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <IconButton
            disablePR
            hint={`${isConnectionShown ? 'Hide' : 'Show'} API key`}
            icon={['far', isConnectionShown ? 'eye-slash' : 'eye']}
            onClick={toggleConnectionShow}
            size="small"
            disabled={disabled}
          />
        )
      }}
    />
  )
}

const BatchSizeControl = ({
  control,
  register,
  loading,
  index,
  error
}: {
  index: number
  register: UseFormRegister<FormType>
  control: Control<FormType>
  loading: boolean
  error?: string
}) => {
  const enabled = useWatch({
    control,
    name: `crons.${index}.activated`
  })
  const disabled = loading || !enabled

  const fieldProps = register(`crons.${index}.batchSize` as const, {
    valueAsNumber: true,
    validate: {
      integer: (v) => (v.toString().match(/^\d+$/) ? true : 'Invalid value')
    }
  })

  return (
    <TextField
      {...fieldProps}
      errorMessage={error}
      helperText="Value must be a positive integer or 0"
      label="Batch size"
      type="number"
      fullWidth
      disabled={disabled}
    />
  )
}

const MinRestControl = ({
  register,
  control,
  loading,
  index,
  error
}: {
  index: number
  register: UseFormRegister<FormType>
  control: Control<FormType>
  loading: boolean
  error?: string
}) => {
  const enabled = useWatch({
    control,
    name: `crons.${index}.activated`
  })
  const disabled = loading || !enabled

  const fieldProps = register(`crons.${index}.minRestMinutes` as const, {
    valueAsNumber: true,
    validate: {
      integer: (v) => (v.toString().match(/^\d+$/) ? true : 'Invalid value')
    }
  })

  return (
    <TextField
      {...fieldProps}
      label="Min rest minutes"
      type="number"
      errorMessage={error}
      helperText="Value must be a positive integer or 0"
      fullWidth
      disabled={disabled}
    />
  )
}

const ScheduleControl = ({
  team,
  loading,
  index,
  control,
  schedule,
  setSchedule
}: {
  team: number
  index: number
  loading: boolean
  control: Control<FormType>
  schedule: ScheduleType
  setSchedule: Dispatch<SetStateAction<ScheduleType>>
}) => {
  const enabled = useWatch({
    control,
    name: `crons.${index}.activated`
  })
  const { classes } = useStyles()

  const disabled = loading || !enabled

  const toggleDaySwitch = (e: React.ChangeEvent<HTMLInputElement>, day: DayType) => {
    e.stopPropagation()
    const { checked } = e.target

    setSchedule({
      ...schedule,
      [team]: schedule[team].map((item) => {
        return item.day.value === day.value ? { ...item, active: checked } : item
      })
    })
  }

  const addTime = (day: DayType) => {
    setSchedule({
      ...schedule,
      [team]: schedule[team].map((item) => {
        return item.day.value === day.value ? { ...item, time: [...item.time, getLocalDayStart(day.value)] } : item
      })
    })
  }

  const removeTime = (day: DayType, removalTime: Moment) => {
    setSchedule({
      ...schedule,
      [team]: schedule[team].map((item) => {
        return item.day.value === day.value ? { ...item, time: item.time.filter((t) => t !== removalTime) } : item
      })
    })
  }

  const handleTimeChange = (day: DayType, time: Moment, newTime: Moment | null) => {
    if (newTime) {
      setSchedule({
        ...schedule,
        [team]: schedule[team].map((item) => {
          return item.day.value === day.value
            ? {
                ...item,
                time: item.time.map((slot) => (slot === time ? newTime : slot))
              }
            : item
        })
      })
    }
  }

  return (
    <>
      <Typography className={classes.subtitle} semiBold>
        Pick sync schedule
      </Typography>
      <>
        {schedule[team].map(({ day, time, active }, i) => (
          <Box mt={i > 0 ? 2 : 0} key={i}>
            <ScheduleItem
              day={day}
              time={time}
              active={active}
              disabled={disabled}
              addTime={addTime}
              removeTime={removeTime}
              toggleDaySwitch={(e) => toggleDaySwitch(e, day)}
              handleTimeChange={(time: Moment, newTime: Moment | null) => handleTimeChange(day, time, newTime)}
            />
          </Box>
        ))}
      </>
    </>
  )
}

const ScheduleItem = ({
  day,
  time,
  active,
  toggleDaySwitch,
  addTime,
  removeTime,
  disabled,
  handleTimeChange
}: ScheduleItemType & {
  disabled: boolean
  addTime: (day: DayType) => void
  removeTime: (day: DayType, removalTime: Moment) => void
  toggleDaySwitch: (event: ChangeEvent<HTMLInputElement>) => void
  handleTimeChange: (time: Moment, newTime: Moment | null) => void
}) => {
  const [expanded, setExpanded] = useState(false)
  const [error, setError] = useState('')
  const { classes, cx } = useStyles()

  useEffect(() => {
    if (expanded && !active) {
      setExpanded(false)
    }
  }, [expanded, active])

  const toggleExpanded = () => setExpanded((prevState) => !prevState)
  const onError = (reason: TimeValidationError) => {
    setError(reason === 'invalidDate' ? 'Invalid time' : '')
  }
  const stopPropagation = (e: MouseEvent<HTMLButtonElement>) => e.stopPropagation()
  const handleAddTime = () => addTime(day)

  return (
    <Accordion
      disabled={disabled}
      onChange={toggleExpanded}
      expanded={expanded}
      variant="outlined"
      classes={{ root: cx(classes.accordion, { [classes.noClick]: !active }) }}
    >
      <AccordionSummary
        expandIcon={active ? <IconButton icon={['far', 'chevron-down']} size="small" disablePadding /> : null}
        aria-controls={`${day.value}-content`}
        id={`${day.value}-header`}
      >
        <Box className={classes.summary}>
          <Box className={classes.switcher}>
            <Switch disabled={disabled} checked={active} onChange={toggleDaySwitch} onClick={stopPropagation} classes={{ root: classes.switch }} />
            <Typography color={active ? 'primary' : 'text.secondary'} variant="h4" semiBold>
              {day.label}
            </Typography>
          </Box>
          {!expanded && (
            <Box className={classes.slots}>
              {time.map((slot, i) => (
                <Typography key={i} color={active ? 'text.primary' : 'text.secondary'}>
                  {slot.format('hh:mm A')}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.details}>
          {time.map((slot, i) => (
            <Box key={i} display="flex" alignItems="flex-start">
              <TimePicker
                value={slot}
                errorMessage={error}
                onChange={(newValue) => handleTimeChange(slot, newValue)}
                onError={onError}
                disabled={disabled}
                size="medium"
                views={['hours', 'minutes']}
                fullWidth
              />
              <IconButton disablePR hint="Remove" icon={['far', 'times']} disabled={disabled} onClick={() => removeTime(day, slot)} size="small" />
            </Box>
          ))}
        </Box>
        <Box mt={1.5}>
          <Button
            onClick={handleAddTime}
            variant="link"
            endIcon={<FontAwesomeIcon icon={['far', 'plus']} style={{ fontSize: 10 }} />}
            disabled={disabled}
            disablePadding
          >
            Add time
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default SyncSettings
