import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Popover from '_shared/popovers/Popover'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import StatusChip from '_core/components/introductions/StatusChip'
import Widget, { LinkAction } from '_core/components/widgets'

import { ellipsisText, formatDate, formatNumberWithSuffix } from 'utils/Utils'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  icon: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1)
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5)
  }
}))

const OutcomeWidget = ({
  loading,
  isCreator,
  querierIsLtnManager,
  planUid,
  editorUserKey,
  usd,
  memo,
  rating,
  date
}: {
  loading: boolean
  querierIsLtnManager: boolean
  isCreator: boolean
  planUid: string
  editorUserKey?: string
  usd?: number
  memo?: string
  rating?: number
  date?: string
}) => {
  const { classes } = useStyles()

  return (
    <Widget>
      <Heading
        title="Request outcome"
        icon={['far', 'sigma']}
        action={
          querierIsLtnManager || isCreator ? (
            <LinkAction link={`${Paths._introductions}/${planUid}/outcome`} title={editorUserKey ? 'Edit' : 'Add'} />
          ) : null
        }
      />
      {!!rating && (
        <Box mb={1.5}>
          <StatusChip status={rating === -1 ? 'failed' : 'successful'} />
        </Box>
      )}
      {(editorUserKey || loading) && (
        <Skeleton condition={!editorUserKey} height={24}>
          <Box className={classes.line}>
            <FontAwesomeIcon icon={['fas', 'user-tie']} className={classes.icon} />
            <Typography>{editorUserKey || 'Long placeholder'}</Typography>
          </Box>
        </Skeleton>
      )}
      {!!usd && (
        <Box className={classes.line}>
          <FontAwesomeIcon icon={['fas', 'dollar-sign']} className={classes.icon} />
          <Typography>{formatNumberWithSuffix(usd)}</Typography>
        </Box>
      )}
      {(memo || loading) && (
        <Skeleton condition={!memo} height={24}>
          <Box className={classes.line}>
            <FontAwesomeIcon icon={['fas', 'file']} className={classes.icon} />
            <Popover placement="top-start" triggerElement={<Typography>{ellipsisText(memo || 'Long placeholder', 75)}</Typography>}>
              <Box maxHeight="50vh" overflow="auto" maxWidth={500}>
                <Typography variant="h4" semiBold>
                  Memo
                </Typography>
                <Box pt={1}>
                  <Typography>{memo}</Typography>
                </Box>
              </Box>
            </Popover>
          </Box>
        </Skeleton>
      )}
      {(date || loading) && (
        <Skeleton condition={!date} height={24}>
          <Box display="flex" alignItems="center">
            <FontAwesomeIcon icon={['far', 'calendar-alt']} className={classes.icon} />
            <Typography>{formatDate(date) || 'Placeholder'}</Typography>
          </Box>
        </Skeleton>
      )}

      {!loading && !editorUserKey && (
        <Empty subTitle="No outcome" icon={<FontAwesomeIcon size="3x" icon={['fat', 'sigma']} style={{ color: '#A7A7A7' }} />} />
      )}
    </Widget>
  )
}

export default OutcomeWidget
