import React, { SyntheticEvent } from 'react'

import { Box, Divider } from '@mui/material'
import { useParams } from 'react-router-dom'

import Avatar from '_shared/Avatar'
import Typography from '_shared/Typography'

import AuditIdentifierPickerDialog from '_core/components/dialogs/AuditIdentifierPicker'

import useDialog from '_core/hooks/useDialog'

const IdentifiersPicker = (props: { lookUpEndpoint: string; selectIdentifier: (source: string, target: string) => void }) => {
  const { sourceId, targetId } = useParams<TuplesPageParams>()
  const { dialogContentProps: openedDialog, openDialog, closeDialog } = useDialog<'source' | 'target' | null>(null)

  const onChangeSource = (event: SyntheticEvent<Element, Event>, value: AuditIdentifier) => {
    closeDialog()
    props.selectIdentifier(encodeURIComponent(value.text), targetId)
  }

  const onChangeTarget = (event: SyntheticEvent<Element, Event>, value: AuditIdentifier) => {
    closeDialog()
    props.selectIdentifier(sourceId, encodeURIComponent(value.text))
  }

  const source = decodeURIComponent(sourceId)
  const target = decodeURIComponent(targetId)

  return (
    <>
      <Box p={2}>
        <Box display="grid" gridTemplateColumns="minmax(0, 1fr) auto auto minmax(0, 1fr)">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography color="text.secondary">Source</Typography>
            <Avatar name={source} size="md" userKey={source} />
          </Box>
          <Box display="flex" alignItems="center" p={2}>
            <AuditIdentifierPickerDialog.TriggerEl open={() => openDialog('source')} />
          </Box>
          <Box display="flex" alignItems="center" p={2}>
            <AuditIdentifierPickerDialog.TriggerEl open={() => openDialog('target')} />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography color="text.secondary">Target</Typography>
            <Avatar name={target} size="md" userKey={target} />
          </Box>
        </Box>
      </Box>
      <Divider />
      <AuditIdentifierPickerDialog
        title="Pick a first identifier"
        lookUpEndpoint={props.lookUpEndpoint}
        onChange={onChangeSource}
        isOpened={openedDialog === 'source'}
        close={closeDialog}
        pickedId={sourceId}
      />
      <AuditIdentifierPickerDialog
        title="Pick a second identifier"
        lookUpEndpoint={props.lookUpEndpoint}
        onChange={onChangeTarget}
        isOpened={openedDialog === 'target'}
        close={closeDialog}
        pickedId={targetId}
      />
    </>
  )
}

export default IdentifiersPicker
