import React, { useContext, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import Empty from '_core/components/Empty'
import InfiniteList from '_core/components/InfiniteList'
import { Narrow } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import MarketDataSearchLink from '_core/components/MarketDataSearchLink'
import ProfileItem from '_core/components/ProfileItem'
import Topbar from '_core/components/Topbar'

import { CSuiteType } from '_core/hooks/useCSuite'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type CSuiteProps = {
  cSuite: CSuiteType | null
  getCSuite: () => Promise<void>
}

const CSuite = ({ cSuite, getCSuite }: CSuiteProps) => {
  const { id } = useParams<{ id: string }>()
  const { setSubHeader } = useContext(LayoutContext)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setSubHeader(`C Suite ${cSuite?.total ? `· ${cSuite.total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, cSuite?.total])

  const items = cSuite?.data?.map((person) => {
    return {
      name: person.fullName,
      score: person.personMd5 ? person.score : undefined,
      link: person.personMd5
        ? `${Paths._people}/${person.personMd5}`
        : mergeUrlWithParams(`${Paths._companies}/${id}/c-suite/${person.fullName}`, {
            email: person.workEmail,
            company: person.jobCompanyName
          }),
      byline: person.jobTitle || '',
      byline2: person.jobStartDate ? `from ${moment(person.jobStartDate).format('MM/YYYY')}` : '',
      sidepanel: true
    }
  })

  const loadMore = async () => {
    setLoading(true)
    await getCSuite()
    setLoading(false)
  }

  return (
    <Narrow>
      <Topbar nativeBack autoHideOnScroll action={<MarketDataSearchLink entity="person" variant="icon" />} />
        <InfiniteList loading={loading} next={loadMore} dataLength={cSuite?.total || 0} hasMore={!!cSuite?.scrollToken}>
          <Repeater
            virtualized
            direction="vertical"
            variant="list"
            component={ProfileItem}
            skeleton={{ size: 5, loading: !cSuite }}
            items={items || []}
            empty={
              <Empty
                title="No records were found"
                action={
                  <Box display="flex" justifyContent="center">
                    <MarketDataSearchLink entity="person" />
                  </Box>
                }
                icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
              />
            }
          />
        </InfiniteList>
    </Narrow>
  )
}

export default CSuite
