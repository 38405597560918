import React, { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { get } from 'utils/httpUtils'

const useStyles = makeStyles()((theme) => ({
  externalWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    paddingRight: '27px',
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(1)
    }
  },
  externalIcon: {
    padding: theme.spacing(1),
    position: 'absolute',
    right: '-8px',
    top: '50%',
    fontSize: 13,
    transform: 'translateY(-50%)'
  }
}))

interface IData {
  version: string
  tenantId: string
  clientId: string
  adminGroups: any[]
  databaseLocation: string
  databaseServerName: string
  databaseName: string
  databaseTimeout: number
  useReadOnlyReplica: boolean
  quickInitialization: boolean
}

const Content = () => {
  const [data, setData] = useState<IData | undefined>()
  const { classes } = useStyles()

  useEffect(() => {
    get('/app/about').then((res: any) => setData(res))
  }, [])

  return (
    <DialogContent>
      <Box mb={2}>
        <Typography color="text.secondary">Version</Typography>
        <Skeleton condition={!data?.version} width="40%" height="21px">
          <Typography>{data?.version}</Typography>
        </Skeleton>
      </Box>
      <Box mb={2}>
        <Typography color="text.secondary">Tenant Id</Typography>
        <Skeleton condition={!data?.tenantId} width="200px" height="21px">
          <Typography>{data?.tenantId}</Typography>
        </Skeleton>
      </Box>
      <Box mb={2}>
        <Typography color="text.secondary">Client Id</Typography>
        <Box className={classes.externalWrapper}>
          <Skeleton condition={!data?.clientId} width="200px" height="21px">
            <Typography>{data?.clientId}</Typography>
          </Skeleton>
          {data?.clientId && (
            <IconButton
              hint="Go to the app registration in Entra ID"
              icon={['far', 'external-link']}
              component="a"
              href={`https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/appId/${data?.clientId}/isMSAApp~/false`}
              target="_blank"
              classes={{ root: classes.externalIcon }}
              size="small"
            />
          )}
        </Box>
      </Box>
      <Box mb={2}>
        <Typography color="text.secondary">Admin groups</Typography>
        <Skeleton condition={!data?.adminGroups} width="200px" height="21px">
          {data?.adminGroups?.length ? (
            data.adminGroups.map((el: string) => (
              <Box key={el} className={classes.externalWrapper}>
                <Typography>{el}</Typography>
                <IconButton
                  hint="Go to the group in Azure AD"
                  icon={['far', 'external-link']}
                  loading={!data?.clientId}
                  component="a"
                  href={`https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Overview/groupId/${el}`}
                  target="_blank"
                  classes={{ root: classes.externalIcon }}
                  size="small"
                />
              </Box>
            ))
          ) : (
            <Typography>No groups</Typography>
          )}
        </Skeleton>
      </Box>
      <Box mb={2}>
        <Typography color="text.secondary">Database info</Typography>
        <Skeleton condition={!data?.databaseServerName && !data?.databaseName} width="90%" height="21px">
          <Typography>{`${data?.databaseLocation}, ${data?.databaseServerName}, ${data?.databaseName}, timeout = ${data?.databaseTimeout}, ${
            data?.useReadOnlyReplica && 'useReadReplica'
          }`}</Typography>
        </Skeleton>
      </Box>
      <Box>
        <Typography color="text.secondary">Quick initialization</Typography>
        <Skeleton condition={typeof data?.quickInitialization !== 'boolean'} width="200px" height="21px">
          <Typography>{data?.quickInitialization === false ? 'False' : 'True'}</Typography>
        </Skeleton>
      </Box>
    </DialogContent>
  )
}

const AboutAppDialog = ({ opened, close }: { opened: boolean; close: () => void }) => {
  return (
    <Dialog open={opened} onClose={close} title={<DialogTitle title="About Dotalign" icon="/image/logo_circle.svg" />} maxWidth={420}>
      <Content />
      <DialogActions>
        <Button variant="text" onClick={close} color="secondary" disablePR>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AboutAppDialog
