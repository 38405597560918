import { useEffect, useContext, useState, Dispatch, SetStateAction, useMemo, ComponentProps } from 'react'

import { useMsal } from '@azure/msal-react'
import { ElementSize, GridColDef } from '@mui/x-data-grid-pro'

import { TeamContext } from '_core/context/TeamContext'

import CompaniesList, { CompaniesListProps, Heading, GridHeadingButtons, DownloadAll } from '_core/components/CompaniesList'
import Filters from '_core/components/filters/Companies'
import {
  nameColumn,
  scoreColumn,
  keyContactColumn,
  bestIntroducerColumn,
  lastInboundColumn,
  lastMeetingColumn,
  lastOutboundColumn,
  nextFutureMeetingColumn
} from '_core/components/grid/columns'
import GridPageFrame from '_core/components/GridPageFrame'
import MarketDataSearchLink from '_core/components/MarketDataSearchLink'
import { sortMap } from '_core/components/sort'

import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useAdmin from '_core/hooks/useAdmin'
import { CompanyItem } from '_core/hooks/useCompaniesListPayloads'
import useCompaniesUserSettings from '_core/hooks/useCompaniesUserSettings'
import { DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useEnableDownloading from '_core/hooks/useEnableDownloading'
import useInteractionsPeriodEndpointParams from '_core/hooks/useInteractionsPeriodEndpointParams'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSortStatsEndpointParams from '_core/hooks/useSortStatsEndpointParams'
import useTagsEndpointParams from '_core/hooks/useTagsEndpointParams'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

import DynamicEntity from '_core/DynamicEntity'
import UserSettings from '_core/UserSettings'

import { getBinary, mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type AddProps = {
  extraProps: {
    addprops: Pick<CompaniesListProps, 'sort' | 'updateSort' | 'onResize' | 'sortByField' | 'viewMode' | 'columns' | 'enableDownloading'> &
      Pick<ComponentProps<typeof DownloadAll>, 'download'>
  }
}

const underTopBarPos = 31
const gridToolsHeight = 240

type CProps = {
  enableDownloading: CompaniesListProps['enableDownloading']
  setHeight: Dispatch<SetStateAction<number | undefined>>
  onPageSizeChange: (rowsPerPage: NumberToString<RowPerPageOptionsType>) => void
  onLoading: (loading: boolean, result: { total_item_count: number } | undefined) => void
  updateSort: (sort: ScoreType | StatSortType) => void
  columns: GridColDef[]
}

const Companies = ({
  download,
  ...props
}: Modify<CompaniesListProps, { items: CompaniesListItem[] }> & Pick<ComponentProps<typeof DownloadAll>, 'download'>) => {
  const { userActivitiesAccess } = useActivitiesAccess()
  const { isStatsWidgetVisible = false } = userActivitiesAccess || {}

  const items: CompanyItem[] = useMemo(
    () =>
      props.items?.map((company) => {
        const lastInbound = company.Stats?.LastInboundMsg || ''
        const lastOutbound = company.Stats?.LastOutboundMsg || ''
        const lastMeeting = company.Stats?.LastMeeting || ''
        const nextFutureMeeting = company.Stats?.NextFutureMeeting || ''

        const profileLink = `${Paths._companies}/${company.CompanyMd5}`

        return {
          id: company.CompanyMd5,
          name: company.CompanyNameText || company.BestUrlText,
          score: company.Score,
          link: profileLink,
          activityStatsLink: isStatsWidgetVisible ? `${profileLink}/activityStats` : '',
          auditLink: `${profileLink}/audit`,
          logoUrl: company.BestUrlText,
          byline: company.BestUrlText,
          keyContact: {
            name: company.AnchorEmployeeNameText || '',
            link: company.AnchorEmployeePersonMd5 ? `${Paths._people}/${company.AnchorEmployeePersonMd5}` : '',
            sidepanel: true as SidepanelType
          },
          bestIntroducer: {
            name: company.BestKnowerNameText || '',
            link: company.BestKnowerUserKeyMd5 ? `${Paths._relationships}/${company.BestKnowerUserKeyMd5}/companies/${company.CompanyMd5}` : '',
            sidepanel: true as SidepanelType
          },
          lastInbound,
          lastOutbound,
          lastMeeting,
          nextFutureMeeting,
          sidepanel: true as SidepanelType,
          variant: 'expandable',
          tags: company.Tags || []
        }
      }),
    [props.loading, props.items.length, isStatsWidgetVisible]
  )

  return (
    <CompaniesList {...props} items={items} downloadAllControl={<DownloadAll total={props.total} loading={props.loading} download={download} />} />
  )
}

const Component = (props: CProps) => {
  const { accounts } = useMsal()
  const { username } = accounts[0] || {}
  const { teamContextValue } = useContext(TeamContext)
  const { queryParams } = useSearchQuery<CompaniesPageParams, { modifyProps: [{ dealsChecked?: IncludeDealsType[] }] }>(['dealsChecked'])

  const { setHeight, onPageSizeChange } = props
  const { sort, rowsPerPage = '10', keyword, perspective, and, viewMode, dealsChecked = [], includeTags, excludeTags } = queryParams

  const interactionsPeriodParams = useInteractionsPeriodEndpointParams(queryParams)
  const sortStatsParams = useSortStatsEndpointParams(sort)
  const tagsParams = useTagsEndpointParams('companies', includeTags, excludeTags)

  const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)

  const params: { name: string; value?: string | number | boolean }[] = [
    { name: 'teamNumber', value: teamContextValue?.teamNumber },
    { name: 'WhereNoneNextFutureMeeting', value: !!and },
    { name: 'IncludeStats', value: true },
    { name: 'WhereDidDealWithUs', value: dealsChecked.includes('hadDeal') },
    { name: 'WithCompanyTags', value: true },
    ...sortStatsParams,
    ...interactionsPeriodParams,
    ...tagsParams
  ]

  const payload = params.filter(({ value }) => !!value).reduce((acc, { name, value }) => ({ ...acc, [name]: `${value}` }), {})

  const url = username && perspective ? mergeUrlWithParams(perspective === 'User' ? `/users/${username}/companies` : '/companies', payload) : null

  const download = {
    fileName: perspective === 'User' ? 'user_companies' : 'companies',
    requestBinary: (data: DownloadBulkParams, excludeFormerEmployees: boolean) =>
      getBinary('/companies/xl', {
        ...(perspective === 'User' ? { relationOfUserKey: username } : {}),
        ...payload,
        ...data,
        numAliases: '5',
        numUrls: '5',
        numEmployees: '5',
        excludeFormerRoles: excludeFormerEmployees.toString()
      })
  }

  const onResize = (containerSize: ElementSize) => {
    if (setHeight && containerSize?.height) {
      setHeight(window.innerHeight - 89 > containerSize?.height + gridToolsHeight ? containerSize?.height + gridToolsHeight : 0)
    }
  }

  return (
    <DynamicEntity<AddProps>
      url={url}
      pageSize={+rowsPerPage}
      updatePageSize={onPageSizeChange}
      addprops={{
        sort,
        onResize,
        enableDownloading: props.enableDownloading,
        updateSort: props.updateSort,
        viewMode: viewMode || 'collapsed',
        sortByField: sortByField || 'score',
        columns: props.columns,
        download
      }}
      component={Companies}
      onLoading={props.onLoading}
      autoHideOnScroll
      list
      infinite
      search
      keepMounted
      empty="No results found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      emptyAction={<MarketDataSearchLink entity="company" keyword={keyword} />}
      id="companies_list"
    />
  )
}

const CompaniesPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const { queryParams, updateQuery } = useSearchQuery<CompaniesPageParams, { modifyProps: [{ dealsChecked?: IncludeDealsType[] }] }>(['dealsChecked'])

  const [height, setHeight] = useState<number>()
  const [total, setTotal] = useState<number>()
  const [contentLoading, setContentLoading] = useState<boolean>(false)

  const { dataVisibility } = useUserDataVisibility()
  const { allowFilteringByInteractions, preventNonAdminDownload } = dataVisibility || {}

  const admin = useAdmin()
  const enableDownloading = useEnableDownloading(admin, preventNonAdminDownload)

  const {
    setInitial,
    reset,
    interactionsFiltersShown,
    interactionsColumnsShown,
    handleChange,
    toggleDealSwitch,
    toggleInteractionSwitch,
    loading: filtersLoading,
    opened,
    toggleOpen: toggleFilterOpen
  } = useCompaniesUserSettings({ allowFilteringByInteractions })

  const disabled = filtersLoading || interactionsFiltersShown === null

  const { sort, viewMode, excludeEmpty } = queryParams

  useEffect(() => {
    setMobileHeader('Companies')
  }, [setMobileHeader])

  useEffect(() => {
    window.scrollTo({ top: window.scrollY < underTopBarPos ? window.scrollY : underTopBarPos })
  }, [total])

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    handleChange({ rowsPerPage })
  }

  const updateSort = (sort: ScoreType | StatSortType) => {
    handleChange({ sort })
  }

  const toggleExclude = () => {
    updateQuery({ excludeEmpty: excludeEmpty === 'true' ? 'false' : 'true' })
  }

  const updateViewMode = (viewMode: ViewModeType) => {
    handleChange({ viewMode })
  }

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    setContentLoading(loading)
    setTotal(result?.total_item_count)
  }

  const filtersProps = {
    toggleOpen: toggleFilterOpen,
    interactionsFiltersShown: !!interactionsFiltersShown,
    disabled: disabled,
    opened,
    total,
    contentLoading,
    reset,
    queryData: queryParams,
    handleChange,
    toggleDealSwitch,
    toggleInteractionSwitch
  }

  const columns = useMemo(
    () =>
      [
        { column: nameColumn },
        { column: keyContactColumn },
        { column: bestIntroducerColumn },
        { column: lastInboundColumn, condition: !!interactionsColumnsShown },
        { column: lastOutboundColumn, condition: !!interactionsColumnsShown },
        { column: lastMeetingColumn, condition: !!interactionsColumnsShown },
        { column: nextFutureMeetingColumn, condition: !!interactionsColumnsShown },
        { column: scoreColumn }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || !!condition)
        .map(({ column }) => column),
    [interactionsColumnsShown]
  )

  const sortItems = columns.filter(({ sortable }) => sortable).map(({ headerName, field }) => ({ label: headerName || '', field }))

  const searchPlaceholder = 'Search for companies'
  return (
    <UserSettings endpoint="/usersettings/companiessearchfilter" setInitial={setInitial}>
      <GridPageFrame
        stickFilters
        loading={typeof total !== 'number'}
        filterHeight={height}
        gridTitle="Companies"
        searchPlaceholder={searchPlaceholder}
        disabledSearch={disabled}
        filters={<Filters {...filtersProps} />}
        heading={
          <Heading
            filtersProps={{
              opened: filtersProps.opened,
              toggleOpen: filtersProps.toggleOpen,
              interactionsFiltersShown: filtersProps.interactionsFiltersShown,
              disabled: filtersProps.disabled
            }}
            filters={<Filters {...filtersProps} />}
            sortProps={{ value: sort, update: updateSort, items: sortItems, toggleExclude, excludeEmpty: excludeEmpty === 'true' }}
            viewProps={{ viewMode, updateViewMode }}
            searchPlaceholder={searchPlaceholder}
          />
        }
        headingAdornmentEnd={GridHeadingButtons}
        component={
          <Component
            enableDownloading={enableDownloading}
            columns={columns}
            setHeight={setHeight}
            onLoading={onLoading}
            updateSort={updateSort}
            onPageSizeChange={onPageSizeChange}
          />
        }
      />
    </UserSettings>
  )
}

export default CompaniesPage
