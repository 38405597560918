import React, { useEffect, useState, useContext, ChangeEvent, useCallback, HTMLAttributes } from 'react'

import { Box, List, CircularProgress, Tabs, Tab } from '@mui/material'
import { parsePhoneNumber } from 'libphonenumber-js'
import { useParams, useHistory } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { TeamContext } from '_core/context/TeamContext'

import { Button, IconButton } from '_shared/buttons'
import Combobox from '_shared/forms/Combobox'
import Typography from '_shared/Typography'

import FormLayout, { FormLayoutActions, FormLayoutContent } from '_core/components/FormLayout'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import ProfilePreview from '_core/components/ProfilePreview'
import Topbar from '_core/components/Topbar'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { useLookUpPeople, useLookUpCompanies } from '_core/hooks/useLookup'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanelClose from '_core/hooks/useSidepanelClose'

import { mergeUrlWithParams, post } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

import { SidepanelNarrow, Narrow, SidepanelWide, Columns, Column } from './layout'

const useStyles = makeStyles<Partial<{ sidepanel: boolean }> | void>()((theme, params) => ({
  autoCompleteWrapper: {
    '& .MuiInputBase-input': {
      '& > div': {
        padding: '6.5px 13.5px',
        '& > p': {
          bottom: 'unset'
        }
      }
    }
  },
  profile: {
    '& > div': {
      paddingLeft: 0
    }
  },
  list: {
    width: '100%',
    overflowY: 'auto',
    boxSizing: 'border-box'
  },
  columns: {
    width: '100%',
    height: '100%',
    margin: 0,
    flex: 1
  },
  content: {
    padding: `0px 0px 0px ${theme.spacing(2)}`,
    height: `calc(100vh - ${params?.sidepanel ? 145.5 : 117.5}px)`,
    marginTop: 0
  }
}))

type OptionType = {
  name: string
  md5: string
  userKey?: string
  logoUrl?: string
  email?: string
  phone?: string
  phoneType?: string
  company?: { name: string; jobTitle: string }
}

const transformData = (data: PeopleListItem | CompaniesListItem): OptionType => {
  if ('PersonMd5' in data) {
    return {
      md5: data.PersonMd5,
      userKey: data.BestEmailAddrText,
      name: data.PersonNameText || data.BestEmailAddrText,
      email: data.BestEmailAddrText,
      phone: data.BestPhoneText,
      phoneType: data.BestPhoneType,
      company: {
        name: data.BestJobMatchedCompanyName || data.BestJobCorpLevelCompanyName || '',
        jobTitle: data.BestJobTitleText || ''
      }
    }
  }

  return {
    md5: data.CompanyMd5,
    logoUrl: data.BestUrlText,
    name: data.CompanyNameText || data.BestUrlText
  }
}

export type Params = {
  entity: Entities
}

const Item = ({ loading, handleDelete, ...member }: { loading: boolean; handleDelete: (value: OptionType) => void } & OptionType) => {
  const {
    classes: { profile }
  } = useStyles()

  const handleDeleteClick = () => {
    handleDelete(member)
  }

  return (
    <>
      {loading && (
        <Box className={profile}>
          <ProfileItem
            name={member.name}
            userKey={member.email}
            logoUrl={member.logoUrl}
            byline={member.email || member.logoUrl}
            byline2={member.phone}
          />
        </Box>
      )}
      {!loading && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box className={profile} maxWidth="calc(100% - 40px)">
            <ProfileItem
              name={member.name}
              userKey={member.email}
              logoUrl={member.logoUrl}
              byline={member.email || member.logoUrl}
              byline2={member.phone}
            />
          </Box>
          <IconButton onClick={handleDeleteClick} icon={['far', 'times']} size="small" />
        </Box>
      )}
    </>
  )
}

const Picker = (props: {
  loading: boolean
  handleSelect: (e: ChangeEvent<{}>, item: OptionType[]) => void
  handleDelete: (value: OptionType) => void
  membersList: OptionType[]
}) => {
  const { loading, handleDelete, membersList } = props
  const { lookupPeople, forceAbort: forcePeopleAbort } = useLookUpPeople()
  const { lookupCompanies, forceAbort: forceCompaniesAbort } = useLookUpCompanies()

  const { entity } = useParams<{ entity: Entities; id: string }>()

  const entitiesMap = {
    people: { lookUp: lookupPeople, forceAbort: forcePeopleAbort },
    companies: { lookUp: lookupCompanies, forceAbort: forceCompaniesAbort }
  }

  const { lookUp, forceAbort } = entitiesMap[entity]

  const { inputValue, open, options, optionsLoading, handleClose, handleOpen, handleFocus, handleInputChange, filterOptions } = useAsyncCombobox<
    OptionType,
    true
  >({
    loadOptions: useCallback(
      async (inptValue: string) => {
        const items = await lookUp(
          inptValue,
          membersList.map(({ md5 }) => md5)
        )
        return items?.map((item) => transformData(item))
      },
      [lookUp, membersList]
    ),
    forceAbort
  })

  const {
    classes: { list, autoCompleteWrapper }
  } = useStyles()

  const handleSelect = (e: ChangeEvent<{}>, items: OptionType[]) => {
    props.handleSelect(e, items)
  }

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: OptionType) => (
    <li {...props}>
      <ProfileItem
        name={option.name}
        byline={option.company?.jobTitle || option.logoUrl}
        byline2={option.company?.name}
        logoUrl={option.logoUrl}
        userKey={option.userKey}
      />
    </li>
  )

  const getOptionLabel = (option: OptionType) => option.name

  return (
    <>
      <Typography variant="h3" style={{ marginBottom: 24 }} semiBold>
        Pick {entity}
      </Typography>
      <Box mb={-1}>
        <Box className={autoCompleteWrapper}>
          <Combobox<OptionType, true>
            multiple
            autoFocus
            inputValue={inputValue}
            open={open}
            options={options}
            value={membersList}
            placeholder="Search"
            loading={optionsLoading}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            onChange={handleSelect}
            onClose={handleClose}
            onOpen={handleOpen}
            onFocus={handleFocus}
            onInputChange={handleInputChange}
            filterOptions={filterOptions}
          />
        </Box>
      </Box>
      <List className={list}>
        <Repeater
          direction="vertical"
          component={Item}
          skeleton={{ size: 1, loading }}
          items={membersList?.map((item) => ({
            ...item,
            handleDelete,
            loading
          }))}
        />
      </List>
    </>
  )
}

const Preview = ({ loading, membersList }: { loading: boolean; membersList: OptionType[] }) => (
  <ProfilePreview
    loading={loading}
    name={membersList?.map((member) => member.name)[0]}
    emails={membersList.map((member) => member.email).filter((email): email is string => !!email)}
    websites={membersList.map((member) => member.logoUrl).filter((logoUrl): logoUrl is string => !!logoUrl)}
    titles={membersList.map((member) => member.company?.jobTitle).filter((jobTitle): jobTitle is string => !!jobTitle)}
    phones={membersList
      .map((member) => {
        if (!member.phone) return null

        const phoneNumber = parsePhoneNumber(member.phone)?.formatInternational()
        const phoneType = member.phoneType ? member.phoneType[0].toUpperCase() : 'O'
        return `${phoneType} ${phoneNumber}`
      })
      .filter((phone): phone is string => !!phone)}
  />
)

const MergeFlow = (props: any) => {
  const sidepanel = isSidepanel()
  const {
    classes: { columns, content }
  } = useStyles({ sidepanel })

  const { queryParams } = useSearchQuery<MergePageParams>()
  const { entity } = useParams<Params>()
  const history = useHistory()

  const { teamContextValue } = useContext(TeamContext)
  const { setMobileHeader } = useContext(LayoutContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [membersList, setMembersList] = useState<OptionType[]>([])
  const [tabIndex, setTabIndex] = useState<number>(0)
  const handleClose = useSidepanelClose()

  useEffect(() => {
    setMobileHeader('Merge')
  }, [setMobileHeader])

  useEffect(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    if (!props.loading && queryParams.ids) {
      const ids = queryParams.ids.split(',')
      const list = ids.map((id: string) => {
        const data = transformData(props[id].data[0])
        return data
      })
      setMembersList(list)
    }
  }, [props.loading, queryParams.ids])

  const handleMerge = async () => {
    setLoading(true)
    await post(
      mergeUrlWithParams(`/${entity}/mergemulti`, { teamNumber: `${teamContextValue.teamNumber}` }),
      membersList.map(({ md5 }) => md5)
    )
    setLoading(false)
    history.push(`${Paths._merge}/${entity}/success`)
  }

  const handleSelect = (e: ChangeEvent<{}>, items: OptionType[]) => {
    setMembersList(items)
  }

  const handleDelete = (row: OptionType) => {
    setMembersList(membersList.filter(({ md5 }: { md5: string }) => md5 !== row.md5))
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue)
  }

  const ActionButtons = (
    <FormLayoutActions>
      <Button variant="text" color="secondary" onClick={handleClose} disabled={props.loading}>
        Cancel
      </Button>
      <Button variant="text" onClick={handleMerge} disabled={props.loading || membersList.length <= 1} disablePR>
        Merge
      </Button>
    </FormLayoutActions>
  )

  const tabs = [
    {
      label: 'PROFILE LIST',
      component: <Picker membersList={membersList} loading={props.loading} handleSelect={handleSelect} handleDelete={handleDelete} />
    },
    {
      label: 'PREVIEW',
      component: <Preview membersList={membersList} loading={props.loading} />
    }
  ]

  return (
    <>
      {loading && (
        <Box width="100vw" height="100vh" display="flex" justifyContent="center" py={25}>
          <CircularProgress />
        </Box>
      )}

      {!loading && (
        <FormLayout>
          <Narrow>
            <Topbar sub={membersList[0]?.name} nativeBack={true} />
          </Narrow>
          <SidepanelWide>
            <FormLayoutContent className={content}>
              <Columns className={columns} spacing={0}>
                <Column xs={8}>
                  <Box pt={2} pr={2} boxSizing="border-box" flex={1}>
                    <Picker membersList={membersList} loading={props.loading} handleSelect={handleSelect} handleDelete={handleDelete} />
                  </Box>
                </Column>
                <Column xs={4} style={{ padding: 0 }}>
                  <Box height={1}>
                    <Preview membersList={membersList} loading={props.loading} />
                  </Box>
                </Column>
              </Columns>
            </FormLayoutContent>

            {ActionButtons}
          </SidepanelWide>
          <SidepanelNarrow>
            <FormLayoutContent>
              <Tabs
                value={tabIndex > -1 ? tabIndex : false}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                {tabs.map(({ label }, idx) => (
                  <Tab key={idx} label={label} />
                ))}
              </Tabs>
              <Box>{tabs[tabIndex].component}</Box>
            </FormLayoutContent>
            {ActionButtons}
          </SidepanelNarrow>
        </FormLayout>
      )}
    </>
  )
}

export default MergeFlow
