import React from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import AuditColumns from '_core/components/audit/AuditLayout'
import { sourceTypesMap } from '_core/components/audit/AuditSources'
import InnerDialog from '_core/components/InnerDialog'
import Repeater from '_core/components/lists/Repeater'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'grid',
    alignItems: 'center',
    gap: theme.spacing(1),
    gridTemplateColumns: `${theme.spacing(2)} minmax(0,auto) minmax(min-content,1fr)`
  },
  icon: {
    color: theme.palette.text.secondary
  }
}))

const Item = (item: AuditAnomalousTupleSource) => {
  const { classes } = useStyles()

  const { icon, text } = item.sourceType ? sourceTypesMap[item.sourceType] : { icon: ['far', 'user'] as IconProp, text: 'data' }
  return (
    <Box className={classes.wrapper}>
      <FontAwesomeIcon icon={icon} size="sm" className={classes.icon} />
      <Tooltip title={item.userKey}>
        <Typography noWrap>{item.userKey}&apos;s</Typography>
      </Tooltip>
      <Typography noWrap>{text.toLowerCase()}</Typography>
    </Box>
  )
}

const TupleSources = ({
  lowText,
  highText,
  sources,
  close
}: Pick<AuditAnomalousTuple, 'lowText' | 'highText' | 'sources'> & { close: () => void }) => {
  const { classes } = useStyles()

  return (
    <InnerDialog close={close}>
      <AuditColumns.Column
        heading={
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <FontAwesomeIcon icon={['far', 'object-ungroup']} size="2x" className={classes.icon} />
            </Grid>
            <Grid item xs={10}>
              <b style={{ wordBreak: 'break-all' }}>{lowText}</b> is directly associated with <b style={{ wordBreak: 'break-all' }}>{highText}</b>{' '}
              based on evidence in the following sources.
            </Grid>
          </Grid>
        }
      >
        <Repeater
          direction="vertical"
          variant="border"
          component={Item}
          skeleton={{ size: 10, loading: false }}
          items={sources.filter((source) => !('forQuickSplit' in source))}
        />
      </AuditColumns.Column>
    </InnerDialog>
  )
}

export default TupleSources
