import React, { useContext, useEffect } from 'react'

import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import IdentifiersPicker from '_core/components/audit/IdentifierPicker'
import InvalidTupleSuccess from '_core/components/audit/InvalidTupleSuccess'
import TuplesChainList, { TuplesChainProps } from '_core/components/audit/TuplesChainList'
import AudiTupleInvalidDialog from '_core/components/dialogs/AuditTupleInvalid'
import Topbar from '_core/components/Topbar'

import useDialog from '_core/hooks/useDialog'
import { useSplitMulti } from '_core/hooks/useSplitMulti'

import DynamicEntity from '_core/DynamicEntity'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const Content = () => {
  const { id, sourceId, targetId } = useParams<TuplesPageParams>()
  const { teamContextValue } = useContext(TeamContext)
  const history = useHistory()
  const { openDialog, closeDialog, isDialogOpened } = useDialog()
  const { checkedTuples, setCheckedTuples, submitInvalid } = useSplitMulti('/people/splitmulti')
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Tuple paths')
  }, [setMobileHeader])

  const selectIdentifier = (source: string, target: string) => {
    history.push(`${Paths._people}/${id}/audit/identifiers/tuples-chain/${source}/${target}`)
  }

  return (
    <>
      <Topbar nativeBack action={<AudiTupleInvalidDialog.TriggerEl disabled={!checkedTuples.length} open={openDialog} />} />
      <IdentifiersPicker lookUpEndpoint={`/people/${id}/auditinfo`} selectIdentifier={selectIdentifier} />
      <DynamicEntity<{
        extraProps: {
          addprops: Pick<TuplesChainProps, 'checkedTuples' | 'setCheckedTuples'>
        }
      }>
        addprops={{ checkedTuples, setCheckedTuples }}
        url={mergeUrlWithParams(`/dataquality/persontuplepaths/${sourceId}/to/${targetId}`, { teamNumber: `${teamContextValue.teamNumber}` })}
        pageSize={10}
        keepMounted
        search
        list
        infinite
        component={TuplesChainList}
        id="person_tuples_chain"
      />
      <AudiTupleInvalidDialog close={closeDialog} submit={submitInvalid} isOpened={isDialogOpened} />
    </>
  )
}

const SuccessPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Audit tuples')
  }, [setMobileHeader])

  return (
    <>
      <Topbar nativeBack />
      <InvalidTupleSuccess />
    </>
  )
}

const TuplesChainPage = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route path={`${path}/success`} component={SuccessPage} />
        <Route path={path} component={Content} />
      </Switch>
    </>
  )
}

export default TuplesChainPage
