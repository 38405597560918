import { GridSortDirection, GridSortItem } from '@mui/x-data-grid-pro'

import Sort, { SortProps } from './index'

type SortMapType = {
  [key in GridSortItem['field']]: { [key in Exclude<GridSortDirection, undefined | null>]: ManualEditsSortType }
}

export const sortMap: SortMapType = {
  date: {
    asc: 'EditedWhenAsc',
    desc: 'EditedWhenDesc'
  }
}

export const sortProperties: { [key: string]: ManualEditsInit['sortProperty'] } = {
  date: 'AdminEditedWhen'
}

export const getSortField = (sort: ManualEditsSortType | undefined) => {
  return Object.keys(sortMap).find((sortKey) => sort === sortMap[sortKey].asc || sort === sortMap[sortKey].desc) || 'date'
}

export const getSortProperty = (sort: ManualEditsSortType): ManualEditsInit['sortProperty'] => {
  const field = getSortField(sort)
  return sortProperties[field]
}

export type ManualEditsSortProps = {
  items: {
    label: string
    field: string
  }[]
  value?: ManualEditsSortType
  update: (val: ManualEditsSortType) => void
} & Omit<SortProps, 'children'>

const ManualEditsSort = (props: ManualEditsSortProps) => {
  const appliedSort = getSortField(props.value)

  const handleClick = (field: string) => {
    const newSort = props.value === sortMap[field].desc ? sortMap[field].asc : sortMap[field].desc
    if (newSort) props.update(newSort)
  }

  return (
    <Sort className={props.className} collapsed={props.collapsed}>
      {props.items.map((item) => (
        <Sort.Chip
          label={item.label}
          key={item.label}
          active={appliedSort === item.field}
          desc={props.value === sortMap[item.field].desc}
          onClick={() => handleClick(item.field)}
        />
      ))}
    </Sort>
  )
}

export { Controller } from './index'
export default ManualEditsSort
