import { GridSortItem } from '@mui/x-data-grid-pro'

import Sort, { SortProps } from './index'

export type MeetingsSortProps = {
  items: {
    label: string
    field: string
  }[]
  sort?: SortType
  updateSort: (val: SortType) => void
} & Omit<SortProps, 'children'>

export const sortMap: {
  [key in GridSortItem['field']]: {
    asc: SortType
    desc: SortType
  }
} = {
  startDate: {
    asc: 'OldestToNewest',
    desc: 'NewestToOldest'
  }
}

const MeetingsSort = (props: MeetingsSortProps) => {
  const appliedSort = Object.keys(sortMap).find((key) => sortMap[key].asc === props.sort || sortMap[key].desc === props.sort)

  const updateSort = (field: string) => {
    const newSort = props.sort === sortMap[field].desc ? sortMap[field].asc : sortMap[field].desc
    if (newSort) props.updateSort(newSort)
  }

  return (
    <Sort className={props.className} collapsed={props.collapsed}>
      {props.items.map((item) => (
        <Sort.Chip
          label={item.label}
          key={item.label}
          active={appliedSort === item.field}
          desc={props.sort === sortMap[item.field].desc}
          onClick={() => updateSort(item.field)}
        />
      ))}
    </Sort>
  )
}

export default MeetingsSort
