import { ComponentProps, useContext, useEffect, useMemo, useState } from 'react'

import { matchPath, useLocation, useParams } from 'react-router-dom'

import Profile from '_pages/companies/[id]/profile'

import { TeamContext } from '_core/context/TeamContext'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useActivitiesStats from '_core/hooks/useActivitiesStats'
import useActivityStatsPeriod from '_core/hooks/useActivityStatsPeriod'
import useCompanyActivitiesPayloads from '_core/hooks/useCompanyActivitiesPayloads'
import useCompanyMarketData from '_core/hooks/useCompanyMarketData'
import useCSuite from '_core/hooks/useCSuite'
import useMarketDataIntegration from '_core/hooks/useMarketDataIntegration'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSimilarCompanies from '_core/hooks/useSimilarCompanies'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

import { getUTC } from 'utils/Utils'

import Paths from 'Paths'

const useCompanyProfile = () => {
  const { id } = useParams<{ id: string }>()
  const { pathname } = useLocation()

  const { queryParams } = useSearchQuery<CompanyProfilePageParams>()
  const { from: fromUTC, to: toUTC } = queryParams

  const { isExact: matchProfile } = matchPath(pathname, { path: Paths.companyProfile }) || {}
  const { isExact: matchMarketDataPages } =
    matchPath(pathname, {
      path: [`${Paths.companyProfile}/similar`, `${Paths.companyProfile}/c-suite`]
    }) || {}

  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue

  const { dataVisibility } = useUserDataVisibility([id])
  const { showInteractionsInProfile } = dataVisibility || {}

  const [profileLoading, setProfileLoading] = useState<boolean>(false)
  const [companyData, setCompanyData] = useState<Pick<CompanyType, 'CompanyMd5' | 'CompanyNameText' | 'BestUrlText'> | null>(null)

  const { CompanyMd5 = '', CompanyNameText, BestUrlText } = companyData || {}

  const companyName = CompanyNameText || BestUrlText
  const { name = '', website } = !profileLoading && !CompanyMd5 ? queryParams : { name: companyName, website: BestUrlText }

  const { enabled: enabledMarketData, showSimilarCompanies } = useMarketDataIntegration()
  const { marketData, getMarketData } = useCompanyMarketData(name, website)

  const { employeeCount, industry, location, type, website: marketDataWebsite } = marketData || {}

  const companyWebsite = website || marketDataWebsite || ''
  const hasValidFilters = marketData && companyWebsite
  const isConfigured = marketData && ((showSimilarCompanies && matchProfile) || (enabledMarketData && matchMarketDataPages))

  const initParams = {
    website: companyWebsite,
    minimumEmployeeCount: employeeCount ? Math.round(Number(employeeCount) / 2) : null,
    maximumEmployeeCount: employeeCount ? Math.round(Number(employeeCount) * 1.5) : null,
    industries: industry ? [industry] : [],
    countries: [location?.country || 'United States'],
    companyType: type || 'Private'
  }

  const { similar, getSimilar, getCompanyDetails } = useSimilarCompanies(CompanyMd5, initParams, !!isConfigured, !!hasValidFilters)
  const { cSuite, getCSuite, getPersonDetails } = useCSuite(CompanyMd5, companyWebsite, !!isConfigured)

  const { isExact: matchCompanyActivities = false } = matchPath(pathname, { path: `${Paths._companies}/:id/activities` }) || {}
  const { userActivitiesAccess } = useActivitiesAccess([id, matchCompanyActivities]) || {}
  const { isStatsWidgetVisible, isDetailedActivityFromStatsWidgetVisible } = userActivitiesAccess || {}

  const statsPayloads = useCompanyActivitiesPayloads(useMemo(() => [CompanyMd5], [CompanyMd5]))

  const activityStatsChartData = useActivityStatsPeriod({
    fromUTC: fromUTC ? getUTC(decodeURIComponent(fromUTC)) : null,
    toUTC: toUTC ? getUTC(decodeURIComponent(toUTC)) : null
  })

  const { stats: activityStats, clearStats } = useActivitiesStats(
    matchProfile && CompanyMd5 ? statsPayloads : null,
    useMemo(() => [CompanyMd5], [CompanyMd5]),
    useMemo(
      () => activityStatsChartData.months.map(({ year, month, minDay, maxDay }) => ({ year, month, minDay, maxDay })),
      [activityStatsChartData.months]
    )
  )

  const {
    fetchWithAbort: fetchInteractions,
    result: interactions,
    setResult: setInteractions,
    reset: resetInteractions
  } = useAbortableFetch<ComponentProps<typeof Profile>['interactions'] | null>()

  const {
    fetchWithAbort: fetchSalesforceUserSettings,
    result: salesforceUserSettings,
    reset: resetSalesforceUserSettings
  } = useAbortableFetch<{ isEnabled: boolean }>()

  useEffect(() => {
    return () => {
      if (CompanyMd5) {
        resetInteractions()
        resetSalesforceUserSettings()
        clearStats()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CompanyMd5])

  useEffect(() => {
    if (!salesforceUserSettings) {
      fetchSalesforceUserSettings({ url: '/usersettings/salesforce' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesforceUserSettings])

  useEffect(() => {
    if (matchProfile && CompanyMd5 && interactions === undefined) {
      if (showInteractionsInProfile) {
        fetchInteractions({ url: `/interactions?TeamNumber=${teamNumber}&companyMd5=${CompanyMd5}` })
      } else {
        setInteractions(null)
      }
    }
  }, [interactions, matchProfile, CompanyMd5, showInteractionsInProfile, teamNumber, fetchInteractions, setInteractions])

  useEffect(() => {
    if (!profileLoading && enabledMarketData && !marketData) {
      getMarketData()
    }
  }, [enabledMarketData, marketData, profileLoading, getMarketData])

  const onProfileLoading = (loading: boolean, response: RemoteData<CompanyType>) => {
    setProfileLoading(loading)
    if (response) {
      const { CompanyMd5, CompanyNameText, BestUrlText } = response.data[0]
      return setCompanyData({ CompanyMd5, CompanyNameText, BestUrlText })
    }
    setCompanyData(null)
  }

  return {
    onProfileLoading,
    activityStats,
    activityStatsChartData,
    isSalesforceEnabled: salesforceUserSettings?.isEnabled,
    showInteractionsInProfile,
    interactions,
    marketData,
    similar,
    getSimilar,
    getCompanyDetails,
    cSuite,
    getCSuite,
    getPersonDetails,
    enabledMarketData,
    showSimilarCompanies,
    isStatsWidgetVisible,
    isDetailedActivityFromStatsWidgetVisible
  }
}

export default useCompanyProfile
