import { useEffect } from 'react'

import { makeStyles } from 'tss-react/mui'

import Empty from '_core/components/Empty'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'

import useSearchQuery from '_core/hooks/useSearchQuery'

import { post } from 'utils/httpUtils'

const postLastSearch = (searchQuery: string) => {
  return post(`/savedsearches/${searchQuery}`)
}

const useStyles = makeStyles()(() => ({
  empty: {
    height: 'calc(100vh - 173px)'
  }
}))

const SearchResult = (props: any) => {
  const { classes } = useStyles()
  const items = props.items?.[0]?.data?.map((item: any) => {
    const md5 = item.PersonMd5 || item.CompanyMd5
    const type = item.PersonMd5 ? 'people' : 'companies'
    return {
      md5: md5,
      userKey: item.BestEmailAddrText,
      logoUrl: item.BestUrlText,
      name: item.PersonNameText || item.BestEmailAddrText || item.CompanyNameText || item.BestUrlText,
      email: item.BestEmailAddrText,
      byline: item.BestJobTitleText || item.BestUrlText || '',
      byline2: item.BestJobMatchedCompanyName || item.BestJobCorpLevelCompanyName || '',
      score: item.Score,
      isSelected: `/${type}/${md5}` === props.openedUrl,
      link: `/${type}/${md5}`,
      sidepanel: 'preview'
    }
  })
  const { queryParams } = useSearchQuery()
  const { keyword }: any = queryParams
  const { openUrl, loading, openedUrl } = props

  useEffect(() => {
    if (openedUrl === '/blank' && items?.length) {
      openUrl(items[0].link)
    }
  }, [items, openUrl, openedUrl])

  useEffect(() => {
    if (!loading) postLastSearch(keyword)
  }, [loading, keyword])

  if (items && items.length === 0 && !props.loading) {
    return <Empty title="No results found" subTitle={`No results were found for your search '${keyword}'`} className={classes.empty} />
  }

  return (
    <Repeater direction="vertical" component={ProfileItem} skeleton={{ size: 15, loading: props.loading }} variant="list" empty=" " items={items} />
  )
}

export default SearchResult
