import { ReactElement, useContext, useEffect } from 'react'

import { Box } from '@mui/material'
import { useParams, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'

import { isSidepanel } from '_pages/sidebar'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'

import AuditSources, { SourceFacts, SourceFactsType } from '_core/components/audit/AuditSources'
import IdentifierContributors from '_core/components/audit/IdentifierContributors'
import Empty from '_core/components/Empty'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import useAdminOrCuratorCheck from '_core/hooks/useAdminOrCuratorCheck'
import useAuditSources from '_core/hooks/useAuditSources'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import useOutlook from '_core/hooks/useOutlook'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const baseMatch = `${Paths._companies}/:id/aliasSources/:name`

const Sources = (props: { searchInput: ReactElement; emptyTitle: string; emptySubtitle: string; enabledOpenFacts: boolean }) => {
  const { key: contributor, type: graphSourceType, name: identifier } = useParams<{ type: SourceFactsType; key: string; name: string }>()
  const history = useHistory()
  const match = useRouteMatch()
  const { searchInput, emptyTitle, emptySubtitle } = props

  const { teamContextValue } = useContext(TeamContext)

  const { result, loading, more, reload } = useEntityEndpoint(
    `/audit/idcontributors?teamNumber=${teamContextValue.teamNumber}&identifier=${encodeURIComponent(identifier)}&entityType=Company`,
    null,
    5,
    true,
    true
  )

  const dataLength = result?.data?.length
  const contributors = result?.data?.map((item: any) => item.userKey)

  const sources = useAuditSources({ identifier, type: 'CompanyName' }, contributors, loading)

  const openFacts = (data: { type: SourceFactsType; key: string }) => {
    const { key, type } = data
    history.push(`${match.url}/${key}/${type}`)
  }

  const empty = !loading && typeof dataLength === 'number' && !dataLength

  return (
    <Switch>
      <Route path={`${baseMatch}/:key/:type`}>
        <SourceFacts
          contributor={contributor}
          requestType="CompanyName"
          graphSourceType={graphSourceType}
          identifier={encodeURIComponent(identifier)}
        />
      </Route>
      <Route exact path={baseMatch}>
        {searchInput}
        {empty && <Empty title={emptyTitle} subTitle={emptySubtitle} />}
        {!empty && (
          <AuditSources
            items={sources?.map((source) => ({ ...source, ...(props.enabledOpenFacts && { openFacts }) }))}
            loading={loading}
            more={more}
            reload={reload}
            hasMore={!!result?.are_more}
            total={result ? dataLength : 0}
          />
        )}
      </Route>
    </Switch>
  )
}

const CompanyAliasSourcesPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { name: identifier } = useParams<{ name: string }>()
  const isOutlook = useOutlook()
  const hasSidebar = isOutlook || isSidepanel()
  const { queryParams } = useSearchQuery<SearchParams>()
  const { isAdminOrCurator, admin } = useAdminOrCuratorCheck()

  useEffect(() => {
    setMobileHeader(identifier || '')
  }, [identifier, setMobileHeader])

  const emptyTitle = 'No alias contributors found'
  const emptySubtitle = `No results found ${queryParams.keyword ? `for your search "${queryParams.keyword}"` : ''}`

  return (
    <Page variant="core">
      <Narrow>
        <Topbar nativeBack sub={`${isAdminOrCurator ? 'Sources' : 'Contributors'}`} />
      </Narrow>
      <Box display="flex" flexDirection="column" height={`calc(100vh - ${hasSidebar ? 88 : 61}px)`}>
        {isAdminOrCurator ? (
          <Sources
            enabledOpenFacts={!!admin}
            searchInput={<IdentifierContributors.Search key={identifier} />}
            emptyTitle={emptyTitle}
            emptySubtitle={emptySubtitle}
          />
        ) : (
          <>
            <IdentifierContributors.Search key={identifier} />
            <DynamicEntity
              id="identifier_contributors"
              scrollableTarget="identifier_contributors"
              url={
                isAdminOrCurator === false ? `/companies/idcontributors?teamNumber=${teamNumber}&identifier=${encodeURIComponent(identifier)}` : ''
              }
              pageSize={20}
              empty={emptyTitle}
              emptySubtitle={emptySubtitle}
              search
              list
              infinite
              component={IdentifierContributors}
            />
          </>
        )}
      </Box>
    </Page>
  )
}

export default CompanyAliasSourcesPage
