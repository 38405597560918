import React, { ChangeEvent, ReactElement } from 'react'

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import Card, { CardContent } from '_shared/Card'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import AssociationListItem from '_core/components/audit/AssociationListItem'
import Repeater from '_core/components/lists/Repeater'

const useStyles = makeStyles()(() => ({
  details: {
    padding: 0
  }
}))

const TuplesChainItem = ({
  tuplesInfos,
  isSinglePath
}: {
  isSinglePath: boolean
  tuplesInfos: {
    id1: string
    id2: string
    checked: boolean
    toggleCheck: (e: ChangeEvent<HTMLInputElement>) => void
    actions: ReactElement
  }[]
}) => {
  const { classes } = useStyles()
  const count = tuplesInfos?.length || 0

  return (
    <Card round variant="outlined">
      <CardContent>
        <Accordion defaultExpanded={isSinglePath}>
          <AccordionSummary expandIcon={<IconButton icon={['far', 'chevron-down']} size="small" disablePadding />} aria-label="Expand">
            <Skeleton condition={!count}>
              <Typography color="text.secondary" noWrap>
                {count} {count > 1 ? 'tuples' : 'tuple'}
              </Typography>
            </Skeleton>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.details }}>
            <Repeater direction="vertical" component={AssociationListItem} skeleton={{ size: 2, loading: !count }} items={tuplesInfos} />
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  )
}

export default TuplesChainItem
