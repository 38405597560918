import { ComponentProps } from 'react'

import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import TitleDescription from '_core/components/TitleDescription'
import Widget from '_core/components/widgets'

const TouchpointsWidget = ({
  loading,
  titlePrefix,
  items
}: {
  loading: boolean
  titlePrefix: 'Latest' | 'First'
  items: ComponentProps<typeof TitleDescription>[]
}) => (
  <Widget scope="stack">
    <Heading title={`${titlePrefix} interactions`} underlined icon={['far', 'handshake']} />
    <Repeater component={TitleDescription} skeleton={{ size: 3, loading }} items={!loading ? items : []} />
  </Widget>
)

export default TouchpointsWidget
