import React from 'react'

import { Box, Chip as MuiChip, ChipProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => ({
  colorSecondary: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary
  },
  colorError: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.text.primary
  },
  colorSuccess: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.text.primary
  },
  colorWarning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.text.primary
  },
  clickable: {
    '&.MuiChip-colorSecondary:hover': {
      backgroundColor: theme.palette.secondary.light
    },
    '&.MuiChip-colorError:hover': {
      backgroundColor: theme.palette.error.light
    },
    '&.MuiChip-colorSuccess:hover': {
      backgroundColor: theme.palette.success.light
    },
    '&.MuiChip-colorWarning:hover': {
      backgroundColor: theme.palette.warning.light
    }
  },
  icon: {
    fontSize: 14,
    width: 14,
    maxWidth: 14,
    marginRight: theme.spacing(1)
  },
  status: {
    borderRadius: theme.spacing(0.5),
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '16px'
  }
}))

export const ChipLabel = ({ label, icon: Icon, noWrap }: { label: string; icon: React.ElementType; noWrap?: boolean }) => {
  const { classes } = useStyles()

  return (
    <Box display="flex" alignItems="center" overflow="hidden">
      <Icon iconClassName={classes.icon} />
      <Typography noWrap={noWrap}>{label}</Typography>
    </Box>
  )
}

export const FilterChipLabel = ({ label, value }: { label: string; value: string }) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography semiBold>{label}:</Typography>
      <Typography noWrap>{value}</Typography>
    </Box>
  )
}

export const StatusChip = ({ className, ...props }: ChipProps) => {
  const { cx, classes } = useStyles()

  return <Chip {...props} className={cx(classes.status, className)} />
}

const Chip = (props: ChipProps) => {
  const { classes } = useStyles()

  return (
    <MuiChip
      classes={{
        colorSecondary: classes.colorSecondary,
        colorError: classes.colorError,
        colorSuccess: classes.colorSuccess,
        colorWarning: classes.colorWarning,
        clickable: classes.clickable
      }}
      {...props}
    />
  )
}

export default Chip
