import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import Avatar from '_shared/Avatar'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import MarketDataSearchLink from '_core/components/MarketDataSearchLink'
import Widget, { SeeAllAction } from '_core/components/widgets'
import useStyles from '_core/components/widgets/styles'

import { PeopleType as SimilarPeopleType } from '_core/hooks/useSimilarPeople'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

const SimilarPeopleWidget = ({ similar, ...props }: { loading: boolean; name: string; similar: SimilarPeopleType | null; marketData: boolean }) => {
  const { id } = useParams<{ id: string }>()
  const { classes } = useStyles()

  const load = props.loading || !similar
  const showEmptyWidget = similar && similar.total === 0

  const items = similar?.data
    ? similar.data.slice(0, 5).map((person) => {
        return {
          name: person.fullName,
          score: person.personMd5 ? person.score : undefined,
          sidepanel: true,
          link: person.personMd5
            ? `${Paths._people}/${person.personMd5}`
            : mergeUrlWithParams(`${Paths._people}/${id}/similar/${person.fullName}`, {
                email: person.workEmail,
                company: person.jobCompanyName
              })
        }
      })
    : []

  return (
    <Widget mdFlex0>
      <Heading
        underlined
        title="Similar people"
        icon={['fas', 'diagram-venn']}
        count={similar?.total}
        action={<SeeAllAction sidepanel link={similar?.total && !showEmptyWidget ? `${Paths._people}/${id}/similar?name=${props.name}` : ''} />}
      />

      {!showEmptyWidget && (
        <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 5, loading: !props.marketData || load }} items={items} />
      )}

      {showEmptyWidget && (
        <Empty
          className={classes.empty}
          subTitle="No records were found"
          action={
            <Box display="flex" justifyContent="center">
              <MarketDataSearchLink entity="person" />
            </Box>
          }
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

export default SimilarPeopleWidget
