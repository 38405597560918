import React from 'react'

import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Typography from '_shared/Typography'

import Heading from '_core/components/Heading'
import ProfileLinkBox from '_core/components/ProfileLinkBox'
import Widget from '_core/components/widgets'

import { AssigneeType } from '_core/hooks/useAssignee'

import Paths from 'Paths'

type AssigneeProps = {
  loading: boolean
  querierIsLtnManager: boolean
  planUid: string
  assignee: AssigneeType
}

const useStyles = makeStyles()((theme) => ({
  heading: {
    margin: 0
  },
  wrapper: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr',
    gap: theme.spacing(2)
  },
  content: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'minmax(0, max-content) auto',
    justifyContent: 'end'
  }
}))

const AssigneeWidget = ({ querierIsLtnManager, planUid, assignee, loading }: AssigneeProps) => {
  const { classes } = useStyles()

  return (
    <Widget>
      <Box className={classes.wrapper}>
        <Heading underlined title="Assignee" icon={['fas', 'user']} className={classes.heading} />
        <Box className={classes.content}>
          {(assignee.displayName || loading) && (
            <ProfileLinkBox
              loading={loading}
              name={assignee.displayName}
              userKey={assignee.emailAddress}
              link={`${Paths._people}/${assignee.emailAddress}`}
            />
          )}

          {!loading && (
            <>
              {querierIsLtnManager && (
                <Link to={`${Paths._introductions}/${planUid}/assignee`}>
                  {assignee.displayName ? (
                    <Box ml={2}>
                      <IconButton icon={['far', 'edit']} color="primary" hint="Reassign manager" size="small" disablePadding />
                    </Box>
                  ) : (
                    <Button color="primary" variant="text" disablePR>
                      Pick
                    </Button>
                  )}
                </Link>
              )}
              {!assignee.displayName && !querierIsLtnManager && <Typography>No manager</Typography>}
            </>
          )}
        </Box>
      </Box>
    </Widget>
  )
}

export default AssigneeWidget
