import React, { ComponentProps } from 'react'

import { Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

import ExternalTag, { ExtraTagsPopover } from '_core/components/ExternalTag'
import TagsGroup from '_core/components/TagsGroup'

const useStyles = makeStyles()((theme) => ({
  tagsWrapper: {
    overflow: 'hidden',
    marginBottom: `-${theme.spacing(0.5)}`,
    flexWrap: 'wrap',
    rowGap: theme.spacing(0.5)
  }
}))

const MarketDataExternalTags = (props: {
  name: string
  title: string
  marketData: boolean
  items: ComponentProps<typeof ExternalTag>['tagData'][] | undefined
}) => {
  const theme = useTheme()
  const sidepanel = useMediaQuery(theme.breakpoints.up('sidepanel'))
  const {
    classes: { tagsWrapper }
  } = useStyles()
  const mocked = new Array(3).fill('')

  return (
    <Box mt={1}>
      <TagsGroup<ComponentProps<typeof ExternalTag>['tagData']>
        tagComponent={ExternalTag}
        className={tagsWrapper}
        max={sidepanel ? 2 : 1}
        items={!props.marketData ? mocked : props.items}
        title={<Typography bold>{props.title}</Typography>}
        renderShowAll={
          props.marketData
            ? ({ extraTagsAmount }: { extraTagsAmount: number }) => (
                <ExtraTagsPopover
                  tags={props.items?.length ? props.items : mocked}
                  title={props.title}
                  name={props.name}
                  triggerElement={
                    <Box display="inline-flex" alignItems="center" height="100%">
                      <Typography noWrap>{extraTagsAmount} more</Typography>
                    </Box>
                  }
                />
              )
            : undefined
        }
      />
    </Box>
  )
}

export default MarketDataExternalTags
