import React from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import { IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import StatusChip, { StatusType } from '_core/components/introductions/StatusChip'
import ProfileLinkBox from '_core/components/ProfileLinkBox'
import { SummaryContent } from '_core/components/SummaryItem'
import Widget from '_core/components/widgets'

import { PersonType } from '_core/hooks/useActiveDirectoryPersonData'
import { BeneficiaryType } from '_core/hooks/useIntroductionRequestForm'

import { formatDate, formatDateTime } from 'utils/Utils'

import Paths from 'Paths'

type BeneficiaryEntity = { name: string; url: string }

export type ActionType = { name: string; icon: IconProp; condition: boolean } & (
  | { action: () => void; link?: never }
  | { action?: never; link: string }
)

type MainInfoWidgetProps = {
  loading: boolean
  headline: string
  reason: string
  description: string
  created: string
  creator: PersonType
  requester: PersonType
  beneficiary: BeneficiaryEntity
  beneficiaryType: BeneficiaryType
  actions: ActionType[]
  status: StatusType | undefined
}

const MainInfoWidget = ({
  status,
  reason,
  loading,
  actions,
  headline,
  description,
  created,
  creator,
  requester,
  beneficiary,
  beneficiaryType
}: MainInfoWidgetProps) => {
  return (
    <Widget scope="none">
      <Box mb={2}>
        <StatusChip status={loading ? undefined : status} />
      </Box>
      <Box mb={2}>
        <SummaryContent
          priorLine={reason}
          title={loading ? '' : headline}
          byline={loading ? '' : description}
          byline2={
            <Box display="flex" alignItems="center" mb={0.5}>
              <Skeleton condition={loading} width={80} height={24}>
                <Tooltip title={formatDateTime(created)}>
                  <Typography color="text.secondary" semiBold>
                    {formatDate(created)}
                  </Typography>
                </Tooltip>
              </Skeleton>
            </Box>
          }
          score={-1}
        />
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(120px, 1fr))" gap={2} mb={2}>
        {(loading || beneficiary?.name) && (
          <>
            <ProfileLinkBox
              heading="Requester"
              loading={loading}
              name={requester.displayName}
              userKey={requester.emailAddress}
              link={`${Paths._people}/${requester.emailAddress}`}
            />
            {beneficiaryType === 'company' ? (
              <ProfileLinkBox
                heading="Beneficiary"
                loading={loading}
                name={beneficiary?.name}
                logoUrl={beneficiary?.url}
                link={`${Paths._companies}/${beneficiary?.url}`}
              />
            ) : (
              <ProfileLinkBox
                heading="Beneficiary"
                loading={loading}
                name={beneficiary?.name}
                logoUrl={beneficiary?.url}
                link={`${Paths._people}/${beneficiary?.url}`}
              />
            )}
          </>
        )}
        {creator.displayName && (
          <ProfileLinkBox
            heading="Creator"
            loading={loading}
            name={creator.displayName}
            userKey={creator.emailAddress}
            link={`${Paths._people}/${creator.emailAddress}`}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        {actions.map((action) => (
          <Box key={action.name}>
            {!loading && action.link ? (
              <Link to={action.link}>
                <IconButton color="primary" hint={action.name} icon={action.icon} size="small" />
              </Link>
            ) : (
              <IconButton color="primary" hint={action.name} loading={loading} icon={action.icon} onClick={action.action} size="small" />
            )}
          </Box>
        ))}
      </Box>
    </Widget>
  )
}

export default MainInfoWidget
