import React, { useState } from 'react'

import { Box, useMediaQuery } from '@mui/material'
import MUIAvatar from '@mui/material/Avatar'
import { TypographyProps } from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Tooltip from '_shared/Tooltip'

import useAvatarImage from '_core/hooks/useAvatarImage'

import { ellipsis } from 'AppTheme'

import Badge from './Badge'
import Skeleton from './Skeleton'
import Typography from './Typography'

const transformedName = (name: AvatarProps['name'] = 'Dummy name', nameMode: AvatarProps['nameMode']) => {
  if (nameMode === 'inline') {
    return name
  }

  const parts = name.split(' ')

  const allSingleLetterParts = parts.every((part) => part.length === 1)
  if (allSingleLetterParts) {
    return name // Return the string when all parts have 1 letter like "E V E R C O R E"
  }

  // If there are only 2 parts, return them on separate lines:
  if (parts.length === 2) {
    return parts.join('\n')
  }

  if (parts.length === 3) {
    // If 2d part less or equal that 2 characters, then insert a newline after the 1 part
    if (parts[1].length < 2 || parts[1].length === 2) {
      const [first, ...rest] = parts
      return [first, '\n', rest.join(' ')].join('')
    } else {
      return parts.join('\n')
    }
  }

  if (parts.length > 3) {
    // If 3d part less or equal that 2 characters, then show 3d and 4d parts together otherwise show only 3d one
    return [parts[0], '\n', parts[1], '\n', parts[2] + ' ', parts[2].length < 2 || parts[2].length === 2 ? parts[3] + ' ' : '', '...'].join('')
  }

  return name
}

const getMaxWidth = (width: number, shape: AvatarProps['shape']) => (shape === 'circle' ? width : '100%')

const getInitials = (name: string) => {
  if (!name) return null

  const trimmedName = name.trim()
  const words = trimmedName.split(/\s/gi).filter((w) => w)

  if (words.length === 1) return name[0]

  return words[0][0] + words[words.length - 1][0]
}

const useStyles = makeStyles<{
  mode?: AvatarProps['mode']
  size: AvatarProps['size']
  shape?: AvatarProps['shape']
}>()((theme, { mode, size, shape }) => ({
  wrapper: {
    display: 'flex',
    '@media (min-height:160px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '100%'
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100%'
  },
  rootLg: {
    [theme.breakpoints.up('md')]: {
      padding: '16px 0'
    }
  },
  name: {
    marginTop: {
      lg: theme.spacing(2),
      md: theme.spacing(1),
      sm: theme.spacing(0.5),
      xs: theme.spacing(0.5)
    }[size || 'sm'],
    marginBottom: {
      lg: theme.spacing(1),
      md: theme.spacing(0.5),
      sm: theme.spacing(0),
      xs: theme.spacing(0)
    }[size || 'sm'],
    textAlign: 'center',
    fontSize: { lg: 18, md: 16, sm: 14, xs: 12 }[size || 'sm'],
    marginLeft: 0,
    maxWidth: '100%',
    ...ellipsis,
    whiteSpace: 'pre' //text will only wrap on line breaks
  },
  avatar: {
    color: mode === 'dark' ? '#fff' : '#1684A7',
    backgroundColor: mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.light,
    textTransform: 'uppercase',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)'
  },
  ellipse: {
    borderRadius: theme.spacing(12.5)
  },
  lg: {
    minWidth: 80,
    maxWidth: getMaxWidth(80, shape),
    height: 80,
    fontSize: 36
  },
  md: {
    minWidth: 52,
    maxWidth: getMaxWidth(52, shape),
    height: 52,
    fontSize: 28
  },
  sm: {
    minWidth: 48,
    maxWidth: getMaxWidth(48, shape),
    height: 48,
    fontSize: 21
  },
  xs: {
    minWidth: 24,
    maxWidth: getMaxWidth(24, shape),
    height: 24,
    fontSize: 12
  },
  image: {
    backgroundColor: 'transparent !important'
  }
}))

export const AvatarWrapper = ({ userKey, logoUrl = '', ...props }: AvatarProps & { userKey: string | undefined; logoUrl: string | undefined }) => {
  return <Avatar {...(userKey ? { userKey } : { logoUrl })} {...props} />
}

export const AvatarText = ({
  name,
  size,
  nameMode = 'multiline',
  color
}: {
  name: string
  size: AvatarProps['size']
  nameMode?: AvatarProps['nameMode']
  color?: AvatarProps['color']
}) => {
  const { classes } = useStyles({ size })
  return (
    <Skeleton condition={!name} width={size === 'lg' ? '240px' : '100%'} height={nameMode === 'multiline' ? 56 : 24}>
      <Tooltip title={name}>
        <Typography variant={size === 'lg' ? 'h4' : 'body1'} className={classes.name} color={color || 'text.primary'}>
          {transformedName(name, nameMode)}
        </Typography>
      </Tooltip>
    </Skeleton>
  )
}

const Avatar = ({
  name,
  logoUrl,
  userKey,
  score,
  size,
  mode,
  children,
  link,
  hideName,
  onClick,
  nameMode = 'multiline',
  shape = 'circle',
  color
}: AvatarProps & (ImageAvatarProps | ContentAvatarProps)) => {
  const { classes, cx } = useStyles({ mode, size, shape })
  const [isError, setIsError] = useState(false)
  const avatarImg = useAvatarImage(name, userKey, logoUrl)
  const minicard = useMediaQuery('(max-height:160px)')

  const isAvatarImgValid = !!avatarImg && !isError
  const initials = getInitials(name) || 'PL'

  const Result = (
    <Box className={classes.wrapper} onClick={onClick}>
      <Badge badgeContent={score} max={9999} showZero={true} overlap="circular" size={minicard ? 'sm' : size}>
        <Skeleton condition={!name} variant="circular">
          <Tooltip title={children ? '' : name}>
            <MUIAvatar
              alt={initials}
              className={cx(classes.avatar, classes[size || 'sm'], {
                [classes.ellipse]: shape === 'ellipse',
                [classes.image]: isAvatarImgValid
              })}
            >
              {isAvatarImgValid ? (
                <img src={avatarImg} alt={name} style={{ width: '100%', position: 'absolute', left: 0 }} onError={() => setIsError(true)} />
              ) : (
                children || initials
              )}
            </MUIAvatar>
          </Tooltip>
        </Skeleton>
      </Badge>
      {!hideName && <AvatarText name={name} nameMode={nameMode} size={size} color={color} />}
    </Box>
  )

  return (
    <div className={cx(classes.root, { [classes.rootLg]: size === 'lg' })}>
      {link && name ? (
        <Link to={link} className={classes.root}>
          {Result}
        </Link>
      ) : (
        Result
      )}
    </div>
  )
}

export default Avatar

export type Display = {
  mode?: 'dark' | 'light'
  nameMode?: 'inline' | 'multiline'
}

export type Size = {
  size?: 'lg' | 'md' | 'sm' | 'xs'
}

type Shape = {
  shape?: 'circle' | 'ellipse'
}

export type AvatarKeysProps =
  | {
      userKey?: string
      logoUrl?: never
    }
  | {
      userKey?: never
      logoUrl?: string
    }

type ImageAvatarProps = {
  children?: never
} & AvatarKeysProps

type ContentAvatarProps = {
  children: React.ReactNode
  logoUrl?: never
  userKey?: never
}

export type AvatarProps = {
  name: string
  score?: number | string
  color?: TypographyProps['color']
  link?: string
  hideName?: boolean
  onClick?: () => void
} & Display &
  Size &
  Shape
