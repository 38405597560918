import { ComponentProps, useContext, useEffect, useMemo, useState } from 'react'

import { matchPath, useLocation, useParams } from 'react-router-dom'

import Profile from '_pages/people/[id]/profile'

import { TeamContext } from '_core/context/TeamContext'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useActivitiesStats from '_core/hooks/useActivitiesStats'
import useActivityStatsPeriod from '_core/hooks/useActivityStatsPeriod'
import useContactActivitiesPayloads from '_core/hooks/useContactActivitiesPayloads'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import useMarketDataIntegration from '_core/hooks/useMarketDataIntegration'
import usePersonMarketData from '_core/hooks/usePersonMarketData'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSimilarPeople from '_core/hooks/useSimilarPeople'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

import { getUTC } from 'utils/Utils'

import Paths from 'Paths'

const usePersonProfile = () => {
  const { id } = useParams<{ id: string }>()
  const { pathname } = useLocation()

  const { queryParams } = useSearchQuery<PersonProfilePageParams>()
  const { from: fromUTC, to: toUTC } = queryParams

  const { isExact: matchProfile } = matchPath(pathname, { path: Paths.personProfile }) || {}
  const { isExact: matchSimilarPage } = matchPath(pathname, { path: `${Paths.personProfile}/similar` }) || {}

  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue

  const { result: userKeyResult } = useEntityEndpoint<{ results: ProfileType } | null>(`/me/profile`)
  const { UserKey } = userKeyResult?.results || {}

  const { dataVisibility } = useUserDataVisibility([id])
  const { showInteractionsInProfile, hideColleagueRelationships } = dataVisibility || {}

  const [profileLoading, setProfileLoading] = useState<boolean>(false)
  const [personData, setPersonData] = useState<Pick<
    PersonType,
    'PersonMd5' | 'PersonNameText' | 'BestEmailAddrText' | 'BestJobMatchedCompanyName' | 'BestJobCorpLevelCompanyName'
  > | null>(null)

  const { PersonMd5 = '', PersonNameText, BestEmailAddrText, BestJobMatchedCompanyName, BestJobCorpLevelCompanyName } = personData || {}

  const {
    name = '',
    email,
    company
  } = !profileLoading && !PersonMd5
    ? queryParams
    : {
        name: PersonNameText,
        email: BestEmailAddrText,
        company: BestJobMatchedCompanyName || BestJobCorpLevelCompanyName
      }

  const { enabled: enabledMarketData, showSimilarPeople } = useMarketDataIntegration()
  const { marketData, getMarketData } = usePersonMarketData(name, email, company)

  const { jobTitle, jobTitleRole, jobTitleSubRole, jobTitleLevels, jobCompanyIndustry, industry, locationCountry, jobCompanySize, workEmail } =
    marketData || {}

  const hasValidFilters = marketData && (workEmail || email) && (jobTitle || company)
  const isConfigured = ((showSimilarPeople && matchProfile) || (enabledMarketData && matchSimilarPage)) && marketData

  const initParams = useMemo(
    () => ({
      email: workEmail || email || '',
      jobTitles: [jobTitle || company || ''].filter(Boolean),
      jobTitleRoles: jobTitleRole ? [jobTitleRole] : [],
      jobTitleSubroles: jobTitleSubRole ? [jobTitleSubRole] : [],
      jobTitleLevels: jobTitleLevels || [],
      companySize: jobCompanySize || '',
      industries: jobCompanyIndustry || industry ? [jobCompanyIndustry || industry || ''] : [],
      countries: [locationCountry || 'United States']
    }),
    [company, email, workEmail, jobTitle, jobCompanyIndustry, industry, locationCountry]
  )

  const { similar, getSimilar, getPersonDetails } = useSimilarPeople(PersonMd5, initParams, !!isConfigured, !!hasValidFilters)

  const { isExact: matchPeopleActivities = false } = matchPath(pathname, { path: `${Paths._people}/:id/activities` }) || {}
  const { userActivitiesAccess } = useActivitiesAccess([id, matchPeopleActivities]) || {}
  const { isStatsWidgetVisible, isDetailedActivityFromStatsWidgetVisible, isMeetingsTabVisible } = userActivitiesAccess || {}

  const statsPayloads = useContactActivitiesPayloads(useMemo(() => [PersonMd5], [PersonMd5]))

  const activityStatsChartData = useActivityStatsPeriod({
    fromUTC: fromUTC ? getUTC(decodeURIComponent(fromUTC)) : null,
    toUTC: toUTC ? getUTC(decodeURIComponent(toUTC)) : null
  })

  const { stats: activityStats, clearStats } = useActivitiesStats(
    matchProfile && PersonMd5 ? statsPayloads : null,
    useMemo(() => [PersonMd5], [PersonMd5]),
    useMemo(
      () => activityStatsChartData.months.map(({ year, month, minDay, maxDay }) => ({ year, month, minDay, maxDay })),
      [activityStatsChartData.months]
    )
  )

  const {
    fetchWithAbort: fetchInteractions,
    result: interactions,
    setResult: setInteractions,
    reset: resetInteractions
  } = useAbortableFetch<ComponentProps<typeof Profile>['interactions'] | null>()

  const {
    fetchWithAbort: fetchSalesforceUserSettings,
    result: salesforceUserSettings,
    reset: resetSalesforceUserSettings
  } = useAbortableFetch<{ isEnabled: boolean }>()

  useEffect(() => {
    return () => {
      if (PersonMd5) {
        resetInteractions()
        resetSalesforceUserSettings()
        clearStats()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PersonMd5])

  useEffect(() => {
    if (!salesforceUserSettings) {
      fetchSalesforceUserSettings({ url: '/usersettings/salesforce' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesforceUserSettings])

  useEffect(() => {
    if (matchProfile && PersonMd5 && interactions === undefined) {
      if (showInteractionsInProfile) {
        fetchInteractions({ url: `/interactions?TeamNumber=${teamNumber}&personMd5=${PersonMd5}` })
      } else {
        setInteractions(null)
      }
    }
  }, [interactions, matchProfile, PersonMd5, showInteractionsInProfile, teamNumber, fetchInteractions, setInteractions])

  useEffect(() => {
    if (!profileLoading && enabledMarketData && !marketData) {
      getMarketData()
    }
  }, [enabledMarketData, marketData, profileLoading, getMarketData])

  const onProfileLoading = (loading: boolean, response: RemoteData<PersonType> | undefined) => {
    setProfileLoading(loading)
    if (response) {
      const { PersonMd5, PersonNameText, BestEmailAddrText, BestJobMatchedCompanyName, BestJobCorpLevelCompanyName } = response.data[0]
      return setPersonData({ PersonMd5, PersonNameText, BestEmailAddrText, BestJobMatchedCompanyName, BestJobCorpLevelCompanyName })
    }
    setPersonData(null)
  }

  return {
    onProfileLoading,
    activityStats,
    activityStatsChartData,
    isSalesforceEnabled: salesforceUserSettings?.isEnabled,
    showInteractionsInProfile,
    interactions,
    marketData,
    similar,
    getSimilar,
    getPersonDetails,
    enabledMarketData,
    showSimilarPeople,
    isStatsWidgetVisible,
    isDetailedActivityFromStatsWidgetVisible,
    isMeetingsTabVisible,
    hideColleagueRelationships,
    UserKey
  }
}

export default usePersonProfile
