import React, { ElementType } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, InputAdornment } from '@mui/material'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import { IconButton, Button } from '_shared/buttons'
import Card from '_shared/Card'
import TextField from '_shared/forms/TextField'

import UnsavedChangesWarning from '_core/components/dialogs/UnsavedChangesWarning'

import DynamicEntity from '_core/DynamicEntity'

import Repeater from './lists/Repeater'
import ProfileItem from './ProfileItem'

const useStyles = makeStyles()(() => ({
  suggestion: {
    minHeight: 260
  }
}))

export const PickerButton = ({ entity, onSearchClick, unsaved, name }: any) => {
  const history = useHistory()
  const [accept, setAccept] = React.useState<boolean | null>(null)
  const handleSearch = (e: any, force?: boolean) => {
    if (!unsaved || force) history.push(`/${entity}/add`)
    else setAccept(false)
  }

  const handleClose = () => {
    setAccept(null)
  }

  const handleAcceptWarning = () => {
    setAccept(null)
    history.push(`/${entity}/add`)
  }

  return (
    <Box marginLeft={1}>
      <UnsavedChangesWarning
        selected={unsaved && typeof accept === 'boolean' && !accept && { id: 'Seach', name: 'Search' }}
        message={`Are you sure you want to leave without saving changes for <strong>${name}</strong>?`}
        openedName={name}
        handleClose={handleClose}
        handleSubmit={handleAcceptWarning}
      />
      <IconButton onClick={onSearchClick || handleSearch} icon={['far', 'search']} style={{ fontSize: 16 }} />
    </Box>
  )
}

type SuggestionProps = {
  items: { [key: string]: any }[]
  loading: boolean
  handleSelect?: (item: any) => void
  getLink?: (val: string) => string
}

const Suggestion = (props: SuggestionProps) => {
  const { classes } = useStyles()

  return (
    <Card variant="outlined" className={classes.suggestion}>
      <Repeater
        direction="vertical"
        variant="list"
        component={ProfileItem}
        skeleton={{ size: 5, loading: props.loading }}
        items={props.items?.map((item: any) => {
          const itemData = {
            md5: item.PersonMd5 || item.CompanyMd5,
            userKey: item.BestEmailAddrText,
            logoUrl: item.BestUrlText,
            name: item.PersonNameText || item.BestEmailAddrText || item.CompanyNameText || item.BestUrlText,
            email: item.BestEmailAddrText,
            byline: item.BestJobTitleText || item.BestUrlText || '',
            byline2: item.BestJobMatchedCompanyName || item.BestJobCorpLevelCompanyName || '',
            score: item.Score,
            link: props?.getLink && props.getLink(item.PersonMd5 || item.CompanyMd5)
          }

          return props.handleSelect
            ? {
                ...itemData,
                onClick: () => props.handleSelect && props.handleSelect(itemData)
              }
            : itemData
        })}
      />
    </Card>
  )
}

export interface PickerProps {
  entity: 'people' | 'companies'
  addButton?: ElementType
  onSearchClick?: () => void
  getLink?: (md5: string) => string
  unsaved?: boolean
  name?: string
}

type AddComponentType = {
  entity: 'company' | 'people'
  keyword: string
  entityLabel: string
  onClick?: () => void
  link?: string
}

export const AddLink = (props: AddComponentType) => {
  return (
    <Button<typeof Link>
      component={Link}
      to={props.link || `/${props.entity}/add/new`}
      style={{ marginTop: 16 }}
      fullWidth
      startIcon={<FontAwesomeIcon icon={['far', 'plus']} style={{ fontSize: 14 }} />}
    >
      Add {props.keyword || props.entityLabel}
    </Button>
  )
}

export const AddButton = (props: AddComponentType) => {
  return (
    <Button
      onClick={props.onClick}
      style={{ marginTop: 16 }}
      fullWidth
      startIcon={<FontAwesomeIcon icon={['far', 'plus']} style={{ fontSize: 14 }} />}
    >
      Add {props.keyword || props.entityLabel}
    </Button>
  )
}

const Picker = ({
  entityLabel,
  entity,
  getLink,
  handleSelect,
  keyword = '',
  handleKeyword,
  addButton: AddButton
}: PickerProps & { entityLabel: string; keyword?: string; handleKeyword: any; handleSelect?: (md5: string) => void }) => {
  const { teamContextValue } = React.useContext(TeamContext)

  return (
    <>
      <Box display="flex" flexDirection="column" style={{ padding: 16, borderBottom: '1px #ECEEF0 solid' }}>
        <Box flex={1} mb={2} alignItems="center" flexDirection="column" justifyContent="center">
          <Box style={{ position: 'relative' }}>
            <TextField
              placeholder="Search"
              type="text"
              autoFocus
              fullWidth
              onChange={handleKeyword}
              defaultValue={keyword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={['far', 'search']} />
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </Box>
        <Box>
          <DynamicEntity<{ extraProps: { addprops: Pick<SuggestionProps, 'getLink' | 'handleSelect'> } }>
            url={`/${entity}?teamNumber=${teamContextValue.teamNumber}&Take=5&SortBy=ScoreDesc&SearchTerm=${keyword}`}
            component={Suggestion}
            addprops={{ getLink, handleSelect }}
            list={true}
            empty="No results found"
            id="suggestions"
          />
        </Box>
        {AddButton && <AddButton entity={entity} entityLabel={entityLabel} keyword={keyword} />}
      </Box>
    </>
  )
}

export default Picker
