import { useContext, useEffect, useRef } from 'react'

import { Box } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

import { GeneralSettingsContext } from '_core/context/GeneralSettings'

import Checkbox from '_shared/forms/Checkbox'
import TextField, { URLTextField } from '_shared/forms/TextField'

import HelpLinkButton from '_core/components/HelpLinkButton'
import Settings, { useStyles } from '_core/components/settings'

import useSaveSettings, { SaveAction } from '_core/hooks/useSaveSettings'

import { addMissedProtocol, getRidOfProtocol, validLinkRegExp } from '_core/helpers/string'

export type SettingsData = { customGaId: string; useCustomLogo: boolean; baseCrmUrl: string }

const GeneralSettings = (props: { loading: boolean; items: SettingsData }) => {
  const {
    classes: { subsection, itemWrapper, iconButton }
  } = useStyles()

  const {
    register,
    getValues,
    control,
    formState: { dirtyFields, errors }
  } = useForm<SettingsData>({
    mode: 'onChange',
    defaultValues: props.loading ? {} : { ...props.items, baseCrmUrl: getRidOfProtocol(props.items.baseCrmUrl) }
  })
  const customGaIdValueRef = useRef<string | null>(null)
  const { setLogo } = useContext(GeneralSettingsContext)
  const { isSaving, save: saveSettings } = useSaveSettings()

  useEffect(() => {
    if (customGaIdValueRef.current === null && !props.loading) {
      customGaIdValueRef.current = props.items.customGaId
    }
  }, [props.items?.customGaId, props.loading])

  const handleReset = () => {
    if (dirtyFields.useCustomLogo) {
      setLogo('')
    }
  }

  const save = (action: SaveAction) => {
    const { baseCrmUrl, ...settings } = getValues()
    saveSettings(
      '/adminsettings/general',
      { ...settings, baseCrmUrl: baseCrmUrl ? addMissedProtocol(baseCrmUrl) : '' },
      action,
      'General settings',
      handleReset
    )
  }

  return (
    <Settings isSaving={isSaving} save={save} saveDisabled={!!Object.keys(errors).length} initialLoading={props.loading}>
      <Controller
        name="customGaId"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Box>
            <Checkbox
              disabled={isSaving}
              checked={!!value}
              onChange={() => onChange(value ? '' : customGaIdValueRef.current || '')}
              name="customCode"
              label="Use custom Google Analytics 4 measurement ID"
            />
            <Box className={subsection}>
              <TextField
                value={value}
                onChange={(e) => {
                  customGaIdValueRef.current = value
                  onChange(e)
                }}
                size="small"
                disabled={isSaving}
                style={{ width: 124, textAlign: 'center' }}
                placeholder="G-XXXXXXXXXX"
              />
            </Box>
          </Box>
        )}
      />
      <Box display="flex" alignItems="center" className={itemWrapper}>
        <Controller
          name="useCustomLogo"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              checked={!!value}
              onChange={onChange}
              disabled={isSaving}
              label="White label by showing my firm's logo instead of DotAlign's on the top of the app"
            />
          )}
        />
        <HelpLinkButton
          hint="Learn more about showing my firm's logo"
          href="https://help.dotalign.com/article/numfsbvf1v-white-labeling-your-dot-align-deployment"
          classes={{ root: iconButton }}
        />
      </Box>
      <Box display="flex" alignItems="center" className={itemWrapper} width="100%">
        <URLTextField
          {...register('baseCrmUrl', {
            pattern: {
              value: validLinkRegExp,
              message: 'This field is invalid'
            }
          })}
          errorMessage={errors.baseCrmUrl?.message}
          label="Base CRM url"
          placeholder="Enter CRM url"
          size="small"
          disabled={isSaving}
          fullWidth
        />
      </Box>
    </Settings>
  )
}

export default GeneralSettings
