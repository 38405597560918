import React, { ComponentProps } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar from '_shared/Avatar'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import Widget, { LinkAction, SeeAllAction } from '_core/components/widgets'

import Paths from 'Paths'

const ContactsWidget = ({
  items,
  loading,
  querierHasWriteAccess,
  planUid,
  closedOut,
  count
}: {
  items: ComponentProps<typeof Avatar>[]
  loading: boolean
  closedOut: boolean
  count: number
  querierHasWriteAccess: boolean
  planUid: string
}) => {
  const baseUrl = `${Paths._introductions}/${planUid}/contacts`

  return (
    <Widget>
      <Heading
        title="Identified people"
        count={count}
        icon={['far', 'users']}
        action={
          !loading ? (
            <>
              {!count && !closedOut && querierHasWriteAccess ? (
                <LinkAction link={`${baseUrl}/add`} title="Add" />
              ) : (
                <SeeAllAction link={count ? baseUrl : ''} />
              )}
            </>
          ) : null
        }
      />
      {(!!items.length || loading) && <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 3, loading }} items={items} />}
      {!items.length && !loading && (
        <Empty subTitle="No contacts" icon={<FontAwesomeIcon size="3x" icon={['fat', 'users']} style={{ color: '#A7A7A7' }} />} />
      )}
    </Widget>
  )
}

export default ContactsWidget
