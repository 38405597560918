import { useCallback, SyntheticEvent, ComponentProps, ReactElement, useEffect, useContext, useState } from 'react'

import { useMsal } from '@azure/msal-react'
import { IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Divider } from '@mui/material'
import moment, { Moment as MomentType } from 'moment'
import { useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import Avatar from '_shared/Avatar'
import { Button, Select, IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { UpdateStatusPopup, RemovePopup, Repeated } from '_core/components/dialogs/EventConfirm'
import { Wide, Narrow, useWide } from '_core/components/layout'
import Item from '_core/components/lists/Item'
import NameLink from '_core/components/NameLink'
import SidepanelLink from '_core/components/SidepanelLink'
import TextContent from '_core/components/TextContent'
import InteractionCompaniesWidget from '_core/components/widgets/InteractionCompanies'
import InteractionParticipantsWidget from '_core/components/widgets/InteractionParticipants'

import useDialog from '_core/hooks/useDialog'
import useEventTime from '_core/hooks/useEventTime'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { minute, hour } from '_core/data/time'
import { stringifyUrl } from '_core/helpers/browser'
import { getCharacterPosition, addMissedProtocol } from '_core/helpers/string'

import { mergeUrlWithParams } from 'utils/httpUtils'
import { formatDate, formatTime } from 'utils/Utils'

import { ellipsis } from 'AppTheme'
import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  info: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  organizer: ellipsis,
  location: {
    display: 'inline-block',
    maxWidth: '100%',
    lineHeight: '24px',
    width: 'fit-content'
  },
  subject: {
    fontSize: '20px',
    lineHeight: '28px',
    maxWidth: 'fit-content',
    width: '100%'
  },
  button: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
    '& .MuiBox-root': {
      padding: 0
    },
    '& .MuiButton-root': {
      paddingTop: 1,
      paddingBottom: 1,
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      minHeight: 21
    }
  },
  iconsBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  icons: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  skeleton: {
    margin: theme.spacing(1),
    minWidth: 25,
    maxWidth: 25
  },
  comingMsg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiTypography-root': {
      color: theme.palette.warning.main,
      marginLeft: theme.spacing(1),
      fontWeight: 600,
      fontSize: 14,
      [theme.breakpoints.up('sm')]: {
        fontSize: 15
      }
    }
  },
  noStatus: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    color: theme.palette.text.secondary
  },
  timeRange: {
    fontSize: 13,
    color: theme.palette.text.primary,
    opacity: 0.6,
    textAlign: 'center'
  },
  textContent: {
    '& button': {
      display: 'flex'
    }
  },
  externalIcon: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0),
    fontSize: 13,
    '& svg': {
      opacity: 0.6
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  timeMessage: {
    paddingTop: theme.spacing(1)
  },
  more: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(0, 5)
  },
  rightAlign: {
    marginLeft: 'auto'
  }
}))

export type EventPropsType = {
  isAllDay?: boolean
  endTimeUTC?: EventType['endTimeUTC']
  startsInMessage?: ReactElement
  actions?: ReactElement
  tags?: ReactElement
  participantsWithPeople: ComponentProps<typeof Avatar>[]
  companies: ComponentProps<typeof Avatar>[]
  details: ReactElement
  subject: EventType['subject']
  startTimeUTC: EventType['startTimeUTC']
}

type StatusDetails = { id: EventStatusKeys; name: string; action?: string; icon?: [IconPrefix, IconName]; endpoint?: string }

export const statusMap: { [key in EventStatusKeys]: StatusDetails } = {
  Accepted: {
    id: 'Accepted',
    name: 'Accepted',
    action: 'Accept',
    icon: ['far', 'check'],
    endpoint: 'accept'
  },
  Organizer: {
    id: 'Accepted',
    name: 'Accepted',
    action: 'Accept',
    icon: ['far', 'check'],
    endpoint: 'accept'
  },
  TentativelyAccepted: {
    id: 'TentativelyAccepted',
    name: 'Tentative',
    action: 'Tentative',
    icon: ['far', 'question'],
    endpoint: 'tentativelyAccept'
  },
  Declined: {
    id: 'Declined',
    name: 'Declined',
    action: 'Decline',
    icon: ['far', 'times'],
    endpoint: 'decline'
  },
  NotResponded: {
    id: 'NotResponded',
    name: 'No status specified'
  },
  None: {
    id: 'None',
    name: 'No status specified'
  }
}

type IconsBlockProps = {
  loading: boolean
  openStatusPopup: (value: EventStatusKeys) => void
  openRemovePopup: () => void
  selectedStatus: EventStatusKeys
  isAppointment: boolean
  editStatusEnabled: boolean
} & Partial<
  Pick<EventType, 'appointmentUID' | 'sourceKey' | 'subject' | 'onlineMeetingJoinUrl' | 'participants'> & {
    organizer: EventListItem['organizer']
    className: string
  }
>

export const TimeMessage = (props: Pick<EventType, 'startTimeUTC' | 'endTimeUTC' | 'isCancelled'> & { className?: string }) => {
  const { classes, cx } = useStyles()
  const { startTimeUTC, endTimeUTC, isCancelled } = props
  const { startsIn, finishIn = -1, startedToday } = useEventTime({ startTimeUTC, endTimeUTC, isCancelled })

  const timeMessageIsShown = typeof startsIn === 'number' && !!startedToday && startsIn < hour && finishIn > 0

  const getInProcess = (unitOfTime?: 'hours' | 'minutes' | 'seconds'): MomentType => {
    const difference = moment().diff(props.startTimeUTC, unitOfTime)
    return moment.utc(difference)
  }

  return (
    <>
      {timeMessageIsShown && (
        <Box className={cx(classes.comingMsg, props.className)}>
          <FontAwesomeIcon icon={['far', 'clock']} color="#F5A91C" />
          {startsIn > 0 && startsIn < hour && startsIn > minute && (
            <Typography>Coming up in {+formatTime(moment.unix(startsIn), 'm') + 1} minutes</Typography>
          )}
          {startsIn >= 0 && startsIn <= minute && (
            <Typography>
              Coming up in {startsIn} second{startsIn > 1 ? 's' : ''}
            </Typography>
          )}
          {startsIn < 0 && finishIn > 0 && (
            <Typography>
              In progress for{' '}
              {getInProcess().unix() >= hour &&
                `${formatTime(getInProcess(), 'h')} hour${+formatTime(getInProcess(), 'h') > 1 ? 's' : ''} ${
                  +formatTime(getInProcess(), 'm') !== 0
                    ? `and ${formatTime(getInProcess(), 'm')} minute${+formatTime(getInProcess(), 'm') > 1 ? 's' : ''} `
                    : ''
                } `}
              {getInProcess().unix() < hour &&
                (getInProcess().unix() >= minute
                  ? `${formatTime(getInProcess(), 'm')} minute${+formatTime(getInProcess(), 'm') > 1 ? 's' : ''}`
                  : `${getInProcess().unix()} second${+formatTime(getInProcess(), 's') > 1 ? 's' : ''}`)}
            </Typography>
          )}
        </Box>
      )}
    </>
  )
}

export const IconsBlock = (props: IconsBlockProps) => {
  const { classes, cx } = useStyles()
  const { accounts } = useMsal()
  const status = statusMap?.[props.selectedStatus]
  const matchLaptopWidth = useWide('laptop')

  const isOrganizer = props.organizer?.email === accounts[0].username
  const handleUpdate = (value: EventStatusKeys, e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    props.openStatusPopup(value)
  }

  const handleRemove = (e: SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation()
    props.openRemovePopup()
  }

  const actions: {
    value: EventStatusKeys
    name: StatusDetails['action']
    icon: StatusDetails['icon']
    action: (value: EventStatusKeys, e: SyntheticEvent) => void
  }[] = [
    {
      value: 'Accepted',
      name: statusMap['Accepted'].action,
      icon: statusMap['Accepted'].icon,
      action: handleUpdate
    },
    {
      value: 'TentativelyAccepted',
      name: statusMap['TentativelyAccepted'].action,
      icon: statusMap['TentativelyAccepted'].icon,
      action: handleUpdate
    },
    {
      value: 'Declined',
      name: statusMap['Declined'].action,
      icon: statusMap['Declined'].icon,
      action: handleUpdate
    }
  ]
  // prettier-ignore
  const C = useCallback(() => (
    <Select
      size="small"
      disabled={!props.editStatusEnabled}
      disableRipple
      component="span"
      color="primary"
      variant="outlined"
      form="rounded"
      startIcon={<FontAwesomeIcon icon={status?.icon || ['fas', 'reply']} style={{ fontSize: 15 }} />}
      endIcon={<FontAwesomeIcon style={{ width: 8 }} color="#979797" icon={['far', 'chevron-down']} />}
    >
      { !!matchLaptopWidth && (status?.icon ? status.name : 'Respond')}
    </Select>
  ), [props.selectedStatus, props.editStatusEnabled, matchLaptopWidth] )

  const Compact = (
    <>
      {(props.loading || props.onlineMeetingJoinUrl) && (
        <IconButton
          loading={props.loading}
          component="a"
          href={props.onlineMeetingJoinUrl ? addMissedProtocol(props.onlineMeetingJoinUrl) : ''}
          target="_blank"
          color="primary"
          onClick={(e: SyntheticEvent) => e.stopPropagation()}
          size="small"
          icon={['fas', 'video']}
          hint={props.onlineMeetingJoinUrl ? addMissedProtocol(props.onlineMeetingJoinUrl) : ''}
        />
      )}
      {(props.loading || (!props.isAppointment && !isOrganizer)) && (
        <Box className={cx({ [classes.button]: !props.loading })}>
          <Skeleton variant="circular" condition={props.loading} width={25} height={25} className={classes.skeleton}>
            <Item component={C} disabled={!props.editStatusEnabled} item={{ menu: { actions } }} />
          </Skeleton>
        </Box>
      )}
    </>
  )

  return (
    <Box className={cx(classes.iconsBlock, props.className)}>
      <IconButton
        href={`mailto:${props.participants?.map((p) => p.email).join(';')}?Subject=RE: ${props.subject}`}
        component="a"
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e: SyntheticEvent<HTMLAnchorElement>) => e.stopPropagation()}
        loading={props.loading}
        color="primary"
        size="small"
        icon={['fas', 'envelope']}
        hint="Email participants"
      />
      <IconButton loading={props.loading} onClick={handleRemove} color="primary" size="small" icon={['fas', 'calendar-xmark']} hint="Remove" />
      <SidepanelLink
        linkProps={{
          to: mergeUrlWithParams(`${Paths._events}/${props.sourceKey}/tags/edit`, {
            name: props.subject,
            appointmentUID: props.appointmentUID
          })
        }}
        sidepanel="preview"
      >
        <IconButton loading={props.loading} icon={['far', 'tag']} color="primary" size="small" hint="Add tags" />
      </SidepanelLink>
      <Wide>
        <Box display={{ md: 'none', laptop: 'flex' }}>
          {(props.loading || props.onlineMeetingJoinUrl) && (
            <Tooltip title={props.onlineMeetingJoinUrl && addMissedProtocol(props.onlineMeetingJoinUrl)}>
              <Box className={classes.button}>
                <Skeleton variant="text" condition={props.loading}>
                  <Button<'a'>
                    size="small"
                    variant="outlined"
                    form="rounded"
                    color="primary"
                    component="a"
                    href={props.onlineMeetingJoinUrl ? addMissedProtocol(props.onlineMeetingJoinUrl) : ''}
                    target="_blank"
                    onClick={(e: SyntheticEvent) => e.stopPropagation()}
                    startIcon={<FontAwesomeIcon icon={['fas', 'video']} style={{ fontSize: 15 }} />}
                  >
                    launch
                  </Button>
                </Skeleton>
              </Box>
            </Tooltip>
          )}
          {(props.loading || (!props.isAppointment && !isOrganizer)) && (
            <Box className={classes.button}>
              <Skeleton variant="text" condition={props.loading}>
                <Item component={C} disabled={!props.editStatusEnabled} item={{ menu: { actions } }} />
              </Skeleton>
            </Box>
          )}
        </Box>
        <Box display={{ md: 'flex', laptop: 'none' }}>{Compact}</Box>
      </Wide>
      <Narrow>{Compact}</Narrow>
    </Box>
  )
}

export const SubjectTitle = ({ loading, title }: { loading?: boolean; title: string }) => {
  const { classes } = useStyles()
  return (
    <Skeleton condition={loading}>
      <Tooltip title={title}>
        <Typography classes={{ root: classes.subject }} semiBold noWrap>
          {loading ? 'Long placeholder' : title}
        </Typography>
      </Tooltip>
    </Skeleton>
  )
}

type MainProps = Partial<
  {
    loading: boolean
    organizer: { name: string; md5: string; email: string }
    closePopup?: () => void
    className?: string
  } & Pick<EventType, 'subject' | 'location' | 'onlineMeetingJoinUrl'>
>

export const Main = (props: MainProps) => {
  const { loading, subject, location, organizer, onlineMeetingJoinUrl, className } = props
  const { classes } = useStyles()
  return (
    <Box className={className}>
      <SubjectTitle loading={loading} title={subject || ''} />
      {Boolean(!location || location !== onlineMeetingJoinUrl) && (
        <Skeleton condition={loading}>
          <Tooltip title={location}>
            <Typography classes={{ root: classes.location }} semiBold noWrap color="text.secondary">
              {location || 'No location specified'}
            </Typography>
          </Tooltip>
        </Skeleton>
      )}

      <Skeleton condition={loading}>
        <span>
          <NameLink
            variant="light"
            name={organizer ? organizer.name || organizer.email : 'Long placeholder'}
            url={stringifyUrl(`${Paths._people}/${organizer?.md5}`, {
              name: organizer?.name || organizer?.email,
              email: organizer?.email
            })}
            className={classes.organizer}
            sidepanel="preview"
            onClick={props.closePopup}
          />
        </span>
      </Skeleton>
    </Box>
  )
}

export const UserEventDetails = (
  props: Omit<ComponentProps<typeof Main>, 'loading'> &
    Pick<ComponentProps<typeof TextContent>, 'body'> & { webLink?: string; sourceKey?: string; loading: boolean }
) => {
  const { classes, cx } = useStyles()

  const minLength = getCharacterPosition(props.body, '\n', 5)
  const maxLength = getCharacterPosition(props.body, '\n', 10)

  return (
    <>
      <Main
        loading={props.loading}
        subject={props.subject || ''}
        location={props.location || ''}
        onlineMeetingJoinUrl={props.onlineMeetingJoinUrl || ''}
        organizer={props.organizer}
        className={classes.main}
      />
      <Box mt={2} width={1}>
        <TextContent
          className={classes.textContent}
          skeleton={{ size: 5, loading: props.loading }}
          body={props.body || ''}
          showMore={(p: { expanded: boolean; toggleExpanded: () => void }) =>
            p.expanded || minLength === props.body?.length ? (
              <span className={classes.more}>
                {minLength !== props.body?.length && (
                  <Button
                    bold={false}
                    variant="link"
                    size="small"
                    onClick={p.toggleExpanded}
                    disablePL
                    endIcon={<FontAwesomeIcon icon={['far', 'chevron-up']} style={{ fontSize: 10 }} />}
                  >
                    Show less
                  </Button>
                )}
                <Button<'a'>
                  bold={false}
                  className={cx({ [classes.rightAlign]: minLength === props.body?.length })}
                  component="a"
                  variant="link"
                  size="small"
                  disablePR
                  target="_blank"
                  href={props.webLink}
                  rel="noreferrer"
                  endIcon={<FontAwesomeIcon icon={['far', 'chevron-right']} style={{ fontSize: 10 }} />}
                >
                  Continue in Outlook
                </Button>
              </span>
            ) : (
              <Button
                bold={false}
                variant="link"
                size="small"
                onClick={p.toggleExpanded}
                disablePL
                endIcon={<FontAwesomeIcon icon={['far', 'chevron-down']} style={{ fontSize: 10 }} />}
              >
                Show more
              </Button>
            )
          }
          minLength={minLength}
          maxLength={maxLength}
        />
      </Box>
      <IconButton
        icon={['far', 'external-link']}
        loading={props.loading}
        className={classes.externalIcon}
        component="a"
        href={props.webLink}
        target="_blank"
        size="small"
        hint="View in outlook"
      />
    </>
  )
}

export const UserEventActions = (
  props: Omit<ComponentProps<typeof IconsBlock>, 'selectedStatus' | 'openRemovePopup' | 'openStatusPopup'> & {
    responseType?: EventStatusKeys
    item?: ComponentProps<typeof UpdateStatusPopup>['item']
  }
) => {
  const { classes } = useStyles()
  const sidepanel = isSidepanel()

  const [selectedRepeated, setSelectedRepeated] = useState<Repeated>(Repeated.one)
  const [selectedStatus, setSelectedStatus] = useState(props.responseType)

  const { payloads, updateParent } = useSidepanelPayloads('preview')

  useEffect(() => {
    if (payloads?.action === 'UPDATE_STATUS') {
      setSelectedStatus(payloads.value)
    }
  }, [payloads])

  const { dialogContentProps: openedStatusDialog, openDialog: openStatusDialog, closeDialog: closeStatusDialog } = useDialog<EventStatusKeys>()
  const { isDialogOpened: openedRemoveDialog, openDialog: openRemoveDialog, closeDialog: closeRemoveDialog } = useDialog()

  const openParentPopup = (kind: EventPopupKind, data: { [key: string]: any }) => updateParent({ action: 'OPEN_POPUP', value: { kind, data } })

  const openStatusPopup = (value: EventStatusKeys) => {
    sidepanel ? openParentPopup('statusUpdate', { item: props.item, value }) : openStatusDialog(value)
  }

  const openRemovePopup = () => {
    sidepanel ? openParentPopup('removeEvent', { item: props.item }) : openRemoveDialog()
  }

  return (
    <>
      <Divider />
      <IconsBlock
        loading={props.loading}
        subject={props.subject || ''}
        organizer={props.organizer}
        sourceKey={props.sourceKey}
        appointmentUID={props.appointmentUID}
        editStatusEnabled={props.editStatusEnabled}
        participants={props.participants || []}
        isAppointment={props.isAppointment}
        onlineMeetingJoinUrl={props.onlineMeetingJoinUrl || ''}
        selectedStatus={selectedStatus || 'None'}
        openRemovePopup={openRemovePopup}
        openStatusPopup={openStatusPopup}
        className={classes.icons}
      />
      {props.item && (
        <>
          <UpdateStatusPopup
            isOpen={Boolean(openedStatusDialog)}
            item={props.item}
            status={openedStatusDialog || selectedStatus || 'None'}
            confirmStatus={setSelectedStatus}
            repeated={selectedRepeated}
            setRepeated={setSelectedRepeated}
            updateStatus={openStatusDialog}
            close={closeStatusDialog}
          />
          <RemovePopup isOpen={openedRemoveDialog} item={props.item} confirmStatus={setSelectedStatus} close={closeRemoveDialog} />
        </>
      )}
    </>
  )
}

const Event = (props: ({ loading: true } & Partial<EventPropsType>) | ({ loading: false } & EventPropsType)) => {
  const { search, pathname } = useLocation()
  const { classes } = useStyles()
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    if (props.subject) {
      setMobileHeader(props.subject)
    }
  }, [props.subject, setMobileHeader])

  return (
    <>
      <Box position="relative">
        {props.startsInMessage}
        <Box className={classes.info}>
          <Skeleton condition={props.loading}>
            <Typography classes={{ root: classes.timeRange }}>
              <span>{formatDate(props.startTimeUTC, 'ddd D, MMM ')}</span>
              {props.isAllDay || (
                <>
                  ⋅{' '}
                  <span>
                    {formatTime(props.startTimeUTC)}
                    {props.endTimeUTC && <> - {formatTime(props.endTimeUTC)}</>}{' '}
                  </span>
                </>
              )}
            </Typography>
          </Skeleton>
          {props.details}
          {props.tags}
        </Box>
        {props.actions}
      </Box>
      <InteractionParticipantsWidget
        total={props.participantsWithPeople?.length || -1}
        link={`${pathname}/participants${search}`}
        items={props.participantsWithPeople?.slice(0, 5) || []}
        loading={props.loading}
      />
      <InteractionCompaniesWidget
        total={props.companies?.length || -1}
        link={`${pathname}/companies${search}`}
        items={props.companies?.slice(0, 5) || []}
        loading={props.loading}
      />
    </>
  )
}

export default Event
