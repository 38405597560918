import { Control, useController } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { ReasonType } from '_core/hooks/useIntroductionReason'
import useSidebar from '_core/hooks/useSidebar'

import Paths from 'Paths'

export const useStyles = makeStyles()((theme) => ({
  profileItem: {
    padding: 0
  },
  icon: {
    fontSize: 14,
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  avatarTitle: {
    marginBottom: theme.spacing(1),
    textAlign: 'center'
  },
  group: {
    marginBottom: theme.spacing(2)
  },
  cardAction: {
    paddingTop: theme.spacing(0.5)
  },
  activeDivider: {
    borderColor: theme.palette.secondary.main
  }
}))

export type BeneficiaryType = 'person' | 'company'
export type IntroductionRequest = {
  planUid: string
  summary: string
  description: string
  reason: ReasonType
  requester: {
    name: string
    email: string
    md5?: string
  }
  beneficiaryCompany: {
    name: string
    website: string
    md5: string
  }
  beneficiaryPerson: {
    name: string
    email: string
    md5: string
  }
}

export const errorMsg = 'This field is required'

const useIntroductionRequestForm = (control: Control<IntroductionRequest, any>, beneficiaryType: BeneficiaryType) => {
  const { close } = useSidebar()
  const sidepanel = isSidepanel()
  const history = useHistory()
  const handleClose = () => (sidepanel ? close() : history.push(Paths._introductions))

  const { field: reasonField } = useController({
    name: 'reason',
    control,
    rules: {
      validate: (value) => !!value.value || errorMsg
    }
  })
  const { field: requesterField } = useController({ name: 'requester', control, rules: { validate: (value) => !!value.name || errorMsg } })
  const { field: beneficiaryPersonField } = useController({
    name: 'beneficiaryPerson',
    control,
    rules: { validate: (value) => (beneficiaryType === 'company' ? true : !!value.name || errorMsg) }
  })
  const { field: beneficiaryCompanyField } = useController({
    name: 'beneficiaryCompany',
    control,
    rules: { validate: (value) => (beneficiaryType === 'person' ? true : !!value.name || errorMsg) }
  })

  return { handleClose, beneficiaryPersonField, beneficiaryCompanyField, requesterField, reasonField }
}

export default useIntroductionRequestForm
