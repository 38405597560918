import React, { ChangeEvent, HTMLAttributes, ReactElement, useCallback, useEffect, useMemo } from 'react'

import { Box, RadioGroup } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import Combobox, { ChipsPickList } from '_shared/forms/Combobox'
import Typography from '_shared/Typography'

import { CompanyOption, PersonOption, renderPersonOption } from '_core/components/introductions/options'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useAssignee, { AssigneeType } from '_core/hooks/useAssignee'
import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Filters from './index'

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 270,
      overflow: 'auto',
      height: '100%'
    }
  },
  block: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    marginBottom: theme.spacing(1)
  },
  profileItem: {
    padding: 0,
    minHeight: 'auto'
  },
  assigneeWrapper: {
    marginLeft: `-${theme.spacing(3, 5)}`,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1)
  },
  listWrapper: {
    marginLeft: `-${theme.spacing(2)}`,
    marginRight: `-${theme.spacing(2)}`
  }
}))

export type IntroductionsFiltersType = {
  total?: number
  opened: boolean
  disabled: boolean
  contentLoading: boolean
  toggleOpen: () => void
  reset: () => Promise<void>
  handleChange: (params: IntroductionsPageParams) => void
  anchorEl?: HTMLElement | null
}

export type OptionType = {
  label: string
  value: string
}

type IntroductionFieldNames = 'entityIdentifiers' | 'beneficiaryIdentifiers' | 'reason' | 'targetingStatuses'

const fetcherRoleOptions: { value: FetcherRoleType | 'all'; label: string }[] = [
  { value: 'all', label: 'All' },
  { value: 'creator', label: 'Created by me' },
  { value: 'assignee', label: 'Assigned to me' },
  { value: 'requester', label: 'Where I am the requester' },
  { value: 'introducer', label: 'Where I am the introducer' }
]

const requestTypeOptions: { value: IntroductionType; label: string }[] = [
  { value: 'all', label: 'All' },
  { value: 'closed', label: 'Closed' },
  { value: 'active', label: 'Active' }
]

const visibilityOptions: { value: IntroductionVisibilityType | 'all'; label: string }[] = [
  { value: 'all', label: 'All' },
  { value: 'mine', label: 'Only me' },
  { value: 'shared', label: 'Network team and the chosen collaborators' }
]

const isOptionEqualToValue = (option: OptionType, value: OptionType | string) =>
  typeof value === 'string' ? option.value === value : option.value === value.value
const getLabel = (option: OptionType | string) => (typeof option === 'string' ? option : option.label)
const getLabelAsName = (option: PersonOption | CompanyOption | string) => (typeof option === 'string' ? option : option.name)

const transformBeneficiariesResult = (res: [IntroductionBeneficiaryCompanyResp[], IntroductionBeneficiaryPersonResp[]]) => {
  const [companies = [], people = []] = res
  const transformedPeople = people.map(({ name, md5, email }) => ({ name, key: md5, email }))
  const transformedCompanies = companies.map(({ name, md5, url }) => ({ name, key: md5, website: url }))
  return [...transformedCompanies, ...transformedPeople]
}

const filterUniqueContacts = (contacts: PersonOption[]) => {
  const uniqueEmailsSet = new Set()
  const uniqueContacts = contacts.filter(({ email }) => {
    if (!uniqueEmailsSet.has(email)) {
      uniqueEmailsSet.add(email)
      return true
    }
    return false
  })

  return uniqueContacts
}

const ShowRequestsControl = ({ disabled, handleChange }: { disabled: boolean; handleChange: (params: IntroductionsPageParams) => void }) => {
  const { classes } = useStyles()

  const { queryParams } = useSearchQuery<IntroductionsPageParams>()
  const { fetcherRole, unassigned, assignedToEmail } = queryParams

  const handleRadioChange = (e: ChangeEvent<{ name: string; value: unknown }>) => {
    const name = e.target.name as 'fetcherRole'
    const value = e.target.value as FetcherRoleType | 'all'

    handleChange({
      [name]: value === 'all' ? undefined : value,
      ...(value === 'assignee' && unassigned === 'true' ? { unassigned: 'false' } : {}),
      ...(value === 'assignee' && assignedToEmail ? { assignedToEmail: undefined, unassigned: 'false' } : {})
    })
  }

  return (
    <Box className={classes.block}>
      <Typography variant="h4" semiBold className={classes.header}>
        Show requests
      </Typography>
      <RadioGroup name="fetcherRole" value={fetcherRole || 'all'} onChange={handleRadioChange}>
        {fetcherRoleOptions.map((item) => (
          <Filters.Radio disabled={disabled} key={item.value} value={item.value} label={item.label} />
        ))}
      </RadioGroup>
    </Box>
  )
}

const AssigneeControl = ({ disabled, handleChange }: { disabled: boolean; handleChange: (params: IntroductionsPageParams) => void }) => {
  const { classes } = useStyles()

  const { queryParams } = useSearchQuery<IntroductionsPageParams>()
  const { fetcherRole, unassigned, assignedToEmail } = queryParams

  const { fetchWithAbort, forceAbort } = useAbortableFetch<AssigneeType[]>()

  const getAssigneeOptions = useCallback(
    async (searchTerm: string) => {
      const url = mergeUrlWithParams('/prospecting/managers', {
        role: 'ProspectingManager',
        searchTerm
      })

      const res = await fetchWithAbort({ url })
      if (res) {
        return res.map((manager) => ({
          displayName: manager.displayName,
          emailAddress: manager.emailAddress
        }))
      }
    },
    [fetchWithAbort]
  )

  const { inputValue, open, options, optionsLoading, handleClose, handleOpen, handleFocus, handleInputChange } = useAsyncCombobox<AssigneeType, true>(
    {
      loadOptions: getAssigneeOptions,
      forceAbort
    }
  )

  const { assignee } = useAssignee('', assignedToEmail)

  const handleAssignedToChange = (event: ChangeEvent<{}>, value: AssigneeType | null) => {
    handleChange({
      assignedToEmail: value ? value.emailAddress : undefined,
      ...(fetcherRole === 'assignee' ? { fetcherRole: undefined } : {})
    })
  }

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, value: AssigneeType) => (
    <li {...props}>
      <Box ml={-2} mr={-2}>
        <ProfileItem name={value.displayName} userKey={value.emailAddress} byline={value.emailAddress} />
      </Box>
    </li>
  )

  const AssignedTo = (
    <Box>
      <Typography>Assigned to</Typography>
      {assignedToEmail && (
        <Box className={classes.assigneeWrapper}>
          <ProfileItem
            variant="expandable"
            name={assignee.displayName}
            userKey={assignee.emailAddress}
            byline={assignee.emailAddress}
            score=""
            className={classes.profileItem}
            icons={
              <>
                {assignee.displayName && (
                  <IconButton
                    disabled={disabled || !assignee.displayName}
                    hint="Change assignee"
                    icon={['far', 'times']}
                    onClick={(e) => handleAssignedToChange(e, null)}
                    size="small"
                    disablePR
                  />
                )}
              </>
            }
          />
        </Box>
      )}
      {!assignedToEmail && (
        <Box className={classes.assigneeWrapper}>
          <Combobox<AssigneeType>
            label=""
            placeholder="Search by email"
            icon={['far', 'search']}
            disabled={disabled}
            open={open}
            value={assignee}
            loading={optionsLoading}
            options={options}
            inputValue={inputValue}
            onChange={handleAssignedToChange}
            onInputChange={handleInputChange}
            getOptionLabel={(option) => option.emailAddress}
            renderOption={renderOption}
            onOpen={handleOpen}
            onClose={handleClose}
            onFocus={handleFocus}
          />
        </Box>
      )}
    </Box>
  )

  const assigneeOptions: {
    value: 'all' | 'true' | 'false' | 'assignedTo'
    name: 'all' | 'assignedTo' | 'unassigned'
    label: string | ReactElement
  }[] = [
    { value: 'all', name: 'all', label: 'All' },
    { value: 'true', name: 'unassigned', label: 'Unassigned' },
    { value: 'false', name: 'unassigned', label: 'Assigned' },
    { value: 'assignedTo', name: 'assignedTo', label: AssignedTo }
  ]

  const assigneeValue = assignedToEmail ? 'assignedTo' : unassigned

  const handleAssigneeChange = (e: ChangeEvent<{ name: string; value: unknown }>) => {
    const { name, value } = e.target as { name: string; value: 'true' | 'false' }
    handleChange({
      unassigned: name === 'unassigned' ? value : undefined,
      ...(name === 'unassigned' && value === 'true' && fetcherRole === 'assignee' ? { fetcherRole: undefined } : {}),
      ...((name === 'unassigned' || name === 'all') && assignedToEmail ? { assignedToEmail: undefined } : {})
    })
  }

  return (
    <Box className={classes.block}>
      <Typography variant="h4" semiBold className={classes.header}>
        Assignee
      </Typography>
      <RadioGroup name="assignee" value={assigneeValue || 'all'} onChange={handleAssigneeChange}>
        {assigneeOptions.map((item) => (
          <Filters.Radio disabled={disabled} key={item.value} name={item.name} value={item.value} label={item.label} />
        ))}
      </RadioGroup>
    </Box>
  )
}

const VisibleForControl = ({ disabled, handleChange }: { disabled: boolean; handleChange: (params: IntroductionsPageParams) => void }) => {
  const { classes } = useStyles()

  const { queryParams } = useSearchQuery<IntroductionsPageParams>()
  const { visibility } = queryParams

  const handleRadioChange = (e: ChangeEvent<{ name: string; value: unknown }>) => {
    const name = e.target.name as 'visibility'
    const value = e.target.value as IntroductionVisibilityType | 'all'

    handleChange({
      [name]: value === 'all' ? undefined : value
    })
  }

  return (
    <Box className={classes.block}>
      <Typography variant="h4" semiBold className={classes.header}>
        Visible for
      </Typography>
      <RadioGroup name="visibility" value={visibility || 'all'} onChange={handleRadioChange}>
        {visibilityOptions.map((item) => (
          <Filters.Radio disabled={disabled} key={item.value} value={item.value} label={item.label} />
        ))}
      </RadioGroup>
    </Box>
  )
}

const ContactsPicker = ({
  disabled,
  fieldName,
  handleChange,
  handleDelete
}: {
  disabled: boolean
  fieldName: Extract<IntroductionFieldNames, 'entityIdentifiers'>
  handleChange: (params: IntroductionsPageParams) => void
  handleDelete: (email: string, field: Extract<IntroductionFieldNames, 'entityIdentifiers'>) => void
}) => {
  const { classes } = useStyles()

  const { queryParams } = useSearchQuery<IntroductionsPageParams, { modifyProps: [{ entityIdentifiers: string[] }] }>(['entityIdentifiers'])
  const memoEntityIdentifiers = useMemo(() => queryParams.entityIdentifiers || [], [queryParams.entityIdentifiers])

  const { fetchWithAbort: fetchContactsOptions, forceAbort } = useAbortableFetch<{ data: { displayAs: string; contactEmail: string }[] }>()
  const { fetchWithAbort: fetchTargetedContacts, result } = useAbortableFetch<{ data: IntroductionContactResp[] }>()

  const contactsList = useMemo(
    () =>
      memoEntityIdentifiers.length
        ? result?.data.map((contact) => ({
            name: contact.displayAs,
            email: contact.contactEmail
          })) || []
        : [],
    [memoEntityIdentifiers.length, result]
  )

  const contactsItems = contactsList.map((contact) => ({
    name: contact.name,
    userKey: contact.email,
    byline: contact.email,
    icons: <IconButton disabled={disabled} icon={['far', 'times']} onClick={() => handleDelete(contact.email, fieldName)} size="small" disablePR />
  }))

  const getContactsOptions = useCallback(
    async (searchText: string): Promise<PersonOption[] | undefined> => {
      const res = await fetchContactsOptions({
        url: `/prospecting/targetedcontacts?WithEvents=false&SearchTerm=${searchText}`
      })
      if (res) {
        return res.data.map((contact) => ({ name: contact.displayAs, email: contact.contactEmail }))
      }
    },
    [fetchContactsOptions]
  )

  const { inputValue, open, options, optionsLoading, handleClose, handleOpen, handleFocus, handleInputChange } = useAsyncCombobox<PersonOption, true>(
    {
      loadOptions: getContactsOptions,
      forceAbort
    }
  )

  useEffect(() => {
    if (memoEntityIdentifiers.length) {
      fetchTargetedContacts({
        url: mergeUrlWithParams('/prospecting/targetedcontacts', { EntityIdentifiers: memoEntityIdentifiers, ClosedFilter: 'All' })
      })
    }
  }, [memoEntityIdentifiers, fetchTargetedContacts])

  const filterContactOptions = (options: PersonOption[]) => {
    const uniqueContacts = filterUniqueContacts(options)
    return uniqueContacts.filter((option) => !memoEntityIdentifiers.includes(option.email))
  }

  const handlePickerChange = (event: ChangeEvent<{}>, value: PersonOption[]) => {
    const updated = value.map((option) => option.email)
    handleChange({ [fieldName]: updated.join(',') })
  }

  return (
    <Box className={classes.block}>
      <Typography variant="h4" semiBold className={classes.header}>
        Contacts
      </Typography>
      <Combobox<PersonOption, true>
        label=""
        options={options}
        loading={optionsLoading}
        open={open}
        inputValue={inputValue}
        placeholder="Search for contacts"
        multiple
        icon={['far', 'search']}
        disabled={disabled}
        value={contactsList}
        onChange={handlePickerChange}
        onOpen={handleOpen}
        onClose={handleClose}
        onFocus={handleFocus}
        onInputChange={handleInputChange}
        getOptionLabel={getLabelAsName}
        filterOptions={filterContactOptions}
        renderOption={renderPersonOption}
      />
      {!!contactsItems?.length && (
        <Box className={classes.listWrapper}>
          <Repeater direction="vertical" variant="list" items={contactsItems} component={ProfileItem} skeleton={{ size: 0, loading: false }} />
        </Box>
      )}
    </Box>
  )
}

const BeneficiariesPicker = ({
  disabled,
  fieldName,
  handleChange,
  handleDelete
}: {
  disabled: boolean
  fieldName: Extract<IntroductionFieldNames, 'beneficiaryIdentifiers'>
  handleChange: (params: IntroductionsPageParams) => void
  handleDelete: (key: string, field: Extract<IntroductionFieldNames, 'beneficiaryIdentifiers'>) => void
}) => {
  const { classes } = useStyles()

  const { queryParams } = useSearchQuery<IntroductionsPageParams, { modifyProps: [{ beneficiaryIdentifiers: string[] }] }>(['beneficiaryIdentifiers'])
  const memoBeneficiaryIdentifiers = useMemo(() => queryParams.beneficiaryIdentifiers || [], [queryParams.beneficiaryIdentifiers])

  const { fetchWithAbort: fetchBeneficiaryOptions, forceAbort } =
    useAbortableFetch<[{ name: string; url: string; md5: string }[], { name: string; email: string; md5: string }[]]>()
  const { fetchWithAbort: fetchBeneficiaryItems, result } =
    useAbortableFetch<[IntroductionBeneficiaryCompanyResp[], IntroductionBeneficiaryPersonResp[]]>()

  const beneficiariesList = useMemo(
    () => (memoBeneficiaryIdentifiers.length && result ? transformBeneficiariesResult(result) : []),
    [memoBeneficiaryIdentifiers.length, result]
  )

  const getBeneficiaryOptions = useCallback(
    async (searchText: string) => {
      const res = await fetchBeneficiaryOptions([
        { url: `/prospecting/beneficiarycompanies?SearchText=${searchText}` },
        { url: `/prospecting/beneficiarypersons?SearchText=${searchText}` }
      ])
      if (res) {
        return transformBeneficiariesResult(res)
      }
    },
    [fetchBeneficiaryOptions]
  )

  const { inputValue, open, options, optionsLoading, handleClose, handleOpen, handleFocus, handleInputChange } = useAsyncCombobox<
    (PersonOption | CompanyOption) & { key: string },
    true
  >({ loadOptions: getBeneficiaryOptions, forceAbort })

  useEffect(() => {
    if (memoBeneficiaryIdentifiers.length) {
      fetchBeneficiaryItems([{ url: '/prospecting/beneficiarycompanies' }, { url: '/prospecting/beneficiarypersons' }])
    }
  }, [memoBeneficiaryIdentifiers, fetchBeneficiaryItems])

  const beneficiariesItems = beneficiariesList.map((beneficiary) => {
    const byline = 'email' in beneficiary ? beneficiary.email : beneficiary.website
    return {
      name: beneficiary.name,
      userKey: 'email' in beneficiary ? beneficiary.email : '',
      logoUrl: 'website' in beneficiary ? beneficiary.website : '',
      byline,
      icons: (
        <IconButton disabled={disabled} icon={['far', 'times']} onClick={() => handleDelete(beneficiary.key, fieldName)} size="small" disablePR />
      )
    }
  })

  const handlePickerChange = (event: ChangeEvent<{}>, value: ((PersonOption | CompanyOption) & { key: string })[]) => {
    const updated = value.map((option) => option.key)
    handleChange({ [fieldName]: updated.join(',') })
  }

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, value: (PersonOption | CompanyOption) & { key: string }) => {
    const byline = 'email' in value ? value.email : value.website

    return (
      <li {...props} key={value.key}>
        <Box ml={-2} mr={-2}>
          <ProfileItem
            name={value.name}
            userKey={'email' in value ? value.email : ''}
            logoUrl={'website' in value ? value.website : ''}
            byline={byline}
          />
        </Box>
      </li>
    )
  }

  return (
    <Box className={classes.block}>
      <Typography variant="h4" semiBold className={classes.header}>
        Beneficiaries
      </Typography>
      <Combobox<(PersonOption | CompanyOption) & { key: string }, true>
        label=""
        options={options}
        loading={optionsLoading}
        open={open}
        inputValue={inputValue}
        placeholder="Search for beneficiaries"
        multiple
        icon={['far', 'search']}
        disabled={disabled}
        value={beneficiariesList}
        onChange={handlePickerChange}
        onOpen={handleOpen}
        onClose={handleClose}
        onFocus={handleFocus}
        onInputChange={handleInputChange}
        getOptionLabel={getLabelAsName}
        filterOptions={(options) => options.filter((option) => !memoBeneficiaryIdentifiers?.includes(option.key))}
        renderOption={renderOption}
      />
      {!!beneficiariesItems?.length && (
        <Box className={classes.listWrapper}>
          <Repeater direction="vertical" variant="list" items={beneficiariesItems} component={ProfileItem} skeleton={{ size: 0, loading: false }} />
        </Box>
      )}
    </Box>
  )
}

const CategoryPicker = ({
  disabled,
  fieldName,
  handleChange
}: {
  disabled: boolean
  fieldName: Extract<IntroductionFieldNames, 'reason'>
  handleChange: (params: IntroductionsPageParams) => void
}) => {
  const { classes } = useStyles()

  const { queryParams } = useSearchQuery<IntroductionsPageParams>()
  const { reason, mode } = queryParams

  const { fetchWithAbort, result: reasonOptions } = useAbortableFetch<OptionType[]>()

  useEffect(() => {
    if (mode === 'requests' && !reasonOptions) {
      fetchWithAbort({ url: '/prospecting/reasons' })
    }
  }, [fetchWithAbort, mode, reasonOptions])

  const onChange = (event: ChangeEvent<{}>, value: OptionType | null) => {
    handleChange({ [fieldName]: value ? value.value : undefined })
  }

  return (
    <Box className={classes.block}>
      <Typography variant="h4" semiBold className={classes.header}>
        Category
      </Typography>
      <Combobox<OptionType>
        label=""
        disabled={disabled}
        options={reasonOptions || []}
        placeholder="Search for category"
        icon={['far', 'search']}
        value={reasonOptions?.find(({ value }) => value === reason) || null}
        onChange={onChange}
        blurOnSelect
        getOptionLabel={getLabel}
        isOptionEqualToValue={isOptionEqualToValue}
      />
    </Box>
  )
}

const TargetingStatusesController = ({
  disabled,
  fieldName,
  handleChange,
  handleDelete
}: {
  disabled: boolean
  fieldName: Extract<IntroductionFieldNames, 'targetingStatuses'>
  handleChange: (params: IntroductionsPageParams) => void
  handleDelete: (email: string, field: Extract<IntroductionFieldNames, 'targetingStatuses'>) => void
}) => {
  const { classes } = useStyles()
  const { queryParams } = useSearchQuery<IntroductionsPageParams>()

  const { fetchWithAbort, result: targetingStatusesList } = useAbortableFetch<OptionType[]>()

  useEffect(() => {
    fetchWithAbort({ url: '/prospecting/introducerstatuses' })
  }, [fetchWithAbort])

  const targetingStatusesValue = queryParams.targetingStatuses?.split(',') || []
  const targetingStatuses =
    targetingStatusesList?.length && targetingStatusesValue.length
      ? (targetingStatusesValue.map((value) => targetingStatusesList.find((option) => option.value === value)).filter(Boolean) as OptionType[])
      : []

  const handleStatusChange = (event: ChangeEvent<{}>, value: OptionType[]) => {
    const updated = value.map((option) => option.value)
    handleChange({ [fieldName]: updated.join(',') })
  }

  return (
    <Box className={classes.block}>
      <Typography variant="h4" semiBold className={classes.header}>
        Targeting statuses
      </Typography>
      <Combobox<OptionType, true>
        label=""
        placeholder="Click to see statuses"
        multiple
        options={targetingStatusesList || []}
        icon={['far', 'search']}
        disabled={disabled}
        value={targetingStatuses}
        onChange={handleStatusChange}
        getOptionLabel={getLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        filterOptions={(options) => options.filter((option) => !targetingStatusesValue.includes(option.value))}
      />
      <ChipsPickList
        items={targetingStatuses.map((status) => ({ ...status, handleDelete: () => handleDelete(status.value, fieldName) }))}
        disabled={disabled}
      />
    </Box>
  )
}

const RequestControl = ({ disabled, handleChange }: { disabled: boolean; handleChange: (params: IntroductionsPageParams) => void }) => {
  const { classes } = useStyles()
  const { queryParams } = useSearchQuery<IntroductionsPageParams>()
  const { requestType } = queryParams

  const handleRequestChange = (e: ChangeEvent<{ name: string; value: unknown }>) => {
    const name = e.target.name as 'requestType'
    const value = e.target.value as IntroductionType

    handleChange({
      [name]: value
    })
  }

  return (
    <Box className={classes.block}>
      <Typography variant="h4" semiBold className={classes.header}>
        Request type
      </Typography>
      <RadioGroup name="requestType" value={requestType || 'active'} onChange={handleRequestChange}>
        {requestTypeOptions.map((item) => (
          <Filters.Radio disabled={disabled} key={item.value} value={item.value} label={item.label} />
        ))}
      </RadioGroup>
    </Box>
  )
}

const IntroductionsFilters = ({ total, opened, contentLoading, handleChange, toggleOpen, disabled, reset, anchorEl }: IntroductionsFiltersType) => {
  const { queryParams } = useSearchQuery<IntroductionsPageParams>()
  const { mode } = queryParams
  const { classes } = useStyles()

  const handleDelete = (value: string | OptionType, fieldName: keyof IntroductionsPageParams) => {
    const current = queryParams[fieldName]

    if (current) {
      const updated = current
        .split(',')
        .filter((segment: string) => {
          const comparisonValue = typeof value === 'string' ? value : value.value
          return segment !== comparisonValue
        })
        .join()
      handleChange({ [fieldName]: updated })
    }
  }

  return (
    <Filters
      opened={opened}
      hideBorder
      className={classes.container}
      disabled={disabled}
      contentLoading={contentLoading}
      total={total}
      anchorEl={anchorEl || null}
      toggleOpen={toggleOpen}
      reset={reset}
    >
      <ShowRequestsControl disabled={disabled} handleChange={handleChange} />
      <AssigneeControl disabled={disabled} handleChange={handleChange} />
      <VisibleForControl disabled={disabled} handleChange={handleChange} />
      {mode === 'contacts' && (
        <TargetingStatusesController disabled={disabled} fieldName="targetingStatuses" handleChange={handleChange} handleDelete={handleDelete} />
      )}
      <ContactsPicker disabled={disabled} fieldName="entityIdentifiers" handleDelete={handleDelete} handleChange={handleChange} />
      {mode !== 'contacts' && (
        <>
          <BeneficiariesPicker disabled={disabled} fieldName="beneficiaryIdentifiers" handleDelete={handleDelete} handleChange={handleChange} />
          <CategoryPicker disabled={disabled} fieldName="reason" handleChange={handleChange} />
        </>
      )}
      <RequestControl disabled={disabled} handleChange={handleChange} />
    </Filters>
  )
}

export { Controller } from './index'
export default IntroductionsFilters
