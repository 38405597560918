import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { Link, useRouteMatch } from 'react-router-dom'

import { Button } from '_shared/buttons'
import Typography from '_shared/Typography'

import Success from '_core/components/Success'

import useSidepanelClose from '_core/hooks/useSidepanelClose'

const InvalidTupleSuccess = () => {
  const match = useRouteMatch()
  const handleClose = useSidepanelClose()
  const url = `${match.url}`.replace('/success', '')

  return (
    <Box height="calc(100vh - 159px)">
      <Success variant="centered" text={<Typography>The selected tuples were successfully marked as invalid.</Typography>}>
        <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
          <Link to={url}>
            <Button variant="outlined" startIcon={<FontAwesomeIcon icon={['far', 'list-check']} style={{ fontSize: 14 }} />}>
              View other tuples
            </Button>
          </Link>
          <Button variant="text" onClick={handleClose} color="secondary">
            Close
          </Button>
        </Box>
      </Success>
    </Box>
  )
}

export default InvalidTupleSuccess
