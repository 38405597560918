import React, { ComponentProps } from 'react'

import { Grid, useMediaQuery, Breakpoint, Theme, GridSize } from '@mui/material'
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'

import theme from 'AppTheme'

const useStyles = makeStyles<{ order?: number }>()((theme, { order }) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      order: order || 'inherit'
    }
  }
}))

export const Column = ({
  children,
  two,
  order,
  double,
  style,
  md,
  sm,
  xs,
  lg,
  id,
  className
}: {
  children: React.ReactNode
  two?: boolean
  order?: number
  double?: boolean
  style?: any
  md?: GridSize
  sm?: GridSize
  xs?: GridSize
  lg?: GridSize
  className?: string
  id?: string
}) => {
  const {
    classes: { column },
    cx
  } = useStyles({ order })
  return (
    <Grid
      item
      lg={lg}
      md={md || (double ? (two ? 12 : 8) : two ? 6 : 4)}
      sm={sm}
      xs={xs || 12}
      classes={{ root: cx(column, className) }}
      style={style}
      {...(id && { id })}
    >
      {children}
    </Grid>
  )
}

export const Columns = ({ spacing = 2, className, direction, children, ...rest }: Omit<ComponentProps<typeof Grid>, 'item' | 'container'>) => {
  return (
    <Grid container spacing={spacing} direction={direction} className={className} {...rest}>
      {children}
    </Grid>
  )
}

export const Wide = ({ children, forceNarrow }: { children: React.ReactNode; forceNarrow?: boolean }) => {
  const visible = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
  return !forceNarrow && visible ? <> {children} </> : null
}

export const Narrow = ({ children, forceNarrow }: { children: React.ReactNode; forceNarrow?: boolean }) => {
  const visible = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const { breakpoints, ...restTheme } = theme
  const forceNarrowTheme = createTheme({
    ...restTheme,
    breakpoints: {
      values: {
        ...breakpoints.values,
        md: Infinity,
        laptop: Infinity,
        lg: Infinity,
        lgXl: Infinity,
        xl: Infinity
      }
    }
  })

  if (forceNarrow) {
    return <ThemeProvider theme={forceNarrowTheme}>{children}</ThemeProvider>
  }
  return visible ? <>{children}</> : null
}

/* to enable middle view => instead of Wide use WideStrict */
export const WideStrict = ({ children, forceNarrow }: { children: React.ReactNode; forceNarrow?: boolean }) => {
  const visible = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  return !forceNarrow && visible ? <>{children}</> : null
}

export const Middle = ({ children, forceNarrow, includeWide }: { children: React.ReactNode; forceNarrow?: boolean; includeWide?: boolean }) => {
  // md, laptop
  const visible = useMediaQuery((theme: Theme) => (includeWide ? theme.breakpoints.up('sm') : theme.breakpoints.between('sm', 'lg')))
  return !forceNarrow && visible ? <>{children}</> : null
}

export const NarrowStrict = ({ children, forceNarrow }: { children: React.ReactNode; forceNarrow?: boolean }) => {
  const visible = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const { breakpoints, ...restTheme } = theme
  const forceNarrowTheme = createTheme({
    ...restTheme,
    breakpoints: {
      values: {
        ...breakpoints.values,
        tablet: Infinity,
        md: Infinity,
        laptop: Infinity,
        lg: Infinity,
        lgXl: Infinity,
        xl: Infinity
      }
    }
  })

  if (forceNarrow) {
    return <ThemeProvider theme={forceNarrowTheme}>{children}</ThemeProvider>
  }
  return visible ? <>{children}</> : null
}

export const SidepanelNarrow = ({ children }: { children: React.ReactNode }) => {
  const visible = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return visible ? <>{children}</> : null
}

export const SidepanelWide = ({ children }: { children: React.ReactNode }) => {
  const visible = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  return visible ? <>{children}</> : null
}

export const useWide = (breakpoint: Breakpoint = 'md') => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up(breakpoint))
}

export const useSidepanelWide = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up('sm'))
}
