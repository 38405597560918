import { ComponentProps } from 'react'

import { useMediaQuery } from '@mui/material'

import DefaultProfileSummary from '_core/components/DefaultProfileSummary'
import MiniProfileSummary from '_core/components/MiniProfileSummary'

const ProfileSummary = ({
  introducers,
  ...profileProps
}: { introducers: ComponentProps<typeof MiniProfileSummary>['introducers'] | null } & ProfileSummaryProps) => {
  const minicard = useMediaQuery('(max-height:160px)')

  if (introducers && minicard) {
    return <MiniProfileSummary title={profileProps.title} score={profileProps.score} loading={profileProps.loading} introducers={introducers} />
  }

  return <DefaultProfileSummary {...profileProps} />
}

export default ProfileSummary
