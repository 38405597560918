import { useContext, useEffect, useState } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import { transformCompanyData } from '_core/hooks/useCompanyMarketData'

import { mergeUrlWithParams } from 'utils/httpUtils'

export type FiltersType = {
  website: string
  minimumEmployeeCount: number | null
  maximumEmployeeCount: number | null
  industries: string[]
  countries: string[]
  companyType: MarketDataCompanyType | ''
}

export type CompaniesType = {
  data: CompaniesMarketDataType[]
  filters?: FiltersType
} & PaginationMarketData

export const transformResponse = (companies: CompaniesMarketDataRes['data']): CompaniesType['data'] => {
  return companies.map((company) => ({
    ...transformCompanyData(company),
    companyMd5: company.companyMd5 || '',
    score: company.score || 0
  }))
}

const useSimilarCompanies = (companyMd5: string, initParams: FiltersType, isConfigured: boolean, hasValidFilters: boolean) => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { fetchWithAbort } = useAbortableFetch<CompaniesMarketDataRes>()
  const [similar, setSimilar] = useState<CompaniesType | null>(null)

  useEffect(() => {
    return () => {
      setSimilar(null)
    }
  }, [companyMd5])

  const getSimilar = async () => {
    if (initParams && teamNumber) {
      const { minimumEmployeeCount, maximumEmployeeCount, ...restParams } = initParams
      const res = await fetchWithAbort({
        url: mergeUrlWithParams('/cloudhub/companies/similar', {
          ...restParams,
          minimumEmployeeCount: (minimumEmployeeCount ?? '').toString(),
          maximumEmployeeCount: (maximumEmployeeCount ?? '').toString(),
          teamNumber: teamNumber.toString(),
          titlecase: 'true',
          ...(similar?.scrollToken && { scrollToken: similar.scrollToken })
        })
      })
      if (res) {
        const transformedData = transformResponse(res.data)
        setSimilar({
          data: similar?.data ? [...similar.data, ...transformedData] : transformedData,
          scrollToken: res.scrollToken || '',
          total: res.total || 0,
          filters: initParams
        })
      }
    }
  }

  useEffect(() => {
    if (isConfigured && !similar && teamNumber) {
      hasValidFilters
        ? getSimilar()
        : setSimilar({
            data: [],
            scrollToken: '',
            total: 0
          })
    }
  }, [isConfigured, similar, teamNumber, hasValidFilters])

  const getCompanyDetails = (name: string, website?: string): CompaniesMarketDataType | undefined => {
    return (similar?.data || []).find((item) => item.name === name || (website && item.website === website))
  }

  return {
    similar,
    getSimilar,
    getCompanyDetails
  }
}

export default useSimilarCompanies
