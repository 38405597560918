import { HTMLAttributes, SyntheticEvent, useCallback, useEffect } from 'react'

import { SelectChangeEvent, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Combobox from '_shared/forms/Combobox'
import Select from '_shared/forms/Select'
import Typography from '_shared/Typography'

import { OptionType } from '_core/components/dialogs/AddTeamMember'
import ProfileItem from '_core/components/ProfileItem'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { useLookUpActiveDirectoryMembers } from '_core/hooks/useLookup'
import useSearchQuery from '_core/hooks/useSearchQuery'

import Filters from './index'

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 270,
      overflow: 'auto',
      height: '100%'
    }
  },
  block: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    '& .MuiTypography-h4, & .MuiTypography-body1': {
      marginBottom: theme.spacing(1)
    }
  },
  header: {
    marginBottom: theme.spacing(1)
  }
}))

type MemberType = Pick<OptionType, 'value' | 'type' | 'label'>

export type ManualEditsFiltersType = {
  total?: number
  opened: boolean
  disabled: boolean
  contentLoading: boolean
  toggleOpen: () => void
  reset: () => Promise<ManualEditsPageParams | undefined>
  anchorEl?: HTMLElement | null
  handleChange: (params: ManualEditsPageParams) => void
  entityOptions: { value: ManualEditsEntityType; label: string }[]
}

const ManualEditsFilters = (props: ManualEditsFiltersType) => {
  const { classes } = useStyles()
  const { total, opened, contentLoading, anchorEl, handleChange: handleFiltersChange, entityOptions, toggleOpen } = props
  const { queryParams } = useSearchQuery<ManualEditsPageParams>()
  const { entityType, contributor } = queryParams

  const { lookUpActiveDirectoryMembers, forceAbort } = useLookUpActiveDirectoryMembers()

  const loadOptions = useCallback(
    async (searchTerm) => {
      const result = await lookUpActiveDirectoryMembers(searchTerm)
      if (result) {
        return result.users.map((user) => ({
          value: user.emailAddress,
          label: user.displayName,
          type: 'user' as MemberType['type']
        }))
      }
    },
    [lookUpActiveDirectoryMembers]
  )

  const {
    inputValue,
    value,
    open,
    options,
    optionsLoading,
    handleClose,
    handleOpen,
    handleFocus,
    updateValue,
    handleInputChange,
    handleValueChange,
    filterOptions
  } = useAsyncCombobox({
    loadOptions,
    forceAbort
  })

  const disabled = props.disabled || !!(!inputValue && contributor)

  const setInitialContributorValue = async (contributor: string) => {
    const result = await lookUpActiveDirectoryMembers(contributor)
    if (result) {
      updateValue(
        result.users.map((user) => ({
          value: user.emailAddress,
          label: user.displayName,
          type: 'user' as MemberType['type']
        }))[0]
      )
    }
  }

  useEffect(() => {
    if (!inputValue && contributor) {
      setInitialContributorValue(contributor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributor])

  const handleSelect = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target as { value: ManualEditsPageParams['entityType'] }
    handleFiltersChange({ entityType: value })
  }

  const handleContributorValueChange = (e: SyntheticEvent<Element, Event>, member: MemberType | null) => {
    handleFiltersChange({ contributor: member?.value })
    handleValueChange(e, member)
  }

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, member: MemberType | null) => {
    return member ? (
      <li {...props}>
        <ProfileItem name={member.label} userKey={member.value} byline={member.value} />
      </li>
    ) : (
      <></>
    )
  }

  const handleReset = async () => {
    const { contributor = '' } = (await props.reset()) || {}
    if (contributor) {
      setInitialContributorValue(contributor)
    }
  }

  return (
    <Filters
      opened={opened}
      hideBorder
      className={classes.container}
      disabled={disabled}
      contentLoading={contentLoading}
      total={total}
      reset={handleReset}
      anchorEl={anchorEl || null}
      toggleOpen={toggleOpen}
    >
      <Box className={classes.block}>
        <Typography variant="h4" semiBold className={classes.header}>
          Type
        </Typography>
        <Select
          size="small"
          name="entityType"
          disabled={disabled || !entityOptions.length}
          options={entityOptions}
          value={entityType || entityOptions[0]?.value}
          onChange={handleSelect}
          fullWidth
        />
      </Box>
      <Box className={classes.block}>
        <Typography variant="h4" semiBold className={classes.header}>
          Pick contributor
        </Typography>
        <Combobox<MemberType>
          open={open}
          loading={optionsLoading}
          options={options}
          value={value}
          inputValue={inputValue}
          placeholder="Search by name or email address"
          disabled={disabled}
          onChange={handleContributorValueChange}
          getOptionLabel={(option) => option?.label || ''}
          renderOption={renderOption}
          onInputChange={handleInputChange}
          onOpen={handleOpen}
          onClose={handleClose}
          onFocus={handleFocus}
          filterOptions={filterOptions}
        />
      </Box>
    </Filters>
  )
}

export { Controller } from './index'
export default ManualEditsFilters
