import React, { useState, useEffect } from 'react'

import { Box, FormGroup, Switch } from '@mui/material'
import { useSnackbar } from 'notistack'
import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import Heading from '_core/components/Heading'
import { useWide } from '_core/components/layout'
import SharingBar from '_core/components/team/SharingBar'
import Widget from '_core/components/widgets'

import useTeam from '_core/hooks/useTeam'

type TeamAdminSettingsWidgetProps = {
  loading: boolean
  team?: Team
  setTeam: (val: Team) => void
}

const useStyles = makeStyles()((theme) => ({
  settingsSwitchShifted: {
    marginTop: theme.spacing(1)
  },
  settingsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& a': {
      color: theme.palette.primary.main
    }
  },
  sharingBar: {
    paddingTop: theme.spacing(0.25)
  }
}))

const TeamAdminSettingsWidget = ({ team, setTeam, loading }: TeamAdminSettingsWidgetProps) => {
  const [indexing, setIndexing] = useState(team?.isActive)
  const [load, setLoad] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { setTeamIndexing } = useTeam()
  const wide = useWide()
  const { classes } = useStyles()

  useEffect(() => {
    if (typeof team?.isActive === 'boolean') setIndexing(team.isActive)
  }, [team?.isActive])

  const handleIndexingClick = async (e: React.SyntheticEvent) => {
    if (team?.id) {
      e.preventDefault()
      setIndexing((indexing) => !indexing)
      setLoad(true)
      try {
        const res = await setTeamIndexing(team.id.toString(), !indexing)
        if (res) {
          enqueueSnackbar(`The team "${team.name}" has been ${res.isActive ? 'activated' : 'not activated'}`)
          setTeam({ ...team, isActive: res.isActive })
        }
      } catch (error) {
        console.log(error)
        setIndexing((indexing) => !indexing)
      } finally {
        setLoad(false)
      }
    }
  }

  return (
    <Widget scope={wide ? 'none' : 'default'}>
      <Heading title="Admin settings" icon={['far', 'tools']} />
      <Box marginTop={{ md: -1.5 }} py={1}>
        <FormGroup row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <Box marginRight={2}>
            <Skeleton variant="text" width="100%" condition={loading}>
              <Typography variant="body1">Activate team</Typography>
            </Skeleton>
            <Skeleton variant="text" width="100%" condition={loading}>
              <Typography color="text.secondary">Analyze contributor mailboxes</Typography>
            </Skeleton>
          </Box>

          <Box marginRight="-8px" marginLeft="-11px">
            <Switch
              className={classes.settingsSwitchShifted}
              color="primary"
              disabled={loading || load}
              checked={!!indexing}
              onChange={handleIndexingClick}
            />
          </Box>
        </FormGroup>
      </Box>
      <Box className={classes.settingsRow} py={1}>
        <Box marginRight={2}>
          <Skeleton variant="text" width="100%" condition={loading}>
            <Typography variant="body1">Default sharing level</Typography>
          </Skeleton>
          <Skeleton variant="text" width="100%" condition={loading}>
            <SharingBar userSharing={team?.defaultSharingOptions} type="team" className={classes.sharingBar} />
          </Skeleton>
        </Box>
      </Box>
    </Widget>
  )
}

export default TeamAdminSettingsWidget
