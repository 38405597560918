import { useCallback, useContext, useRef, useState } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import useAbortableFetch from '_core/hooks/useAbortableFetch'

import { mergeUrlWithParams, post } from 'utils/httpUtils'

export type ContributorData = {
  primaryEmail: string
  networkIdentifier: string
  userKeyMd5: string
  firstName: string
  lastName: string
  fullName: string
  userType: string
  userKeyPlainText: string
  securityPartition: string
  markedForDeletion: string
  missingFromUserStoreDateTimeUtc: string
  dontIndex: boolean
  storageConfirmation: string
}

export type GeneralFieldType =
  | 'Company'
  | 'Country'
  | 'Industry'
  | 'Location'
  | 'Major'
  | 'Region'
  | 'Role'
  | 'School'
  | 'SubRole'
  | 'Skill'
  | 'Title'

export type RemoteTagsListItem = { rowNumber: number; total: number; category: string; name: string; deprecated: null; entityCount: number }

export const TaggableType = {
  people: 'person',
  companies: 'company',
  meetings: 'meeting'
}

type ActiveDirUser = {
  displayName: string
  emailAddress: string
}

export type ActiveDirLookup = {
  users: ActiveDirUser[]
  groups: {
    displayName: string
    id: string
    members: ActiveDirUser[]
    providerName: string
    isValid: boolean
  }[]
}

type LookUpPeopleParams = { ColleagueFlag?: ColleagueFlag }

export const useLookUpPeople = (params?: LookUpPeopleParams) => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { fetchWithAbort, loading, setForceLoading, forceAbort } = useAbortableFetch<{ data: PeopleListItem[] }>()

  const lookupPeople = useCallback(
    async (searchTerm?: string, skip?: PeopleListItem['PersonMd5'][], take?: number) => {
      const endpoint = mergeUrlWithParams('/people', {
        teamNumber: `${teamNumber}`,
        searchTerm,
        sortBy: 'ScoreDesc',
        take: `${take || 10}`,
        ...(params ? params : {})
      })
      const result = await fetchWithAbort({ url: endpoint })
      return skip
        ? result?.data.filter((d) => !skip?.includes(d.PersonMd5) && (d.MyUserKeyMd5 ? !skip?.includes(d.MyUserKeyMd5) : true))
        : result?.data
    },
    [fetchWithAbort, teamNumber]
  )

  return { lookupPeople, loading, setForceLoading, forceAbort }
}

export const useLookUpCompanies = () => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { fetchWithAbort, loading, setForceLoading, forceAbort } = useAbortableFetch<{ data: CompaniesListItem[] }>()

  const lookupCompanies = useCallback(
    async (searchTerm?: string, skip?: CompaniesListItem['CompanyMd5'][], take?: number) => {
      const endpoint = mergeUrlWithParams('/companies', { teamNumber: `${teamNumber}`, searchTerm, sortBy: 'ScoreDesc', take: `${take || 10}` })
      const result = await fetchWithAbort({ url: endpoint })
      return skip ? result?.data.filter((d) => !skip?.includes(d.CompanyMd5)) : result?.data
    },
    [fetchWithAbort, teamNumber]
  )

  return { lookupCompanies, loading, setForceLoading, forceAbort }
}

export const useLookUpBothPeopleCompanies = () => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { fetchWithAbort, loading, setForceLoading, forceAbort } = useAbortableFetch<{ data: PeopleListItem[] } | { data: CompaniesListItem[] }>()

  const lookupBothPeopleCompanies = useCallback(
    async (searchTerm?: string, skip?: Partial<CompaniesListItem['CompanyMd5'] & PeopleListItem['PersonMd5']>[], take?: number) => {
      return Promise.all(
        (['people', 'companies'] as Entities[]).map((entity) => {
          const url = `/${entity}?teamNumber=${teamNumber}&SearchTerm=${searchTerm}&SortBy=ScoreDesc&Take=${take || 10}`
          return entity === 'people' ? fetchWithAbort({ url }) : fetchWithAbort({ url })
        })
      ).then((resp) => {
        const data = resp
          .reduce((acc, item) => (item ? acc.concat(item.data) : acc), [] as (PeopleListItem | CompaniesListItem)[])
          .flat()
          .filter((d: any) => !skip?.includes(d.PersonMd5 || d.CompanyMd5))
        return [...data].sort((a, b) => b.Score - a.Score)
      })
    },
    [fetchWithAbort, teamNumber]
  )

  return { lookupBothPeopleCompanies, loading, setForceLoading, forceAbort }
}

export const useLookUpContributors = () => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { fetchWithAbort, result, loading, setForceLoading, forceAbort } = useAbortableFetch<{ data: TeamMemberRes[] }>()

  const lookupContributors = useCallback(
    async (searchTerm?: string, skip?: TeamMemberRes['userKeyMd5'][], take?: number) => {
      const endpoint = mergeUrlWithParams(`/teams/${teamNumber}/members`, {
        roleTypes: 'Member',
        userTypes: 'StandardUser',
        take: `${take || 10}`,
        searchTerm
      })
      const result = await fetchWithAbort({ url: endpoint })
      return skip ? result?.data.filter((d) => !skip?.includes(d.userKeyMd5)) : result?.data
    },
    [fetchWithAbort, teamNumber]
  )

  return { lookupContributors, loading, setForceLoading, forceAbort, contributors: result?.data }
}

export const useLookUpContributorsByIds = () => {
  const idsRef = useRef<string[]>()
  const { fetchWithAbort, loading, setForceLoading, reset, result } = useAbortableFetch<ContributorData[]>()

  const sortedResut = (res: typeof result) => {
    if (res) {
      return idsRef.current?.map((id) => res.find((item) => item.userKeyMd5 === id)) as ContributorData[]
    }
  }

  const lookUpContributorsByIds = useCallback(
    async (ids: string[]) => {
      idsRef.current = ids
      const endpoint = mergeUrlWithParams('/users/contributors', { ids })
      const result = await fetchWithAbort({ url: endpoint })
      return sortedResut(result)
    },
    [fetchWithAbort]
  )

  return { lookUpContributorsByIds, loading, setForceLoading, contributorsByIds: sortedResut(result), reset }
}

export const useLookUpPeopleByIds = () => {
  const { teamContextValue } = useContext(TeamContext)

  const [{ result, loading }, setData] = useState<{
    result?: {
      data: PeopleListItem[]
      are_more: boolean
      page_start: number
      page_end: number
      total_item_count: number
    }
    loading: boolean
  }>({ loading: false })

  const setLoading = () => {
    setData({ loading: true })
  }

  const lookUpPeopleByIds = async (ids: string[], teamNumber: number = teamContextValue.teamNumber, skip?: number, searchTerm?: string) => {
    setLoading()
    const r = await post<typeof result>(`/people/leads?teamNumber=${teamNumber}`, { ids, includeStats: true, skip, searchTerm })
    setData({ loading: false, result: r })
    return r
  }

  const reset = () => {
    setData({ loading: false })
  }

  return {
    lookUpPeopleByIds,
    loading,
    setForceLoading: setLoading,
    peopleByIds: result,
    reset
  }
}

export const useLookUpCompaniesByIds = () => {
  const { teamContextValue } = useContext(TeamContext)
  const [{ result, loading }, setData] = useState<{
    result?: {
      data: CompaniesListItem[]
      are_more: boolean
      page_start: number
      page_end: number
      total_item_count: number
    }
    loading: boolean
  }>({ loading: false })

  const setLoading = () => {
    setData({ loading: true })
  }

  const lookUpCompaniesByIds = async (ids: string[], teamNumber: number = teamContextValue.teamNumber, skip?: number, searchTerm?: string) => {
    setLoading()
    const r = await post<typeof result>(`/companies/leads?teamNumber=${teamNumber}`, { ids, includeStats: true, skip, searchTerm })
    setData({ loading: false, result: r })
    return r
  }

  const reset = () => {
    setData({ loading: false })
  }

  return {
    lookUpCompaniesByIds,
    loading,
    setForceLoading: setLoading,
    companiesByIds: result,
    reset
  }
}

export const useLookUpActiveDirectoryMembers = () => {
  const { fetchWithAbort, loading, setForceLoading, forceAbort } = useAbortableFetch<ActiveDirLookup>()

  const lookUpActiveDirectoryMembers = useCallback(
    async (searchTerm?: string, skip?: string[]) => {
      const endpoint = `/teams/principals?searchTerm=${searchTerm}`

      const result = await fetchWithAbort({ url: endpoint })
      if (result) {
        return skip
          ? {
              users: result?.users.filter((user) => !skip?.includes(user.emailAddress || user.displayName)),
              groups: result?.groups.filter((group) => !skip?.includes(group.displayName))
            }
          : result
      }
    },
    [fetchWithAbort]
  )

  return { lookUpActiveDirectoryMembers, loading, setForceLoading, forceAbort }
}

export const useLookUpTagNames = (entity: keyof typeof TaggableType) => {
  const { fetchWithAbort, loading, setForceLoading, forceAbort } = useAbortableFetch<{
    data: {
      rowNumber: number
      total: number
      name: string
      taggableType: 'Person' | 'Contact'
      taggableTypeId: number
    }[]
  }>()

  const lookUpTagNames = useCallback(
    async (searchText: string): Promise<string[] | undefined> => {
      const res = await fetchWithAbort({
        url: mergeUrlWithParams('/tags/categories', {
          searchText,
          taggableType: TaggableType[entity]
        })
      })
      if (res) {
        return res.data?.map((el) => el.name)
      }
    },
    [entity, fetchWithAbort]
  )

  return { lookUpTagNames, loading, setForceLoading, forceAbort }
}

export const useLookUpTagValues = (entity: keyof typeof TaggableType) => {
  const { fetchWithAbort, loading, result, setForceLoading, forceAbort, reset } = useAbortableFetch<{ data: RemoteTagsListItem[] }>()

  const lookUpTagValues = useCallback(
    async (searchText?: string, categoryName?: string) => {
      const res = await fetchWithAbort({
        url: mergeUrlWithParams('/tags/tags', { searchText, categoryName, taggableType: TaggableType[entity] })
      })
      if (res) {
        return res.data?.map(({ category: categoryName, name: tagName, entityCount }) => ({ categoryName, tagName, entityCount }))
      }
    },
    [entity, fetchWithAbort]
  )

  return {
    lookUpTagValues,
    tagValues: result?.data?.map(({ category: categoryName, name: tagName, entityCount }) => ({ categoryName, tagName, entityCount })),
    loading,
    setForceLoading,
    forceAbort,
    reset
  }
}

export const useLookUpUserCompanies = () => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { fetchWithAbort, loading, setForceLoading, result, forceAbort, reset } = useAbortableFetch<{ data: UserCompaniesListItem[] }>()

  const lookupUserCompanies = useCallback(
    async (userMd5: string, searchTerm?: string, take?: number) => {
      const endpoint = mergeUrlWithParams(`/users/${userMd5}/companies`, {
        teamNumber: `${teamNumber}`,
        searchTerm,
        sortBy: 'ScoreDesc',
        take: `${take || 10}`
      })
      const result = await fetchWithAbort({ url: endpoint })
      return result
    },
    [fetchWithAbort, teamNumber]
  )

  return { lookupUserCompanies, loading, setForceLoading, forceAbort, result, reset }
}

export const useLookUpUserContacts = () => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { fetchWithAbort, loading, setForceLoading, result, forceAbort, reset } = useAbortableFetch<{ data: UserPeopleListItem[] }>()

  const lookupUserContacts = useCallback(
    async (userMd5: string, searchTerm?: string, take?: number) => {
      const endpoint = mergeUrlWithParams(`/users/${userMd5}/people`, {
        teamNumber: `${teamNumber}`,
        searchTerm,
        sortBy: 'ScoreDesc',
        take: `${take || 10}`
      })
      const result = await fetchWithAbort({ url: endpoint })
      return result
    },
    [fetchWithAbort, teamNumber]
  )

  return { lookupUserContacts, loading, setForceLoading, forceAbort, result, reset }
}

export const useLookUpCompanyIntroducers = () => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue

  const { fetchWithAbort, loading, result, setForceLoading, forceAbort, reset } = useAbortableFetch<{
    data: CompanyIntroducerListItem[]
    are_more: boolean
    page_start: number
    page_end: number
    total_item_count: number
  }>()

  const lookUpCompanyIntroducers = useCallback(
    async (companyMd5: string, searchTerm?: string, take?: number, skip?: number) => {
      const endpoint = mergeUrlWithParams(`/companies/${companyMd5}/introducers`, {
        teamNumber: `${teamNumber}`,
        searchTerm,
        sortBy: 'ScoreDesc',
        includeStats: `${true}`,
        take: `${take || 10}`,
        skip: `${skip || 0}`
      })
      const result = await fetchWithAbort({ url: endpoint })
      return result
    },
    [fetchWithAbort, teamNumber]
  )

  return {
    lookUpCompanyIntroducers,
    loading,
    result,
    reset,
    setForceLoading,
    forceAbort
  }
}

export const useLookUpPersonIntroducers = () => {
  const { teamContextValue } = useContext(TeamContext)
  const { fetchWithAbort, loading, result, setForceLoading, forceAbort, reset } = useAbortableFetch<{
    data: PersonIntroducerListItem[]
    are_more: boolean
    page_start: number
    page_end: number
    total_item_count: number
  }>()

  const lookUpPersonIntroducers = useCallback(
    async ({
      personMd5,
      teamNumber = teamContextValue.teamNumber,
      searchTerm,
      take,
      skip
    }: {
      personMd5: string
      teamNumber?: number
      searchTerm?: string
      take?: number
      skip?: number
    }) => {
      const endpoint = mergeUrlWithParams(`/people/${personMd5}/introducers`, {
        teamNumber: `${teamNumber}`,
        searchTerm,
        sortBy: 'ScoreDesc',
        includeStats: `${true}`,
        take: `${take || 10}`,
        skip: `${skip || 0}`
      })
      const result = await fetchWithAbort({ url: endpoint })
      return result
    },
    [fetchWithAbort, teamContextValue.teamNumber]
  )

  return {
    lookUpPersonIntroducers,
    loading,
    result,
    reset,
    setForceLoading,
    forceAbort
  }
}

export const useGeneralLookUp = () => {
  const { fetchWithAbort, loading, setForceLoading, forceAbort } = useAbortableFetch<{ data: { name: string; count: number }[] }>()

  const lookUpGeneral = useCallback(
    async (field: GeneralFieldType, searchTerm?: string) => {
      const endpoint = mergeUrlWithParams('/cloudhub/autocomplete', {
        field,
        searchTerm
      })
      const result = await fetchWithAbort({ url: endpoint })
      return result?.data
    },
    [fetchWithAbort]
  )

  return { lookUpGeneral, loading, setForceLoading, forceAbort }
}

export const useLookUpMarketDataPeople = () => {
  const { fetchWithAbort, loading, setForceLoading, forceAbort } = useAbortableFetch<{ data: PeopleMarketDataRes['data'] }>()

  const lookUpMarketDataPeople = useCallback(
    async (teamNumber: string, searchTerm?: string, take: number = 5) => {
      const endpoint = mergeUrlWithParams('/cloudhub/people/search', {
        teamNumber,
        size: `${take}`,
        searchTerm: searchTerm
      })
      const result = await fetchWithAbort({ url: endpoint })
      return result?.data
    },
    [fetchWithAbort]
  )

  return { lookUpMarketDataPeople, loading, setForceLoading, forceAbort }
}

export const useLookUpMarketDataCompanies = () => {
  const { fetchWithAbort, loading, setForceLoading, forceAbort } = useAbortableFetch<{ data: CompaniesMarketDataRes['data'] }>()

  const lookUpMarketDataCompanies = useCallback(
    async (teamNumber: string, searchTerm?: string, take: number = 5) => {
      const endpoint = mergeUrlWithParams('/cloudhub/companies/search', {
        teamNumber,
        size: `${take}`,
        searchTerm
      })
      const result = await fetchWithAbort({ url: endpoint })
      return result?.data
    },
    [fetchWithAbort]
  )

  return { lookUpMarketDataCompanies, loading, setForceLoading, forceAbort }
}
