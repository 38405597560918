import { useMemo } from 'react'

import useEntityEndpoint from '_core/hooks/useEntityEndpoint'

const useMarketDataIntegration = (isReady: boolean = true): Partial<MarketDataIntegration> => {
  const memoUrls = useMemo(
    () => [
      { key: 'integration', url: '/usersettings/marketDataIntegration' },
      { key: 'isConfigured', url: '/cloudhub/configured' }
    ],
    []
  )

  const { result } = useEntityEndpoint<{
    data: [{ integration: MarketDataIntegration; isConfigured: boolean }]
  } | null>(null, isReady ? memoUrls : null)

  const { integration, isConfigured } = result?.data[0] || {}
  const enabled = integration?.enabled && isConfigured
  const showSimilarPeople = integration?.showSimilarPeople && isConfigured
  const showSimilarCompanies = integration?.showSimilarCompanies && isConfigured

  return { enabled, showSimilarPeople, showSimilarCompanies }
}

export default useMarketDataIntegration
