import React, { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { IconButton } from '_shared/buttons'

import SidepanelLink from '_core/components/SidepanelLink'
import Topbar from '_core/components/Topbar'
import ColleaguesWidget from '_core/components/widgets/IntroductionColleagues'
import TeamsWidget from '_core/components/widgets/IntroductionTeams'

import useAccessDetailsForm from '_core/hooks/useAccessDetailsForm'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type ListProps = { request: IntroductionRequestResp; loading: boolean }

const CollaboratorsList = ({ request, loading }: ListProps) => {
  const { id } = useParams<{ id: string }>()
  const { setMobileHeader, setSubHeader } = useContext(LayoutContext)
  const { appUserAccesses, teamAccesses, fetching } = useAccessDetailsForm(request)

  const plan = request?.plan || {}

  useEffect(() => {
    setMobileHeader(plan.planSummary, !plan.planSummary)
  }, [setMobileHeader, plan.planSummary])

  useEffect(() => {
    setSubHeader('Collaborators')
    return () => setSubHeader('')
  }, [setSubHeader])

  const Action = (
    <SidepanelLink linkProps={{ to: `${Paths._introductions}/${id}/collaborators/add` }}>
      <IconButton icon={['far', 'edit']} hint="Edit collaborators" color="primary" size="small" />
    </SidepanelLink>
  )

  const isCreator = plan.planUid && request.queriedByAppUserKey === plan.creatorDotAppUserKey
  const load = loading || !teamAccesses || !appUserAccesses || fetching

  return (
    <>
      <Topbar nativeBack action={isCreator ? Action : null} />
      <ColleaguesWidget
        count={teamAccesses?.length}
        loading={load}
        items={
          appUserAccesses
            ? appUserAccesses.slice(0, 3).map((user) => ({
                name: user.name,
                userKey: user.email,
                byline: user.email,
                link: `${Paths._people}/${user.email}`
              }))
            : []
        }
      />
      <TeamsWidget
        count={teamAccesses?.length}
        items={
          teamAccesses
            ? teamAccesses.slice(0, 3).map((team) => ({
                name: team.teamName,
                byline: `${team.teamMembers} member${team.teamMembers > 1 ? 's' : ''}`,
                link: `${Paths._teams}/${team.teamNumber}/members`
              }))
            : []
        }
        loading={load}
      />
    </>
  )
}

export default CollaboratorsList
