import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'

import { invalidReasonMap } from '_pages/manual-edits/data'
import { InformAffiliationType } from '_pages/people/[id]/affiliations'

import { IconButton } from '_shared/buttons'

import AffiliationInformDialog from '_core/components/dialogs/AffiliationInform'
import InformAboutPhoneNumberDialog, { InformPhoneType } from '_core/components/dialogs/InformAboutPhoneNumber'
import EditUndoDialog, { ManualEditRowItem } from '_core/components/dialogs/ManualEditUndo'
import { TextCell, DateCell, MultipleLinedCell } from '_core/components/grid/columns'
import SidepanelLink from '_core/components/SidepanelLink'

import useSearchQuery from '_core/hooks/useSearchQuery'

import { formatDateTime, formatDateTimeFromNow } from 'utils/Utils'

const ActionCell = (
  params: GridRenderCellParams<
    any,
    ManualEditRowItem & {
      className: string
      openUndo: () => void
      openAffiliationDialog: (data: InformAffiliationType) => void
      openPhoneDialog: (data: InformPhoneType) => void
    }
  >
) => {
  const {
    editLink,
    auditLink,
    className,
    assertion,
    openUndo,
    openAffiliationDialog,
    openPhoneDialog,
    identifier2RefText,
    identifier1RefText,
    identifier1Md5
  } = params.row
  const { queryParams } = useSearchQuery<ManualEditsPageParams>()
  const { entityType = '' } = queryParams

  const edit =
    entityType === 'CompanyPreferredName' ||
    entityType === 'CompanyPreferredUrl' ||
    entityType === 'PersonEmail' ||
    entityType === 'PersonPreferredName'

  const audit = assertion === 'Requalified'
  const affiliation = entityType === 'PersonJob'
  const phone = entityType === 'PersonPhone'
  const administration = entityType.includes('Administration')

  const onAffiliationClick = () =>
    openAffiliationDialog({
      companyName: identifier2RefText,
      personName: identifier1RefText,
      personId: identifier1Md5,
      isFormerJob: assertion === 'NoLongerAffiliated',
      isInvalidJob: assertion === 'NeverAffiliated'
    })

  const onPhoneClick = () => {
    openPhoneDialog({
      personId: identifier1Md5,
      personName: identifier1RefText,
      phoneNumber: identifier2RefText,
      isInvalid: assertion === 'InvalidPersonPhone',
      isFormer: assertion === 'NotCurrentPersonPhone'
    })
  }

  return (
    <>
      {edit && editLink && (
        <SidepanelLink linkProps={{ to: editLink }}>
          <IconButton icon={['far', 'edit']} style={{ fontSize: 16 }} hint="Edit" className={className} />
        </SidepanelLink>
      )}
      {audit && auditLink && (
        <SidepanelLink linkProps={{ to: auditLink }}>
          <IconButton icon={['far', 'list-check']} style={{ fontSize: 16 }} hint="Audit" className={className} />
        </SidepanelLink>
      )}
      {(invalidReasonMap[assertion as InvalidReasonsType] || administration) && <EditUndoDialog.TriggerEl open={openUndo} className={className} />}
      {affiliation && <AffiliationInformDialog.TriggerEl open={onAffiliationClick} className={className} />}
      {phone && <InformAboutPhoneNumberDialog.TriggerEl open={onPhoneClick} className={className} />}
    </>
  )
}

const DataCell = ({ text = '-', md5 = '-' }: { text: string; md5: string }) => <MultipleLinedCell title={text} byline1={md5} />

const identifierColumn: GridColDef = {
  field: 'identifier',
  headerName: 'Identifier',
  width: 220,
  flex: 1,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <DataCell text={params.row.identifier1RefText} md5={params.row.identifier1Md5} />
}

const anchorIdentifierColumn: GridColDef = {
  ...identifierColumn,
  field: 'anchorIdentifier',
  headerName: 'Anchor identifier'
}

const token1Column: GridColDef = {
  ...identifierColumn,
  field: 'token1Identifier',
  headerName: 'Token 1'
}

const assertionColumn: GridColDef = {
  field: 'assertion',
  headerName: 'Assertion',
  width: 140,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.assertionLabel} />
}

const token2Column: GridColDef = {
  field: 'token2Identifier',
  headerName: 'Token 2',
  width: 220,
  flex: 1,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <DataCell text={params.row.identifier2RefText} md5={params.row.identifier2Md5} />
}

const preferredNameColumn: GridColDef = {
  ...token2Column,
  field: 'preferredName',
  headerName: 'Preferred name'
}

const preferredUrlColumn: GridColDef = {
  ...token2Column,
  field: 'preferredUrl',
  headerName: 'Preferred url'
}

const preferredEmailColumn: GridColDef = {
  ...token2Column,
  field: 'preferredEmail',
  headerName: 'Preferred email'
}

const preferredPhoneColumn: GridColDef = {
  ...token2Column,
  field: 'preferredPhone',
  headerName: 'Preferred phone number'
}

const companyColumn: GridColDef = {
  ...token2Column,
  field: 'company',
  headerName: 'Company'
}

const assertedByColumn: GridColDef = {
  field: 'assertedBy',
  headerName: 'By',
  width: 220,
  flex: 1,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.assertedBy} />
}

const dateColumn: GridColDef = {
  field: 'date',
  headerName: 'Date',
  width: 195,
  flex: 1,
  filterable: false,
  sortable: true,
  renderCell: (params: GridRenderCellParams) => <DateCell value={formatDateTime(params.row.date)} label={formatDateTimeFromNow(params.row.date, 5)} />
}

const actionColumn: GridColDef = {
  field: 'action',
  headerName: 'Action',
  width: 100,
  flex: 0.5,
  headerAlign: 'center',
  align: 'center',
  filterable: false,
  renderCell: ActionCell
}

export {
  identifierColumn,
  anchorIdentifierColumn,
  token1Column,
  assertionColumn,
  preferredNameColumn,
  preferredPhoneColumn,
  preferredEmailColumn,
  preferredUrlColumn,
  token2Column,
  assertedByColumn,
  dateColumn,
  companyColumn,
  actionColumn
}
