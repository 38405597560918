import { useCallback, useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { sortMap, sortProperties } from '_core/components/sort/ManualEdits'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useFilter from '_core/hooks/useFilter'
import useSearchQuery from '_core/hooks/useSearchQuery'

export const getSort = (direction?: ManualEditsInit['sortDirection'], property?: ManualEditsInit['sortProperty']) => {
  const sortField = Object.keys(sortProperties).find((key) => sortProperties[key] === property) || 'date'
  const dir = direction === 'Ascending' ? 'asc' : 'desc'
  return sortMap[sortField][dir]
}

const useManualEditsUserSettings = (props: { setInitialOpen: (isOpened: boolean) => void; resetEndpoint: string }) => {
  const { search, pathname } = useLocation()
  const { resetEndpoint, setInitialOpen } = props
  const { queryParams, updateQuery } = useSearchQuery<ManualEditsPageParams>()
  const { save } = useFilter()
  const { fetchWithAbort } = useAbortableFetch<ManualEditsInit>()

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!search) {
      setLoading(true)
    }
  }, [pathname, search])

  const setInitial = useCallback(
    ({ rowsPerPage, type: entityType, contributorKey: contributor, isOpened, sortProperty, sortDirection }: ManualEditsInit) => {
      setInitialOpen(isOpened)
      setLoading(false)
      if (!search) {
        updateQuery({ rowsPerPage: `${rowsPerPage || 10}`, entityType, contributor, sort: getSort(sortDirection, sortProperty) })
      }
    },
    [setLoading, updateQuery, setInitialOpen, search]
  )

  const handleChange = (
    saveData: { endpoint: string; getData: (params: ManualEditsPageParams & { isOpened: boolean }) => ManualEditsInit },
    updates: ManualEditsPageParams
  ) => {
    return save(saveData, { ...queryParams, ...updates })
  }

  const reset = async (saveData: { endpoint: string; getData: (params: ManualEditsPageParams & { isOpened: boolean }) => ManualEditsInit }) => {
    setLoading(true)
    const {
      rowsPerPage,
      type: entityType,
      contributorKey: contributor,
      isOpened,
      sortDirection,
      sortProperty
    } = (await fetchWithAbort({ url: resetEndpoint })) || {}
    if (rowsPerPage && entityType && typeof isOpened === 'boolean') {
      const newParams: ManualEditsPageParams = {
        rowsPerPage: `${rowsPerPage}`,
        entityType,
        contributor,
        sort: getSort(sortDirection, sortProperty)
      }

      await save(
        { ...saveData, getData: (params: ManualEditsPageParams & { isOpened: boolean }) => saveData.getData({ ...params, isOpened }) },
        newParams
      )
      setLoading(false)
      return newParams
    }
  }

  return {
    setInitial,
    handleChange,
    reset,
    loading
  }
}

export default useManualEditsUserSettings
