import React, { ComponentProps, forwardRef, ReactNode } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

import Heading from '_core/components/Heading'
import { Narrow, useWide } from '_core/components/layout'
import SidepanelLink from '_core/components/SidepanelLink'

import usePresetScroll from '_core/hooks/usePresetScroll'

const useStyles = makeStyles<{ minHeight?: number }>()((theme, { minHeight }) => ({
  container: {
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      minHeight: minHeight || ''
    }
  },
  widget: {
    margin: 0,
    flex: '1 1 auto',
    ['@media (min-height:160px)']: {
      // eslint-disable-line no-useless-computed-key
      borderTop: '8px #ECEEF0 solid'
    },
    [theme.breakpoints.up('md')]: {
      borderTop: 0,
      margin: `0 0 ${theme.spacing(2)} 0`,
      backgroundColor: theme.palette.background.light,
      borderRadius: theme.spacing(0.5)
    }
  },
  default: {
    padding: theme.spacing(1),
    ['@media (min-height:160px)']: {
      // eslint-disable-line no-useless-computed-key
      padding: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      border: '1px #ECEEF0 solid',
      position: 'relative',
      paddingBottom: theme.spacing(9)
    }
  },
  stack: {
    ['@media (min-height:160px)']: {
      // eslint-disable-line no-useless-computed-key
      padding: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      border: '1px #ECEEF0 solid',
      position: 'relative'
    }
  },
  none: {
    borderTop: 0,
    padding: theme.spacing(1),
    ['@media (min-height:160px)']: {
      // eslint-disable-line no-useless-computed-key
      padding: theme.spacing(2),
      position: 'relative'
    }
  },
  link: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      position: 'absolute',
      bottom: 0,
      left: theme.spacing(2),
      width: `calc(100% - ${theme.spacing(4)})`,
      marginBottom: theme.spacing(2)
    }
  }
}))

type ScopeProps = {
  scope?: 'stack' | 'none' | 'default'
}

type WidgetProps = {
  children: React.ReactNode
  empty?: boolean
  mdOrder?: number
  mdFlex0?: boolean
  minHeight?: number
  className?: string
} & ScopeProps

type LinkActionType = { title: string; icon?: IconProp; link?: string; sidepanel?: SidepanelType }

export const HeadingButton = (props: { title: string; endIcon?: ReactNode }) => {
  const { classes } = useStyles({})
  const wide = useWide()
  const { saveScroll } = usePresetScroll()

  const handleSeeAllClick = () => {
    saveScroll()
  }

  return (
    <Button
      onClick={handleSeeAllClick}
      variant={wide ? 'widget' : 'link'}
      endIcon={wide && props.endIcon}
      className={classes.link}
      disablePR={!wide}
      disablePY
    >
      {props.title}
    </Button>
  )
}

export const LinkAction = (props: LinkActionType) => {
  const Button = (
    <HeadingButton title={props.title} endIcon={props.icon ? <FontAwesomeIcon icon={props.icon} style={{ fontSize: 14, marginLeft: 1 }} /> : null} />
  )

  return (
    <>
      {props.link &&
        (props.sidepanel ? (
          <SidepanelLink sidepanel={props.sidepanel} linkProps={{ to: props.link }}>
            {Button}
          </SidepanelLink>
        ) : (
          <Link to={props.link}>{Button}</Link>
        ))}
    </>
  )
}

export const SeeAllAction = (props: Pick<LinkActionType, 'link' | 'sidepanel'>) => (
  <LinkAction title="See all" icon={['far', 'chevron-right']} sidepanel={props.sidepanel} link={props.link} />
)

export const WidgetHeading = (props: ComponentProps<typeof Heading> & ComponentProps<typeof SeeAllAction>) => {
  const { sidepanel, link, action, ...headingProps } = props
  return (
    <Heading
      {...headingProps}
      action={
        action || (
          <Narrow>
            <SeeAllAction sidepanel={sidepanel} link={link} />
          </Narrow>
        )
      }
    />
  )
}

const Widget = forwardRef(({ children, scope, empty, mdOrder, mdFlex0, minHeight, className }: WidgetProps, ref) => {
  const { classes, cx } = useStyles({ minHeight })

  if (empty)
    return (
      <Box ref={ref} className={classes.container} order={{ md: mdOrder || 'inherit' }} flex={mdFlex0 ? 0 : ''}>
        Not found
      </Box>
    )

  return (
    <Box
      ref={ref}
      className={cx(classes.container, classes.widget, classes[scope || 'default'], className)}
      order={{ md: mdOrder || 'inherit' }}
      flex={mdFlex0 ? 0 : ''}
    >
      {children}
    </Box>
  )
})

export default Widget
