import { Moment as MomentType } from 'moment'

import { getLocal } from 'utils/Utils'

const interactionsTypes: { name: string; type?: Capitalize<IncludeInteractions> }[] = [
  { name: 'InboundMsg', type: 'Inbound' },
  { name: 'OutboundMsg', type: 'Outbound' },
  { name: 'Meeting', type: 'Meeting' },
  { name: 'Activity' }
]
const whereOptions: { value: TouchpointDateType; prefix: string }[] = [
  { value: 'Latest', prefix: 'Last' },
  { value: 'First', prefix: 'First' },
  { value: 'Next', prefix: 'NextFuture' }
]

export const interactionsFilterData = whereOptions.flatMap(({ value: wh, prefix }) =>
  interactionsTypes.map(({ name, ...item }) => ({ ...item, name: `${prefix}${name}` as InterectionType, wh }))
)

const now = getLocal()
const useInteractionsPeriodEndpointParams = (queryData: Modify<CompaniesPageParams, { dealsChecked?: IncludeDeals[] }> | Modify<PeoplePageParams, { checked?: IncludePeopleType[] }> | IntroducersPageParams) => {
  const { where, interaction, period, days, excludeEmpty, sort } = queryData

  const nDaysAgo = days && now.clone().subtract(+days, 'd')
  const inNDays = days && now.clone().add(+days, 'd')

  const periodData: { [key in Lowercase<PeriodOptionsType>]: { [key in 'prev' | 'next']: { oldest?: MomentType; newest?: MomentType } } } = {
    within: {
      next: { oldest: now, newest: inNDays },
      prev: { oldest: nDaysAgo, newest: now }
    },
    after: {
      next: { oldest: inNDays },
      prev: { newest: nDaysAgo }
    }
  }

  const interactionsParams = interactionsFilterData
    .filter(({ wh, type }) => where === wh && interaction === type)
    .flatMap(({ name }) => [
      {
        name: `Oldest${name}`,
        value: periodData[(period?.toLowerCase() || 'within') as keyof typeof periodData][where === 'Next' ? 'next' : 'prev'].oldest
          ?.startOf('day')
          .toISOString()
      },
      {
        name: `Newest${name}`,
        value: periodData[(period?.toLowerCase() || 'within') as keyof typeof periodData][where === 'Next' ? 'next' : 'prev'].newest
          ?.endOf('day')
          .toISOString()
      }
    ])

  const provideNextFutureExcludeEmptyProps = excludeEmpty === 'true' && (sort === 'NextFutureAsc' || sort === 'NextFutureDesc')
  const nextFutureExcludeEmpty = { name: 'OldestNextFutureMeeting', value: now?.clone().endOf('day').toISOString() }

  return provideNextFutureExcludeEmptyProps ? [...interactionsParams, nextFutureExcludeEmpty] : interactionsParams
}

export default useInteractionsPeriodEndpointParams
