import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { IconButton, Select } from '_shared/buttons'

export const MarketDataMenuButton = () => (
  <IconButton color="primary" component="span" icon={['far', 'ellipsis-v']} hint="Market data menu" size="small" />
)

const MarketDataSelect = () => (
  <Select
    disableRipple
    component="span"
    color="primary"
    variant="outlined"
    endIcon={<FontAwesomeIcon style={{ width: 8 }} color="#979797" icon={['far', 'chevron-down']} />}
  >
    Market Data
  </Select>
)

export default MarketDataSelect
