export const invalidReasonMap: { [key in InvalidReasonsType]: string } = {
  Split: 'not associated',
  Merge: 'associated',
  AutomatedSender: 'junk/invalid',
  CommonName: 'common name',
  JunkName: 'junk/invalid',
  SpamDomain: 'junk/invalid',
  PersonalDomain: 'personal domain',
  SharedEmail: 'shared email address',
  SharedDomain: 'shared domain'
}

export const cEntityTypes: { value: ManualEditsEntityType; label: string }[] = [
  {
    value: 'CompanyDisqualifier',
    label: 'Disqualifier'
  },
  {
    value: 'CompanyPreferredName',
    label: 'Preferred name'
  },
  {
    value: 'CompanyPreferredUrl',
    label: 'Preferred url'
  },
  {
    value: 'CompanyTuple',
    label: 'Tuple'
  },
  {
    value: 'CompanyDisqualifierAdministration',
    label: 'Disqualifier (administration)'
  },
  {
    value: 'CompanySplitAdministration',
    label: 'Split (administration)'
  }
]

export const pEntityTypes: { value: ManualEditsEntityType; label: string }[] = [
  {
    value: 'PersonDisqualifier',
    label: 'Disqualifier'
  },
  {
    value: 'PersonPreferredName',
    label: 'Preferred name'
  },
  {
    value: 'PersonEmail',
    label: 'Preferred email'
  },
  {
    value: 'PersonTuple',
    label: 'Tuple'
  },
  { value: 'PersonJob', label: 'Job' },
  { value: 'PersonPhone', label: 'Phone' },
  {
    value: 'PersonDisqualifierAdministration',
    label: 'Disqualifier (administration)'
  },
  {
    value: 'PersonSplitAdministration',
    label: 'Split (administration)'
  }
]
