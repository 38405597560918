import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Icon from '_shared/Icon'
import Page from '_shared/Page'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import Heading from '_core/components/Heading'
import { Columns, Column, Wide, useWide } from '_core/components/layout'
import NameLink from '_core/components/NameLink'
import Widget from '_core/components/widgets'
import TouchpointsWidget from '_core/components/widgets/Touchpoints'

import useTouchpoints from '_core/hooks/useTouchpoints'

import { getLocal } from 'utils/Utils'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  shiftBottom: {
    [theme.breakpoints.up('md')]: {
      marginBottom: `-${theme.spacing(2)}`
    }
  }
}))

export const AlertWidget = (props: PersonType & { loading: boolean }) => {
  const { NextFutureMeeting, NextFutureMeetingUserKeyMd5, NextFutureMeetingUserName } = props.Stats || {}
  const localNextFuture = getLocal(NextFutureMeeting)
  const isPast = localNextFuture.diff(getLocal()) < 0

  if (isPast || (!props.loading && !NextFutureMeeting)) {
    return null
  }

  return (
    <Widget scope="stack">
      <Skeleton condition={props.loading} width={160} height={24}>
        <Box display="flex">
          <Icon icon={['far', 'calendar-alt']} contained mr={1} />
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Typography variant="h4" semiBold>
              Upcoming meeting&nbsp;
            </Typography>

            <Typography variant="h4">
              <NameLink url={`${Paths._people}/${NextFutureMeetingUserKeyMd5}`} name={NextFutureMeetingUserName || ''} preposition="with" />
              {!props.loading && `, ${localNextFuture.format('MMM D, h:mm A')}`}
            </Typography>
          </Box>
        </Box>
      </Skeleton>
    </Widget>
  )
}

const TouchpointsPage = (props: any) => {
  const { classes } = useStyles()
  const wide = useWide()

  const latestTouchpoints = useTouchpoints(props.Stats, 'Last')
  const firstTouchpoints = useTouchpoints(props.Stats, 'First')

  return (
    <Page variant="core">
      <Wide>
        <Heading title="Touchpoints" icon={['far', 'handshake']} variant="main" />
      </Wide>
      <Columns spacing={wide ? 1 : 0}>
        <Column md={12} className={classes.shiftBottom}>
          <AlertWidget {...props} />
        </Column>
        <Column two>
          <TouchpointsWidget loading={props.loading} titlePrefix="Latest" items={latestTouchpoints || []} />
        </Column>
        <Column two>
          <TouchpointsWidget loading={props.loading} titlePrefix="First" items={firstTouchpoints || []} />
        </Column>
      </Columns>
    </Page>
  )
}

export default TouchpointsPage
