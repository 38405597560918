import { useContext, useState } from 'react'

import { useSnackbar } from 'notistack'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import { mergeUrlWithParams, post } from 'utils/httpUtils'

export type MarkAsInvalidResp = {
  success: boolean
  errorMessage?: string
}

const errorMessage = 'An error occurred while marking as invalid'

export const useSplitMulti = (endpoint: string) => {
  const history = useHistory()
  const match = useRouteMatch()
  const { teamContextValue } = useContext(TeamContext)
  const [checkedTuples, setCheckedTuples] = useState<string[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const submitInvalid = async () => {
    const payload = checkedTuples.map((tuple) => {
      const [identity1, identity2] = tuple.split(' = ')
      return { identity1, identity2 }
    })

    try {
      const resp = await post<MarkAsInvalidResp>(mergeUrlWithParams(endpoint, { teamNumber: `${teamContextValue.teamNumber}` }), payload)

      if (resp?.success) {
        history.replace(`${match.url}/success`)
      } else {
        enqueueSnackbar(resp?.errorMessage || errorMessage, {
          variant: 'error'
        })
      }
    } catch (e) {
      console.log(e)
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      })
    }
  }

  return {
    submitInvalid,
    checkedTuples,
    setCheckedTuples
  }
}
