import React, { useEffect } from 'react'

import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

import Chip, { FilterChipLabel } from '_core/components/Chip'
import Content from '_core/components/MarketDataCompaniesSearchFilters'

import useSearchQuery from '_core/hooks/useSearchQuery'

import Filters, { AppliedFilters } from './index'

export type MarketDataFilters = {
  opened: boolean
  contentLoading: boolean
  disabled: boolean
  handleChange: (params: ModifiedSearchPageParams) => void
  anchorEl?: HTMLElement | null
  total: number | undefined
  loading: boolean
  toggleOpen: () => void
}

export type CompaniesFiltersType = {
  name: string
  website: string
  companyType: MarketDataCompanyType | ''
  countries: string[]
  industries: string[]
  locationName: string[]
  locationRegion: string[]
  minimumEmployeeCount: number | null
  maximumEmployeeCount: number | null
}

type AppliedFilter = { field: keyof CompaniesFiltersType; fieldLabel: string; value: string }

const useStyles = makeStyles()((theme) => ({
  container: {
    overflow: 'visible',
    '& div[class*="topBlock"]': {
      marginBottom: 0
    },
    '& > div[class*="content"]': {
      marginTop: theme.spacing(5) //topBlock height - (fixed , so it is removed from the normal document flow)
    },
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
      width: 270,
      maxHeight: 'calc(100vh - 190px)',
      paddingBottom: 0
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: 0,
    boxSizing: 'border-box',
    width: '100%',
    margin: `0 -${theme.spacing(2.5)}`,
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
    background: theme.palette.background.light,
    borderTop: `1px solid ${theme.palette.text.secondary}`,
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      width: 'auto'
    }
  }
}))

const resetFilters: CompaniesFiltersType = {
  name: '',
  website: '',
  companyType: '',
  countries: [],
  industries: [],
  locationName: [],
  locationRegion: [],
  minimumEmployeeCount: null,
  maximumEmployeeCount: null
}

const getPayload = (filters: CompaniesFiltersType) =>
  Object.entries(filters)
    .filter(([, value]) => (Array.isArray(value) ? value.length : !!value))
    .reduce((acc, [name, value]) => ({ ...acc, [name]: Array.isArray(value) ? value : `${value}` }), {})

const fieldNamesMap: { [key in keyof CompaniesFiltersType]: string } = {
  name: 'Name',
  website: 'Website',
  industries: 'Industry',
  countries: 'Country',
  companyType: 'Company type',
  locationName: 'Location',
  locationRegion: 'Region',
  minimumEmployeeCount: 'Minimum employee count',
  maximumEmployeeCount: 'Maximum employee count'
}

export const transformFilters = (filters: Partial<CompaniesFiltersType>): AppliedFilter[] => {
  return Object.entries(filters).flatMap(([field, value]) => {
    if (!value) return []

    const typedField = field as keyof CompaniesFiltersType
    const fieldLabel = fieldNamesMap[typedField]
    return Array.isArray(value)
      ? value.map((val) => ({ field: typedField, fieldLabel, value: val }))
      : [
          {
            field: typedField,
            fieldLabel,
            value: `${value}`
          }
        ]
  })
}

const AppliedFiltersContent = ({
  disabled,
  transformedFilters,
  filtersCount,
  handleDelete
}: {
  disabled: boolean
  transformedFilters: AppliedFilter[]
  filtersCount: number
  handleDelete: (field: AppliedFilter['field'], value: AppliedFilter['value']) => void
}) => {
  return (
    <AppliedFilters disabled={disabled} filtersCount={filtersCount} sticky>
      {transformedFilters.map(({ field, fieldLabel, value }) => {
        return (
          <Chip
            key={value}
            label={<FilterChipLabel label={fieldLabel} value={value} />}
            color="secondary"
            size="small"
            onDelete={() => handleDelete(field, value)}
          />
        )
      })}
    </AppliedFilters>
  )
}

const CompaniesSearchFilters = ({ opened, contentLoading, loading, disabled, anchorEl, handleChange, total, ...props }: MarketDataFilters) => {
  const { queryParams, updateQuery } = useSearchQuery<SearchPageParams>()
  const companiesFilters = queryParams.companiesFilters ? JSON.parse(queryParams.companiesFilters) : {}
  const {
    reset: formReset,
    getValues,
    setValue,
    control,
    register
  } = useForm<CompaniesFiltersType>({
    defaultValues: {
      name: companiesFilters.name || '',
      website: companiesFilters.website || '',
      companyType: companiesFilters.companyType || '',
      countries: companiesFilters.countries || [],
      industries: companiesFilters.industries || [],
      locationName: companiesFilters.locationName || [],
      locationRegion: companiesFilters.locationRegion || [],
      minimumEmployeeCount: +companiesFilters.minimumEmployeeCount || null,
      maximumEmployeeCount: +companiesFilters.maximumEmployeeCount || null
    }
  })
  const { classes } = useStyles()

  useEffect(() => {
    if (!queryParams.companiesFilters && !loading) {
      formReset(resetFilters)
    }
  }, [queryParams.companiesFilters, loading])

  const handleUpdateQuery = (updatedQuery: string) => {
    updateQuery({ ...queryParams, companiesFilters: updatedQuery })
  }

  const reset = () => {
    if (queryParams.companiesFilters) {
      handleUpdateQuery('')
    } else {
      formReset()
    }
  }

  const apply = () => {
    const filters = getPayload(getValues())
    if (Object.keys(filters).length) {
      handleUpdateQuery(JSON.stringify(filters))
    } else {
      reset()
    }
  }

  const transformedFilters = transformFilters(companiesFilters)
  const filtersCount = Object.keys(transformedFilters).length

  const handleDelete = (field: AppliedFilter['field'], value: AppliedFilter['value']) => {
    const currentValue = companiesFilters[field]
    if (currentValue) {
      const updatedFilters = { ...companiesFilters, [field]: Array.isArray(currentValue) ? currentValue.filter((val) => val !== value) : undefined }
      handleUpdateQuery(JSON.stringify(updatedFilters))
      setValue(field, Array.isArray(currentValue) ? currentValue.filter((val) => val !== value) : null)
    }
  }

  return (
    <Filters
      opened={opened}
      reset={reset}
      disabled={disabled}
      contentLoading={contentLoading}
      anchorEl={anchorEl}
      total={total}
      className={classes.container}
      toggleOpen={props.toggleOpen}
      filtersCount={filtersCount}
    >
      <AppliedFiltersContent disabled={disabled} transformedFilters={transformedFilters} filtersCount={filtersCount} handleDelete={handleDelete} />
      <Content disabled={disabled} register={register} control={control} apply={apply} />
      <Box className={classes.buttonContainer}>
        <Button disabled={disabled} variant="text" color="secondary" onClick={apply} disablePadding>
          Search
        </Button>
      </Box>
    </Filters>
  )
}

export default CompaniesSearchFilters
