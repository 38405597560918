import { ComponentProps, useEffect } from 'react'

import { Box, Divider, Stack, Switch, useTheme } from '@mui/material'
import { ChartsAxisData } from '@mui/x-charts'
import moment, { Moment as MomentType } from 'moment'
import { matchPath, useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { ActivitiesListItem } from '_core/components/ActivitiesList'
import ActivityStatsChart, { inboundColor, meetingsColor, outboundColor } from '_core/components/charts/ActivityStats'
import Heading from '_core/components/Heading'
import { Columns, Column, useWide, NarrowStrict, Middle, Wide, Narrow } from '_core/components/layout'
import { activityStatsRangeControlOnboardingTarget } from '_core/components/onboarding/ActivityStats'
import Widget, { SeeAllAction } from '_core/components/widgets'

import useOnboarding from '_core/hooks/useOnboarding'
import useSidepanelLink from '_core/hooks/useSidepanelLink'

import { stringifyUrl } from '_core/helpers/browser'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  card: {
    position: 'relative'
  },
  inbound: {
    backgroundColor: inboundColor
  },
  outbound: {
    backgroundColor: outboundColor
  },
  meetings: {
    backgroundColor: meetingsColor
  },
  gridContainer: {
    minHeight: 500
  },
  dateWrapper: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  marker: {
    marginRight: theme.spacing(1),
    width: 8,
    height: 8,
    borderRadius: 2
  }
}))

type ActivityStatsWidgetProps = {
  stats: Pick<ActivitiesListItem, 'inbound' | 'outbound' | 'meetings'>[] | undefined
  isDetailedActivityFromStatsWidgetVisible: boolean
  seeAllLink?: string
  detailsLink: string
  months: { year: number; month: number; minDay: number; maxDay: number; chartLabel: string }[]
  chartTitle: string
  zoomOutPeriod: readonly [MomentType, MomentType] | null
  currentPeriod: readonly [MomentType, MomentType] | null
  nextPeriod: readonly [MomentType, MomentType] | null
  prevPeriod: readonly [MomentType, MomentType] | null
  updatePeriod: (period: readonly [MomentType, MomentType]) => void
  weekView: boolean
  stack: boolean
  toggleStack: () => void
} & Pick<ComponentProps<typeof Widget>, 'scope'>

const Marker = ({ className }: { className: string }) => {
  const { cx, classes } = useStyles()
  return <Box className={cx(classes.marker, className)} />
}

const ActivityStatsWidget = (props: ActivityStatsWidgetProps) => {
  const { classes } = useStyles()
  const {
    stats,
    isDetailedActivityFromStatsWidgetVisible,
    seeAllLink,
    detailsLink,
    months,
    chartTitle,
    zoomOutPeriod,
    nextPeriod,
    prevPeriod,
    weekView,
    stack,
    toggleStack,
    updatePeriod
  } = props

  const theme = useTheme()
  const middle = useWide('sm')

  const { pathname } = useLocation()

  const { isExact: activityStatsPage } =
    matchPath(pathname, {
      path: [`${Paths._people}/:id/activityStats`, `${Paths._companies}/:id/activityStats`]
    }) || {}

  const { meetings, inbound, outbound } = stats?.[0] || {}

  const { handleClick } = useSidepanelLink()
  const { startOnboarding } = useOnboarding()

  useEffect(() => {
    if (stats) {
      startOnboarding(activityStatsPage ? '/activityStats' : 'activityStatsWidget')
    }
  }, [stats])

  const count = (meetings?.count || 0) + (inbound?.count || 0) + (outbound?.count || 0)

  const activitySummary = [
    { dataKey: 'inbound' as const, header: 'Inbound', amount: inbound?.count || 0, color: inboundColor },
    { dataKey: 'outbound' as const, header: 'Outbound', amount: outbound?.count || 0, color: outboundColor },
    { dataKey: 'meetings' as const, header: 'Meetings', amount: meetings?.count || 0, color: meetingsColor }
  ]

  const dataset = months.map(({ chartLabel }, idx) => ({
    chartLabel,
    inbound: inbound?.byIndex[idx] || 0,
    outbound: outbound?.byIndex[idx] || 0,
    meetings: meetings?.byIndex[idx] || 0
  }))

  const handleAxisClick = (event: MouseEvent, data: null | ChartsAxisData) => {
    const { dataIndex } = data || {}
    if (typeof dataIndex === 'number') {
      const { year, month, minDay, maxDay } = months[dataIndex]
      if (!weekView && isDetailedActivityFromStatsWidgetVisible) {
        updatePeriod([
          moment.utc({ year, month: month - 1, day: minDay }).startOf('day'),
          moment.utc({ year, month: month - 1, day: maxDay }).endOf('day')
        ])
      } else {
        const { inbound, outbound, meetings } = dataset[dataIndex]
        if (inbound || outbound || meetings) {
          console.log('data', inbound, outbound, meetings)
          const { name: interaction } =
            [
              { name: 'Meeting', condition: meetings && !inbound && !outbound },
              { name: 'Outbound', condition: !meetings && !inbound && outbound },
              { name: 'Inbound', condition: !meetings && inbound && !outbound }
            ].find(({ condition }) => condition) || {}

          handleClick(event, true, {
            to: stringifyUrl(detailsLink, {
              interaction,
              from: moment
                .utc({ year, month: month - 1, day: minDay })
                .startOf('day')
                .toISOString(),
              to: moment
                .utc({ year, month: month - 1, day: maxDay })
                .endOf('day')
                .toISOString()
            })
          })
        }
      }
    }
  }

  const goBackToPrevPeriod = () => {
    if (zoomOutPeriod) {
      updatePeriod(zoomOutPeriod)
    }
  }

  const showNextRange = () => {
    if (nextPeriod) {
      updatePeriod(nextPeriod)
    }
  }

  const showPrevRange = () => {
    if (prevPeriod) {
      updatePeriod(prevPeriod)
    }
  }

  const stackColsEl = (
    <Box className={classes.switch}>
      <Switch disabled={!stats} checked={stack} onChange={toggleStack} />
      <Typography variant="h4">Stack columns</Typography>
    </Box>
  )

  const legendEl = (
    <Stack flexWrap="wrap" direction="row" useFlexGap gap={`0px ${theme.spacing(2)}`}>
      {activitySummary.map(({ header, amount, dataKey }) => (
        <Box display="flex" alignItems="center" key={header}>
          <Marker className={classes[dataKey]} />
          <Skeleton condition={!stats}>
            <Typography noWrap>
              {header} <strong>{amount}</strong>
            </Typography>
          </Skeleton>
        </Box>
      ))}
    </Stack>
  )

  const prevIconEl = (
    <Box>
      <IconButton size="small" onClick={showPrevRange} icon={['far', 'chevron-left']} loading={!chartTitle} />
    </Box>
  )

  const nextIconEl = (
    <Box>
      <IconButton size="small" onClick={showNextRange} icon={['far', 'chevron-right']} loading={!chartTitle} disablePR />
    </Box>
  )

  const dateRangeEl = (
    <div className={classes.dateWrapper}>
      <Skeleton condition={!chartTitle}>
        <Typography semiBold>{chartTitle || 'placeholder'}</Typography>
      </Skeleton>
    </div>
  )

  return (
    <Widget scope={props.scope || 'default'}>
      <Heading
        underlined
        title="Activity stats"
        icon={['far', 'wave-pulse']}
        count={count}
        action={
          <>
            <SeeAllAction link={stats ? seeAllLink : ''} />
            <Wide>{stackColsEl}</Wide>
          </>
        }
      />
      <Columns spacing={0}>
        <Middle includeWide>
          <Narrow>{stackColsEl}</Narrow>
          <Column xs={12} md={12}>
            <Columns columnSpacing={1} spacing={0} alignItems="center">
              <Column sm={6.5} md={6.5}>
                {legendEl}
              </Column>
              <Column sm={5.5} md={5.5}>
                <Box display="flex" alignItems="center" justifyContent="flex-end" ml="auto" className={activityStatsRangeControlOnboardingTarget}>
                  {zoomOutPeriod && (
                    <>
                      <IconButton size="small" icon={['far', 'magnifying-glass-minus']} onClick={goBackToPrevPeriod} />
                      <Divider orientation="vertical" flexItem classes={{ root: classes.divider }} />
                    </>
                  )}
                  {dateRangeEl}
                  {prevIconEl}
                  {nextIconEl}
                </Box>
              </Column>
            </Columns>
          </Column>
        </Middle>
        <NarrowStrict>
          <Box display="flex" alignItems="center" flexDirection="column" flex={1}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flex={1}
              className={activityStatsRangeControlOnboardingTarget}
              ml={zoomOutPeriod ? '-39px' : 0}
            >
              {zoomOutPeriod && (
                <>
                  <IconButton size="small" icon={['far', 'magnifying-glass-minus']} onClick={goBackToPrevPeriod} />
                  <Divider orientation="vertical" flexItem />
                </>
              )}
              {prevIconEl}
              {dateRangeEl}
              {nextIconEl}
            </Box>
            <Box mb={1}>{legendEl}</Box>
          </Box>
        </NarrowStrict>
        <Column xs={12} md={12}>
          <ActivityStatsChart
            loading={!stats}
            height={247}
            stack={middle ? stack : true}
            dataset={dataset}
            series={dataset.length ? activitySummary.map(({ dataKey, header, color }) => ({ dataKey, label: header, color })) : []}
            onAxisClick={handleAxisClick}
          />
        </Column>
      </Columns>
    </Widget>
  )
}

export default ActivityStatsWidget
