import { useContext } from 'react'

import useOutlook from '_core/hooks/useOutlook'

import { LayoutContext } from 'Layout/LayoutContextProvider'

type Variants = 'root' | 'preview' | 'extension' | 'sidepanel' | 'outlook'

type HistoryMapType = {
  [key in Variants]: { history: { [key: string]: any }[]; setHistory: any }
}

const useAppHistory = () => {
  const ctx = useContext(LayoutContext)
  const isOutlook = useOutlook()

  const historyMap: HistoryMapType = {
    preview: { history: ctx.previewHistory, setHistory: ctx.setPreviewHistory },
    root: { history: ctx.rootHistory, setHistory: ctx.setRootHistory },
    extension: { history: ctx.rootHistory, setHistory: ctx.setRootHistory },
    outlook: { history: ctx.rootHistory, setHistory: ctx.setRootHistory },
    sidepanel: { history: ctx.sidepanelHistory, setHistory: ctx.setSidepanelHistory }
  }

  const wName = window.name as Variants
  const key = historyMap[wName] ? wName : typeof isOutlook === 'boolean' ? ((isOutlook ? 'outlook' : 'root') as Variants) : undefined
  const { history: appHistory = [], setHistory: setAppHistory = () => {} } = key ? historyMap[key] || {} : {}

  return {
    appHistory,
    setAppHistory
  }
}

export default useAppHistory
