import { Box, Divider } from '@mui/material'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import CompanyData from '_core/components/market-data/CompanyData'
import ReportIncorrectData from '_core/components/market-data/Report'
import UnknownProfileSummary from '_core/components/UnknownProfileSummary'

type CompanyMarketDataProps = {
  company: CompanyMarketDataType | null
  profileLink?: string
}

const CompanyMarketData = ({ company, profileLink }: CompanyMarketDataProps) => (
  <>
    {(!company || company.id) && (
      <>
        <UnknownProfileSummary title={company?.name || ''} url={company?.website} loading={!company?.name} />
        <Divider />
        <Box p={2}>
          <Heading underlined title="Details" icon={['fas', 'chart-column']} />
          <CompanyData data={company} profileLink={profileLink} />
        </Box>
      </>
    )}
    {company && !company.id && <Empty title="No data found" />}
    <ReportIncorrectData marketData={JSON.stringify(company)} />
  </>
)

export default CompanyMarketData
