import React, { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import { nameReason } from '_core/components/dialogs/InformAboutIdentity'
import IdentifierLabel from '_core/components/IdentifierLabel'
import Repeater from '_core/components/lists/Repeater'
import TitleDescription from '_core/components/TitleDescription'

import { mergeUrlWithParams, post } from 'utils/httpUtils'
import { dateToStringWithTodayComparing } from 'utils/Utils'

import Paths from 'Paths'

export type AliasNamesProps = {
  loading: boolean
  total?: number
  items?: { CoNameAlias: string; CurrentAsOf: Date; IdentifierMd5: string }[]
  companyName: string
  setTotalAliases: (val: number) => void
}

const useStyles = makeStyles()((theme) => ({
  item: {
    padding: `${theme.spacing(1)} 0 ${theme.spacing(1)} 5px`
  },
  label: {
    paddingBottom: theme.spacing(1)
  }
}))

const AliasNames = ({ loading, items, total, companyName, setTotalAliases }: AliasNamesProps) => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = useContext(TeamContext)
  const { classes } = useStyles()

  useEffect(() => {
    if (typeof total === 'number') {
      setTotalAliases(total)
    }
  }, [setTotalAliases, total])

  const submitInvalid = async (identity: string, value: number) => {
    return post(mergeUrlWithParams('/companies/disqualify', { teamNumber: `${teamContextValue.teamNumber}` }), {
      identity,
      reason: nameReason[value]
    })
  }

  return (
    <Repeater
      component={TitleDescription}
      skeleton={{ size: 6, loading }}
      addprops={{ className: classes.item }}
      items={
        items?.map((name) => ({
          title: '',
          description: (
            <IdentifierLabel
              name={companyName}
              identity={name.CoNameAlias}
              type="CompanyName"
              className={classes.label}
              submit={submitInvalid}
              sourceLink={`${Paths._companies}/${id}/aliasSources/${name.CoNameAlias}`}
              auditLink={`${Paths._companies}/${id}/audit`}
            />
          ),
          blurb: name.CurrentAsOf ? `Last evidence on ${dateToStringWithTodayComparing(name.CurrentAsOf)}` : 'No data found'
        })) || []
      }
      empty="No aliases were found"
    />
  )
}

export default AliasNames
