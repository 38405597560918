import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { isSidepanel } from '_pages/sidebar'

import useAppHistory from '_core/hooks/useAppHistory'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const useSidebar = () => {
  const history = useHistory()
  const { historyIndex: index, setHistoryIndex: setIndex } = React.useContext(LayoutContext)
  const { appHistory, setAppHistory } = useAppHistory()

  const forwardActive = index > 0
  const backActive = appHistory.length > 0 && index !== appHistory.length - 1

  useEffect(() => {
    if (window.name === 'extension') {
      window.parent.postMessage(`da_payloads:${JSON.stringify({ backActive, forwardActive })}`, '*')
    }
  }, [backActive, forwardActive])

  const close = () => {
    setAppHistory([])
    history.replace('/blank')
    localStorage.removeItem('sidepanel')
  }

  const navigate = (direction: 'back' | 'forward') => {
    window.scrollTo(0, 0)
    if (appHistory.length > 0) {
      setIndex((prevIndex: number) => {
        const index = direction === 'back' ? prevIndex + 1 : prevIndex - 1
        if (isSidepanel('sidepanel')) {
          window.parent.postMessage(`sidepanel_navigate_click:${JSON.stringify(appHistory[index])}`, '*')
        } else {
          history.replace({
            ...appHistory[index],
            state: {
              ...(appHistory[index]?.state || {}),
              historyIndex: index
            }
          })
        }
        return index
      })
    }
  }

  const back = () => navigate('back')
  const forward = () => navigate('forward')
  const currentHistory = appHistory.length > 0 ? appHistory[index > -1 ? index : 0] : {}
  const currentPath = currentHistory.pathname ? `${currentHistory.pathname}${currentHistory.search}` : null

  return {
    close,
    back,
    forward,
    forwardActive,
    backActive,
    currentPath
  }
}

export default useSidebar
