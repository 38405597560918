import { ComponentProps, ReactNode, useContext } from 'react'

import { Box } from '@mui/material'

import { TeamContext } from '_core/context/TeamContext'

import Typography from '_shared/Typography'

import { emailReason } from '_core/components/dialogs/InformAboutIdentity'
import Heading from '_core/components/Heading'
import IdentifierLabel from '_core/components/IdentifierLabel'
import Repeater from '_core/components/lists/Repeater'
import PhoneNumber from '_core/components/PhoneNumber'
import Widget, { SeeAllAction } from '_core/components/widgets'

import { sum } from '_core/helpers/numbers'

import { mergeUrlWithParams, post } from 'utils/httpUtils'

import useStyles from './styles'

export const ContactInfoEmailsWidget = (props: { total: number; link: string; children: ReactNode }) => {
  return (
    <Widget scope={props.link ? 'default' : 'stack'}>
      <Heading title="Email addresses" icon={['far', 'envelope']} count={props.total} action={<SeeAllAction link={props.link} sidepanel />} />
      {props.children}
    </Widget>
  )
}

export const ContactInfoPhonesWidget = (props: { total: number; link: string; children: ReactNode }) => {
  return (
    <Widget scope={props.link ? 'default' : 'stack'}>
      <Heading title="Phone numbers" icon={['far', 'phone']} count={props.total} action={<SeeAllAction link={props.link} sidepanel />} />
      {props.children}
    </Widget>
  )
}

const ContactInformationWidget = (props: {
  loading: boolean
  link: string
  phones: ComponentProps<typeof PhoneNumber>[]
  phonesTotal?: number
  emails: Omit<ComponentProps<typeof IdentifierLabel>, 'submit'>[]
  emailsTotal?: number
}) => {
  const {
    classes: { widget, heading }
  } = useStyles()

  const { loading, link, phones, emails, phonesTotal, emailsTotal } = props

  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue

  const count = loading ? -1 : sum(phonesTotal, emailsTotal)

  const submitInvalid = (identity: string, value: number) => {
    return post(mergeUrlWithParams('/people/disqualify', { teamNumber: `${teamNumber}` }), {
      identity,
      reason: emailReason[value]
    })
  }

  return (
    <Widget className={widget}>
      <Heading
        underlined
        title="Contact information"
        count={count}
        icon={['far', 'address-card']}
        action={<SeeAllAction link={count > 0 ? link : ''} />}
      />
      <Box marginBottom="16px">
        <Typography variant="body1" className={heading} component="div" style={{ marginTop: 16, paddingTop: 8, marginBottom: emailsTotal ? -8 : 0 }}>
          Emails
        </Typography>

        <Repeater
          component={IdentifierLabel}
          variant="homepage"
          skeleton={{ size: 2, loading }}
          items={emails.map((email) => ({ ...email, submit: submitInvalid }))}
          empty="No email addresses were found"
        />

        <Typography variant="body1" className={heading} component="div" style={{ marginTop: 16, marginBottom: phonesTotal ? -8 : 0 }}>
          Phone numbers
        </Typography>

        <Repeater component={PhoneNumber} variant="homepage" skeleton={{ size: 2, loading }} items={phones} empty="No phone numbers were found" />
      </Box>
    </Widget>
  )
}

export default ContactInformationWidget
