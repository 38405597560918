import React, { useState, useEffect, useCallback } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import SharingLevelDialog from '_core/components/dialogs/SharingLevel'
import Heading from '_core/components/Heading'
import { useWide } from '_core/components/layout'
import SharingBar from '_core/components/team/SharingBar'
import Widget from '_core/components/widgets'

import useDialog from '_core/hooks/useDialog'

type ContributorSettingsWidgetProps = {
  loading: boolean
  handleChange: () => void
  team: Team | undefined
  userMembership: UserTeamRelations | undefined
  userRelations: UserTeamRelations[] | undefined
}

const useStyles = makeStyles()((theme) => ({
  settingsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& a': {
      color: theme.palette.primary.main
    }
  },
  sharingBar: {
    paddingTop: theme.spacing(0.25)
  }
}))

const TeamContributorSettingsWidget = ({
  userMembership: membership,
  userRelations,
  loading,
  team,
  handleChange
}: ContributorSettingsWidgetProps) => {
  const { isDialogOpened, openDialog, closeDialog } = useDialog()
  const [userMembership, setUserMembership] = useState<UserTeamRelations>()

  useEffect(() => {
    setUserMembership(membership)
  }, [membership])

  const getUserSharing = useCallback((): SharingData | undefined => {
    if (!userRelations || !userMembership) return undefined
    return {
      isSharing: userMembership.isSharing || false,
      isSharingContactInfo: userMembership.isSharingContactInfo || false,
      isSharingInteractionStats: userMembership.isSharingInteractionStats || false,
      isSharingContactInfoWithAdminsAndCurators: userMembership.isSharingContactInfoWithAdminsAndCurators || false
    }
  }, [userRelations, userMembership])
  const [userSharing, setUserSharing] = useState(getUserSharing())
  const wide = useWide()
  const { classes } = useStyles()

  useEffect(() => {
    setUserSharing(getUserSharing())
  }, [getUserSharing, userMembership])

  return (
    <>
      <Widget scope={wide ? 'none' : 'default'}>
        <Heading title="Your settings" icon={['far', 'cog']} />
        <Box marginTop={{ md: -1.5 }} py={1}>
          <Box className={classes.settingsRow}>
            <Box>
              <Skeleton variant="text" width="100%" condition={loading}>
                <Typography variant="body1">Sharing level</Typography>
              </Skeleton>
              <Skeleton variant="text" width="100%" condition={loading}>
                <SharingBar userSharing={userSharing} className={classes.sharingBar} />
              </Skeleton>
            </Box>

            <SharingLevelDialog.TriggerEl open={openDialog} loading={loading} />
          </Box>
        </Box>
      </Widget>
      {team && (
        <SharingLevelDialog
          team={team}
          isOpen={isDialogOpened}
          close={closeDialog}
          userRelations={userRelations}
          userMembership={userMembership}
          setUserMembership={setUserMembership}
          handleRefresh={handleChange}
        />
      )}
    </>
  )
}

export default TeamContributorSettingsWidget
