import { useParams } from 'react-router'

import Avatar from '_shared/Avatar'

import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import Widget, { SeeAllAction } from '_core/components/widgets'

import Paths from 'Paths'

export type TeamMembersWidgetProps = {
  items: TeamMemberRes[]
  loading: boolean
  total: number
}

const TeamMembersWidget = ({ loading, items, total }: TeamMembersWidgetProps) => {
  const { id: teamId } = useParams<{ id: string }>()

  return (
    <Widget>
      <Heading title="Contributors" icon={['far', 'user']} count={total} action={<SeeAllAction link={`${Paths._teams}/${teamId}/members`} />} />
      <Repeater
        direction="horizontal"
        component={Avatar}
        skeleton={{ size: 5, loading }}
        items={items?.slice(0, 5).map((contributor: any) => ({
          name: contributor.name,
          userKey: contributor.userKeyPlainText,
          link: `${Paths._people}/${contributor.userKeyMd5}`
        }))}
      />
    </Widget>
  )
}

export default TeamMembersWidget
