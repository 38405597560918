import React, { ReactElement, useState } from 'react'

import { Box, Breadcrumbs as MuiBreadcrumbs, BreadcrumbsProps, Menu, MenuItem } from '@mui/material'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import { Icon, IconButtonProps } from '_shared/buttons/Icon'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

export type BreadcrumbType = { label: string | undefined; path: string; action?: ReactElement }

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: '10px 10px 0'
  },
  icon: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    padding: theme.spacing(1),
    fontSize: 12
  },
  label: {
    maxWidth: '60px',
    [theme.breakpoints.up('sidepanel')]: {
      maxWidth: '95px'
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '150px'
    }
  }
}))

export const BreadcrumbAction = (props: Pick<IconButtonProps, 'disabled' | 'onClick' | 'hint'> & Icon) => {
  const { classes } = useStyles()

  return (
    <IconButton
      disabled={props.disabled}
      disablePadding
      onClick={props.onClick}
      icon={props.icon}
      hint={props.hint}
      classes={{ root: classes.icon }}
    />
  )
}

const Breadcrumb = ({ item }: { item: BreadcrumbType }) => {
  const { classes } = useStyles()

  return (
    <Box display="flex" alignItems="center">
      <Link to={item.path}>
        <Skeleton condition={!item.label}>
          <Tooltip title={item.label}>
            <Typography color="text.secondary" classes={{ root: classes.label }} noWrap>
              {item.label || 'placeholder'}
            </Typography>
          </Tooltip>
        </Skeleton>
      </Link>
      {item.action}
    </Box>
  )
}

const TextBreadcrumb = ({ item }: { item: BreadcrumbType }) => {
  const { classes } = useStyles()

  return (
    <Box display="flex" alignItems="center">
      <Skeleton condition={!item.label}>
        <Tooltip title={item.label}>
          <Typography color="text.primary" classes={{ root: classes.label }} noWrap>
            {item.label || 'placeholder'}
          </Typography>
        </Tooltip>
      </Skeleton>
      {item.action}
    </Box>
  )
}

const Breadcrumbs = ({
  routes,
  itemsAfterCollapse = 1,
  itemsBeforeCollapse = 1,
  maxItems = 8
}: { routes: BreadcrumbType[] } & Pick<BreadcrumbsProps, 'maxItems' | 'itemsBeforeCollapse' | 'itemsAfterCollapse'>) => {
  const { pathname } = useLocation()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { classes } = useStyles()

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const allItems = routes.filter((route) => route.path && matchPath(pathname, route.path))

  if (allItems.length <= 1) {
    return null
  }

  if ((maxItems && allItems.length <= maxItems) || itemsBeforeCollapse + itemsAfterCollapse >= allItems.length) {
    return (
      <MuiBreadcrumbs separator="›" aria-label="breadcrumb" classes={{ root: classes.root }}>
        {allItems.map((breadcrumb, index) => {
          const last = index === allItems.length - 1

          return last ? <TextBreadcrumb key={breadcrumb.path} item={breadcrumb} /> : <Breadcrumb key={breadcrumb.path} item={breadcrumb} />
        })}
      </MuiBreadcrumbs>
    )
  }

  const crumbsBeforeCollapse = allItems.slice(0, itemsBeforeCollapse)
  const collapsedCrumbs = allItems.slice(itemsBeforeCollapse, allItems.length - itemsAfterCollapse)
  const crumbsAfterCollapse = allItems.slice(allItems.length - itemsAfterCollapse, allItems.length)

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {collapsedCrumbs.map((breadcrumb) => (
          <MenuItem key={breadcrumb.path} onClick={handleClose}>
            <Link to={breadcrumb.path}>{breadcrumb.label || 'placeholder'}</Link>
          </MenuItem>
        ))}
      </Menu>
      <MuiBreadcrumbs separator="›" aria-label="breadcrumb" classes={{ root: classes.root }}>
        {crumbsBeforeCollapse.map((breadcrumb) => (
          <Breadcrumb key={breadcrumb.path} item={breadcrumb} />
        ))}

        {!!collapsedCrumbs.length && <BreadcrumbAction icon={['far', 'ellipsis-h']} onClick={handleClick} />}

        {crumbsAfterCollapse.map((breadcrumb, index) => {
          const last = index === crumbsAfterCollapse.length - 1

          return last ? <TextBreadcrumb key={breadcrumb.path} item={breadcrumb} /> : <Breadcrumb key={breadcrumb.path} item={breadcrumb} />
        })}
      </MuiBreadcrumbs>
    </>
  )
}

export default Breadcrumbs
