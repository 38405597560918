import { ChangeEvent, useEffect, useState } from 'react'

import { Box, Stack, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Checkbox from '_shared/forms/Checkbox'

import InternalTag, { TagCategoryHeader } from '_core/components/InternalTag'
import Repeater from '_core/components/lists/Repeater'
import ReachDotAlignButton from '_core/components/ReachDotAlignButton'
import { Collapsed as SearchInput } from '_core/components/SearchInput'
import SidepanelLink from '_core/components/SidepanelLink'

import useAdmin from '_core/hooks/useAdmin'

import { getSkeletonSize } from '_core/helpers/skeleton'

export type LocalTagsListItems = { [categoryName: string]: { [tagName: string]: { entityCount: number; isActive: boolean } } }

const useStyles = makeStyles<{ searchBarIsHidden: boolean }>()((theme, { searchBarIsHidden }) => ({
  searchWrapper: {
    position: 'absolute',
    width: `calc(100% - ${theme.spacing(4)})`,
    zIndex: 1
  },
  tagsList: {
    marginTop: searchBarIsHidden ? 0 : `calc(36.5px + ${theme.spacing(1)})`
  }
}))

export const SelectTagsContent = ({
  isReady,
  loading,
  toggleActive,
  items,
  initialTagsProvided,
  setFilteredTags,
  manageTagsLink,
  onManageTagsClick
}: {
  isReady: boolean
  loading: boolean
  toggleActive: (categoryName: string, tagName: string, checked: boolean) => void
  items: LocalTagsListItems
  initialTagsProvided: boolean
  setFilteredTags: (searchTerm?: string) => Promise<void>
  manageTagsLink: string
  onManageTagsClick?: () => void
}) => {
  const admin = useAdmin()
  const showSkeleton = !isReady || loading || typeof admin !== 'boolean'
  const { classes } = useStyles({ searchBarIsHidden: !initialTagsProvided && !showSkeleton })
  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    setFilteredTags(searchValue)
  }, [searchValue, setFilteredTags])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearchValue(value)
  }

  const handleClearInput = () => {
    setSearchValue('')
  }

  const manageTagsBtn = (
    <SidepanelLink linkProps={{ to: manageTagsLink }} onClick={onManageTagsClick}>
      {' '}
      <Button variant="link" disablePadding>
        {' '}
        here{' '}
      </Button>{' '}
    </SidepanelLink>
  )

  const reachTeamBtn = <ReachDotAlignButton />

  return (
    <>
      {(initialTagsProvided || showSkeleton) && (
        <Box className={classes.searchWrapper}>
          <SearchInput
            autoFocus={isReady && typeof admin === 'boolean'}
            opened
            fullWidth
            placeholder="Search for value or name"
            value={searchValue}
            onChange={handleInputChange}
            clearInput={handleClearInput}
            disabled={!isReady || typeof admin !== 'boolean'}
          />
        </Box>
      )}
      <Box className={classes.tagsList}>
        {(initialTagsProvided || showSkeleton) && (
          <Repeater
            items={Object.keys(items).map((categoryName) => ({ categoryName }))}
            empty={`No tags were found for your search '${searchValue}'`}
            component={({ categoryName }: { categoryName: string }) => {
              const filteredList = showSkeleton ? getSkeletonSize(3, '') : Object.keys(items[categoryName])

              return (
                <>
                  <TagCategoryHeader categoryName={categoryName} />
                  {/* refactor repeater to use spacing and use horizontal repeater here */}
                  <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                    {filteredList.map((tagName, i) => {
                      const { isActive = false, entityCount = 0 } = categoryName ? items[categoryName][tagName] : {}
                      return (
                        <Checkbox
                          key={i}
                          disabled={showSkeleton}
                          checked={isActive}
                          onChange={(e, checked) => toggleActive(categoryName, tagName, checked)}
                          label={<InternalTag clickable label={tagName ? `${tagName} (${entityCount})` : tagName} />}
                        />
                      )
                    })}
                  </Stack>
                </>
              )
            }}
            skeleton={{ loading: showSkeleton, size: 3 }}
          />
        )}
        {!initialTagsProvided && !showSkeleton && (
          <Typography>
            No tags have been set up for usage yet.{' '}
            {admin ? (
              <>Please click {manageTagsBtn} to manage tags.</>
            ) : (
              <>Please reach out to your administrator or {reachTeamBtn} to get started.</>
            )}
          </Typography>
        )}
      </Box>
    </>
  )
}

export const SelectTagsActions = ({
  clearSelected,
  disabledApply,
  totalActive,
  handleSubmit,
  handleCancel
}: {
  disabledApply: boolean
  clearSelected: () => void
  totalActive: number
  handleSubmit: () => void
  handleCancel: () => void
}) => {
  return (
    <Box display="flex" flex="1" justifyContent="space-between">
      <Button variant="text" onClick={clearSelected} color="secondary" disablePL disabled={!totalActive}>
        Clear selected ({totalActive})
      </Button>
      <Box>
        <Button variant="text" onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={handleSubmit} disablePR disabled={disabledApply}>
          Apply
        </Button>
      </Box>
    </Box>
  )
}
