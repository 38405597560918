import React, { ComponentProps } from 'react'

import { Box } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

import ExternalTag, { ExtraTagsPopover } from '_core/components/ExternalTag'
import { TextCell } from '_core/components/grid/columns'
import TagsGroup from '_core/components/TagsGroup'

const useStyles = makeStyles()((theme) => ({
  tagsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, auto) minmax(0, auto)',
    overflow: 'hidden',
    gap: theme.spacing(1),
    '& div': {
      maxWidth: '100%'
    }
  }
}))

const TagsContent = ({ loading, tags, name, title }: { loading: boolean; tags: string[]; name: string; title: string }) => {
  const { classes } = useStyles()
  const mocked = new Array(3).fill('')

  return (
    <>
      {(!loading || !!tags?.length) && (
        <TagsGroup<ComponentProps<typeof ExternalTag>['tagData']>
          tagComponent={ExternalTag}
          className={classes.tagsWrapper}
          max={1}
          items={loading ? mocked : tags}
          renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
            <ExtraTagsPopover
              tags={tags?.length ? tags : mocked}
              title={title}
              name={name}
              triggerElement={
                <Box display="inline-flex" alignItems="center" height="100%">
                  <Typography noWrap>{extraTagsAmount} more</Typography>
                </Box>
              }
            />
          )}
        />
      )}
    </>
  )
}

export const skillsColumn: GridColDef = {
  field: 'skills',
  headerName: 'Skills',
  width: 200,
  minWidth: 200,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => (
    <TagsContent title="Skills" name={params.row.name} tags={params.row.skills} loading={params.row.loading} />
  )
}

export const educationSchoolColumn: GridColDef = {
  field: 'school',
  headerName: 'School',
  width: 200,
  minWidth: 200,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => (
    <TagsContent title="Schools" name={params.row.name} tags={params.row.schoolNames} loading={params.row.loading} />
  )
}

export const typeColumn: GridColDef = {
  field: 'type',
  headerName: 'Type',
  width: 200,
  minWidth: 100,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.type} />
}

export const employeeCountColumn: GridColDef = {
  field: 'employeeCount',
  headerName: 'Employee count',
  width: 200,
  minWidth: 150,
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.employeeCount} />
}
