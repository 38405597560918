import React, { useState } from 'react'

import { Button } from '_shared/buttons'
import Dialog, { DefaultSuccessAction, DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import Typography from '_shared/Typography'

import GridHeadingControl from '_core/components/GridHeadingControl'

const TriggerEl = ({ open, disabled }: { open: () => void; disabled: boolean }) => (
  <GridHeadingControl hint="Remove selected" disabled={disabled} onClick={open} icon={['far', 'user-minus']} />
)

const RemoveSelectedDialog = ({
  isOpened,
  close,
  successClose,
  handleRemove,
  success,
  content
}: {
  isOpened: boolean
  close: () => void
  successClose: () => void
  handleRemove: () => Promise<void>
  success: boolean
  content: string
}) => {
  const [loading, setLoading] = useState(false)

  const closeHandler = () => {
    close()
    setLoading(false)
  }

  const remove = async () => {
    setLoading(true)
    await handleRemove()
    setLoading(false)
  }

  return (
    <Dialog
      open={isOpened}
      onClose={success ? successClose : close}
      loading={loading}
      success={success}
      title={<DialogTitle title="Remove selected?" />}
    >
      <DialogContent successContent={<Typography>The removal was successful</Typography>}>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions successActions={<DefaultSuccessAction close={successClose} />}>
        <Button variant="text" disabled={loading} onClick={closeHandler} color="secondary">
          Cancel
        </Button>
        <Button variant="text" disabled={loading} onClick={remove} disablePR>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RemoveSelectedDialog.TriggerEl = TriggerEl

export default RemoveSelectedDialog
