import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import Avatar from '_shared/Avatar'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import MarketDataSearchLink from '_core/components/MarketDataSearchLink'
import Widget, { SeeAllAction } from '_core/components/widgets'
import useStyles from '_core/components/widgets/styles'

import { CSuiteType } from '_core/hooks/useCSuite'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

const CSuiteWidget = ({ cSuite, ...props }: { companyName: string; loading: boolean; cSuite: CSuiteType | null }) => {
  const { id } = useParams<{ id: string }>()
  const { classes } = useStyles()

  const items = cSuite?.data?.length
    ? cSuite.data.slice(0, 5).map((person) => {
        return {
          name: person.fullName,
          score: person.personMd5 ? person.score : undefined,
          sidepanel: true,
          link: person.personMd5
            ? `${Paths._people}/${person.personMd5}`
            : mergeUrlWithParams(`${Paths._companies}/${id}/c-suite/${person.fullName}`, {
                email: person.workEmail,
                company: person.jobCompanyName
              })
        }
      })
    : []

  const load = props.loading || !cSuite
  const showEmptyWidget = cSuite && cSuite.total === 0

  return (
    <Widget>
      <Heading
        underlined
        title="C Suite"
        icon={['fas', 'user-tie']}
        count={cSuite?.total}
        action={<SeeAllAction sidepanel link={cSuite?.total && !load ? `${Paths._companies}/${id}/c-suite?name=${props.companyName}` : ''} />}
      />
      {!showEmptyWidget && <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 5, loading: !cSuite?.total }} items={items} />}
      {showEmptyWidget && (
        <Empty
          className={classes.empty}
          subTitle="No records were found"
          action={
            <Box display="flex" justifyContent="center">
              <MarketDataSearchLink entity="person" />
            </Box>
          }
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

export default CSuiteWidget
