import { makeStyles } from 'tss-react/mui'

import AvatarGroup from '_shared/AvatarGroup'
import Typography from '_shared/Typography'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  font: {
    fontSize: '12px'
  },
  groupWrapper: {
    marginTop: '5px',
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    }
  }
}))

export type TeamMembersAvatarGroupProps = {
  items: TeamMemberRes[]
  loading: boolean
  total: number
  teamId: number
}

const TeamMembersAvatarGroup = ({ teamId, loading, items, total }: TeamMembersAvatarGroupProps) => {
  const { classes } = useStyles()

  return (
    <AvatarGroup
      alignRight
      max={4}
      size="xs"
      sidepanel={true}
      seeAllLink={`${Paths._teams}/${teamId}/members`}
      skeleton={{ size: 4, loading }}
      hideName={true}
      className={classes.groupWrapper}
      total={total}
      items={items?.map((contributor: any) => ({
        name: contributor.name,
        userKey: contributor.userKeyPlainText,
        link: `${Paths._people}/${contributor.userKeyMd5}`
      }))}
      empty={
        <Typography color="text.secondary" className={classes.font}>
          No contributors
        </Typography>
      }
    />
  )
}

export default TeamMembersAvatarGroup
