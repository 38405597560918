/** @jsxRuntime classic */
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React, { ReactNode } from 'react'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import ReactDOM from 'react-dom'
import { BrowserRouter, MemoryRouter } from 'react-router-dom'

import { isSidepanel } from '_pages/sidebar'

import OutlookProvider from '_core/context/Outlook'

import { withErrors } from '_core/ErrorBoundary'
import { getBrowser } from '_core/helpers/browser'

import App from 'App'
import theme from 'AppTheme'
import config from 'config'

import './index.scss'
//

const sidepanel = isSidepanel()

const FalconRouter = ({ children }: { children: React.ReactNode }) => {
  const sidepanelUrl = localStorage.getItem('sidepanel') || '/blank'

  return (
    <>
      {!sidepanel && window.name !== 'extension' ? (
        <BrowserRouter>{children}</BrowserRouter>
      ) : (
        <MemoryRouter initialEntries={[sidepanel ? sidepanelUrl : `${window.location.pathname}${window.location.search}`]} initialIndex={0}>
          {children}
        </MemoryRouter>
      )}
    </>
  )
}

const AppProviders = (props: { children: ReactNode }) => {
  return (
    <OutlookProvider>
      <FalconRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
        </StyledEngineProvider>
      </FalconRouter>
    </OutlookProvider>
  )
}

;(async () => {
  /* workaround to prevent app launch in popup; proper solution to set redirectUri as blank.html for loginPopup, acquireTokenPopup */

  if (window.name.includes('msal')) {
    return <></>
  }

  const { name, version } = getBrowser()
  const notSupportedBrowsers = [{ name: 'Safari', version: 14 }]

  const isBrowserSupported = !notSupportedBrowsers.find((br) => br.name === name && version <= br.version)

  const settings = await config()
  if (!settings || !isBrowserSupported) {
    const C = withErrors(App)({ isBrowserSupported })
    ReactDOM.render(<AppProviders>{C}</AppProviders>, document.getElementById('root'))
    return
  }

  const pca = await PublicClientApplication.createPublicClientApplication(settings.msalConfiguration)

  ReactDOM.render(
    <MsalProvider instance={pca}>
      <AppProviders>
        <App />
      </AppProviders>
    </MsalProvider>,
    document.getElementById('root')
  )
})()

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
