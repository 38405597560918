import { ComponentProps, useContext, useState } from 'react'

import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Typography from '_shared/Typography'

import AliasNames, { AliasNamesProps } from '_core/components/AliasNames'
import AliasUrls, { AliasUrlsProps } from '_core/components/AliasUrls'
import { nameReason } from '_core/components/dialogs/InformAboutIdentity'
import Heading from '_core/components/Heading'
import IdentifierLabel from '_core/components/IdentifierLabel'
import Repeater from '_core/components/lists/Repeater'
import Widget, { SeeAllAction } from '_core/components/widgets'

import DynamicEntity from '_core/DynamicEntity'
import { sum } from '_core/helpers/numbers'

import { mergeUrlWithParams, post } from 'utils/httpUtils'

import Paths from 'Paths'

import useStyles from './styles'

const maxNumber = 10

export const AliasesWidget = (props: { companyName: string }) => {
  const { teamContextValue } = useContext(TeamContext)
  const { id } = useParams<{ id: string }>()
  const [totalAliases, setTotalAliases] = useState<number>(0)

  return (
    <Widget scope={totalAliases > maxNumber ? 'default' : 'stack'}>
      <Heading
        title="Aliases"
        icon={['far', 'building']}
        count={totalAliases}
        action={<SeeAllAction link={totalAliases > maxNumber ? `${Paths._companies}/${id}/aliases/names` : ''} sidepanel />}
      />
      <DynamicEntity<{ extraProps: { addprops: Pick<AliasNamesProps, 'companyName' | 'setTotalAliases'> } }>
        url={`/companies/${id}/aliases?TeamNumber=${teamContextValue.teamNumber}&Take=10`}
        component={AliasNames}
        addprops={{ companyName: props.companyName, setTotalAliases }}
        list
        id="company_aliases_name"
      />
    </Widget>
  )
}

export const DomainsWidget = (props: { companyName: string }) => {
  const { teamContextValue } = useContext(TeamContext)
  const { id } = useParams<{ id: string }>()
  const [totalDomains, setTotalDomains] = useState<number>(0)

  return (
    <Widget scope={totalDomains > maxNumber ? 'default' : 'stack'}>
      <Heading
        title="Domains"
        icon={['far', 'globe']}
        count={totalDomains}
        action={<SeeAllAction link={totalDomains > maxNumber ? `${Paths._companies}/${id}/aliases/urls` : ''} sidepanel />}
      />
      <DynamicEntity<{ extraProps: { addprops: Pick<AliasUrlsProps, 'companyName' | 'setTotalDomains'> } }>
        url={`/companies/${id}/urls?TeamNumber=${teamContextValue.teamNumber}&Take=10`}
        component={AliasUrls}
        addprops={{ companyName: props.companyName, setTotalDomains }}
        list
        id="company_aliases_url"
      />
    </Widget>
  )
}

const AliasesDomainsWidget = (props: {
  loading: boolean
  link: string
  aliasesTotal?: number
  domainsTotal?: number
  aliases: Omit<ComponentProps<typeof IdentifierLabel>, 'submit'>[]
  domains: Omit<ComponentProps<typeof IdentifierLabel>, 'submit'>[]
}) => {
  const {
    classes: { heading }
  } = useStyles()
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue

  const { loading, link, aliases, domains, aliasesTotal, domainsTotal } = props

  const submitInvalid = async (identity: string, value: number) => {
    return post(mergeUrlWithParams('/companies/disqualify', { teamNumber: `${teamNumber}` }), {
      identity,
      reason: nameReason[value]
    })
  }

  const count = loading ? -1 : sum(aliasesTotal, domainsTotal)

  return (
    <Widget>
      <Heading
        underlined
        title="Aliases and domains"
        count={count}
        icon={['far', 'fingerprint']}
        action={<SeeAllAction link={count > 0 ? link : ''} />}
      />
      <Box marginBottom="16px">
        <Typography
          variant="body1"
          className={heading}
          component="div"
          style={{ marginTop: 16, paddingTop: 8, marginBottom: aliases.length ? -8 : 0 }}
        >
          Aliases
        </Typography>

        <Repeater
          component={IdentifierLabel}
          variant="homepage"
          skeleton={{ size: 2, loading: props.loading }}
          items={aliases.map((alias) => ({ ...alias, submit: submitInvalid }))}
          empty="No aliases were found"
        />

        <Typography variant="body1" className={heading} component="div" style={{ marginTop: 12, marginBottom: domains?.length ? -8 : 0 }}>
          Domains
        </Typography>

        <Repeater
          component={IdentifierLabel}
          variant="homepage"
          skeleton={{ size: 2, loading: props.loading }}
          items={domains.map((domain) => ({ ...domain, submit: submitInvalid }))}
          empty="No domains were found"
        />
      </Box>
    </Widget>
  )
}

export default AliasesDomainsWidget
