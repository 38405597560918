import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'

import Avatar from '_shared/Avatar'
import Typography from '_shared/Typography'

import IntroducerPickerDialog from '_core/components/dialogs/IntroducerPicker'

import { useStyles } from '_core/hooks/useIntroductionRequestForm'

import Paths from 'Paths'

type WidgetProps = {
  loading: boolean
  requesterName: string
  requesterEmail: string
  introducerName: string
  introducerEmail: string
  querierIsLtnManager: boolean
  contactName: string
  contactEmail: string
  openIntroducerPicker: () => void
}

const ParticipantsWidget = ({
  loading,
  requesterEmail,
  requesterName,
  introducerName,
  introducerEmail,
  querierIsLtnManager,
  contactName,
  contactEmail,
  openIntroducerPicker
}: WidgetProps) => {
  const { classes } = useStyles()

  return (
    <>
      <Box p={2}>
        <Box display="grid" alignItems="start" gridTemplateColumns="repeat(3, minmax(65px, 1fr))" columnGap={1}>
          <Box>
            <Typography color="text.secondary" className={classes.avatarTitle}>
              Requester
            </Typography>
            <Avatar
              name={loading ? '' : requesterName}
              size="md"
              link={`${Paths._people}/${requesterEmail}`}
              userKey={requesterEmail}
              nameMode="multiline"
            />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography color="text.secondary" className={classes.avatarTitle}>
              Introducer
            </Typography>
            {introducerName || loading ? (
              <Avatar
                name={loading ? '' : introducerName}
                size="md"
                link={`${Paths._people}/${introducerEmail}`}
                userKey={introducerEmail}
                nameMode="multiline"
              />
            ) : (
              <>
                <Avatar name="Not selected" size="md" nameMode="multiline" hideName>
                  <FontAwesomeIcon icon={['fas', 'user-tie']} />
                </Avatar>
                {querierIsLtnManager && (
                  <Box pt={1}>
                    <IntroducerPickerDialog.TriggerEl open={openIntroducerPicker} />
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box>
            <Typography color="text.secondary" className={classes.avatarTitle}>
              Contact
            </Typography>
            <Avatar
              name={loading ? '' : contactName}
              size="md"
              link={`${Paths._people}/${contactEmail}?name=${contactName}&email=${contactEmail}`}
              userKey={contactEmail}
              nameMode="multiline"
            />
          </Box>
        </Box>
        {querierIsLtnManager && introducerName && (
          <Box display="flex" justifyContent="center">
            <IntroducerPickerDialog.TriggerEl open={openIntroducerPicker} mode="edit" />
          </Box>
        )}
      </Box>
    </>
  )
}

export default ParticipantsWidget
