import React, { useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/material'
import { GridSortItem, GridSortModel } from '@mui/x-data-grid-pro'

import { DataGrid, GridPaginationType } from '_core/components/grid'
import {
  assigneeColumn,
  contactColumn,
  createdColumn,
  creatorColumn,
  introducerColumn,
  lastUpdateColumn,
  requesterColumn,
  statusColumn,
  summaryColumn,
  targetingStatusColumn
} from '_core/components/introductions/columns'
import ExpandableDetails from '_core/components/introductions/ExpandableDetails'
import StatusChip, { defineStatus } from '_core/components/introductions/StatusChip'
import { Narrow, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import { getSortByField, getSortModels, updateSort } from '_core/components/sort/Introductions'

import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { formatDateTime } from 'utils/Utils'

import Paths from 'Paths'

type ContactsListProps = {
  items: IntroductionContactResp[]
  loading: any
  updateSort: (val: IntroductionsSortType) => void
  sortByField?: GridSortItem['field']
  statuses: { label: string; value: string }[]
} & GridPaginationType

const ContactsList = (props: ContactsListProps) => {
  const { queryParams } = useSearchQuery<IntroductionsPageParams>()
  const { sort, viewMode } = queryParams
  const { payloads } = useSidepanelPayloads()
  const [items, setItems] = useState<{ [key: string]: any }[]>()

  const isExpandedView = viewMode === 'expanded'

  useEffect(() => {
    if (!props.loading) {
      setItems(
        props.items?.map(
          ({
            id,
            introducers,
            planSummary,
            planCreated,
            planLastAlive,
            planClosedOut,
            planUid,
            planReadyForReview,
            planCreatorAppUserKey,
            assignedToAppUserKey,
            querierIsLtnManager,
            selectedIntroducer,
            ...contact
          }) => {
            const introductionLink = `${Paths._introductions}/${planUid}/contacts/${contact.contactEmail}`

            return {
              id,
              planUid,
              querierIsLtnManager,
              introductionLink,
              readyForReview: planReadyForReview,
              closedOut: planClosedOut,
              updated: planLastAlive,
              created: planCreated,
              status: defineStatus(planUid, !!planClosedOut) || 'active',
              summary: planSummary,
              requestLink: `${Paths._introductions}/${planUid}`,
              targetingStatus: {
                value: contact.targetingStatus,
                label: props.statuses.find(({ value }) => value === contact.targetingStatus)?.label
              },
              email: contact.contactEmail,
              name: contact.displayAs,
              sidepanel: true,
              link: introductionLink,
              assignee: {
                name: assignedToAppUserKey,
                email: assignedToAppUserKey,
                link: `${Paths._people}/${assignedToAppUserKey}`
              },
              requester: {
                name: contact.requesterName,
                email: contact.requesterEmail,
                link: `${Paths._people}/${contact.requesterMd5}`
              },
              creator: {
                name: planCreatorAppUserKey === contact.requesterEmail ? contact.requesterName : planCreatorAppUserKey,
                email: planCreatorAppUserKey,
                link: `${Paths._people}/${planCreatorAppUserKey}`
              },
              introducer: {
                name: selectedIntroducer?.introducerEmail,
                email: selectedIntroducer?.introducerEmail,
                link: `${Paths._people}/${selectedIntroducer?.introducerEmail}`
              }
            }
          }
        )
      )
    } else {
      setItems(undefined)
    }
  }, [props.loading])

  useEffect(() => {
    if (payloads && payloads.action === 'UPDATE_INTRODUCTION_DETAILS' && items?.length) {
      const { planUid, contactId, ...rest } = payloads.value
      const transformed: { [key: string]: any } = {}

      'payload' in rest
        ? rest.payload.forEach(({ fieldName, value }) => {
            transformed[fieldName] = value
          })
        : (transformed[rest.fieldName] = rest.value)

      const updatedList = items.map((item) => {
        if (item.planUid === planUid && (!contactId || contactId === item.email)) {
          return {
            ...item,
            ...transformed
          }
        } else {
          return item
        }
      })
      setItems(updatedList)
    }
  }, [payloads])

  const models: GridSortModel = useMemo(() => getSortModels(sort), [sort])
  const sortByField = useMemo(() => getSortByField(sort), [sort])
  const handleSortModel = (model: GridSortModel) => updateSort(model, sort, props.updateSort)

  return (
    <>
      <Wide>
        <DataGrid
          rows={items || []}
          columns={[
            contactColumn,
            requesterColumn,
            createdColumn,
            lastUpdateColumn,
            targetingStatusColumn,
            introducerColumn,
            requestStatusColumn,
            summaryColumn,
            assigneeColumn,
            creatorColumn
          ]}
          controls={[]}
          loading={props.loading || !items}
          setPageSize={props.setPageSize}
          paging={props.paging}
          total={items ? props.total : undefined}
          setSortModel={handleSortModel}
          sortModel={models.filter((m) => m.field === sortByField)}
        />
      </Wide>
      <Narrow>
        <Box px={2}>
          <Repeater
            direction="vertical"
            variant="card"
            component={ProfileItem}
            skeleton={{ size: 20, loading: props.loading || !items }}
            items={
              items?.length
                ? items.map(({ name, status, summary, introductionLink, assignee, created, introducer, requester, updated, closedOut }) => ({
                    name,
                    variant: 'expandable',
                    icons: <StatusChip status={status} />,
                    byline: summary,
                    byline2: formatDateTime(created),
                    byline3: (
                      <ExpandableDetails
                        isExpandedView={isExpandedView}
                        sortByField={sortByField}
                        assignee={assignee}
                        introducer={introducer}
                        requester={requester}
                        updated={updated}
                        closedOut={closedOut}
                      />
                    ),
                    link: introductionLink
                  }))
                : []
            }
          />
        </Box>
      </Narrow>
    </>
  )
}

const requestStatusColumn = {
  ...statusColumn,
  width: 120,
  minWidth: 120,
  headerName: 'Request status'
}

export default ContactsList
