import { SyntheticEvent } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'

import { InformAffiliationType } from '_pages/people/[id]/affiliations'

import Affiliation, { AffiliationPopover } from '_core/components/Affiliation'
import AffiliationInformDialog from '_core/components/dialogs/AffiliationInform'
import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import { useWide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import Widget, { SeeAllAction } from '_core/components/widgets'

import useDialog from '_core/hooks/useDialog'

import { dateFormat, getLocal } from 'utils/Utils'

import Paths from 'Paths'

import useStyles from './styles'

const AffiliationsWidget = (props: {
  loading: boolean
  items?: NonNullable<PersonType['Jobs']>['data']
  total?: number
  link: string
  personName: string
  personId: string
}) => {
  const wide = useWide()
  const { classes } = useStyles()

  const { items, total, link, loading, personId, personName } = props

  const {
    dialogContentProps: openedDialog,
    openDialog,
    closeDialog,
    successMode,
    openSuccess
  } = useDialog<Pick<InformAffiliationType, 'companyName' | 'isFormerJob' | 'currentAsOf'>>()

  const handleOpenDialog = (e: SyntheticEvent, data: Pick<InformAffiliationType, 'companyName' | 'isFormerJob' | 'currentAsOf'>) => {
    e.stopPropagation()
    e.preventDefault()
    openDialog(data)
  }

  const handleCloseDialog = () => {
    closeDialog()
  }

  return (
    <>
      <Widget>
        <Heading underlined title="Affiliations" count={total} icon={['far', 'suitcase']} action={<SeeAllAction link={total ? link : ''} />} />
        {(!!total || loading) && (
          <Repeater
            component={Affiliation}
            skeleton={{ size: 2, loading: props.loading }}
            items={
              (wide ? items?.slice(0, 2) : items)?.map((job) => ({
                title: job.JobCoName || job.JobCorpLevelName,
                description: job.JobTitleText,
                blurb: `Last evidence on ${getLocal(job.JobCurrentAsOf).format(dateFormat)}`,
                sidepanel: true,
                link: `${Paths._companies}/${job.JobCompanyMd5}`,
                icons: (
                  <Box display="flex" alignItems="center">
                    {job.JobIsFormer && (
                      <AffiliationPopover
                        workExperience={{
                          companyIdentity: job.JobCompanyMd5,
                          companyName: job.JobCoName || job.JobCorpLevelName,
                          jobTitle: job.JobTitleText,
                          currentAsOf: job.JobCurrentAsOf
                        }}
                        icon={['far', 'address-book']}
                      />
                    )}
                    <AffiliationInformDialog.TriggerEl
                      open={(e: SyntheticEvent) =>
                        handleOpenDialog(e, {
                          companyName: job.JobCoName || job.JobCorpLevelName || '',
                          isFormerJob: !!job.JobIsFormer || false,
                          currentAsOf: job.JobCurrentAsOf || ''
                        })
                      }
                    />
                  </Box>
                )
              })) || []
            }
          />
        )}
        {!total && !loading && (
          <Empty
            className={classes.empty}
            subTitle={`${personName} has no affiliations`}
            icon={<FontAwesomeIcon size="3x" icon={['fat', 'suitcase']} style={{ color: '#A7A7A7' }} />}
          />
        )}
      </Widget>
      <AffiliationInformDialog
        personId={personId}
        personName={personName}
        close={handleCloseDialog}
        openSuccess={openSuccess}
        opened={!!openedDialog}
        success={successMode}
        {...openedDialog}
      />
    </>
  )
}

export default AffiliationsWidget
