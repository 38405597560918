import { ComponentProps } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import NameLink from '_core/components/NameLink'
import TitleDescription from '_core/components/TitleDescription'

import Paths from 'Paths'

const useTouchpoints = (stats: UserPersonType['Stats'] | UserCompanyType['Stats'], key: 'First' | 'Last' | 'NextFuture') => {
  return (
    stats &&
    (
      [
        stats[`${key}Meeting`] && {
          title: 'Meeting',
          date: stats[`${key}Meeting`],
          description: (
            <>
              <FontAwesomeIcon icon={['far', 'calendar-alt']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
              <NameLink
                url={`${Paths._people}/${stats[`${key}MeetingUserKeyMd5`]}`}
                name={stats[`${key}MeetingUserName`] || ''}
                preposition={`${key === 'NextFuture' ? 'is meeting' : 'met'}`}
              />
            </>
          ),
          blurb: moment(stats[`${key}Meeting`]).format('DD MMMM YYYY')
        },
        key !== 'NextFuture' &&
          stats[`${key}InboundMsg`] && {
            title: 'Message received',
            date: stats[`${key}InboundMsg`],
            description: (
              <>
                <FontAwesomeIcon icon={['fas', 'comment-alt-lines']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
                <NameLink
                  url={`${Paths._people}/${stats[`${key}InboundMsgUserKeyMd5`]}`}
                  name={stats[`${key}InboundMsgUserName`] || ''}
                  preposition="to"
                />
              </>
            ),
            blurb: moment(stats[`${key}InboundMsg`]).format('DD MMMM YYYY')
          },
        key !== 'NextFuture' &&
          stats[`${key}OutboundMsg`] && {
            title: 'Message sent',
            date: stats[`${key}OutboundMsg`],
            description: (
              <>
                <FontAwesomeIcon icon={['fas', 'comment-alt-lines']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
                <NameLink
                  url={`${Paths._people}/${stats[`${key}OutboundMsgUserKeyMd5`]}`}
                  name={stats[`${key}OutboundMsgUserName`] || ''}
                  preposition="from"
                />
              </>
            ),
            blurb: moment(stats[`${key}OutboundMsg`]).format('DD MMMM YYYY')
          }
      ].filter((key) => key) as (Omit<ComponentProps<typeof TitleDescription>, 'blurb2'> & { date: string })[]
    ).sort((a, b) => ((key === 'Last' ? a.date < b.date : a.date > b.date) ? 1 : -1))
  )
}

export default useTouchpoints
