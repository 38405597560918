import { useMemo } from 'react'

import { GeneralSettings } from '_core/components/settings/user'

import DynamicEntity from '_core/DynamicEntity'

const GeneralSettingsPage = () => {
  // prettier-ignore
  const generalUrls = useMemo(() => [
      { url: '/usersettings/homepageoptions', key: 'options' },
      { url: '/usersettings/general', key: 'homePage', merge: true, single: true }
    ], [])

  return <DynamicEntity urls={generalUrls} component={GeneralSettings} id="general_settings" />
}

export default GeneralSettingsPage
