import { CSSProperties } from 'react'

import { Theme } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import type {} from '@mui/x-data-grid-pro/themeAugmentation'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { CSSObject } from 'tss-react'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtext: true
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    sidepanel: true
    phone: true
    tablet: true
    laptop: true
    lgXl: true
  }

  interface HoverPaletteColorOptions {
    primary: string
    secondary: string
    error: string
    success: string
    warning: string
  }

  interface TypeText {
    hint: string
    light: string
  }

  interface TypeBackground {
    default: string
    paper: string
    active: string
    darker: string
    light: string
  }

  interface TypographyVariants {
    subtext: CSSProperties
  }

  interface TypographyVariantsOptions {
    subtext?: CSSProperties
  }

  interface Palette {
    hover: HoverPaletteColorOptions
    background: TypeBackground
    text: TypeText
  }

  interface PaletteOptions {
    hover?: HoverPaletteColorOptions
  }
}

export const ellipsis: CSSObject = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  boxSizing: 'border-box',
  maxWidth: '100%'
}

let theme = createTheme({
  palette: {
    primary: {
      main: '#1B95BB'
    },
    secondary: {
      main: '#1B95BB',
      light: '#EAF5FE'
    },
    text: {
      primary: '#202020',
      secondary: '#979797',
      disabled: '#DDDDDD',
      hint: 'rgba(0, 0, 0, 0.6)',
      light: '#FFFFFF'
    },
    background: {
      active: 'rgba(46, 156, 189, 0.08)',
      darker: '#F8F9FB',
      light: '#FFFFFF'
    },
    error: {
      main: '#EF4B23',
      light: 'rgba(239, 75, 35, 0.2)'
    },
    success: {
      main: '#4AA61D',
      light: 'rgba(74, 166, 29, 0.2)'
    },
    warning: {
      main: '#F5A91C',
      light: 'rgba(245, 169, 28, 0.2)'
    },
    hover: {
      primary: '#EEEFF1',
      secondary: '#F8FBFC',
      success: 'rgba(74, 166, 29, 0.04)',
      error: 'rgba(239, 75, 35, 0.04)',
      warning: 'rgba(245, 169, 28, 0.04)'
    }
  },
  typography: {
    fontFamily: [
      //'"Harmonia Sans"',
      '"Source Sans Pro"',
      '"Open Sans"',
      'sans-serif'
    ].join(','),
    h1: {
      fontSize: 32,
      lineHeight: '51px'
    },
    h2: {
      fontSize: 24,
      lineHeight: '38px'
    },
    h3: {
      fontSize: 20,
      lineHeight: '32px'
    },
    h4: {
      fontSize: 16,
      lineHeight: '26px'
    },
    h5: {
      fontSize: 12,
      lineHeight: '18px'
    },
    h6: {
      fontSize: 10,
      lineHeight: '16px'
    },
    body1: {
      fontSize: 14,
      lineHeight: '22px'
    },
    subtitle1: {
      fontSize: 15,
      lineHeight: '24px'
    },
    subtext: {
      textTransform: 'uppercase',
      fontSize: 10,
      lineHeight: '16px',
      letterSpacing: '1.5px'
    },
    caption: {
      textTransform: 'uppercase',
      fontSize: 14
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sidepanel: 310,
      phone: 340,
      sm: 600,
      tablet: 780,
      md: 960,
      laptop: 1054,
      lg: 1280,
      lgXl: 1600,
      xl: 1920
    }
  }
})

theme = createTheme(theme, {
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          flex: 1
        },
        outlined: {
          borderColor: '#ECEEF0'
        },
        elevation0: {
          boxShadow: 'none'
        },
        elevation1: {
          boxShadow:
            '0px 2px 4px rgb(221 221 221 / 25%), 0px -2px 4px rgb(221 221 221 / 25%), -2px 0px 4px rgb(221 221 221 / 25%), 2px 0px 4px rgb(221 221 221 / 25%)'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 16
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
          textTransform: 'none',
          fontSize: '12px',
          lineHeight: '20px'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        icon: {
          color: theme.palette.text.secondary
        },
        iconSmall: {
          marginLeft: theme.spacing(1)
        },
        iconMedium: {
          marginLeft: theme.spacing(1.5)
        },
        clickable: {
          '&:focus, &:hover': {
            backgroundColor: theme.palette.secondary.light
          }
        },
        deletable: {
          '&:focus, &:hover': {
            backgroundColor: theme.palette.secondary.light
          }
        },
        deleteIcon: {
          color: theme.palette.text.secondary,
          width: 12,
          height: 12,
          margin: '0 12px 0 0',
          '&:hover': {
            color: theme.palette.secondary.main
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.palette.text.disabled
          },
          '& .MuiInputBase-input': {
            textOverflow: 'ellipsis',
            overflowX: 'hidden'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&': {
            backgroundColor: theme.palette.background.light
          },
          '&:hover:not(&.Mui-disabled):not(&.Mui-error) .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.text.disabled
            }
          }
        },
        input: {
          height: '16.5px',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          opacity: 0.8,
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.secondary,
            fontWeight: 400,
            ...ellipsis
          },
          '&.Mui-disabled': {
            '&, &::placeholder': {
              color: theme.palette.text.disabled
            }
          },
          '&.MuiInputBase-inputMultiline': {
            boxSizing: 'border-box'
          }
        },
        notchedOutline: {
          top: '-4px',
          borderColor: theme.palette.text.secondary
        },
        multiline: {
          padding: 0
        },
        inputAdornedEnd: {
          paddingRight: 0
        },
        inputAdornedStart: {
          paddingLeft: 0
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '&, & .MuiButtonBase-root': {
            color: theme.palette.text.secondary
          }
        },
        positionStart: {
          marginRight: '12px'
        },
        positionEnd: {
          marginLeft: '12px'
        }
      }
    },
    MuiButtonGroup: {
      defaultProps: {
        variant: 'outlined',
        color: 'primary'
      },
      styleOverrides: {
        root: {
          '& .MuiButton-sizeSmall': {
            height: 26
          },
          '& .MuiButton-outlined': {
            textTransform: 'capitalize',
            fontWeight: 500,
            letterSpacing: '0.2px'
          },
          '& .MuiButton-contained': {
            '&, &:hover': {
              textTransform: 'capitalize',
              color: theme.palette.text.light,
              boxShadow: 'none',
              '&.MuiButton-containedPrimary': {
                border: '1px solid',
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main
              }
            }
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: theme.palette.text.secondary,
          '&.Mui-disabled': {
            color: theme.palette.text.disabled
          }
        },
        iconOutlined: {
          right: 5
        },
        select: {
          minHeight: '16.5px',
          height: '16.5px',
          lineHeight: '16.5px',
          '&:focus': {
            backgroundColor: theme.palette.background.light
          }
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        color: 'secondary'
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.palette.text.disabled
          },
          '&:hover': {
            backgroundColor: 'rgba(27, 149, 187, 0.04)'
          }
        },
        sizeSmall: {
          padding: 12,
          fontSize: 15,
          width: 15,
          height: 15,
          boxSizing: 'content-box'
        },
        sizeMedium: {
          padding: 12
        },
        colorError: {
          '&:hover': {
            backgroundColor: theme.palette.hover.error
          }
        },
        colorSuccess: {
          '&:hover': {
            backgroundColor: theme.palette.hover.success
          }
        },
        colorWarning: {
          '&:hover': {
            backgroundColor: theme.palette.hover.warning
          }
        },
        colorSecondary: {
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.secondary.main
          }
        },
        colorInherit: {
          '&:hover': {
            color: theme.palette.secondary.main
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontWeight: 600,
          fontSize: 14,
          minWidth: 'unset',
          '&.Mui-disabled': {
            color: theme.palette.text.disabled
          }
        },
        contained: {
          '&.Mui-disabled': {
            color: theme.palette.text.light
          }
        },
        outlined: {
          '&.Mui-disabled': {
            border: `1px solid ${theme.palette.text.disabled}`
          }
        },
        outlinedSecondary: {
          color: theme.palette.text.secondary,
          border: `1px solid ${theme.palette.text.secondary}`,
          '&:hover': {
            color: theme.palette.secondary.main
          }
        },
        outlinedSizeSmall: {
          paddingRight: 12,
          paddingLeft: 12
        },
        textSizeSmall: {
          paddingRight: 8,
          paddingLeft: 8
        },
        textSecondary: {
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.secondary.main
          }
        },
        textInherit: {
          '&:hover': {
            color: theme.palette.secondary.main
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&, &.Mui-expanded': {
            minHeight: 'auto'
          }
        },
        content: {
          '&, &.Mui-expanded': {
            margin: '6px 0px'
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: theme.palette.hover.secondary
          },
          '&.Mui-selected': {
            color: theme.palette.secondary.main,
            '&, &:focus': {
              backgroundColor: theme.palette.background.light
            },
            '&:hover': {
              backgroundColor: theme.palette.hover.secondary
            }
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          '&.MuiDataGrid-menuList': {
            padding: 0
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.light,
          '&, &:hover': {
            color: theme.palette.text.primary
          },
          '&.Mui-selected': {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.background.light,
            '&:hover': {
              backgroundColor: theme.palette.hover.secondary
            }
          }
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.light,
          color: theme.palette.text.primary,
          '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.hover.secondary
          },
          '&.Mui-selected': {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.background.light,
            '&:hover': {
              backgroundColor: theme.palette.hover.secondary
            }
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 24,
          alignItems: 'center',
          marginBottom: theme.spacing(2),
          paddingLeft: 0,
          paddingRight: 0,
          [theme.breakpoints.up('md')]: {
            borderBottom: 'none'
          }
        },
        scrollButtons: {
          width: '25px',
          height: '25px',
          border: '1px solid grey',
          borderRadius: '50%',
          transition: 'width 0.5s',
          '&[class*="disabled"]': {
            width: 0
          }
        },
        indicator: {
          height: '2px'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          marginRight: theme.spacing(3),
          paddingTop: '6px',
          paddingBottom: '6px',
          lineHeight: '24.5px',
          textTransform: 'uppercase',
          fontWeight: 600,
          fontSize: 14,
          letterSpacing: '1.25px',
          minHeight: 24,
          minWidth: 'auto',
          paddingLeft: 0,
          paddingRight: 0,
          whiteSpace: 'nowrap',
          flowGrow: 1,
          '&:last-of-type': {
            marginRight: 0
          },
          [theme.breakpoints.up('sm')]: {
            flowGrow: 0
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        marginDense: {
          marginTop: 1,
          marginBottom: 1
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#f2f2f2'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none'
        },
        row: {
          '& div[class*="hoveredRowActions"]': {
            display: 'none'
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(46, 156, 189, 0.08)',
            '&:hover, &.Mui-hovered': {
              backgroundColor: theme.palette.hover.secondary
            }
          },
          '&:hover, &.Mui-hovered': {
            backgroundColor: theme.palette.hover.secondary,
            '& div[class*="hoveredRowActions"]': {
              display: 'block'
            }
          }
        },
        cell: {
          '&:focus-within': {
            outline: 'none'
          },
          '& .MuiTypography-root': {
            fontSize: 15
          },
          '&[data-field]:not([data-field="relationship"])': {
            maxWidth: 'inherit',
            ...ellipsis
          }
        },
        pinnedColumns: {
          backgroundColor: theme.palette.background.light
        },
        pinnedColumnHeaders: {
          backgroundColor: theme.palette.background.light
        },
        columnHeader: {
          '&:focus-within': {
            outline: 'none'
          },
          '& .MuiIconButton-root': {
            fontSize: 14,
            padding: 8
          }
        },
        columnHeaderTitle: {
          color: theme.palette.text.secondary,
          fontSize: 14,
          fontWeight: 600
        },
        footerContainer: {
          paddingRight: 4
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: '8px'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '8px',
          '&.Mui-disabled': {
            pointerEvents: 'auto'
          }
        }
      }
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltipPlacementTop: {
          marginBottom: '0px !important'
        },
        tooltipPlacementRight: {
          marginLeft: '0px !important'
        },
        tooltipPlacementBottom: {
          marginTop: '0px !important'
        },
        tooltipPlacementLeft: {
          marginRight: '0px !important'
        }
      }
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& .MuiPickersCalendarHeader-labelContainer': {
            maxHeight: '39px'
          },
          '& .MuiPickersCalendarHeader-label': {
            color: theme.palette.text.secondary
          }
        }
      }
    },
    MuiDayPicker: {
      styleOverrides: {
        weekContainer: {
          '& .MuiPickersDay-root.Mui-selected': {
            '&:hover, &:focus': {
              backgroundColor: theme.palette.secondary.main
            }
          }
        }
      }
    },
    MuiMonthPicker: {
      styleOverrides: {
        root: {
          '& .PrivatePickersMonth-root.Mui-selected': {
            '&:hover, &:focus': {
              backgroundColor: theme.palette.secondary.main
            }
          }
        }
      }
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          '& .PrivatePickersYear-yearButton.Mui-selected': {
            '&:hover, &:focus': {
              backgroundColor: theme.palette.secondary.main
            }
          }
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('sm')]: {
            '&:not(.MuiContainer-disableGutters)': {
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2)
            }
          }
        }
      }
    }
  }
})

export const ellipsisContainer: CSSObject = {
  display: 'block',
  flex: '1 1 0',
  minWidth: 0,
  width: 1
}

export const activeIcon: CSSProperties = {
  width: 14,
  height: 14,
  background: 'rgb(231, 246, 255)',
  padding: 8,
  borderRadius: '100%',
  color: '#167A99'
}

export const widgetSubTitle = (theme: Theme): CSSObject => ({
  fontWeight: 'bold',
  [theme.breakpoints.up('md')]: {
    fontWeight: 600
  },
  marginBottom: theme.spacing(0.5),
  wordBreak: 'break-word'
})

export const actionButtons = (theme: Theme, position: 'sticky' | 'fixed' = 'fixed'): CSSObject => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  borderTop: `1px solid ${theme.palette.text.secondary}`,
  padding: `0 ${theme.spacing(2)}`,
  boxSizing: 'border-box',
  position: position,
  bottom: 0,
  left: 0,
  right: 0,
  background: theme.palette.background.light,
  paddingBottom: '20px',
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  '& button': {
    marginTop: '10px',
    marginBottom: '10px'
  },
  [theme.breakpoints.up('sidepanel')]: {
    paddingBottom: 0
  }
})

export default theme
