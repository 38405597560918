/* eslint-disable react/jsx-key */
import React, { Fragment, useEffect } from 'react'

import { useTheme } from '@mui/material/styles'
import { Moment as MomentType } from 'moment'

import { AlertWidget } from '_pages/companies/[id]/touchpoints'

import { ActivitiesListItem } from '_core/components/ActivitiesList'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import ActivityStatsWidget from '_core/components/widgets/ActivityStats'
import AliasesDomainsWidget from '_core/components/widgets/AliasesAndDomains'
import MarketDataWidget from '_core/components/widgets/CompanyMarketData'
import ContactsWidget from '_core/components/widgets/Contacts'
import CSuiteWidget from '_core/components/widgets/CSuite'
import ExternalKeysWidget from '_core/components/widgets/ExternalKeys'
import InteractionsWidget, { InteractionType } from '_core/components/widgets/Interactions'
import IntroducersWidget from '_core/components/widgets/Introducers'
import KeyTouchpointsWidget from '_core/components/widgets/KeyTouchpoints'
import RelationshipsWidget from '_core/components/widgets/Relationships'
import SimilarCompaniesWidget from '_core/components/widgets/SimilarCompanies'

import useActivitiesStatsUserSettings from '_core/hooks/useActivitiesStatsUserSettings'
import { ActivityStatsPeriod } from '_core/hooks/useActivityStatsPeriod'
import { CSuiteType } from '_core/hooks/useCSuite'
import useSearchQuery from '_core/hooks/useSearchQuery'
import { CompaniesType as SimilarCompaniesType } from '_core/hooks/useSimilarCompanies'
import useTouchpointRelationSummary from '_core/hooks/useTouchpointRelationSummary'

import UserSettings from '_core/UserSettings'

import Paths from 'Paths'

export type ProfileAddProps = {
  activityStats: Pick<ActivitiesListItem, 'outbound' | 'inbound' | 'meetings'>[] | undefined
  activityStatsChartData: ActivityStatsPeriod
  isSalesforceEnabled: boolean | undefined
  showInteractionsInProfile: boolean | undefined
  interactions:
    | {
        are_more: boolean
        skip_token: string
        data: InteractionType[]
      }
    | { errorMessage: string }
    | null
    | undefined
  marketData: CompanyMarketDataType | null
  similar: SimilarCompaniesType | null
  getSimilar: () => Promise<void>
  getCompanyDetails: (name: string, website?: string) => CompaniesMarketDataType | undefined
  cSuite: CSuiteType | null
  getCSuite: () => Promise<void>
  getPersonDetails: (name: string, email?: string) => PeopleMarketDataType | undefined
  enabledMarketData: MarketDataIntegration['enabled']
  showSimilarCompanies: MarketDataIntegration['showSimilarCompanies']
  isStatsWidgetVisible: boolean | undefined
  isDetailedActivityFromStatsWidgetVisible: boolean | undefined
}

const Profile = (props: DynamicEntityChild<CompanyType, true, false, ProfileAddProps>) => {
  const theme = useTheme()
  const wideStrict = useWide('lg')

  const companyName = props.CompanyNameText || props.BestUrlText

  const { setInitial, handleChange } = useActivitiesStatsUserSettings()
  const { queryParams } = useSearchQuery<CompanyProfilePageParams>()
  const { stackColumns } = queryParams

  useEffect(() => {
    if (!props.loading && props.CompanyMd5) window.parent.postMessage('da_open', '*')
  }, [props.loading, props.CompanyMd5])

  const statsArr = !props.loading
    ? Object.getOwnPropertyNames(props.Stats).filter(
        (k) =>
          ['FirstInboundMsg', 'FirstMeeting', 'FirstOutboundMsg', 'LastInboundMsg', 'LastMeeting', 'LastOutboundMsg', 'NextFutureMeeting'].indexOf(
            k
          ) > -1
      )
    : []

  const updatePeriod = (period: readonly [MomentType, MomentType]) => {
    handleChange({ from: period[0].toISOString(), to: period[1].toISOString() })
  }

  const toggleStack = () => {
    handleChange({ stackColumns: `${!JSON.parse(stackColumns || 'false')}` })
  }

  const keyTouchpoints = useTouchpointRelationSummary(props.Stats)

  const ExternalKeysWidgetEl = props.CrmSourceKeys?.data?.length ? (
    <ExternalKeysWidget
      entity="company"
      loading={props.loading}
      keys={props.CrmSourceKeys.data.map((key) => ({ sourceId: key.SourceId, lastModified: key.LastModifiedDateTime }))}
      total={props.CrmSourceKeys.total_item_count}
      name={companyName}
      link={`${Paths._companies}/${props.CompanyMd5}/keys`}
    />
  ) : (
    <></>
  )

  const ContactsWidgetEl = (
    <ContactsWidget
      loading={props.loading}
      entityName={companyName}
      link={`${Paths._companies}/${props.CompanyMd5}/contacts`}
      total={props.Employees?.total_item_count}
      items={
        props.Employees?.data?.slice(0, 5).map((contact) => ({
          name: contact.PersonNameText || contact.ContactBestEmail,
          score: contact.WeKnowPersonScore,
          sidepanel: true,
          link: `${Paths._people}/${contact.PersonMd5}`,
          userKey: contact.ContactBestEmail
        })) || []
      }
    />
  )

  const IntroducersWidgetEl = (
    <IntroducersWidget
      title="Introducers"
      loading={props.loading}
      empty={`${companyName} has no introducers`}
      link={`${Paths._companies}/${props.CompanyMd5}/introducers`}
      total={props.Introducers?.total_item_count}
      items={
        props.Introducers?.data.slice(0, 5).map((intro) => ({
          name: intro.IntroducerName,
          userKey: intro.IntroducerBestEmailAddressText,
          score: intro.IntroducerScore,
          link: `${Paths._relationships}/${intro.UserKeyMd5 || intro.IntroducerPersonKeyMd5}/companies/${props.CompanyMd5}`,
          sidepanel: true
        })) || []
      }
    />
  )

  const MarketDataWidgetEl = (
    <MarketDataWidget
      md5={props.CompanyMd5}
      marketData={!!props.marketData}
      companyName={companyName}
      id={props.marketData?.id}
      summary={props.marketData?.summary}
      tags={props.marketData?.tags}
      website={props.marketData?.website}
      founded={props.marketData?.founded}
      location={props.marketData?.location}
      employeeCount={props.marketData?.employeeCount}
      industry={props.marketData?.industry}
    />
  )

  const CSuiteWidgetWidgetEl = <CSuiteWidget cSuite={props.cSuite} companyName={companyName} loading={props.loading} />

  const SimilarCompaniesWidgetEl = (
    <SimilarCompaniesWidget marketData={!!props.marketData} similar={props.similar} companyName={companyName} loading={props.loading} />
  )

  const InteractionsWidgetEl = (
    <InteractionsWidget
      interactions={props.interactions && 'data' in props.interactions ? props.interactions.data : undefined}
      errorMessage={props.interactions && 'errorMessage' in props.interactions ? props.interactions.errorMessage : undefined}
      loading={props.loading}
      entityName={companyName}
      link={props.CompanyMd5 ? `${Paths._companies}/${props.CompanyMd5}/interactions` : ''}
    />
  )

  const ActivitiesStatsWidgetEl = (
    <UserSettings
      key={props.CompanyMd5 && `${!!stackColumns}`}
      endpoint={!stackColumns && props.isStatsWidgetVisible ? '/usersettings/activitiesstats' : ''}
      setInitial={setInitial}
    >
      <ActivityStatsWidget
        isDetailedActivityFromStatsWidgetVisible={!!props.isDetailedActivityFromStatsWidgetVisible}
        stats={props.activityStats}
        stack={stackColumns === 'true'}
        detailsLink={`${Paths._companies}/${props.CompanyMd5}/activities`}
        seeAllLink={`${Paths._companies}/${props.CompanyMd5}/activityStats`}
        updatePeriod={updatePeriod}
        toggleStack={toggleStack}
        {...props.activityStatsChartData}
      />
    </UserSettings>
  )

  const TouchpointsWidgetEl = (
    <KeyTouchpointsWidget
      loading={props.loading}
      entityName={companyName}
      link={statsArr.length && !props.loading ? `${Paths._companies}/${props.CompanyMd5}/touchpoints` : ''}
      items={keyTouchpoints || []}
      // check whether stats could be null
      total={props.loading || props.Stats === null ? -1 : statsArr.length}
    />
  )

  const RelationshipsWidgetEl = (
    <RelationshipsWidget
      loading={props.loading}
      items={
        props.Employees?.data
          .filter((item) => item.KnowerNameText)
          .slice(0, 3)
          .map((rel) => ({
            name1: rel.PersonNameText || rel.ContactBestEmail,
            name2: rel.KnowerNameText,
            score: rel.UserKnowsPersonScore,
            userKey1: rel.ContactBestEmail
          })) || []
      }
      link={`${Paths._companies}/${props.CompanyMd5}/relationships`}
      companyName={companyName}
    />
  )

  const AliasesDomainsWidgetEl = (
    <AliasesDomainsWidget
      loading={props.loading}
      link={`${Paths._companies}/${props.CompanyMd5}/aliases`}
      aliasesTotal={props.Aliases?.total_item_count}
      domainsTotal={props.Urls?.total_item_count}
      aliases={
        props.Aliases?.data?.slice(0, 2).map((name) => ({
          name: companyName,
          identity: name.CoNameAlias,
          icon: ['far', 'building'],
          type: 'CompanyName',
          sourceLink: `${Paths._companies}/${props.CompanyMd5}/aliasSources/${name.CoNameAlias}`,
          auditLink: `${Paths._companies}/${props.CompanyMd5}/audit`
        })) || []
      }
      domains={
        props.Urls?.data?.slice(0, 2).map((email) => ({
          name: companyName,
          identity: email.CoUrl,
          icon: ['far', 'globe'],
          type: 'DomainUrl',
          sourceLink: `${Paths._companies}/${props.CompanyMd5}/domainSources/${email.CoUrl}`,
          auditLink: `${Paths._companies}/${props.CompanyMd5}/audit`
        })) || []
      }
    />
  )

  const columnsRows = [
    {
      condition: props.showInteractionsInProfile && props.isStatsWidgetVisible && props.enabledMarketData && props.showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [InteractionsWidgetEl]]
            },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [CSuiteWidgetWidgetEl]] },
            { columns: [[ContactsWidgetEl], [SimilarCompaniesWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactsWidgetEl], [CSuiteWidgetWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: props.showInteractionsInProfile && props.isStatsWidgetVisible && props.enabledMarketData && !props.showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [InteractionsWidgetEl]]
            },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [TouchpointsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: props.showInteractionsInProfile && props.isStatsWidgetVisible && !props.enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [InteractionsWidgetEl]]
            },
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[IntroducersWidgetEl, ContactsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !props.showInteractionsInProfile && props.isStatsWidgetVisible && props.enabledMarketData && props.showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[ActivitiesStatsWidgetEl, []], [MarketDataWidgetEl]] },
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl], [SimilarCompaniesWidgetEl]] },
            { columns: [[CSuiteWidgetWidgetEl], [RelationshipsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[ContactsWidgetEl], [CSuiteWidgetWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !props.showInteractionsInProfile && props.isStatsWidgetVisible && props.enabledMarketData && !props.showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[ActivitiesStatsWidgetEl, []], [MarketDataWidgetEl]] },
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !props.showInteractionsInProfile && props.isStatsWidgetVisible && !props.enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[ActivitiesStatsWidgetEl, []], [IntroducersWidgetEl]] },
            { columns: [[ContactsWidgetEl], [TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: props.showInteractionsInProfile && !props.isStatsWidgetVisible && props.enabledMarketData && props.showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, ContactsWidgetEl], [IntroducersWidgetEl, CSuiteWidgetWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactsWidgetEl], [CSuiteWidgetWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: props.showInteractionsInProfile && !props.isStatsWidgetVisible && props.enabledMarketData && !props.showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, ContactsWidgetEl], [IntroducersWidgetEl, RelationshipsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: props.showInteractionsInProfile && !props.isStatsWidgetVisible && !props.enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl, TouchpointsWidgetEl], [ContactsWidgetEl, RelationshipsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl, ContactsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !props.showInteractionsInProfile && !props.isStatsWidgetVisible && props.enabledMarketData && props.showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [CSuiteWidgetWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[ContactsWidgetEl], [CSuiteWidgetWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !props.showInteractionsInProfile && !props.isStatsWidgetVisible && props.enabledMarketData && !props.showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !props.showInteractionsInProfile && !props.isStatsWidgetVisible && !props.enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    }
  ]

  const colRows = (columnsRows.find(({ condition }) => !!condition) || columnsRows[0]).rows.find(({ condition }) => condition)?.colRows

  return (
    <>
      <Wide>
        <Columns>
          <Column md={12}>
            <AlertWidget {...props} />
          </Column>
        </Columns>
        {colRows?.map(({ columns }, k) => (
          <Columns key={k}>
            {columns.map((column, i) => {
              if (!Array.isArray(column)) {
                return (
                  <Column md={12} key={`${k}${i}`}>
                    {column}
                  </Column>
                )
              }
              return (
                <Column key={`${k}${i}`} two={!wideStrict} double={!!column.find((r) => Array.isArray(r))}>
                  {column.map((row, j) =>
                    Array.isArray(row) ? (
                      <Columns
                        key={`${k}${i}${j}`}
                        md={12}
                        minWidth={`calc(100% + ${theme.spacing(2)})`}
                        {...(!row.length ? { display: 'none' } : {})}
                      >
                        {row.map((r, y) => (
                          <Column md={6} key={`${k}${i}${j}${y}`}>
                            {r}
                          </Column>
                        ))}
                      </Columns>
                    ) : (
                      <Fragment key={`${k}${i}${j}`}>{row}</Fragment>
                    )
                  )}
                </Column>
              )
            })}
          </Columns>
        ))}
      </Wide>
      <Narrow>
        <AlertWidget {...props} />
        {props.enabledMarketData && MarketDataWidgetEl}
        {props.isStatsWidgetVisible && ActivitiesStatsWidgetEl}
        {IntroducersWidgetEl}
        {ContactsWidgetEl}
        {props.showSimilarCompanies && (
          <>
            {CSuiteWidgetWidgetEl}
            {SimilarCompaniesWidgetEl}
          </>
        )}
        {props.showInteractionsInProfile && InteractionsWidgetEl}
        {TouchpointsWidgetEl}
        {RelationshipsWidgetEl}
        {AliasesDomainsWidgetEl}
        {ExternalKeysWidgetEl}
      </Narrow>
    </>
  )
}
export default Profile
