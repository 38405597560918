import { useContext, useEffect, useMemo } from 'react'

import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import { DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useEntityEndpoint, { useNewEntityEndpoint } from '_core/hooks/useEntityEndpoint'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { stringifyUrl } from '_core/helpers/browser'

import { postBinary } from 'utils/httpUtils'
import { dateFormatURLQuery, getLocal } from 'utils/Utils'

import Paths from 'Paths'

export type Meeting = {
  id: string
  subject: string
  location: string
  participants: {
    id: string
    name: string
    email: string
    companyMd5: string
  }[]
  organizer: {
    id: string
    name: string
    email: string
    companyMd5: string
  }
  companies: string[]
  startDate: string
  endDate: string
  link: string
}

const useContributorsMeetings = (ids: string[]) => {
  const { id: contributorLevel } = useParams<{ id: string } | undefined>() || {}
  const { queryParams } = useSearchQuery<EntityMeetingsPageParams | MeetingsPageParams>()
  const { from, to, sort = 'NewestToOldest', page, rowsPerPage = '10', checked } = queryParams

  const { teamContextValue } = useContext(TeamContext)

  const { result: userKeyResult } = useEntityEndpoint<{ results: ProfileType } | null>(`/me/profile`)
  const { UserKeyMd5, UserKey } = userKeyResult?.results || {}
  const userCompany = UserKey?.split('@')[1]

  const shouldFetch = contributorLevel ? queryParams.from : 'ids' in queryParams

  const body = useMemo(
    () => ({
      minDate: getLocal(from, dateFormatURLQuery).startOf('day').toISOString(),
      maxDate: getLocal(to, dateFormatURLQuery).endOf('day').toISOString(),
      direction: sort,
      participantTake: 100,
      withMeetingTags: false,
      havingMeetingTags: '',
      lackingMeetingTags: '',
      pagingBy: 'FocusedPersonId',
      focusedRole: 'Any',
      focusPersonIds: ids,
      includeRecurringMeetings: checked?.includes('includeRecurringMeetings')
    }),
    [from, ids, sort, to, checked]
  )

  const { result, loading, reload, more } = useNewEntityEndpoint<RemoteData<ActivityMeetingsRespType>>({
    url: shouldFetch ? `/meetings/byperiod?teamNumber=${teamContextValue.teamNumber}` : null,
    body,
    pageSize: +rowsPerPage,
    page: +(page || '1')
  })

  useEffect(() => {
    if (!shouldFetch) {
      reload()
    }
  }, [shouldFetch])

  const participantsMapper = ({
    StandardizedName: name,
    PersonIdText: email,
    PersonIdMd5: id,
    CompanyIdText: md5
  }: ActivityEmailsRespType['Participants']['data'][number]) => ({
    id,
    name: name || email || id, // Ask some participants name and email are missed, should I use md5 instead,
    email: email || '', // Ask some participants emails are missed,
    companyMd5: md5
  })

  const meetings: Meeting[] | undefined = result?.data?.map(
    ({ CalGlobalMd5, StartDateTime, EndDateTime, Participants, CalGlobalKey, Subject, Location }, i) => {
      const companies = Participants.data.reduce((acc, { CompanyIdText: companyDomain }) => {
        return companyDomain && !acc.includes(companyDomain) ? [...acc, companyDomain] : acc
      }, [] as string[])

      const isParticipant = Participants.data.findIndex(({ PersonIdMd5 }) => PersonIdMd5 === UserKeyMd5) > -1

      const { pathname, params }: { pathname: string; params: Modify<ActivityEventPageParams, { participants?: string[]; companies?: string[] }> } =
        isParticipant
          ? { pathname: `${Paths._events}/${CalGlobalKey}`, params: { idType: 'CalGlobalKey' } }
          : {
              pathname: `${Paths._activities}/event`,
              params: {
                participants: Participants.data.map((p) => p.PersonIdText || p.PersonIdMd5),
                companies: companies,
                startTime: StartDateTime
              }
            }

      const { internalParticipants, externalParticipants } = Participants.data.reduce(
        (acc, p) => (p.Role !== 'From' && acc[p.CompanyIdText === userCompany ? 'internalParticipants' : 'externalParticipants'].push(p), acc),
        {
          internalParticipants: [] as ActivityParticipant[],
          externalParticipants: [] as ActivityParticipant[]
        }
      )

      const organizer = Participants.data.filter(({ Role }) => Role === 'From')[0]
      return {
        id: CalGlobalMd5,
        subject: Subject,
        location: Location,
        participants: [...externalParticipants, ...internalParticipants, organizer].map(participantsMapper),
        organizer: participantsMapper(organizer),
        companies: companies,
        startDate: StartDateTime,
        endDate: EndDateTime,
        link: stringifyUrl(pathname, params) // Check whether we can fetch by id,
      }
    }
  )

  const download = {
    fileName: 'meetings',
    requestBinary: ({ skip, take }: DownloadBulkParams) =>
      postBinary(`/meetings/byperiodxl?teamNumber=${teamContextValue.teamNumber}`, { ...body, skip: +skip, take: +take })
  }

  return {
    meetings,
    total: result?.total_item_count,
    areMore: result?.are_more,
    loading: contributorLevel ? loading : loading && (!from || 'ids' in queryParams),
    more,
    reload,
    download
  }
}

export default useContributorsMeetings
