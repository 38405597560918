import { ComponentProps } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import NameLink from '_core/components/NameLink'
import TitleDescription from '_core/components/TitleDescription'

import { latestTouchpointKey, firstTouchpointKey, nextTouchpointKey } from '_core/helpers/touchpoints'

import Paths from 'Paths'

type TouchpointKey = Extract<
  InterectionType,
  'LastMeeting' | 'LastInboundMsg' | 'FirstMeeting' | 'FirstInboundMsg' | 'LastOutboundMsg' | 'FirstOutboundMsg' | 'NextFutureMeeting'
>

const useTouchpointRelationSummary = (stats: CompanyType['Stats']) => {
  if (stats) {
    const lastkey = latestTouchpointKey(stats) as TouchpointKey | undefined
    const firstkey = firstTouchpointKey(stats) as TouchpointKey | undefined
    const nextkey = nextTouchpointKey(stats) as TouchpointKey | undefined

    return (
      stats &&
      ([
        nextkey && {
          title: 'Upcoming',
          description: (
            <>
              <FontAwesomeIcon icon={['far', 'calendar-alt']} size="sm" color="#1B95BB" style={{ marginRight: '8px' }} />
              <NameLink
                url={`${Paths._people}/${stats[`${nextkey}${'UserKeyMd5'}`]}`}
                name={stats[`${nextkey}${'UserName'}`] || ''}
                preposition="with"
              />
            </>
          ),
          blurb: moment(stats[nextkey]).format('DD MMMM YYYY')
        },
        lastkey && {
          title: 'Latest',
          description: (
            <>
              <FontAwesomeIcon
                icon={lastkey === 'LastMeeting' ? ['far', 'calendar-alt'] : ['fas', 'comment-alt-lines']}
                size="sm"
                color="#1B95BB"
                style={{ marginRight: '8px' }}
              />
              <NameLink
                url={`${Paths._people}/${stats[`${lastkey}${lastkey === 'LastInboundMsg' ? 'ContactMd5' : 'UserKeyMd5'}`]}`}
                name={stats[`${lastkey}${lastkey === 'LastInboundMsg' ? 'ContactName' : 'UserName'}`] || ''}
              />
              <NameLink
                url={`${Paths._people}/${stats[`${lastkey}${lastkey === 'LastInboundMsg' ? 'UserKeyMd5' : 'ContactMd5'}`]}`}
                name={stats[`${lastkey}${lastkey === 'LastInboundMsg' ? 'UserName' : 'ContactName'}`] || ''}
                preposition={lastkey === 'LastMeeting' ? 'met' : 'to'}
              />
            </>
          ),
          blurb: moment(stats[lastkey]).format('DD MMMM YYYY')
        },
        firstkey && {
          title: 'First',
          description: (
            <>
              <FontAwesomeIcon
                icon={firstkey === 'FirstMeeting' ? ['far', 'calendar-alt'] : ['fas', 'comment-alt-lines']}
                size="sm"
                color="#1B95BB"
                style={{ marginRight: '8px' }}
              />
              <NameLink
                url={`${Paths._people}/${stats[`${firstkey}${firstkey === 'FirstInboundMsg' ? 'ContactMd5' : 'UserKeyMd5'}`]}`}
                name={stats[`${firstkey}${firstkey === 'FirstInboundMsg' ? 'ContactName' : 'UserName'}`] || ''}
              />
              <NameLink
                url={`${Paths._people}/${stats[`${firstkey}${firstkey === 'FirstInboundMsg' ? 'UserKeyMd5' : 'ContactMd5'}`]}`}
                name={stats[`${firstkey}${firstkey === 'FirstInboundMsg' ? 'UserName' : 'ContactName'}`] || ''}
                preposition={firstkey === 'FirstMeeting' ? 'met' : 'to'}
              />
            </>
          ),
          blurb: moment(stats[firstkey]).format('DD MMMM YYYY')
        }
      ].filter((key) => key) as Omit<ComponentProps<typeof TitleDescription>, 'blurb2'>[])
    )
  }
}

export default useTouchpointRelationSummary
