import { useContext, useEffect, useState, useMemo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Chip } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridSelectionModel } from '@mui/x-data-grid-pro'
import { useParams } from 'react-router-dom'

import { IconButton } from '_shared/buttons'

import IndexingDialog from '_core/components/dialogs/Indexing'
import RemoveTeamContributorsDialog from '_core/components/dialogs/RemoveTeamContributors'
import DownloadBulkControl from '_core/components/DownloadBulkControl'
import { DataGrid, GridPaginationType } from '_core/components/grid'
import { nameWithEmailColumn } from '_core/components/grid/columns'
import { Narrow, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import SidepanelLink from '_core/components/SidepanelLink'
import SharingBar from '_core/components/team/SharingBar'

import useAdmin from '_core/hooks/useAdmin'
import useDialog from '_core/hooks/useDialog'
import { DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useTeam from '_core/hooks/useTeam'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

export interface IMember {
  id: string
  teamName: string
  teamId: string
  name: string
  sharing: SharingData
  credentials: TeamMemberCredential[]
  prohibitAppAccess: boolean
  userKey: string
  userKeyPlainText: string
  link: string
  sidepanel?: boolean
  icons: JSX.Element
  byline: string
  byline2: JSX.Element
}

export type TeamMembersProps = {
  items: TeamMemberRes[]
  loading: boolean
  load: boolean
  setLoading: (val: boolean) => void
  handleChange?: () => void
  download: { fileName: string; requestUrl: (data: DownloadBulkParams) => Promise<any> }
} & GridPaginationType

const EditButton = ({ teamId, userKeyPlainText }: { teamId: string; userKeyPlainText: string }) => (
  <SidepanelLink linkProps={{ to: `${Paths._teams}/${teamId}/associations/user/${encodeURIComponent(userKeyPlainText)}/edit` }} sidepanel>
    <IconButton icon={['far', 'edit']} style={{ fontSize: 16 }} hint="Edit contributor`s team access" />
  </SidepanelLink>
)

const MembershipColumn = (props: GridRenderCellParams) => {
  const { checkIsDirectTeamMember } = useTeam()
  const isDirectMember = checkIsDirectTeamMember(props)

  return (
    <Box style={{ overflowX: 'auto', overflowY: 'hidden', textOverflow: 'unset' }}>
      {isDirectMember ? (
        <Chip label="Direct" style={{ fontSize: '12px', lineHeight: '1.2', height: '18px', backgroundColor: '#979797', color: '#fff' }} />
      ) : (
        props.row.credentials
          .filter((c: TeamMemberCredential) => c.credentialType !== 'OwnsEmailAddress')
          .map((c: TeamMemberCredential) => (
            <Chip
              key={c.credentialId}
              variant="outlined"
              size="small"
              title={c.credentialName}
              icon={<FontAwesomeIcon icon={['far', 'users']} style={{ width: '14px' }} />}
              label={c.credentialName.length > 18 ? `${c.credentialName.substr(0, 18)}…` : c.credentialName}
              style={{ fontSize: '12px', lineHeight: '1.2', marginRight: '8px', height: '18px', color: 'rgba(0,0,0,0.6)' }}
            />
          ))
      )}
    </Box>
  )
}

const TeamMembers = ({ items, loading, load, setLoading, total, handleChange, paging, setPageSize, download }: TeamMembersProps) => {
  const { setSubHeader, setMobileHeader } = useContext(LayoutContext)
  const [selected, setSelected] = useState<string[]>([])
  const { id: teamId } = useParams<{ id: string }>()
  const { checkIsDirectTeamMember } = useTeam()
  const { fileName, requestUrl: requestBinary } = download
  const { dialogContentProps: openedStatusDialog, openDialog: openStatusDialog, closeDialog: closeStatusDialog } = useDialog<any>(null)
  const { isDialogOpened: isRemoveDialogOpened, openDialog: openRemoveDialog, closeDialog: closeRemoveDialog } = useDialog()
  const admin = useAdmin()

  const teamName = items?.[0]?.teamName

  useEffect(() => {
    setMobileHeader(teamName || 'Team')
  }, [setMobileHeader, teamName])

  useEffect(() => {
    setSubHeader(`Contributors · ${total || 0}`)
    return () => setSubHeader('')
  }, [setSubHeader, total])

  const rows: IMember[] = useMemo(
    () =>
      items?.map((member) => {
        const sharing = {
          isSharing: member.isSharing,
          isSharingContactInfo: member.isSharingContactInfo,
          isSharingInteractionStats: member.isSharingInteractionStats,
          isSharingContactInfoWithAdminsAndCurators: member.isSharingContactInfoWithAdminsAndCurators
        }
        const userKey = member.userKeyMd5
        const email = member.emailAddress

        return {
          id: userKey,
          name: member.name,
          teamName: member.teamName,
          byline: email,
          byline2: <SharingBar userSharing={sharing} />,
          link: `${Paths._people}/${userKey}`,
          userKeyPlainText: member.userKeyPlainText,
          credentials: member.credentials,
          prohibitAppAccess: member.prohibitAppAccess,
          sharing,
          teamId,
          userKey,
          email,
          sidepanel: true,
          icons: (
            <Box display="flex" alignItems="center" justifyContent="end">
              {member.prohibitAppAccess === false && (
                <FontAwesomeIcon
                  title="This contributor has access to the team's data"
                  icon={['fas', 'circle-check']}
                  style={{ fontSize: 16, color: '#979797', cursor: 'default', marginRight: '4px' }}
                />
              )}
              {admin && <EditButton teamId={teamId} userKeyPlainText={member.userKeyPlainText} />}
            </Box>
          )
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [admin, loading, teamId]
  )

  const handleSelect = (selectionModel: GridSelectionModel) => {
    setSelected(selectionModel as string[])
  }

  const selectedItems = useMemo(() => rows?.filter(({ id }: IMember) => selected.includes(id)), [rows, selected])

  const DownloadAll = <DownloadBulkControl totalRows={total} disabled={loading} download={{ fileName, requestBinary }} />

  const RemoveAllMembers = useMemo(
    () => (
      <>
        {admin && <RemoveTeamContributorsDialog.TriggerEl open={openRemoveDialog} disabled={!selected.length || load} />}
        <RemoveTeamContributorsDialog
          isOpened={isRemoveDialogOpened}
          close={closeRemoveDialog}
          items={selectedItems}
          dropSelected={() => setSelected([])}
          teamId={teamId}
          reload={handleChange}
          loading={load}
          setLoading={setLoading}
        />
      </>
    ),
    [admin, handleChange, load, selected.length, selectedItems, setLoading, teamId, isRemoveDialogOpened, closeRemoveDialog, openRemoveDialog]
  )

  const teamMemberStatusColumn: GridColDef = useMemo(
    () => ({
      field: 'status',
      headerName: 'Status',
      width: 100,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params: GridRenderCellParams<{ row: { name: string } }>) => <IndexingDialog.TriggerEl open={() => openStatusDialog(params.row)} />
    }),
    [openStatusDialog]
  )

  return (
    <>
      <Narrow>
        <Repeater direction="vertical" variant="list" component={ProfileItem} skeleton={{ size: 5, loading }} items={rows} />
      </Narrow>
      <Wide>
        <DataGrid
          rows={rows || []}
          columns={[
            nameWithEmailColumn,
            membershipColumn,
            sharingColumn,
            teamMemberAccessColumn,
            teamMemberStatusColumn,
            ...(admin ? [teamMemberEditColumn] : [])
          ]}
          controls={[DownloadAll, RemoveAllMembers]}
          loading={loading}
          checkboxSelection={admin}
          paging={paging}
          setPageSize={setPageSize}
          total={total}
          onSelect={handleSelect}
          isRowSelectable={(params) => checkIsDirectTeamMember(params)}
          rowHeight={70}
        />
      </Wide>
      <IndexingDialog {...(openedStatusDialog || {})} close={closeStatusDialog} />
    </>
  )
}

export default TeamMembers

const teamMemberAccessColumn: GridColDef = {
  field: 'Access',
  headerName: 'Team access',
  width: 120,
  flex: 0.7,
  headerAlign: 'center',
  align: 'center',
  filterable: false,
  renderCell: (params: GridRenderCellParams) => (
    <FontAwesomeIcon
      title={!params.row.prohibitAppAccess ? "This contributor has access to the team's data" : "This contributor has no access to the team's data"}
      icon={!params.row.prohibitAppAccess ? ['fas', 'circle-check'] : ['far', 'ban']}
      style={{ fontSize: 16, color: '#979797' }}
    />
  )
}

const teamMemberEditColumn: GridColDef = {
  field: 'Edit',
  headerName: 'Edit',
  width: 100,
  flex: 0.5,
  headerAlign: 'center',
  align: 'center',
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <EditButton teamId={params.row.teamId} userKeyPlainText={params.row.userKeyPlainText} />
}

const membershipColumn: GridColDef = {
  field: 'membership',
  headerName: 'Membership',
  flex: 0.7,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <MembershipColumn {...params} />
}

const sharingColumn: GridColDef = {
  field: 'sharing',
  headerName: 'Sharing',
  flex: 0.7,
  minWidth: 130,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <SharingBar userSharing={params.row.sharing} />
}
