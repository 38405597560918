import { useEffect, useState, useCallback, useContext } from 'react'

import { useLocation } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import { useWide } from '_core/components/layout'
import { sortMap } from '_core/components/sort'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import useFilter from '_core/hooks/useFilter'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { monthsInAYear } from '_core/data/day'

import { getUTC } from 'utils/Utils'

type QueryParamType<T extends boolean> = T extends true ? ContributorsCompaniesPageParams : CompaniesPageParams

type InitialParams = Modify<CompaniesPageParams, { dealsChecked?: IncludeDealsType[] }> & { showInteractionsFilters: boolean; isOpened: boolean }

const companiesSaveData = {
  endpoint: '/usersettings/companiessearchfilter',
  getData: (params: InitialParams): CompaniesInit => {
    const {
      sort,
      rowsPerPage,
      perspective,
      where,
      interaction,
      period,
      days,
      and,
      showInteractionsFilters,
      dealsChecked = [],
      filterByDeal,
      viewMode,
      includeTags,
      excludeTags,
      isOpened
    } = params

    return {
      sort: sort || 'ScoreDesc',
      rowsPerPage: +(rowsPerPage || '20') as RowPerPageOptionsType,
      perspective: perspective || 'Team',
      touchpointDateType: where || 'First',
      touchpointType: interaction || 'Any',
      dateConditionType: period || 'Anytime',
      dayCount: +(days || '7') as DaysOptions,
      futureMeetingNotScheduled: !!and,
      showInteractionsFilters,
      showDealsFilters: filterByDeal && JSON.parse(filterByDeal),
      doneDealWithCompany: dealsChecked.includes('hadDeal'),
      expandedView: viewMode === 'expanded',
      includeTags: includeTags ? JSON.parse(includeTags) : includeTags,
      excludeTags: excludeTags ? JSON.parse(excludeTags) : excludeTags,
      isOpened
    }
  }
}

const useCompaniesUserSettings = (props: {
  allowFilteringByInteractions: boolean | undefined
  isContributor?: boolean
  showStatsColumns?: boolean
}) => {
  const wide = useWide()
  const { search } = useLocation()
  const { queryParams, updateQuery } = useSearchQuery<QueryParamType<typeof isContributor>, { modifyProps: [{ dealsChecked?: IncludeDealsType[] }] }>(
    ['dealsChecked']
  )

  const { teamContextValue } = useContext(TeamContext)

  const [isOpened, setOpened] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [params, setParams] = useState<InitialParams>()

  const { isContributor = false, allowFilteringByInteractions, showStatsColumns } = props
  const { save } = useFilter()
  const { fetchWithAbort } = useAbortableFetch<CompaniesInit>()

  const interactionsActive = !!queryParams.where
  const dealsActive = !!queryParams.filterByDeal && JSON.parse(queryParams.filterByDeal) === true

  const { result: teamResult } = useEntityEndpoint<{ results: TeamDataRes }>(`/teams/${teamContextValue.teamNumber}`)

  const { shareInteractionStats: isInteractionsEnabled } = teamResult?.results?.defaultSharingOptions || {}

  const touchpointData = (({ days, period, and, interaction, where }) => ({ days, period, and, interaction, where }))(params || queryParams)
  const dealsData = (({ dealsChecked }) => ({ dealsChecked }))(params || queryParams)

  const interactionsFiltersShown =
    typeof allowFilteringByInteractions === 'boolean' && typeof isInteractionsEnabled === 'boolean'
      ? !!(allowFilteringByInteractions && isInteractionsEnabled)
      : null

  const interactionsColumnsShown = typeof isInteractionsEnabled === 'boolean' && isInteractionsEnabled

  const getInitialParams = useCallback((data: CompaniesInit): InitialParams => {
    const {
      sort,
      rowsPerPage,
      showInteractionsFilters,
      perspective,
      touchpointDateType,
      touchpointType,
      dateConditionType,
      dayCount,
      futureMeetingNotScheduled,
      showDealsFilters,
      doneDealWithCompany,
      expandedView,
      includeTags,
      excludeTags,
      isOpened
    } = data
    const days: NumberToString<DaysOptions> = `${dayCount}`
    const checkboxes: { [key in IncludeDealsType]: boolean } = { hadDeal: doneDealWithCompany }
    const dealsChecked = (Object.keys(checkboxes) as IncludeDealsType[]).filter((key) => checkboxes[key])

    return {
      sort,
      showInteractionsFilters,
      rowsPerPage: `${rowsPerPage || 20}`,
      where: touchpointDateType,
      filterByDeal: `${showDealsFilters}`,
      perspective,
      viewMode: expandedView ? 'expanded' : 'collapsed',
      dealsChecked,
      includeTags: includeTags?.length ? JSON.stringify(includeTags) : '',
      excludeTags: excludeTags?.length ? JSON.stringify(excludeTags) : '',
      isOpened,
      ...(dayCount ? { days } : {}),
      ...(futureMeetingNotScheduled ? { and: 'noFutureScheduled' } : {}),
      ...(touchpointType !== 'Any' ? { interaction: touchpointType } : {}),
      ...(dateConditionType !== 'Anytime' ? { period: dateConditionType } : {})
    }
  }, [])

  const handleChange = (
    updates: typeof queryParams,
    additionalParams?: { [key in 'intActive' | 'dlsActive' | 'isOpened']?: boolean } | undefined
  ) => {
    const { intActive = interactionsActive, dlsActive = dealsActive, isOpened = params?.isOpened } = additionalParams || {}

    const hiddenParamsToSave = [
      { params: { perspective: params?.perspective }, condition: isContributor },
      { params: { showInteractionsFilters: intActive, isOpened }, condition: true },
      { params: touchpointData, condition: !intActive },
      { params: dealsData, condition: !dlsActive }
    ]
      .filter(({ condition }) => condition)
      .reduce((acc, { params }) => ({ ...acc, ...params }), {})

    return save<void>(getCompaniesSaveData(hiddenParamsToSave), { ...queryParams, ...updates })
  }

  const getExcludeEmpty = (sort: ScoreType | StatSortType | undefined): 'true' | 'false' | null => {
    if (!sort) {
      return null
    }

    const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)
    return sortByField && sortMap[sortByField].excludeEmpty ? `${sortMap[sortByField].excludeEmpty}` : null
  }

  const showStatsReady = 'showStatsColumns' in props ? typeof showStatsColumns === 'boolean' : true

  useEffect(() => {
    if (!search && interactionsFiltersShown !== null && showStatsReady && params) {
      const { showInteractionsFilters, dealsChecked } = params

      const baseParams = (({ sort, rowsPerPage, filterByDeal, viewMode, includeTags, excludeTags }) => ({
        sort,
        rowsPerPage,
        filterByDeal,
        viewMode,
        includeTags,
        excludeTags
      }))(params)

      const tData = (({ and, period, where, interaction, days }) => ({ and, period, where, interaction, days }))(params)
      const { days, ...restTData } = tData

      const to = getUTC().clone().endOf('month')
      const from = to
        .clone()
        .subtract(monthsInAYear - 1, 'months')
        .startOf('month')

      const dealData = { dealsChecked }
      const deals = baseParams.filterByDeal && JSON.parse(baseParams.filterByDeal) ? dealData : getEmptyRelatedParams(Object.keys(dealsData || {}))

      updateQuery({
        ...baseParams,
        // excludeEmpty: getExcludeEmpty(baseParams.sort),
        ...(!isContributor
          ? { perspective: params.perspective }
          : showStatsColumns && !('from' in queryParams) && !('to' in queryParams)
            ? { from: from.toISOString(), to: to.toISOString() }
            : {}),
        ...(showInteractionsFilters && interactionsFiltersShown ? { ...restTData, ...(restTData.period ? { days } : {}) } : {}),
        ...deals
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, updateQuery, params, interactionsFiltersShown, isContributor, showStatsColumns])

  useEffect(() => {
    const updateCallback = (prevState: typeof params) => {
      if (prevState) {
        const { days, period, and, interaction, where, dealsChecked } = queryParams
        const updateParams = [
          { params: { days, period, and, interaction, where }, condition: interactionsActive },
          { params: { dealsChecked }, condition: dealsActive }
        ]
          .filter(({ condition }) => condition)
          .reduce((acc, { params }) => ({ ...acc, ...params }), {})

        return { ...prevState, ...updateParams }
      }
    }

    setParams(updateCallback)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const setInitial = useCallback(
    (data: CompaniesInit) => {
      if (wide) {
        setOpened(data.isOpened)
      }
      setParams(getInitialParams(data))
      setLoading(false)
    },
    [getInitialParams, setLoading]
  )

  const getEmptyRelatedParams = (keys: string[]) => {
    return keys.reduce((acc, item) => ({ ...acc, [item]: undefined }), {} as { [key: string]: undefined })
  }

  const reset = async () => {
    setLoading(true)
    const defaultData = await fetchWithAbort({ url: '/usersettings/default/companiessearchfilter' })

    if (defaultData) {
      const params = getInitialParams(defaultData)
      setParams(params)

      const { showInteractionsFilters, dealsChecked, isOpened } = params
      const baseParams = (({ sort, rowsPerPage, filterByDeal, viewMode }) => ({
        sort,
        rowsPerPage,
        filterByDeal,
        viewMode
      }))(params)

      const tData = (({ and, period, where, interaction, days }) => ({ and, period, where, interaction, days }))(params)
      const { days, ...restTData } = tData

      const dealData = { dealsChecked }

      const saveData = {
        ...companiesSaveData,
        getData: (params: typeof queryParams) =>
          companiesSaveData.getData({ ...params, showInteractionsFilters, isOpened, ...(!showInteractionsFilters ? tData : {}) })
      }

      const deals = baseParams.filterByDeal ? dealData : getEmptyRelatedParams(Object.keys(dealsData || {}))

      const interactions =
        showInteractionsFilters && interactionsFiltersShown
          ? { ...restTData, ...(restTData.period ? { days } : {}) }
          : getEmptyRelatedParams(Object.keys(touchpointData || {}))

      const newQueryParams = {
        ...baseParams,
        ...(!isContributor ? { perspective: params.perspective } : {}),
        ...interactions,
        ...deals,
        includeTags: params.includeTags,
        excludeTags: params.includeTags
      }

      await save(saveData, newQueryParams)
      setOpened(isOpened)
      setLoading(false)
      return newQueryParams
    }
  }

  const getCompaniesSaveData = (additionalParams: any) => ({
    ...companiesSaveData,
    getData: (params: typeof queryParams) => companiesSaveData.getData({ ...params, ...additionalParams })
  })

  const toggleDealSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    const paramsToClear = getEmptyRelatedParams(Object.keys(dealsData || {}))
    return handleChange(
      { ...(checked ? { filterByDeal: `${checked}`, ...dealsData } : { filterByDeal: `${checked}`, ...paramsToClear }) },
      { dlsActive: checked }
    )
  }

  const toggleInteractionSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target

    const paramsToClear = getEmptyRelatedParams(Object.keys(touchpointData || {}))
    const { days, ...restTouchPointData } = touchpointData

    const optionalParams = [{ days: touchpointData.days, condition: touchpointData.period }]
      .filter(({ condition }) => condition)
      .reduce((acc, { condition, ...param }) => ({ ...acc, ...param }), {})

    return handleChange({ ...(checked ? { ...restTouchPointData, ...optionalParams } : paramsToClear) }, { intActive: checked })
  }

  const toggleOpen = () => {
    const newState = !isOpened
    setOpened(newState)

    if (wide) {
      setParams((prevState) => (prevState ? { ...prevState, isOpened: newState } : prevState))
      return handleChange({}, { isOpened: newState })
    }
  }

  return {
    setInitial,
    reset,
    interactionsFiltersShown,
    interactionsColumnsShown,
    handleChange,
    toggleDealSwitch,
    toggleInteractionSwitch,
    loading,
    opened: isOpened,
    toggleOpen
  }
}

export default useCompaniesUserSettings
