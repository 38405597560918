import { useCallback } from 'react'

import moment from 'moment'

import useFilter from '_core/hooks/useFilter'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { monthsInAYear } from '_core/data/day'

export type RenamedGridForContacts = {
  [K in keyof GridParams as `p${Capitalize<K>}`]: GridParams[K]
}

export type RenamedGridForCompanies = {
  [K in keyof GridParams as `c${Capitalize<K>}`]: GridParams[K]
}

const activitiesSaveData = {
  endpoint: '/usersettings/activitiesstats',
  getData: ({
    from,
    to,
    stackColumns
  }: ActivityStatsPageParams<GridParams | RenamedGridForContacts | RenamedGridForCompanies>): ActivityStatsInit => {
    return {
      fromDate:
        from ||
        moment
          .utc()
          .clone()
          .subtract(monthsInAYear - 1, 'months')
          .startOf('month')
          .toISOString(),
      toDate: to || moment.utc().endOf('month').toISOString(),
      stackColumns: stackColumns === 'true' || false
    }
  }
}

const useActivitiesStatsUserSettings = () => {
  const { save } = useFilter()
  const { queryParams, updateQuery } = useSearchQuery<ActivityStatsPageParams<GridParams | RenamedGridForContacts | RenamedGridForCompanies>>()

  const setInitial = useCallback(
    ({ fromDate: from, toDate: to, stackColumns }: ActivityStatsInit) => {
      updateQuery({ stackColumns: `${stackColumns}`, from, to })
    },
    [updateQuery]
  )

  const handleChange = (updates: typeof queryParams) => {
    save(activitiesSaveData, { ...queryParams, ...updates })
  }

  return {
    setInitial,
    handleChange
  }
}

export default useActivitiesStatsUserSettings
