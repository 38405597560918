import React, { useState } from 'react'

import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'

import LoadingButton from '_shared/buttons/LoadingButton'
import Card, { CardContent } from '_shared/Card'
import TextField from '_shared/forms/TextField'
import Page from '_shared/Page'
import Typography from '_shared/Typography'

import Heading from '_core/components/Heading'
import { Column, Columns } from '_core/components/layout'

import { post } from 'utils/httpUtils'

type FormType = { senderName: string; senderEmail: string; body: string; transportHeaders: string }

const EmailParserPage = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [result, setResult] = useState<string>('')
  const {
    register,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FormType>({
    defaultValues: {
      senderName: '',
      senderEmail: '',
      body: '',
      transportHeaders: ''
    }
  })
  const { enqueueSnackbar } = useSnackbar()

  const handleRequest = async (payload: FormType) => {
    setLoading(true)
    setResult('')
    try {
      const resp = await post('/Parser/email', payload)
      if (resp) {
        setResult(JSON.stringify(resp, null, 2))
      }
    } catch (error) {
      enqueueSnackbar('Encountered error during parsing', {
        variant: 'error'
      })
      console.log('error during parsing', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Page>
      <Columns>
        <Column two>
          <Card variant="outlined">
            <CardContent>
              <Heading title="Email Parser" underlined />
              <Box pb={1.5}>
                <TextField label="Sender name" {...register('senderName')} disabled={loading} fullWidth />
              </Box>
              <Box pb={1.5}>
                <TextField label="Sender email" {...register('senderEmail')} disabled={loading} fullWidth />
              </Box>
              <Box pb={1.5}>
                <TextField label="Body" {...register('body')} rows="5" multiline disabled={loading} fullWidth />
              </Box>
              <Box>
                <TextField label="Transport Headers" {...register('transportHeaders')} rows="5" multiline disabled={loading} fullWidth />
              </Box>
              <Box display="flex" justifyContent="flex-end" alignItems="center" pt={2}>
                <LoadingButton
                  loading={loading}
                  disabled={loading || !isDirty}
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit(handleRequest)}
                >
                  Request
                </LoadingButton>
              </Box>
            </CardContent>
          </Card>
        </Column>
        <Column two>
          <Card variant="outlined">
            <CardContent>
              <Heading title="Result" underlined />
              {!result && <Typography>Initiate a request to show a result</Typography>}
              {result && <Typography component="pre">{result}</Typography>}
            </CardContent>
          </Card>
        </Column>
      </Columns>
    </Page>
  )
}

export default EmailParserPage
