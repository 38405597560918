import React, { useContext, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import Chip, { FilterChipLabel } from '_core/components/Chip'
import Empty from '_core/components/Empty'
import { AppliedFilters } from '_core/components/filters'
import { transformFilters } from '_core/components/filters/MarketDataCompaniesSearch'
import InfiniteList from '_core/components/InfiniteList'
import { Narrow } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import MarketDataSearchLink from '_core/components/MarketDataSearchLink'
import ProfileItem from '_core/components/ProfileItem'
import Topbar from '_core/components/Topbar'

import { CompaniesType } from '_core/hooks/useSimilarCompanies'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type SimilarCompaniesProps = {
  similar: CompaniesType | null
  getSimilar: () => Promise<void>
}

const SimilarCompanies = ({ similar, getSimilar }: SimilarCompaniesProps) => {
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState(false)
  const { setSubHeader } = useContext(LayoutContext)

  const total = similar?.total || 0

  useEffect(() => {
    setSubHeader(`Similar Companies ${total ? `· ${total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, total])

  const items = similar?.data?.map((company) => {
    return {
      name: company.name,
      score: company.companyMd5 ? company.score : undefined,
      logoUrl: company.website,
      link: company.companyMd5
        ? `${Paths._companies}/${company.companyMd5}`
        : mergeUrlWithParams(`${Paths._companies}/${id}/similar/${company.name}`, {
            website: company.website
          }),
      sidepanel: true,
      byline: company.website,
      byline2: company.industry
    }
  })

  const loadMore = async () => {
    setLoading(true)
    await getSimilar()
    setLoading(false)
  }

  const { website, ...filters } = similar?.filters || {}
  const transformedFilters = transformFilters(filters)
  const filtersCount = Object.keys(transformedFilters).length

  return (
    <Narrow>
      <Topbar nativeBack autoHideOnScroll action={<MarketDataSearchLink entity="company" companiesFilters={JSON.stringify(filters)} variant="icon" />} />
        <AppliedFilters disabled={!filtersCount} filtersCount={filtersCount}>
          {transformedFilters.map(({ fieldLabel, value }) => {
            return <Chip key={value} label={<FilterChipLabel label={fieldLabel} value={value} />} color="secondary" size="small" />
          })}
        </AppliedFilters>
        <InfiniteList loading={loading} next={loadMore} dataLength={similar?.total || 0} hasMore={!!similar?.scrollToken}>
          <Repeater
            virtualized
            direction="vertical"
            component={ProfileItem}
            skeleton={{ size: 5, loading: !similar }}
            variant="list"
            empty={
              <Empty
                title="No records were found"
                action={
                  <Box display="flex" justifyContent="center">
                    <MarketDataSearchLink entity="company" companiesFilters={JSON.stringify(filters)} />
                  </Box>
                }
                icon={<FontAwesomeIcon size="3x" icon={['fat', 'buildings']} style={{ color: '#A7A7A7' }} />}
              />
            }
            items={items || []}
          />
        </InfiniteList>
    </Narrow>
  )
}

export default SimilarCompanies
