import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { AvatarWrapper, Display as AvatarDisplayProps } from '_shared/Avatar'
import { Score } from '_shared/Badge'
import Skeleton from '_shared/Skeleton'

import { useWide, Wide } from '_core/components/layout'

import Paths from 'Paths'

const useStyles = makeStyles<{ mode?: RelationshipProps['mode'] } | void>()((theme, props) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'start'
  },
  connection: {
    display: 'grid',
    width: '100%',
    gap: theme.spacing(2),
    gridTemplateColumns: 'minmax(0,1fr) auto minmax(0,1fr)',
    '& > *:first-of-type': {
      position: 'relative',
      paddingLeft: theme.spacing(2),
      '&:after': {
        content: '""',
        width: `calc(50% - ${theme.spacing(7)})`,
        height: 1,
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(3)
      }
    },
    '& > *:last-of-type': {
      position: 'relative',
      paddingRight: theme.spacing(2),
      '&:after': {
        content: '""',
        width: `calc(50% - ${theme.spacing(7)})`,
        height: 1,
        position: 'absolute',
        left: theme.spacing(1),
        top: theme.spacing(3)
      }
    }
  },
  connectionStyle: {
    '& > div': {
      '&:after': {
        background: 'rgba(27, 149, 187, 0.87)'
      }
    }
  },
  name: {
    fontSize: theme.spacing(2),
    flex: '1 1 auto',
    width: '100%',
    marginBottom: theme.spacing(2),
    boxSizing: 'border-box',
    lineHeight: '1.5rem'
  },
  avatar: {
    color: props?.mode === 'dark' ? theme.palette.background.light : '#1684A7',
    backgroundColor: props?.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.light,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(2.5),
    boxShadow: `0 0 0 1px ${theme.palette.background.light}`,
    '&:last-child': {
      marginLeft: theme.spacing(-1)
    }
  },
  badgeStyle: {
    marginTop: theme.spacing(1.5)
  },
  link: {
    marginTop: theme.spacing(0.5),
    color: 'rgba(27, 149, 187, 0.87)',
    textAlign: 'center'
  },
  title: {
    color: theme.palette.text.hint,
    textAlign: 'center'
  }
}))

const RelationshipDetail = ({
  name1,
  link1,
  title1,
  logo1,
  company1,
  companyId1,
  name2,
  link2,
  title2,
  logo2,
  company2,
  companyId2,
  score,
  mode,
  userKey1,
  userKey2
}: RelationshipProps) => {
  const { classes, cx } = useStyles({ mode })
  const wide = useWide()

  const nameMode = wide ? 'inline' : 'multiline'

  return (
    <div className={classes.root}>
      <div className={cx(classes.connection, name1 && classes.connectionStyle)}>
        <Box>
          <AvatarWrapper name={name1} size="md" link={link1} userKey={userKey1} logoUrl={logo1} nameMode={nameMode} />
          <Wide>
            <RelationshipDetailsContent title={title1 || ''} name={name1} company={company1 || ''} companyId={companyId1 || ''} />
          </Wide>
        </Box>
        <Box className={classes.wrapper}>
          <Score value={score} size="sm" className={classes.badgeStyle} skClassName={classes.badgeStyle} />
        </Box>
        <Box>
          <AvatarWrapper name={name2} size="md" link={link2} userKey={userKey2} logoUrl={logo2} nameMode={nameMode} />
          <Wide>
            <RelationshipDetailsContent title={title2 || ''} name={name2} company={company2 || ''} companyId={companyId2 || ''} />
          </Wide>
        </Box>
      </div>
    </div>
  )
}

export default RelationshipDetail

const RelationshipDetailsContent = ({ mode, title, name, company, companyId }: RelationshipContentProps) => {
  const { classes } = useStyles({ mode })

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Skeleton condition={!title && !name} width={120} height={25}>
        <span className={classes.title}>{title}</span>
      </Skeleton>
      <Skeleton condition={!company && !name} width={80} height={25}>
        <Link className={classes.link} to={`${Paths._companies}/${companyId}`}>
          {company}
        </Link>
      </Skeleton>
    </Box>
  )
}

export type Display = {
  nameMode?: AvatarDisplayProps['nameMode']
  mode?: AvatarDisplayProps['mode']
}

type Size = {
  size?: 'lg' | 'sm'
}

type RelationshipProps = {
  name1: string
  link1: string
  logo1?: string
  userKey1?: string
  name2: string
  link2: string
  logo2?: string
  userKey2?: string
  score: number | string | undefined
  loading?: boolean
  title1?: string
  company1?: string
  companyId1?: string
  title2?: string
  company2?: string
  companyId2?: string
} & Size &
  Display

type RelationshipContentProps = {
  title: string
  name: string
  company: string
  companyId: string
} & Display
