import { ComponentProps, useContext, useEffect, useMemo, useState } from 'react'

import { matchPath, useLocation, useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import { ActivityStatsPeriodMsg } from '_core/components/charts/ActivityStats'
import Filters from '_core/components/filters/Introducers'
import {
  lastInboundColumn,
  lastMeetingColumn,
  lastOutboundColumn,
  nameColumn,
  scoreColumn,
  nextFutureMeetingColumn,
  inboundCountColumn,
  outboundCountColumn,
  meetingsCountColumn,
  nameWithByLinesColumn,
  companyColumn as gridCompanyColumn,
  jobTitleColumn as gridJobTitleColumn
} from '_core/components/grid/columns'
import GridPageFrame from '_core/components/GridPageFrame'
import IntroducersList, { Heading, IntroducersListProps } from '_core/components/IntroducersList'
import { Narrow, useWide, Wide } from '_core/components/layout'
import { sortMap } from '_core/components/sort'
import Topbar from '_core/components/Topbar'

import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useActivitiesStats, { ActivityStatsMonth } from '_core/hooks/useActivitiesStats'
import useActivityStatsPeriod from '_core/hooks/useActivityStatsPeriod'
import { DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useInteractionsPeriodEndpointParams from '_core/hooks/useInteractionsPeriodEndpointParams'
import useIntroducersUserSettings from '_core/hooks/useIntroducersUserSettings'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSortStatsEndpointParams from '_core/hooks/useSortStatsEndpointParams'
import useTagsEndpointParams from '_core/hooks/useTagsEndpointParams'

import DynamicEntity from '_core/DynamicEntity'
import UserSettings from '_core/UserSettings'

import { getBinary, mergeUrlWithParams } from 'utils/httpUtils'
import { getUTC } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

export const jobTitleColumn = {
  ...gridJobTitleColumn,
  flex: 0.6
}

export const companyColumn = {
  ...gridCompanyColumn,
  flex: 0.6
}

const Introducers = (
  props: Modify<IntroducersListProps, { items: Required<PersonIntroducerListItem>[] }> & {
    months: ActivityStatsMonth[]
  }
) => {
  const { setSubHeader } = useContext(LayoutContext)
  const { id } = useParams<{ id: string }>()
  const { pathname } = useLocation()

  const { isExact: matchActivityStatsPath } = matchPath(pathname, `${Paths._people}/:id/activityStats`) || {}

  useEffect(() => {
    setSubHeader(`Introducers ${props.total ? `· ${props.total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, props.total])

  const md5s = useMemo(() => props.items.map(({ UserPersonKeyMd5 }) => UserPersonKeyMd5), [props.loading, props.items.length])

  const statsPayloads = useMemo(
    () => ({
      meetings: {
        pagingBy: 'FocusedPersonId',
        focusedRole: 'Any',
        person2Ids: [id],
        focusPersonIds: md5s
      },
      inbound: {
        pagingBy: 'Person2Id',
        focusedRole: 'Sender',
        focusPersonIds: [id],
        person2Ids: md5s
      },
      outbound: {
        pagingBy: 'FocusedPersonId',
        focusedRole: 'Sender',
        person2Ids: [id],
        focusPersonIds: md5s
      }
    }),
    [id, md5s]
  )
  const { stats } = useActivitiesStats(md5s ? statsPayloads : null, md5s, props.months)

  const items = useMemo(
    () =>
      props.items?.map((intro, idx) => {
        const lastInbound = intro.Stats?.LastInboundMsg || ''
        const lastOutbound = intro.Stats?.LastOutboundMsg || ''
        const lastMeeting = intro.Stats?.LastMeeting || ''
        const nextFutureMeeting = intro.Stats?.NextFutureMeeting || ''

        const { inbound, outbound, meetings } = stats?.[idx] || {}

        const isActivityStatsVisible = !!props.columns.find(({ field }) => field === 'inboundCount')
        const isStatsDatesVisible = !!props.columns.find(({ field }) => field === 'lastInboundMsg')

        return {
          id: intro.UserKeyMd5 || intro.UserPersonKeyMd5,
          userKey: intro.UserBestEmailAddressText,
          email: intro.UserBestEmailAddressText,
          name: intro.UserName,
          byline: intro.UserBestJobTitleText || '',
          byline2: intro.UserBestJobMatchedCompanyName || intro.UserBestJobCorpLevelCompanyName || '',
          score: intro.ScorePoints,
          title: intro.UserBestJobTitleText || '',
          company: {
            name: intro.UserBestJobMatchedCompanyName || intro.UserBestJobCorpLevelCompanyName || '',
            link: `${Paths._companies}/${intro.UserBestJobCompanyMd5}`,
            sidepanel: true as SidepanelType
          },
          link: `${Paths._relationships}/${intro.UserKeyMd5}/people/${id}`,
          sidepanel: true as SidepanelType,
          variant: 'expandable',
          tags: intro.Tags,
          ...(isActivityStatsVisible
            ? {
                inbound: stats ? inbound?.count || null : -1,
                outbound: stats ? outbound?.count || null : -1,
                meetings: stats ? meetings?.count || null : -1
              }
            : {}),
          ...(isStatsDatesVisible ? { lastInbound, lastOutbound, lastMeeting, nextFutureMeeting } : {})
        }
      }),
    [props.loading, props.items.length, stats, id]
  )

  return <IntroducersList {...props} items={items} enableMiddleView={matchActivityStatsPath} />
}

type IntroducersListPageProps = Pick<ComponentProps<typeof DynamicEntity>, 'onLoading' | 'autoHideOnScroll' | 'infinite' | 'updatePageSize'> &
  Pick<ComponentProps<typeof Introducers>, 'updateSort' | 'rowsPerPageOptions' | 'months' | 'columns'>

export const IntrList = (props: IntroducersListPageProps) => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = useContext(TeamContext)
  const { queryParams } = useSearchQuery<IntroducersPageParams>()
  const { keyword, rowsPerPage = '10', sort, viewMode, includeTags, excludeTags } = queryParams

  const interactionsPeriodParams = useInteractionsPeriodEndpointParams(queryParams)
  const tagsParams = useTagsEndpointParams('people', includeTags, excludeTags)
  const sortStatsParams = useSortStatsEndpointParams(sort)

  const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)

  const payload = {
    TeamNumber: `${teamContextValue.teamNumber}`,
    IncludeStats: `${true}`,
    WithPersonTags: `${true}`,
    ...[tagsParams, sortStatsParams, interactionsPeriodParams]
      .flat()
      .filter(({ value }) => !!value)
      .reduce((acc, { name, value }) => ({ ...acc, [name]: `${value}` }), {})
  }

  const url = sort ? mergeUrlWithParams(`/people/${id}/introducers`, payload) : null

  const download = {
    fileName: 'user_introducers',
    requestBinary: (data: DownloadBulkParams) => getBinary(`/people/${id}/introducersxl`, { ...payload, ...data })
  }

  return (
    <DynamicEntity<{
      extraProps: {
        addprops: Pick<
          ComponentProps<typeof Introducers>,
          'sort' | 'sortByField' | 'updateSort' | 'viewMode' | 'download' | 'rowsPerPageOptions' | 'months' | 'columns'
        >
      }
    }>
      url={url}
      addprops={{
        sort,
        updateSort: props.updateSort,
        rowsPerPageOptions: props.rowsPerPageOptions,
        sortByField: sortByField || 'score',
        viewMode: viewMode || 'collapsed',
        months: props.months,
        columns: props.columns,
        download
      }}
      pageSize={+rowsPerPage}
      updatePageSize={props.updatePageSize}
      component={Introducers}
      onLoading={props.onLoading}
      list
      search
      keepMounted
      infinite={props.infinite}
      autoHideOnScroll={props.autoHideOnScroll}
      empty="No results found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      id="person_introducers"
    />
  )
}

const ContactIntroducersListPage = () => {
  const { id } = useParams<{ id: string }>()
  const { queryParams, updateQuery } = useSearchQuery<IntroducersPageParams & Pick<ActivityStatsPageParams, 'from' | 'to'>>()
  const { sort, viewMode, excludeEmpty, from, to } = queryParams

  const { userActivitiesAccess } = useActivitiesAccess([id]) || {}
  const { isStatsWidgetVisible = false } = userActivitiesAccess || {}

  const wideStrict = useWide('lg')

  const chartData = useActivityStatsPeriod({
    fromUTC: from ? getUTC(decodeURIComponent(from)) : null,
    toUTC: to ? getUTC(decodeURIComponent(to)) : null
  })

  const [total, setTotal] = useState<number>()
  const [contentLoading, setContentLoading] = useState<boolean>(false)

  const {
    setInitial,
    handleChange,
    reset,
    loading: filtersLoading,
    opened,
    interactionsFiltersShown,
    interactionsColumnsShown,
    toggleInteractionSwitch,
    toggleOpen: toggleFilterOpen
  } = useIntroducersUserSettings(isStatsWidgetVisible)

  const disabled = filtersLoading || interactionsFiltersShown === null

  const columns = useMemo(
    () =>
      [
        { column: wideStrict ? { ...nameColumn, minWidth: 200, width: 200 } : { ...nameWithByLinesColumn, minWidth: 281, width: 281 } },
        { column: jobTitleColumn, condition: wideStrict },
        { column: companyColumn, condition: wideStrict },
        { column: inboundCountColumn, condition: isStatsWidgetVisible },
        { column: outboundCountColumn, condition: isStatsWidgetVisible },
        { column: meetingsCountColumn, condition: isStatsWidgetVisible },
        { column: lastInboundColumn, condition: !!interactionsColumnsShown },
        { column: lastOutboundColumn, condition: !!interactionsColumnsShown },
        { column: lastMeetingColumn, condition: !!interactionsColumnsShown },
        { column: nextFutureMeetingColumn, condition: !!interactionsColumnsShown },
        { column: scoreColumn }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || !!condition)
        .map(({ column }) => column),
    [interactionsColumnsShown, isStatsWidgetVisible, wideStrict]
  )

  const sortItems = columns.filter(({ sortable }) => sortable).map(({ headerName, field }) => ({ label: headerName || '', field }))

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    handleChange({ rowsPerPage })
  }

  const updateSort = (sort: ScoreType | StatSortType) => {
    handleChange({ sort })
  }

  const toggleExclude = () => {
    updateQuery({ excludeEmpty: excludeEmpty === 'true' ? 'false' : 'true' })
  }

  const updateViewMode = (viewMode: ViewModeType) => {
    handleChange({ viewMode })
  }

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    setContentLoading(loading)
    setTotal(result?.total_item_count)
  }

  const filtersProps = {
    toggleOpen: toggleFilterOpen,
    interactionsFiltersShown: !!interactionsFiltersShown,
    disabled,
    opened,
    total,
    contentLoading,
    reset,
    queryData: queryParams,
    handleChange,
    toggleInteractionSwitch
  }

  const searchPlaceholder = 'Search for introducers'

  return (
    <UserSettings endpoint="/usersettings/introducersfilter" setInitial={setInitial}>
      <Narrow>
        <Topbar nativeBack autoHideOnScroll />
      </Narrow>
      <GridPageFrame
        loading={typeof total !== 'number'}
        gridTitle="Introducers"
        gridHeadingIcon={['far', 'address-book']}
        searchPlaceholder={searchPlaceholder}
        disabledSearch={filtersProps.disabled}
        filters={<Filters {...filtersProps} />}
        heading={
          <Heading
            filters={<Filters {...filtersProps} />}
            filtersProps={{ opened: filtersProps.opened, toggleOpen: filtersProps.toggleOpen, disabled: filtersProps.disabled }}
            sortProps={{ sort, updateSort, items: sortItems, toggleExclude, excludeEmpty: excludeEmpty === 'true' }}
            viewProps={{ viewMode, updateViewMode }}
            searchPlaceholder={searchPlaceholder}
          >
            {viewMode === 'expanded' && isStatsWidgetVisible && <ActivityStatsPeriodMsg title={chartData.gridMessage} />}
          </Heading>
        }
        component={
          <>
            {isStatsWidgetVisible && (
              <Wide>
                <ActivityStatsPeriodMsg title={chartData.gridMessage} />
              </Wide>
            )}
            <IntrList
              autoHideOnScroll
              infinite
              columns={columns}
              updateSort={updateSort}
              updatePageSize={onPageSizeChange}
              months={chartData.months}
              onLoading={onLoading}
            />
          </>
        }
      />
    </UserSettings>
  )
}

export default ContactIntroducersListPage
