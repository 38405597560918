import { useContext, useEffect } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import { useLookUpCompaniesByIds } from '_core/hooks/useLookup'
import useSearchQuery from '_core/hooks/useSearchQuery'

const useActivitiesCompanies = () => {
  const { queryParams } = useSearchQuery<ActivitiesPageParams, { modifyProps: [{ customList: string[] }] }>(['customList'])
  const { groupBy, view, customList = [] } = queryParams
  const customView = groupBy === 'Company' && view === 'Custom'
  const {
    fetchWithAbort,
    loading: companiesLoading,
    result: companiesListResult,
    reset: resetCompaniesList
  } = useAbortableFetch<{ data: CompaniesListItem[] }>()
  const { lookUpCompaniesByIds, loading: companiesByIdsLoading, companiesByIds, reset: resetCompaniesByIds } = useLookUpCompaniesByIds()

  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue

  const { makeRequest, data, loading, reset } =
    [
      {
        loading: companiesLoading,
        data: companiesListResult?.data,
        makeRequest: () => fetchWithAbort({ url: `/companies?teamNumber=${teamNumber}&SortBy=ScoreDesc&Take=5` }),
        reset: resetCompaniesList,
        condition: groupBy === 'Company' && view === 'Top'
      },
      {
        loading: companiesLoading,
        data: companiesListResult?.data,
        makeRequest: () =>
          fetchWithAbort({
            url: `/companies?teamNumber=${teamNumber}&SortBy=Stats&StatSortType=LastActivity&StatSortOldestToNewest=false&Take=5`
          }),
        reset: resetCompaniesList,
        condition: groupBy === 'Company' && view === 'Recent'
      },
      {
        loading: companiesByIdsLoading,
        data: companiesByIds?.data.sort((a, b) => customList.indexOf(a.CompanyMd5) - customList.indexOf(b.CompanyMd5)),
        makeRequest: async () => lookUpCompaniesByIds(customList),
        reset: resetCompaniesByIds,
        condition: customView && customList.length > 0
      }
    ].find(({ condition }) => !!condition) || {}

  useEffect(() => {
    makeRequest && makeRequest()

    return () => {
      reset && reset()
    }
  }, [groupBy, view, customList.length])

  const list = data?.map(({ CompanyMd5, CompanyNameText, BestUrlText }: CompaniesListItem) => ({
    md5: CompanyMd5,
    name: CompanyNameText || BestUrlText,
    urlText: BestUrlText
  }))

  const listMd5s = list?.map(({ md5 }) => md5)

  return {
    list,
    loading,
    listMd5s
  }
}

export default useActivitiesCompanies
