import React, { ComponentProps } from 'react'

import { Box } from '@mui/material'

import Avatar from '_shared/Avatar'
import Typography from '_shared/Typography'

import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import Widget, { LinkAction, SeeAllAction } from '_core/components/widgets'

import Paths from 'Paths'

type CollaboratorsWidgetProps = {
  colleagues: ComponentProps<typeof Avatar>[]
  teams: ComponentProps<typeof Avatar>[]
  isCreator: boolean
  planUid: string
  count: number
  loading: boolean
}

const CollaboratorsWidget = ({ colleagues, teams, isCreator, planUid, loading, count }: CollaboratorsWidgetProps) => {
  const baseUrl = `${Paths._introductions}/${planUid}/collaborators`

  return (
    <Widget>
      <Heading
        title="Collaborators"
        count={count}
        icon={['fas', 'lock']}
        action={
          !loading ? (
            <>{!count && isCreator ? <LinkAction link={`${baseUrl}/add`} title="Add" /> : <SeeAllAction link={count ? baseUrl : ''} />}</>
          ) : null
        }
      />
      <Typography bold>Colleagues</Typography>
      <Repeater
        direction="horizontal"
        component={Avatar}
        variant="homepage"
        skeleton={{ size: 3, loading }}
        items={colleagues}
        empty="No colleagues"
      />
      <Box mt={1.5}>
        <Typography bold>Teams</Typography>
        <Repeater direction="horizontal" component={Avatar} variant="homepage" skeleton={{ size: 3, loading }} items={teams} empty="No teams" />
      </Box>
    </Widget>
  )
}

export default CollaboratorsWidget
