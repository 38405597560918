import React, { AnchorHTMLAttributes } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { Link, LinkProps } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import SidepanelLink from '_core/components/SidepanelLink'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    width: 'fit-content',
    maxWidth: '100%'
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    fontSize: 16,
    width: theme.spacing(2),
    minWidth: theme.spacing(2)
  },
  line: {
    maxWidth: '100%'
  }
}))

type LineProps = { loading: boolean; icon: IconProp; hint?: string; value?: string }

export const LinkLine = ({ loading, value, hint, icon, sidepanel, to, ...linkProps }: LineProps & LinkProps & { sidepanel?: boolean }) => {
  const { classes } = useStyles()

  return (
    <>
      {(loading || value) && (
        <Skeleton condition={!value}>
          <Tooltip title={hint || value}>
            <Box className={classes.wrapper}>
              <FontAwesomeIcon icon={icon} className={classes.icon} />
              <Typography className={classes.line} color="primary.main" noWrap>
                {sidepanel ? (
                  <SidepanelLink sidepanel linkProps={{ to }}>
                    {value || 'Placeholder name'}
                  </SidepanelLink>
                ) : (
                  <Link to={to} {...linkProps}>
                    {value || 'Placeholder name'}
                  </Link>
                )}
              </Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
    </>
  )
}

export const AnchorLinkLine = ({ loading, value, hint, icon, ...linkProps }: LineProps & AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const { classes } = useStyles()

  return (
    <>
      {(loading || value) && (
        <Skeleton condition={!value}>
          <Tooltip title={hint || value}>
            <Box className={classes.wrapper}>
              <FontAwesomeIcon icon={icon} className={classes.icon} />
              <Typography className={classes.line} color="primary.main" noWrap>
                <a {...linkProps}>{value || 'Placeholder name'}</a>
              </Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
    </>
  )
}

const Line = ({ loading, value, icon, hint }: LineProps) => {
  const { classes } = useStyles()

  return (
    <>
      {(loading || value) && (
        <Skeleton condition={!value}>
          <Tooltip title={hint || value}>
            <Box className={classes.wrapper}>
              <FontAwesomeIcon icon={icon} className={classes.icon} />
              <Typography className={classes.line} noWrap>
                {value || 'Placeholder name'}
              </Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
    </>
  )
}

export default Line
