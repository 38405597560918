import React from 'react'

import GridHeadingControl from '_core/components/GridHeadingControl'

const SendEmailToSelected = ({ disabled, recipients }: { disabled: boolean; recipients: string[] }) => {
  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    window.location.href = `mailto:${recipients.join(';')}`
  }

  return <GridHeadingControl hint="Send an email to the selected people" icon={['far', 'envelope']} onClick={handleClick} disabled={disabled} />
}

export default SendEmailToSelected
