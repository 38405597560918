import { matchPath, useLocation } from 'react-router-dom'

const usePathWithParams = <T extends Record<string, string>>() => {
  const { pathname } = useLocation()

  const resolvePathWithParams = (path: string) => {
    const match = matchPath<T>(pathname, { path })

    if (!match) return ''

    const paramsKeys = Object.keys(match.params)
    if (!paramsKeys.length) return match.path

    return paramsKeys.reduce((path, param) => path.replace(`:${param}`, match.params[param as keyof T]), match.path)
  }

  return resolvePathWithParams
}

export default usePathWithParams
