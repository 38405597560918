import { ComponentProps } from 'react'

import Avatar from '_shared/Avatar'

import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import Widget, { SeeAllAction } from '_core/components/widgets'

const InteractionCompaniesWidget = ({
  total,
  link,
  items,
  loading
}: {
  total?: number
  link: string
  items: ComponentProps<typeof Avatar>[]
  loading: boolean
}) => {
  return (
    <Widget>
      <Heading underlined title="Companies" count={total} icon={['far', 'building']} action={<SeeAllAction link={total ? link : ''} />} />
      <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 5, loading }} items={items} />
    </Widget>
  )
}

export default InteractionCompaniesWidget
