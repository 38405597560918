import { ChipProps } from '@mui/material'

import Skeleton from '_shared/Skeleton'

import { StatusChip as Chip } from '_core/components/Chip'

export type StatusType = 'active' | 'closed'

type Details = {
  label: string
  color: ChipProps['color']
}

type StatusesMap = {
  [key in StatusType | 'successful' | 'failed']: Details
}

export const statusesMap: StatusesMap = {
  active: {
    label: 'Active',
    color: 'secondary'
  },
  closed: {
    label: 'Closed',
    color: 'default'
  },
  successful: {
    label: 'Successful',
    color: 'success'
  },
  failed: {
    label: 'Failed',
    color: 'error'
  }
}

export const defineStatus = (id: string, closedOut: boolean): StatusType | undefined => {
  if (id) {
    if (closedOut) {
      return 'closed'
    } else {
      return 'active'
    }
  }
}

const StatusChip = (props: { planUid: string; closedOut: boolean } | { status?: StatusType | 'successful' | 'failed' }) => {
  const status = 'planUid' in props ? defineStatus(props.planUid, props.closedOut) : props.status
  const details = status ? statusesMap[status] : null

  return (
    <Skeleton condition={!details} width="70px" height="21px" variant="rounded">
      {details && <Chip label={details.label} color={details.color} size="small" />}
    </Skeleton>
  )
}

export default StatusChip
