import React, { ChangeEvent } from 'react'

import { makeStyles } from 'tss-react/mui'

import useSearchQuery from '_core/hooks/useSearchQuery'

import Filters from './index'

type SearchFiltersType = {
  opened: boolean
  contentLoading: boolean
  disabled: boolean
  handleChange: (params: ModifiedSearchPageParams) => void
  anchorEl?: HTMLElement | null
}

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      borderRight: '1px solid #f2f2f2',
      height: '100%',
      overflow: 'auto'
    }
  }
}))

const SearchFilters = ({ opened, contentLoading, disabled, anchorEl, handleChange }: SearchFiltersType) => {
  const { queryParams } = useSearchQuery<SearchPageParams, { modifyProps: [{ entities: SearchEntities[] }] }>(['entities'])
  const { entities = [] } = queryParams
  const { classes } = useStyles()

  const isChecked = (entity: SearchEntities) => !!entities?.includes(entity)

  const handleCheckboxEntityChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name: toggledEl } = e.target as { name: SearchEntities }
    const checked = entities.includes(toggledEl) ? entities.filter((el) => el !== toggledEl) : [...entities, toggledEl]
    handleChange({ entities: checked })
  }

  return (
    <Filters opened={opened} contentLoading={contentLoading} anchorEl={anchorEl} className={classes.container}>
      <>
        <Filters.Checkbox checked={isChecked('person')} onChange={handleCheckboxEntityChange} name="person" label="People" disabled={disabled} />
        <Filters.Checkbox checked={isChecked('company')} onChange={handleCheckboxEntityChange} name="company" label="Companies" disabled={disabled} />
      </>
    </Filters>
  )
}

export { Controller } from './index'
export default SearchFilters
