import React, { useState } from 'react'

import { Box } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'

import { IconButton } from '_shared/buttons'
import Checkbox from '_shared/forms/Checkbox'
import TextField, { URLTextField } from '_shared/forms/TextField'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { StatusChip } from '_core/components/Chip'
import Heading from '_core/components/Heading'
import ReachDotAlignButton from '_core/components/ReachDotAlignButton'
import Settings, { useStyles } from '_core/components/settings'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useSaveSettings, { SaveAction } from '_core/hooks/useSaveSettings'

import { addMissedProtocol, getRidOfProtocol, validUrl } from '_core/helpers/string'

const CloudHubSettings = (props: {
  loading: boolean
  isConfigured?: boolean
  marketDataIntegration?: MarketDataIntegration
  dotAlignCloudHub?: {
    url: string
    apiKey: string
  }
}) => {
  const {
    classes: { itemWrapper, subtitle, subsection }
  } = useStyles()

  const [isApiKeyShown, setApiKeyShown] = useState<boolean>(false)
  const { isSaving, save: saveSettings } = useSaveSettings()
  const [isConfigured, setIsConfigured] = useState<boolean | undefined>(props.isConfigured)
  const { fetchWithAbort, loading } = useAbortableFetch<boolean>()

  const {
    register,
    watch,
    setValue,
    getValues,
    control,
    formState: { dirtyFields },
    reset
  } = useForm<{ url?: string; apiKey?: string; enabled?: boolean; showSimilarPeople?: boolean; showSimilarCompanies?: boolean }>({
    mode: 'onChange',
    defaultValues: props.loading
      ? {}
      : { ...{ ...props.dotAlignCloudHub, url: getRidOfProtocol(props.dotAlignCloudHub?.url) }, ...props.marketDataIntegration } || {}
  })

  const { url, enabled } = watch()

  const save = async (action: SaveAction) => {
    const { showSimilarPeople, showSimilarCompanies, apiKey } = getValues()
    const promises = [
      {
        endpoint: '/adminsettings/marketDataIntegration',
        payload: { enabled, showSimilarCompanies, showSimilarPeople },
        condition: dirtyFields['enabled'] || dirtyFields['showSimilarCompanies'] || dirtyFields['showSimilarPeople']
      },
      {
        endpoint: '/adminsettings/dotAlignCloudHub',
        payload: { url: addMissedProtocol(url), apiKey },
        condition: dirtyFields['url'] || dirtyFields['apiKey']
      }
    ].filter(({ condition }) => !!condition)

    try {
      await Promise.all(promises.map(({ endpoint, payload }) => saveSettings(endpoint, payload)))
      action('Market data settings')
      if (dirtyFields['url'] || dirtyFields['apiKey']) {
        setIsConfigured(undefined)
        const isConfigured = await fetchWithAbort({ url: '/cloudhub/configured' })
        setIsConfigured(isConfigured)
      }
      reset(watch())
    } catch {
      action('Market data settings', 'error')
    }
  }

  const toggleApiKeyShow = () => {
    setApiKeyShown((prevState) => !prevState)
  }

  const handleEnabledChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    callback?: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined
  ) => {
    if (!checked) {
      setValue('showSimilarCompanies', false)
      setValue('showSimilarPeople', false)
    }
    if (callback) {
      callback(e, checked)
    }
  }

  const disabled = isSaving || loading
  const disabledMD = isSaving || !isConfigured
  const isInvalid = !!(url && dirtyFields?.url && !validUrl(url))

  const reachTeamBtn = <ReachDotAlignButton />

  return (
    <Settings isSaving={isSaving} save={save} initialLoading={props.loading} saveDisabled={isInvalid}>
      <Heading title="Configuration" className={subtitle} />

      <Skeleton condition={typeof isConfigured !== 'boolean' || disabled} width={200} height={32} variant="rounded">
        <StatusChip color={isConfigured ? 'success' : 'error'} label={`Cloud Hub settings are ${isConfigured ? 'valid' : 'invalid'}`} />
        {!isConfigured && (
          <Box mt={1}>
            <Typography color="text.secondary">Please reach out to {reachTeamBtn} if you need assistance configuring Cloud Hub</Typography>
          </Box>
        )}
      </Skeleton>

      <Box width={1} boxSizing="border-box" className={itemWrapper} mb={2}>
        <URLTextField
          {...register('url')}
          label="Hub URL"
          placeholder="Enter Hub URL"
          size="small"
          disabled={disabled}
          fullWidth
          errorMessage={isInvalid ? 'This field is invalid' : ''}
        />
        <Box className={itemWrapper}>
          <TextField
            {...register('apiKey')}
            label="API key"
            type={isApiKeyShown ? 'text' : 'password'}
            placeholder="Enter Hub API key"
            size="small"
            fullWidth
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <IconButton
                  disablePadding
                  hint={`${isApiKeyShown ? 'Hide' : 'Show'} API key`}
                  icon={['far', isApiKeyShown ? 'eye-slash' : 'eye']}
                  onClick={toggleApiKeyShow}
                  size="small"
                  disabled={disabled}
                />
              )
            }}
          />
        </Box>
      </Box>
      <Heading title="Market data settings" className={subtitle} />
      {isConfigured === false && <StatusChip color="default" label="CloudHub integration required" />}

      <Controller
        name="enabled"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            checked={!!value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => handleEnabledChange(event, checked, onChange)}
            disabled={disabledMD}
            label="Enable market data integration"
          />
        )}
      />

      <Box className={subsection} display="flex" flexDirection="column">
        <Controller
          name="showSimilarCompanies"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              checked={!!value}
              onChange={onChange}
              disabled={disabledMD || !enabled}
              label="Show similar companies and C-Suite inside company profile"
            />
          )}
        />
        <Controller
          name="showSimilarPeople"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox checked={!!value} onChange={onChange} disabled={disabledMD || !enabled} label="Show similar people inside people profile" />
          )}
        />
      </Box>
    </Settings>
  )
}

export default CloudHubSettings
