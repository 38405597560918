import React, { useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Tab, Tabs as MuiTabs } from '@mui/material'
import { Link, Route } from 'react-router-dom'

import { isSidepanel } from '_pages/sidebar'

import { Button } from '_shared/buttons'
import FloatingButton from '_shared/buttons/FloatingButton'
import { IMenuAction } from '_shared/buttons/FloatingMenuButton'
import Card, { CardContent } from '_shared/Card'
import Typography from '_shared/Typography'

import { Narrow, Wide } from '_core/components/layout'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import {
  HelpLink,
  privilegedCompaniesOnboardingTarget,
  privilegedMemberOnboardingTarget,
  privilegedContactsOnboardingTarget
} from '_core/components/onboarding/PrivilegedCompanies'
import SearchInput from '_core/components/SearchInput'
import SidepanelLink from '_core/components/SidepanelLink'
import Topbar from '_core/components/Topbar'

import useOnboarding from '_core/hooks/useOnboarding'
import useOutlook from '_core/hooks/useOutlook'
import useTabs from '_core/hooks/useTabs'

import Paths, { DataAdminSubPaths } from 'Paths'

export const baseUrl = DataAdminSubPaths.privilegedCompanies

const tabs = [
  {
    id: 'companies',
    label: 'COMPANIES',
    routesMatch: [baseUrl],
    className: privilegedCompaniesOnboardingTarget
  },
  {
    id: 'privileged-users',
    label: 'PRIVILEGED USERS',
    routesMatch: [`${baseUrl}/members`],
    className: privilegedMemberOnboardingTarget
  },
  {
    id: 'exceptions',
    label: 'EXCEPTIONS',
    routesMatch: [`${baseUrl}/contacts`],
    className: privilegedContactsOnboardingTarget
  }
]

export const Buttons = ({ action }: { action: IMenuAction }) => {
  return (
    <>
      <Wide>
        <SidepanelLink linkProps={{ to: action.link }} sidepanel={action.sidepanel}>
          <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={action.icon} style={{ fontSize: 14 }} />}>
            {action.label}
          </Button>
        </SidepanelLink>
      </Wide>
      <Narrow>
        <SidepanelLink linkProps={{ to: action.link }}>
          <FloatingButton>
            <FontAwesomeIcon icon={action.icon} size="lg" />
          </FloatingButton>
        </SidepanelLink>
      </Narrow>
    </>
  )
}

const Tabs = ({ loading }: { loading: boolean }) => {
  const { tabIndex } = useTabs(tabs)
  const { startOnboarding } = useOnboarding()

  useEffect(() => {
    if (!loading) {
      startOnboarding(DataAdminSubPaths.privilegedCompanies)
    }
  }, [loading])

  return (
    <MuiTabs value={tabIndex > -1 ? tabIndex : false} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
      {tabs.map(({ routesMatch, label }, idx) => (
        <Tab key={idx} component={Link} to={routesMatch[0]} label={label} />
      ))}
    </MuiTabs>
  )
}

export const SearchHeading = ({ loading }: { loading: boolean }) => {
  const isOutlook = useOutlook()
  const hasSidebar = isOutlook || isSidepanel()
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const anchorRef = useRef<HTMLDivElement | null>(null)

  return (
    <Route exact path={[baseUrl, `${baseUrl}/members`, `${baseUrl}/contacts`]}>
      <Topbar nativeBack={Paths._dataAdmin} action={<HelpLink />} />
      <Card elevation={0} square className={autoHideClassName} sticky={hasSidebar ? 88 : 61}>
        <CardContent>
          <Tabs loading={loading} />
          <div ref={anchorRef}>
            <SearchInput placeholder="Search" variant="collapsed" opened />
          </div>
        </CardContent>
      </Card>
    </Route>
  )
}

const Heading = ({ loading }: { loading: boolean }) => (
  <>
    <Wide>
      <Box display="flex" alignItems="center">
        <Typography variant="h1">Restricted companies</Typography>
        <HelpLink />
      </Box>
      <Box mt={2}>
        <Tabs loading={loading} />
      </Box>
    </Wide>
    <Narrow>
      <SearchHeading loading={loading} />
    </Narrow>
  </>
)

export default Heading
