import React, { PropsWithChildren } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid } from '@mui/material'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Narrow, Wide } from './layout'

export type DashboardLinkType = { name: string; link: string; icon: IconProp; condition?: boolean }

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    background: theme.palette.background.light,
    borderRadius: theme.spacing(0.5)
  },
  panel: {
    background: theme.palette.background.darker,
    height: '100%',
    marginRight: theme.spacing(2)
  },
  content: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(2)
    }
  },
  icon: {
    padding: theme.spacing(1),
    borderRadius: '100%',
    color: theme.palette.text.secondary
  },
  selectedIcon: {
    color: theme.palette.secondary.main,
    background: theme.palette.secondary.light
  },
  link: {
    fontSize: 12,
    padding: `${theme.spacing(2)} 0`,
    display: 'flex',
    flexFlow: 'column wrap',
    textAlign: 'center',
    maxWidth: '100%',
    color: theme.palette.text.secondary,
    '&:focus': {
      background: theme.palette.background.light
    },
    '& svg': {
      alignSelf: 'center'
    }
  },
  current: {
    pointerEvents: 'none',
    color: theme.palette.primary.main,
    background: theme.palette.background.light
  }
}))

const LinkItem = ({ link, current, loading }: { link: DashboardLinkType; current: boolean; loading: boolean }) => {
  const { classes, cx } = useStyles()

  const Icon = (
    <>
      <FontAwesomeIcon icon={link.icon} size="lg" className={cx(classes.icon, { [classes.selectedIcon]: current })} />
      {link.name}
    </>
  )

  return loading ? (
    <span className={cx(classes.link, { [classes.current]: current })}>{Icon}</span>
  ) : (
    <Link to={link.link} onClick={(e) => current && e.preventDefault()} className={cx(classes.link, { [classes.current]: current })}>
      {Icon}
    </Link>
  )
}

const Dashboard = (
  props: PropsWithChildren<{
    loading: boolean
    links: DashboardLinkType[]
  }>
) => {
  const { pathname } = useLocation()
  const { classes } = useStyles()

  return (
    <>
      <Narrow>{props.children}</Narrow>
      <Wide>
        <Box className={classes.wrapper}>
          <Grid container>
            <Grid item md={1}>
              <Box className={classes.panel}>
                {props.links.map((link) => {
                  const match = matchPath(pathname, {
                    path: link.link,
                    exact: true,
                    strict: false
                  })
                  return <LinkItem key={link.name} link={link} loading={props.loading} current={!!match} />
                })}
              </Box>
            </Grid>
            <Grid item xs={12} md={11} className={classes.content}>
              {props.children}
            </Grid>
          </Grid>
        </Box>
      </Wide>
    </>
  )
}

export default Dashboard
