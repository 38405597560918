import { getGraphToken } from "Auth";
import { UrlParams, mergeUrlWithParams } from "./httpUtils";

export function getGraphPhoto(userKey: string): Promise<any> {
  return getGraphToken()
    .then((token: any) => fetch(`https://graph.microsoft.com/v1.0/users/${userKey}/photo`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token.accessToken}`
        }
      }).then((resp: any) => resp.ok && fetch(`https://graph.microsoft.com/v1.0/users/${userKey}/photo/$value`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'image/*',
            'Authorization': `Bearer ${token.accessToken}`
          }
        }
      )))
    .then((resp: any) => resp.blob());
}

export function getGraph(url: string, params?: UrlParams): Promise<any> {
  return getGraphToken().then((token: any) => {
    return fetch(
      mergeUrlWithParams(`https://graph.microsoft.com/v1.0${url}`, params),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token.accessToken}`
        }
      });
  });
}
