import { useState } from 'react'

import { Box } from '@mui/material'

import Checkbox from '_shared/forms/Checkbox'

import HelpLinkButton from '_core/components/HelpLinkButton'
import Settings, { useStyles } from '_core/components/settings'

import useSaveSettings, { SaveAction } from '_core/hooks/useSaveSettings'

const SalesforceSettings = (props: any) => {
  const [settingsData, setSettingsData] = useState<any>(props.items)
  const {
    classes: { iconButton, itemWrapper }
  } = useStyles()

  const { isSaving, save: saveSettings } = useSaveSettings()

  const save = (action: SaveAction) => saveSettings('/adminsettings/salesforce', settingsData, action, 'Salesforce settings')

  const handleEnableToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettingsData({
      ...settingsData,
      isEnabled: e.target.checked
    })
  }

  const handleCredentialsToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettingsData({
      ...settingsData,
      useCustomCredentials: e.target.checked
    })
  }

  return (
    <Settings isSaving={isSaving} save={save} initialLoading={props.loading}>
      {settingsData && (
        <Box width="100%">
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={settingsData.isEnabled}
              name="salesforce_enabled"
              onChange={handleEnableToggle}
              label="Turn on Salesforce integration"
            />
            <HelpLinkButton
              hint="Learn more about DotAlign's Salesforce integration"
              href="https://help.dotalign.com/article/m3agr0chbj-save-to-salesforce"
              classes={{ root: iconButton }}
            />
          </Box>
          <Box display="flex" alignItems="center" className={itemWrapper}>
            <Checkbox
              checked={settingsData.useCustomCredentials}
              name="salesforce_credentials"
              onChange={handleCredentialsToggle}
              label="Use custom credentials"
            />
            <HelpLinkButton
              hint="Learn more about using a custom Salesforce connected app from DotAlign"
              href="https://help.dotalign.com/article/rsgsb2bbk2-using-a-custom-salesforce-connected-app-from-dot-align"
              classes={{ root: iconButton }}
            />
          </Box>
        </Box>
      )}
    </Settings>
  )
}

export default SalesforceSettings
