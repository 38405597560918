import { ComponentProps } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar from '_shared/Avatar'

import Empty from '_core/components//Empty'
import Heading from '_core/components/Heading'
import { useWide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import Widget, { SeeAllAction } from '_core/components/widgets'

import useStyles from './styles'

const ContactsWidget = (props: { loading: boolean; total?: number; link: string; items: ComponentProps<typeof Avatar>[]; entityName: string }) => {
  const { classes } = useStyles()
  const wide = useWide()
  const { items, total, link, entityName, loading } = props

  return (
    <Widget>
      <Heading underlined title="Contacts" count={total} icon={['far', 'user']} action={<SeeAllAction link={total ? link : ''} />} />
      {(!!total || loading) && <Repeater direction="horizontal" component={Avatar} skeleton={{ size: wide ? 5 : 4, loading }} items={items} />}
      {!total && !loading && (
        <Empty
          className={classes.empty}
          subTitle={`${entityName} has no contacts`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

export default ContactsWidget
