import { useContext, useEffect, useState } from 'react'

import useAppHistory from '_core/hooks/useAppHistory'

import { LayoutContext } from 'Layout/LayoutContextProvider'

type useScrollValue = (enableScrollListener?: boolean) => {
  scrollPosition?: number
  clearScroll: () => void
  saveScroll: (payloads?: { [key: string]: any }) => void
}

const usePresetScroll: useScrollValue = (enableScrollListener) => {
  const { historyIndex } = useContext(LayoutContext)
  const { appHistory, setAppHistory } = useAppHistory()
  const [check, setCheck] = useState<number>(0)
  const { state } = appHistory[historyIndex] || {}
  const { scrollPosition } = state || {}

  useEffect(() => {
    if (enableScrollListener && scrollPosition && check > -1) {
      if (window.scrollY === scrollPosition) {
        clearScroll()
        setCheck(-1)
      }
      window.scrollTo({ top: scrollPosition })
      setCheck((prev) => prev + 1)
    }
  }, [scrollPosition, enableScrollListener, check])

  const saveScroll = (payloads?: { [key: string]: any }) => {
    const state = { scrollPosition: window.scrollY, payloads }
    setAppHistory([...appHistory.slice(0, historyIndex), { ...appHistory[historyIndex], state }, ...appHistory.slice(historyIndex + 1)])
  }

  const clearScroll = () => {
    setAppHistory([...appHistory.slice(0, historyIndex), { ...appHistory[historyIndex], state: undefined }, ...appHistory.slice(historyIndex + 1)])
  }

  return {
    scrollPosition,
    clearScroll,
    saveScroll
  }
}

export default usePresetScroll
