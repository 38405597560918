import React, { SyntheticEvent, useCallback, useContext, useRef } from 'react'

import { Box } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import Combobox, { ChipsPickList } from '_shared/forms/Combobox'
import TextField from '_shared/forms/TextField'

import MarketDataFieldPicker, { companySizes, jobTitleLevelsOptions } from '_core/components/MarketDataFieldPicker'
import {
  CountriesController,
  FieldController,
  IndustriesController,
  LocationNamesController,
  LocationRegionsController
} from '_core/components/MarketDataSearchFilters'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { useLookUpMarketDataCompanies } from '_core/hooks/useLookup'
import useResizeObserver from '_core/hooks/useResizeObserver'

const useStyles = makeStyles<{ appliedFiltersHeight: number }>()((theme, { appliedFiltersHeight }) => ({
  block: {
    paddingBottom: theme.spacing(1.5)
  },
  inputScroll: {
    '&& .MuiAutocomplete-input': {
      scrollMarginTop: `calc(${appliedFiltersHeight}px + 167px)`,
      [theme.breakpoints.up('md')]: {
        scrollMarginTop: `calc(${appliedFiltersHeight}px + 187px)`
      }
    }
  },
  sticky: {
    position: 'sticky',
    top: `calc(${appliedFiltersHeight}px + 40px)`,
    zIndex: 10,
    background: theme.palette.background.light,
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      top: `calc(${appliedFiltersHeight}px + 59px)`
    }
  },
  fullWidth: {
    marginLeft: theme.spacing(-2.5),
    marginRight: theme.spacing(-2.5),
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`
  },
  content: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(6.5),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  }
}))

export type PeopleFiltersType = {
  firstName: string
  lastName: string
  emailAddress: string
  companySize: null | string
  companyNames: string[]
  jobTitleLevels: string[]
  jobTitles: string[]
  jobTitleRoles: string[]
  jobTitleSubroles: string[]
  countries: string[]
  industries: string[]
  locationName: string[]
  locationRegion: string[]
  educationSchoolName: string[]
  skills: string[]
}

type SuggestOption = {
  field: SuggestedFields
  group: string
  value: string
  count: number
}

type FieldsResp = {
  group: string
  fields: string[]
  data: {
    name: string
    count: number
  }[]
}

const suggestedFields = {
  companyNames: { group: 'Company name', fields: ['job_company_name', 'experience.company.name'] },
  jobTitles: { group: 'Job title', fields: ['job_title', 'experience.title.name'] },
  jobTitleRoles: { group: 'Job title role', fields: ['job_title_role', 'experience.title.role'] },
  jobTitleSubroles: {
    group: 'Job title sub roles',
    fields: ['job_title_sub_role', 'experience.title.sub_role']
  },
  countries: {
    group: 'Country',
    fields: ['location_country', 'countries', 'street_addresses.country', 'job_company_location_country', 'experience.company.location.country']
  },
  locationName: {
    group: 'Location name',
    fields: [
      'location_name',
      'location_names',
      'street_addresses.name',
      'job_company_location_name',
      'experience.location_names',
      'experience.company.location.name'
    ]
  },
  locationRegion: {
    group: 'Location region',
    fields: ['location_region', 'regions', 'street_addresses.region', 'job_company_location_region', 'experience.company.location.region']
  },
  industries: { group: 'Industry', fields: ['industry', 'job_company_industry', 'experience.company.industry'] },
  educationSchoolName: { group: 'School name', fields: ['education.school.name'] },
  skills: { group: 'Skills', fields: ['skills'] }
}
// other available fields:[ "education.majors", "education.minors"]
type SuggestedFields = keyof typeof suggestedFields

const transformGroup = ({ fields, data }: FieldsResp): SuggestOption[] => {
  const suggestedKeys = Object.keys(suggestedFields) as SuggestedFields[]
  const matchedField = suggestedKeys.find((field) => suggestedFields[field].fields.sort().join(',') === fields.sort().join(','))

  if (matchedField) {
    const { group } = suggestedFields[matchedField]
    return data.map((item) => ({
      group,
      field: matchedField,
      value: item.name,
      count: item.count
    }))
  }
  return []
}

const MarketDataPeopleSearchFilters = ({
  disabled,
  setValue,
  register,
  control,
  getValues,
  appliedFiltersHeight,
  applyFilters
}: {
  disabled: boolean
  appliedFiltersHeight: number
  applyFilters: () => void
} & UseFormReturn<PeopleFiltersType>) => {
  const inputsRefs = useRef<{ [key in keyof PeopleFiltersType]: HTMLInputElement | null }>({
    firstName: null,
    lastName: null,
    emailAddress: null,
    companySize: null,
    companyNames: null,
    jobTitleLevels: null,
    jobTitles: null,
    jobTitleRoles: null,
    jobTitleSubroles: null,
    countries: null,
    industries: null,
    locationName: null,
    locationRegion: null,
    educationSchoolName: null,
    skills: null
  })
  useResizeObserver(inputsRefs.current)
  const { classes, cx } = useStyles({ appliedFiltersHeight })

  const scrollIntoView = useCallback((field: SuggestedFields) => {
    const el = inputsRefs.current[field]
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      applyFilters()
    }
  }

  const handleChange = (v: string[], onChange: (v: string[]) => void) => {
    onChange(v)
    applyFilters()
  }

  const handleDelete = (removableValue: string, value: string[], onChange: (v: string[]) => void) => {
    onChange(value.filter((el: string) => el !== removableValue))
    applyFilters()
  }

  return (
    <>
      <Box className={cx(classes.block, classes.sticky, classes.fullWidth, 'advanced-search-multi-autocomplete')}>
        <SuggestAutocompleteController
          setValue={setValue}
          getValues={getValues}
          disabled={disabled}
          scrollIntoView={scrollIntoView}
          applyFilters={applyFilters}
        />
      </Box>
      <Box className={classes.content}>
        <Box className={classes.block}>
          <TextField
            label="First name"
            {...register('firstName')}
            inputRef={(el: HTMLInputElement) => (inputsRefs.current['firstName'] = el)}
            placeholder="E.g. Vince"
            icon={['far', 'user']}
            disabled={disabled}
            onKeyDown={handleKeyDown}
            fullWidth
          />
        </Box>
        <Box className={classes.block}>
          <TextField
            label="Last name"
            {...register('lastName')}
            inputRef={(el: HTMLInputElement) => (inputsRefs.current['lastName'] = el)}
            placeholder="E.g. Scafaria"
            icon={['far', 'user']}
            disabled={disabled}
            onKeyDown={handleKeyDown}
            fullWidth
          />
        </Box>
        <Box className={classes.block}>
          <TextField
            label="Email address"
            {...register('emailAddress')}
            inputRef={(el: HTMLInputElement) => (inputsRefs.current['emailAddress'] = el)}
            placeholder="E.g. vince@dotalign.com"
            icon={['far', 'envelope']}
            disabled={disabled}
            onKeyDown={handleKeyDown}
            fullWidth
          />
        </Box>
        <Box className={classes.block}>
          <CompanyNamesController
            control={control}
            disabled={disabled}
            inputRef={(el) => (inputsRefs.current['companyNames'] = el)}
            className={classes.inputScroll}
            handleChange={handleChange}
            handleDelete={handleDelete}
          />
        </Box>
        <Box className={classes.block}>
          <Controller
            name="jobTitles"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MarketDataFieldPicker
                field={name}
                label="Job titles"
                placeholder="E.g. Chief executive officer"
                icon={['far', 'briefcase']}
                value={value}
                inputRef={(el) => (inputsRefs.current[name] = el)}
                className={classes.inputScroll}
                handleChange={(v) => handleChange(v, onChange)}
                handleDelete={(v) => handleDelete(v, value, onChange)}
                disabled={disabled}
              />
            )}
          />
        </Box>
        <Box className={classes.block}>
          <Controller
            name="jobTitleRoles"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MarketDataFieldPicker
                field={name}
                label="Job areas"
                icon={['far', 'briefcase']}
                placeholder="Pick an option"
                value={value}
                inputRef={(el) => (inputsRefs.current[name] = el)}
                className={classes.inputScroll}
                handleChange={(v) => handleChange(v, onChange)}
                handleDelete={(v) => handleDelete(v, value, onChange)}
                disabled={disabled}
                loadOnFocus
              />
            )}
          />
        </Box>
        <Box className={classes.block}>
          <Controller
            name="jobTitleSubroles"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MarketDataFieldPicker
                field={name}
                label="Job sub-areas"
                icon={['far', 'briefcase']}
                placeholder="Pick an option"
                value={value}
                inputRef={(el) => (inputsRefs.current[name] = el)}
                className={classes.inputScroll}
                handleChange={(v) => handleChange(v, onChange)}
                handleDelete={(v) => handleDelete(v, value, onChange)}
                disabled={disabled}
                loadOnFocus
              />
            )}
          />
        </Box>
        <Box className={classes.block}>
          <Controller
            name="jobTitleLevels"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <JobTitleLevelsPicker
                disabled={disabled}
                value={value}
                inputRef={(el) => (inputsRefs.current[name] = el)}
                handleChange={(v) => handleChange(v, onChange)}
                handleDelete={(v) => handleDelete(v, value, onChange)}
              />
            )}
          />
        </Box>
        <Box className={classes.block}>
          <IndustriesController
            control={control}
            disabled={disabled}
            inputRef={(el) => (inputsRefs.current['industries'] = el)}
            className={classes.inputScroll}
            handleChange={handleChange}
            handleDelete={handleDelete}
          />
        </Box>
        <Box className={classes.block}>
          <CountriesController
            control={control}
            disabled={disabled}
            inputRef={(el) => (inputsRefs.current['countries'] = el)}
            className={classes.inputScroll}
            handleChange={handleChange}
            handleDelete={handleDelete}
          />
        </Box>
        <Box className={classes.block}>
          <LocationNamesController
            control={control}
            disabled={disabled}
            inputRef={(el) => (inputsRefs.current['locationName'] = el)}
            className={classes.inputScroll}
            handleChange={handleChange}
            handleDelete={handleDelete}
          />
        </Box>
        <Box className={classes.block}>
          <LocationRegionsController
            control={control}
            disabled={disabled}
            inputRef={(el) => (inputsRefs.current['locationRegion'] = el)}
            className={classes.inputScroll}
            handleChange={handleChange}
            handleDelete={handleDelete}
          />
        </Box>
        <Box className={classes.block}>
          <Controller
            name="skills"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MarketDataFieldPicker
                field={name}
                label="Skills"
                placeholder="E.g. data analysis"
                icon={['far', 'briefcase']}
                value={value}
                inputRef={(el) => (inputsRefs.current[name] = el)}
                className={classes.inputScroll}
                handleChange={(v) => handleChange(v, onChange)}
                handleDelete={(v) => handleDelete(v, value, onChange)}
                disabled={disabled}
              />
            )}
          />
        </Box>
        <Box className={classes.block}>
          <Controller
            name="educationSchoolName"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MarketDataFieldPicker
                field={name}
                label="School names"
                placeholder="E.g. Princeton"
                icon={['far', 'briefcase']}
                value={value}
                inputRef={(el) => (inputsRefs.current[name] = el)}
                className={classes.inputScroll}
                handleChange={(v) => handleChange(v, onChange)}
                handleDelete={(v) => handleDelete(v, value, onChange)}
                disabled={disabled}
              />
            )}
          />
        </Box>
        <Box className={classes.block}>
          <Controller
            name="companySize"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Combobox<string, false, boolean>
                label="Company size"
                icon={['far', 'arrow-up-small-big']}
                placeholder="Pick an option"
                options={companySizes}
                disabled={disabled}
                value={value || null}
                inputRef={(el) => (inputsRefs.current[name] = el)}
                onChange={(e, value) => {
                  onChange(value)
                  applyFilters()
                }}
                disableClearable={!value}
                blurOnSelect
                filterSelectedOptions
              />
            )}
          />
        </Box>
      </Box>
    </>
  )
}

const SuggestAutocompleteController = ({
  disabled,
  setValue,
  getValues,
  scrollIntoView,
  applyFilters
}: {
  applyFilters: () => void
  disabled: boolean
  scrollIntoView: (field: SuggestedFields) => void
} & Pick<UseFormReturn<PeopleFiltersType>, 'setValue' | 'getValues'>) => {
  const { fetchWithAbort, forceAbort } = useAbortableFetch<{
    singleFieldResponses: FieldsResp[]
  }>()

  const { inputValue, open, options, handleClose, handleOpen, handleFocus, handleInputChange, optionsLoading } = useAsyncCombobox<
    SuggestOption,
    true
  >({
    loadOptions: useCallback(
      async (searchTerm: string) => {
        const result = await fetchWithAbort({ url: `/cloudhub/people/multiautocomplete?searchTerm=${searchTerm}` })
        if (result) {
          return result.singleFieldResponses.filter((item) => item.data.length).flatMap(transformGroup)
        }
      },
      [fetchWithAbort]
    ),
    forceAbort
  })

  const handleSuggest = (event: SyntheticEvent<Element, Event>, options: SuggestOption[] | null) => {
    const option = options ? options[options.length - 1] : null
    if (option) {
      const { field, value } = option
      const multipleFilters = {} as { [key in SuggestedFields]: string[] }

      if (field && value) {
        if (!multipleFilters[field]) {
          multipleFilters[field] = (getValues(field) as string[]) || []
        }
        multipleFilters[field].push(value)
      }

      Object.entries(multipleFilters).forEach(([key, value]) => {
        setValue(key as SuggestedFields, value)
      })
      applyFilters()
      scrollIntoView(option.field)
    }
  }

  return (
    <Combobox<SuggestOption, true>
      multiple
      label="Search for options"
      placeholder="Search for options"
      icon={['far', 'search']}
      disabled={disabled}
      options={options}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      groupBy={(option) => option.group}
      loading={optionsLoading}
      open={open}
      onChange={handleSuggest}
      onClose={handleClose}
      onOpen={handleOpen}
      onFocus={handleFocus}
      getOptionLabel={(option) => option.value}
      renderOption={renderSuggestOption}
    />
  )
}

const CompanyNamesController = ({
  disabled,
  control,
  inputRef,
  className,
  handleChange,
  handleDelete
}: FieldController & { inputRef: React.Ref<HTMLInputElement>; className?: string }) => {
  const { teamContextValue } = useContext(TeamContext)
  const { lookUpMarketDataCompanies, forceAbort } = useLookUpMarketDataCompanies()
  const { inputValue, open, options, handleClose, handleOpen, handleFocus, handleInputChange, filterOptions, optionsLoading } = useAsyncCombobox<
    string,
    true
  >({
    loadOptions: useCallback(
      async (searchTerm: string) => {
        const result = await lookUpMarketDataCompanies(`${teamContextValue.teamNumber}`, searchTerm, 10)
        if (result) {
          return result.map(({ name }) => name)
        }
      },
      [lookUpMarketDataCompanies]
    ),
    forceAbort
  })

  return (
    <Controller
      name="companyNames"
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          <Combobox<string, true>
            multiple
            inputRef={inputRef}
            label="Company names"
            icon={['far', 'briefcase']}
            placeholder="E.g. Dotalign"
            disabled={disabled}
            value={value || []}
            onChange={(e, v: string[]) => handleChange(v, onChange)}
            inputValue={inputValue}
            open={open}
            loading={optionsLoading}
            options={options}
            onClose={handleClose}
            onOpen={handleOpen}
            onFocus={handleFocus}
            onInputChange={handleInputChange}
            filterOptions={filterOptions}
            className={className}
          />
          <ChipsPickList
            items={value?.map((name: string) => ({ label: name, handleDelete: () => handleDelete(name, value, onChange) })) || []}
            disabled={disabled}
          />
        </>
      )}
    />
  )
}

export const JobTitleLevelsPicker = ({
  disabled,
  value,
  handleChange,
  handleDelete,
  applyFilters,
  inputRef,
  className
}: {
  disabled: boolean
  handleDelete: (value: string, fieldName: 'jobTitleLevels') => void
  handleChange: (v: string[], fieldName: 'jobTitleLevels') => void
  applyFilters?: () => void
  value?: string[]
} & { inputRef?: React.Ref<HTMLInputElement>; className?: string }) => {
  const handleValueChange = (e: SyntheticEvent<Element, Event>, v: string[]) => {
    handleChange(v, 'jobTitleLevels')
    applyFilters && applyFilters()
  }

  return (
    <>
      <Combobox<string, true, true>
        inputRef={inputRef}
        label="Seniority levels"
        placeholder="Pick an option"
        icon={['far', 'briefcase']}
        options={jobTitleLevelsOptions}
        disabled={disabled}
        value={value || []}
        onChange={handleValueChange}
        blurOnSelect
        multiple
        disableClearable
        filterSelectedOptions
        className={className}
      />
      <ChipsPickList
        items={
          value?.map((level: string) => ({
            label: level,
            handleDelete: () => handleDelete(level, 'jobTitleLevels')
          })) || []
        }
        disabled={disabled}
      />
    </>
  )
}

const renderSuggestOption = (props: { [key: string]: any }, value: SuggestOption) => {
  return (
    <li {...props} key={value.value}>
      {value.value} ({value.count})
    </li>
  )
}

export default MarketDataPeopleSearchFilters
