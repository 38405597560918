import { useEffect, useContext, useState, useMemo, ComponentProps } from 'react'

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useParams, useLocation, matchPath } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Typography from '_shared/Typography'

import { ActivityStatsPeriodMsg } from '_core/components/charts/ActivityStats'
import CompaniesList, { CompaniesListProps, DownloadAll, Heading } from '_core/components/CompaniesList'
import Filters from '_core/components/filters/Companies'
import {
  nameColumn,
  scoreColumn,
  keyContactColumn,
  bestIntroducerColumn,
  lastInboundColumn,
  lastMeetingColumn,
  lastOutboundColumn,
  nextFutureMeetingColumn,
  nameWithByLinesColumn,
  inboundCountColumn,
  outboundCountColumn,
  meetingsCountColumn,
  SidepanelLinkCell
} from '_core/components/grid/columns'
import GridPageFrame from '_core/components/GridPageFrame'
import { Narrow, useWide, Wide } from '_core/components/layout'
import { sortMap } from '_core/components/sort'
import Topbar from '_core/components/Topbar'

import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useActivitiesStats, { ActivityStatsMonth } from '_core/hooks/useActivitiesStats'
import useActivityStatsPeriod from '_core/hooks/useActivityStatsPeriod'
import useAdmin from '_core/hooks/useAdmin'
import useCompaniesUserSettings from '_core/hooks/useCompaniesUserSettings'
import useCompanyActivitiesPayloads from '_core/hooks/useCompanyActivitiesPayloads'
import { DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useEnableDownloading from '_core/hooks/useEnableDownloading'
import useInteractionsPeriodEndpointParams from '_core/hooks/useInteractionsPeriodEndpointParams'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSortStatsEndpointParams from '_core/hooks/useSortStatsEndpointParams'
import useTagsEndpointParams from '_core/hooks/useTagsEndpointParams'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

import DynamicEntity from '_core/DynamicEntity'
import UserSettings from '_core/UserSettings'

import { getBinary, mergeUrlWithParams } from 'utils/httpUtils'
import { getUTC } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

export const relationshipColumn: GridColDef = {
  field: 'relationship',
  headerName: 'Relationship',
  width: 250,
  minWidth: 250,
  flex: 1,
  hideable: false,
  pinnable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { row, id } = params
    const { keyContact = {}, bestIntroducer = {}, onClick } = row

    return (
      <Typography component="span">
        <SidepanelLinkCell
          component="span"
          value={bestIntroducer.name}
          id={id}
          onClick={onClick}
          link={bestIntroducer.link}
          sidepanel={bestIntroducer.sidepanel}
        />{' '}
        knows{' '}
        <SidepanelLinkCell
          component="span"
          value={keyContact.name}
          id={id}
          onClick={onClick}
          link={keyContact.link}
          sidepanel={keyContact.sidepanel}
        />
      </Typography>
    )
  }
}

const Companies = ({
  download,
  ...props
}: Modify<CompaniesListProps, { items: UserCompaniesListItem[] }> &
  Pick<ComponentProps<typeof DownloadAll>, 'download'> & { months: ActivityStatsMonth[] }) => {
  const { setSubHeader } = useContext(LayoutContext)
  const { id } = useParams<{ id: string }>()
  const { pathname } = useLocation()

  const upSmall = useWide('sm')
  const wideStrict = useWide('lg')

  const { isExact: matchActivityStatsPath } = matchPath(pathname, `${Paths._people}/:id/activityStats`) || {}

  const md5s = useMemo(() => props.items.map(({ CompanyMd5 }) => CompanyMd5), [props.loading, props.items.length])

  const statsPayloads = useCompanyActivitiesPayloads(md5s, id)
  const { stats } = useActivitiesStats(md5s ? statsPayloads : null, md5s, props.months)

  const items = useMemo(
    () =>
      props.items?.map((company, idx) => {
        const lastInbound = company.Stats?.LastInboundMsg || ''
        const lastOutbound = company.Stats?.LastOutboundMsg || ''
        const lastMeeting = company.Stats?.LastMeeting || ''
        const nextFutureMeeting = company.Stats?.NextFutureMeeting || ''

        const { inbound, outbound, meetings } = stats?.[idx] || {}

        const isActivityStatsVisible = !!props.columns.find(({ field }) => field === 'inboundCount')
        const isStatsDatesVisible = !!props.columns.find(({ field }) => field === 'lastInboundMsg')

        return {
          id: company.CompanyMd5,
          name: company.CompanyNameText || company.BestUrlText,
          score: company.Score,
          link: `${Paths._relationships}/${company.UserKeyMd5}/companies/${company.CompanyMd5}`,
          logoUrl: company.BestUrlText,
          byline: company.BestUrlText,
          keyContact: {
            name: company.AnchorEmployeeNameText || '',
            link: company.AnchorEmployeePersonMd5 ? `${Paths._people}/${company.AnchorEmployeePersonMd5}` : '',
            sidepanel: true as SidepanelType
          },
          bestIntroducer: {
            name: company.BestKnowerNameText || '',
            link: company.BestKnowerUserKeyMd5 ? `${Paths._relationships}/${company.BestKnowerUserKeyMd5}/companies/${company.CompanyMd5}` : '',
            sidepanel: true as SidepanelType
          },
          sidepanel: true as SidepanelType,
          variant: 'expandable',
          tags: company.Tags || [],
          ...(isActivityStatsVisible
            ? {
                inbound: stats ? inbound?.count || null : -1,
                outbound: stats ? outbound?.count || null : -1,
                meetings: stats ? meetings?.count || null : -1
              }
            : {}),
          ...(isStatsDatesVisible ? { lastInbound, lastOutbound, lastMeeting, nextFutureMeeting } : {})
        }
      }),
    [props.loading, props.items.length, stats]
  )

  useEffect(() => {
    setSubHeader(`Companies ${props.total ? `· ${props.total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, props.total])

  return (
    <CompaniesList
      {...props}
      rowHeight={upSmall && !wideStrict ? 80 : 52}
      items={items}
      downloadAllControl={<DownloadAll total={props.total} loading={props.loading} download={download} />}
      enableMiddleView={matchActivityStatsPath}
    />
  )
}

type CmpniesListProps = {
  queryParams: Modify<ContributorsPeoplePageParams, { dealsChecked?: IncludeDealsType[] }>
} & Pick<ComponentProps<typeof DynamicEntity>, 'onLoading' | 'autoHideOnScroll' | 'infinite' | 'updatePageSize'> &
  Pick<ComponentProps<typeof Companies>, 'updateSort' | 'columns' | 'rowsPerPageOptions' | 'months' | 'enableDownloading'>

export const CmpniesList = (props: CmpniesListProps) => {
  const { id } = useParams<{ id: string }>()
  const { search } = useLocation()
  const { teamContextValue } = useContext(TeamContext)

  const { sort, viewMode, rowsPerPage = '10', keyword, and, dealsChecked = [], includeTags, excludeTags } = props.queryParams

  const interactionsPeriodParams = useInteractionsPeriodEndpointParams(props.queryParams)
  const sortStatsParams = useSortStatsEndpointParams(sort)
  const tagsParams = useTagsEndpointParams('companies', includeTags, excludeTags)

  const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)

  const params: { name: string; value?: any }[] = [
    { name: 'teamNumber', value: teamContextValue?.teamNumber },
    { name: 'WhereNoneNextFutureMeeting', value: !!and },
    { name: 'IncludeStats', value: true },
    { name: 'WhereDidDealWithUs', value: dealsChecked.includes('hadDeal') },
    { name: 'WithCompanyTags', value: true },
    ...sortStatsParams,
    ...interactionsPeriodParams,
    ...tagsParams
  ]

  const payload = params.filter(({ value }) => !!value).reduce((acc, { name, value }) => ({ ...acc, [name]: `${value}` }), {})

  const url = search ? mergeUrlWithParams(`/users/${id}/companies`, payload) : null

  const download = {
    fileName: 'user_companies',
    requestBinary: (data: DownloadBulkParams, excludeFormerEmployees: boolean) =>
      getBinary('/companies/xl', {
        ...payload,
        ...data,
        relationOfUserKey: id,
        numAliases: '5',
        numUrls: '5',
        numEmployees: '5',
        excludeFormerRoles: excludeFormerEmployees.toString()
      })
  }

  return (
    <DynamicEntity<{
      extraProps: {
        addprops: Pick<
          ComponentProps<typeof Companies>,
          'sort' | 'sortByField' | 'updateSort' | 'viewMode' | 'download' | 'rowsPerPageOptions' | 'months' | 'columns' | 'enableDownloading'
        >
      }
    }>
      url={url}
      component={Companies}
      updatePageSize={props.updatePageSize}
      pageSize={+rowsPerPage}
      list
      infinite={props.infinite}
      search={keyword}
      autoHideOnScroll={props.autoHideOnScroll}
      keepMounted
      onLoading={props.onLoading}
      addprops={{
        sort,
        sortByField: sortByField || 'score',
        updateSort: props.updateSort,
        rowsPerPageOptions: props.rowsPerPageOptions,
        viewMode: viewMode || 'collapsed',
        columns: props.columns,
        download,
        months: props.months,
        enableDownloading: props.enableDownloading
      }}
      empty="No results found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      id="person_companies"
    />
  )
}

const UserCompanies = () => {
  const wideStrict = useWide('lg')
  const { id } = useParams<{ id: string }>()
  const { queryParams, updateQuery } = useSearchQuery<
    ContributorsCompaniesPageParams & Pick<ActivityStatsPageParams, 'from' | 'to'>,
    { modifyProps: [{ dealsChecked?: IncludeDealsType[] }] }
  >(['dealsChecked'])

  const { sort, viewMode, excludeEmpty, from, to } = queryParams

  const [total, setTotal] = useState<number>()
  const [contentLoading, setContentLoading] = useState<boolean>(false)

  const { userActivitiesAccess } = useActivitiesAccess([id]) || {}
  const { isStatsWidgetVisible } = userActivitiesAccess || {}

  const chartData = useActivityStatsPeriod({
    fromUTC: from ? getUTC(decodeURIComponent(from)) : null,
    toUTC: to ? getUTC(decodeURIComponent(to)) : null
  })

  const { dataVisibility } = useUserDataVisibility()
  const { allowFilteringByInteractions, preventNonAdminDownload } = dataVisibility || {}

  const admin = useAdmin()
  const enableDownloading = useEnableDownloading(admin, preventNonAdminDownload)

  const {
    setInitial,
    reset,
    interactionsFiltersShown,
    interactionsColumnsShown,
    handleChange,
    toggleDealSwitch,
    toggleInteractionSwitch,
    loading: filtersLoading,
    opened,
    toggleOpen: toggleFilterOpen
  } = useCompaniesUserSettings({
    isContributor: true,
    allowFilteringByInteractions,
    showStatsColumns: isStatsWidgetVisible
  })

  const disabled = filtersLoading || interactionsFiltersShown === null

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    handleChange({ rowsPerPage })
  }

  const updateSort = (sort: ScoreType | StatSortType) => {
    handleChange({ sort })
  }

  const toggleExclude = () => {
    updateQuery({ excludeEmpty: excludeEmpty === 'true' ? 'false' : 'true' })
  }

  const updateViewMode = (viewMode: ViewModeType) => {
    handleChange({ viewMode })
  }

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    setContentLoading(loading)
    setTotal(result?.total_item_count)
  }

  const filtersProps = {
    isContributor: true,
    toggleOpen: toggleFilterOpen,
    interactionsFiltersShown: !!interactionsFiltersShown,
    disabled,
    opened,
    total,
    reset,
    contentLoading,
    queryData: queryParams,
    handleChange,
    toggleDealSwitch,
    toggleInteractionSwitch
  }

  const columns = useMemo(
    () =>
      [
        { column: wideStrict ? { ...nameColumn, minWidth: 200, width: 200 } : { ...nameWithByLinesColumn, minWidth: 281, width: 281 } },
        { column: keyContactColumn, condition: wideStrict },
        { column: bestIntroducerColumn, condition: wideStrict },
        { column: relationshipColumn, condition: !wideStrict },
        { column: inboundCountColumn, condition: !!isStatsWidgetVisible },
        { column: outboundCountColumn, condition: !!isStatsWidgetVisible },
        { column: meetingsCountColumn, condition: !!isStatsWidgetVisible },
        { column: lastInboundColumn, condition: !!interactionsColumnsShown },
        { column: lastOutboundColumn, condition: !!interactionsColumnsShown },
        { column: lastMeetingColumn, condition: !!interactionsColumnsShown },
        { column: nextFutureMeetingColumn, condition: !!interactionsColumnsShown },
        { column: scoreColumn }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || !!condition)
        .map(({ column }) => column),
    [interactionsColumnsShown, isStatsWidgetVisible, wideStrict]
  )

  const sortItems = columns.filter(({ sortable }) => sortable).map(({ headerName, field }) => ({ label: headerName || '', field }))

  const searchPlaceholder = 'Search for companies'
  return (
    <UserSettings endpoint="/usersettings/companiessearchfilter" setInitial={setInitial}>
      <Narrow>
        <Topbar nativeBack autoHideOnScroll />
      </Narrow>
      <GridPageFrame
        loading={typeof total !== 'number'}
        gridTitle="Companies"
        gridHeadingIcon={['far', 'building']}
        searchPlaceholder={searchPlaceholder}
        disabledSearch={disabled}
        filters={<Filters {...filtersProps} />}
        heading={
          <Heading
            filtersProps={{
              opened: filtersProps.opened,
              toggleOpen: filtersProps.toggleOpen,
              isContributor: filtersProps.isContributor,
              interactionsFiltersShown: filtersProps.interactionsFiltersShown,
              disabled: filtersProps.disabled
            }}
            filters={<Filters {...filtersProps} />}
            sortProps={{ items: sortItems, value: sort, update: updateSort, toggleExclude, excludeEmpty: excludeEmpty === 'true' }}
            viewProps={{ viewMode, updateViewMode }}
            searchPlaceholder={searchPlaceholder}
          >
            {viewMode === 'expanded' && isStatsWidgetVisible && <ActivityStatsPeriodMsg title={chartData.gridMessage} />}
          </Heading>
        }
        component={
          <>
            {isStatsWidgetVisible && (
              <Wide>
                <ActivityStatsPeriodMsg title={chartData.gridMessage} />
              </Wide>
            )}
            <CmpniesList
              columns={columns}
              onLoading={onLoading}
              updateSort={updateSort}
              updatePageSize={onPageSizeChange}
              months={chartData.months}
              queryParams={queryParams}
              enableDownloading={enableDownloading}
              autoHideOnScroll
            />
          </>
        }
      />
    </UserSettings>
  )
}

export default UserCompanies
