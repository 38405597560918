import { ReactElement, useState, useRef, useEffect, ChangeEvent } from 'react'

import { Box, RadioGroup, SelectChangeEvent } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { makeStyles } from 'tss-react/mui'

import DatePicker from '_shared/DatePicker'
import Select from '_shared/forms/Select'
import Typography from '_shared/Typography'

import Filters from '_core/components/filters'
import { daysOptions } from '_core/components/filters/data'

import { getLocal, dateFormatURLQuery } from 'utils/Utils'

import useControlsStyles from './styles'

const useStyles = makeStyles()(() => ({
  select: {
    flex: 1
  }
}))

type RangeProps = {
  disabled: boolean
  from: string | undefined
  to: string | undefined
  focusTriggered: boolean
  handleDateChange?: ({ picker, value }: { picker: 'from' | 'to'; value: MomentType | null }) => void
  handleDateError?: ({ picker, value, reason }: { picker: 'from' | 'to'; value: MomentType | null; reason: string | null }) => void
}

export const Range = (props: RangeProps) => {
  const { classes } = useControlsStyles()
  const [openedPicker, setOpenedPicker] = useState<'from' | 'to' | null>(null)
  const { disabled, from, to, focusTriggered } = props

  const datePickerRef = useRef<any>()

  useEffect(() => {
    if (focusTriggered && !disabled) {
      datePickerRef.current.focus()
    }
  }, [focusTriggered, disabled])

  const closePicker = () => {
    setOpenedPicker(null)
  }

  const handleDateChange = (name: 'from' | 'to') => (date: unknown) => {
    if (name && props.handleDateChange) {
      props.handleDateChange({ picker: name, value: date as MomentType | null })
    }
  }

  const handleDateError = (name: 'from' | 'to') => (error: string | null, date: unknown) => {
    if (name && props.handleDateError) {
      props.handleDateError({ picker: name, value: date as MomentType | null, reason: error })
    }
  }

  const handlePickerOpen = (name: 'from' | 'to') => () => {
    setOpenedPicker(name)
  }

  return (
    <Box className={classes.dates}>
      {[
        { name: 'from', placeholder: 'From', value: from, maxDate: to, initialFocus: true },
        { name: 'to', placeholder: 'To', value: to, minDate: from }
      ].map(({ name, value, maxDate, minDate, placeholder, initialFocus }) => (
        <DatePicker
          ref={initialFocus ? datePickerRef : null}
          key={name}
          open={openedPicker === name}
          value={value ? getLocal(value, dateFormatURLQuery) : null}
          onClose={closePicker}
          views={['year', 'month', 'day']}
          onOpen={handlePickerOpen(name as 'from' | 'to')}
          onChange={handleDateChange(name as 'from' | 'to')}
          onError={handleDateError(name as 'from' | 'to')}
          placeholder={placeholder}
          disabled={disabled}
          {...(maxDate ? { maxDate: getLocal(maxDate, dateFormatURLQuery) } : {})}
          {...(minDate ? { minDate: getLocal(minDate, dateFormatURLQuery) } : {})}
        />
      ))}
    </Box>
  )
}

const Period = (props: {
  label?: string
  period?: PeriodOptionsType | 'Anytime' | 'Custom'
  disabled: boolean
  handlePeriodChange: (e: ChangeEvent<{ name: string; value: unknown }>) => void
  handleDaysChange: (e: SelectChangeEvent<unknown>) => void
  handleDateChange?: RangeProps['handleDateChange']
  handleDateError?: RangeProps['handleDateError']
  options: {
    value: string
    label: string | ReactElement
    disabled: boolean
    selectValue?: DaysOptions
    includeSelector?: boolean
    includeRange?: boolean
    from?: RangeProps['from']
    to?: RangeProps['to']
    focusTriggered?: boolean
  }[]
}) => {
  const { classes } = useControlsStyles()
  const { classes: innerClasses, cx } = useStyles()
  const { label, options, disabled, period, handleDaysChange, handlePeriodChange } = props

  return (
    <>
      {label && (
        <Typography variant="h4" semiBold classes={{ root: classes.header }}>
          {label}
        </Typography>
      )}
      <RadioGroup name="period" value={!disabled ? period : ''} onChange={handlePeriodChange}>
        {options.map((item) => (
          <Filters.Radio
            key={item.value}
            disabled={disabled}
            value={item.value}
            label={
              <Box className={classes.period}>
                <Typography classes={{ root: classes.periodLabel }}>{item.label}</Typography>
                {item.includeSelector && (
                  <Select
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={item.selectValue}
                    name={`${item.value}`}
                    options={daysOptions}
                    onChange={handleDaysChange}
                    className={cx(classes.select, innerClasses.select)}
                    disabled={disabled || item.disabled}
                  />
                )}
                {item.includeRange && (
                  <Range
                    from={item.from}
                    to={item.to}
                    focusTriggered={item.focusTriggered || false}
                    handleDateChange={props.handleDateChange}
                    handleDateError={props.handleDateError}
                    disabled={disabled || item.disabled}
                  />
                )}
              </Box>
            }
          />
        ))}
      </RadioGroup>
    </>
  )
}

export default Period
