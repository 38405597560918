import React, { ReactNode } from 'react'

import { Box, CircularProgress } from '@mui/material'
import { useSnackbar } from 'notistack'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Card, { CardContent } from '_shared/Card'

import { useWide } from '_core/components/layout'

import { SaveAction } from '_core/hooks/useSaveSettings'

export const useStyles = makeStyles()((theme) => ({
  subsection: {
    color: theme.palette.text.secondary,
    lineHeight: '23px',
    marginLeft: theme.spacing(4),
    fontSize: 14,
    '& > div': {
      display: 'inline-flex'
    }
  },
  field: {
    '&&': {
      margin: `0 ${theme.spacing(0.5)}`
    }
  },
  icon: {
    fontSize: '17px',
    paddingLeft: theme.spacing(1.5)
  },
  iconButton: {
    fontSize: '17px',
    marginRight: '-8px',
    '&[type="button"]': {
      cursor: 'auto'
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 0
    }
  },
  itemWrapper: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  filtersContent: {
    paddingTop: theme.spacing(1.5),
    '& input': {
      textAlign: 'center'
    }
  },
  subItemWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  subItem: {
    display: 'grid',
    marginTop: `-${theme.spacing(1)}`,
    gridTemplateColumns: '1fr auto auto',
    alignItems: 'center',
    '& .MuiOutlinedInput-root': {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5)
    },
    [theme.breakpoints.up('sm')]: {
      '& .MuiOutlinedInput-root': {
        marginTop: 0,
        marginBottom: 0
      }
    },
    '& > p': {
      marginTop: theme.spacing(0.75),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1.25),
        marginBottom: theme.spacing(1.25),
        marginRight: theme.spacing(1.5)
      }
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr auto auto auto'
    }
  },
  error: {
    color: theme.palette.error.main,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(0.5)
    }
  },
  popover: {
    width: '80%'
  },
  tiny: {
    width: 30,
    [theme.breakpoints.down('sm')]: {
      padding: '2px 4px'
    }
  },
  subtitle: {
    paddingBottom: 0
  }
}))

type SettingsProps = {
  initialLoading?: boolean
  isSaving: boolean
  children: ReactNode
  save: (value: SaveAction) => void
  saveDisabled?: boolean
}

const Settings = (props: SettingsProps) => {
  const isWide = useWide()

  const { enqueueSnackbar } = useSnackbar()

  const handleSave = () => {
    props.save((subject, variant = 'default') => {
      enqueueSnackbar(`${subject} have ${variant === 'error' ? 'not' : ''} been updated.`, {
        variant
      })
    })
  }

  return (
    <>
      {props.initialLoading && (
        <Box paddingX={{ xs: 2, sidepanel: 0 }}>
          <CircularProgress size="24px" color="secondary" />
        </Box>
      )}
      {!props.initialLoading && (
        <>
          <Card variant="outlined">
            <CardContent>{props.children}</CardContent>
          </Card>
          <Box display="flex" justifyContent="flex-end" alignItems="center" mt={isWide ? 4 : 2}>
            {props.isSaving && <CircularProgress size="24px" style={{ marginRight: 16 }} />}
            <Button disabled={props.isSaving || props.saveDisabled} onClick={handleSave} variant="contained">
              Save
            </Button>
          </Box>
        </>
      )}
    </>
  )
}

export default Settings
