import { useContext } from 'react'

import { OutlookContext } from '_core/context/Outlook'

const useOutlook = () => {
  const { isOutlook } = useContext(OutlookContext)
  return isOutlook
}

export default useOutlook
