import React, { isValidElement, ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'

import { AvatarWrapper } from '_shared/Avatar'
import { Button } from '_shared/buttons'

import { StatusChip, ChipLabel } from '_core/components/Chip'
import { ActionCell, DateCell, NameCell, TextCell } from '_core/components/grid/columns'
import { getStatusIcon } from '_core/components/introductions/ContactItem'
import IntroductionStatusChip, { StatusType } from '_core/components/introductions/StatusChip'
import SidepanelLink from '_core/components/SidepanelLink'

import { formatDate, formatDateTime, formatDateTimeFromNow, formatNumberWithSuffix } from 'utils/Utils'

const ProfileLinkCell = React.memo(
  ({ name, link, userKey, logoUrl, empty }: { name: string; logoUrl?: string; userKey?: string; link: string; empty?: string | ReactElement }) => {
    return (
      <>
        {name && (
          <SidepanelLink linkProps={{ to: link }} sidepanel={true}>
            <Box display="grid" alignItems="center" gridTemplateColumns="1fr minmax(0px, auto)">
              <AvatarWrapper name={name} userKey={userKey} logoUrl={logoUrl} size="xs" mode="dark" hideName />
              <Box ml={1}>
                <TextCell value={name} color="primary" />
              </Box>
            </Box>
          </SidepanelLink>
        )}
        {!name && empty && typeof empty === 'string' && <TextCell value={empty} />}
        {!name && isValidElement(empty) && empty}
      </>
    )
  }
)

const SummaryCell = React.memo((params: GridRenderCellParams) => (
  <SidepanelLink linkProps={{ to: params.row.requestLink }} sidepanel={params.row.sidepanel}>
    <TextCell value={params.row.summary} color="primary" bold />
  </SidepanelLink>
))

const TargetingStatusCell = React.memo(({ value, label, link }: { value: string; label: string; link: string }) => {
  const { icon, color } = getStatusIcon(value)

  const Label = <ChipLabel label={label} icon={icon} noWrap />
  return (
    <SidepanelLink linkProps={{ to: link }} sidepanel={true}>
      <StatusChip label={Label} color={color} clickable />
    </SidepanelLink>
  )
})

const StatusCell = React.memo((props: { status: StatusType | 'successful' | 'failed' }) => {
  return <IntroductionStatusChip status={props.status} />
})

export const createdColumn: GridColDef = {
  field: 'byCreated',
  headerName: 'Created',
  width: 120,
  minWidth: 120,
  flex: 1,
  hideable: true,
  pinnable: true,
  sortable: true,
  type: 'dateTime',
  renderCell: (params: GridRenderCellParams) => {
    const { created } = params.row
    const value = formatDateTime(created)
    const label = formatDate(created)
    return <DateCell value={value} label={label} />
  }
}

export const lastUpdateColumn: GridColDef = {
  field: 'byActivity',
  headerName: 'Updated',
  width: 160,
  minWidth: 160,
  flex: 1,
  hideable: true,
  pinnable: true,
  sortable: true,
  type: 'dateTime',
  renderCell: (params: GridRenderCellParams) => {
    const { updated } = params.row
    const value = formatDateTime(updated)
    const label = formatDateTimeFromNow(updated, 5)
    return <DateCell value={value} label={label} />
  }
}

export const assigneeColumn: GridColDef = {
  field: 'assignee',
  headerName: 'Assignee',
  width: 200,
  minWidth: 200,
  flex: 1,
  hideable: true,
  pinnable: true,
  renderCell: (params: GridRenderCellParams) => {
    const { assignee, requestLink, querierIsLtnManager, readyForReview } = params.row
    const AddAssignee = (
      <SidepanelLink linkProps={{ to: `${requestLink}/assignee` }} sidepanel={true}>
        <Button variant="link" startIcon={<FontAwesomeIcon icon={['far', 'plus']} style={{ fontSize: 14 }} />}>
          Assign
        </Button>
      </SidepanelLink>
    )
    return (
      <ProfileLinkCell
        userKey={assignee.email}
        name={assignee.name}
        link={assignee.link}
        empty={querierIsLtnManager && readyForReview ? AddAssignee : 'Unassigned'}
      />
    )
  }
}

export const beneficiaryColumn: GridColDef = {
  field: 'beneficiary',
  headerName: 'Beneficiary',
  width: 200,
  minWidth: 200,
  flex: 1,
  hideable: true,
  pinnable: true,
  renderCell: (params: GridRenderCellParams) => {
    const { beneficiary, beneficiaryType } = params.row
    return (
      <ProfileLinkCell
        userKey={beneficiaryType === 'person' ? beneficiary.url : ''}
        logoUrl={beneficiaryType === 'company' ? beneficiary.url : ''}
        name={beneficiary.name}
        link={beneficiary.link}
        empty="No beneficiary"
      />
    )
  }
}

export const outcomeColumn: GridColDef = {
  field: 'outcome',
  headerName: 'Outcome',
  width: 200,
  minWidth: 200,
  flex: 1,
  hideable: true,
  pinnable: true,
  renderCell: (params: GridRenderCellParams) => {
    const { outcome, requestLink, querierIsLtnManager, isCreator } = params.row

    return outcome.rating ? (
      <Box display="flex" alignItems="center" gap={2}>
        <StatusCell status={outcome.rating === -1 ? 'failed' : 'successful'} />
        {!!outcome.usd && <TextCell value={formatNumberWithSuffix(outcome.usd)} />}
      </Box>
    ) : isCreator || querierIsLtnManager ? (
      <ActionCell
        action={
          <SidepanelLink linkProps={{ to: `${requestLink}/outcome` }} sidepanel={true}>
            <Button variant="link" startIcon={<FontAwesomeIcon icon={['far', 'plus']} style={{ fontSize: 14 }} />}>
              Add outcome
            </Button>
          </SidepanelLink>
        }
      />
    ) : (
      <TextCell value="No outcome" />
    )
  }
}

export const statusColumn: GridColDef = {
  field: 'status',
  headerName: 'Status',
  width: 100,
  minWidth: 100,
  flex: 1,
  hideable: true,
  pinnable: true,
  renderCell: (params: GridRenderCellParams) => <StatusCell status={params.row.status} />
}

export const targetingStatusColumn: GridColDef = {
  field: 'targetingStatusColumn',
  headerName: 'Status',
  width: 250,
  minWidth: 250,
  flex: 1,
  hideable: true,
  pinnable: true,
  renderCell: (params: GridRenderCellParams) => {
    const { targetingStatus, introductionLink } = params.row
    return <TargetingStatusCell label={targetingStatus.label} value={targetingStatus.value} link={introductionLink} />
  }
}

export const requesterColumn: GridColDef = {
  field: 'requester',
  headerName: 'Requester',
  width: 200,
  minWidth: 200,
  flex: 1,
  hideable: true,
  pinnable: true,
  renderCell: (params: GridRenderCellParams) => {
    const { requester } = params.row
    return <ProfileLinkCell userKey={requester.email} name={requester.name} link={requester.link} />
  }
}

export const creatorColumn: GridColDef = {
  field: 'creator',
  headerName: 'Creator',
  width: 200,
  minWidth: 200,
  flex: 1,
  hideable: true,
  pinnable: true,
  renderCell: (params: GridRenderCellParams) => {
    const { creator } = params.row
    return <ProfileLinkCell userKey={creator.email} name={creator.name} link={creator.link} />
  }
}

export const summaryColumn: GridColDef = {
  field: 'summary',
  headerName: 'Headline',
  width: 250,
  minWidth: 250,
  flex: 1,
  hideable: false,
  pinnable: true,
  renderCell: (params: GridRenderCellParams) => <SummaryCell {...params} />
}

export const contactColumn: GridColDef = {
  field: 'name',
  headerName: 'Contact',
  width: 250,
  minWidth: 250,
  flex: 1,
  hideable: false,
  pinnable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <NameCell {...params} />
}

export const introducerColumn: GridColDef = {
  field: 'introducer',
  headerName: 'Introducer',
  width: 200,
  minWidth: 200,
  flex: 1,
  hideable: false,
  pinnable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { introducer, introductionLink, querierIsLtnManager, closedOut } = params.row
    const AddIntroducer = (
      <SidepanelLink linkProps={{ to: introductionLink }} sidepanel={true}>
        <Button variant="link" startIcon={<FontAwesomeIcon icon={['far', 'plus']} style={{ fontSize: 14 }} />}>
          Add
        </Button>
      </SidepanelLink>
    )
    return (
      <ProfileLinkCell
        userKey={introducer.email}
        name={introducer.name}
        link={introducer.link}
        empty={querierIsLtnManager && !closedOut ? AddIntroducer : 'No introducer'}
      />
    )
  }
}
