import React, { useContext } from 'react'

import { useParams } from 'react-router-dom'

import AuditProvider from '_core/context/Audit'
import { TeamContext } from '_core/context/TeamContext'

import Audit from '_core/components/audit/CompanyAudit'
import AuditEmpty from '_core/components/audit/Empty'

import DynamicEntity from '_core/DynamicEntity'

const Component = (props: { items: Partial<AuditInfo>; loading: boolean }) => {
  const { items: auditInfo, loading } = props

  if (props.loading || (auditInfo.anchorMd5 && auditInfo.entities)) {
    return (
      <AuditProvider auditInfo={auditInfo as AuditInfo}>
        <Audit loading={loading} />
      </AuditProvider>
    )
  }

  return <AuditEmpty />
}

const CompanyAuditPage = () => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = useContext(TeamContext)

  return (
    <DynamicEntity
      url={`/companies/${id}/auditinfo?teamNumber=${teamContextValue.teamNumber}`}
      list
      component={Component}
      empty="Profile not found"
      id="audit_profile"
    />
  )
}

export default CompanyAuditPage
