import { CSSProperties, ReactNode, RefObject, MouseEvent } from 'react'

import { Link, LinkProps } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import useSidepanelLink, { SidepanelLinkProps } from '_core/hooks/useSidepanelLink'

type AllowedComponents = keyof Pick<JSX.IntrinsicElements, 'div' | 'a'>

const useStyles = makeStyles()(() => ({}))

const SidepanelLink = <C extends AllowedComponents>(
  props: SidepanelLinkProps & {
    sidepanel?: SidepanelType
    linkRef?: C extends Extract<AllowedComponents, 'div'> ? RefObject<HTMLDivElement> : RefObject<HTMLAnchorElement>
    linkProps: { index?: number; style?: CSSProperties; className?: string } & Pick<LinkProps, 'to'>
    children?: ReactNode
    component?: C
  }
) => {
  const {
    linkProps: { to, index, style, className: linkPropsClassName },
    sidepanel = true,
    linkRef,
    onClick,
    window,
    preventClickOnSame,
    strict,
    children,
    component: Component
  } = props
  const { cx } = useStyles()
  const { handleClick, className } = useSidepanelLink({ onClick, window, preventClickOnSame, strict })

  if (!to) {
    return <>{children}</>
  }

  if (Component === 'div') {
    return (
      <div
        ref={linkRef as RefObject<HTMLDivElement>}
        style={style}
        className={cx(className, linkPropsClassName)}
        onClick={(e: MouseEvent<HTMLDivElement>) => handleClick(e, sidepanel, { to, index })}
      >
        {children}
      </div>
    )
  }

  return (
    <Link
      ref={linkRef as RefObject<HTMLAnchorElement>}
      onClick={(e: MouseEvent<HTMLAnchorElement>) => handleClick(e, sidepanel, { to, index })}
      style={style}
      className={cx(className, linkPropsClassName)}
      to={to}
    >
      {children}
    </Link>
  )
}

export default SidepanelLink
