import { useEffect } from 'react'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useNavBarMenuItemsShown from '_core/hooks/useNavBarMenuItemsShown'

export type ActivitiesVisibilityLevel = 'Off' | 'AdminsOnly' | 'AllUsers'
export type ActivitiesVisibility = {
  enabled: boolean
  statsWidgetVisibility: ActivitiesVisibilityLevel
  detailedActivityFromStatsWidgetVisibility: ActivitiesVisibilityLevel
  activityTabVisibility: ActivitiesVisibilityLevel
  showMeetingsTab: boolean
}
export type ActivitiesUserAccess = 'isStatsWidgetVisible' | 'isDetailedActivityFromStatsWidgetVisible' | 'isActivityTabVisible'

export type ActivitiesAccessData = [ActivitiesVisibility, ProfileType]

export const useActivitiesAccessData = () => {
  const { fetchWithAbort, result, setResult, reset } = useAbortableFetch<ActivitiesAccessData>()
  const [visibility, profile] = result || []

  const checkWhetherVisible = (visibilityLevel: ActivitiesVisibilityLevel) => {
    if (visibilityLevel === 'Off') {
      return false
    }
    return !!(visibilityLevel === 'AllUsers' || (visibilityLevel === 'AdminsOnly' && profile?.IsAdmin))
  }

  const userAccess = visibility
    ? {
        isStatsWidgetVisible: visibility.enabled && checkWhetherVisible(visibility.statsWidgetVisibility),
        isDetailedActivityFromStatsWidgetVisible: visibility.enabled && checkWhetherVisible(visibility.detailedActivityFromStatsWidgetVisibility),
        isActivityTabVisible: visibility.enabled && checkWhetherVisible(visibility.activityTabVisibility),
        isMeetingsTabVisible: visibility.showMeetingsTab
      }
    : visibility

  const updateUserActivitiesAccess = (activitiesAccessUpdateData: ActivitiesAccessData) => {
    setResult(activitiesAccessUpdateData)
  }

  const checkAccess = async () => {
    reset()
    fetchWithAbort([{ url: '/usersettings/activities' }, { url: '/me/profile' }])
  }

  return {
    checkAccess,
    activitiesAccessResult: result,
    userActivitiesAccess: userAccess,
    updateUserActivitiesAccess,
    resetActivitiesAccess: reset
  }
}

const useActivitiesAccess = (refetchDeps: (string | boolean)[] = []) => {
  const { updateUserActivitiesAccess } = useNavBarMenuItemsShown()
  const { checkAccess, ...activitiesAccess } = useActivitiesAccessData()

  useEffect(() => {
    checkAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refetchDeps)

  const { activitiesAccessResult, userActivitiesAccess } = activitiesAccess

  const loading = typeof userActivitiesAccess === 'undefined'

  useEffect(() => {
    checkAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refetchDeps)

  useEffect(() => {
    if (!loading) {
      if (activitiesAccessResult) {
        updateUserActivitiesAccess(activitiesAccessResult)
      }

      return () => {
        if (activitiesAccessResult) {
          updateUserActivitiesAccess(activitiesAccessResult)
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return activitiesAccess
}

export default useActivitiesAccess
