import React from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button } from '_shared/buttons'
import Card, { CardContent, CardActions } from '_shared/Card'
import Typography from '_shared/Typography'

import Heading from '_core/components/Heading'
import { Column, Columns, useWide } from '_core/components/layout'
import SidepanelLink from '_core/components/SidepanelLink'

import { DataAdminSubPaths } from 'Paths'

const Action = ({ link, title, icon }: { link: string; title: string; icon: IconProp }) => {
  return (
    <SidepanelLink sidepanel={true} linkProps={{ to: link }}>
      <Button variant="widget" color="primary" startIcon={<FontAwesomeIcon icon={icon} style={{ fontSize: 14 }} />} fullWidth>
        {title}
      </Button>
    </SidepanelLink>
  )
}

const baseUrl = DataAdminSubPaths.dataQuality
const items: {
  label: string
  icon: IconProp
  entity: DataQualityEntities
  description: string
}[] = [
  {
    label: 'People',
    icon: ['far', 'address-book'],
    description: 'Manage people related entity alignment issues.',
    entity: 'people'
  },
  {
    label: 'Companies',
    icon: ['far', 'building'],
    description: 'Manage companies related entity alignment issues.',
    entity: 'companies'
  }
]

const DataQualityActions = () => {
  const wide = useWide()

  return (
    <Columns mt={wide ? 0 : -2}>
      {items.map((item) => (
        <Column key={item.label} xs={12} sm={6} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Heading title={item.label} icon={item.icon} underlined />
              {item.description && <Typography color="text.secondary">{item.description}</Typography>}
            </CardContent>
            <CardActions>
              <Columns ml={-1} mr={1} mb={1}>
                <Column key={item.label} xs={12} md={6}>
                  <Action title="Download report" icon={['far', 'file-download']} link={`${baseUrl}/${item.entity}/download`} />
                </Column>
                <Column key={item.label} xs={12} md={6}>
                  <Action title="Upload fixes" icon={['far', 'cloud-arrow-up']} link={`${baseUrl}/${item.entity}/upload`} />
                </Column>
              </Columns>
            </CardActions>
          </Card>
        </Column>
      ))}
    </Columns>
  )
}

export default DataQualityActions
