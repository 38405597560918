import { ChangeEvent } from 'react'

import { Box } from '@mui/material'
import { Control, Controller, useForm } from 'react-hook-form'

import Checkbox from '_shared/forms/Checkbox'
import TextField from '_shared/forms/TextField'
import { HelpIconPopover } from '_shared/popovers/IconPopover'
import Typography from '_shared/Typography'

import Settings, { useStyles } from '_core/components/settings'

import useSaveSettings, { SaveAction } from '_core/hooks/useSaveSettings'

type SettingsData = {
  processContactCards: boolean
  prioritizeHistoricalScan: boolean
  blockNonBizPersonPush: boolean
  ensurePushPersonNames: boolean
  minP2pScore: number | string
  pushOnlyIfRelevant: boolean
  relevantAnyMsgDaysAgo: number | string
  relevantInteractionDaysAgo: number | string
}

type DataProcessingSettingsProps = {
  loading: boolean
  items?: SettingsData
}

const NumberTextField = ({ name, control, disabled }: { name: keyof SettingsData; disabled: boolean; control: Control<SettingsData> }) => {
  const { classes } = useStyles()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <TextField
          {...field}
          onChange={(e: ChangeEvent<HTMLInputElement>) => field.onChange(+e.target.value || '')}
          type="number"
          size="small"
          inputProps={{
            className: classes.tiny
          }}
          classes={{ root: classes.field }}
          disabled={disabled}
        />
      )}
    />
  )
}

const DataProcessingSettings = ({ items: settings, ...props }: DataProcessingSettingsProps) => {
  const { getValues, watch, control } = useForm<SettingsData>({
    mode: 'onChange',
    defaultValues: props.loading ? {} : settings || {}
  })

  const {
    classes: { filtersContent, subItem, subItemWrapper, icon }
  } = useStyles()

  const { isSaving, save: saveSettings } = useSaveSettings()

  const save = (action: SaveAction) => {
    const payload = getValues()
    saveSettings('/adminsettings/dataprocessing', payload, action, 'Admin data processing settings')
  }

  const pushOnlyIfRelevant = watch('pushOnlyIfRelevant')

  return (
    <Settings isSaving={isSaving} save={save} initialLoading={props.loading}>
      <Box width="100%">
        <Box display="flex" alignItems="center">
          <Controller
            name="processContactCards"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Checkbox checked={!!value} onChange={onChange} disabled={isSaving} label="Process contact cards" />
            )}
          />
          <HelpIconPopover
            title="Unchecking this setting will instruct the system not to process contact cards found in mailboxes"
            className={icon}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Controller
            name="prioritizeHistoricalScan"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Checkbox checked={!!value} onChange={onChange} disabled={isSaving} label="Prioritize historical scan of mailbox data" />
            )}
          />
          <HelpIconPopover
            title="Check this box to have the system give priority
                to indexing historical mailbox data. This does not mean newly
                incoming mailbox data will not be indexed, rather slighly more
                batches of historical data will be indexed in a given unit of time"
            className={icon}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Controller
            name="blockNonBizPersonPush"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Checkbox checked={!!value} onChange={onChange} disabled={isSaving} label="Don't process contacts with only personal email addresses" />
            )}
          />
          <HelpIconPopover
            title="If a contact has personal email addresses,
                but no work related email addresses, they should
                not be processed and surfaced in the app."
            className={icon}
          />
        </Box>
        <Box display="flex" alignItems="center" mb="-9px">
          <Controller
            name="pushOnlyIfRelevant"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Checkbox checked={!!value} onChange={onChange} disabled={isSaving} label="Apply filters to what contributors share with the team" />
            )}
          />
          <HelpIconPopover
            title="Data gathered for a contributor is analyzed in an area specific to that
                contributor and then shared with the team area to create a composite
                across all contributors. Along with the preferences of the contributor,
                these settings control what is shared, with an eye toward reducing noise
                at the team composite level."
            className={icon}
          />
        </Box>
        <Box marginLeft={4} className={filtersContent}>
          <Box className={subItemWrapper}>
            <Box className={subItem}>
              <Typography color="text.secondary">Stale relationship (in days)</Typography>
              <NumberTextField name="relevantInteractionDaysAgo" disabled={!pushOnlyIfRelevant || isSaving} control={control} />
              <HelpIconPopover
                title="Stale relationship (in days) - the number of days of no interactions before a person is removed from the team view.
                    Imagine you've had some back-and-forth interactions with a person, but the last of those was over 3 years ago. After that time
                    of no interaction, that person will be removed from view."
                className={icon}
              />
            </Box>
          </Box>
          <Box className={subItemWrapper}>
            <Box className={subItem}>
              <Typography color="text.secondary">No reciprocity (in days)</Typography>
              <NumberTextField name="relevantAnyMsgDaysAgo" disabled={!pushOnlyIfRelevant || isSaving} control={control} />
              <HelpIconPopover
                title="No reciprocity (in days) – the number of days after which a person with whom there has been no reciprocity
                    (i.e., either they send an email that was not replied to, or a contributor send them an email and they did not respond to it),
                    is removed from the team level view. Imagine a person sending a contributor a marketing or sales email that the contributor has
                    never responded to. If no subsequent interaction has happened, that person will be removed from the team view after one year."
                className={icon}
              />
            </Box>
          </Box>
          <Box className={subItemWrapper}>
            <Box className={subItem}>
              <Typography color="text.secondary">Minimum score setting</Typography>
              <NumberTextField name="minP2pScore" disabled={!pushOnlyIfRelevant || isSaving} control={control} />
              <HelpIconPopover
                title="Minimum score setting - the minimum scored relationship that should be pushed to the team composite."
                className={icon}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Settings>
  )
}

export default DataProcessingSettings
