import { useContext, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'

import Heading from '_core/components/Heading'
import InfoEmails from '_core/components/InfoEmails'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

export type InfoEmailsAddPropsType = {
  name: string
  setTotalEmails: (val: number) => void
}

const ContactInfoEmails = (props: { name: string }) => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = useContext(TeamContext)
  const { setSubHeader } = useContext(LayoutContext)
  const [total, setTotal] = useState<number>()

  useEffect(() => {
    setSubHeader('Contact information - emails')
    return () => setSubHeader('')
  }, [setSubHeader])

  return (
    <Page variant="core">
      <Narrow>
        <Topbar nativeBack />
        <Box p={2}>
          <Heading title="Email addresses" icon={['far', 'envelope']} count={total || 0} />
          <DynamicEntity<{ extraProps: { addprops: InfoEmailsAddPropsType } }>
            url={`/people/${id}/emailAddresses?TeamNumber=${teamContextValue.teamNumber}&Take=10`}
            component={InfoEmails}
            addprops={{ setTotalEmails: setTotal, name: props.name }}
            list={true}
            infinite={true}
            id="person_info_emails"
          />
        </Box>
      </Narrow>
    </Page>
  )
}

export default ContactInfoEmails
