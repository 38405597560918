import { useContext, useMemo, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import DownloadBulkControl from '_core/components/DownloadBulkControl'
import Filters from '_core/components/filters/Meetings'
import GridPageFrame from '_core/components/GridPageFrame'
import InfiniteScroll from '_core/components/InfiniteScroll'
import { Narrow, Wide } from '_core/components/layout'
import MeetingsList, { columns, Heading } from '_core/components/MeetingsList'
import Topbar from '_core/components/Topbar'

import useAdmin from '_core/hooks/useAdmin'
import useContributorsMeetings from '_core/hooks/useContributorsMeetings'
import useEnableDownloading from '_core/hooks/useEnableDownloading'
import useMeetingsUserSettings from '_core/hooks/useMeetingsUserSettings'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

import UserSettings from '_core/UserSettings'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const MeetingsPage = () => {
  const { id } = useParams<{ id: string }>()

  const { setSubHeader } = useContext(LayoutContext)
  const { queryParams } = useSearchQuery<EntityMeetingsPageParams, { modifyProps: [{ checked: IncludeMeetingsType[] }] }>(['checked'])

  const { setInitial, handleChange, toggleOpen, opened, reset, loading, handleDateChange, range } = useMeetingsUserSettings()

  const { dataVisibility } = useUserDataVisibility()
  const { preventNonAdminDownload } = dataVisibility || {}

  const admin = useAdmin()
  const enableDownloading = useEnableDownloading(admin, preventNonAdminDownload)

  const { meetings, total = 0, areMore, loading: contentLoading, more, reload, download } = useContributorsMeetings(useMemo(() => [id], [id]))

  useEffect(() => {
    setSubHeader(`Meetings ${total ? `· ${total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, total])

  const updateSort = (sort: SortType) => {
    handleChange({ sort })
  }

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    handleChange({ rowsPerPage })
  }

  const sortItems = columns.filter(({ sortable }) => sortable).map(({ headerName, field }) => ({ label: headerName || '', field }))

  const filtersProps = {
    toggleOpen,
    disabled: loading,
    opened,
    total,
    contentLoading,
    range,
    reset,
    queryData: queryParams,
    handleChange,
    handleDateChange
  }

  const component = (
    <MeetingsList
      meetings={meetings}
      total={total}
      loading={contentLoading}
      updateSort={updateSort}
      onPageSizeChange={onPageSizeChange}
      enableDownloading={enableDownloading}
      downloadAllControl={<DownloadBulkControl totalRows={total} disabled={contentLoading} download={download} />}
    />
  )

  return (
    <Page>
      <UserSettings endpoint="/usersettings/meetingsgrid" setInitial={setInitial}>
        <Narrow>
          <Topbar nativeBack autoHideOnScroll />
        </Narrow>
        <Wide>
          <GridPageFrame loading={loading} gridTitle="Meetings" filters={<Filters {...filtersProps} />} heading={<></>} component={component} />
        </Wide>
        <Narrow>
          <Heading
            filtersProps={{
              opened: filtersProps.opened,
              toggleOpen: filtersProps.toggleOpen,
              disabled: filtersProps.disabled
            }}
            filters={<Filters {...filtersProps} />}
            sortProps={{ sort: queryParams.sort || 'NewestToOldest', updateSort, items: sortItems }}
          />
          <InfiniteScroll loading={loading} dataLength={meetings?.length || 0} next={more} refreshFunction={reload} hasMore={!!areMore}>
            {component}
          </InfiniteScroll>
        </Narrow>
      </UserSettings>
    </Page>
  )
}

export default MeetingsPage
