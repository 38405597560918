import { ReactElement, ReactNode } from 'react'

import { Box } from '@mui/material'

import { Button } from '_shared/buttons'

import LeaveDialog from '_core/components/dialogs/Leave'
import FormLayout, { FormLayoutActions, FormLayoutContent } from '_core/components/FormLayout'

import useDialog from '_core/hooks/useDialog'
import useSidepanelClose from '_core/hooks/useSidepanelClose'

type IsDirtyProps = {
  isDirty: true
  leaveText?: string
}

type NoLeaveDialogProps = {
  isDirty?: false
  leaveText?: never
}

type NextStepFrameProps = {
  back: ReactElement | null
  next: ReactElement
  disabled?: boolean
  children: ReactNode
  reset?: () => void
} & (IsDirtyProps | NoLeaveDialogProps)

const NextStepFrame = ({ next, back, disabled, children, reset, isDirty }: NextStepFrameProps) => {
  const { isDialogOpened, openDialog, closeDialog } = useDialog()
  const handleClose = useSidepanelClose()

  const leaveConfirm = () => {
    if (reset) {
      reset()
    }
    handleClose()
  }

  const cancel = () => {
    isDirty ? openDialog() : leaveConfirm()
  }

  return (
    <FormLayout>
      {children}
      <FormLayoutActions>
        {back}
        <Box mr={-1} ml="auto">
          <Button onClick={cancel} disabled={disabled} variant="text" color="secondary">
            Cancel
          </Button>
          {next}
        </Box>
      </FormLayoutActions>
      <LeaveDialog opened={isDialogOpened} close={closeDialog} confirm={leaveConfirm} />
    </FormLayout>
  )
}

export default NextStepFrame
