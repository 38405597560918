import { useEffect, ReactNode, ReactElement, useRef } from 'react'

import { Box, CircularProgress } from '@mui/material'
import ReactInfiniteScroll from 'react-infinite-scroll-component'
import { makeStyles } from 'tss-react/mui'

import { useWide } from '_core/components/layout'

import usePresetScroll from '_core/hooks/usePresetScroll'
import useSearchQuery from '_core/hooks/useSearchQuery'

import useWindowDimensions from 'SharedComponents/useWindowDimensions'

const useStyles = makeStyles()((theme) => ({
  progress: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center'
  },
  root: {
    overflow: 'unset !important'
  }
}))

type InfiniteScrollType = {
  next: () => void
  refreshFunction: () => void
  scrollableTarget?: ReactNode
  children: ReactNode
  dataLength: number
  hasMore: boolean
  loading: boolean
  loader?: ReactElement
}

const InfiniteScroll = ({ dataLength, hasMore, next, refreshFunction, scrollableTarget, loader, children, loading }: InfiniteScrollType) => {
  const { classes } = useStyles()
  const windowDimensions = useWindowDimensions()
  const { queryParams, updateQuery } = useSearchQuery<GridParams>()
  const initNextCalled = useRef<boolean>()
  const wide = useWide()
  usePresetScroll(!wide && !loading)

  useEffect(() => {
    if (!initNextCalled?.current) {
      setTimeout(() => {
        if (dataLength && hasMore && window.document.body.clientHeight < windowDimensions.height) {
          next()
          initNextCalled.current = true
        }
      }, 0)
    }
  }, [dataLength, hasMore, next, windowDimensions])

  const handleNext = () => {
    next()
    const page = +(queryParams?.page || '1') + 1
    updateQuery({ page: `${page}` })
  }

  return (
    <ReactInfiniteScroll
      className={classes.root}
      dataLength={dataLength}
      next={handleNext}
      hasMore={hasMore}
      loader={
        loader || (
          <Box className={classes.progress}>
            <CircularProgress color="secondary" />
          </Box>
        )
      }
      scrollableTarget={scrollableTarget}
      scrollThreshold={0.89}
      refreshFunction={refreshFunction}
      pullDownToRefresh
      pullDownToRefreshThreshold={50}
      style={{ overflow: 'unset' }}
    >
      {children}
    </ReactInfiniteScroll>
  )
}
export default InfiniteScroll
