import React, { useState, createContext, ReactNode, useEffect, useRef, Dispatch, SetStateAction } from 'react'

type ContextType = {
  entities: AuditEntityState[] | undefined
  setEntities: Dispatch<SetStateAction<AuditEntityState[] | undefined>>
  activeId: string | undefined
  setActiveId: Dispatch<SetStateAction<string | undefined>>
  isDirty: boolean
  initial: AuditEntityState[] | undefined
  primary: string | undefined
  tuples: AuditTuples | undefined
  setTuples: Dispatch<SetStateAction<AuditTuples | undefined>>
  checkedTuples: string[]
  setCheckedTuples: Dispatch<SetStateAction<string[]>>
}

export const AuditContext = createContext({} as ContextType)

const AuditProvider = (props: { children: ReactNode; auditInfo: AuditInfo | undefined }) => {
  const { children, auditInfo } = props
  const { anchorMd5: primary } = auditInfo || {}
  const initial = useRef<AuditEntity[]>()

  const [entities, setEntities] = useState<AuditEntityState[]>()
  const [activeId, setActiveId] = useState<string>()

  const [tuples, setTuples] = useState<AuditTuples>()
  const [checkedTuples, setCheckedTuples] = useState<string[]>([])

  const isDirty = entities && initial.current ? entities[0].identifiers.length !== initial.current[0].identifiers.length : false

  useEffect(() => {
    if (auditInfo && !entities) {
      const { entities, anchorMd5 } = auditInfo
      const initialEntities = entities.map((entity) => ({
        ...entity,
        id: anchorMd5,
        identifiers: entity.identifiers.map((identifier) => ({
          ...identifier,
          isAnchor: identifier.md5 === anchorMd5 || identifier.distilledKeyMd5 === anchorMd5 || identifier.equivalentMd5 === anchorMd5
        }))
      }))

      setEntities(initialEntities)
      initial.current = initialEntities
      setActiveId(anchorMd5)
    }
  }, [entities, auditInfo])

  const ctx = {
    initial: initial.current,
    primary,
    entities,
    setEntities,
    activeId,
    setActiveId,
    isDirty,
    tuples,
    setTuples,
    checkedTuples,
    setCheckedTuples
  }
  return <AuditContext.Provider value={ctx}>{children}</AuditContext.Provider>
}

export default AuditProvider
