import { SyntheticEvent } from 'react'

import { CircularProgress, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Typography from '_shared/Typography'

import { ManualEditRowItem } from '_core/components/dialogs/ManualEditUndo'
import HelpLinkButton from '_core/components/HelpLinkButton'
import { Narrow } from '_core/components/layout'

const useStyles = makeStyles()((theme) => ({
  cardIcon: {
    position: 'absolute',
    top: 6,
    right: 9
  },
  undoButtonsContainer: {
    marginTop: theme.spacing(2),
    maxWidth: 130,
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    '& > .MuiButton-root': {
      flex: 1
    }
  }
}))

export const HelpLink = ({ className }: { className?: string }) => (
  <HelpLinkButton
    hint="Learn more about manual edits"
    style={{ fontSize: 18 }}
    href="https://help.dotalign.com/article/gthtgd1k02-manual-edits"
    classes={{ root: className }}
  />
)

type ManualEditUndoType = {
  loading: boolean
  items: ManualEditRowItem[]
  handleCancel: () => void
  handleConfirm: () => Promise<void> | void
  title: string
}

const ManualEditUndo = ({ loading, handleConfirm: confirm, handleCancel, title }: ManualEditUndoType) => {
  const { classes } = useStyles()

  const handleConfirm = (e: SyntheticEvent) => {
    e.stopPropagation()
    return confirm()
  }

  return (
    <>
      <Narrow>
        {loading && (
          <Box minWidth={{ xs: 280, md: 360 }} minHeight={{ xs: 240, md: 320 }} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <>
            <HelpLink className={classes.cardIcon} />
            <Typography variant="h4">{title}</Typography>
            <Box className={classes.undoButtonsContainer}>
              <Button onClick={handleConfirm}>Yes</Button>
              <Button onClick={handleCancel}>No</Button>
            </Box>
          </>
        )}
      </Narrow>
    </>
  )
}

export default ManualEditUndo
