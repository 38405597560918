import { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import { emailReason } from '_core/components/dialogs/InformAboutIdentity'
import IdentifierLabel from '_core/components/IdentifierLabel'
import Repeater from '_core/components/lists/Repeater'
import TitleDescription from '_core/components/TitleDescription'

import { mergeUrlWithParams, post } from 'utils/httpUtils'
import { dateToStringWithTodayComparing } from 'utils/Utils'

import Paths from 'Paths'

type InfoEmailsProps = {
  name: string
  loading: boolean
  total: number
  items: PersonEmailType[]
  setTotalEmails: (val: number) => void
}

const useStyles = makeStyles()((theme) => ({
  item: {
    padding: `${theme.spacing(1)} 0 ${theme.spacing(1)} 5px`
  },
  label: {
    paddingBottom: theme.spacing(1)
  }
}))

const InfoEmails = ({ loading, setTotalEmails, name, items, total }: InfoEmailsProps) => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = useContext(TeamContext)
  const { classes } = useStyles()

  useEffect(() => {
    if (typeof total === 'number') {
      setTotalEmails(total)
    }
  }, [setTotalEmails, total])

  const submitInvalid = (identity: string, value: number) => {
    return post(mergeUrlWithParams('/people/disqualify', { teamNumber: `${teamContextValue.teamNumber}` }), {
      identity,
      reason: emailReason[value]
    })
  }

  return (
    <Repeater
      component={TitleDescription}
      skeleton={{ size: 4, loading }}
      addprops={{ className: classes.item }}
      items={items?.map((email) => ({
        title: '',
        description: (
          <IdentifierLabel
            name={name}
            identity={email.AddressText}
            type="EmailAddress"
            submit={submitInvalid}
            className={classes.label}
            sourceLink={`${Paths._people}/${id}/emailSources/${email.AddressText}`}
            auditLink={`${Paths._people}/${id}/audit`}
          />
        ),
        blurb: email.CurrentAsOf ? `Last evidence on ${dateToStringWithTodayComparing(email.CurrentAsOf)}` : 'No data found'
      }))}
      empty="No email addresses were found"
    />
  )
}

export default InfoEmails
