import { useContext, useEffect, useState, useRef, useCallback } from 'react'

import { Switch, Route, Redirect, useLocation, matchPath } from 'react-router-dom'

import PrivateRelationshipsList from '_pages/manual-edits/private'

import Page from '_shared/Page'

import Filters from '_core/components/filters/ManualEdits'
import { Narrow, useWide } from '_core/components/layout'
import { Heading } from '_core/components/ManualEditsList'
import { HelpLink } from '_core/components/ManualEditUndo'

import useManualEditsUserSettings from '_core/hooks/useManualEditsUserSettings'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { LayoutContext } from 'Layout/LayoutContextProvider'
import NavBar from 'SharedComponents/NavBar'

import Paths from 'Paths'

import {
  actionColumn,
  anchorIdentifierColumn,
  assertedByColumn,
  assertionColumn,
  companyColumn,
  dateColumn,
  identifierColumn,
  preferredEmailColumn,
  preferredNameColumn,
  preferredPhoneColumn,
  preferredUrlColumn,
  token1Column,
  token2Column
} from './columns'
import CompaniesManualEdits, { saveData as cSaveData, resetEndpoint as cResetEndpoint } from './companies'
import { pEntityTypes, cEntityTypes } from './data'
import PeopleManualEdits, { saveData as pSaveData, resetEndpoint as pResetEndpoint } from './people'

const ManualEditsPage = () => {
  const wide = useWide()
  const { pathname } = useLocation()
  const { setMobileHeader } = useContext(LayoutContext)
  const [opened, setOpen] = useState<boolean>(false)
  const [total, setTotal] = useState<number>()

  const { queryParams } = useSearchQuery<ManualEditsPageParams>()
  const { sort } = queryParams

  const initialOpened = useRef<boolean>(false)

  const matchCompanies = matchPath(pathname, {
    path: `${Paths._manualEdits}/companies`
  })

  const matchPeople = matchPath(pathname, {
    path: `${Paths._manualEdits}/people`
  })

  const {
    options: entityOptions,
    saveData,
    resetEndpoint
  } = [
    { options: pEntityTypes, saveData: pSaveData, condition: matchPeople, resetEndpoint: pResetEndpoint },
    { options: cEntityTypes, saveData: cSaveData, condition: matchCompanies, resetEndpoint: cResetEndpoint }
  ].filter(({ condition }) => condition)[0] || {}

  const setInitialOpen = useCallback(
    (isOpened: boolean) => {
      initialOpened.current = isOpened
      if (wide) {
        setOpen(isOpened)
      }
    },
    [wide]
  )

  const {
    setInitial,
    handleChange,
    reset,
    loading: filtersLoading
  } = useManualEditsUserSettings({
    resetEndpoint,
    setInitialOpen
  })

  useEffect(() => {
    setMobileHeader('Manual Edits')
  }, [setMobileHeader])

  const toggleFilterOpen = () => {
    const isOpened = !opened
    setOpen(isOpened)
    if (wide) {
      handleChange({ ...saveData, getData: (params) => saveData.getData({ ...params, isOpened }) }, {})
    }
  }

  const handleEntityChange = (value: ManualEditsPageParams) => {
    handleChange({ ...saveData, getData: (params) => saveData.getData({ ...params, isOpened: wide ? opened : initialOpened.current }) }, value)
  }

  const updateSort = (sort: ManualEditsSortType) => {
    handleEntityChange({ sort })
  }

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    handleEntityChange({ rowsPerPage })
  }

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    setTotal(result?.total_item_count)
  }

  const contentLoading = typeof total !== 'number'

  const handleReset = () => {
    return reset(saveData)
  }

  const filtersProps = {
    toggleOpen: toggleFilterOpen,
    total,
    opened,
    disabled: filtersLoading,
    contentLoading,
    reset: handleReset,
    handleChange: handleEntityChange,
    entityOptions
  }

  const sortItems = [
    identifierColumn,
    anchorIdentifierColumn,
    token1Column,
    assertionColumn,
    preferredNameColumn,
    preferredPhoneColumn,
    preferredEmailColumn,
    preferredUrlColumn,
    token2Column,
    assertedByColumn,
    dateColumn,
    companyColumn,
    actionColumn
  ]
    .filter(({ sortable }) => sortable)
    .map(({ headerName, field }) => ({ label: headerName || '', field }))

  return (
    <Page>
      <Narrow>
        <NavBar>
          <HelpLink />
        </NavBar>
      </Narrow>
      <Heading
        disabledControls={filtersLoading}
        filtersProps={{ opened: filtersProps.opened, toggleOpen: filtersProps.toggleOpen, disabled: filtersProps.disabled }}
        filters={<Filters {...filtersProps} />}
        sortProps={{ value: sort, update: updateSort, items: sortItems }}
      />
      <Switch>
        <Route exact path={Paths._manualEdits}>
          <Redirect to={`${Paths._manualEdits}/companies`} />
        </Route>
        <Route exact path={`${Paths._manualEdits}/privaterelationships`} component={PrivateRelationshipsList} />
        <Route
          path={`${Paths._manualEdits}/people`}
          render={() => (
            <PeopleManualEdits
              filters={<Filters {...filtersProps} />}
              total={total}
              setInitial={setInitial}
              onPageSizeChange={onPageSizeChange}
              onLoading={onLoading}
              disabledSearch={filtersProps.disabled}
              updateSort={updateSort}
            />
          )}
        />
        <Route
          path={`${Paths._manualEdits}/companies`}
          render={() => (
            <CompaniesManualEdits
              filters={<Filters {...filtersProps} />}
              total={total}
              setInitial={setInitial}
              onPageSizeChange={onPageSizeChange}
              onLoading={onLoading}
              disabledSearch={filtersProps.disabled}
              updateSort={updateSort}
            />
          )}
        />
      </Switch>
    </Page>
  )
}

export default ManualEditsPage
