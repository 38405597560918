import React, { useMemo } from 'react'

import { Box, Typography, Divider } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import useSearchQuery from '_core/hooks/useSearchQuery'

import Paths from 'Paths'

import { TagsControl } from './controls'

import Filters from './index'

type EventNameType = Extract<IncludeEvents, 'meetings' | 'recurringMeetings' | 'appointments' | 'canceledMeetings'>
type StatusNameType = Extract<IncludeEvents, 'accepted' | 'tentativelyAccepted' | 'notResponded'>

const useStyles = makeStyles()((theme) => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  heading: {
    marginBottom: theme.spacing(1)
  },
  container: {
    paddingTop: 0,
    overflow: 'visible',
    '& div[class*="topBlock"]': {
      [theme.breakpoints.up('md')]: {
        backgroundColor: theme.palette.background.darker
      }
    }
  },
  block: {
    marginBottom: theme.spacing(1.5)
  }
}))

export const events: { name: EventNameType; label: string }[] = [
  { name: 'meetings', label: 'Meetings' },
  { name: 'recurringMeetings', label: 'Recurring meetings' },
  { name: 'appointments', label: 'Appointments' },
  { name: 'canceledMeetings', label: 'Cancelled meetings' }
]
export const statuses: { name: StatusNameType; label: string }[] = [
  { name: 'accepted', label: 'Accepted' },
  { name: 'tentativelyAccepted', label: 'Tentative' },
  { name: 'notResponded', label: 'Not responded' }
]

type EventsFiltersPropsType = {
  opened: boolean
  setLoading: (val: boolean) => void
  contentLoading: boolean
  anchorEl?: HTMLElement | null
  total?: number
  reset: () => void
  handleChange: (params: Modify<EventsPageParams, { checked?: IncludeEvents[] }>) => Promise<void | undefined>
}

const EventsFilters = ({ opened, setLoading, handleChange, contentLoading, anchorEl, total, reset }: EventsFiltersPropsType) => {
  const { classes } = useStyles()
  const { search } = useLocation<{ search: string }>()
  const { queryParams } = useSearchQuery<EventsPageParams, { modifyProps: [{ checked?: IncludeEvents[] }] }>(['checked'])
  const { checked = [], includeTags, excludeTags } = queryParams
  const disabled = !search

  const inclTags: TagsSearchParam | undefined = useMemo(() => (includeTags ? JSON.parse(includeTags) : includeTags), [includeTags])
  const exclTags: TagsSearchParam | undefined = useMemo(() => (excludeTags ? JSON.parse(excludeTags) : excludeTags), [excludeTags])

  const toggle = async (e: any) => {
    const { name } = e.target
    setLoading(true)
    await handleChange({
      checked: checked.includes(name) ? checked.filter((v: IncludeEvents) => v !== name) : [...checked, name]
    })
    setLoading(false)
  }

  return (
    <Filters
      opened={opened}
      contentLoading={contentLoading}
      className={classes.container}
      anchorEl={anchorEl}
      total={total}
      reset={reset}
      disabled={disabled}
    >
      <>
        <Box>
          <Typography classes={{ root: classes.heading }}>Events</Typography>
          {events.map((item) => (
            <Filters.Checkbox
              key={item.name}
              name={item.name}
              label={item.label}
              onChange={toggle}
              checked={!!checked.includes(item.name)}
              disabled={disabled}
            />
          ))}
        </Box>
        <Divider className={classes.divider} />
        <Box>
          <Typography classes={{ root: classes.heading }}>Status</Typography>
          {statuses.map((item) => (
            <Filters.Checkbox
              key={item.name}
              name={item.name}
              label={item.label}
              onChange={toggle}
              checked={!!checked.includes(item.name)}
              disabled={disabled}
            />
          ))}
        </Box>
        <Divider className={classes.divider} />
        <TagsControl
          taggableType="meetings"
          manageTagsLink={`${Paths._tags}/meetings`}
          appliedInclude={inclTags}
          appliedExclude={exclTags}
          handleChange={handleChange}
          disabled={disabled}
          blockClassName={classes.block}
        />
      </>
    </Filters>
  )
}

export { Controller } from './index'
export default EventsFilters
