import { SalesforceSettings } from "_core/components/settings/admin"

import DynamicEntity from "_core/DynamicEntity"

const SalesforceSettingsPage = () => {
  return (
    <DynamicEntity
      url={'/adminsettings/salesforce'}
      component={SalesforceSettings}
      empty="Salesforce related settings not found"
      list
      id="admin_settings_salesforce"
    />
  )
}

export default SalesforceSettingsPage