import { useContext, useEffect, useState } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import { transformPersonData } from '_core/hooks/usePersonMarketData'

import { mergeUrlWithParams } from 'utils/httpUtils'

export type FiltersType = {
  email: string
  companySize: string
  jobTitles: string[]
  jobTitleRoles: string[]
  jobTitleSubroles: string[]
  jobTitleLevels: string[]
  industries: string[]
  countries: string[]
  locationName?: string[]
  locationRegion?: string[]
  educationSchoolName?: string[]
  skills?: string[]
}

export type PeopleType = {
  data: PeopleMarketDataType[]
  filters?: FiltersType
} & PaginationMarketData

export const transformResponse = (people: PeopleMarketDataRes['data']): PeopleType['data'] => {
  return people.map((person) => ({
    ...transformPersonData(person),
    personMd5: person.personMd5 || '',
    score: person.score || 0
  }))
}

const useSimilarPeople = (personMd5: string, initParams: FiltersType, isConfigured: boolean, hasValidFilters: boolean) => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { fetchWithAbort } = useAbortableFetch<PeopleMarketDataRes>()
  const [similar, setSimilar] = useState<PeopleType | null>(null)

  useEffect(() => {
    return () => {
      setSimilar(null)
    }
  }, [personMd5])

  const getSimilar = async () => {
    if (initParams && teamNumber) {
      const res = await fetchWithAbort({
        url: mergeUrlWithParams('/cloudhub/people/similar', {
          ...initParams,
          teamNumber: teamNumber.toString(),
          titlecase: 'true',
          ...(similar?.scrollToken && { scrollToken: similar.scrollToken })
        })
      })
      if (res) {
        const transformedData = transformResponse(res.data)
        setSimilar({
          data: similar?.data ? [...similar.data, ...transformedData] : transformedData,
          scrollToken: res.scrollToken || '',
          total: res.total || 0,
          filters: initParams
        })
      }
    }
  }

  useEffect(() => {
    if (isConfigured && !similar && teamNumber) {
      hasValidFilters
        ? getSimilar()
        : setSimilar({
            data: [],
            scrollToken: '',
            total: 0
          })
    }
  }, [isConfigured, similar, teamNumber, hasValidFilters])

  const getPersonDetails = (name: string, email?: string): PeopleMarketDataType | undefined => {
    return (similar?.data || []).find((item) => item.fullName === name || (email && item.workEmail === email))
  }

  return {
    similar,
    getSimilar,
    getPersonDetails
  }
}

export default useSimilarPeople
