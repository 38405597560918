import { createContext, useEffect, useState, useMemo, useCallback, Fragment, ReactNode } from 'react'

import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom'

import { cEntityTypes, pEntityTypes } from '_pages/manual-edits/data'

import { perspectiveOptions as companiesPerspectiveOptions, dealsOptions } from '_core/components/filters/Companies'
import { wasOptions, daysOptions, touchpointDateTypeOptions, interactionsOptions } from '_core/components/filters/data'
import { perspectiveOptions as peoplePerspectiveOptions } from '_core/components/filters/People'

import { parseUrl, stringifyUrl } from '_core/helpers/browser'

import { request } from 'utils/fetchUtils'
import { formatDate, getLocal, dateFormat, getUTC } from 'utils/Utils'

import Paths, { DataAdminSubPaths } from 'Paths'

type UnionToType<U extends Record<string, unknown>> = {
  [K in U extends unknown ? keyof U : never]: U extends unknown ? (K extends keyof U ? NonNullable<U[K]> : never) : never
}

type Params = UnionToType<ContextType['queryParams']>

type Key<D = any> = {
  isValid: (params: Exclude<Params, 'sort'> & { sort: any }) => boolean
  getDefault: (data: D) => any
}

type PageData<Q, D> = {
  keys: { [key in keyof Required<Q>]: Key<D> }
  endpoint?: string
}

type InteractionsDataType = Key<PeopleInit | CompaniesInit | IntroducersInit | CompanyContactsInit>

const today = getLocal()

export const evtsIncludeOptions: IncludeEvents[] = [
  'appointments',
  'recurringMeetings',
  'meetings',
  'canceledMeetings',
  'accepted',
  'tentativelyAccepted',
  'notResponded'
]

const pIncludeOptions: IncludePeopleType[] = ['showExternalContacts', 'showColleagues', 'showFormerColleagues']
const iIncludeOptions: Extract<IncludeInteractions, 'inbound' | 'outbound'>[] = ['inbound', 'outbound']
const sIncludeOptions: SearchEntities[] = ['person', 'company']
const uploadsSortOptions: UploadsSortType[] = ['DateDesc', 'DateAsc', 'FileNameAsc', 'FileNameAsc', 'FileTypeDesc', 'FileTypeAsc']
const adminSettingsTabOptions: AdminSettingsTabs[] = [
  'general',
  'cloud-hub',
  'email-alerts',
  'salesforce',
  'data-processing',
  'data-visibility',
  'data-analytics',
  'linkedin',
  'activities',
  'introductions'
]
const userSettingsTabOptions: UserSettingsTabs[] = ['general', 'email', 'demo']
const manualEditsSortOptions: ManualEditsSortType[] = ['EditedWhenDesc', 'EditedWhenAsc']

const rowsPerPageOpts: NumberToString<RowPerPageOptionsType>[] = ['10', '20', '50']
const sortOptions: Array<StatSortType | ScoreType> = [
  'ScoreAsc',
  'ScoreDesc',
  'LastInboundDesc',
  'LastInboundAsc',
  'LastOutboundDesc',
  'LastOutboundAsc',
  'LastMeetingAsc',
  'LastMeetingDesc',
  'NextFutureDesc',
  'NextFutureAsc'
]
const viewModeOptions: Array<ViewModeType> = ['collapsed', 'expanded']
const searchSourceOptions: Array<SearchScope> = ['DotAlign', 'MarketData']

// common
const page = {
  isValid: (params: Params) => !params.page || !isNaN(+params.page),
  getDefault: () => null
}

const keyword = {
  isValid: () => true,
  getDefault: () => ''
}

const date: Key = {
  isValid: (params) => moment(params.date, dateFormat, true).isValid(),
  getDefault: () => formatDate(today)
}

const sort = {
  isValid: (params: Exclude<Params, 'sort'> & { sort: ScoreType | StatSortType }) => sortOptions.includes(params.sort),
  getDefault: () => 'ScoreDesc'
}

const rowsPerPage: Key = {
  isValid: (params) => rowsPerPageOpts.includes(params.rowsPerPage),
  getDefault: () => '10'
}

const excludeEmpty: Key = {
  isValid: (params) => !params.excludeEmpty || params.excludeEmpty === 'true' || params.excludeEmpty === 'false',
  getDefault: () => null
}

const viewMode = {
  isValid: (params: Params) => viewModeOptions.includes(params.viewMode),
  getDefault: () => viewModeOptions[0]
}

const period: InteractionsDataType = {
  isValid: (params) => !params.period || wasOptions.map(({ value }) => `${value}`).includes(params.period),
  getDefault: ({ dateConditionType }) => dateConditionType
}

const days: InteractionsDataType = {
  isValid: (params) => (!params.period && !params.days) || (params.period && daysOptions.map(({ value }) => `${value}`).includes(params.days)),
  getDefault: ({ dayCount }) => `${dayCount || daysOptions[0].value}`
}

const where: InteractionsDataType = {
  isValid: (params) => !params.where || touchpointDateTypeOptions.map(({ value }) => value).includes(params.where),
  getDefault: ({ touchpointDateType }) => touchpointDateType
}

const interaction: InteractionsDataType = {
  isValid: (params) => !params.interaction || interactionsOptions.map(({ value }) => value).includes(params.interaction),
  getDefault: ({ touchpointType }) => touchpointType
}

const and: InteractionsDataType = {
  isValid: (params) => !params.and || params.and === 'noFutureScheduled',
  getDefault: ({ futureMeetingNotScheduled }) => futureMeetingNotScheduled
}

const isTagValid = (tagParam: 'excludeTags' | 'includeTags', params: Params) => {
  if (!params[tagParam]) {
    return true
  }
  try {
    const tags = JSON.parse(params[tagParam])
    return tags?.every((tag: { name: string; value: string }) => tag.name && tag.value)
  } catch (e) {
    return false
  }
}

const excludeTags = {
  isValid: (params: Params) => isTagValid('excludeTags', params),
  getDefault: ({ excludeTags }: { excludeTags: TagsSearchParam }) => (excludeTags ? JSON.stringify(excludeTags) : '')
}

const includeTags = {
  isValid: (params: Params) => isTagValid('includeTags', params),
  getDefault: ({ includeTags }: { includeTags: TagsSearchParam }) => (includeTags ? JSON.stringify(includeTags) : '')
}

// specific pages
const peopleParams: PageData<PeoplePageParams, PeopleInit> = {
  keys: {
    checked: {
      isValid: (params) => !(params.checked?.split(',') as IncludePeopleType[])?.find((value) => !pIncludeOptions.includes(value)),
      getDefault: (data) => {
        const { showColleagues, showExternalContacts, showFormerColleagues } = data
        const checkboxes = { showColleagues, showExternalContacts, showFormerColleagues }
        return Object.keys(checkboxes).filter((key) => checkboxes[key as IncludePeopleType])
      }
    },
    sort: { ...sort, getDefault: (data: PeopleInit) => data.sort },
    rowsPerPage: { ...rowsPerPage, getDefault: (data: PeopleInit) => `${data.rowsPerPage}` },
    perspective: {
      isValid: (params) => peoplePerspectiveOptions.map(({ value }) => value).includes(params.perspective),
      getDefault: ({ perspective }) => perspective
    },
    period,
    days,
    where,
    interaction,
    and,
    page,
    keyword,
    viewMode,
    includeTags,
    excludeTags,
    excludeEmpty
  },
  endpoint: '/usersettings/peoplesearchfilter'
}

const companiesParams: PageData<CompaniesPageParams, CompaniesInit> = {
  keys: {
    sort: { ...sort, getDefault: (data: CompaniesInit) => data.sort },
    rowsPerPage: { ...rowsPerPage, getDefault: (data: CompaniesInit) => `${data.rowsPerPage}` },
    perspective: {
      isValid: (params) => companiesPerspectiveOptions.map(({ value }) => value).includes(params.perspective),
      getDefault: ({ perspective }) => perspective
    },
    period,
    days,
    where,
    interaction,
    and,
    page,
    keyword,
    viewMode,
    excludeTags,
    includeTags,
    filterByDeal: {
      isValid: ({ filterByDeal }: Params) => filterByDeal === 'true' || filterByDeal === 'false',
      getDefault: ({ showDealsFilters }) => showDealsFilters
    },
    dealsChecked: {
      isValid: (params) =>
        !(params.checked?.split(',') as IncludeDealsType[])?.find((value) => !dealsOptions.map(({ name }) => name).includes(value)),
      getDefault: (data) => {
        const { doneDealWithCompany } = data
        const checkboxes = { hadDeal: doneDealWithCompany }
        return Object.keys(checkboxes).filter((key) => checkboxes[key as IncludeDealsType])
      }
    },
    excludeEmpty
  },
  endpoint: '/usersettings/companiessearchfilter'
}

const eventsParams: PageData<EventsPageParams, IEventsInit> = {
  keys: {
    checked: {
      isValid: (params) => !(params.checked?.split(',') as IncludeEvents[])?.find((value) => !evtsIncludeOptions.includes(value)),
      getDefault: ({ showPast, ...data }: any) => Object.keys(data).filter((key) => data[key as keyof typeof data])
    },
    showPast: {
      isValid: ({ showPast }: Params) => showPast === 'true' || showPast === 'false',
      getDefault: ({ showPast }) => showPast
    },
    date,
    excludeTags,
    includeTags
  },
  endpoint: '/usersettings/homePage'
}

const interactionsParams: PageData<InteractionsPageParams, { [key in Extract<IncludeInteractions, 'inbound' | 'outbound'>]: boolean }> = {
  keys: {
    checked: {
      isValid: (params) =>
        !(params.checked?.split(',') as Extract<IncludeInteractions, 'inbound' | 'outbound'>[])?.find((value) => !iIncludeOptions.includes(value)),
      getDefault: (data) => Object.keys(data).filter((key) => data[key as keyof typeof data])
    }
  },
  endpoint: '/usersettings/interactionsfilter'
}

const searchParams: PageData<SearchPageParams, ISearchInit> = {
  keys: {
    entities: {
      isValid: (params) => !(params.entities?.split(',') as SearchEntities[])?.find((value) => !sIncludeOptions.includes(value)),
      getDefault: (data) => data.entities.map((e) => e.toLowerCase())
    },
    selectedEntity: {
      isValid: (params) => !(params.entities?.split(',') as SearchEntities[])?.find((value) => !sIncludeOptions.includes(value)),
      getDefault: (data) => data.selectedEntity.toLowerCase()
    },
    scope: {
      isValid: (params) => searchSourceOptions.includes(params.scope),
      getDefault: (data) => data.searchType
    },
    peopleFilters: {
      isValid: () => true,
      getDefault: () => ''
    },
    companiesFilters: {
      isValid: () => true,
      getDefault: () => ''
    },
    rowsPerPage,
    viewMode,
    keyword
  },
  endpoint: '/usersettings/searchfilter'
}

const dataUploadsParams: PageData<DataUploadsPageParams, IDataUploadsInit> = {
  keys: {
    sort: {
      isValid: (params: Exclude<Params, 'sort'> & { sort: DataUploadsPageParams['sort'] }) => uploadsSortOptions.includes(params.sort),
      getDefault: () => 'DateDesc'
    },
    rowsPerPage,
    page,
    keyword
  }
}

const contributorsPeopleParams: PageData<PeoplePageParams, PeopleInit> = {
  ...peopleParams,
  keys: {
    ...peopleParams.keys,
    perspective: {
      isValid: (params) => !params.perspective,
      getDefault: () => null
    }
  },
  endpoint: '/usersettings/peoplesearchfilter'
}

const contributorsCompaniesParams: PageData<CompaniesPageParams, CompaniesInit> = {
  ...companiesParams,
  keys: {
    ...companiesParams.keys,
    perspective: {
      isValid: (params) => !params.perspective,
      getDefault: () => null
    }
  },
  endpoint: '/usersettings/companiessearchfilter'
}

const peopleManualEditsParams: PageData<ManualEditsPageParams, ManualEditsInit> = {
  keys: {
    entityType: {
      isValid: (params: Exclude<Params, 'sort'>) => !params.entityType || !!pEntityTypes.find((type) => type.value === params.entityType),
      getDefault: (data) => data.type
    },
    contributor: {
      isValid: () => true,
      getDefault: (data) => data.contributorKey
    },
    rowsPerPage: { ...rowsPerPage, getDefault: (data: ManualEditsInit) => `${data.rowsPerPage}` },
    sort: {
      isValid: (params: Exclude<Params, 'sort'> & { sort: ManualEditsPageParams['sort'] }) => manualEditsSortOptions.includes(params.sort),
      getDefault: () => 'EditedWhenDesc'
    },
    page,
    keyword
  },
  endpoint: '/usersettings/personmanualeditsfilters'
}

const companiesManualEditsParams: PageData<ManualEditsPageParams, ManualEditsInit> = {
  keys: {
    entityType: {
      isValid: (params: Exclude<Params, 'sort'>) => !params.entityType || !!cEntityTypes.find((type) => type.value === params.entityType),
      getDefault: (data) => data.type
    },
    contributor: {
      isValid: () => true,
      getDefault: (data) => data.contributorKey
    },
    rowsPerPage: { ...rowsPerPage, getDefault: (data: ManualEditsInit) => `${data.rowsPerPage}` },
    sort: {
      isValid: (params: Exclude<Params, 'sort'> & { sort: ManualEditsPageParams['sort'] }) => manualEditsSortOptions.includes(params.sort),
      getDefault: () => 'EditedWhenDesc'
    },
    page,
    keyword
  },
  endpoint: '/usersettings/companymanualeditsfilters'
}

const introducersParams: PageData<IntroducersPageParams, IntroducersInit> = {
  keys: {
    sort: { ...sort, getDefault: (data: IntroducersInit) => data.sort },
    rowsPerPage: { ...rowsPerPage, getDefault: (data: IntroducersInit) => `${data.rowsPerPage}` },
    period,
    days,
    where,
    interaction,
    and,
    page,
    keyword,
    viewMode,
    includeTags,
    excludeTags,
    excludeEmpty,
    from: { isValid: () => true, getDefault: () => getUTC().toISOString() },
    to: { isValid: () => true, getDefault: () => getUTC().toISOString() }
  },
  endpoint: '/usersettings/introducersfilter'
}

const companyContactsParams: PageData<CompanyContactsPageParams, CompanyContactsInit> = {
  keys: {
    sort: { ...sort, getDefault: (data: CompanyContactsInit) => data.sort },
    rowsPerPage: { ...rowsPerPage, getDefault: (data: CompanyContactsInit) => `${data.rowsPerPage}` },
    period,
    days,
    where,
    interaction,
    and,
    page,
    keyword,
    viewMode,
    includeTags,
    excludeTags,
    excludeEmpty
  },
  endpoint: '/usersettings/companycontactsfilters'
}

const userSettingsParams: PageData<UserSettingsPageParams, UserSettingsTabs> = {
  keys: {
    tab: {
      isValid: (params: Pick<Params, 'tab'>) => !!userSettingsTabOptions.find((tab) => tab === params.tab),
      getDefault: () => userSettingsTabOptions[0]
    }
  }
}

const adminSettingsParams: PageData<AdminSettingsPageParams, AdminSettingsTabs> = {
  keys: {
    tab: {
      isValid: (params: Pick<Params, 'tab'>) => !!adminSettingsTabOptions.find((tab) => tab === params.tab),
      getDefault: () => adminSettingsTabOptions[0]
    }
  }
}

export const ParamsValidationContext = createContext({} as ContextType)

const ParamsValidationProvider = (props: { children: ReactNode }) => {
  const { pathname, search, state } = useLocation()
  const history = useHistory()
  const params: any = useMemo(() => parseUrl(search), [search])
  const { enqueueSnackbar } = useSnackbar()

  const { isExact: matchCompanies } = useRouteMatch(Paths._companies) || {}
  const { isExact: matchPeople } = useRouteMatch(Paths._people) || {}
  const { isExact: matchContributorsCompanies } = useRouteMatch(`${Paths.personProfile}/companies`) || {}
  const { isExact: matchContributorsPeople } = useRouteMatch(`${Paths.personProfile}/people`) || {}
  const { isExact: matchEvents } = useRouteMatch(Paths._events) || {}
  const { isExact: matchInteractions } = useRouteMatch([`${Paths.personProfile}/interactions`, `${Paths.companyProfile}/interactions`]) || {}
  const { isExact: matchSearch } = useRouteMatch([Paths._search]) || {}
  const { isExact: matchDataUploads } = useRouteMatch([DataAdminSubPaths.dataUploads]) || {}
  const { isExact: matchPeopleManualEdits } = useRouteMatch(`${Paths._manualEdits}/people`) || {}
  const { isExact: matchCompaniesManualEdits } = useRouteMatch(`${Paths._manualEdits}/companies`) || {}
  const { isExact: matchIntroducers } = useRouteMatch([`${Paths.personProfile}/introducers`, `${Paths.companyProfile}/introducers`]) || {}
  const { isExact: matchCompanyContacts } = useRouteMatch(`${Paths.companyProfile}/contacts`) || {}
  const { isExact: matchAdminSettings } = useRouteMatch(`${Paths._adminSettings}`) || {}
  const { isExact: matchUserSettings } = useRouteMatch(`${Paths._settings}`) || {}

  const getData = () => {
    switch (true) {
      case matchPeople:
        return peopleParams
      case matchContributorsPeople:
        return contributorsPeopleParams
      case matchCompanies:
        return companiesParams
      case matchContributorsCompanies:
        return contributorsCompaniesParams
      case matchEvents:
        return eventsParams
      case matchInteractions:
        return interactionsParams
      case matchSearch:
        return searchParams
      case matchDataUploads:
        return dataUploadsParams
      case matchPeopleManualEdits:
        return peopleManualEditsParams
      case matchCompaniesManualEdits:
        return companiesManualEditsParams
      // case matchIntroducers:
      //   return introducersParams
      case matchCompanyContacts:
        return companyContactsParams
      case matchAdminSettings:
        return adminSettingsParams
      case matchUserSettings:
        return userSettingsParams
      default:
        return null
    }
  }

  const invParams: (Key<any> & { name: string })[] = useMemo(
    () =>
      (() => {
        if (search && pathname !== Paths.login) {
          const { keys } = (getData() || {}) as { keys: { [key: string]: Key<any> } }
          if (keys) {
            const keysData = Object.keys(keys).map((key) => ({ ...keys[key], name: key }))
            return keysData.filter((key) => !key.isValid(params))
          }
        }
        return []
      })(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, search]
  )

  const [{ pathname: fixedParamsPathname, fixedParams }, setFixedParamsState] = useState<{
    pathname: string
    fixedParams: { [key: string]: any } | null
  }>({ pathname, fixedParams: null })

  const validParams = useMemo(
    () => (invParams.length ? (fixedParamsPathname === pathname ? fixedParams : null) : params),
    [fixedParams, fixedParamsPathname, invParams.length, params, pathname]
  )

  useEffect(() => {
    ;(async () => {
      if (invParams.length) {
        const { endpoint, keys } = getData() || {}
        const result = endpoint ? await request(endpoint) : keys

        enqueueSnackbar(
          <div>
            {invParams.map(({ name }, i) => (
              <Fragment key={i}>
                <i>
                  {name}: {params[name]}
                </i>
                {i + 1 < invParams.length && ','}{' '}
              </Fragment>
            ))}
            {invParams.length > 1 ? 'are' : 'is'} invalid
            <br />
            Some invalid parameters were updated to their default values.
          </div>,
          { variant: 'default' }
        )

        const fixedParams = { ...params, ...invParams.reduce((acc, { name, getDefault }) => ({ ...acc, [name]: getDefault(result) }), {}) }

        setFixedParamsState({ pathname, fixedParams })
      } else {
        setFixedParamsState({ pathname, fixedParams: null })
      }
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invParams])

  const updateQuery = useCallback(
    (params: { [key: string]: string }, st: unknown = state) => {
      const newPathname = stringifyUrl(pathname, {
        ...validParams,
        ...params
      })
      history.replace(newPathname, st)
    },
    [history, pathname, validParams, state]
  )

  useEffect(() => {
    if (validParams) {
      const validKeys = Object.keys(validParams) as (keyof typeof validParams)[]
      const invalidKeys = Object.keys(invParams || {}) as (keyof typeof invParams)[]
      if (search && validKeys.length) {
        const missedReqParamsKeys = validKeys.filter((key) => !params[key])

        if (missedReqParamsKeys.length || invalidKeys.length) {
          const paramsToUpdate = [...missedReqParamsKeys, ...invalidKeys].reduce((acc, key) => ({ ...acc, [key]: validParams[key] }), {})
          updateQuery(paramsToUpdate)
        }
      }
    }
  }, [params, validParams, updateQuery, search, invParams])

  const ctx = useMemo(
    () => ({
      queryParams: validParams,
      updateQuery
    }),
    [validParams, updateQuery]
  )

  if (validParams === null) {
    return null
  }

  return <ParamsValidationContext.Provider value={ctx}>{props.children}</ParamsValidationContext.Provider>
}

export default ParamsValidationProvider

type ContextType = {
  queryParams:
    | PeoplePageParams
    | ProfilePageParams
    | CompaniesPageParams
    | EventsPageParams
    | InteractionsPageParams
    | SearchPageParams
    | MessageDetailsPageParams
    | LoginAttempts
    | DataUploadsPageParams
    | ManualEditsPageParams
    | MergePageParams
    | ActivitiesPageParams
    | UserSettingsPageParams
    | AdminSettingsPageParams
  updateQuery: (params: { [key: string]: string }, state?: { [key: string]: any } | undefined) => void
}

type ProfilePageParams = {
  name?: string
  email?: string
}

type MessageDetailsPageParams = {
  deleteBackLink?: string
}

type LoginAttempts = {
  numAttempts?: string | null
}
