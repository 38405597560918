import React, { SyntheticEvent, useState } from 'react'

import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Dialog, { DialogActions, DialogTitle, DialogContent } from '_shared/Dialog'
import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => ({
  undoTitle: {
    fontSize: 18
  },
  undoAssertion: {
    margin: `${theme.spacing(0.5)} 0px`,
    textTransform: 'lowercase',
    fontSize: 14
  }
}))

type Assertion =
  | 'Affiliated'
  | 'NoLongerAffiliated'
  | 'NeverAffiliated'
  | 'Requalified'
  | 'CurrentPersonPhone'
  | 'InvalidPersonPhone'
  | 'NotCurrentPersonPhone'
  | InvalidReasonsType
  | ManualEditsEntityType

export type ManualEditRowItem = {
  id: string
  identifier1Md5: string
  identifier1RefText: string
  identifier2Md5: string
  identifier2RefText: string
  assertionLabel: string
  assertedBy: string
  date: string
  assertion?: Assertion
  editLink?: string
  auditLink?: string
  title?: string
}

export type DeleteManualEditsRespType = { success: boolean; errorMessage?: string }

type ManualEditUndoProps = {
  items: ManualEditRowItem[]
  isOpened: boolean
  close: () => void
  title: string
  confirmDelete: (payload: ManualEditRowItem[], successCallback?: () => void) => Promise<void>
}

const TriggerEl = ({ open, disabled, className }: { open: (e: SyntheticEvent) => void; disabled?: boolean; className?: string }) => {
  return <IconButton className={className} hint="Undo" icon={['fas', 'undo']} size="small" onClick={open} disabled={disabled} />
}

const EditUndoDialog = ({ items, isOpened, close, title, confirmDelete }: ManualEditUndoProps) => {
  const { classes } = useStyles()
  const [undoLoading, setUndoLoading] = useState<boolean>(false)

  const handleUndoCancel = (e: SyntheticEvent) => {
    e.stopPropagation()
    close()
  }

  const deleteEdit = async () => {
    setUndoLoading(true)
    await confirmDelete(items)
    setUndoLoading(false)
    close()
  }

  const handleUndoConfirm = () => {
    deleteEdit()
  }

  const itemsLength = items.length
  const item = items?.[0]

  return (
    <Dialog
      open={isOpened}
      onClose={close}
      loading={undoLoading}
      title={
        <DialogTitle title={title} link="https://help.dotalign.com/article/gthtgd1k02-manual-edits" linkTooltip="Learn more about manual edits" />
      }
    >
      <DialogContent>
        {itemsLength === 1 && (
          <>
            <Typography classes={{ root: classes.undoTitle }}>{item.identifier1RefText}</Typography>
            <Typography classes={{ root: classes.undoAssertion }}>{item.assertionLabel ? item.assertionLabel : ''}</Typography>
            {item.identifier2RefText && <Typography classes={{ root: classes.undoTitle }}>{item.identifier2RefText}</Typography>}
          </>
        )}
        {itemsLength > 1 && <Typography>Do you want to undo the selected edits?</Typography>}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={handleUndoCancel}>
          No
        </Button>
        <Button variant="text" onClick={handleUndoConfirm} disablePR>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EditUndoDialog.TriggerEl = TriggerEl

export default EditUndoDialog
