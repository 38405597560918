import React, { ChangeEvent, Dispatch, SetStateAction, useContext } from 'react'

import { Box } from '@mui/material'
import { Link, useRouteMatch } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { TeamContext } from '_core/context/TeamContext'

import { IconButton } from '_shared/buttons'
import Card, { CardContent } from '_shared/Card'

import AssociationListItem from '_core/components/audit/AssociationListItem'
import AudiTupleInvalidDialog from '_core/components/dialogs/AuditTupleInvalid'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import Repeater from '_core/components/lists/Repeater'
import SearchInput from '_core/components/SearchInput'

import useOutlook from '_core/hooks/useOutlook'
import useUserMembership from '_core/hooks/useUserMembership'

export type TupleInfoType = {
  lowIdentifier: {
    text: string
    md5: string
  }
  type: 'Association'
  highIdentifier: {
    text: string
    md5: string
  }
}

export type TuplesProps = {
  loading: boolean
  items: TupleInfoType[]
  checkedTuples: string[]
  setCheckedTuples: Dispatch<SetStateAction<string[]>>
}

const useStyles = makeStyles()((theme) => ({
  icon: {
    fontSize: 15
  },
  input: {
    marginRight: theme.spacing(0.5),
    maxWidth: `calc(100% - 39px)`,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1,
    zIndex: 2
  },
  icons: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: 39,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1
  }
}))

export const Heading = ({ openDialog, isDialogDisabled }: { openDialog: () => void; isDialogDisabled: boolean }) => {
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const { classes } = useStyles()
  const isOutlook = useOutlook()
  const hasSidebar = isOutlook || isSidepanel()

  return (
    <Card elevation={0} square className={autoHideClassName} sticky={hasSidebar ? 88 : 61}>
      <CardContent>
        <Box display="flex">
          <SearchInput placeholder="Search tuples" variant="collapsed" wrapperClassName={classes.input} opened />
          <Box className={classes.icons}>
            <AudiTupleInvalidDialog.TriggerEl classes={{ root: classes.icon }} disabled={isDialogDisabled} open={openDialog} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

const TuplesList = (props: TuplesProps) => {
  const match = useRouteMatch()
  const { teamContextValue } = useContext(TeamContext)
  const { reader } = useUserMembership(teamContextValue.teamNumber)
  const { classes } = useStyles()

  const toggleCheck = (e: ChangeEvent<HTMLInputElement>, uid: string) => {
    if (e.target.checked) {
      props.setCheckedTuples((prev) => [...prev, uid])
    } else {
      props.setCheckedTuples((prev) => prev.filter((id) => id !== uid))
    }
  }

  const items = props.items.map(({ lowIdentifier, highIdentifier }) => {
    const id1 = lowIdentifier.text
    const id2 = highIdentifier.text
    const uid = `${id1} = ${id2}`

    return {
      id1,
      id2,
      disabled: typeof reader !== 'boolean' || reader,
      checked: props.checkedTuples.includes(uid),
      toggleCheck: (e: ChangeEvent<HTMLInputElement>) => toggleCheck(e, uid),
      actions: (
        <IconButton<typeof Link>
          component={Link}
          to={{ pathname: `${match.url}/sources/${encodeURIComponent(id1)}/${encodeURIComponent(id2)}` }}
          classes={{ root: classes.icon }}
          icon={['fas', 'list-tree']}
          hint="Sources"
          color="primary"
        />
      )
    }
  })

  return <Repeater direction="vertical" variant="list" component={AssociationListItem} skeleton={{ size: 5, loading: props.loading }} items={items} />
}

export default TuplesList
