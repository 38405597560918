import React, { SyntheticEvent, useCallback, useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Combobox from '_shared/forms/Combobox'

import CreateCompanyOptionDialog, {
  CompanyOptionType,
  renderOption,
  SuggestCompanyOptionType,
  useCreateCompanyOption
} from '_core/components/dialogs/CreateCompanyOptionDialog'
import Empty from '_core/components/Empty'
import FormLayout, { FormLayoutActions, FormLayoutContent } from '_core/components/FormLayout'
import { transformCompanyOption } from '_core/components/introductions/options'
import { Narrow } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import useDialog from '_core/hooks/useDialog'
import { useLookUpCompanies } from '_core/hooks/useLookup'
import useSuggestOptionCreation from '_core/hooks/useSuggestOptionCreation'

const useStyles = makeStyles()((theme) => ({
  list: {
    marginLeft: `-${theme.spacing(2)}`,
    marginRight: `-${theme.spacing(2)}`
  }
}))

const AddPrivilegedCompanies = (props: { handleSave: (addedCompanies: CompanyOptionType[]) => void }) => {
  const [isSaving, setSaving] = useState(false)
  const { lookupCompanies, forceAbort } = useLookUpCompanies()
  const {
    inputValue,
    value,
    open,
    options,
    optionsLoading,
    handleClose,
    handleOpen,
    handleFocus,
    handleInputChange,
    handleValueChange,
    updateValue: updateCompanies
  } = useAsyncCombobox<CompanyOptionType, true>({
    initialValue: [],
    loadOptions: useCallback(
      async (searchTerm) => {
        const result = await lookupCompanies(searchTerm)
        if (result) {
          return result.map((company) => ({ name: company.CompanyNameText || company.BestUrlText, website: company.BestUrlText }))
        }
      },
      [lookupCompanies]
    ),
    forceAbort
  })
  const companies = value || []
  const companiesWebsites = companies.map((company) => company.website)
  const filterOptions = (options: CompanyOptionType[]) =>
    options.filter((option) => option.name && option.website && !companiesWebsites.includes(option.website))
  const filterWithSuggest = useSuggestOptionCreation<CompanyOptionType, SuggestCompanyOptionType>({
    loading: optionsLoading,
    filterFn: filterOptions,
    transformOption: transformCompanyOption
  })
  const { openDialog, closeDialog, isDialogOpened } = useDialog(false)
  const { closeCreateOptionDialog, createdOption, setCreatedOption } = useCreateCompanyOption(closeDialog)
  const { classes } = useStyles()

  const handleSave = () => {
    setSaving(true)
    props.handleSave(companies)
  }

  const removeCompany = (removalWebsite: string) => {
    updateCompanies(
      companies?.filter(({ website }) => {
        return website.toLowerCase() !== removalWebsite.toLowerCase()
      })
    )
  }

  const createCompanyOptionSubmit = (createdOption: CompanyOptionType) => {
    updateCompanies([
      ...companies,
      {
        name: createdOption.name,
        website: createdOption.website
      }
    ])
    closeCreateOptionDialog()
  }

  const handleCompanySelect = (e: SyntheticEvent<Element, Event>, companies: CompanyOptionType[] | SuggestCompanyOptionType[]) => {
    const company = companies[companies.length - 1]
    if (company) {
      if ('label' in company) {
        openDialog()
        setCreatedOption({
          name: company.name,
          website: company.website
        })
      } else {
        handleValueChange(e, companies)
        close()
      }
    }
  }

  const items = companies.map((company) => {
    return {
      name: company.name,
      logoUrl: company.website,
      byline: company.website,
      icons: (
        <IconButton
          hint="Remove"
          icon={['far', 'times'] as IconProp}
          disabled={isSaving}
          onClick={() => removeCompany(company.website)}
          size="small"
          disablePR
        />
      )
    }
  })

  return (
    <Narrow>
      <FormLayout>
        <FormLayoutContent>
          <Combobox<CompanyOptionType, true, boolean>
            multiple
            autoFocus
            open={open}
            inputValue={inputValue}
            loading={optionsLoading}
            options={options}
            value={companies}
            filterOptions={filterWithSuggest}
            placeholder="Add company"
            onChange={handleCompanySelect}
            onInputChange={handleInputChange}
            onClose={handleClose}
            onOpen={handleOpen}
            onFocus={handleFocus}
            getOptionLabel={(option) => option.name}
            renderOption={renderOption}
          />

          <Repeater
            direction="vertical"
            variant="list"
            component={ProfileItem}
            skeleton={{ loading: false }}
            items={items}
            className={classes.list}
            empty={
              <Box mt={2}>
                <Empty subTitle="No companies" icon={<FontAwesomeIcon size="3x" icon={['fat', 'buildings']} style={{ color: '#A7A7A7' }} />} />
              </Box>
            }
          />
        </FormLayoutContent>
        <CreateCompanyOptionDialog opened={isDialogOpened} close={closeCreateOptionDialog} submit={createCompanyOptionSubmit} value={createdOption} />
        <FormLayoutActions>
          <Button variant="text" onClick={handleSave} disabled={!companies.length || isSaving} disablePR>
            Save
          </Button>
        </FormLayoutActions>
      </FormLayout>
    </Narrow>
  )
}

export default AddPrivilegedCompanies
