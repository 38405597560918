import React, { ComponentProps, Fragment, ReactElement, useCallback, useContext, useEffect, useMemo } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Box, useTheme } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { matchPath, Route, Switch, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import RelationshipActivitiesPage from '_pages/relationships/[userId]/companies/[companyId]/activities'
import RelationshipActivityStatsPage from '_pages/relationships/[userId]/companies/[companyId]/activityStats'
import RelationshipContactsPage from '_pages/relationships/[userId]/companies/[companyId]/contacts'

import { TeamContext } from '_core/context/TeamContext'

import Avatar from '_shared/Avatar'
import { IconButton } from '_shared/buttons'
import Card, { CardContent } from '_shared/Card'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import Dashboard, { DashboardLinkType } from '_core/components/Dashboard'
import { PickAColleagueRelationshipDialog, PickACompanyRelationshipDialog } from '_core/components/dialogs/RelationshipPicker'
import Empty from '_core/components/Empty'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import PrivateRoute from '_core/components/PrivateRoute'
import Line, { AnchorLinkLine, LinkLine } from '_core/components/ProfileLine'
import ProfileSummary from '_core/components/ProfileSummary'
import RelationshipScore from '_core/components/RelationshipScore'
import Topbar from '_core/components/Topbar'
import ActivityStatsWidget from '_core/components/widgets/ActivityStats'
import ContactsWidget from '_core/components/widgets/Contacts'
import QuickFactsWidget from '_core/components/widgets/QuickFacts'
import TouchpointsWidget from '_core/components/widgets/Touchpoints'

import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useActivitiesStats from '_core/hooks/useActivitiesStats'
import useActivitiesStatsUserSettings from '_core/hooks/useActivitiesStatsUserSettings'
import useActivityStatsPeriod from '_core/hooks/useActivityStatsPeriod'
import useCompanyActivitiesPayloads from '_core/hooks/useCompanyActivitiesPayloads'
import useCompanyMarketData from '_core/hooks/useCompanyMarketData'
import useDialog from '_core/hooks/useDialog'
import useMarketDataIntegration from '_core/hooks/useMarketDataIntegration'
import usePersonMarketData from '_core/hooks/usePersonMarketData'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useTouchpointRelations from '_core/hooks/useTouchpointRelations'

import DynamicEntity from '_core/DynamicEntity'
import { stringifyUrl } from '_core/helpers/browser'
import { addMissedProtocol } from '_core/helpers/string'
import UserSettings from '_core/UserSettings'

import { getUTC } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const url = Paths._relationships

const useStyles = makeStyles()((theme) => ({
  summary: {
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2)
    }
  }
}))

const RelationHeader = (
  props: {
    loading: boolean
    enabled?: MarketDataIntegration['enabled']
  } & UserCompanyType
) => {
  const { dialogContentProps, openDialog, closeDialog } = useDialog<{ type: 'user' | 'company' }>()
  const { type } = dialogContentProps || {}

  const { classes } = useStyles()

  const {
    enabled: enabledMarketData,
    UserBestEmailAddress = '',
    UserFullName = '',
    UserKeyMd5,
    Score,
    CompanyNameText = '',
    CompanyMd5,
    BestUrlText,
    Introducers
  } = props || {}

  const {
    IntroducerBestJobTitleText,
    IntroducerBestJobMatchedCompanyName,
    IntroducerBestJobCorpLevelCompanyName,
    IntroducerBestJobCompanyMd5,
    IntroducerBestPhoneText
  } = Introducers?.data[0] || {}

  const companyName = CompanyNameText || BestUrlText

  const { marketData: userMarketData, getMarketData: getUserMarketData } = usePersonMarketData(UserFullName, UserBestEmailAddress)
  const { marketData: companyMarketData, getMarketData: getCompanyMarketData } = useCompanyMarketData(companyName, BestUrlText)

  const { linkedinUrl: userLinkedinUrl, githubUrl: userGithubUrl } = userMarketData || {}
  const { linkedinUrl: companyLinkedinUrl, twitterUrl: companyTwitterUrl, facebookUrl: companyFacebookUrl } = companyMarketData || {}

  useEffect(() => {
    if (!props.loading && enabledMarketData && !userMarketData) {
      getUserMarketData()
    }
  }, [enabledMarketData, userMarketData, props.loading, getUserMarketData])

  useEffect(() => {
    if (!props.loading && enabledMarketData && !companyMarketData) {
      getCompanyMarketData()
    }
  }, [enabledMarketData, companyMarketData, props.loading, getCompanyMarketData])

  const pickAColleageOpen = () => {
    openDialog({ type: 'user' })
  }

  const pickACompanyOpen = () => {
    openDialog({ type: 'company' })
  }

  return (
    <>
      <Card elevation={0} className={classes.summary}>
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Wide>
              <ProfileSummary
                userKey={UserBestEmailAddress}
                title={UserFullName || ''}
                loading={props.loading}
                introducers={null}
                profileLink={`${Paths._people}/${UserKeyMd5}`}
                actions={[
                  (props.loading || IntroducerBestPhoneText) && (
                    <IconButton<'a'>
                      component="a"
                      icon={['far', 'phone']}
                      color="primary"
                      hint={`Make a call to ${IntroducerBestPhoneText}`}
                      disablePadding
                      href={`tel:${IntroducerBestPhoneText?.substring(2)}`}
                      loading={!IntroducerBestPhoneText}
                      size="small"
                    />
                  ),
                  (props.loading || UserBestEmailAddress) && (
                    <IconButton<'a'>
                      component="a"
                      icon={['far', 'envelope']}
                      color="primary"
                      hint={`Send an email to ${UserBestEmailAddress}`}
                      disablePadding
                      href={`mailto:${UserBestEmailAddress}`}
                      loading={!UserBestEmailAddress}
                      size="small"
                    />
                  ),
                  (props.loading || userLinkedinUrl || UserFullName) && (
                    <IconButton<'a'>
                      component="a"
                      icon={['fab', 'linkedin']}
                      color="primary"
                      hint="Show Linkedin profile"
                      disablePadding
                      href={
                        userLinkedinUrl
                          ? addMissedProtocol(userLinkedinUrl)
                          : `https://www.linkedin.com/search/results/people/?keywords=${UserFullName}`
                      }
                      loading={props.loading}
                      rel="noopener noreferrer"
                      target="_blank"
                      size="small"
                    />
                  ),
                  ((enabledMarketData && !userMarketData) || (!props.loading && userGithubUrl)) && (
                    <IconButton<'a'>
                      component="a"
                      icon={['fab', 'github']}
                      color="primary"
                      hint="Show Github profile"
                      disablePadding
                      href={userGithubUrl ? addMissedProtocol(userGithubUrl) : ''}
                      loading={!userGithubUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      size="small"
                    />
                  )
                ].filter((action): action is ReactElement => !!action)}
                byline={<Line icon={['fas', 'user-tie']} loading={props.loading} value={IntroducerBestJobTitleText} />}
                byline2={
                  <LinkLine
                    icon={['fas', 'building']}
                    loading={props.loading}
                    value={IntroducerBestJobMatchedCompanyName || IntroducerBestJobCorpLevelCompanyName}
                    to={`${Paths._companies}/${IntroducerBestJobCompanyMd5}`}
                  />
                }
              />
            </Wide>
            <Narrow>
              <Avatar name={UserFullName || ''} size="md" link={`${Paths._people}/${UserKeyMd5}`} userKey={UserBestEmailAddress} />
            </Narrow>
            <Box display="flex" alignItems="center" p={2}>
              <PickAColleagueRelationshipDialog.TriggerEl disabled={props.loading} open={pickAColleageOpen} />
            </Box>
            <RelationshipScore score={Score} />
            <Box display="flex" alignItems="center" p={2}>
              <PickACompanyRelationshipDialog.TriggerEl disabled={props.loading} open={pickACompanyOpen} />
            </Box>
            <Wide>
              <ProfileSummary
                flip
                url={BestUrlText}
                title={companyName}
                loading={props.loading}
                introducers={null}
                profileLink={`${Paths._companies}/${CompanyMd5}`}
                byline={
                  <AnchorLinkLine
                    icon={['far', 'globe']}
                    loading={props.loading}
                    value={BestUrlText}
                    hint="Visit website"
                    href={BestUrlText ? addMissedProtocol(BestUrlText) : ''}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                }
                actions={[
                  (props.loading || companyLinkedinUrl || CompanyNameText) && (
                    <IconButton<'a'>
                      component="a"
                      icon={['fab', 'linkedin']}
                      color="primary"
                      hint="Show Linkedin profile"
                      disablePadding
                      href={
                        companyLinkedinUrl
                          ? addMissedProtocol(companyLinkedinUrl)
                          : `https://www.linkedin.com/search/results/companies/?keywords=${CompanyNameText}`
                      }
                      loading={props.loading}
                      rel="noopener noreferrer"
                      target="_blank"
                      size="small"
                    />
                  ),
                  ((enabledMarketData && !companyMarketData) || (!props.loading && companyTwitterUrl)) && (
                    <IconButton<'a'>
                      component="a"
                      icon={['fab', 'twitter']}
                      color="primary"
                      hint="Show Twitter profile"
                      disablePadding
                      href={companyTwitterUrl ? addMissedProtocol(companyTwitterUrl) : ''}
                      loading={!companyTwitterUrl}
                      rel="noopener noreferrer"
                      target="_blank"
                      size="small"
                    />
                  ),
                  ((enabledMarketData && !companyMarketData) || (!props.loading && companyFacebookUrl)) && (
                    <IconButton<'a'>
                      component="a"
                      icon={['fab', 'facebook']}
                      color="primary"
                      hint="Show Facebook profile"
                      disablePadding
                      href={companyFacebookUrl ? addMissedProtocol(companyFacebookUrl) : ''}
                      loading={!companyFacebookUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      size="small"
                    />
                  )
                ].filter((action): action is ReactElement => !!action)}
              />
            </Wide>
            <Narrow>
              <Avatar name={companyName || ''} size="md" link={`${Paths._companies}/${CompanyMd5}`} logoUrl={BestUrlText} />
            </Narrow>
          </Box>
        </CardContent>
      </Card>
      <PickAColleagueRelationshipDialog isOpened={type === 'user'} close={closeDialog} />
      <PickACompanyRelationshipDialog isOpened={type === 'company'} close={closeDialog} />
    </>
  )
}

const ActivityStats = ({
  isDetailedActivityFromStatsWidgetVisible,
  companyMd5,
  introducerMd5,
  onChange
}: {
  isDetailedActivityFromStatsWidgetVisible: boolean
  introducerMd5: string
  companyMd5: string
  onChange: (params: ActivityStatsPageParams<GridParams>) => void
}) => {
  const { from, to } = useParams<{ from: string; to: string }>()

  const statsPayloads = useCompanyActivitiesPayloads(
    useMemo(() => [companyMd5], [companyMd5]),
    introducerMd5
  )

  const { queryParams } = useSearchQuery<ActivityStatsPageParams<GridParams>>()
  const { stackColumns = 'false', from: fromUTC, to: toUTC } = queryParams

  const chartData = useActivityStatsPeriod({
    fromUTC: fromUTC ? getUTC(decodeURIComponent(fromUTC)) : null,
    toUTC: toUTC ? getUTC(decodeURIComponent(toUTC)) : null
  })

  const { stats, clearStats } = useActivitiesStats(
    introducerMd5 ? statsPayloads : null,
    useMemo(() => [companyMd5], [companyMd5]),
    useMemo(() => chartData.months.map(({ year, month, minDay, maxDay }) => ({ year, month, minDay, maxDay })), [chartData.months])
  )

  useEffect(() => {
    if (!introducerMd5 || !companyMd5) {
      clearStats()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [introducerMd5, companyMd5])

  const updatePeriod = (period: readonly [MomentType, MomentType]) => {
    onChange({ from: period[0].toISOString(), to: period[1].toISOString() })
  }

  const toggleStack = () => {
    onChange({ stackColumns: `${!JSON.parse(stackColumns)}` })
  }

  return (
    <ActivityStatsWidget
      isDetailedActivityFromStatsWidgetVisible={isDetailedActivityFromStatsWidgetVisible}
      stats={stats}
      stack={stackColumns === 'true'}
      detailsLink={`${Paths._relationships}/${from}/companies/${to}/activities`}
      seeAllLink={`${Paths._relationships}/${from}/companies/${to}/activityStats`}
      updatePeriod={updatePeriod}
      toggleStack={toggleStack}
      {...chartData}
    />
  )
}

const RelationshipDetails = (
  props: {
    loading: boolean
    isStatsWidgetVisible: boolean
    isDetailedActivityFromStatsWidgetVisible: boolean
    relationships: { data: UserCompanyContactListItem[]; total_item_count: number } | undefined
    former: { data: UserCompanyContactListItem[]; total_item_count: number } | undefined
  } & UserCompanyType
) => {
  const theme = useTheme()
  const wideStrict = useWide('lg')
  const { from, to } = useParams<{ from: string; to: string }>()

  const { relationships, former, isStatsWidgetVisible, isDetailedActivityFromStatsWidgetVisible, loading, ...main } = props

  const { queryParams } = useSearchQuery<ActivityStatsPageParams<GridParams>>()
  const { stackColumns } = queryParams

  const { setInitial, handleChange } = useActivitiesStatsUserSettings()

  const { Stats, CompanyMd5 = '', CompanyNameText = '', BestUrlText = '' } = main

  const { UserKeyMd5 = '', ...restStatsData } = Stats || {}

  const userKeyMd5Keys: (keyof StatsType<{ CompanyMd5: string }>)[] = [
    'LastInboundMsgUserKeyMd5',
    'LastActivityUserKeyMd5',
    'LastOutboundMsgUserKeyMd5',
    'LastMeetingUserKeyMd5'
  ]

  const stats = {
    ...restStatsData,
    ...userKeyMd5Keys.reduce((acc, key) => ({ ...acc, [key]: UserKeyMd5 }), {})
  }

  const latestTouchpoints = useTouchpointRelations(stats, 'Last')

  if (!props.loading && (!CompanyMd5 || !relationships?.data.length)) return <Empty title="No relationship details found" />

  const ActivitiesStatsWidgetEl = (
    <ActivityStats
      isDetailedActivityFromStatsWidgetVisible={isDetailedActivityFromStatsWidgetVisible}
      introducerMd5={UserKeyMd5}
      companyMd5={CompanyMd5}
      onChange={handleChange}
    />
  )

  const LatestInteractionsWidgetEl = <TouchpointsWidget titlePrefix="Latest" loading={props.loading} items={latestTouchpoints || []} />

  const QuickFactsWidgetEl = (
    <QuickFactsWidget
      loading={props.loading}
      stats={stats}
      totalRelationships={relationships?.total_item_count}
      totalFormerAffiliations={former?.total_item_count}
    />
  )

  const TopContactsWidgetEl = (
    <ContactsWidget
      loading={props.loading}
      total={relationships?.total_item_count}
      items={
        relationships?.data?.slice(0, 5).map((contact) => ({
          name: contact.PersonNameText || 'No name',
          score: contact.UserKnowsPersonScore,
          link: `${Paths._people}/${contact.PersonMd5}`,
          sidepanel: true
        })) || []
      }
      link={stringifyUrl(`${Paths._relationships}/${from}/companies/${to}/contacts`, { from: queryParams.from, to: queryParams.to })}
      entityName={CompanyNameText || BestUrlText}
    />
  )

  const columnsRows = [
    {
      condition: props.isStatsWidgetVisible,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [LatestInteractionsWidgetEl]]
            },
            { columns: [[QuickFactsWidgetEl], [TopContactsWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[LatestInteractionsWidgetEl], [QuickFactsWidgetEl]] },
            { columns: [[TopContactsWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !props.isStatsWidgetVisible,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[LatestInteractionsWidgetEl], [QuickFactsWidgetEl], [TopContactsWidgetEl]]
            }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [{ columns: [[LatestInteractionsWidgetEl], [QuickFactsWidgetEl]] }, { columns: [[TopContactsWidgetEl]] }]
        }
      ]
    }
  ]

  const colRows = (columnsRows.find(({ condition }) => !!condition) || columnsRows[0]).rows.find(({ condition }) => condition)?.colRows

  return (
    <UserSettings endpoint={!stackColumns && isStatsWidgetVisible ? '/usersettings/activitiesstats' : ''} setInitial={setInitial}>
      <Wide>
        {colRows?.map(({ columns }, k) => (
          <Columns key={k}>
            {columns.map((column, i) => {
              if (!Array.isArray(column)) {
                return (
                  <Column md={12} key={`${k}${i}`}>
                    {column}
                  </Column>
                )
              }
              return (
                <Column key={`${k}${i}`} two={!wideStrict} double={!!column.find((r) => Array.isArray(r))}>
                  {column.map((row, j) =>
                    Array.isArray(row) ? (
                      <Columns
                        key={`${k}${i}${j}`}
                        md={12}
                        minWidth={`calc(100% + ${theme.spacing(2)})`}
                        {...(!row.length ? { display: 'none' } : {})}
                      >
                        {row.map((r, y) => (
                          <Column md={6} key={`${k}${i}${j}${y}`}>
                            {r}
                          </Column>
                        ))}
                      </Columns>
                    ) : (
                      <Fragment key={`${k}${i}${j}`}>{row}</Fragment>
                    )
                  )}
                </Column>
              )
            })}
          </Columns>
        ))}
      </Wide>
      <Narrow>
        <RelationHeader loading={props.loading} {...main} />
        {isStatsWidgetVisible && ActivitiesStatsWidgetEl}
        {LatestInteractionsWidgetEl}
        {QuickFactsWidgetEl}
        {TopContactsWidgetEl}
      </Narrow>
    </UserSettings>
  )
}

const RelationshipUserCompany = ({ loading, forceNarrow, ...relProps }: UserCompanyType & { loading: boolean; forceNarrow: boolean }) => {
  const { pathname } = useLocation()
  const { from, to } = useParams<{ from: string; to: string }>()
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const wide = useWide()
  const { setMobileHeader } = useContext(LayoutContext)
  const { enabled } = useMarketDataIntegration()

  useEffect(() => {
    setMobileHeader('Relationship details')
  }, [setMobileHeader])

  const activitiesPath = `${url}/:from/:via/:to/activities`
  const activityStatsPath = `${url}/:from/:via/:to/activityStats`
  const { isExact: matchActivities = false } = matchPath(pathname, { path: [activitiesPath, activityStatsPath] }) || {}
  const { userActivitiesAccess } = useActivitiesAccess([from, to, matchActivities]) || {}
  const { isStatsWidgetVisible, isDetailedActivityFromStatsWidgetVisible } = userActivitiesAccess || {}

  const { UserFullName, CompanyNameText, BestUrlText } = relProps || {}

  const companyName = CompanyNameText || BestUrlText

  const dashboardLinks: DashboardLinkType[] = [
    {
      name: 'Dashboard',
      link: `${url}/${from}/companies/${to}`,
      icon: ['far', 'th'] as IconProp
    },
    {
      name: 'Activity statistics',
      link: `${url}/${from}/companies/${to}/activityStats`,
      condition: isStatsWidgetVisible,
      icon: ['far', 'chart-simple'] as IconProp
    },
    {
      name: 'Contacts',
      link: `${url}/${from}/companies/${to}/contacts`,
      icon: ['far', 'user'] as IconProp
    }
  ].filter(({ condition }) => (typeof condition === 'boolean' ? condition : true))

  const contentMemoUrls = useMemo(() => {
    return [
      {
        key: 'relationships',
        url: `/users/${from}/companies/${to}/people?TeamNumber=${teamNumber}&Take=${wide ? 4 : 3}&SortScoreType=PersonalScore&SortBy=ScoreDesc`
      },
      { key: 'former', url: `/users/${from}/companies/${to}/people?TeamNumber=${teamNumber}&Take=1&EmployeeType=Former` }
    ]
  }, [from, to, wide, teamNumber])

  const content = useCallback(
    (props: ComponentProps<typeof RelationshipDetails>) => <RelationshipDetails {...props} loading={props.loading || loading} />,
    [loading]
  )

  const sub = UserFullName && companyName ? `${UserFullName} and ${companyName}` : ''

  const hasAccessToPrivateRoutes = isStatsWidgetVisible && isDetailedActivityFromStatsWidgetVisible

  const subPagesTopBar = (
    <Narrow>
      <Topbar
        nativeBack
        autoHideOnScroll
        title={relProps.UserFullName ? `${relProps.UserFullName} and ${companyName}` : ''}
        loading={!relProps.UserFullName}
      />
    </Narrow>
  )

  return (
    <>
      <Wide>
        <RelationHeader {...relProps} enabled={enabled} loading={loading} />
      </Wide>
      <Dashboard loading={loading} links={dashboardLinks}>
        <Switch>
          <Route exact path={`${url}/:from/:via/:to`}>
            <Narrow>
              <Topbar
                sub={
                  <Skeleton condition={!sub} width={96}>
                    <Typography title={sub} variant="body1" style={{ maxWidth: '100%' }} noWrap>
                      {sub || 'placeholder'}
                    </Typography>
                  </Skeleton>
                }
              />
            </Narrow>
            <DynamicEntity<{
              extraProps: {
                addprops: Pick<ComponentProps<typeof RelationshipDetails>, 'isStatsWidgetVisible' | 'isDetailedActivityFromStatsWidgetVisible'>
              }
            }>
              urls={contentMemoUrls}
              component={content}
              nativeBack={true}
              id="rel_details_narrow"
              keepMounted
              addprops={{
                isStatsWidgetVisible: !!isStatsWidgetVisible,
                isDetailedActivityFromStatsWidgetVisible: !!isDetailedActivityFromStatsWidgetVisible,
                ...relProps
              }}
            />
          </Route>
          <Route>
            <Switch>
              <PrivateRoute hasAccess={hasAccessToPrivateRoutes} path={[activitiesPath, activityStatsPath]}>
                {subPagesTopBar}
                <Switch>
                  <Route exact path={activitiesPath} component={RelationshipActivitiesPage} />
                  <Route exact render={() => <RelationshipActivityStatsPage companyName={companyName} />} />
                </Switch>
              </PrivateRoute>
              <Route exact path={`${url}/:from/:via/:to/contacts`}>
                {subPagesTopBar}
                <RelationshipContactsPage />
              </Route>
            </Switch>
          </Route>
        </Switch>
      </Dashboard>
    </>
  )
}

const RelationshipUserCompanyPage = () => {
  const { from, to } = useParams<{ from: string; to: string }>()
  const wide = useWide()

  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue

  return (
    <DynamicEntity
      url={`/users/${from}/companies/${to}?TeamNumber=${teamNumber}&Take=${wide ? 4 : 3}&IncludeStats=True`}
      component={RelationshipUserCompany}
      nativeBack={true}
      id="rel_details"
      keepMounted
    />
  )
}

export default RelationshipUserCompanyPage
