import React, { PropsWithChildren, useEffect, useRef } from 'react'

import { useLocation } from 'react-router-dom'

import useAbortableFetch from '_core/hooks/useAbortableFetch'

type UserSettingsType<T> = {
  endpoint: string
  setInitial: (result: T) => void
}

const UserSettings = <T,>({ endpoint, setInitial, children }: PropsWithChildren<UserSettingsType<T>>) => {
  const { search } = useLocation()
  const initialized = useRef<boolean>(false)
  const { fetchWithAbort } = useAbortableFetch<T>()

  useEffect(() => {
    const makeCall = async () => {
      const result = await fetchWithAbort({ url: endpoint })
      if (result) {
        setInitial(result)
      }
    }
    if (!initialized.current && endpoint) {
      initialized.current = true
      makeCall()
    }
  }, [endpoint, fetchWithAbort, search, setInitial])

  return <>{children}</>
}

export default UserSettings
