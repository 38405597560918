export const addMissedProtocol = (url) => {
  const newUrl = url.replace(/^http:\/\//i, 'https://')

  if (!/^(?:f|ht)tps?:\/\//.test(newUrl)) {
    return `https://${url}`
  }
  return newUrl
}

export const getRidOfProtocol = (url) => {
  const newUrl = url.replace(/^http:\/\//i, 'https://')
  const result = (newUrl.match(/^(ht)tps?:\/\/(.*)/) || [])[2]
  return result
}

export const validLinkRegExp = /^[A-Z0-9.-]+\.[A-Z]{2,}(\/[A-Z0-9._%+-]*)*(\?[A-Z0-9&=._%+-]*)?$/i

export const validUrl = (url) => {
  return url && /^[A-Z0-9.-]+\.[A-Z]{2,}\/?$/i.test(url)
}

export const validEmail = (email) => {
  return typeof email === 'string' && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const getCharacterPosition = (str, ch, limit) => {
  return str?.split(ch, limit).join(ch)?.length
}

export const capitalizeWord = (word) => (word ? word[0].toUpperCase() + word.slice(1) : '')
