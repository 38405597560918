import React from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { GeneralSettingsData } from '_core/context/GeneralSettings'

import { IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import CopyButton from '_core/components/CopyButton'
import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import TitleDescription from '_core/components/TitleDescription'
import Widget, { SeeAllAction } from '_core/components/widgets'

import useEntityEndpoint from '_core/hooks/useEntityEndpoint'

import { formatDateTime } from 'utils/Utils'

type WidgetProps = {
  loading: boolean
  entity: 'person' | 'company'
  link: string
  name: string
  total: number
  keys: { sourceId: string; lastModified: Date }[]
}

const useStyles = makeStyles()(() => ({
  externalIcon: {
    fontSize: 12
  }
}))

const entityMap = {
  person: 'Contact',
  company: 'Account'
}

const ExternalKeysWidget = (props: WidgetProps) => {
  const { result, loading } = useEntityEndpoint<{ results: GeneralSettingsData } | null>('/usersettings/general')
  const { classes } = useStyles()
  const baseCrmUrl = result?.results?.baseCrmUrl
  const load = loading || props.loading

  const getSalesforceLink = (id: string) => {
    const cleanBaseUrl = baseCrmUrl?.endsWith('/') ? baseCrmUrl.slice(0, -1) : baseCrmUrl
    const entityType = entityMap[props.entity]

    if (cleanBaseUrl && entityType) {
      return `${cleanBaseUrl}/${entityType}/${id}/view`
    }
  }

  return (
    <Widget>
      <Heading underlined title="External keys" icon={['fas', 'share-alt']} count={props.total} action={<SeeAllAction link={props.link} />} />
      <Repeater
        component={TitleDescription}
        skeleton={{ size: 2, loading: load }}
        items={props.keys.map((key) => {
          const externalLink = getSalesforceLink(key.sourceId)
          return {
            title: '',
            description: (
              <Box display="grid" gridTemplateColumns="1fr auto" alignItems="space-between">
                <Skeleton condition={loading}>
                  <Typography noWrap>{key.sourceId}</Typography>
                </Skeleton>
                <Box display="flex" alignItems="center">
                  {externalLink && (
                    <IconButton<'a'>
                      component="a"
                      disablePY
                      hint={`Go to ${externalLink}`}
                      icon={['far', 'external-link']}
                      href={externalLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      classes={{ root: classes.externalIcon }}
                      size="small"
                    />
                  )}
                  <CopyButton text={key.sourceId} disablePR disablePY />
                </Box>
              </Box>
            ),
            blurb: key.lastModified ? formatDateTime(key.lastModified) : 'No date'
          }
        })}
      />
    </Widget>
  )
}

export default ExternalKeysWidget
