import { Box, useTheme } from '@mui/material'
import { AxisConfig, BarChart, BarChartProps, ChartsXAxisProps } from '@mui/x-charts'

import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

export const inboundColor = '#5B8FF9'
export const outboundColor = '#5AD8A6'
export const meetingsColor = '#5D7092'

export const ActivityStatsPeriodMsg = ({ title }: { title: string | undefined }) => {
  return (
    <Box sx={{ mb: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 } }} display="flex">
      <Typography>Activity stats shown are for</Typography>&nbsp;
      <Skeleton condition={!title}>
        <Typography semiBold component="span">
          {title || 'placeholder'}
        </Typography>
      </Skeleton>
    </Box>
  )
}

const ActivityStatsChart = ({
  loading,
  dataset,
  series,
  onAxisClick,
  height,
  stack
}: { stack: boolean } & Required<Pick<BarChartProps, 'loading' | 'dataset' | 'series' | 'onAxisClick' | 'height'>>) => {
  const theme = useTheme()

  return (
    <BarChart
      loading={loading}
      dataset={dataset}
      yAxis={[{ tickLabelStyle: { fill: theme.palette.text.secondary } }]}
      xAxis={
        dataset?.length
          ? [
              {
                tickLabelStyle: { fill: theme.palette.text.secondary, textTransform: 'none' },
                dataKey: 'chartLabel',
                scaleType: 'band',
                categoryGapRatio: stack ? 0.8 : 0.7,
                barGapRatio: 0.3
              } as AxisConfig<'band', unknown, ChartsXAxisProps> // workaround to fix mui ts bug
            ]
          : []
      }
      series={stack ? series.map((s) => ({ ...s, stack: 'total' })) : series}
      onAxisClick={onAxisClick}
      slotProps={{ legend: { hidden: true } }}
      grid={{ horizontal: true, vertical: true }}
      borderRadius={8}
      height={height}
      margin={{ top: 8, bottom: 24, left: 24, right: 0 }}
    />
  )
}

export default ActivityStatsChart
