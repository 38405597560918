import { useMemo } from 'react'

import { ActivitiesSettings } from '_core/components/settings/admin'

import DynamicEntity from '_core/DynamicEntity'

const ActivitiesSettingsPage = () => {
  const activitiesUrls = useMemo(
    () => [
      { key: 'settings', url: '/adminsettings/activities' },
      { key: 'profile', url: '/me/profile' }
    ],
    []
  )

  return <DynamicEntity urls={activitiesUrls} component={ActivitiesSettings} id="admin_activities" />
}

export default ActivitiesSettingsPage
