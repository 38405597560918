import { Button, IconButton } from '_shared/buttons'
import { IconButtonProps } from '_shared/buttons/Icon'
import Dialog, { DialogContent, DialogActions, DialogTitle } from '_shared/Dialog'
import Typography from '_shared/Typography'

const TriggerEl = ({
  open,
  classes,
  disabled,
  hint
}: {
  open: () => void
  classes?: IconButtonProps['classes']
  disabled?: boolean
  hint?: string
}) => (
  <IconButton
    disabled={disabled}
    onClick={open}
    classes={classes}
    icon={['far', 'ban']}
    color="primary"
    size="small"
    hint={hint || 'Mark the selected tuples as invalid'}
  />
)

const AudiTupleInvalidDialog = ({ isOpened, close, submit }: { isOpened: boolean; close: () => void; submit: () => void }) => {
  return (
    <Dialog open={isOpened} onClose={close} title={<DialogTitle title="Mark as invalid?" />}>
      <DialogContent>
        <Typography>Are you sure you want to mark the selected tuples as invalid?</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={close} color="secondary">
          No
        </Button>
        <Button variant="text" onClick={submit} disablePR>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AudiTupleInvalidDialog.TriggerEl = TriggerEl

export default AudiTupleInvalidDialog
