import { ChangeEvent, ReactElement } from 'react'

import { Box, ListItem } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Card, { CardContent } from '_shared/Card'
import Checkbox, { useStyles as useCheckboxStyles } from '_shared/forms/Checkbox'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

const useStyles = makeStyles()(() => ({
  content: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) auto',
    alignItems: 'center'
  },
  tuple: {
    flex: 1,
    marginRight: 0
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > p': {
      maxWidth: '100%'
    }
  }
}))

type AssociationItemProps = {
  id1: string
  id2: string
  source?: ReactElement
  actions?: ReactElement
}

export const AssociationItem = (props: AssociationItemProps) => {
  const { classes } = useStyles()

  return (
    <Card round variant="outlined">
      <CardContent classes={{ root: classes.content }}>
        <Box className={classes.centered}>
          <Skeleton condition={!props.id1}>
            <Typography noWrap>{props.id1 || 'long placeholder'}</Typography>
          </Skeleton>
          <Typography color="text.hint" noWrap>
            is associated with
          </Typography>
          <Skeleton condition={!props.id1 && !props.id2}>
            <Typography noWrap>{props.id2 || 'long placeholder'}</Typography>
          </Skeleton>
        </Box>
        {props.actions && <>{props.actions}</>}
      </CardContent>
      {props.source && <>{props.source}</>}
    </Card>
  )
}

const AssociationListItem = ({
  toggleCheck,
  ...props
}: AssociationItemProps & {
  checked: boolean
  toggleCheck: (e: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}) => {
  const { classes, cx } = useStyles()
  const { classes: checkboxClasses } = useCheckboxStyles({ align: 'top' })

  return (
    <ListItem dense>
      <Checkbox
        disabled={!props.id1 || props.disabled}
        checked={props.checked || false}
        onChange={toggleCheck}
        align="center"
        classes={{ ...checkboxClasses, root: cx(checkboxClasses.root, classes.tuple) }}
        label={<AssociationItem id1={props.id1} id2={props.id2} actions={props.actions} source={props.source} />}
      />
    </ListItem>
  )
}

export default AssociationListItem
