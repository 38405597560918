import { useMemo } from 'react'

import { CloudHubSettings } from '_core/components/settings/admin'

import DynamicEntity from '_core/DynamicEntity'

const CloudHubSettingsPage = () => {
  const cloudHubUrls = useMemo(
    () => [
      { key: 'marketDataIntegration', url: '/adminsettings/marketDataIntegration' },
      { key: 'isConfigured', url: '/cloudhub/configured' },
      { key: 'dotAlignCloudHub', url: '/adminsettings/dotAlignCloudHub' }
    ],
    []
  )

  return <DynamicEntity urls={cloudHubUrls} component={CloudHubSettings} id="admin_settings_cloudhub" />
}

export default CloudHubSettingsPage
