import Card, { CardContent } from '_shared/Card'

import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import SearchInput from '_core/components/SearchInput'

type IdentifierContributorsProps = {
  loading: boolean
  items?: { [key: string]: string }[]
}

const lowercaseKeys = (arr: { [key: string]: any }[]) =>
  arr.map((obj) => Object.keys(obj).reduce((acc, key) => ({ ...acc, [key.toLowerCase()]: obj[key] }), {}))

const ContributorSearch = () => {
  return (
    <Card elevation={0} square>
      <CardContent>
        <SearchInput placeholder="Search for contributors" variant="collapsed" opened />
      </CardContent>
    </Card>
  )
}

const IdentifierContributors = (props: IdentifierContributorsProps) => {
  const { loading, items } = props

  return (
    <Repeater
      disablePadding
      variant="list"
      items={items ? lowercaseKeys(items) : []}
      component={(props: { userkey: string }) => <ProfileItem name={props.userkey} userKey={props.userkey} />}
      skeleton={{ size: 5, loading }}
    />
  )
}

IdentifierContributors.Search = ContributorSearch

export default IdentifierContributors
