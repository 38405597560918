import { ComponentProps } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import TitleDescription from '_core/components/TitleDescription'
import Widget, { SeeAllAction } from '_core/components/widgets'

import useStyles from './styles'

const KeyTouchpointsWidget = (props: {
  loading: boolean
  link: string
  entityName: string
  total?: number
  items: ComponentProps<typeof TitleDescription>[]
}) => {
  const { classes } = useStyles()

  const { loading, link, entityName, total, items } = props

  return (
    <Widget className={classes.widget}>
      <Heading underlined title="Key touch points" icon={['far', 'handshake']} count={total} action={<SeeAllAction link={total ? link : ''} />} />
      {(!!total || loading) && <Repeater component={TitleDescription} skeleton={{ size: 3, loading }} items={items || []} />}
      {!total && !loading && (
        <Empty
          className={classes.empty}
          subTitle={`You have no touchpoints with ${entityName}`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'handshake']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

export default KeyTouchpointsWidget
