import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { ExpandableContent, ExpandableDetailsProps, ItemsType } from '_core/components/ExpandableDetails'

import { formatDateTime, formatDateTimeFromNow } from 'utils/Utils'

const StatsContent = ({
  isExpandedView,
  sortByField,
  lastInbound,
  lastOutbound,
  lastMeeting,
  nextFutureMeeting,
  inbound,
  outbound,
  meetings
}: {
  lastInbound?: string
  lastOutbound?: string
  lastMeeting?: string
  nextFutureMeeting?: string
  inbound?: number | null
  outbound?: number | null
  meetings?: number | null
} & Pick<ExpandableDetailsProps, 'sortByField' | 'isExpandedView'>) => {
  const formatDate = (value: string) => formatDateTimeFromNow(value, 5)

  const items: ItemsType = [
    {
      field: 'lastInboundMsg',
      value: lastInbound ? formatDate(lastInbound) : '',
      title: 'Last inbound',
      label: lastInbound ? formatDateTime(lastInbound) : '',
      icon: ['fas', 'message-arrow-down'] as IconProp,
      condition: lastInbound !== undefined
    },
    {
      field: 'lastOutboundMsg',
      value: lastOutbound ? formatDate(lastOutbound) : '',
      title: 'Last outbound',
      label: lastOutbound ? formatDateTime(lastOutbound) : '',
      icon: ['fas', 'message-arrow-up'] as IconProp,
      condition: lastOutbound !== undefined
    },
    {
      field: 'lastMeeting',
      value: lastMeeting ? formatDate(lastMeeting) : '',
      title: 'Last meeting',
      label: lastMeeting ? formatDateTime(lastMeeting) : '',
      icon: ['far', 'calendar-alt'] as IconProp,
      condition: lastMeeting !== undefined
    },
    {
      field: 'nextFutureMeeting',
      value: nextFutureMeeting ? formatDate(nextFutureMeeting) : '',
      title: 'Next meeting',
      icon: ['far', 'calendar-alt'] as IconProp,
      label: nextFutureMeeting ? formatDateTime(nextFutureMeeting) : '',
      condition: nextFutureMeeting !== undefined
    },
    {
      field: 'inbound',
      value: inbound,
      title: 'Inbound',
      icon: ['fas', 'message-arrow-down'] as IconProp,
      condition: inbound !== undefined
    },
    {
      field: 'outbound',
      value: outbound,
      title: 'Outbound',
      icon: ['fas', 'message-arrow-up'] as IconProp,
      condition: outbound !== undefined
    },
    {
      field: 'meetings',
      value: meetings,
      title: 'Meetings',
      icon: ['far', 'calendar-alt'] as IconProp,
      condition: meetings !== undefined
    }
  ].filter(({ condition }) => condition !== false)

  return <ExpandableContent items={items} isExpandedView={isExpandedView} sortByField={sortByField} />
}

export default StatsContent
