import { ChangeEvent, HTMLAttributes, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { Box, Divider } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import Combobox from '_shared/forms/Combobox'
import Typography from '_shared/Typography'

import { Range } from '_core/components/filters/controls/Period'
import ToggleControl from '_core/components/filters/controls/Toggle'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { useLookUpContributors, useLookUpContributorsByIds } from '_core/hooks/useLookup'

import { formatDate } from 'utils/Utils'

import Filters from './index'

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 270,
      overflow: 'auto',
      height: '100%'
    }
  },
  header: {
    marginBottom: theme.spacing(1)
  },
  listWrapper: {
    marginLeft: `-${theme.spacing(2)}`,
    marginRight: `-${theme.spacing(2)}`
  },
  divider: {
    margin: `${theme.spacing(0.5)} -${theme.spacing(2.5)} ${theme.spacing(1.5)}`
  },
  block: {
    marginBottom: theme.spacing(1.5),
    '& > .MuiTypography-h4:not(span), & > .MuiTypography-body1:not(span)': {
      marginBottom: theme.spacing(1)
    }
  }
}))

export const includeOptions: { name: IncludeMeetingsType; label: string }[] = [
  { name: 'includeRecurringMeetings', label: 'Include recurring meetings' }
]

export type MeetingsFiltersType = {
  opened: boolean
  total?: number
  disabled: boolean
  contentLoading: boolean
  toggleOpen: () => void
  reset: () => void
  anchorEl?: HTMLElement | null
  queryData: ModifiedMeetingsPageParams
  range: Pick<ModifiedMeetingsPageParams, 'from' | 'to'> | undefined
  handleChange: (params: ModifiedMeetingsPageParams) => void
  handleDateChange: ({ picker, value }: { picker: 'from' | 'to'; value: MomentType | null }) => void
}

type Option = {
  name: string
  emailAddress: string
  md5: string
}

const MeetingsFilters = (props: MeetingsFiltersType) => {
  const { id: contributorLevel } = useParams<{ id: string } | undefined>() || {}

  const { classes } = useStyles()
  const { opened, disabled, contentLoading, anchorEl, total, reset, queryData, handleChange, toggleOpen } = props
  const { lookupContributors: lookupContributorsOptions, forceAbort: contributorsOptionsForceAbort } = useLookUpContributors()
  const { lookUpContributorsByIds: getContributorsByIds, contributorsByIds } = useLookUpContributorsByIds()

  const [picked, setPicked] = useState<Option[]>()

  const pickedMd5s = useMemo(() => picked?.map(({ md5 }) => md5), [picked])

  const isReady = queryData.from

  useEffect(() => {
    if (isReady) {
      if (queryData.ids) {
        if (!picked) {
          getContributorsByIds(queryData.ids)
        }
      } else {
        setPicked([])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!queryData.ids])

  useEffect(() => {
    if (contributorsByIds && !picked) {
      setPicked(contributorsByIds?.map(({ fullName: name, primaryEmail: emailAddress, userKeyMd5: md5 }) => ({ name, emailAddress, md5 })))
    }
  }, [contributorsByIds, picked])

  const loadOptions = useCallback(
    async (searchTerm?: string, skip?: string[], take?: number) => {
      const resData = await lookupContributorsOptions(searchTerm, [...(queryData.ids || []), ...(skip || [])], take)
      return resData?.map((item) => ({ name: item.name, emailAddress: item.emailAddress, md5: item.userKeyMd5 }))
    },
    [lookupContributorsOptions, queryData.ids]
  )

  const { inputValue, open, options, optionsLoading, handleClose, handleOpen, handleFocus, handleInputChange, filterOptions } = useAsyncCombobox({
    loadOptions: useCallback((searchTerm?: string) => loadOptions(searchTerm, pickedMd5s), [loadOptions, pickedMd5s]),
    forceAbort: contributorsOptionsForceAbort
  })

  const handleSelect = (e: SyntheticEvent, value: Option[]) => {
    setPicked(value)
    handleChange({ ids: value.map(({ md5 }) => md5) })
  }

  const handleDeletePicked = (e: SyntheticEvent, md5: Option['md5']) => {
    e.stopPropagation()
    setPicked((prevState = []) => prevState.filter((picked) => picked.md5 !== md5))
    handleChange({ ids: queryData.ids?.filter((id) => id !== md5) })
  }

  const toggleInclude = (e: ChangeEvent<{ name: unknown }>) => {
    const { name } = e.target as { name: IncludeMeetingsType }
    const { checked = [] } = queryData
    handleChange({
      checked: checked.includes(name) ? checked.filter((v: IncludeMeetingsType) => v !== name) : [...checked, name]
    })
  }

  const handleDateError = ({ picker, reason, value }: { picker: 'from' | 'to'; reason: string | null; value: MomentType | null }) => {
    const val = value ? formatDate(value) : null
    if (queryData && queryData[picker] === val) {
      handleChange({ [picker]: !reason && value?.isValid() ? formatDate(value) : null })
    }
  }

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, value: Option) => (
    <li {...props}>
      <ProfileItem name={value.name} userKey={value.emailAddress} byline={value.emailAddress} />
    </li>
  )

  const contributors = useMemo(() => picked || [], [picked])

  return (
    <Filters
      opened={opened}
      className={classes.container}
      disabled={disabled}
      contentLoading={contentLoading}
      total={total}
      reset={reset}
      anchorEl={anchorEl || null}
      toggleOpen={toggleOpen}
      {...(contributorLevel ? { shift: 16 } : {})}
    >
      {!contributorLevel && (
        <Box className={classes.block}>
          <Typography variant="h4" semiBold className={classes.header}>
            Pick contributors
          </Typography>
          <Combobox<Option, true>
            multiple
            autoFocus
            open={open}
            disabled={disabled}
            inputValue={inputValue}
            loading={optionsLoading}
            options={options}
            value={contributors}
            placeholder="Search by name or email address"
            onChange={handleSelect}
            onInputChange={handleInputChange}
            onClose={handleClose}
            onOpen={handleOpen}
            onFocus={handleFocus}
            renderOption={renderOption}
            getOptionLabel={(option: Option) => option.name}
            filterOptions={filterOptions}
          />
          {picked && picked.length > 0 && (
            <Repeater
              variant="list"
              component={ProfileItem}
              items={picked.map(({ md5, name, emailAddress }) => ({
                name: name,
                userKey: emailAddress,
                byline: emailAddress,
                icons: (
                  <IconButton
                    icon={['far', 'times']}
                    size="small"
                    onClick={(e) => {
                      handleDeletePicked(e, md5)
                    }}
                  />
                )
              }))}
              skeleton={{ loading: false, size: 0 }}
            />
          )}
        </Box>
      )}
      <Box className={classes.block}>
        <Typography variant="h4" semiBold className={classes.header}>
          Date range
        </Typography>
        <Range
          from={props.range?.from}
          to={props.range?.to}
          handleDateChange={props.handleDateChange}
          handleDateError={handleDateError}
          disabled={disabled}
          focusTriggered={false}
        />
      </Box>
      <Divider className={classes.divider} />
      <ToggleControl value={queryData.checked || []} disabled={disabled} toggle={toggleInclude} options={includeOptions} />
    </Filters>
  )
}

export { Controller } from './index'
export default MeetingsFilters
