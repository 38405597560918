import React, { ForwardedRef, forwardRef } from 'react'

import { Card as MuiCard, CardContent, CardActionArea, CardActions, CardHeader, CardMedia, CardProps as MuiCardProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<{ sticky?: number }>()((theme, { sticky }) => ({
  round: {
    borderRadius: theme.spacing(1)
  },
  sticky: {
    top: sticky,
    position: 'sticky',
    zIndex: 100,
    background: theme.palette.background.light
  }
}))

type CardProps = Omit<MuiCardProps, 'classes'> & { round?: boolean; sticky?: number; className?: string }

const CardInner = ({ round, children, sticky, className, ...other }: CardProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { classes, cx } = useStyles({ sticky })

  return (
    <MuiCard ref={ref} classes={{ root: cx(className, { [classes.round]: round, [classes.sticky]: typeof sticky === 'number' }) }} {...other}>
      {children}
    </MuiCard>
  )
}

const Card = forwardRef(CardInner) as (props: CardProps & { ref?: ForwardedRef<HTMLDivElement> }) => ReturnType<typeof CardInner>

export { CardContent, CardActionArea, CardActions, CardHeader, CardMedia }
export default Card
