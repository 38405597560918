import { useEffect, useContext, useState, ReactElement, ComponentProps } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Divider } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Avatar from '_shared/Avatar'
import { Button, IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import DeleteMessageDialog from '_core/components/dialogs/DeleteMessage'
import { Columns, Column } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import NameLink from '_core/components/NameLink'
import TextContent from '_core/components/TextContent'
import InteractionCompaniesWidget from '_core/components/widgets/InteractionCompanies'
import InteractionParticipantsWidget from '_core/components/widgets/InteractionParticipants'

import useDialog from '_core/hooks/useDialog'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { stringifyUrl } from '_core/helpers/browser'
import { getCharacterPosition } from '_core/helpers/string'

import { del } from 'utils/httpUtils'
import { formatDateTime } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    '& .MuiGrid-item': {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  textContent: {
    '& button': {
      display: 'flex'
    }
  },
  iconsBlock: {
    display: 'flex'
  },
  heading: {
    lineHeight: '28px',
    maxWidth: '100%',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  participants: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& .MuiBox-root': {
      padding: 0
    }
  },
  externalIcon: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.25),
    fontSize: 13,
    '& svg': {
      opacity: 0.6
    }
  },
  more: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(0, 5)
  },
  center: {
    textAlign: 'center'
  },
  title: {
    marginBottom: 0
  },
  rightAlign: {
    marginLeft: 'auto'
  }
}))

export type ParticipantType = { email: string; name: string; addresseeRole: 'Cc' | 'To' | 'From' }

export type MessageDetailsProps = {
  participantsWithPeople: {
    participant: ParticipantType
    person: { [key: string]: string }
  }[]
  companies?: {
    companyMd5: string
    corpLevelCompanyName: string
    matchedUrlText?: string
  }[]
  participants: ParticipantType[]
  rawSubject: string
  body: string
  sourceKey: string
  senderEmail: string
  senderSmtpEmail: string
  returnPath: string
  senderName: string
  important: boolean
  lastModifiedDateTime: string
  receivedDateTime: string
  sentDateTime: string
  searchKey: string
  conversationIndex: string
  inetMsgId: string
  transportHeaders: { [key: string]: any }
  folderSourceKey: string
  sentRepresentingName: string
  sentRepresentingEmailAddress: string
  attachments: any[]
  delimCategory: string
  isDraft: boolean
  isValid: boolean
  webLink: string
  hasAttachments: boolean
  total: number
}

export const Details = (props: ({ loading: true } & Partial<MessageDetailsProps>) | ({ loading: false } & Required<MessageDetailsProps>)) => {
  const { classes, cx } = useStyles()

  const cc = props.participants?.filter((p) => p.addresseeRole === 'Cc')

  const minLength = getCharacterPosition(props.body, '\n', 5)
  const maxLength = getCharacterPosition(props.body, '\n', 10)

  return (
    <>
      <Columns spacing={0}>
        <Column xs={3}>
          <Skeleton condition={props.loading}>
            <Typography color="text.secondary">From</Typography>
          </Skeleton>
        </Column>
        <Column xs={9}>
          <Skeleton condition={props.loading}>
            <NameLink
              variant="light"
              name={!props.loading ? props.senderName || '' : 'Long placeholder'}
              url={stringifyUrl(`${Paths._people}/${props.senderEmail}`, { name: props.senderName, email: props.senderEmail })}
            />
          </Skeleton>
        </Column>
      </Columns>
      <Columns spacing={0}>
        <Column xs={3}>
          <Skeleton condition={props.loading}>
            <Typography color="text.secondary">To</Typography>
          </Skeleton>
        </Column>
        <Column xs={9}>
          <Repeater
            className={classes.participants}
            skeleton={{ size: 1, loading: props.loading }}
            items={
              props.participants
                ?.filter((p: { addresseeRole: 'Cc' | 'To' | 'From' }) => p.addresseeRole === 'To')
                .map((p: { name: string; email: string }, i: number, participants: any[]) => ({
                  name: p.name,
                  email: p.email,
                  isLast: participants?.length - 1 === i
                })) || []
            }
            component={(p: { name: string; email: string; isLast: boolean }) => (
              <Skeleton condition={!p.name}>
                <NameLink
                  variant="light"
                  name={p.name ? `${p.name}${p.isLast ? '' : ','}` : 'LongLongPlaceholder'}
                  url={stringifyUrl(`${Paths._people}/${p.email}`, { name: p.name, email: p.email })}
                />
              </Skeleton>
            )}
          />
        </Column>
      </Columns>
      {(props.loading || !!cc?.length) && (
        <Columns spacing={0}>
          <Column xs={3}>
            <Skeleton condition={props.loading}>
              <Typography color="text.secondary">Cc</Typography>
            </Skeleton>
          </Column>
          <Column xs={9}>
            <Repeater
              className={classes.participants}
              skeleton={{ size: 1, loading: props.loading }}
              items={
                cc?.map((p: { name: string; email: string }, i: number, participants: any[]) => ({
                  name: p.name,
                  email: p.email,
                  isLast: participants?.length - 1 === i
                })) || []
              }
              component={(props) => (
                <Skeleton condition={!props.name}>
                  <NameLink
                    variant="light"
                    name={props.name ? `${props.name}${props.isLast ? '' : ','}` : 'SuperLongLongPlaceholder'}
                    url={stringifyUrl(`${Paths._people}/${props.email}`, { name: props.name, email: props.email })}
                  />
                </Skeleton>
              )}
            />
          </Column>
        </Columns>
      )}
      <Box mt={2}>
        <TextContent
          skeleton={{ size: 5, loading: props.loading }}
          body={props.body || ''}
          className={classes.textContent}
          showMore={(p: { expanded: boolean; toggleExpanded: () => void }) =>
            p.expanded || minLength === props.body?.length ? (
              <span className={classes.more}>
                {minLength !== props.body?.length && (
                  <Button
                    bold={false}
                    variant="link"
                    size="small"
                    onClick={p.toggleExpanded}
                    disablePL
                    endIcon={<FontAwesomeIcon icon={['far', 'chevron-up']} style={{ fontSize: 10 }} />}
                  >
                    Show less
                  </Button>
                )}
                <Button<'a'>
                  bold={false}
                  className={cx({ [classes.rightAlign]: minLength === props.body?.length })}
                  component="a"
                  variant="link"
                  size="small"
                  disablePL
                  target="_blank"
                  href={props.webLink}
                  rel="noreferrer"
                  endIcon={<FontAwesomeIcon icon={['far', 'chevron-right']} style={{ fontSize: 10 }} />}
                >
                  Continue in Outlook
                </Button>
              </span>
            ) : (
              <Button
                bold={false}
                variant="link"
                size="small"
                onClick={p.toggleExpanded}
                disablePL
                endIcon={<FontAwesomeIcon icon={['far', 'chevron-down']} style={{ fontSize: 10 }} />}
              >
                Show more
              </Button>
            )
          }
          minLength={minLength}
          maxLength={maxLength}
        />
      </Box>
      <IconButton
        icon={['far', 'external-link']}
        loading={props.loading}
        className={classes.externalIcon}
        component="a"
        href={props.webLink}
        target="_blank"
        size="small"
        hint="View in outlook"
      />
    </>
  )
}

export const Actions = (
  props:
    | ({ loading: true } & Partial<Pick<MessageDetailsProps, 'rawSubject' | 'webLink'>>)
    | ({ loading: false } & Required<Pick<MessageDetailsProps, 'rawSubject' | 'webLink'>>)
) => {
  const history = useHistory()
  const params = useParams<{ messageId: string }>()
  const { queryParams } = useSearchQuery<{ deleteBackLink: string }>()
  const { enqueueSnackbar } = useSnackbar()

  const { classes } = useStyles()
  const { isDialogOpened, openDialog, closeDialog } = useDialog()
  const [loading, setLoading] = useState<boolean>(false)

  const deleteMessage = async () => {
    setLoading(true)
    try {
      await del(`/Messages/${params.messageId}`)
      if (queryParams.deleteBackLink) {
        history.replace(queryParams.deleteBackLink)
      }
      enqueueSnackbar(`'${props.rawSubject}' message was deleted`)
    } finally {
      closeDialog()
      setLoading(false)
    }
  }

  return (
    <>
      <Divider />
      <Box className={classes.iconsBlock} p={2}>
        <IconButton
          icon={['fas', 'envelope']}
          component="a"
          href={props.webLink}
          target="_blank"
          disablePY
          disablePL
          color="primary"
          size="small"
          hint="reply"
          loading={props.loading}
        />
        <DeleteMessageDialog.TriggerEl open={openDialog} loading={props.loading} />
      </Box>

      <DeleteMessageDialog isOpened={isDialogOpened} close={closeDialog} loading={loading} handleDelete={deleteMessage} />
    </>
  )
}

type MDetailsProps = Modify<
  Pick<MessageDetailsProps, 'rawSubject' | 'sentDateTime' | 'companies' | 'participants'>,
  { companies: ComponentProps<typeof Avatar>[]; participants: ComponentProps<typeof Avatar>[] }
>

const MessageDetails = (
  props: { actions?: ReactElement; details?: ReactElement } & (({ loading: true } & Partial<MDetailsProps>) | ({ loading: false } & MDetailsProps))
) => {
  const { classes } = useStyles()
  const { pathname } = useLocation()

  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    if (props.rawSubject) {
      setMobileHeader(props.rawSubject)
    }
  }, [props.rawSubject, setMobileHeader])

  return (
    <>
      <Box p={2}>
        <Skeleton condition={props.loading}>
          <Typography color="text.secondary">{formatDateTime(props.sentDateTime)}</Typography>
        </Skeleton>

        <Typography classes={{ root: classes.heading }} noWrap semiBold variant="h3">
          <Skeleton condition={props.loading}>
            <span>{props.rawSubject || '(No subject)'}</span>
          </Skeleton>
        </Typography>
        {props.details}
      </Box>
      {props.actions}
      <InteractionParticipantsWidget
        total={props.participants?.length || -1}
        link={`${pathname}/participants`}
        items={props.participants?.slice(0, 5) || []}
        loading={props.loading}
      />
      <InteractionCompaniesWidget
        total={props.companies?.length || -1}
        link={`${pathname}/companies`}
        items={props.companies?.slice(0, 5) || []}
        loading={props.loading}
      />
    </>
  )
}

export default MessageDetails
