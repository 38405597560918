import { useContext, ComponentProps, useMemo, ReactElement } from 'react'

import {
  identifierColumn,
  anchorIdentifierColumn,
  preferredNameColumn,
  token1Column,
  token2Column,
  preferredUrlColumn,
  assertionColumn,
  assertedByColumn,
  dateColumn,
  actionColumn
} from '_pages/manual-edits/columns'
import { invalidReasonMap } from '_pages/manual-edits/data'

import { TeamContext } from '_core/context/TeamContext'

import { DeleteManualEditsRespType, ManualEditRowItem } from '_core/components/dialogs/ManualEditUndo'
import GridPageFrame from '_core/components/GridPageFrame'
import ManualEditsList, { transformDisqualifierAdminItem, transformSplitAdminItem } from '_core/components/ManualEditsList'
import { getSortProperty } from '_core/components/sort/ManualEdits'

import useManualEditsListUrl from '_core/hooks/useManualEditsListUrl'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'
import UserSettings from '_core/UserSettings'

import { del } from 'utils/httpUtils'

import Paths from 'Paths'

type CManualEditsListProps = {
  items: ManualEditItem[]
  handleReload: () => void
} & Pick<ComponentProps<typeof ManualEditsList>, 'total' | 'setPageSize' | 'columns' | 'paging' | 'loading' | 'confirmDelete' | 'updateSort'> & {
    getAssertion: ({ propValues, sourceTally }: Pick<ManualEditType, 'sourceTally' | 'propValues'>) =>
      | {
          value: ManualEditRowItem['assertion']
          label: string
        }
      | undefined
  }

export const saveData = {
  endpoint: '/usersettings/companymanualeditsfilters',
  getData: (params: ManualEditsPageParams & { isOpened: boolean }): ManualEditsInit => {
    const { contributor, rowsPerPage, entityType, isOpened, sort = 'EditedWhenDesc' } = params

    return {
      type: entityType || 'CompanyTuple',
      contributorKey: contributor,
      sortDirection: sort?.includes('Asc') ? 'Ascending' : 'Descending',
      sortProperty: getSortProperty(sort),
      rowsPerPage: +(rowsPerPage || '10') as RowPerPageOptionsType,
      isOpened
    }
  }
}

export const resetEndpoint = '/usersettings/default/companymanualeditsfilters'

const CManualEditsList = (
  props:
    | ({ loading: true } & Modify<CManualEditsListProps, { items: Partial<CManualEditsListProps['items']> }>)
    | ({ loading: false } & Required<CManualEditsListProps>)
) => {
  const { teamContextValue } = useContext(TeamContext)
  const { queryParams } = useSearchQuery<ManualEditsPageParams>()
  const { entityType } = queryParams

  const { items, total, setPageSize, columns, paging, loading, getAssertion, updateSort, handleReload } = props

  const { value: identifier1Key, md5Key: identifier1Md5Key = '' } =
    [
      { value: 'HighMd5', md5Key: 'valueBinary', condition: entityType === 'CompanyTuple' },
      { value: 'CompanyMd5', md5Key: 'valueBinary', condition: entityType !== 'CompanyTuple' }
    ].find(({ condition }) => !!condition) || {}

  const { value: identifier2Key, md5Key: identifier2Md5Key = '' } =
    [
      { value: 'LowMd5', md5Key: 'valueBinary', condition: entityType === 'CompanyTuple' },
      { value: 'CompanyName', md5Key: 'valueRefdMd5', condition: entityType === 'CompanyPreferredName' },
      { value: 'DomainUrl', md5Key: 'valueRefdMd5', condition: entityType === 'CompanyPreferredUrl' }
    ].find(({ condition }) => !!condition) || {}

  const itms = useMemo(
    () =>
      !loading
        ? items.map((item, index) => {
            if ('propValues' in item) {
              const { sourceTally, propValues, graph, manuallyAssertedWhen, rootLastModified, rootCreated } = item
              const { value: assertionValue, label: assertionLabel = '' } = getAssertion({ propValues, sourceTally }) || {}

              const identifier1 = propValues.find(({ key }) => key === identifier1Key)?.value
              const identifier2 = propValues.find(({ key }) => key === identifier2Key)?.value

              const identifier1Md5 = identifier1?.[identifier1Md5Key as keyof typeof identifier1]?.toString() || ''
              const identifier2Md5 = identifier2?.[identifier2Md5Key as keyof typeof identifier2]?.toString() || ''

              return {
                id: `${index}`,
                identifier1Md5,
                identifier1RefText: identifier1?.referenceText || '',
                identifier2Md5,
                identifier2RefText: identifier2?.referenceText || '',
                assertion: assertionValue,
                assertionLabel,
                assertedBy: graph.userKey,
                date: manuallyAssertedWhen || rootLastModified || rootCreated,
                editLink: `${Paths._companies}/${identifier1Md5}/edit`,
                auditLink: `${Paths._companies}/${identifier1Md5}/audit`
              }
            }

            if ('plainText' in item) {
              return transformDisqualifierAdminItem(item)
            }

            return transformSplitAdminItem(item)
          })
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading]
  )

  const confirmDelete = async (payload: ManualEditRowItem[]) => {
    const entity = 'Company'
    const teamNumber = teamContextValue.teamNumber

    if (entityType === 'CompanyDisqualifierAdministration') {
      const response = await del<DeleteManualEditsRespType>(`/identities/invalids?teamNumber=${teamNumber}`, {
        type: entity,
        notInvalidAfterAllIds: payload.map((item) => item.identifier1RefText),
        dontSplitAfterAllTuples: []
      })
      handleReload()
      return response
    }

    if (entityType === 'CompanySplitAdministration') {
      const response = await del<DeleteManualEditsRespType>(`/identities/invalids?teamNumber=${teamNumber}`, {
        type: entity,
        notInvalidAfterAllIds: [],
        dontSplitAfterAllTuples: payload.map((item) => ({
          identifier1: item.identifier1Md5 || item.identifier1RefText,
          identifier2: item.identifier2Md5 || item.identifier2RefText
        }))
      })
      handleReload()
      return response
    }

    return del<DeleteManualEditsRespType>(
      `/teams/${teamNumber}/manualedits`,
      payload.map(({ identifier1RefText, assertion, identifier2RefText }) => ({
        identity1: identifier1RefText,
        identity2: identifier2RefText,
        assertion,
        entity
      }))
    )
  }

  return (
    <ManualEditsList
      items={itms}
      columns={columns}
      confirmDelete={confirmDelete}
      updateSort={updateSort}
      {...(loading ? { loading } : { total, setPageSize, paging, loading })}
    />
  )
}

const CompaniesManualEdits = (props: {
  filters: ReactElement
  setInitial: (resp: ManualEditsInit) => void
  onPageSizeChange: (rowsPerPage: NumberToString<RowPerPageOptionsType>) => void
  total: number | undefined
  onLoading: (loading: boolean, result: { results: ManualEditType[]; total_item_count: number } | undefined) => void
  disabledSearch: boolean
  updateSort: (sort: ManualEditsSortType) => void
}) => {
  const { queryParams } = useSearchQuery<ManualEditsPageParams>()
  const { entityType, rowsPerPage, contributor = '', sort } = queryParams

  const {
    assertionFilter,
    columns = [identifierColumn, assertionColumn, assertedByColumn, dateColumn, actionColumn],
    getAssertion = () => undefined
  } = useMemo(
    () =>
      [
        {
          columns: [identifierColumn, assertionColumn, assertedByColumn, dateColumn, actionColumn],
          assertionFilter: 'ManuallyOpinedEither',
          condition: entityType === 'CompanyDisqualifier',
          getAssertion: ({ propValues, sourceTally }: Pick<ManualEditType, 'sourceTally' | 'propValues'>) => {
            const { valueEnum: disqualifierReason } = propValues.find(({ key }) => key === 'DisqualifierReason')?.value || {}

            if (disqualifierReason && sourceTally < 0) {
              const assertion: { value: ManualEditRowItem['assertion']; label: string } = {
                value: disqualifierReason,
                label: invalidReasonMap[disqualifierReason]
              }
              return assertion
            } else if (!disqualifierReason && sourceTally > 0) {
              const assertion: { value: ManualEditRowItem['assertion']; label: string } = {
                value: 'Requalified',
                label: 'requalified'
              }
              return assertion
            }
          }
        },
        {
          columns: [anchorIdentifierColumn, assertionColumn, preferredNameColumn, assertedByColumn, dateColumn, actionColumn],
          assertionFilter: 'Affirmed',
          condition: entityType === 'CompanyPreferredName',
          getAssertion: (): { value: ManualEditRowItem['assertion']; label: string } => ({
            value: entityType,
            label: 'preferred name'
          })
        },
        {
          columns: [anchorIdentifierColumn, assertionColumn, preferredUrlColumn, assertedByColumn, dateColumn, actionColumn],
          assertionFilter: 'Affirmed',
          condition: entityType === 'CompanyPreferredUrl',
          getAssertion: (): { value: ManualEditRowItem['assertion']; label: string } => ({
            value: entityType,
            label: 'preferred url'
          })
        },
        {
          columns: [token1Column, assertionColumn, token2Column, assertedByColumn, dateColumn, actionColumn],
          assertionFilter: 'ManuallyOpinedEither',
          condition: entityType === 'CompanyTuple',
          getAssertion: ({ sourceTally }: { sourceTally: ManualEditType['sourceTally']; propValues: ManualEditType['propValues'] }) => {
            const assertionOpts: { value: ManualEditRowItem['assertion']; label: string; condition: boolean }[] = [
              {
                value: 'Split',
                label: invalidReasonMap.Split,
                condition: sourceTally < 0
              },
              {
                value: 'Merge',
                label: invalidReasonMap.Merge,
                condition: sourceTally > 0
              }
            ]

            return assertionOpts.find(({ condition }) => !!condition)
          }
        },
        {
          columns: [token1Column, assertionColumn, token2Column, assertedByColumn, dateColumn, actionColumn],
          condition: entityType === 'CompanySplitAdministration'
        }
      ].find(({ condition }) => !!condition),
    [entityType]
  ) || {}

  const { url, handleReload } = useManualEditsListUrl(entityType, contributor, assertionFilter, sort)

  return (
    <UserSettings endpoint="/usersettings/companymanualeditsfilters" setInitial={props.setInitial}>
      <GridPageFrame
        stickFilters
        loading={typeof props.total !== 'number'}
        filterHeight={100}
        gridTitle="Companies"
        searchPlaceholder="Search for manual edit"
        filters={props.filters}
        disabledSearch={props.disabledSearch}
        heading={<></>}
        component={
          <DynamicEntity<{
            extraProps: { addprops: Pick<ComponentProps<typeof CManualEditsList>, 'columns' | 'getAssertion' | 'updateSort' | 'handleReload'> }
          }>
            url={url}
            infinite
            list
            keepMounted
            search
            autoHideOnScroll
            addprops={{ columns, getAssertion, updateSort: props.updateSort, handleReload }}
            pageSize={+(rowsPerPage || '10')}
            onLoading={props.onLoading}
            component={CManualEditsList}
            updatePageSize={props.onPageSizeChange}
            empty="No edits found"
            emptySubtitle="There are no company related manual edits"
            id="companies_manual_edits"
          />
        }
      />
    </UserSettings>
  )
}

export default CompaniesManualEdits
