import React, { ComponentProps } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar from '_shared/Avatar'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import Widget from '_core/components/widgets'

const ColleaguesWidget = (props: { items: ComponentProps<typeof Avatar>[]; loading: boolean; count?: number }) => (
  <Widget>
    <Heading underlined title="Colleagues" count={props.count} icon={['far', 'user']} />
    <Repeater
      direction="horizontal"
      component={Avatar}
      empty={<Empty subTitle="No colleagues" icon={<FontAwesomeIcon size="3x" icon={['fat', 'address-book']} style={{ color: '#A7A7A7' }} />} />}
      skeleton={{ size: 3, loading: props.loading }}
      items={props.items}
    />
  </Widget>
)

export default ColleaguesWidget
