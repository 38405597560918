import { useState, useCallback, useRef } from 'react'

import { useLocation } from 'react-router-dom'

import { useWide } from '_core/components/layout'
import { sortMap } from '_core/components/sort/Introductions'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useFilter from '_core/hooks/useFilter'
import useSearchQuery from '_core/hooks/useSearchQuery'

type InitialParams = Modify<IntroductionsPageParams, { isOpened: boolean }>

const introductionsSaveData = {
  endpoint: '/prospecting/planfetchfilter',
  getData: (params: InitialParams): IntroductionsInit => {
    const {
      sort = 'ByActivityDesc',
      rowsPerPage,
      viewMode,
      isOpened,
      fetcherRole,
      reason,
      unassigned,
      assignedToEmail,
      beneficiaryIdentifiers,
      entityIdentifiers,
      targetingStatuses,
      seekingManagerHelp,
      visibility,
      mode,
      requestType
    } = params

    const sortData = Object.keys(sortMap)
      .filter((key) => {
        const { asc, desc } = sortMap[key]
        return [asc, desc].includes(sort)
      })
      .reduce(
        (acc, key) => ({
          ...acc,
          sort: `${key.charAt(0).toUpperCase()}${key.slice(1)}`,
          direction: sort?.includes('Asc') ? 'Ascending' : 'Descending'
        }),
        {}
      ) as { [key in 'sort' | 'direction']: IntroductionsInit[key] }

    return {
      rowsPerPage: +(rowsPerPage || '10') as RowPerPageOptionsType,
      fetcherRole: fetcherRole ? (fetcherRole.toLowerCase() as FetcherRoleType) : undefined,
      unassigned: unassigned === 'true' ? true : unassigned === 'false' ? false : undefined,
      seekingManagerHelp: seekingManagerHelp === 'true' ? true : unassigned === 'false' ? false : undefined,
      beneficiaryIdentifiers: beneficiaryIdentifiers ? beneficiaryIdentifiers.split(',') : [],
      entityIdentifiers: entityIdentifiers ? entityIdentifiers.split(',') : [],
      targetingStatuses: targetingStatuses ? targetingStatuses.split(',') : [],
      expandedView: viewMode === 'expanded',
      readyForReview: visibility === 'shared' ? true : visibility === 'mine' ? false : undefined,
      mode: mode === 'contacts' ? 'Contacts' : 'Plans',
      closedFilter: requestType,
      assignedToEmail,
      reason,
      isOpened,
      ...sortData
    }
  }
}

const useIntroductionsUserSettings = () => {
  const wide = useWide()
  const { search } = useLocation()
  const { queryParams, updateQuery } = useSearchQuery<IntroductionsPageParams>()
  const [isOpened, setOpened] = useState(false)
  const [loading, setLoading] = useState(true)
  const { save } = useFilter()
  const { fetchWithAbort } = useAbortableFetch<IntroductionsInit>()
  const initialOpened = useRef<InitialParams['isOpened']>(isOpened)

  const getInitialParams = useCallback((data: IntroductionsInit): InitialParams => {
    const {
      sort,
      direction,
      rowsPerPage,
      expandedView,
      mode,
      isOpened,
      readyForReview,
      assignedToEmail,
      beneficiaryIdentifiers,
      entityIdentifiers,
      targetingStatuses,
      seekingManagerHelp,
      reason,
      unassigned,
      fetcherRole,
      closedFilter
    } = data

    return {
      assignedToEmail,
      mode: mode === 'Contacts' ? 'contacts' : 'requests',
      beneficiaryIdentifiers: beneficiaryIdentifiers ? beneficiaryIdentifiers.join(',') : '',
      entityIdentifiers: entityIdentifiers ? entityIdentifiers.join(',') : '',
      targetingStatuses: targetingStatuses ? targetingStatuses.join(',') : '',
      fetcherRole: fetcherRole ? (fetcherRole.toLowerCase() as FetcherRoleType) : undefined,
      unassigned: typeof unassigned === 'boolean' ? `${unassigned}` : undefined,
      seekingManagerHelp: typeof seekingManagerHelp === 'boolean' ? `${seekingManagerHelp}` : undefined,
      visibility: readyForReview === true ? 'shared' : readyForReview === false ? 'mine' : undefined,
      viewMode: expandedView ? 'expanded' : 'collapsed',
      requestType: closedFilter ? (closedFilter.toLowerCase() as IntroductionType) : 'active',
      rowsPerPage: `${rowsPerPage || 10}`,
      sort: sortMap[`${sort.charAt(0).toLowerCase()}${sort.slice(1)}`][direction === 'Ascending' ? 'asc' : 'desc'],
      isOpened,
      reason
    }
  }, [])

  const setInitial = useCallback(
    (data: IntroductionsInit) => {
      const { isOpened, ...params } = getInitialParams(data)
      initialOpened.current = isOpened
      if (wide) {
        setOpened(isOpened)
      }
      if(!search){
        updateQuery(params)
      }
      setLoading(false)
    },
    [wide, getInitialParams, setLoading, search]
  )

  const getIntroductionsSaveData = (additionalParams: any) => ({
    ...introductionsSaveData,
    getData: (params: InitialParams) => introductionsSaveData.getData({ ...params, ...additionalParams })
  })

  const handleChange = (updates: IntroductionsPageParams, additionalParams?: { [key in 'isOpened']?: boolean } | undefined) => {
    const { isOpened = initialOpened.current} = additionalParams || {}

    const hiddenParamsToSave = {
      isOpened
    }

    return save<void>(getIntroductionsSaveData(hiddenParamsToSave), { ...queryParams, ...updates })
  }

  const reset = async () => {
    setLoading(true)
    const defaultData = await fetchWithAbort({ url: '/prospecting/default/planfetchfilter' })
    if (defaultData) {
      const { isOpened, ...params } = getInitialParams(defaultData)
      await save(getIntroductionsSaveData({ isOpened }), params)
      setOpened(isOpened)
      setLoading(false)
    }
  }

  const toggleOpen = () => {
    const newState = !isOpened
    setOpened(newState)
    if (wide) {
      return handleChange({}, { isOpened: newState })
    }
  }

  return {
    setInitial,
    handleChange,
    loading,
    opened: isOpened,
    toggleOpen,
    reset
  }
}

export default useIntroductionsUserSettings
