import * as _ from 'lodash'
import { makeStyles } from 'tss-react/mui'

import Avatar from '_shared/Avatar'
import Card, { CardContent } from '_shared/Card'
import Typography from '_shared/Typography'

import Repeater from '_core/components/lists/Repeater'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  topsCard: {
    minHeight: 260
  },
  tops: {
    flexWrap: 'wrap'
  },
  results: {
    display: 'flex',
    width: '100%',
    flex: 1,
    overflow: 'auto',
    flexDirection: 'column',
    height: 'calc(100vh - 262px)',
    [theme.breakpoints.up('md')]: {
      width: 410,
      borderRight: `1px solid #f2f2f2`,
      height: 'calc(100vh - 175px)'
    }
  },
  options: {
    overflow: 'auto',
    '& > div': {
      '& > div': {
        padding: 0,
        '& > div, & > a > div,  & > button > div': {
          padding: `${theme.spacing(1)} ${theme.spacing(2)}`
        }
      }
    }
  },
  text: {
    opacity: 0.42,
    textAlign: 'center'
  }
}))

const Tops = (props: any) => {
  const { classes } = useStyles()
  const { companies, people } = props
  const showTops = props.loading || companies?.data.length || people?.data.length

  const companiesRows = _.chunk(companies?.data, 5)
  const peopleRows = _.chunk(people?.data, 5)

  return (
    <Card square elevation={0} className={classes.topsCard}>
      <CardContent>
        {!!showTops && (
          <Repeater
            className={classes.tops}
            direction="horizontal"
            component={Avatar}
            skeleton={{ size: 10, loading: props.loading }}
            addprops={{ style: { width: '100%', maxWidth: 72, display: 'block', flex: '0 0 100%', boxSizing: 'border-box', marginBottom: 16 } }}
            items={[peopleRows, companiesRows]?.flat(2)?.map((item: any) => ({
              userKey: item.BestEmailAddrText || '',
              logoUrl: item.BestUrlText,
              name: item.PersonNameText || item.BestEmailAddrText || item.CompanyNameText || item.BestUrlText,
              score: item.Score,
              sidepanel: 'preview',
              link: item.PersonMd5 ? `${Paths._people}/${item.PersonMd5}` : `${Paths._companies}/${item.CompanyMd5}`
            }))}
          />
        )}
        {!showTops && <Typography classes={{ root: classes.text }}>No companies or people found</Typography>}
      </CardContent>
    </Card>
  )
}

export default Tops
