import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import Avatar from '_shared/Avatar'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import MarketDataSearchLink from '_core/components/MarketDataSearchLink'
import Widget, { SeeAllAction } from '_core/components/widgets'
import useStyles from '_core/components/widgets/styles'

import { CompaniesType as SimilarCompaniesType } from '_core/hooks/useSimilarCompanies'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

const SimilarCompaniesWidget = ({
  similar,
  ...props
}: {
  loading: boolean
  companyName: string
  similar: SimilarCompaniesType | null
  marketData: boolean
}) => {
  const { id } = useParams<{ id: string }>()
  const { classes } = useStyles()

  const load = props.loading || !similar
  const showEmptyWidget = similar && similar.total === 0

  const items = similar?.data
    ? similar.data.slice(0, 5).map((company) => {
        return {
          name: company.name,
          score: company.companyMd5 ? company.score : undefined,
          sidepanel: true,
          logoUrl: company.website,
          link: company.companyMd5
            ? `${Paths._companies}/${company.companyMd5}`
            : mergeUrlWithParams(`${Paths._companies}/${id}/similar/${company.name}`, {
                website: company.website
              }),
          byline: company.website,
          byline2: company.industry
        }
      })
    : []

  return (
    <Widget>
      <Heading
        underlined
        title="Similar companies"
        icon={['fas', 'diagram-venn']}
        count={similar?.total}
        action={
          <SeeAllAction sidepanel link={similar?.total && !showEmptyWidget ? `${Paths._companies}/${id}/similar?name=${props.companyName}` : ''} />
        }
      />
      {!showEmptyWidget && (
        <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 5, loading: !props.marketData || load }} items={items} />
      )}
      {showEmptyWidget && (
        <Empty
          className={classes.empty}
          subTitle="No records were found"
          action={
            <Box display="flex" justifyContent="center">
              <MarketDataSearchLink entity="company" />
            </Box>
          }
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'buildings']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

export default SimilarCompaniesWidget
