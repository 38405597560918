import { useCallback, useEffect, useMemo, useState } from 'react'

import moment, { Moment as MomentType } from 'moment'
import { useLocation, useParams } from 'react-router-dom'

import { useWide } from '_core/components/layout'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useFilter from '_core/hooks/useFilter'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { monthsInAYear } from '_core/data/day'

import { dateFormatURLQuery, formatDate, getUTC } from 'utils/Utils'

type InitialParams = ModifiedMeetingsPageParams & { isOpened: boolean }

const meetingsSaveData = {
  endpoint: '/usersettings/meetingsgrid',
  getData: ({ from, to, rowsPerPage = '10', sort = 'OldestToNewest', ids = [], isOpened, checked = [] }: InitialParams): MeetingsInit => {
    return {
      fromDate: (
        getUTC(from).startOf('day') ||
        moment
          .utc()
          .clone()
          .subtract(monthsInAYear - 1, 'months')
          .startOf('month')
      ).toISOString(),
      toDate: (getUTC(to).endOf('day') || moment.utc().endOf('month')).toISOString(),
      rowsPerPage: +rowsPerPage as RowPerPageOptionsType,
      sort,
      contributorIds: ids,
      isOpened,
      includeRecurringMeetings: checked.includes('includeRecurringMeetings')
    }
  }
}

const useMeetingsUserSettings = () => {
  const { id: contributorLevel } = useParams<{ id: string } | undefined>() || {}
  const wide = useWide()

  const [opened, setOpened] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const { search } = useLocation()

  const [params, setParams] = useState<InitialParams>()

  const { fetchWithAbort } = useAbortableFetch<MeetingsInit>()

  const { queryParams, updateQuery } = useSearchQuery<MeetingsPageParams, { modifyProps: [{ ids: string[]; checked: IncludeMeetingsType[] }] }>([
    'ids',
    'checked'
  ])

  const { save } = useFilter()

  const getInitialParams = useCallback((data: MeetingsInit): InitialParams => {
    const { fromDate, toDate, sort, rowsPerPage, contributorIds, includeRecurringMeetings, isOpened } = data

    const checkboxes: { [key in IncludeMeetingsType]: boolean } = { includeRecurringMeetings }
    const checked = (Object.keys(checkboxes) as IncludeMeetingsType[]).filter((key) => checkboxes[key])

    return {
      isOpened,
      from: getUTC(fromDate).format(dateFormatURLQuery),
      to: getUTC(toDate).format(dateFormatURLQuery),
      sort,
      rowsPerPage: `${rowsPerPage}`,
      ids: contributorIds,
      checked
    }
  }, [])

  const setInitial = useCallback(
    (data: MeetingsInit) => {
      const params = getInitialParams(data)
      if (wide) {
        setOpened(params.isOpened)
      }
      setParams(params)
      setLoading(false)
    },
    [getInitialParams, setLoading, wide, setOpened]
  )

  useEffect(() => {
    if (!search && params) {
      const { ids, isOpened, ...baseParams } = params
      updateQuery({ ...baseParams, ids: contributorLevel ? undefined : ids })
    }
  }, [contributorLevel, params, search, updateQuery])

  const getMeetingsSaveData = (additionalParams: InitialParams) => ({
    ...meetingsSaveData,
    getData: (params: typeof queryParams) => meetingsSaveData.getData({ ...params, ...additionalParams })
  })

  const handleChange = (updates: typeof queryParams, additionalParams?: { [key in 'isOpened']?: boolean } | undefined) => {
    const { isOpened = opened } = additionalParams || {}

    const hiddenParamsToSave = [
      { params: { ids: params?.ids }, condition: contributorLevel },
      { params: { isOpened }, condition: true }
    ]
      .filter(({ condition }) => condition)
      .reduce((acc, { params }) => ({ ...acc, ...params }), {}) as InitialParams

    return save<void>(getMeetingsSaveData(hiddenParamsToSave), { ...queryParams, ...updates })
  }

  const handleDateChange = ({ picker, value }: { picker: 'from' | 'to'; value: MomentType | null }) => {
    const formatted = formatDate(value, dateFormatURLQuery)

    setParams((prev) => (prev ? { ...prev, [picker]: value ? formatted : null } : prev))

    if (value?.isValid()) {
      handleChange({ [picker]: formatted })
    }
  }

  const toggleOpen = () => {
    const isOpened = !opened
    setOpened(isOpened)
    if (wide) {
      handleChange({}, { isOpened })
    }
  }

  const reset = async () => {
    setLoading(true)
    const defaultData = await fetchWithAbort({ url: '/usersettings/default/meetingsgrid' })
    if (defaultData) {
      const params = getInitialParams(defaultData)
      const { isOpened, ...qParams } = params

      await save<void>(getMeetingsSaveData({ isOpened }), qParams)
      setOpened(isOpened)
      setParams(params)
      setLoading(false)
      return params
    }
  }

  const range = useMemo(() => ({ from: params?.from, to: params?.to }), [params?.from, params?.to])

  return { setInitial, opened, handleChange, handleDateChange, toggleOpen, reset, range, loading }
}

export default useMeetingsUserSettings
