import { useCallback, useEffect, useMemo, useState } from 'react'

import { useMsal } from '@azure/msal-react'
import { Moment as MomentType } from 'moment'

import { statusMap } from '_core/components/Event'

import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { stringifyUrl } from '_core/helpers/browser'

import { getLocal } from 'utils/Utils'

import Paths from 'Paths'

type Params = {
  checked: IncludeEvents[]
  loading: boolean
  items?: EventType[]
  date?: MomentType
  showPast?: boolean
}

const useEvents = ({ checked, date = getLocal(), loading, showPast = true, items }: Params) => {
  const [events, setEvents] = useState<EventListItem[]>()
  const isTodayOpened = getLocal(date).diff(getLocal().startOf('day'), 'days') === 0
  const { accounts } = useMsal()
  const loggedInUser = accounts[0]

  const { payloads } = useSidepanelPayloads('preview')

  useEffect(() => {
    if (!loading && !events && items) {
      setEvents(
        items.map((item) => {
          const participantsWithPeople = item.participantsWithPeople?.map((item) => ({
            ...item.person,
            PersonMd5: item.person?.PersonMd5 || '',
            PersonNameText: item.person?.PersonNameText || item.participant?.name || '',
            BestEmailAddrText: item.participant?.email.toLowerCase(),
            link: stringifyUrl(`${Paths._people}/${item.person?.PersonMd5 || item.participant?.email.toLowerCase()}`, {
              name: item.person?.PersonNameText || item.participant?.name,
              email: item.participant?.email.toLowerCase()
            })
          }))
          const loggedInUserIndex = participantsWithPeople?.findIndex(
            (p) => p.BestEmailAddrText === loggedInUser.username || p.UserInput === loggedInUser.username
          )
          if (loggedInUserIndex > -1) {
            participantsWithPeople.push(participantsWithPeople.splice(loggedInUserIndex, 1)[0])
          }
          return {
            ...item,
            status: item.responseStatus.responseType,
            participantsWithPeople,
            organizer: participantsWithPeople
              ?.filter((p) => p?.BestEmailAddrText === item.senderEmail || p?.UserInput === item.senderEmail)
              .map((p) => ({ name: p.PersonNameText, md5: p.PersonMd5, email: p.BestEmailAddrText }))[0]
          }
        })
      )
    }
  }, [loading, items, events])

  useEffect(() => {
    if (events) {
      if (payloads && payloads.action === 'UPDATE_TAGS') {
        if (Array.isArray(payloads.value)) {
          const identifiers = payloads.value.map((el) => el.identifier)
          const indexes = identifiers.map((identifier) => events?.findIndex((item) => item.appointmentUID === identifier))
          const newItems = [...events]
          indexes.forEach((index: number) => {
            const updatedTags = payloads.value.find((el) => el.identifier === newItems[index]?.appointmentUID)?.tags
            newItems[index] = {
              ...newItems[index],
              ...(updatedTags ? { tags: updatedTags } : {})
            }
          })
          setEvents(newItems)
        }
      }
      if (payloads && payloads.action === 'SYNC_TAGS') {
        const { taggableType, categoriesRenames, tagsRenames, deprecations } = payloads.value
        if (taggableType === 'meetings') {
          const renamed = events.map(({ tags, ...event }) => {
            const nonDeprecated = tags.filter(
              ({ categoryName, tagName }: UngroupedTag) =>
                !deprecations.find((deprecated) => deprecated.categoryName === categoryName && deprecated.tagName === tagName)
            )
            return {
              ...event,
              tags: nonDeprecated.map(({ categoryName, tagName }: UngroupedTag) => {
                const { newCategoryName = categoryName } = categoriesRenames.find(({ oldCategoryName }) => categoryName === oldCategoryName) || {}

                const { newTagName = tagName } =
                  tagsRenames.find(({ oldCategoryName, oldTagName }) => categoryName === oldCategoryName && tagName === oldTagName) || {}
                return { categoryName: newCategoryName, tagName: newTagName }
              })
            }
          })
          setEvents(renamed)
        }
      }
    }
  }, [payloads])

  const filterPast = (item: EventListItem) => {
    return getLocal(item.startTimeUTC).diff(getLocal()) < 0 && getLocal(item.endTimeUTC).diff(getLocal()) < 0 && !item.isAllDay
  }

  const applyFilters = useCallback(
    (item: EventListItem) => {
      if (item.status) {
        const status = statusMap[item.status as EventStatusKeys].id
        const isAppointment = item.participantsWithPeople?.length === 1 && item.organizer?.md5 === item.participantsWithPeople[0].PersonMd5

        const by: { key: IncludeEvents; condition: boolean }[][] = [
          [
            { key: 'meetings', condition: !item.seriesMasterKey && !isAppointment },
            { key: 'recurringMeetings', condition: !!item.seriesMasterKey },
            { key: 'appointments', condition: isAppointment },
            { key: 'canceledMeetings', condition: item.isCancelled }
          ],
          [
            { key: 'accepted', condition: status === 'Accepted' || status === 'Organizer' || status === 'None' },
            { key: 'tentativelyAccepted', condition: status === 'TentativelyAccepted' },
            { key: 'notResponded', condition: status === 'NotResponded' || status === 'None' }
          ]
        ]

        const activeGroup = by.map((groupItems) => !!groupItems.find((item) => checked.includes(item.key)))
        const matchFilters =
          by.filter((groupItems, i) => !!groupItems.find((item) => !activeGroup[i] || (checked.includes(item.key) && item.condition))).length ===
          by.length

        if (status === 'Declined' || (checked.length && !matchFilters) || (!showPast && isTodayOpened && filterPast(item))) {
          return null
        }

        return item
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checked.length, isTodayOpened, showPast]
  )

  const filteredEvents = useMemo(() => events?.filter(applyFilters), [applyFilters, events])

  const update = (index: number, updated: Partial<EventListItem>) => {
    if (events && filteredEvents) {
      const { sourceKey } = filteredEvents[index]
      const idx = events.findIndex((evt) => evt.sourceKey === sourceKey)
      setEvents((events = []) => [...events.slice(0, idx), { ...events[idx], ...updated }, ...events.slice(idx + 1)])
    }
  }

  return {
    total: events?.filter((evt) => evt.status !== 'Declined')?.length || 0,
    items: filteredEvents,
    update
  }
}

export default useEvents
