import React, { useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { isSidepanel } from '_pages/sidebar'

import { TeamContext } from '_core/context/TeamContext'

import AddCompanies from '_core/components/AddPrivilegedCompanies'
import { CompanyOptionType } from '_core/components/dialogs/CreateCompanyOptionDialog'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import useSidebar from '_core/hooks/useSidebar'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { post } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import { DataAdminSubPaths } from 'Paths'

type OptionalFields = {
  companyUrl: string
  companyName: string
  viaWhomEmail: string
  lastModified: string
}

export type PrivilegedCompany = {
  rowNumber: number
  teamNumber: number
  companyMd5: string
  requirePrivilegedAccess: boolean
  total: number
  idText?: string // can be a name or url
} & (OptionalFields | { [K in keyof OptionalFields]: never })

const AddPrivilegedCompaniesPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Add companies')
  }, [setMobileHeader])

  return (
    <Narrow>
      <Topbar nativeBack />
      <Component />
    </Narrow>
  )
}

const Component = () => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const history = useHistory()
  const { close: sidebarClose } = useSidebar()
  const { updateParent } = useSidepanelPayloads()

  const sidepanel = isSidepanel()

  const handleSave = async (addedCompanies: CompanyOptionType[]) => {
    if (addedCompanies.length) {
      const payload = addedCompanies.map((company) => ({
        url: company.website,
        name: company.name
      }))
      await post(`/companies/privcos?teamNumber=${teamNumber}`, payload)

      if (sidepanel) {
        sidebarClose()
        updateParent({ action: 'RELOAD_LIST', value: 'privileged-companies' })
      } else {
        history.push(DataAdminSubPaths.privilegedCompanies)
      }
    }
  }

  return <AddCompanies handleSave={handleSave} />
}

export default AddPrivilegedCompaniesPage
