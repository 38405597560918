import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import Typography from '_shared/Typography'

import { Tab } from '_core/hooks/useTabs'

const useStyles = makeStyles()((theme) => ({
  outlinedDetails: {
    display: 'block',
    padding: `0 ${theme.spacing(0.5)} ${theme.spacing(2)}`,
    [theme.breakpoints.up('sidepanel')]: {
      padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`
    }
  },
  title: {
    textTransform: 'uppercase',
    letterSpacing: '1.25px',
    padding: `${theme.spacing(2)} 0`
  }
}))

const SettingsAccordion = ({ tabs }: { tabs: Tab[] }) => {
  const { classes } = useStyles()
  const { pathname } = useLocation()

  return (
    <>
      {tabs.map(({ label, component: Component, routesMatch }) => {
        const tPath = routesMatch[0]
        const isExpanded = !!matchPath(tPath, { path: pathname })?.isExact
        return (
          <Accordion key={tPath} expanded={isExpanded} variant="outlined">
            <AccordionSummary
              to={tPath}
              component={Link}
              expandIcon={<IconButton icon={['far', 'chevron-down']} size="small" disablePadding />}
              aria-label="Expand"
              aria-controls={`${tPath}-content`}
              id={`${tPath}-header`}
            >
              <Typography noWrap semiBold className={classes.title} color={isExpanded ? 'primary.main' : 'text.secondary'}>
                {label}
              </Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.outlinedDetails }}>{Component && <Component />}</AccordionDetails>
          </Accordion>
        )
      })}
    </>
  )
}

export default SettingsAccordion
