import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'

import { Box } from '@mui/material'
import { Link, useRouteMatch } from 'react-router-dom'

import { IconButton } from '_shared/buttons'

import TuplesChainItem from '_core/components/audit/TuplesChainItem'
import { TupleInfoType } from '_core/components/audit/TuplesList'
import Empty from '_core/components/Empty'
import Repeater from '_core/components/lists/Repeater'

export type TuplesChainProps = {
  id: string
  loading: boolean
  total: number
  items: { tupleInfos: TupleInfoType[] | [] }[]
  checkedTuples: string[]
  setCheckedTuples: Dispatch<SetStateAction<string[]>>
}

const TuplesChainList = (props: TuplesChainProps) => {
  const match = useRouteMatch<TuplesPageParams>()

  const toggleCheck = (e: ChangeEvent<HTMLInputElement>, uid: string) => {
    if (e.target.checked) {
      props.setCheckedTuples((prev) => [...prev, uid])
    } else {
      props.setCheckedTuples((prev) => prev.filter((id) => id !== uid))
    }
  }

  const items = props.items[0]?.tupleInfos.length
    ? props.items.map(({ tupleInfos }) => ({
        isSinglePath: props.total === 1,
        tuplesInfos: tupleInfos.map(({ lowIdentifier, highIdentifier }) => {
          const id1 = lowIdentifier.text
          const id2 = highIdentifier.text
          const uid = `${id1} = ${id2}`
          return {
            id1: lowIdentifier.text,
            id2: highIdentifier.text,
            checked: props.checkedTuples.includes(uid),
            toggleCheck: (e: ChangeEvent<HTMLInputElement>) => toggleCheck(e, uid),
            actions: (
              <IconButton<typeof Link>
                component={Link}
                to={{
                  pathname: `${match.url}/sources/${encodeURIComponent(id1)}/${encodeURIComponent(id2)}`
                }}
                icon={['fas', 'list-tree']}
                hint="Sources"
                size="small"
                color="primary"
              />
            )
          }
        })
      }))
    : []

  return (
    <Box px={2}>
      <Repeater
        direction="vertical"
        component={TuplesChainItem}
        skeleton={{ size: 5, loading: props.loading }}
        items={items}
        empty={<Empty title="No tuple paths were found" />}
      />
    </Box>
  )
}

export default TuplesChainList
