import React from 'react'

import { Step } from '_core/context/Onboarding'

import Typography from '_shared/Typography'

import { useWide } from '_core/components/layout'

const steps: Step[] = [
  {
    disableBeacon: true,
    title: 'Source',
    content: (
      <Typography style={{ maxWidth: '220px' }}>
        Search your DotAlign dataset or search millions of people and companies using market data provided by DotAlign.
      </Typography>
    ),
    target: '.advanced-search-scope',
    placement: 'bottom'
  },
  {
    disableBeacon: true,
    title: 'People or companies',
    content: <Typography style={{ maxWidth: '220px' }}>Select the entity type you would like to search in.</Typography>,
    target: '.advanced-search-entity',
    placement: 'bottom'
  }
]

const multiAutocompleteStep: Step = {
  disableBeacon: true,
  title: 'Multi-autocomplete',
  content: (
    <Typography style={{ maxWidth: '220px' }}>
      Start typing to see result grouped by field name, including the number of results for each field
    </Typography>
  ),
  target: '.advanced-search-multi-autocomplete',
  placement: 'bottom'
}

const useAdvancedSearchSteps = () => {
  const wide = useWide()

  return wide ? [...steps, multiAutocompleteStep] : steps
}

export default useAdvancedSearchSteps
