import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { educationSchoolColumn, skillsColumn } from '_pages/search/columns'

import LoadingButton from '_shared/buttons/LoadingButton'

import ExpandableDetails from '_core/components/ExpandableDetails'
import { DataGrid, GridPaginationType } from '_core/components/grid'
import { emailColumn, companyColumn, industryColumn, jobTitleColumn, locationColumn, nameColumn, scoreColumn } from '_core/components/grid/columns'
import IsInNetworkIcon from '_core/components/IsInNetworkIcon'
import { Narrow, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import { PeopleFiltersType } from '_core/components/MarketDataPeopleSearchFilters'
import ProfileItem from '_core/components/ProfileItem'

import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

type ResultsProps = {
  items: PeopleMarketDataRes['data']
  loading: boolean
  hasMore: boolean
  more: () => void
  isNextLoading: boolean
  viewMode: ViewModeType
} & GridPaginationType

const Results = ({ loading, isNextLoading, hasMore, more, viewMode, ...props }: ResultsProps) => {
  const isExpandedView = viewMode === 'expanded'

  const items = props.items.map((person, i) => ({
    id: `${i}-${person.id}`,
    name: person.full_name || '',
    email: person.work_email,
    industry: person.industry,
    location: person.location_name,
    title: person.job_title,
    company: {
      name: person.job_company_name,
      link: mergeUrlWithParams(`${Paths._companies}/${person.job_company_website || person.job_company_name}`, {
        name: person.job_company_name,
        website: person.job_company_website
      }),
      url: person.job_company_website,
      sidepanel: true
    },
    link: person.personMd5
      ? `${Paths._people}/${person.personMd5}`
      : mergeUrlWithParams(`${Paths._people}/${person.work_email || person.full_name}`, {
          email: person.work_email,
          name: person.full_name,
          company: person.job_company_name
        }),
    isInNetwork: !!person.score,
    score: person.score,
    schoolNames: [...new Set(person.education?.map((item) => item.school?.name).filter(Boolean) || [])],
    skills: person.skills
  }))

  const loadMore = (
    <LoadingButton
      loading={isNextLoading}
      disabled={loading}
      fullWidth
      variant="link"
      onClick={more}
      endIcon={<FontAwesomeIcon icon={['far', 'arrow-rotate-right']} style={{ fontSize: 16 }} />}
    >
      Show more
    </LoadingButton>
  )

  const variantItem = isExpandedView ? 'card' : 'list'

  return (
    <>
      <Wide>
        <DataGrid
          loading={loading}
          rows={!loading ? items : []}
          columns={[
            nameColumn,
            emailColumn,
            jobTitleColumn,
            companyColumn,
            industryColumn,
            locationColumn,
            skillsColumn,
            educationSchoolColumn,
            scoreColumn
          ]}
          footerControls={hasMore ? [loadMore] : []}
          controls={[]}
          autoHeight={false}
          total={props.total}
          paging={props.paging}
          setPageSize={props.setPageSize}
        />
      </Wide>
      <Narrow>
        <Repeater
          direction="vertical"
          component={ProfileItem}
          skeleton={{ size: 5, loading }}
          variant={variantItem}
          items={items.map(({ name, isInNetwork, score, link, email, title, company, industry, location }) => ({
            variant: 'expandable',
            name,
            score,
            icons: isInNetwork && <IsInNetworkIcon name={name} />,
            link,
            byline: email || '',
            byline3: (
              <ExpandableDetails
                isExpandedView={isExpandedView}
                items={[
                  { field: '', value: title, title: 'Job title', icon: ['far', 'briefcase'] },
                  { field: '', value: company?.name, title: 'Company name', icon: ['far', 'building'] },
                  { field: '', value: industry, title: 'Industry', icon: ['fas', 'industry'] },
                  { field: '', value: location, title: 'Location', icon: ['fas', 'location-dot'] }
                ]}
              />
            )
          }))}
        />
      </Narrow>
    </>
  )
}

const PeopleResults = ({
  teamNumber,
  ...props
}: {
  teamNumber: number
  onLoading: (loading: boolean, result: { total_item_count: number } | undefined) => void
}) => {
  const { queryParams, updateQuery } = useSearchQuery<SearchPageParams, { modifyProps: [{ entities: SearchEntities[] }] }>(['entities'])

  const { peopleFilters, keyword, viewMode, rowsPerPage = '10' } = queryParams
  const filters: Partial<PeopleFiltersType> = peopleFilters ? JSON.parse(peopleFilters) : {}
  const params: { name: Capitalize<keyof PeopleFiltersType> | 'Size'; value?: ValueOf<PeopleFiltersType> | number }[] = [
    { name: 'FirstName', value: filters.firstName },
    { name: 'LastName', value: filters.lastName },
    { name: 'EmailAddress', value: filters.emailAddress },
    { name: 'CompanyNames', value: filters.companyNames },
    { name: 'JobTitles', value: filters.jobTitles },
    { name: 'JobTitleRoles', value: filters.jobTitleRoles },
    { name: 'JobTitleSubroles', value: filters.jobTitleSubroles },
    { name: 'JobTitleLevels', value: filters.jobTitleLevels },
    { name: 'CompanySize', value: filters.companySize },
    { name: 'Industries', value: filters.industries },
    { name: 'Countries', value: filters.countries },
    { name: 'LocationName', value: filters.locationName },
    { name: 'LocationRegion', value: filters.locationRegion },
    { name: 'EducationSchoolName', value: filters.educationSchoolName },
    { name: 'Skills', value: filters.skills },
    { name: 'Size', value: rowsPerPage }
  ]
  const payload = params.filter(({ value }) => !!value).reduce((acc, { name, value }) => ({ ...acc, [name]: `${value}` }), {})
  const url = mergeUrlWithParams('/cloudhub/people/advancedsearch', { ...payload, teamNumber: `${teamNumber}` })

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    updateQuery({ rowsPerPage })
  }

  return (
    <DynamicEntity<{ extraProps: { addprops: Pick<ResultsProps, 'viewMode'> } }>
      url={url}
      onLoading={props.onLoading}
      component={Results}
      addprops={{
        viewMode: viewMode || 'collapsed'
      }}
      pageSize={+rowsPerPage}
      updatePageSize={onPageSizeChange}
      keepMounted
      list
      infinite="click"
      empty="No records found"
      search
      emptySubtitle={keyword ? `No records found for your search '${keyword}'` : ''}
      id="advanced_people_search"
    />
  )
}

export default PeopleResults
