import { EmailAlertsSettings } from '_core/components/settings/admin'

import DynamicEntity from '_core/DynamicEntity'

const EmailAlertsSettingsPage = () => {
  return (
    <DynamicEntity
      url={`/adminsettings/email`}
      component={EmailAlertsSettings}
      empty="Email related settings not found"
      list
      id="admin_settings_email"
    />
  )
}
export default EmailAlertsSettingsPage
