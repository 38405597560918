import React from 'react'

import { Controller, UseFormReturn } from 'react-hook-form'

import MarketDataFieldPicker from '_core/components/MarketDataFieldPicker'

export type FieldController = {
  disabled: boolean
  handleChange: (v: string[], onChange: (v: string[]) => void) => void
  handleDelete: (removableValue: string, value: string[], onChange: (v: string[]) => void) => void
} & Pick<UseFormReturn<any>, 'control'>

export const IndustriesController = ({
  disabled,
  control,
  inputRef,
  className,
  handleChange,
  handleDelete
}: FieldController & { inputRef: React.Ref<HTMLInputElement>; className?: string }) => {
  return (
    <Controller
      name="industries"
      control={control}
      render={({ field: { onChange, value, name } }) => (
        <MarketDataFieldPicker
          field={name}
          label="Industries"
          icon={['fas', 'industry']}
          placeholder="E.g. Real Estate"
          value={value}
          handleChange={(v) => handleChange(v, onChange)}
          handleDelete={(v) => handleDelete(v, value, onChange)}
          disabled={disabled}
          className={className}
          inputRef={inputRef}
        />
      )}
    />
  )
}

export const CountriesController = ({
  disabled,
  control,
  inputRef,
  className,
  handleChange,
  handleDelete
}: FieldController & { inputRef: React.Ref<HTMLInputElement>; className?: string }) => {
  return (
    <Controller
      name="countries"
      control={control}
      render={({ field: { onChange, value, name } }) => (
        <MarketDataFieldPicker
          field={name}
          label="Countries"
          icon={['fas', 'location-dot']}
          placeholder="E.g. United States"
          value={value}
          handleChange={(v) => handleChange(v, onChange)}
          handleDelete={(v) => handleDelete(v, value, onChange)}
          disabled={disabled}
          className={className}
          inputRef={inputRef}
        />
      )}
    />
  )
}

export const LocationNamesController = ({
  disabled,
  control,
  inputRef,
  className,
  handleChange,
  handleDelete
}: FieldController & { inputRef: React.Ref<HTMLInputElement>; className?: string }) => {
  return (
    <Controller
      name="locationName"
      control={control}
      render={({ field: { onChange, value, name } }) => (
        <MarketDataFieldPicker
          field={name}
          label="Location names"
          icon={['fas', 'location-dot']}
          placeholder="E.g. Los Angeles, California, United States"
          value={value}
          handleChange={(v) => handleChange(v, onChange)}
          handleDelete={(v) => handleDelete(v, value, onChange)}
          disabled={disabled}
          className={className}
          inputRef={inputRef}
        />
      )}
    />
  )
}

export const LocationRegionsController = ({
  disabled,
  control,
  inputRef,
  className,
  handleChange,
  handleDelete
}: FieldController & { inputRef: React.Ref<HTMLInputElement>; className?: string }) => {
  return (
    <Controller
      name="locationRegion"
      control={control}
      render={({ field: { onChange, value, name } }) => (
        <MarketDataFieldPicker
          field={name}
          label="Location regions"
          placeholder="E.g. Arizona"
          icon={['fas', 'location-dot']}
          value={value}
          handleChange={(v) => handleChange(v, onChange)}
          handleDelete={(v) => handleDelete(v, value, onChange)}
          disabled={disabled}
          className={className}
          inputRef={inputRef}
        />
      )}
    />
  )
}
