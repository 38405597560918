import React, { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react'

import { Step as JoyrideStep } from 'react-joyride'

import Paths, { DataAdminSubPaths } from 'Paths'

type OnboardingPaths = typeof Paths._events | typeof Paths._teamProfile | typeof Paths._introductions | typeof DataAdminSubPaths.privilegedCompanies | typeof Paths._search
export type ActiveOnboarding = OnboardingPaths | 'sidebar' | 'filterbar' | 'activityStatsEntryPoint' | '/activityStats' | 'activityStatsWidget'
export type Step = JoyrideStep & { headingActions?: ReactNode; primaryLabel?: string }

type ContextType = {
  active: ActiveOnboarding | undefined
  setActive: (value?: ActiveOnboarding) => void
  queue: ActiveOnboarding[]
  setQueue: Dispatch<SetStateAction<ActiveOnboarding[]>>
}

export const OnboardingContext = createContext({} as ContextType)

const OnboardingProvider = (props: { children: ReactNode }) => {
  const [queue, setQueue] = useState<ActiveOnboarding[]>([])
  const [active, setActive] = useState<ActiveOnboarding>()

  const ctx = {
    active,
    setActive,
    queue,
    setQueue
  }
  return <OnboardingContext.Provider value={ctx}>{props.children}</OnboardingContext.Provider>
}

export default OnboardingProvider
