import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import { Button, IconButton } from '_shared/buttons'
import Combobox from '_shared/forms/Combobox'

import CreatePersonOptionDialog, {
  renderOption,
  useCreatePersonOption,
  PersonOptionType,
  SuggestPersonOptionType
} from '_core/components/dialogs/CreatePersonOptionDialog'
import Empty from '_core/components/Empty'
import { transformPersonOption } from '_core/components/introductions/options'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import useDialog from '_core/hooks/useDialog'
import { useLookUpMarketDataPeople } from '_core/hooks/useLookup'
import useSidepanelClose from '_core/hooks/useSidepanelClose'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'
import useSuggestOptionCreation from '_core/hooks/useSuggestOptionCreation'

import { del, post } from 'utils/httpUtils'

import { actionButtons } from 'AppTheme'

import Paths from 'Paths'

type Contact = Pick<PersonOptionType, 'name' | 'email'>
type AddContactsProps = {
  items: IntroductionContactResp[]
  loading: boolean
  planUid: string
}

const useStyles = makeStyles()((theme) => ({
  actionStickyButtons: {
    ...actionButtons(theme, 'sticky'),
    zIndex: 1
  },
  buttons: {
    marginBottom: -77,
    [theme.breakpoints.up('sidepanel')]: {
      marginBottom: -57
    }
  },
  wrapper: {
    paddingBottom: 60, //space for infinite loader
    boxSizing: 'border-box',
    minHeight: 'calc(100vh - 138px)',
    [theme.breakpoints.up('sidepanel')]: {
      minHeight: 'calc(100vh - 118px)'
    }
  }
}))

const AddContactsForm = ({ planUid, ...props }: AddContactsProps) => {
  const [contacts, setContacts] = useState<Contact[]>([])
  const [loading, setLoading] = useState(false)
  const { isDialogOpened, openDialog, closeDialog } = useDialog()
  const { closeCreateOptionDialog, setCreatedOption, createdOption } = useCreatePersonOption(closeDialog)
  const filterOptions = (options: PersonOptionType[]) =>
    options.filter((option) => option.name && option.email && !chosenContacts?.includes(option.email))
  const { lookUpMarketDataPeople, forceAbort } = useLookUpMarketDataPeople()

  const { teamContextValue } = useContext(TeamContext)

  const handleClose = useSidepanelClose(Paths._introductions)
  const history = useHistory()
  const { updateParent } = useSidepanelPayloads()
  const { enqueueSnackbar } = useSnackbar()
  const { classes, cx } = useStyles()

  const {
    inputValue,
    open,
    options,
    optionsLoading,
    handleClose: handleComboboxClose,
    handleOpen,
    handleFocus,
    handleInputChange
  } = useAsyncCombobox<PersonOptionType>({
    loadOptions: useCallback(
      async (searchTerm: string) => {
        const result = await lookUpMarketDataPeople(`${teamContextValue.teamNumber}`, searchTerm)
        if (result) {
          return result.map((person) => ({
            name: person.full_name,
            email: person.work_email,
            jobTitle: person.job_title,
            company: person.job_company_name
          }))
        }
      },
      [lookUpMarketDataPeople]
    ),
    forceAbort
  })

  const filterWithSuggest = useSuggestOptionCreation<PersonOptionType, SuggestPersonOptionType>({
    loading: optionsLoading,
    filterFn: filterOptions,
    transformOption: transformPersonOption
  })

  useEffect(() => {
    if (props.items.length) {
      setContacts((prevState) => {
        const newPortion = props.items
          .filter(({ contactEmail }) => !prevState.find(({ email }) => email === contactEmail))
          .map((contact) => ({
            name: contact.displayAs,
            email: contact.contactEmail
          }))
        return [...prevState, ...newPortion]
      })
    }
  }, [props.items.length])

  const load = props.loading || loading
  const chosenContacts = contacts.map((contact) => contact.email)

  const handleCreationSubmit = (createdOption: Contact) => {
    setContacts((prevState) => [createdOption, ...prevState])
    closeCreateOptionDialog()
  }

  const onComboBoxChange = (event: ChangeEvent<{}>, value: (Contact | SuggestPersonOptionType)[]) => {
    const newValue = value[value.length - 1]

    if ('label' in newValue) {
      openDialog()
      setCreatedOption({
        name: newValue.name,
        email: newValue.email
      })
    } else {
      setContacts((prevState) => [
        {
          name: newValue.name,
          email: newValue.email
        },
        ...prevState
      ])
    }
  }

  const save = async () => {
    setLoading(true)

    const removedContacts = props.items.filter(({ contactEmail }) => !chosenContacts.includes(contactEmail))
    if (removedContacts.length) {
      const payloads = removedContacts.map(({ contactEmail }) => ({
        planUid,
        identifier: contactEmail
      }))

      await Promise.all(payloads.map((payload) => del('/prospecting/targetcontact', payload)))
    }

    const addedContacts = contacts.filter(({ email }) => !props.items.find(({ contactEmail }) => contactEmail === email))
    if (addedContacts.length) {
      const data = {
        planUid,
        requests: addedContacts?.map((contact) => ({
          contactName: contact.name,
          contactEmail: contact.email,
          targetingStatus: 'AssigneeIntroIdeasSought'
        }))
      }
      await post<IntroductionContactResp[]>('/prospecting/targetedcontacts', data)
    }

    setLoading(false)
    if (removedContacts.length || addedContacts.length) {
      enqueueSnackbar('Contacts list has been updated')
      updateParent({ action: 'RELOAD_LIST', value: 'introductions' })
    }
    history.push(`${Paths._introductions}/${planUid}`)
  }

  const removeContact = async (email: string) => {
    setContacts((prevState) => prevState?.filter((contact) => contact.email !== email))
  }

  return (
    <>
      <Box className={classes.wrapper}>
        <Box px={2} pt={2}>
          <Combobox<PersonOptionType, true, true>
            multiple
            placeholder="Pick a person"
            icon={['far', 'search']}
            value={contacts || []}
            options={options}
            open={open}
            loading={optionsLoading}
            inputValue={inputValue}
            onChange={onComboBoxChange}
            renderOption={renderOption}
            filterOptions={filterWithSuggest}
            disabled={load}
            onInputChange={handleInputChange}
            onOpen={handleOpen}
            onClose={handleComboboxClose}
            onFocus={handleFocus}
            getOptionLabel={(option: PersonOptionType) => option.name}
          />
        </Box>
        <Repeater
          direction="vertical"
          variant="list"
          empty={
            <Box mt={2}>
              <Empty subTitle="No contacts" icon={<FontAwesomeIcon size="3x" icon={['fat', 'users']} style={{ color: '#A7A7A7' }} />} />
            </Box>
          }
          component={ProfileItem}
          skeleton={{ size: 10, loading: props.loading }}
          items={
            contacts?.length
              ? contacts.map((contact) => ({
                  name: contact.name,
                  byline: contact.email,
                  userKey: contact.email,
                  icons: <IconButton icon={['far', 'times']} disabled={load} onClick={() => removeContact(contact.email)} size="small" disablePR />
                }))
              : []
          }
        />
      </Box>
      <CreatePersonOptionDialog opened={isDialogOpened} close={closeCreateOptionDialog} submit={handleCreationSubmit} value={createdOption} />
      <Box className={cx(classes.actionStickyButtons, classes.buttons)}>
        <Button onClick={handleClose} variant="text" color="secondary" disablePL>
          Close
        </Button>
        <Button onClick={save} disabled={load} variant="text" disablePR>
          Save
        </Button>
      </Box>
    </>
  )
}

export default AddContactsForm
