import { useState, useEffect } from 'react'

import { matchPath, useLocation } from 'react-router-dom'

export type Tab = {
  label: string
  routesMatch: string[]
  component?: () => JSX.Element
}

const useTabs = (tabs: Tab[], comparableParam?: string) => {
  const { pathname, search } = useLocation()

  const matchPathname = (path: string) =>
    matchPath(pathname, {
      path,
      exact: true,
      strict: false
    })

  const getTabIndex = (): number => {
    let index = -1
    tabs.forEach((value: Tab, i) => {
      for (const path of value.routesMatch) {
        if (!comparableParam && matchPathname(path)) {
          index = i
        }

        if (comparableParam) {
          const [pathWithoutQuery, queryParams] = path.split('?')

          const match = matchPathname(pathWithoutQuery)

          if (!queryParams && match) {
            index = i
          }

          if (queryParams && match && new URLSearchParams(queryParams).get(comparableParam) === new URLSearchParams(search).get(comparableParam)) {
            index = i
          }
        }
      }
    })
    return index
  }

  const [tabIndex, setTabIndex] = useState(getTabIndex())

  useEffect(() => {
    setTabIndex(getTabIndex())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return {
    tabIndex,
    setTabIndex
  }
}

export default useTabs
