import { cloneElement, ReactElement, useContext, useEffect, useRef, useState } from 'react'

import { Box, SelectChangeEvent } from '@mui/material'
import { Switch } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import RequestPage from '_pages/introductions/[id]'
import AddIntroductionParticipantsPage from '_pages/introductions/[id]/addParticipants'
import RequestAssigneePage from '_pages/introductions/[id]/assignee'
import RequestCollaboratorsPage from '_pages/introductions/[id]/collaborators'
import AddRequestCollaboratorsPage from '_pages/introductions/[id]/collaborators/add'
import RequestContactsPage from '_pages/introductions/[id]/contacts'
import IntroductionPage from '_pages/introductions/[id]/contacts/[id]'
import EditRequestPage from '_pages/introductions/[id]/edit'
import RequestOutcomePage from '_pages/introductions/[id]/outcome'
import RequestSharingSettingsPage from '_pages/introductions/[id]/sharingSettings'
import CreateRequestPage from '_pages/introductions/create'
import ReportRequestPage from '_pages/introductions/report'
import { isSidepanel } from '_pages/sidebar'

import { IconButton } from '_shared/buttons'
import Card, { CardContent } from '_shared/Card'
import Select from '_shared/forms/Select'

import Filters, { Controller as FilterController, OptionType } from '_core/components/filters/Introductions'
import { PeopleFiltersType } from '_core/components/filters/People'
import GridPageFrame from '_core/components/GridPageFrame'
import ContactsList from '_core/components/introductions/ContactsList'
import RequestsList, { GridHeadingButtons } from '_core/components/introductions/RequestsList'
import { useWide } from '_core/components/layout'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import PrivateRoute from '_core/components/PrivateRoute'
import SearchInput from '_core/components/SearchInput'
import Sort, { Controller as SortController, IntroductionsSortProps, sortMap } from '_core/components/sort/Introductions'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useIntroductionsAccess from '_core/hooks/useIntroductionsAccess'
import useIntroductionsUserSettings from '_core/hooks/useIntroductionsUserSettings'
import useOnboarding from '_core/hooks/useOnboarding'
import useOutlook from '_core/hooks/useOutlook'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import DynamicEntity from '_core/DynamicEntity'
import { capitalizeWord } from '_core/helpers/string'
import UserSettings from '_core/UserSettings'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type AddProps = {
  setReload: (val: boolean) => void
  updateSort: (sort: IntroductionsSortType) => void
}

type ComponentProps = {
  reload: boolean
  onPageSizeChange: (rowsPerPage: NumberToString<RowPerPageOptionsType>) => void
  onLoading: (loading: boolean, result: { total_item_count: number } | undefined) => void
  sortBy: string | undefined
}

const modeOptions: { label: string; value: IntroductionsModeType }[] = [
  { label: 'Request view', value: 'requests' },
  { label: 'Contact view', value: 'contacts' }
]

const getPayload = (params: { name: string; value?: any }[]) =>
  params.filter(({ value }) => !!value).reduce((acc, { name, value }) => ({ ...acc, [name]: Array.isArray(value) ? value : `${value}` }), {})

const useStyles = makeStyles<{ filtersOpened?: boolean }>()((theme, { filtersOpened }) => ({
  select: {
    '& > div:before, & > div:after': {
      display: 'none'
    },
    borderTop: '1px #f2f2f2 solid',
    borderBottom: '1px #f2f2f2 solid',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    [theme.breakpoints.up('md')]: {
      padding: 0,
      border: 0
    }
  },
  icons: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: filtersOpened ? 39 : 39 * 3,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1
  },
  displayVariant: {
    display: !filtersOpened ? 'block' : 'none'
  },
  sortContainer: {
    padding: theme.spacing(2),
    margin: `0 -${theme.spacing(2)} -${theme.spacing(2)}`
  },
  input: {
    marginRight: theme.spacing(0.5),
    maxWidth: filtersOpened ? 'calc(100% - 39px)' : `calc(100% - ${39 * 3}px)`,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1,
    zIndex: 2
  },
  sticky: {
    top: 132,
    position: 'sticky',
    zIndex: 90,
    background: theme.palette.background.light
  },
  search: {
    '&.opened': {
      [theme.breakpoints.down('laptop')]: {
        width: filtersOpened ? 200 : 300
      }
    }
  }
}))

const IntroductionsPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const { queryParams } = useSearchQuery<IntroductionsPageParams>()
  const [total, setTotal] = useState<number>()
  const [contentLoading, setContentLoading] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  const { payloads } = useSidepanelPayloads()
  const wide = useWide()
  const { autoHideClassName } = useAutoHideOnScrollStyles(!wide, 105)
  const { setInitial, handleChange, loading: filtersLoading, opened, toggleOpen: toggleFilterOpen, reset } = useIntroductionsUserSettings()

  const filtersProps = {
    total,
    opened,
    disabled: filtersLoading,
    contentLoading,
    toggleOpen: toggleFilterOpen,
    reset,
    handleChange
  }

  const { classes, cx } = useStyles({ filtersOpened: opened })

  useEffect(() => {
    setMobileHeader('Introductions')
  }, [setMobileHeader])

  const { startOnboarding } = useOnboarding()

  useEffect(() => {
    startOnboarding(Paths._introductions)
  }, [])

  useEffect(() => {
    if (reload) setReload(false)
  }, [reload])

  useEffect(() => {
    if (payloads && payloads.action === 'RELOAD_LIST' && payloads.value === 'introductions') {
      setReload(true)
    }
  }, [payloads])

  const { mode, sort, viewMode } = queryParams

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    setContentLoading(loading)
    setTotal(result?.total_item_count)
  }

  const sortBy = (Object.keys(sortMap) as (keyof typeof sortMap)[])
    .map((sortKey) => {
      const { asc, desc } = sortMap[sortKey]
      return { value: capitalizeWord(sortKey), condition: sort === asc || sort === desc }
    })
    .find((sort) => sort.condition)?.value

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    handleChange({ rowsPerPage })
  }

  const updateSort = (sort: IntroductionsSortType) => {
    handleChange({ sort })
  }

  const updateMode = (e: SelectChangeEvent<unknown>) => {
    const mode = e.target.value as IntroductionsModeType
    handleChange({ mode })
  }

  const updateViewMode = (viewMode: ViewModeType) => {
    handleChange({ viewMode })
  }

  const Content = (
    <>
      {mode === 'contacts' ? (
        <ContactsListComponent reload={reload} updateSort={updateSort} onLoading={onLoading} onPageSizeChange={onPageSizeChange} sortBy={sortBy} />
      ) : (
        <RequestsListComponent
          reload={reload}
          setReload={setReload}
          updateSort={updateSort}
          onLoading={onLoading}
          onPageSizeChange={onPageSizeChange}
          sortBy={sortBy}
        />
      )}
    </>
  )

  const ModeToggler = (
    <Box className={cx({ [classes.sticky]: !wide, [autoHideClassName]: !wide })}>
      <Select
        name="mode"
        variant="standard"
        disabled={filtersLoading}
        options={modeOptions}
        onChange={updateMode}
        value={mode || 'requests'}
        fullWidth
        size="small"
        className={classes.select}
      />
    </Box>
  )

  return (
    <UserSettings endpoint="/prospecting/planfetchfilter" setInitial={setInitial}>
      <Box className="introductions-demo"></Box>
      <GridPageFrame
        stickFilters
        loading={typeof total === 'undefined'}
        filterHeight={100}
        gridTitle="Introductions"
        searchPlaceholder="Search"
        filters={<Filters {...filtersProps} />}
        disabledSearch={filtersLoading}
        searchClassName={classes.search}
        heading={
          <Heading
            filters={<Filters {...filtersProps} />}
            filtersProps={{ opened: filtersProps.opened, toggleOpen: filtersProps.toggleOpen, disabled: filtersProps.disabled }}
            sortProps={{ sort, updateSort }}
            viewProps={{ viewMode, updateViewMode }}
            searchPlaceholder="Search"
          />
        }
        headingAdornmentStart={ModeToggler}
        headingAdornmentEnd={GridHeadingButtons}
        component={Content}
      />
    </UserSettings>
  )
}

const ContactsListComponent = (props: ComponentProps & Pick<AddProps, 'updateSort'>) => {
  const { queryParams } = useSearchQuery<IntroductionsPageParams>()
  const { fetchWithAbort, result: statuses } = useAbortableFetch<OptionType[]>()

  useEffect(() => {
    fetchWithAbort({ url: '/prospecting/introducerstatuses' })
  }, [fetchWithAbort])

  const {
    keyword,
    sort,
    mode,
    rowsPerPage = '10',
    assignedToEmail,
    visibility,
    entityIdentifiers,
    requestType,
    targetingStatuses,
    fetcherRole,
    unassigned
  } = queryParams

  const params: { name: string; value?: any }[] = [
    { name: 'FetcherRole', value: fetcherRole },
    { name: 'Unassigned', value: unassigned },
    { name: 'AssigneeEmailFilter', value: assignedToEmail },
    { name: 'ClosedFilter', value: requestType },
    { name: 'EntityIdentifiers', value: entityIdentifiers ? entityIdentifiers.split(',') : [] },
    { name: 'Statuses', value: targetingStatuses ? targetingStatuses.split(',') : [] },
    { name: 'ReadyForReview', value: visibility === 'shared' ? 'true' : visibility === 'mine' ? 'false' : null },
    { name: 'Sort', value: props.sortBy },
    { name: 'Direction', value: sort && (sort?.includes('Asc') ? 'Ascending' : 'Descending') }
  ]

  const url = mode === 'contacts' ? mergeUrlWithParams('/prospecting/targetedcontacts', getPayload(params)) : ''

  return (
    <DynamicEntity<{ extraProps: { addprops: Pick<AddProps, 'updateSort'> & { statuses: OptionType[] } } }>
      url={props.reload || !statuses ? null : url}
      component={ContactsList}
      addprops={{ updateSort: props.updateSort, statuses: statuses || [] }}
      keepMounted
      list
      search
      onLoading={props.onLoading}
      updatePageSize={props.onPageSizeChange}
      pageSize={+rowsPerPage}
      empty="No contacts found"
      emptySubtitle={keyword ? `No contacts found for your search '${keyword}'` : ''}
      id="contacts_list"
    />
  )
}

const RequestsListComponent = (props: ComponentProps & AddProps) => {
  const { queryParams } = useSearchQuery<IntroductionsPageParams>()

  const {
    sort,
    keyword,
    mode,
    reason,
    rowsPerPage = '10',
    unassigned,
    assignedToEmail,
    fetcherRole,
    visibility,
    beneficiaryIdentifiers,
    entityIdentifiers,
    requestType
  } = queryParams

  const params: { name: string; value?: any }[] = [
    { name: 'FetcherRole', value: fetcherRole },
    { name: 'Reason', value: reason },
    { name: 'Unassigned', value: unassigned },
    { name: 'AssignedToEmail', value: assignedToEmail },
    { name: 'ClosedFilter', value: requestType },
    { name: 'BeneficiaryIdentifiers', value: beneficiaryIdentifiers ? beneficiaryIdentifiers.split(',') : [] },
    { name: 'EntityIdentifiers', value: entityIdentifiers ? entityIdentifiers.split(',') : [] },
    { name: 'ReadyForReview', value: visibility === 'shared' ? 'true' : visibility === 'mine' ? 'false' : null },
    { name: 'OrderBy', value: props.sortBy },
    { name: 'Direction', value: sort && (sort?.includes('Asc') ? 'Ascending' : 'Descending') }
  ]

  const payload = getPayload(params)

  const url = mode === 'requests' ? mergeUrlWithParams('/prospecting/detailedplans', payload) : ''

  return (
    <DynamicEntity<{ extraProps: { addprops: AddProps } }>
      url={props.reload ? null : url}
      component={RequestsList}
      addprops={{
        setReload: props.setReload,
        updateSort: props.updateSort
      }}
      keepMounted
      list
      search
      onLoading={props.onLoading}
      updatePageSize={props.onPageSizeChange}
      pageSize={+rowsPerPage}
      empty="No requests found"
      emptySubtitle={keyword ? `No requests found for your search '${keyword}'` : ''}
      id="introduction_requests_list"
    />
  )
}

type HeadingProps = {
  filters: ReactElement
  filtersProps: Pick<PeopleFiltersType, 'opened' | 'disabled' | 'toggleOpen'>
  sortProps: Pick<IntroductionsSortProps, 'sort' | 'updateSort'>
  viewProps: {
    viewMode?: ViewModeType
    updateViewMode: (val: ViewModeType) => void
  }
  searchPlaceholder: string
}

const Heading = ({ filtersProps, sortProps, viewProps, searchPlaceholder, filters }: HeadingProps) => {
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const { viewMode, updateViewMode } = viewProps
  const { toggleOpen: toggleFilterOpen, opened: filtersOpened, disabled } = filtersProps
  const isOutlook = useOutlook()
  const hasSidebar = isOutlook || isSidepanel()
  const anchorRef = useRef<HTMLDivElement | null>(null)
  const [sortCollapsed, setSortCollapsed] = useState(true)
  const { classes } = useStyles({ filtersOpened })

  const toggleSortOpen = () => {
    setSortCollapsed((prevState: boolean) => !prevState)
  }

  const toggleView = () => updateViewMode(viewMode === 'collapsed' ? 'expanded' : 'collapsed')

  return (
    <Card elevation={0} square className={autoHideClassName} sticky={hasSidebar ? 88 : 61}>
      <CardContent>
        <div ref={anchorRef}>
          <Box display="flex">
            <SearchInput disabled={disabled} placeholder={searchPlaceholder} variant="collapsed" opened wrapperClassName={classes.input} />
            <Box className={classes.icons}>
              <IconButton
                hint={viewMode === 'expanded' ? 'Collapse view' : 'Expand view'}
                onClick={toggleView}
                size="small"
                icon={['fas', viewMode === 'expanded' ? 'chart-simple-horizontal' : 'bars']}
                className={classes.displayVariant}
                disabled={disabled}
              />
              <SortController collapsed={sortCollapsed} toggleCollapsed={toggleSortOpen} className={classes.displayVariant} disabled={disabled} />
              <FilterController opened={filtersOpened} toggleOpen={toggleFilterOpen} disabled={disabled} />
            </Box>
          </Box>
        </div>
        {cloneElement(filters, { anchorEl: anchorRef.current })}
        <Sort collapsed={sortCollapsed} {...sortProps} className={classes.sortContainer} />
      </CardContent>
    </Card>
  )
}

const IntroductionsPages = () => {
  const { isUserIntroductionsVisible: hasAccess } = useIntroductionsAccess()

  return (
    <Switch>
      <PrivateRoute exact hasAccess={hasAccess} path={`${Paths._introductions}/create`} component={CreateRequestPage} />
      <PrivateRoute exact hasAccess={hasAccess} path={`${Paths._introductions}/report`} component={ReportRequestPage} />
      <PrivateRoute exact hasAccess={hasAccess} path={`${Paths._introductions}/:id/sharing-settings`} component={RequestSharingSettingsPage} />
      <PrivateRoute exact hasAccess={hasAccess} path={`${Paths._introductions}/:id/add-participants`} component={AddIntroductionParticipantsPage} />
      <PrivateRoute exact hasAccess={hasAccess} path={`${Paths._introductions}/:id/edit`} component={EditRequestPage} />
      <PrivateRoute exact hasAccess={hasAccess} path={`${Paths._introductions}/:id/assignee`} component={RequestAssigneePage} />
      <PrivateRoute exact hasAccess={hasAccess} path={`${Paths._introductions}/:id/outcome`} component={RequestOutcomePage} />
      <PrivateRoute exact hasAccess={hasAccess} path={`${Paths._introductions}/:id/collaborators/add`} component={AddRequestCollaboratorsPage} />
      <PrivateRoute exact hasAccess={hasAccess} path={`${Paths._introductions}/:id/collaborators`} component={RequestCollaboratorsPage} />
      <PrivateRoute
        exact
        hasAccess={hasAccess}
        path={[`${Paths._introductions}/:id/contacts`, `${Paths._introductions}/:id/contacts/add`]}
        component={RequestContactsPage}
      />
      <PrivateRoute exact hasAccess={hasAccess} path={`${Paths._introductions}/:id/contacts/:contactId`} component={IntroductionPage} />
      <PrivateRoute exact hasAccess={hasAccess} path={`${Paths._introductions}/:id`} component={RequestPage} />
      <PrivateRoute exact hasAccess={hasAccess} path={Paths._introductions} component={IntroductionsPage} />
    </Switch>
  )
}

export default IntroductionsPages
