import { ComponentProps } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import Relationship from '_core/components/Relationship'
import Widget, { SeeAllAction } from '_core/components/widgets'

import useStyles from './styles'

const RelationshipsWidget = (props: { loading: boolean; link: string; items: ComponentProps<typeof Relationship>[]; companyName: string }) => {
  const { classes } = useStyles()

  const { link, companyName, loading, items } = props

  return (
    <Widget>
      <Heading underlined title="Key relationships" icon={['far', 'star']} action={<SeeAllAction link={items.length ? link : ''} />} />
      {(!!items.length || props.loading) && <Repeater direction="vertical" component={Relationship} skeleton={{ size: 3, loading }} items={items} />}
      {!items.length && !props.loading && (
        <Empty
          className={classes.empty}
          subTitle={`You have no key relationships with ${companyName}`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'star']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

export default RelationshipsWidget
