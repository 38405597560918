import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { makeStyles } from 'tss-react/mui'

import Empty from '_core/components/Empty'
import ReachDotAlignButton from '_core/components/ReachDotAlignButton'

const useStyles = makeStyles()(() => ({
  content: {
    maxWidth: 540,
    margin: '0 auto'
  }
}))

const FeatureNoAccess = () => {
  const { classes } = useStyles()
  const reachTeamBtn = <ReachDotAlignButton />

  return (
    <Empty
      className={classes.content}
      icon={<FontAwesomeIcon size="4x" style={{ color: '#A7A7A7' }} icon={['fat', 'envelope-open-text']} />}
      title="The feature is not enabled"
      subTitle={<>Please reach out to {reachTeamBtn} to get further assistance.</>}
    />
  )
}

export default FeatureNoAccess
