import { useEffect } from 'react'

import { useUserRelations } from '_core/hooks/useTeam'

const useUserMembership = (teamNumber: number) => {
  const { getUserRelations, userRelations } = useUserRelations()

  useEffect(() => {
    if (teamNumber) {
      ;(async function () {
        await getUserRelations()
      })()
    }
  }, [teamNumber])

  const userMembership = userRelations?.find((relation) => relation.teamNumber === teamNumber)
  const role = userMembership?.teamRoleType

  return {
    userRelations,
    userMembership,
    contributor: userMembership ? role === 'Member' : undefined,
    curator: userMembership ? role === 'Curator' : undefined,
    reader: userMembership ? role === 'Reader' : undefined
  }
}

export default useUserMembership
