import React, { useEffect, useState, Dispatch, SetStateAction } from 'react'

import { Box, Container } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridSelectionModel, GridSortModel } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import Page from '_shared/Page'
import Popover from '_shared/popovers/Popover'
import Typography from '_shared/Typography'

import { MarkPublicDialog } from '_core/components/dialogs/UpdateRelationshipPrivacy'
import Grid, { DataGrid, GridPaginationType } from '_core/components/grid'
import { actionButtonColumn, ActionCell, scoreColumn } from '_core/components/grid/columns'
import GridHeadingControl from '_core/components/GridHeadingControl'
import Heading from '_core/components/Heading'
import { Narrow, useWide, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import PrivateIdentifierItem from '_core/components/PrivateIdentifierItem'
import SearchInput from '_core/components/SearchInput'

import useDialog from '_core/hooks/useDialog'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'

import { mergeUrlWithParams } from 'utils/httpUtils'

type PrivateIdentifier = {
  id: string
  score: string
  identifiers?: {
    main: string
    other: string[]
  }
  isPrivateRelations: boolean
  handleOpenDialog: () => void
}

type AliasType = {
  Md5: string
  Text: string
  Type: 'PersonTwoPartName' | 'PersonCompleteName' | 'EmailAddress'
}

type PrivateListProps = {
  items: {
    ContactMd5: string
    ScorePoints?: number
    MarkedPrivate?: boolean
    Identifiers: {
      IdProperty: string
      Aliases: AliasType[]
    }[]
  }[]
  loading: boolean
  selected: string
  updateSort: (val: ScoreType) => void
  sort?: ScoreType
  forceNarrow?: boolean
  setReload: Dispatch<SetStateAction<boolean>>
} & GridPaginationType

const scoreCentredColumn: GridColDef = {
  ...scoreColumn,
  align: 'center',
  headerAlign: 'center'
}

const actionColumn: GridColDef = {
  ...actionButtonColumn,
  renderCell: (params: GridRenderCellParams) => <ActionCell action={<MarkPublicDialog.TriggerButton onClick={params.row.handleOpenDialog} />} />
}

const IdentifierCell = React.memo((params: GridRenderCellParams) => {
  const others = params.row.identifiers?.other || []
  const extraOthers = others.length > 1 ? others.slice(1) : []

  return (
    <Box>
      <Typography variant="h4" style={{ maxWidth: params.colDef.width || 300 - 16 }} noWrap>
        {params.row.identifiers?.main}
      </Typography>
      {!!others.length && (
        <Box display="inline-grid" gridTemplateColumns="1fr auto" gap={1.5} alignItems="center">
          <Typography noWrap>{others[0]}</Typography>

          {!!extraOthers.length && (
            <Popover placement="bottom" maxWidth={400} triggerElement={<Typography>{extraOthers.length} more</Typography>}>
              <Box>
                <Heading title="Additional person identifiers" icon={['far', 'address-card']} count={extraOthers.length} />
                {extraOthers.map((name: string) => (
                  <Typography key={name} noWrap>
                    {name}
                  </Typography>
                ))}
              </Box>
            </Popover>
          )}
        </Box>
      )}
    </Box>
  )
})

const identifierColumn: GridColDef = {
  field: 'identifier',
  headerName: 'Person',
  width: 300,
  minWidth: 150,
  flex: 1,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <IdentifierCell {...params} />
}

const useStyles = makeStyles()(() => ({
  gridWrapper: {
    position: 'relative',
    display: 'flex',
    minHeight: 'calc(100vh - 275px)'
  }
}))

const PrivateList = (props: PrivateListProps) => {
  const [list, setList] = useState<PrivateIdentifier[]>([])
  const [selected, setSelected] = useState<string[]>([])
  const { updateQuery } = useSearchQuery()
  const {
    isDialogOpened,
    dialogContentProps: openedDialog,
    openDialog,
    hideDialog,
    successMode,
    openSuccess
  } = useDialog<{ userKeys: string[]; userName?: string }>({
    userKeys: []
  })

  const transformData = (data: AliasType[]) => {
    if (data.length) {
      const names: string[] = []
      const fullNames: string[] = []
      const emails: string[] = []

      data.forEach((el) => {
        if (el.Type === 'PersonTwoPartName') names.push(el.Text)
        if (el.Type === 'PersonCompleteName') fullNames.push(el.Text)
        if (el.Type === 'EmailAddress') emails.push(el.Text)
      })

      if (names[0]) {
        const [, ...rest] = names
        return {
          main: names[0],
          other: [...emails, ...rest, ...fullNames]
        }
      }

      if (!names[0] && fullNames[0]) {
        const [, ...rest] = fullNames
        return {
          main: fullNames[0],
          other: [...emails, ...rest]
        }
      }

      if (!names[0] && !fullNames[0] && emails[0]) {
        const [, ...rest] = emails
        return {
          main: emails[0],
          other: [...rest]
        }
      }
    }
  }

  const wide = useWide()

  const removePrivate = () => setList((prevState) => prevState.filter((person) => person.id !== openedDialog?.userKeys[0]))

  useEffect(() => {
    setList(
      props.items?.map((person) => {
        const data = transformData(person.Identifiers[0].Aliases)

        return {
          id: person.ContactMd5,
          score: person?.ScorePoints ? `${person.ScorePoints}` : '-',
          identifiers: data,
          isPrivateRelations: !!person?.MarkedPrivate,
          handleOpenDialog: () => openDialog({ userKeys: [person.ContactMd5], userName: data?.main })
        }
      })
    )
  }, [props.items])

  const updateSort = (model: GridSortModel) => {
    if (model?.length) {
      const sort = model[0].sort === 'asc' ? 'ScoreAsc' : 'ScoreDesc'
      updateQuery({ sort })
    }
  }

  const handleSelect = (selectionModel: GridSelectionModel) => {
    setSelected(selectionModel as string[])
  }

  const MarkSelectedAsPublic = (
    <GridHeadingControl
      icon={['far', 'lock-open']}
      onClick={() => openDialog({ userKeys: selected })}
      hint="Mark selected as shared"
      disabled={!selected.length || !list.length}
    />
  )

  const controls = [MarkSelectedAsPublic]

  return (
    <>
      <Wide>
        <DataGrid
          rows={list}
          rowHeight={62}
          columns={[identifierColumn, scoreCentredColumn, actionColumn]}
          controls={controls}
          setSortModel={updateSort}
          onSelect={handleSelect}
          loading={props.loading}
          setPageSize={props.setPageSize}
          checkboxSelection={!!controls.length}
          paging={props.paging}
          total={props.total}
        />
      </Wide>
      <Narrow>
        <Container>
          <Box mt={-2} mb={-1}>
            <Repeater
              direction="vertical"
              variant="homepage"
              component={PrivateIdentifierItem}
              skeleton={{ size: 10, loading: props.loading }}
              items={list}
              empty="No private relationships found"
            />
          </Box>
        </Container>
      </Narrow>
      <MarkPublicDialog
        success={successMode}
        openSuccess={openSuccess}
        opened={isDialogOpened}
        close={hideDialog}
        {...openedDialog}
        {...(wide ? { handleRefresh: props.setReload } : { update: removePrivate })}
      />
    </>
  )
}

const PrivateRelationshipsList = () => {
  const [reload, setReload] = useState<boolean>(false)
  const { queryParams } = useSearchQuery<GridParams>()
  const { sort, keyword } = queryParams
  const { result } = useEntityEndpoint<{ results: ProfileType } | null>('/me/profile')
  const { classes } = useStyles()

  const userKey = result?.results.UserKey

  useEffect(() => {
    if (reload) setReload(false)
  }, [reload])

  const url =
    userKey && !reload
      ? mergeUrlWithParams('/privacy', { contributorKey: userKey, scope: 'ContributorMeOnlyComposite', SortBy: sort || 'ScoreDesc' })
      : null

  const List = (
    <DynamicEntity<{ extraProps: { addprops: Pick<PrivateListProps, 'setReload'> } }>
      url={url}
      addprops={{ setReload }}
      component={PrivateList}
      list
      infinite
      keepMounted
      search
      empty="No private relationships found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      id="private_relationships"
    />
  )

  return (
    <Page>
      <Wide>
        <Box className={classes.gridWrapper}>
          <Grid>
            <Grid.Heading title="Private relationships">
              <Box display="flex" alignItems="center">
                <Box mx={2}>
                  <SearchInput placeholder="Search for people" variant="collapsed" opened />
                </Box>
              </Box>
            </Grid.Heading>
            {List}
          </Grid>
        </Box>
      </Wide>
      <Narrow>{List}</Narrow>
    </Page>
  )
}

export default PrivateRelationshipsList
