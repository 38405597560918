import React, { useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/material'
import { GridSelectionModel } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import { PrivilegedCompany } from '_pages/data-administration/privileged-companies/add'

import RemoveSelectedDialog from '_core/components/dialogs/RemoveSelected'
import { ExpandableContent as Content } from '_core/components/ExpandableDetails'
import { DataGrid, GridPaginationType } from '_core/components/grid'
import { dateColumn, editorColumn, nameColumn, websiteColumn } from '_core/components/grid/columns'
import { Narrow, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'

import useDialog from '_core/hooks/useDialog'
import { useLookUpCompaniesByIds } from '_core/hooks/useLookup'

import { del } from 'utils/httpUtils'
import { formatDateTime } from 'utils/Utils'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sidepanel')]: {
      rowGap: theme.spacing(1),
      columnGap: theme.spacing(2),
      gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)'
    }
  }
}))

export type CompaniesProps = {
  loading: boolean
  items: PrivilegedCompany[]
  handleReload: () => void
  teamNumber: number
} & GridPaginationType

const PrivilegedCompaniesList = ({ teamNumber, handleReload, ...props }: CompaniesProps) => {
  const [selected, setSelected] = useState<number[]>([])
  const { isDialogOpened: isRemoveDialogOpened, openDialog: openRemoveDialog, closeDialog: closeRemoveDialog, openSuccess, successMode } = useDialog()
  const { lookUpCompaniesByIds, companiesByIds: fullData, loading: fullDataLoading } = useLookUpCompaniesByIds()
  const { classes } = useStyles()

  useEffect(() => {
    if (props.items.length) {
      const ids = props.items.filter((item) => !item.companyName).map((item) => item.companyMd5)
      ids.length && lookUpCompaniesByIds(ids)
    }
  }, [props.items])

  const showFullInfo = !!props.items.length && props.items.some((item) => item.companyName && item.companyUrl)
  const loading = props.loading || fullDataLoading

  const items = props.items.map((company) => {
    const fullCompanyData = fullData?.data.find(({ CompanyMd5, BestUrlText }) => {
      return CompanyMd5 === company.companyMd5 || BestUrlText === company.idText || BestUrlText === company.companyUrl
    })
    const url = company.companyUrl || (company.idText?.includes('.') ? company.idText : fullCompanyData?.BestUrlText)
    const name = fullCompanyData?.CompanyNameText || company.companyName || company.idText || company.companyMd5
    const hasExtraInfo = Boolean(company.viaWhomEmail || company.lastModified)

    const commonProps = {
      id: company.rowNumber,
      name,
      website: url,
      logoUrl: url,
      companyMd5: company.companyMd5,
      byline: name === url ? '' : url,
      link: `${Paths._companies}/${company.companyMd5 || url}`,
      sidepanel: true,
      variant: 'expandable'
    }

    if (hasExtraInfo) {
      return {
        ...commonProps,
        editor: company.viaWhomEmail,
        editorLink: `${Paths._people}/${company.viaWhomEmail}`,
        date: company.lastModified,
        byline3: (
          <Box className={classes.content}>
            <Content
              items={[
                {
                  field: 'date',
                  value: formatDateTime(company.lastModified),
                  title: 'Date',
                  icon: ['far', 'calendar-alt']
                },
                { field: 'editor', value: company.viaWhomEmail, title: 'Updated by', icon: ['fas', 'user-tie'] }
              ]}
              isExpandedView
            />
          </Box>
        )
      }
    }

    return commonProps
  })

  const handleSelect = (selectionModel: GridSelectionModel) => {
    setSelected(selectionModel as number[])
  }

  const handleRemove = async () => {
    const payload = selected
      .map((id) => {
        const company = items.find((item) => item.id === id)
        return company?.companyMd5
      })
      .filter(Boolean)
    await del(`/companies/privcos?teamNumber=${teamNumber}`, payload)
    openSuccess()
  }

  const handleSuccessClose = () => {
    closeRemoveDialog()
    handleReload()
  }

  const columns = useMemo(
    () =>
      [
        { column: nameColumn },
        { column: websiteColumn },
        { column: editorColumn, condition: showFullInfo },
        { column: dateColumn, condition: showFullInfo }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || condition)
        .map(({ column }) => column),
    [showFullInfo]
  )

  const removeCompanies = useMemo(
    () => (
      <>
        <RemoveSelectedDialog.TriggerEl open={openRemoveDialog} disabled={!selected.length} />
        <RemoveSelectedDialog
          content="Removing a company makes it visible to others."
          isOpened={isRemoveDialogOpened}
          close={closeRemoveDialog}
          successClose={handleSuccessClose}
          handleRemove={handleRemove}
          success={successMode}
        />
      </>
    ),
    [selected.length, isRemoveDialogOpened, closeRemoveDialog, openRemoveDialog, successMode, selected.length]
  )

  return (
    <>
      <Wide>
        <DataGrid
          rows={!props.loading && !fullDataLoading ? items : []}
          columns={columns}
          controls={[removeCompanies]}
          loading={loading}
          setPageSize={props.setPageSize}
          paging={props.paging}
          total={props.total}
          onSelect={handleSelect}
          checkboxSelection
        />
      </Wide>
      <Narrow>
        <Box px={2}>
          <Repeater direction="vertical" variant="card" component={ProfileItem} skeleton={{ size: 5, loading }} items={items} />
        </Box>
      </Narrow>
    </>
  )
}

export default PrivilegedCompaniesList
