import React, { useEffect, useContext, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import Chip, { FilterChipLabel } from '_core/components/Chip'
import Empty from '_core/components/Empty'
import { AppliedFilters } from '_core/components/filters'
import { transformFilters } from '_core/components/filters/MarketDataPeopleSearch'
import InfiniteList from '_core/components/InfiniteList'
import { Narrow } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import MarketDataSearchLink from '_core/components/MarketDataSearchLink'
import ProfileItem from '_core/components/ProfileItem'
import Topbar from '_core/components/Topbar'

import { PeopleType } from '_core/hooks/useSimilarPeople'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type SimilarPeopleProps = {
  similar: PeopleType | null
  getSimilar: () => Promise<void>
}

const SimilarPeople = ({ similar, getSimilar }: SimilarPeopleProps) => {
  const { setSubHeader } = useContext(LayoutContext)
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState<boolean>(false)

  const total = similar?.total || 0

  useEffect(() => {
    setSubHeader(`Similar People ${total ? `· ${total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, total])

  const items = similar?.data?.map((person) => ({
    name: person.fullName,
    score: person.personMd5 ? person.score : undefined,
    link: person.personMd5
      ? `${Paths._people}/${person.personMd5}`
      : mergeUrlWithParams(`${Paths._people}/${id}/similar/${person.fullName}`, {
          email: person.workEmail,
          company: person.jobCompanyName
        }),
    sidepanel: true,
    byline: person.jobTitle,
    byline2: person.jobCompanyName
  }))

  const loadMore = async () => {
    setLoading(true)
    await getSimilar()
    setLoading(false)
  }

  const { email, ...filters } = similar?.filters || {}
  const transformedFilters = transformFilters(filters)
  const filtersCount = Object.keys(transformedFilters).length

  return (
    <Narrow>
      <Topbar nativeBack autoHideOnScroll action={<MarketDataSearchLink entity="person" peopleFilters={JSON.stringify(filters)} variant="icon" />} />
        <AppliedFilters disabled={!filtersCount} filtersCount={filtersCount}>
          {transformedFilters.map(({ fieldLabel, value }) => {
            return <Chip key={value} label={<FilterChipLabel label={fieldLabel} value={value} />} color="secondary" size="small" />
          })}
        </AppliedFilters>
        <InfiniteList loading={loading} next={loadMore} dataLength={total} hasMore={!!similar?.scrollToken}>
          <Repeater
            virtualized
            direction="vertical"
            component={ProfileItem}
            skeleton={{ size: 5, loading: !similar }}
            variant="list"
            items={items || []}
            empty={
              <Empty
                title="No records were found"
                action={
                  <Box display="flex" justifyContent="center">
                    <MarketDataSearchLink entity="person" peopleFilters={JSON.stringify(filters)} />
                  </Box>
                }
                icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
              />
            }
          />
        </InfiniteList>
    </Narrow>
  )
}

export default SimilarPeople
