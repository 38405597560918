import { useContext } from 'react'

import { Route, Switch, useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Repeater from '_core/components/lists/Repeater'
import MessageDetails, { Actions, Details, MessageDetailsProps } from '_core/components/MessageDetails'
import ProfileItem from '_core/components/ProfileItem'
import Topbar from '_core/components/Topbar'

import DynamicEntity from '_core/DynamicEntity'
import { stringifyUrl } from '_core/helpers/browser'

import Paths from 'Paths'

const baseURL = `${Paths._messages}/:messageId`

const Component = (props: ({ loading: true } & MessageDetailsProps) | ({ loading: false } & Required<MessageDetailsProps>)) => {
  const participants =
    props.participants?.map((p) => ({
      name: p.name,
      byline: p.email,
      userKey: p.email,
      link: stringifyUrl(`${Paths._people}/${p.email}`, { name: p.name, email: p.email })
    })) || []

  const companies =
    props.companies?.map((item) => ({
      name: item.corpLevelCompanyName,
      byline: item.matchedUrlText,
      logoUrl: item.matchedUrlText,
      link: `${Paths._companies}/${item.companyMd5}`
    })) || []

  return (
    <Switch>
      <Route path={`${baseURL}/participants`}>
        <Topbar nativeBack title={props.rawSubject || '(No subject)'} sub={`Participants · ${participants?.length || 0}`} />
        <Repeater direction="vertical" variant="list" component={ProfileItem} skeleton={{ size: 10, loading: props.loading }} items={participants} />
      </Route>
      <Route path={`${baseURL}/companies`}>
        <Topbar nativeBack title={props.rawSubject || '(No subject)'} sub={`Companies · ${companies?.length || 0}`} />
        <Repeater direction="vertical" variant="list" component={ProfileItem} skeleton={{ size: 10, loading: props.loading }} items={companies} />
      </Route>

      <Route>
        {props.loading ? (
          <MessageDetails
            loading={true}
            actions={<Actions loading={props.loading} rawSubject={props.rawSubject} webLink={props.webLink} />}
            details={<Details {...props} />}
          />
        ) : (
          <MessageDetails
            loading={props.loading}
            rawSubject={props.rawSubject}
            sentDateTime={props.sentDateTime}
            participants={participants}
            companies={companies}
            actions={<Actions loading={props.loading} rawSubject={props.rawSubject} webLink={props.webLink} />}
            details={<Details {...props} />}
          />
        )}
      </Route>
    </Switch>
  )
}
const MessagePage = () => {
  const { teamContextValue } = useContext(TeamContext)
  const { messageId } = useParams<{ messageId: string }>()

  return (
    <DynamicEntity
      url={`/messages/${messageId}?TeamNumber=${teamContextValue.teamNumber}`}
      component={Component}
      empty="No results found"
      emptySubtitle="No data"
      id="meeting"
    />
  )
}

export default MessagePage
