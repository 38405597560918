import { ReactElement, ReactNode } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { Columns, Column, SidepanelWide, SidepanelNarrow } from '_core/components/layout'

const useStyles = makeStyles<{ sidepanel?: boolean }>()((theme, { sidepanel }) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: sidepanel ? 'calc(100vh - 177.5px)' : 'calc(100vh - 150.5px)',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  borderBottom: {
    borderBottom: `1px solid #f2f2f2`
  },
  column: {
    '&:first-of-type': {
      maxWidth: '100%',
      minHeight: 194,
      maxHeight: 194,
      [theme.breakpoints.up('sm')]: {
        height: 'inherit',
        maxHeight: '100%',
        maxWidth: '50%',
        borderBottom: 'unset',
        borderRight: `1px solid #f2f2f2`
      }
    },
    '&:last-of-type': {
      maxWidth: '100%',
      height: 'calc(100% - 194px)',
      [theme.breakpoints.up('sm')]: {
        height: 'inherit',
        maxWidth: '50%'
      }
    }
  },
  columnContent: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column'
  }
}))

const AuditColumn = (props: { heading: ReactElement; children: ReactNode }) => {
  const sidepanel = isSidepanel()
  const { classes } = useStyles({ sidepanel })

  return (
    <>
      <Box p={2} className={classes.borderBottom}>
        {props.heading}
      </Box>
      <Box className={classes.columnContent}>{props.children}</Box>
    </>
  )
}

const AuditColumns = (props: { entities: ReactElement | null; children: ReactNode }) => {
  const sidepanel = isSidepanel()
  const { classes, cx } = useStyles({ sidepanel })

  return (
    <>
      <SidepanelWide>
        <Columns spacing={0} className={classes.wrapper}>
          <Column xs={6} className={classes.column}>
            {props.entities}
          </Column>
          <Column xs={6} className={classes.column}>
            {props.children}
          </Column>
        </Columns>
      </SidepanelWide>
      <SidepanelNarrow>
        <Columns spacing={0} className={classes.wrapper}>
          {props.entities && (
            <>
              <Column xs={6} className={cx(classes.column, classes.borderBottom)}>
                {props.entities}
              </Column>
              <Column xs={6} className={classes.column}>
                <Box className={classes.columnContent}>{props.children}</Box>
              </Column>
            </>
          )}
          {!props.entities && props.children}
        </Columns>
      </SidepanelNarrow>
    </>
  )
}

AuditColumns.Column = AuditColumn

export default AuditColumns
