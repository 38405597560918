import React, { useContext, useEffect, useMemo, useState } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'

import DeleteIntroductionRequestDialog from '_core/components/dialogs/DeleteIntroductionRequest'
import UpdateRequestTypeDialog from '_core/components/dialogs/UpdateIntroductionRequestType'
import { defineStatus } from '_core/components/introductions/StatusChip'
import AssigneeWidget from '_core/components/widgets/IntroductionAssignee'
import CollaboratorsWidget from '_core/components/widgets/IntroductionCollaborators'
import ContactsWidget from '_core/components/widgets/IntroductionContacts'
import MainInfoWidget, { ActionType } from '_core/components/widgets/IntroductionMainInfo'
import OutcomeWidget from '_core/components/widgets/IntroductionOutcome'
import SharingSettingsWidget from '_core/components/widgets/IntroductionSharingSettings'

import useActiveDirectoryPersonData from '_core/hooks/useActiveDirectoryPersonData'
import useAssignee from '_core/hooks/useAssignee'
import useCollaboratorsAccess, { Colleague, Team } from '_core/hooks/useCollaboratorsAccess'
import useDialog from '_core/hooks/useDialog'
import useIntroductionReason from '_core/hooks/useIntroductionReason'
import { BeneficiaryType } from '_core/hooks/useIntroductionRequestForm'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { sum } from '_core/helpers/numbers'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type RequestDetailsProps = {
  request: IntroductionRequestResp
  loading: boolean
}

const RequestDetails = ({ request, ...props }: RequestDetailsProps) => {
  const { queriedByAppUserKey, querierIsLtnManager, querierHasWriteAccess } = request || {}
  const plan = request?.plan || {}

  const {
    created,
    planSummary,
    planDescription,
    planUid,
    requesterEmail,
    assignedToAppUserKey,
    readyForReview,
    beneficiaryPersonName,
    beneficiaryCompanyName,
    beneficiaryPersonEmail,
    beneficiaryCompanyUrl,
    creatorDotAppUserKey
  } = plan
  const getReason = useIntroductionReason()
  const { setMobileHeader } = useContext(LayoutContext)
  const { updateParent } = useSidepanelPayloads()
  const {
    dialogContentProps: openedDialog,
    openDialog,
    closeDialog,
    successMode,
    openSuccess
  } = useDialog<'delete' | 'updateRequestType' | null>(null)
  const { person: requester, loading: requesterLoading } = useActiveDirectoryPersonData(requesterEmail)
  const { person: creator, loading: creatorLoading } = useActiveDirectoryPersonData(
    requesterEmail?.toLowerCase() === creatorDotAppUserKey?.toLowerCase() ? '' : creatorDotAppUserKey
  )
  const { teamAccesses, appUserAccesses } = useCollaboratorsAccess(request?.appUserAccesses, request?.teamAccesses, props.loading)
  const { assignee, loading: assigneeLoading } = useAssignee(planUid, assignedToAppUserKey)
  const [closedOut, setClosedOut] = useState<string>()
  const reason = getReason(plan.reason)

  useEffect(() => {
    setMobileHeader(planSummary, !planSummary)
  }, [setMobileHeader, planSummary])

  useEffect(() => {
    setClosedOut(plan.closedOut)
  }, [plan.closedOut])

  const loading = props.loading || requesterLoading || creatorLoading
  const isCreator = !!planUid && queriedByAppUserKey === creatorDotAppUserKey
  const isRequester = !!planUid && queriedByAppUserKey === requesterEmail

  const beneficiaryType: BeneficiaryType = beneficiaryCompanyName ? 'company' : 'person'
  const beneficiary = {
    name: !loading ? beneficiaryCompanyName || beneficiaryPersonName : '',
    url: !loading ? beneficiaryCompanyUrl || beneficiaryPersonEmail : ''
  }

  const contacts = (request?.contacts || []).slice(0, 3).map((contact) => ({
    name: contact.displayAs,
    userKey: contact.contactEmail,
    link: `${Paths._introductions}/${planUid}/contacts/${contact.contactEmail}`
  }))

  const colleagues = (appUserAccesses || []).slice(0, 3).map((person: Colleague) => ({
    name: person.name,
    userKey: person.email,
    link: `${Paths._people}/${person.email}`
  }))

  const teams = (teamAccesses || []).slice(0, 3).map((team: Team) => ({
    name: team.teamName,
    link: `${Paths._teams}/${team.teamNumber}/members`
  }))

  const actions: ActionType[] = useMemo(
    () =>
      [
        {
          name: 'Edit request',
          icon: ['far', 'edit'] as IconProp,
          link: `${Paths._introductions}/${planUid}/edit`,
          condition: querierHasWriteAccess && !closedOut
        },
        {
          name: 'Delete request',
          icon: ['far', 'trash'] as IconProp,
          action: () => openDialog('delete'),
          condition: isCreator || querierIsLtnManager
        },
        {
          name: 'Close request',
          icon: ['far', 'box-archive'] as IconProp,
          action: () => openDialog('updateRequestType'),
          condition: (isCreator || querierIsLtnManager) && !closedOut
        },
        {
          name: 'Make request active',
          icon: ['far', 'box-open'] as IconProp,
          action: () => openDialog('updateRequestType'),
          condition: (isCreator || querierIsLtnManager) && !!closedOut
        }
      ].filter((item) => item.condition || loading),
    [isCreator, querierIsLtnManager, querierHasWriteAccess, loading, closedOut, planUid]
  )

  const status = defineStatus(planUid, !!closedOut)

  const reload = () => updateParent({ action: 'RELOAD_LIST', value: 'introductions' })

  return (
    <>
      <MainInfoWidget
        actions={actions}
        loading={loading}
        status={status}
        reason={reason}
        headline={planSummary}
        description={planDescription}
        created={created}
        creator={creator}
        requester={requester}
        beneficiary={beneficiary}
        beneficiaryType={beneficiaryType}
      />
      {(isCreator || isRequester) && <SharingSettingsWidget planUid={planUid} readyForReview={!!readyForReview} loading={loading} />}
      {!!readyForReview && (
        <AssigneeWidget loading={loading || assigneeLoading} planUid={planUid} assignee={assignee} querierIsLtnManager={querierIsLtnManager} />
      )}
      <ContactsWidget
        items={contacts}
        count={request?.contacts?.length}
        loading={loading}
        planUid={planUid}
        querierHasWriteAccess={querierHasWriteAccess}
        closedOut={!!closedOut}
      />
      {!!readyForReview && (
        <CollaboratorsWidget
          planUid={planUid}
          isCreator={isCreator}
          loading={!appUserAccesses || !teamAccesses}
          count={sum(appUserAccesses?.length, teamAccesses?.length)}
          colleagues={colleagues}
          teams={teams}
        />
      )}
      <OutcomeWidget
        loading={loading}
        planUid={planUid}
        querierIsLtnManager={querierIsLtnManager}
        isCreator={isCreator}
        date={plan.outcomeDate}
        memo={plan.outcomeMemo}
        usd={plan.outcomeUsd}
        rating={plan.outcomeRating}
        editorUserKey={plan.outcomeEditorAppUserKey}
      />
      <DeleteIntroductionRequestDialog
        isOpened={openedDialog === 'delete'}
        closeDialog={closeDialog}
        reload={reload}
        items={[planUid]}
        openSuccess={openSuccess}
        successMode={successMode}
      />
      <UpdateRequestTypeDialog
        outputType={closedOut ? 'active' : 'closed'}
        isOpened={openedDialog === 'updateRequestType'}
        closeDialog={closeDialog}
        planUid={planUid}
        openSuccess={openSuccess}
        successMode={successMode}
        setUpdatedValue={setClosedOut}
        updateParent={updateParent}
      />
    </>
  )
}

export default RequestDetails
