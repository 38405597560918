import React from 'react'

import { Box } from '@mui/material'

import Empty from '_core/components/Empty'

const AuditEmpty = () => (
  <Box height="calc(100vh - 159px)">
    <Empty title="No audit identities found" />
  </Box>
)

export default AuditEmpty
