import { useMemo } from 'react'

import { List, ListItem, ListItemText, Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { invalidReasonMap } from '_pages/manual-edits/data'

import { Button } from '_shared/buttons'
import Typography from '_shared/Typography'

import { EntitiesList } from '_core/components/audit/AuditEntities'
import AuditColumns from '_core/components/audit/AuditLayout'
import { useSidepanelWide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import NextStepFrame from '_core/components/NextStepFrame'

import useAuditEntities from '_core/hooks/useAuditEntities'
import useAuditTuples from '_core/hooks/useAuditTuples'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  title: {
    lineHeight: '20px',
    fontSize: 15,
    padding: `0px ${theme.spacing(2)}`
  },
  primary: {
    fontSize: 15,
    wordBreak: 'break-word',
    opacity: 0.87,
    fontWeight: 600
  },
  lists: {
    maxHeight: '100%',
    overflow: 'auto'
  },
  list: {
    paddingTop: 0,
    paddingBottom: theme.spacing(2)
  },
  helpText: {
    textAlign: 'center'
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    whiteSpace: 'nowrap'
  }
}))

const Review = (props: {
  merges: {
    name1: AuditEntity['name']
    name2: AuditIdentifier['text']
  }[]
  splits: AuditSplits | undefined
  invalid: AuditIdentifier[] | undefined
}) => {
  const { splits = [], merges, invalid = [] } = props

  const { classes } = useStyles()

  return (
    <Box className={classes.lists}>
      <Box py={2}>
        {!!invalid.length && (
          <div>
            <Typography classes={{ root: classes.title }} semiBold color="text.secondary">
              Invalid
            </Typography>
            <List dense disablePadding classes={{ root: classes.list }}>
              <Repeater
                variant="numbered"
                component={({ text, invalidReason }: Required<AuditIdentifier>) => (
                  <ListItem disableGutters>
                    <ListItemText
                      classes={{ primary: classes.primary }}
                      primary={text}
                      secondary={invalidReasonMap[invalidReason as InvalidReasonsType]}
                    />
                  </ListItem>
                )}
                skeleton={{ size: 25, loading: false }}
                items={invalid}
              />
            </List>
          </div>
        )}
        {!!splits?.length && (
          <div>
            <Typography classes={{ root: classes.title }} semiBold>
              Splits
            </Typography>
            <List dense disablePadding classes={{ root: classes.list }}>
              <Repeater
                variant="numbered"
                component={({ identity1, identity2 }: any) => (
                  <ListItem disableGutters>
                    <ListItemText classes={{ primary: classes.primary }} primary={`${identity2} and ${identity1}`} />
                  </ListItem>
                )}
                skeleton={{ size: 25, loading: false }}
                items={splits}
              />
            </List>
          </div>
        )}
        {!!merges.length && (
          <div>
            <Typography classes={{ root: classes.title }} semiBold>
              Merges
            </Typography>
            <List dense disablePadding classes={{ root: classes.list }}>
              <Repeater
                variant="numbered"
                component={({ name1, name2 }: any) => (
                  <ListItem disableGutters>
                    <ListItemText classes={{ primary: classes.primary }} primary={`${name1} and ${name2}`} />
                  </ListItem>
                )}
                skeleton={{ size: 25, loading: false }}
                items={merges}
              />
            </List>
          </div>
        )}
      </Box>
    </Box>
  )
}

const AuditReview = (props: { submit: () => Promise<void>; back: () => void; reset: () => void }) => {
  const { classes } = useStyles()
  const { valid, invalid, isDirty } = useAuditEntities()
  const { splits } = useAuditTuples()
  const sidepanelWide = useSidepanelWide()

  const { identifiers: invalidIdentifiers } = invalid[0] || {}

  const entities = useMemo(() => valid.filter(({ identifiers, isPrimary }) => identifiers.length || isPrimary), [valid])

  const merges = entities
    .slice(1)
    .map(({ name, identifiers }) =>
      identifiers.map(({ text }) => ({
        name1: name,
        name2: text
      }))
    )
    .flat()

  return (
    <NextStepFrame
      next={
        <Button onClick={props.submit} variant="text" color="secondary">
          Submit
        </Button>
      }
      back={
        <Button onClick={props.back} variant="text" color="secondary">
          Back
        </Button>
      }
      reset={props.reset}
      isDirty={isDirty}
    >
      <AuditColumns
        entities={
          sidepanelWide ? (
            <Box p={2}>
              <EntitiesList items={entities.map((ent) => ({ ...ent, isActive: true }))} fullWidth />
            </Box>
          ) : null
        }
      >
        <AuditColumns.Column
          heading={
            <Typography classes={{ root: classes.helpText }}>
              Please review the following proposed changes to identities in your system, and click SUBMIT if they are accurate. You can always view
              the changes made to your system on the{' '}
              <Link className={classes.link} to={{ pathname: `${Paths._manualEdits}?sidepanel=false` }} target="_blank">
                manual edits
              </Link>{' '}
              screen.
            </Typography>
          }
        >
          <Review merges={merges} splits={splits} invalid={invalidIdentifiers} />
        </AuditColumns.Column>
      </AuditColumns>
    </NextStepFrame>
  )
}

export default AuditReview
