import { useState, useCallback, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { useWide } from '_core/components/layout'

import useFilter from '_core/hooks/useFilter'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { capitalizeWord } from '_core/helpers/string'

type InitialParams = ModifiedSearchPageParams & AdditionalParams
type AdditionalParams = { isOpened: boolean }

const searchSaveData = {
  endpoint: '/usersettings/searchfilter',
  getData: (params: InitialParams): ISearchInit => {
    const { scope, selectedEntity, entities, isOpened, viewMode } = params
    return {
      searchType: scope || 'DotAlign',
      selectedEntity: capitalizeWord(selectedEntity) || 'Person',
      entities: entities ? entities.map((e) => capitalizeWord(e)) : ['Person'],
      state: isOpened ? 'Open' : 'Close',
      expandedView: viewMode === 'expanded'
    }
  }
}

const useSearchSettings = () => {
  const { search } = useLocation()
  const { queryParams, updateQuery } = useSearchQuery<SearchPageParams, { modifyProps: [{ entities: SearchEntities[] }] }>(['entities'])
  const [opened, setOpened] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [params, setParams] = useState<InitialParams>()
  const { save } = useFilter()
  const wide = useWide()

  useEffect(() => {
    if (!search && params) {
      const { scope, selectedEntity, entities, viewMode, rowsPerPage } = params
      updateQuery({
        scope,
        viewMode,
        rowsPerPage,
        ...(scope === 'DotAlign' ? { entities } : { selectedEntity })
      })
    }
  }, [search, updateQuery, params])

  const getInitialParams = useCallback((data: ISearchInit): InitialParams => {
    const { searchType, selectedEntity, entities, state, expandedView } = data
    return {
      scope: searchType || 'DotAlign',
      selectedEntity: selectedEntity ? (selectedEntity.toLowerCase() as SearchEntities) : 'person',
      entities: entities?.length ? (entities.map((e) => e.toLowerCase()) as SearchEntities[]) : ['person'],
      isOpened: state === 'Open',
      viewMode: expandedView ? 'expanded' : 'collapsed',
      rowsPerPage: '10'
    }
  }, [])

  const setInitial = useCallback(
    (data: ISearchInit) => {
      const params = getInitialParams(data)
      if (wide) {
        setOpened(params.isOpened)
      }
      setParams(params)
      setLoading(false)
    },
    [getInitialParams, setLoading]
  )

  const handleChange = (updates: typeof queryParams, additionalParams?: AdditionalParams) => {
    const { isOpened = wide ? opened : !!params?.isOpened } = additionalParams || {}
    const { peopleFilters, companiesFilters, ...restQueryParams } = queryParams

    const hiddenParamsToSave = {
      isOpened
    }

    save(getSearchSaveData(hiddenParamsToSave), { ...restQueryParams, ...updates })
  }

  const switchSearchScope = (scope: SearchScope, entity?: SearchEntities) => {
    const scopeValuesMap: { [key in SearchScope]: ModifiedSearchPageParams } = {
      DotAlign: {
        scope: 'DotAlign',
        entities: entity ? [entity] : params?.entities,
        selectedEntity: undefined,
        peopleFilters: undefined,
        companiesFilters: undefined
      },
      MarketData: {
        scope: 'MarketData',
        selectedEntity: entity || params?.selectedEntity,
        entities: undefined
      }
    }

    handleChange(scopeValuesMap[scope])
  }

  const getSearchSaveData = (additionalParams: AdditionalParams) => {
    return {
      ...searchSaveData,
      getData: (params: typeof queryParams) => searchSaveData.getData({ ...params, ...additionalParams })
    }
  }

  const toggleOpen = () => {
    const isOpened = !opened
    setOpened(isOpened)
    if (wide) {
      setParams((prevState) => (prevState ? { ...prevState, isOpened } : prevState))
      handleChange({}, { isOpened })
    }
  }

  return {
    setInitial,
    handleChange,
    loading,
    opened,
    toggleOpen,
    switchSearchScope
  }
}

export default useSearchSettings
