import { useContext, useEffect } from 'react'

import { Route, Switch, useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import Topbar from '_core/components/Topbar'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const baseURL = `${Paths._deals}/:dealId`

const Component = (props: any) => {
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader(props.Synopsis, props.Synopsis ? false : true)
  }, [setMobileHeader, props.Synopsis])

  return (
    <Switch>
      <Route path={`${baseURL}/colleagues`}>
        <Topbar nativeBack sub={`Colleagues · ${props.Colleagues?.data?.length || 0}`} />
        <Repeater
          direction="vertical"
          variant="list"
          component={ProfileItem}
          skeleton={{ size: 10, loading: props.loading }}
          items={
            props.Colleagues?.data?.map((item: any) => ({
              name: item.UserFullName,
              byline: item.UserEmail,
              userKey: item.UserKeyMd5,
              link: `${Paths._people}/${item.UserKeyMd5}`
            })) || []
          }
        />
      </Route>
    </Switch>
  )
}

const DealPage = () => {
  const { teamContextValue } = useContext(TeamContext)
  const { dealId } = useParams<{ dealId: string }>()

  return (
    <DynamicEntity
      id="deal"
      url={`/ourDeals?teamNumber=${teamContextValue.teamNumber}&DealKey=${dealId}`}
      component={Component}
      empty="No results found"
      emptySubtitle="No data"
    />
  )
}

export default DealPage
