import React from 'react'

import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'

import TextField from '_shared/forms/TextField'
import { HelpIconPopover } from '_shared/popovers/IconPopover'
import Typography from '_shared/Typography'

import Settings, { useStyles } from '_core/components/settings'

import useSaveSettings, { SaveAction } from '_core/hooks/useSaveSettings'

type SettingsData = {
  pointsForFirstMeeting: number
  pointsAssignedToSubsequentMeetings: number
  pointsAssignedToAnEmailInteraction: number
}

const DataAnalyticsSettings = ({ items: settings, ...props }: { loading: boolean; items?: SettingsData }) => {
  const {
    getValues,
    formState: { errors },
    register
  } = useForm<SettingsData>({
    mode: 'onChange',
    defaultValues: props.loading ? {} : settings || {}
  })
  const { isSaving, save: saveSettings } = useSaveSettings()
  const { classes } = useStyles()
  const { icon, field, filtersContent, subItem, subItemWrapper } = classes

  const fieldMessage = {
    pointsForFirstMeeting: 'The value must be within this range: 1 - 25',
    pointsAssignedToSubsequentMeetings: 'The value must be within this range: 1 - 10',
    pointsAssignedToAnEmailInteraction: 'The value must be within this range: 1 - 5'
  }

  const save = (action: SaveAction) => {
    const settingsData = getValues()
    saveSettings('/adminsettings/dataanalytics', settingsData, action, 'Data analytics settings')
  }

  return (
    <Settings isSaving={isSaving} save={save} initialLoading={props.loading} saveDisabled={!!Object.keys(errors).length}>
      <Box width="100%">
        <Box display="flex" alignItems="center">
          <Typography>Relationship score customization</Typography>
          <HelpIconPopover
            title="You can customize the relationship scores that DotAlign computes by setting your desired values for the following."
            className={icon}
          />
        </Box>
        <Box className={filtersContent}>
          <Box className={subItemWrapper}>
            <Box className={subItem}>
              <Typography color="text.secondary">Points assigned to the first meeting</Typography>
              <TextField
                {...register('pointsForFirstMeeting', {
                  required: true,
                  valueAsNumber: true,
                  min: 1,
                  max: 25
                })}
                size="small"
                style={{ width: 50 }}
                classes={{ root: field }}
                type="number"
              />
              <HelpIconPopover className={icon} title={fieldMessage.pointsForFirstMeeting} />
              {errors.pointsForFirstMeeting && (
                <Typography component="span" className={classes.error}>
                  {fieldMessage.pointsForFirstMeeting}
                </Typography>
              )}
            </Box>
          </Box>
          <Box className={subItemWrapper}>
            <Box className={subItem}>
              <Typography color="text.secondary">Points assigned to subsequent meetings</Typography>
              <TextField
                {...register('pointsAssignedToSubsequentMeetings', {
                  required: true,
                  valueAsNumber: true,
                  min: 1,
                  max: 10
                })}
                size="small"
                style={{ width: 50 }}
                classes={{ root: field }}
                type="number"
              />
              <HelpIconPopover className={icon} title={fieldMessage.pointsAssignedToSubsequentMeetings} />
              {errors.pointsAssignedToSubsequentMeetings && (
                <Typography component="span" className={classes.error}>
                  {fieldMessage.pointsAssignedToSubsequentMeetings}
                </Typography>
              )}
            </Box>
          </Box>
          <Box className={subItemWrapper}>
            <Box className={subItem}>
              <Typography color="text.secondary">Points assigned to a reciprocated email message</Typography>
              <TextField
                {...register('pointsAssignedToAnEmailInteraction', {
                  required: true,
                  valueAsNumber: true,
                  min: 1,
                  max: 5
                })}
                size="small"
                style={{ width: 50 }}
                classes={{ root: field }}
                type="number"
              />
              <HelpIconPopover className={icon} title={fieldMessage.pointsAssignedToAnEmailInteraction} />
              {errors.pointsAssignedToAnEmailInteraction && (
                <Typography component="span" className={classes.error}>
                  {fieldMessage.pointsAssignedToAnEmailInteraction}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Settings>
  )
}

export default DataAnalyticsSettings
