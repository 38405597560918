import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'

import Typography from '_shared/Typography'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import HelpLinkButton from '_core/components/HelpLinkButton'
import { Column, Columns, useWide } from '_core/components/layout'
import MarketDataExternalTags from '_core/components/MarketDataExternalTags'
import Line from '_core/components/ProfileLine'
import Widget, { SeeAllAction } from '_core/components/widgets'
import useStyles from '_core/components/widgets/styles'

import { ellipsisText } from 'utils/Utils'

import Paths from 'Paths'

const CompanyMarketDataWidget = (
  props: { companyName: string; md5: string; marketData: boolean } & Partial<
    Pick<CompanyMarketDataType, 'id' | 'tags' | 'summary' | 'website' | 'founded' | 'location' | 'employeeCount' | 'industry'>
  >
) => {
  const { classes } = useStyles()

  const { tags, summary, id, website, founded, location, employeeCount, industry } = props

  const noData = props.marketData && !id
  const noWidgetData = id && !tags?.length && !summary && !industry && !employeeCount
  const notEnoughData = noWidgetData && !website && !founded && !location
  const matchPhoneWidth = useWide('phone')

  return (
    <Widget>
      <Heading
        underlined
        title="Market data"
        icon={['fas', 'chart-column']}
        action={
          <>
            <Box flex={1}>
              <HelpLinkButton
                hint="Learn more about market data"
                href="https://help.dotalign.com/article/x8f8c6vw1i-dot-align-market-data"
                size="small"
                disablePY
              />
            </Box>
            <SeeAllAction sidepanel link={!noData && !notEnoughData ? `${Paths._companies}/${props.md5}/market-data` : ''} />
          </>
        }
      />

      {(noData || notEnoughData || (noWidgetData && !notEnoughData)) && (
        <Empty
          className={classes.empty}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'chart-column']} style={{ color: '#A7A7A7' }} />}
          subTitle={
            <>
              {noData && 'No details are available in market data'}
              {notEnoughData && 'Not enough details are available in market data'}
              {noWidgetData && !notEnoughData && 'No tag or summary info is available, click "see all" for other market data'}
            </>
          }
        />
      )}

      {summary && <Typography>{ellipsisText(summary, 150)}</Typography>}
      <Columns spacing={0} columnSpacing={2}>
        <Column xs={matchPhoneWidth ? 6 : 12} md={12}>
          <Line loading={!props.marketData} icon={['fas', 'industry']} value={industry} />
        </Column>
        <Column xs={matchPhoneWidth ? 6 : 12} md={12}>
          <Line loading={!props.marketData} icon={['fas', 'users']} value={employeeCount ? `${employeeCount}+ employees` : ''} />
        </Column>
      </Columns>
      {(!props.marketData || !!tags?.length) && (
        <MarketDataExternalTags marketData={props.marketData} name={props.companyName} items={tags} title="Tags" />
      )}
    </Widget>
  )
}

export default CompanyMarketDataWidget
