import React from 'react'

import { makeStyles } from 'tss-react/mui'

import Badge from '_shared/Badge'

const useStyles = makeStyles()((theme) => ({
  animationBadge: {
    position: 'absolute',
    left: theme.spacing(3)
  }
}))

const EntityMoveCount = ({ count }: { count: number }) => {
  const { classes, cx } = useStyles()

  if (!count) return null

  return <Badge badgeContent={`+${count}`} className={cx('bounceAnimation', classes.animationBadge)} />
}

export default EntityMoveCount
