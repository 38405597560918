import { useContext, useEffect } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import { useLookUpContributorsByIds, useLookUpPeopleByIds } from '_core/hooks/useLookup'
import useSearchQuery from '_core/hooks/useSearchQuery'

const useActivitiesContacts = () => {
  const { queryParams } = useSearchQuery<ActivitiesPageParams, { modifyProps: [{ customList: string[] }] }>(['customList'])
  const { groupBy, view, customList = [] } = queryParams
  const {
    fetchWithAbort,
    loading: contactsLoading,
    result: contactsListResult,
    reset: resetContactsList
  } = useAbortableFetch<{ data: PeopleListItem[] }>()

  const { lookUpPeopleByIds, loading: peopleByIdsLoading, peopleByIds, reset: resetPeopleByIds } = useLookUpPeopleByIds()
  const {
    lookUpContributorsByIds,
    loading: contributorsByIdsLoading,
    contributorsByIds,
    reset: resetContributorsByIds
  } = useLookUpContributorsByIds()

  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue

  const mapContact = ({ MyUserKeyMd5, PersonMd5, PersonNameText, BestEmailAddrText }: PeopleListItem) => ({
    md5: groupBy === 'Contributor' && MyUserKeyMd5 ? MyUserKeyMd5 : PersonMd5,
    name: PersonNameText || BestEmailAddrText,
    emailAddress: BestEmailAddrText
  })

  useEffect(() => {
    makeRequest && makeRequest()

    return () => {
      reset && reset()
    }
  }, [groupBy, view, customList.length])

  const {
    makeRequest,
    key = 'JustTheseUserKeyMd5s',
    getList,
    loading,
    reset
  } = [
    {
      key: 'JustTheseContactAnchorMd5s',
      loading: contactsLoading,
      getList: () => contactsListResult?.data.map(mapContact),
      makeRequest: () => fetchWithAbort({ url: `/people?teamNumber=${teamNumber}&SortBy=ScoreDesc&Take=5` }),
      reset: resetContactsList,
      condition: groupBy === 'Contact' && view === 'Top'
    },
    {
      key: 'JustTheseContactAnchorMd5s',
      loading: contactsLoading,
      getList: () => contactsListResult?.data.map(mapContact),
      makeRequest: () =>
        fetchWithAbort({
          url: `/people?teamNumber=${teamNumber}&SortBy=Stats&StatSortType=LastActivity&StatSortOldestToNewest=false&Take=5`
        }),
      reset: resetContactsList,
      condition: groupBy === 'Contact' && view === 'Recent'
    },
    {
      key: 'JustTheseContactAnchorMd5s',
      loading: peopleByIdsLoading,
      getList: () =>
        peopleByIds?.data
          .sort(({ MyUserKeyMd5: AMyUserKey, PersonMd5: APersonMd5 }, { MyUserKeyMd5: BMyUserKey, PersonMd5: BPersonMd5 }) => {
            return (
              customList.indexOf(groupBy === 'Contributor' && AMyUserKey ? AMyUserKey : APersonMd5) -
              customList.indexOf(groupBy === 'Contributor' && BMyUserKey ? BMyUserKey : BPersonMd5)
            )
          })
          .map(mapContact),
      makeRequest: () => lookUpPeopleByIds(customList),
      reset: resetPeopleByIds,
      condition: groupBy === 'Contact' && view === 'Custom' && customList.length > 0
    },
    {
      key: 'JustTheseUserKeyMd5s',
      loading: contributorsByIdsLoading,
      getList: () =>
        contributorsByIds
          ?.sort((a, b) => customList.indexOf(a.userKeyMd5) - customList.indexOf(b.userKeyMd5))
          ?.map(({ userKeyMd5, fullName, primaryEmail }) => ({ md5: userKeyMd5, name: fullName, emailAddress: primaryEmail })),
      makeRequest: () => lookUpContributorsByIds(customList),
      reset: resetContributorsByIds,
      condition: groupBy === 'Contributor' && customList.length > 0
    }
  ].find(({ condition }) => !!condition) || {}

  const list = getList ? getList() : getList
  const listMd5s = list?.map(({ md5 }) => md5)

  return {
    list,
    listMd5s,
    key,
    loading
  }
}

export default useActivitiesContacts
