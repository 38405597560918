import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AccountCircle } from '@mui/icons-material'
import { Box } from '@mui/material'
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom'

import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { useSalesforceUserInfo } from '_core/hooks/useSalesforce'

import AvatarMenu, { useStyles, MenuItemType } from 'SharedComponents/AvatarMenu'

type CurrentUser = {
  name: string
  username: string
  profileLink: string
  userId: string
  organizationId: string
  organizationUrl: string
}

const AccountMenu = (props: RouteComponentProps & { currentUser: CurrentUser; profilePic?: string }) => {
  const { classes } = useStyles()
  const { pathname } = useLocation()
  const [pic, setPic] = useState<string | undefined>(props.profilePic)
  const { logout } = useSalesforceUserInfo()

  const options: MenuItemType[] = [
    props.currentUser && {
      id: 'user_name',
      label: (
        <Box>
          <Box>{props.currentUser.name}</Box>
          <Box position="relative" top={-4}>
            <Typography className={classes.text} variant="body1" color="text.secondary" title={props.currentUser.username}>
              {props.currentUser.username}
            </Typography>
          </Box>
        </Box>
      ),
      icon: () => (
        <Box className={classes.wrapper}>
          {pic ? (
            <img src={pic} className={classes.pic} alt="Profile" onError={() => setPic('')} />
          ) : (
            <AccountCircle color="secondary" className={classes.pic} />
          )}
        </Box>
      ),
      callback: () => {
        window.open(props.currentUser.profileLink, '_blank')
      },
      addprops: {
        style: {
          borderBottom: '1px #eee solid'
        }
      }
    },
    {
      id: 'salesforce_user_id',
      label: (
        <Box>
          <Typography variant="body1" color="text.secondary" className={classes.text}>
            Salesforce user id
          </Typography>
          <Box position="relative">
            <Tooltip title={props.currentUser.userId}>
              <Typography className={classes.value} noWrap semiBold>
                {props.currentUser.userId}
              </Typography>
            </Tooltip>
          </Box>
          <Typography variant="body1" color="text.secondary" className={classes.text}>
            Salesforce organization id
          </Typography>
          <Box position="relative">
            <Tooltip title={props.currentUser.organizationId}>
              <Typography className={classes.value} noWrap semiBold>
                {props.currentUser.organizationId}
              </Typography>
            </Tooltip>
          </Box>
          <Typography variant="body1" color="text.secondary" className={classes.text}>
            Salesforce organization url
          </Typography>
          <Box position="relative">
            <Tooltip title={props.currentUser.organizationUrl}>
              <Typography className={classes.value} noWrap semiBold>
                {props.currentUser.organizationUrl}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      ),
      addprops: {
        disableRipple: true,
        style: {
          cursor: 'auto',
          userSelect: 'auto',
          borderBottom: '1px #eee solid',
          background: '#fff'
        }
      }
    },
    {
      id: 'log_out',
      label: 'Log out',
      icon: (args: any) => <FontAwesomeIcon size="1x" icon={['far', 'sign-out']} className={classes.icon} {...args} />,
      callback: () => logout(pathname)
    }
  ].filter((el) => typeof el !== 'boolean')

  return <AvatarMenu id="salesforce-account-menu" options={options} profilePic={props.profilePic} />
}

export default withRouter(AccountMenu)
