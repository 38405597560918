import { Box } from '@mui/material'

import { Step } from '_core/context/Onboarding'

import Typography from '_shared/Typography'

export const expandFilterOnboardingTarget = 'expand-filter-onboarding'

const Content = (
  <Box maxWidth="125px">
    <Typography>Click here to show/hide the filters.</Typography>
  </Box>
)

const steps: Step[] = [
  {
    disableBeacon: true,
    title: 'Show/hide filter',
    content: Content,
    target: `.${expandFilterOnboardingTarget}`
  }
]

export default steps
