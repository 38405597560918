import { Box } from '@mui/material'

import { ActiveOnboarding, Step } from '_core/context/Onboarding'

import Typography from '_shared/Typography'

export const activityStatsBreakdownOnboardingTarget = 'activityStats-breakdown-onboarding'
export const activityStatsEntryPointOnboardingTarget = 'activityStats-entryPoint-onboarding'
export const activityStatsRangeControlOnboardingTarget = 'activityStats-range-control-onboarding'

export const entryPointSteps: Step[] = [
  {
    disableBeacon: true,
    title: 'Activity chart',
    content: (
      <Box maxWidth={250}>
        <Typography>Click here to see an activity chart for the person or company, including a break down by employee</Typography>
      </Box>
    ),
    target: `.${activityStatsEntryPointOnboardingTarget}`,
    disableScrolling: true
  }
]

export const widgetPointSteps = [
  {
    disableBeacon: true,
    title: 'Range control',
    content: (
      <Box maxWidth={250}>
        <Typography>Click on the chart to zoom in, and use these controls to manipulate the date range for which activity is shown</Typography>
      </Box>
    ),
    target: `.${activityStatsRangeControlOnboardingTarget}`,
    disableScrolling: true,
    disableScrollParentFix: true
  }
]

const pageSteps = (completed_onboarding: ActiveOnboarding[]) => [
  ...(completed_onboarding?.includes('activityStatsWidget') ? [] : [widgetPointSteps[0]]),
  {
    disableBeacon: true,
    title: 'Breakdown by employee',
    content: (
      <Box maxWidth={250}>
        <Typography>See a break down of the activity for the selected date range by employee</Typography>
      </Box>
    ),
    target: `.${activityStatsBreakdownOnboardingTarget} .MuiDataGrid-main`
  }
]

export default pageSteps
