import React, { ComponentProps, useRef, useState, useMemo, memo, CSSProperties, ReactElement, UIEvent, useEffect, useCallback } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { VariableSizeList as List, GridOnItemsRenderedProps } from 'react-window'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

import {
  ActivitiesLayout,
  ActivityDetails,
  PickFirstContainer,
  MoveIconHint,
  ColumnHeader,
  ActivitiesTimeline,
  ActivityRow
} from '_core/components/ActivitiesList'
import ActivitiesPickerDialog from '_core/components/dialogs/ActivitiesPicker'
import Empty from '_core/components/Empty'
import InfiniteScroll from '_core/components/InfiniteScroll'
import { useWide } from '_core/components/layout'
import { VirtalizedListCarousel, VirtalizedGridCarousel, PrevButton, NextButton, easeInOutCubic } from '_core/components/VirtalizedCarousel'

import useActivitiesContacts from '_core/hooks/useActivitiesContacts'
import useActivitiesGroups, { LocalActivityItem } from '_core/hooks/useActivitiesGroups'
import useActivitiesSlidesToShow, { maxSlidesLength, timeColumnWidth } from '_core/hooks/useActivitiesSlidesToShow'
import useActivitiesSlots, { ActivityItem } from '_core/hooks/useActivitiesSlots'
import useCurrentSlideIndex from '_core/hooks/useCurrentSlideIndex'
import useDialog from '_core/hooks/useDialog'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import { useLookUpContributors, useLookUpPeople } from '_core/hooks/useLookup'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useVirtualized from '_core/hooks/useVirtualized'

import { getSkeletonSize } from '_core/helpers/skeleton'

import { formatTime, getLocal } from 'utils/Utils'

import Paths from 'Paths'

const useStyles = makeStyles<void | Partial<{ cellWidth: number }>>()((theme, props) => ({
  cell: {
    maxWidth: props?.cellWidth
  }
}))

const PickPeople = ({
  addPerson,
  disabled,
  isIcon
}: {
  addPerson: ComponentProps<typeof ActivitiesPickerDialog>['add']
  disabled: boolean
  isIcon: boolean
}) => {
  const { queryParams } = useSearchQuery<ActivitiesPageParams, { modifyProps: [{ customList: string[] }] }>(['customList'])
  const { groupBy } = queryParams
  const memoCustomList = useMemo(() => queryParams.customList || [], [queryParams.customList])

  const { isDialogOpened, openDialog, closeDialog } = useDialog(false)
  const { lookupPeople, forceAbort: peopleForceAbort } = useLookUpPeople()
  const { lookupContributors, forceAbort: contributorsForceAbort } = useLookUpContributors()
  const [tags, setTags] = useState<{ md5: string; name: string; emailAddress: string }[] | null>(null)

  const propsMap = {
    contact: {
      text: 'Add contacts',
      icon: ['far', 'user-plus'] as IconProp,
      loadOptions: useCallback(
        async (searchTerm?: string, skip?: string[], take?: number) => {
          const resData = await lookupPeople(searchTerm, [...memoCustomList, ...(skip || [])], take)
          return resData?.map((item) => ({
            name: item.PersonNameText || item.BestEmailAddrText,
            emailAddress: item.BestEmailAddrText,
            md5: item.PersonMd5
          }))
        },
        [lookupPeople, memoCustomList]
      ),
      forceAbort: peopleForceAbort
    },
    contributor: {
      text: 'Add contributors',
      icon: ['far', 'user-plus'] as IconProp,
      loadOptions: useCallback(
        async (searchTerm?: string, skip?: string[], take?: number) => {
          const resData = await lookupContributors(searchTerm, [...memoCustomList, ...(skip || [])], take)
          return resData?.map((item) => ({ name: item.name, emailAddress: item.emailAddress, md5: item.userKeyMd5 }))
        },
        [lookupContributors, memoCustomList]
      ),
      forceAbort: contributorsForceAbort
    }
  }

  const { loadOptions, text, icon, forceAbort } = propsMap[groupBy?.toLowerCase() as keyof typeof propsMap] || {}

  useEffect(() => {
    ;(async () => {
      if (isDialogOpened) {
        setTags(null)
        const tags = await loadOptions('', memoCustomList, memoCustomList.length + 3)
        if (tags) {
          setTags(tags)
        }
      }
    })()
  }, [isDialogOpened, loadOptions, memoCustomList])

  return (
    <>
      <ActivitiesPickerDialog.TriggerEl disabled={disabled} open={openDialog} text={text} icon={icon} isIcon={isIcon} />
      <ActivitiesPickerDialog
        title={text}
        loadOptions={loadOptions}
        isOpened={isDialogOpened}
        close={closeDialog}
        add={addPerson}
        tags={tags?.slice(0, 3)}
        forceAbort={forceAbort}
      />
    </>
  )
}

const TSlot = memo((props: { md5?: string; loading: boolean; dateTime: string } & LocalActivityItem) => {
  const { md5, loading, from = [], to = [], action, companies = [], detailsLink, dateTime = '' } = props

  const isFutureEvent = getLocal(dateTime).isAfter(getLocal())

  const fromIndex = from.findIndex((f) => md5 === f.id)
  const toIndex = to.findIndex((to) => md5 === to.id)
  const fromsIncludesMd5 = fromIndex > -1
  const tosIncludesMd5 = toIndex > -1

  const [f = [], t = []] =
    fromsIncludesMd5 || tosIncludesMd5
      ? fromsIncludesMd5
        ? [[from[fromIndex], ...from.slice(0, fromIndex), ...from.slice(fromIndex + 1)], to]
        : [[to[toIndex], ...to.slice(0, toIndex), ...to.slice(toIndex + 1)], from]
      : []

  const iconsMap = {
    inbound: ['fas', 'message-arrow-down'],
    outbound: ['fas', 'message-arrow-up'],
    meeting: ['far', 'calendar-alt']
  }

  const { text = '' } =
    [
      {
        text: 'received message from',
        condition: tosIncludesMd5 && action !== 'meeting'
      },
      {
        text: 'sent message to',
        condition: fromsIncludesMd5 && action !== 'meeting'
      },
      {
        text: isFutureEvent ? 'will meet' : 'met',
        condition: action === 'meeting'
      }
    ].find(({ condition }) => condition) || {}

  return (
    <ActivityDetails
      time={formatTime(dateTime)}
      isInternal={companies.length === 1}
      companies={companies.filter((domain) => !!domain) as string[]}
      from={f}
      to={t}
      detailsLink={detailsLink}
      actionText={text}
      icon={action ? (iconsMap[action] as IconProp) : null}
      loading={loading}
    />
  )
})

const Cell = memo(
  ({
    style,
    columnIndex,
    rowIndex,
    data: { rows, columns, loading, setSize, width, resetSize }
  }: {
    style: CSSProperties
    columnIndex: number
    rowIndex: number
    data: {
      rows: ActivityRow[]
      columns: string[]
      loading: boolean
      setSize: (rowIndex: number, height: number) => void
      resetSize: (rowIndex: number, columnIndex: number) => void
      width: number
    }
  }) => {
    const { classes } = useStyles({ cellWidth: width })
    const pMd5 = columns[columnIndex]
    const timeSlot = rows[rowIndex] || {}
    const [dateTime] = Object.keys(timeSlot)
    const [entityMap = {}] = Object.values(timeSlot) as [{ [x: string]: LocalActivityItem }]

    const cellRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      resetSize(rowIndex, columnIndex)
    }, [width, rowIndex, columnIndex])

    useEffect(() => {
      if (cellRef.current) {
        setSize(rowIndex, cellRef.current.getBoundingClientRect().height + 16)
      }
    }, [rowIndex, loading, width])

    return (
      <div style={style}>
        <div ref={cellRef} className={classes.cell}>
          <TSlot loading={loading} md5={pMd5} dateTime={dateTime} {...(entityMap[pMd5 as keyof typeof entityMap] || {})} />
        </div>
      </div>
    )
  }
)

const HeaderRow = ({ style, index, data: { items } }: { style: CSSProperties; index: number; data: { items: ReactElement[] } }) => {
  return <div style={style}>{items[index]}</div>
}

const ActivitiesByPerson = ({
  width,
  onCustomSelect,
  reset,
  onLoading
}: {
  width: number
  onCustomSelect: ComponentProps<typeof ActivitiesPickerDialog>['add']
  reset: () => void
  onLoading: (loading: boolean) => void
}) => {
  const wide = useWide()
  const { search } = useLocation()
  const { result: userKeyResult } = useEntityEndpoint<{ results: ProfileType } | null>(`/me/profile`)
  const myUserKey = userKeyResult?.results.UserKeyMd5

  const headerRef = useRef<List>(null)
  const timelineRef = useRef<List>(null)
  const sizeMap = useRef<{ [key: number]: number }>({})
  const [[firstOverscanVisibleRow, firstOverscanVisibleColumn], setFirstOverscanVisibleValues] = useState<
    [GridOnItemsRenderedProps['overscanRowStartIndex'], GridOnItemsRenderedProps['overscanColumnStartIndex']]
  >([0, 0])

  const { slideIndex, updateSlideIndex } = useCurrentSlideIndex()

  const { list: contactsList, listMd5s: contactsListMd5s, loading: listLoading, key } = useActivitiesContacts()

  const { queryParams } = useSearchQuery<ActivitiesPageParams, { modifyProps: [{ customList?: string[] }] }>(['customList'])
  const { customList = [], groupBy, view } = queryParams

  const containerWidth = width - timeColumnWidth - 12

  const customView = groupBy === 'Contributor' || view === 'Custom'
  const skeletonSize = contactsListMd5s?.length || customList.length || maxSlidesLength

  const { slidesToShow, columnWidth, isAddButtonIcon, getColumnSize } = useActivitiesSlidesToShow(skeletonSize, containerWidth)

  const shouldSelectPerson = customView ? (contactsListMd5s ? !contactsListMd5s.length : !customList.length) : false

  const { slots, hasMore, loading: slotsLoading, more, reload } = useActivitiesSlots({ key, list: contactsListMd5s })
  const groupLists = useActivitiesGroups()

  const loading = listLoading || slotsLoading || !myUserKey

  const onContainerScroll = (e: UIEvent<HTMLDivElement>) => {
    if (headerRef.current) {
      headerRef.current.scrollTo(e.currentTarget.scrollLeft)
    }
  }

  const outerElementStyle = useMemo(
    () => ({ width: containerWidth, overflow: 'hidden', minHeight: `calc(100vh - ${wide ? 262 : 242}px)` }) as CSSProperties,
    [containerWidth, wide]
  )
  const { ref: gridRef, outerElementType } = useVirtualized(true, {}, { containerStyle: outerElementStyle, onContainerScroll })

  useEffect(() => {
    if (gridRef.current && headerRef.current && timelineRef.current) {
      gridRef.current.resetAfterRowIndex(0)
      gridRef.current.resetAfterColumnIndex(0)
      headerRef.current.resetAfterIndex(0)
      timelineRef.current.resetAfterIndex(0)
      headerRef.current.scrollTo(0)
      updateSlideIndex(0)
    }
  }, [containerWidth, gridRef, search, updateSlideIndex])

  const list = useMemo(
    () =>
      !slotsLoading
        ? slots.map((slot) => {
            const [date] = Object.keys(slot)
            const [timeSlots] = Object.values(slot)

            return {
              [date]: timeSlots.map((timeSlot) => {
                const [time] = Object.keys(timeSlot)
                const [timeItems] = Object.values(timeSlot) as ActivityItem[][]

                const lists = myUserKey ? groupLists(timeItems, time, myUserKey) : []

                return {
                  [time]: lists.reduce(
                    (acc, { from, to, action, companies, detailsLink }) => ({
                      ...acc,
                      ...[...from, ...to].reduce((acc, { id }) => {
                        return { ...acc, [id]: { from, to, action, companies, detailsLink } }
                      }, {})
                    }),
                    {} as {
                      [id: string]: LocalActivityItem
                    }
                  )
                }
              })
            }
          })
        : [],
    [slotsLoading, myUserKey, slots]
  )

  const flatRows = useMemo(
    () =>
      loading
        ? getSkeletonSize(7)
        : list
            .map((slot) => {
              const [timeSlots = []] = Object.values(slot)
              return timeSlots
            })
            .flat(),
    [loading, list]
  ) as ActivityRow[]

  useEffect(() => {
    onLoading(loading)
  }, [loading])

  const setSize = (rowIndex: number, size: number) => {
    if (gridRef.current && timelineRef.current && headerRef.current) {
      const condition = loading ? true : (sizeMap.current[rowIndex] || 0) < size

      sizeMap.current = { ...sizeMap.current, [rowIndex]: condition ? size : sizeMap.current[rowIndex] }
      gridRef.current.resetAfterRowIndex(rowIndex)
      headerRef.current.resetAfterIndex(rowIndex)
      timelineRef.current.resetAfterIndex(rowIndex)
    }
  }

  const getSize = (index: number) => sizeMap.current[index] || 50

  const handleRemovePerson = (md5: string) => {
    onCustomSelect(customList.filter((customItemMd5) => customItemMd5 !== md5))
  }

  const handleAddPerson = (md5s: string[]) => {
    onCustomSelect([...customList, ...md5s])
  }

  const goBack = () => {
    updateSlideIndex((prevState = 0) => prevState - 1)
  }

  const goNext = () => {
    updateSlideIndex((prevState = 0) => prevState + 1)
  }

  const onGridItemsRendered = (props: GridOnItemsRenderedProps) => {
    setFirstOverscanVisibleValues([props.overscanRowStartIndex, props.overscanColumnStartIndex])
  }

  const resetSize = (rowIndex: number, columnIndex: number) => {
    if (rowIndex === firstOverscanVisibleRow && firstOverscanVisibleColumn === columnIndex) {
      sizeMap.current = {}
    }
  }

  const actionButton = <PickPeople addPerson={handleAddPerson} disabled={!shouldSelectPerson && !contactsList} isIcon={isAddButtonIcon} />

  return (
    <>
      {shouldSelectPerson && <PickFirstContainer width={width}>{actionButton}</PickFirstContainer>}
      {!shouldSelectPerson && (
        <Box display="grid" gridTemplateColumns={`${timeColumnWidth}px auto 50px`}>
          <ActivitiesTimeline ref={timelineRef} rowHeight={getSize} width={timeColumnWidth} rows={flatRows} loading={loading} />
          <ActivitiesLayout
            header={
              <VirtalizedListCarousel
                slideIndex={slideIndex}
                style={{ overflow: 'hidden' }}
                ref={headerRef}
                height={98}
                slidesToShow={slidesToShow}
                getColumnSize={getColumnSize}
                prevArrow={<PrevButton onClick={goBack} />}
                nextArrow={<NextButton onClick={goNext} />}
                itemCount={skeletonSize}
                width={containerWidth}
                duration={700}
                easing={easeInOutCubic}
                layout="horizontal"
                itemData={{
                  items: (contactsList || getSkeletonSize(skeletonSize, {})).map(
                    ({ name = '', md5 = '', emailAddress = '' }, index: number, arr: object[]) => (
                      <ColumnHeader
                        key={md5}
                        name={name}
                        md5={md5}
                        userKey={emailAddress}
                        link={`${Paths._people}/${md5}`}
                        onDelete={handleRemovePerson}
                        actionButton={customView ? actionButton : null}
                        isLastChild={index === arr?.length - 1}
                      />
                    )
                  )
                }}
              >
                {HeaderRow}
              </VirtalizedListCarousel>
            }
          >
            {!loading && list.length === 0 && (
              <div>
                <Empty
                  title="No activities were found"
                  subTitle="Try changing your date and/or filter parameters"
                  icon={<FontAwesomeIcon size="5x" icon={['fat', 'calendar-clock']} style={{ color: '#A7A7A7' }} />}
                  action={
                    <Button variant="link" onClick={reset} color="primary" disablePT>
                      clear all filters
                    </Button>
                  }
                />
              </div>
            )}
            {(loading || list.length > 0) && reload && (
              <InfiniteScroll
                loading={loading}
                dataLength={flatRows.length}
                next={more}
                refreshFunction={reload}
                hasMore={hasMore}
                scrollableTarget="activities_list"
              >
                <VirtalizedGridCarousel
                  slideIndex={slideIndex}
                  columnCount={skeletonSize}
                  getColumnSize={getColumnSize}
                  height={window.innerHeight}
                  width={containerWidth}
                  slidesToShow={slidesToShow}
                  rowCount={flatRows.length}
                  rowHeight={getSize}
                  duration={700}
                  easing={easeInOutCubic}
                  outerElementType={outerElementType}
                  ref={gridRef}
                  onItemsRendered={onGridItemsRendered}
                  itemData={{
                    rows: flatRows,
                    columns: contactsListMd5s || getSkeletonSize(skeletonSize, ''),
                    loading,
                    setSize,
                    resetSize,
                    width: columnWidth
                  }}
                >
                  {Cell}
                </VirtalizedGridCarousel>
              </InfiniteScroll>
            )}
          </ActivitiesLayout>
          {!loading && <MoveIconHint />}
        </Box>
      )}
    </>
  )
}

export default ActivitiesByPerson
