import React, { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'

import { Narrow } from '_core/components/layout'
import CompanyMarketData from '_core/components/market-data/CompanyMarketData'
import Topbar from '_core/components/Topbar'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useCompanyMarketData from '_core/hooks/useCompanyMarketData'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const CompanyMarketDataPage = () => {
  const { companyId: name } = useParams<{ companyId: string }>()
  const { queryParams } = useSearchQuery<{ website?: string }>()
  const { website } = queryParams
  const { marketData, getMarketData } = useCompanyMarketData(name, website)
  const { setMobileHeader, setSubHeader } = useContext(LayoutContext)
  const { teamContextValue } = useContext(TeamContext)
  const { fetchWithAbort, result } = useAbortableFetch<{ data: CompanyType[] }>()

  useEffect(() => {
    setMobileHeader(name)
  }, [name, setMobileHeader])

  useEffect(() => {
    setSubHeader('Details')
    return () => setSubHeader('')
  }, [setSubHeader])

  useEffect(() => {
    if (!marketData) {
      getMarketData()
    }
  }, [marketData, getMarketData])

  useEffect(() => {
    if (website) {
      fetchWithAbort({ url: `/companies/${website}?teamNumber=${teamContextValue.teamNumber}` })
    }
  }, [website])

  const profile = result?.data?.[0]

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack />
        <CompanyMarketData company={marketData} profileLink={profile?.CompanyMd5 ? `${Paths._companies}/${profile.CompanyMd5}` : ''} />
      </Narrow>
    </Page>
  )
}

export default CompanyMarketDataPage
