import React, { useCallback } from 'react'

import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { TextFieldProps } from '@mui/material'

import Combobox, { ChipsPickList } from '_shared/forms/Combobox'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { useGeneralLookUp, GeneralFieldType } from '_core/hooks/useLookup'

export type MarketDataFieldType =
  | 'industries'
  | 'countries'
  | 'jobTitles'
  | 'jobTitleRoles'
  | 'jobTitleSubroles'
  | 'locationName'
  | 'locationRegion'
  | 'educationSchoolName'
  | 'skills'

export const companySizes = ['1-10', '11-50', '51-200', '201-500', '501-1000', '1001-5000', '5001-10000', '10001+']
export const jobTitleLevelsOptions = ['Cxo', 'Director', 'Entry', 'Manager', 'Owner', 'Partner', 'Senior', 'Training', 'Unpaid', 'Vp']
export const companyTypeOptions: MarketDataCompanyType[] = ['Private', 'Public', 'Educational', 'Government', 'Nonprofit']

const MarketDataFieldPicker = ({
  disabled,
  value,
  loadOnFocus,
  field,
  label,
  icon,
  placeholder,
  handleDelete,
  handleChange,
  className,
  inputRef,
  applyFilters
}: {
  disabled: boolean
  value?: string[]
  loadOnFocus?: boolean
  field: MarketDataFieldType
  label: string
  icon: [IconPrefix, IconName]
  placeholder: string
  handleDelete: (value: string, fieldName: MarketDataFieldType) => void
  handleChange: (v: string[], fieldName: MarketDataFieldType) => void
  applyFilters?: () => void
  className?: string
  inputRef?: TextFieldProps['inputRef']
}) => {
  const { lookUpGeneral, forceAbort } = useGeneralLookUp()

  const fieldNamesMap: { [key in MarketDataFieldType]: GeneralFieldType } = {
    industries: 'Industry',
    countries: 'Country',
    jobTitles: 'Title',
    jobTitleRoles: 'Role',
    jobTitleSubroles: 'SubRole',
    locationName: 'Location',
    locationRegion: 'Region',
    educationSchoolName: 'School',
    skills: 'Skill'
  }

  const lookUpFieldType = fieldNamesMap[field]

  const { inputValue, open, options, optionsLoading, handleClose, handleOpen, handleFocus, handleInputChange, filterOptions } = useAsyncCombobox<
    string,
    true
  >({
    loadOnFocus: !!loadOnFocus,
    loadOptions: useCallback(
      async (searchTerm: string) => {
        const result = await lookUpGeneral(lookUpFieldType, searchTerm)
        if (result) {
          return result.map(({ name }) => name)
        }
      },
      [lookUpFieldType, lookUpGeneral]
    ),
    forceAbort
  })

  const handleValueChange = (e: React.SyntheticEvent<Element, Event>, v: string[]) => {
    handleChange(v, field)
    applyFilters && applyFilters()
  }

  return (
    <>
      <Combobox<string, true>
        multiple
        inputRef={inputRef}
        forcePopupIcon={!!loadOnFocus}
        label={label}
        placeholder={placeholder}
        icon={icon}
        inputValue={inputValue}
        open={open}
        loading={optionsLoading}
        options={options}
        disabled={disabled}
        value={value || []}
        onChange={handleValueChange}
        onClose={handleClose}
        onOpen={handleOpen}
        onFocus={handleFocus}
        onInputChange={handleInputChange}
        filterOptions={filterOptions}
        className={className}
      />
      <ChipsPickList items={value?.map((name) => ({ label: name, handleDelete: () => handleDelete(name, field) })) || []} disabled={disabled} />
    </>
  )
}

export default MarketDataFieldPicker
