import { makeStyles } from 'tss-react/mui'

const useStickyStyles = makeStyles<{ sticky?: number }>()((theme, { sticky }) => ({
  root: {
    top: sticky,
    position: 'sticky',
    zIndex: 100,
    background: theme.palette.background.light
  }
}))

export default useStickyStyles
