import React, { useContext, useEffect } from 'react'

import { Box } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { Route, Switch } from 'react-router-dom'

import DownloadDataQualityPage from '_pages/data-administration/data-quality/[entity]/download'
import UploadDataQualityPage from '_pages/data-administration/data-quality/[entity]/upload'

import Page from '_shared/Page'
import Typography from '_shared/Typography'

import DataQualityActions from '_core/components/DataQualityActions'
import { Narrow, Wide } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths, { DataAdminSubPaths } from 'Paths'

const baseUrl = DataAdminSubPaths.dataQuality

const DataQualityPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const { payloads } = useSidepanelPayloads()

  useEffect(() => {
    setMobileHeader('Data quality')
  }, [setMobileHeader])

  useEffect(() => {
    if (payloads && payloads.action === 'START_FILE_TRANSFER') {
      enqueueSnackbar(payloads.value.message, {
        autoHideDuration: 4000,
        variant: payloads.value.variant
      })
    }
  }, [payloads])

  return (
    <>
      <Wide>
        <Typography variant="h1">Data quality</Typography>
        <DataQualityActions />
      </Wide>
      <Narrow>
        <Topbar nativeBack={Paths._dataAdmin} />
        <Box p={2}>
          <DataQualityActions />
        </Box>
      </Narrow>
    </>
  )
}

const DataQualityPages = () => (
  <Page>
    <Switch>
      <Route exact path={baseUrl} component={DataQualityPage} />
      <Route path={`${baseUrl}/:entity/download`} component={DownloadDataQualityPage} />
      <Route path={`${baseUrl}/:entity/upload`} component={UploadDataQualityPage} />
    </Switch>
  </Page>
)

export default DataQualityPages
