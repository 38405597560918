import React, { useContext, useEffect } from 'react'

import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import InvalidTupleSuccess from '_core/components/audit/InvalidTupleSuccess'
import TuplesList, { Heading, TuplesProps } from '_core/components/audit/TuplesList'
import AudiTupleInvalidDialog from '_core/components/dialogs/AuditTupleInvalid'
import Topbar from '_core/components/Topbar'

import useDialog from '_core/hooks/useDialog'
import { useSplitMulti } from '_core/hooks/useSplitMulti'

import DynamicEntity from '_core/DynamicEntity'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const Content = () => {
  const { id, keyword } = useParams<{ id: string } & SearchParams>()
  const { teamContextValue } = useContext(TeamContext)
  const { checkedTuples, setCheckedTuples, submitInvalid } = useSplitMulti('/people/splitmulti')
  const { openDialog, closeDialog, isDialogOpened } = useDialog(false)

  return (
    <>
      <Heading openDialog={openDialog} isDialogDisabled={!checkedTuples.length} />
      <DynamicEntity<{ extraProps: { addprops: Pick<TuplesProps, 'checkedTuples' | 'setCheckedTuples'> } }>
        url={mergeUrlWithParams(`/dataquality/people/${id}/tuples`, { teamNumber: `${teamContextValue.teamNumber}` })}
        addprops={{ checkedTuples, setCheckedTuples }}
        pageSize={10}
        keepMounted
        component={TuplesList}
        list
        infinite
        search
        empty="Tuples not found"
        emptySubtitle={keyword ? `No tuples found for your search '${keyword}'` : ''}
        id="person_tuples_list"
      />
      <AudiTupleInvalidDialog close={closeDialog} submit={submitInvalid} isOpened={isDialogOpened} />
    </>
  )
}

const TuplesListPage = () => {
  const { path } = useRouteMatch()
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Audit tuples')
  }, [setMobileHeader])

  return (
    <>
      <Topbar nativeBack title="Audit tuples" />
      <Switch>
        <Route path={`${path}/success`} component={InvalidTupleSuccess} />
        <Route path={path} component={Content} />
      </Switch>
    </>
  )
}

export default TuplesListPage
