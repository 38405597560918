import React, { ChangeEvent, useEffect, useState, useMemo } from 'react'

import { SelectChangeEvent, Divider, Fade, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

import Paths from 'Paths'

import { TouchpointControl, PeriodControl, Switcher, ToggleControl, TagsControl } from './controls'

import Filters, { wasOptions, daysOptions, scheduledOptions, andOptions, interactionsHelpText } from './index'

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 270,
      overflow: 'auto',
      height: '100%'
    }
  },
  block: {
    marginBottom: theme.spacing(1.5),
    '& .MuiTypography-h4:not(span), & .MuiTypography-body1:not(span)': {
      marginBottom: theme.spacing(1)
    }
  },
  divider: {
    margin: `${theme.spacing(0.5)} -${theme.spacing(2.5)} ${theme.spacing(1.5)}`
  },
  helpText: {
    width: `calc(100% - 5px)`
  },
  shift: {
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      marginLeft: `-${theme.spacing(2)}`,
      flex: '1 0 auto'
    }
  }
}))

export type IntroducersFiltersType = {
  total?: number
  opened: boolean
  disabled: boolean
  contentLoading: boolean
  toggleOpen: () => Promise<void | undefined> | undefined
  reset: () => Promise<IntroducersPageParams | undefined>
  anchorEl?: HTMLElement | null
  interactionsFiltersShown: boolean
  queryData: IntroducersPageParams
  handleChange: (params: IntroducersPageParams) => Promise<void | undefined>
  toggleInteractionSwitch: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void | undefined>
}

const IntroducersFilters = (props: IntroducersFiltersType) => {
  const { classes } = useStyles()
  const {
    total,
    opened,
    disabled,
    contentLoading,
    anchorEl,
    interactionsFiltersShown,
    queryData,
    handleChange,
    toggleInteractionSwitch,
    toggleOpen
  } = props
  const { days, period, and, interaction, where, includeTags, excludeTags, sort } = queryData
  const interactionsActive = !!where

  const inclTags: TagsSearchParam | undefined = useMemo(() => (includeTags ? JSON.parse(includeTags) : includeTags), [includeTags])
  const exclTags: TagsSearchParam | undefined = useMemo(() => (excludeTags ? JSON.parse(excludeTags) : excludeTags), [excludeTags])

  const [daysValue, setDaysValue] = useState<{ [key in Lowercase<PeriodOptionsType>]: DaysOptions }>({
    within: daysOptions[0].value,
    after: daysOptions[0].value
  })
  const { within, after } = daysValue

  useEffect(() => {
    if (days && period) {
      setDaysValue((prev) => ({ ...prev, [period.toLowerCase()]: +days }))
    }
  }, [days, period])

  const toggleAnd = (e: ChangeEvent<{ name: unknown }>) => {
    const { name } = e.target
    handleChange({
      and: and ? undefined : (name as IntroducersPageParams['and'])
    })
  }

  const handleInteractionChange = (e: ChangeEvent<{ name: string; value: unknown }>) => {
    const { name, value } = e.target
    handleChange({ [name]: value === 'Any' ? null : value })
  }

  const handleDaysChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target as { name: string; value: DaysOptions }

    if (name) {
      handleChange({ days: `${value}` })
    }
  }

  const handlePeriodChange = (e: ChangeEvent<{ name: string; value: unknown }>) => {
    const { name, value } = e.target as { name: string; value: string }
    handleChange(
      value === 'Anytime'
        ? { [name]: undefined, days: undefined }
        : { [name]: value, days: `${daysValue[value.toLowerCase() as keyof typeof daysValue]}` }
    )
  }

  const handleWhereChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target
    if (name) {
      const index = (where === 'Next' ? scheduledOptions : wasOptions).findIndex(
        (opt: { value: PeriodOptionsType | 'Anytime' }) => opt.value === (period || 'Anytime')
      )
      const { value: periodVal } = (value === 'Next' ? scheduledOptions : wasOptions)[index]
      handleChange({
        [name]: value,
        period: periodVal === 'Anytime' ? undefined : periodVal,
        ...((value as TouchpointDateType) === 'Next' ? { interaction: 'Meeting' } : {})
      })
    }
  }

  const reset = async () => {
    const newQueryParams = await props.reset()
    if (newQueryParams) {
      const { days, period } = newQueryParams

      if (period && days) {
        const prd = period.toLowerCase() as keyof typeof daysValue
        const daysWithoutCurPrd = { ...daysValue }
        delete daysWithoutCurPrd[prd]

        const restKeys = Object.keys(daysWithoutCurPrd)
          .filter((key) => key !== period?.toLowerCase())
          .reduce((acc, key) => ({ ...acc, [key]: 7 }), daysWithoutCurPrd)

        setDaysValue({ ...{ [prd]: days }, ...restKeys })
      } else {
        setDaysValue(Object.keys(daysValue).reduce((acc, key) => ({ ...acc, [key]: 7 }), daysValue))
      }
    }
  }

  return (
    <Filters
      opened={opened}
      className={classes.container}
      disabled={disabled}
      contentLoading={contentLoading}
      total={total}
      reset={reset}
      anchorEl={anchorEl || null}
      toggleOpen={toggleOpen}
      shift={16}
    >
      <>
        <TagsControl
          taggableType="people"
          manageTagsLink={`${Paths._tags}/people`}
          appliedInclude={inclTags}
          appliedExclude={exclTags}
          handleChange={handleChange}
          disabled={!sort || disabled}
          blockClassName={classes.block}
        />

        {interactionsFiltersShown && (
          <>
            <Switcher
              checked={!!where}
              disabled={disabled}
              label="Filter by interactions"
              toggle={toggleInteractionSwitch}
              informText={
                <>
                  You can filter introducers using data about inbound, outbound and meeting interactions.
                  {!!interactionsActive && (
                    <>
                      <br /> <br /> {interactionsHelpText}
                    </>
                  )}
                </>
              }
            />
            {!!interactionsActive || <Typography classes={{ root: classes.helpText }}>{interactionsHelpText}</Typography>}
            <Fade in={interactionsActive} unmountOnExit>
              <div>
                <Divider className={classes.divider} />
                <Box className={classes.block}>
                  <TouchpointControl
                    where={where}
                    interaction={interaction}
                    disabled={disabled}
                    handleWhereChange={handleWhereChange}
                    handleInteractionChange={handleInteractionChange}
                  />
                </Box>

                {where !== 'Next' && (
                  <Box className={classes.block}>
                    <Box className={classes.block}>
                      <PeriodControl
                        label="Was"
                        options={wasOptions.map(({ value, label }) => ({
                          value,
                          label,
                          disabled: value !== period,
                          selectValue: value === 'Within' ? within : after,
                          includeSelector: value !== 'Anytime'
                        }))}
                        disabled={disabled}
                        period={period || 'Anytime'}
                        handlePeriodChange={handlePeriodChange}
                        handleDaysChange={handleDaysChange}
                      />
                    </Box>
                    <ToggleControl value={[and || '']} disabled={disabled} toggle={toggleAnd} options={andOptions} label="And" />
                  </Box>
                )}
                {where === 'Next' && (
                  <Box className={classes.block}>
                    <PeriodControl
                      label="Is scheduled"
                      options={scheduledOptions.map(({ value, label }) => ({
                        value,
                        label,
                        disabled: value !== period,
                        selectValue: value === 'Within' ? within : after,
                        includeSelector: value !== 'Anytime'
                      }))}
                      disabled={disabled}
                      period={period || 'Anytime'}
                      handlePeriodChange={handlePeriodChange}
                      handleDaysChange={handleDaysChange}
                    />
                  </Box>
                )}
              </div>
            </Fade>
          </>
        )}
      </>
    </Filters>
  )
}

export { Controller } from './index'
export default IntroducersFilters
