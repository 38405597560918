import { useEffect, useState } from 'react'

import { Box } from '@mui/material'

import Checkbox, { useStyles as useCheckboxStyles } from '_shared/forms/Checkbox'

import HelpLinkButton from '_core/components/HelpLinkButton'
import Settings, { useStyles } from '_core/components/settings'

import useNavBarMenuItemsShown from '_core/hooks/useNavBarMenuItemsShown'
import { SaveAction } from '_core/hooks/useSaveSettings'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

const DataVisibilitySettings = () => {
  const { updateDataVisibility } = useNavBarMenuItemsShown()

  const { dataVisibility, saveDataVisibility, dataVisibilityLoading, dataVisibilitySaving: isSaving } = useUserDataVisibility()
  const [settingsData, setSettingsData] = useState<UserDataVisibilityParams>()

  const {
    classes: { iconButton, itemWrapper },
    cx
  } = useStyles()
  const { classes: checkboxClasses } = useCheckboxStyles({ align: 'top' })

  useEffect(() => {
    setSettingsData(dataVisibility)
  }, [dataVisibility])

  const save = async (action: SaveAction) => {
    const actionText = 'Data visibility settings'
    if (settingsData) {
      try {
        updateDataVisibility(await saveDataVisibility(settingsData))
        action(actionText)
      } catch {
        setSettingsData(dataVisibility)
        action(actionText, 'error')
      }
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    setSettingsData((prevSettingsData) => (prevSettingsData ? { ...prevSettingsData, [name]: checked } : prevSettingsData))
  }

  return (
    <Settings initialLoading={dataVisibilityLoading} isSaving={isSaving} save={save}>
      {settingsData && (
        <Box width="100%">
          <Box display="flex" alignItems="center">
            <Checkbox checked={settingsData.showEventsTab} name="showEventsTab" onChange={handleChange} label="Show events tab" disabled={isSaving} />
            <HelpLinkButton
              hint="Learn more about events and interactions"
              href="https://help.dotalign.com/article/7t2k2kpfzt-events-page"
              disabled={isSaving}
              classes={{ root: iconButton }}
            />
          </Box>
          <Checkbox
            checked={settingsData.showDealsTab}
            name="showDealsTab"
            onChange={handleChange}
            label="Show deals tab"
            disabled={isSaving}
            classes={{ ...checkboxClasses, root: cx(checkboxClasses.root, itemWrapper) }}
          />
          <Box display="flex" alignItems="center" className={itemWrapper}>
            <Checkbox
              checked={settingsData.showInteractionsInProfile}
              name="showInteractionsInProfile"
              onChange={handleChange}
              label="Show interactions inline with profile"
              disabled={isSaving}
            />
            <HelpLinkButton
              hint="Learn more about events and interactions"
              href="https://help.dotalign.com/article/7t2k2kpfzt-events-page"
              disabled={isSaving}
              classes={{ root: iconButton }}
            />
          </Box>
          <Checkbox
            checked={settingsData.allowFilteringByInteractions}
            name="allowFilteringByInteractions"
            onChange={handleChange}
            label="Allow filtering of people and companies by interaction data"
            classes={{ ...checkboxClasses, root: cx(checkboxClasses.root, itemWrapper) }}
            disabled={isSaving}
          />
          <Checkbox
            checked={settingsData.hideColleagueRelationships}
            name="hideColleagueRelationships"
            onChange={handleChange}
            label="Hide other contributors' relationship lists"
            classes={{ ...checkboxClasses, root: cx(checkboxClasses.root, itemWrapper) }}
            disabled={isSaving}
          />
          <Checkbox
            checked={settingsData.preventNonAdminDownload}
            name="preventNonAdminDownload"
            onChange={handleChange}
            label="Prevent non-admins from downloading relationship lists"
            classes={{ ...checkboxClasses, root: cx(checkboxClasses.root, itemWrapper) }}
            disabled={isSaving}
          />
          <Checkbox
            checked={settingsData.restrictAPIConsoleAccess}
            name="restrictAPIConsoleAccess"
            onChange={handleChange}
            label="Restrict the API console only to admins"
            classes={{ ...checkboxClasses, root: cx(checkboxClasses.root, itemWrapper) }}
            disabled={isSaving}
          />
        </Box>
      )}
    </Settings>
  )
}

export default DataVisibilitySettings
