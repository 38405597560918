import React from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'

import { IconButton } from '_shared/buttons'

import { SettingContent, settingsDetails } from '_core/components/introductions/SharingSettings'
import Widget from '_core/components/widgets'

import Paths from 'Paths'

const SharingSettingsWidget = ({ readyForReview, loading, planUid }: { readyForReview: boolean; loading: boolean; planUid: string }) => {
  const settings: { picked: boolean; label: string; icon: IconProp }[] = [
    {
      icon: settingsDetails['private'].icon,
      label: settingsDetails['private'].label,
      picked: !readyForReview
    },
    {
      icon: settingsDetails['shared'].icon,
      label: settingsDetails['shared'].label,
      picked: readyForReview
    }
  ]

  const currentSetting = loading ? undefined : settings.find((el) => el.picked)

  return (
    <Widget>
      <Box display="grid" alignItems="center" gridTemplateColumns="1fr auto">
        <SettingContent icon={currentSetting?.icon} label={currentSetting?.label} />
        {!loading && (
          <Link to={`${Paths._introductions}/${planUid}/sharing-settings`}>
            <IconButton icon={['far', 'edit']} hint="Edit" size="small" color="primary" disablePY disablePR />
          </Link>
        )}
      </Box>
    </Widget>
  )
}

export default SharingSettingsWidget
