import { useEffect, useMemo, useContext, ComponentProps, useState } from 'react'

import { Box } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { jobTitleColumn, IntrList, companyColumn } from '_pages/companies/[id]/introducers'

import { TeamContext } from '_core/context/TeamContext'

import Grid from '_core/components/grid'
import {
  lastInboundColumn,
  lastMeetingColumn,
  lastOutboundColumn,
  nameColumn,
  scoreColumn,
  nextFutureMeetingColumn,
  inboundCountColumn,
  outboundCountColumn,
  meetingsCountColumn,
  nameWithByLinesColumn
} from '_core/components/grid/columns'
import Heading from '_core/components/Heading'
import { Narrow, Column, Columns, Middle, NarrowStrict, Wide, useWide } from '_core/components/layout'
import SearchInput from '_core/components/SearchInput'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/widgets'
import ActivityStatsWidget from '_core/components/widgets/ActivityStats'
import IntroducersWidget from '_core/components/widgets/Introducers'

import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useActivitiesStats from '_core/hooks/useActivitiesStats'
import useActivitiesStatsUserSettings from '_core/hooks/useActivitiesStatsUserSettings'
import useActivityStatsPeriod from '_core/hooks/useActivityStatsPeriod'
import useCompanyActivitiesPayloads from '_core/hooks/useCompanyActivitiesPayloads'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import useIntroducersUserSettings from '_core/hooks/useIntroducersUserSettings'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'
import { stringifyUrl } from '_core/helpers/browser'
import UserSettings from '_core/UserSettings'

import { mergeUrlWithParams } from 'utils/httpUtils'
import { getUTC } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  contributorsSection: {
    [theme.breakpoints.up('sm')]: {
      minHeight: 350
    }
  },
  grid: {
    padding: 0,
    height: '100%'
  }
}))

const IntrWidget = (props: { items: CompanyIntroducerListItem[]; loading: boolean; total: number; companyName: string }) => {
  const { id } = useParams<{ id: string }>()
  const { queryParams } = useSearchQuery<ActivityStatsPageParams<GridParams>>()

  const { from, to } = queryParams
  return (
    <IntroducersWidget
      title="Stats by employee"
      loading={props.loading}
      empty={`${props.companyName} has no employees`}
      link={stringifyUrl(`${Paths._companies}/${id}/introducers`, { from, to })}
      total={props.total}
      items={props.items.slice(0, 5).map((intro) => ({
        name: intro.UserName,
        userKey: intro.UserBestEmailAddressText,
        score: intro.ScorePoints,
        link: `${Paths._relationships}/${intro.UserKeyMd5}/companies/${id}`,
        sidepanel: true
      }))}
    />
  )
}

const CompanyActivityStatisticsPage = ({ companyName }: { companyName: string }) => {
  const { id } = useParams<{ id: string }>()
  const { classes } = useStyles()
  const { updateQuery, queryParams } = useSearchQuery<ActivityStatsPageParams<GridParams>>()
  const { sort, from, to, stackColumns } = queryParams

  const wideStrict = useWide('lg')

  const { teamContextValue } = useContext(TeamContext)
  const { setMobileHeader } = useContext(LayoutContext)

  const [userSettingsParams, setUserSettingsParams] = useState<ActivityStatsPageParams<GridParams>>()

  const statsPayloads = useCompanyActivitiesPayloads(useMemo(() => [id], [id]))

  const chartData = useActivityStatsPeriod({
    fromUTC: from ? getUTC(decodeURIComponent(from)) : null,
    toUTC: to ? getUTC(decodeURIComponent(to)) : null
  })

  const { userActivitiesAccess } = useActivitiesAccess([id]) || {}
  const { isDetailedActivityFromStatsWidgetVisible = false } = userActivitiesAccess || {}

  const { result: teamResult } = useEntityEndpoint<{ results: TeamDataRes }>(`/teams/${teamContextValue.teamNumber}`)
  const { shareInteractionStats: isInteractionsEnabled } = teamResult?.results?.defaultSharingOptions || {}
  const interactionsColumnsShown = typeof isInteractionsEnabled === 'boolean' && isInteractionsEnabled

  const { stats } = useActivitiesStats(
    id ? statsPayloads : null,
    useMemo(() => [id], [id]),
    useMemo(() => chartData.months.map(({ year, month, minDay, maxDay }) => ({ year, month, minDay, maxDay })), [chartData.months])
  )

  const { handleChange: handleChangeActivities } = useActivitiesStatsUserSettings()
  const { handleChange: handleChangeIntroducers } = useIntroducersUserSettings()

  useEffect(() => {
    setMobileHeader(companyName, !companyName)
  }, [companyName, setMobileHeader])

  useEffect(() => {
    if (!stackColumns && userSettingsParams) {
      const { sort, stackColumns } = userSettingsParams || {}
      const isReadyToUpdate = sort && stackColumns
      if (isReadyToUpdate) {
        updateQuery(userSettingsParams)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettingsParams])

  const setInitialUserSettingsParams = (params: IntroducersInit | ActivityStatsInit) => {
    if ('sort' in params) {
      setUserSettingsParams((prevState) => ({ ...prevState, sort: params.sort }))
    } else {
      const { fromDate: from, toDate: to, stackColumns } = params
      setUserSettingsParams((prevState) => ({ ...prevState, from, to, stackColumns: `${stackColumns}`, rowsPerPage: `${5}` }))
    }
  }

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    updateQuery({ rowsPerPage })
  }

  const updateSort = (sort: ScoreType | StatSortType) => {
    handleChangeIntroducers({ sort })
  }

  const updatePeriod = (period: readonly [MomentType, MomentType]) => {
    handleChangeActivities({ from: period[0].toISOString(), to: period[1].toISOString() })
  }

  const toggleStack = () => {
    if (stackColumns) {
      handleChangeActivities({ stackColumns: `${!JSON.parse(stackColumns)}` })
    }
  }

  const columns = useMemo(
    () =>
      [
        { column: wideStrict ? { ...nameColumn, minWidth: 200, width: 200 } : { ...nameWithByLinesColumn, minWidth: 281, width: 281 } },
        { column: jobTitleColumn, condition: wideStrict },
        { column: companyColumn, condition: wideStrict },
        { column: inboundCountColumn },
        { column: outboundCountColumn },
        { column: meetingsCountColumn },
        { column: lastInboundColumn, condition: !!interactionsColumnsShown },
        { column: lastOutboundColumn, condition: !!interactionsColumnsShown },
        { column: lastMeetingColumn, condition: !!interactionsColumnsShown },
        { column: nextFutureMeetingColumn, condition: !!interactionsColumnsShown },
        { column: scoreColumn }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || !!condition)
        .map(({ column }) => column),
    [interactionsColumnsShown, wideStrict]
  )

  const gridEl = (
    <Widget scope="stack">
      <Grid padding={0} className={classes.grid}>
        <Heading
          underlined
          title="Introducers"
          icon={['far', 'address-book']}
          action={
            <Box minWidth="220px">
              <SearchInput variant="collapsed" placeholder="Search for introducers" opened />
            </Box>
          }
        />
        <IntrList
          columns={columns}
          months={chartData.months}
          rowsPerPageOptions={[3, 5, 10]}
          updateSort={updateSort}
          onPageSizeChange={onPageSizeChange}
        />
      </Grid>
    </Widget>
  )

  return (
    <UserSettings endpoint={!stackColumns ? '/usersettings/activitiesstats' : ''} setInitial={setInitialUserSettingsParams}>
      <UserSettings endpoint={!stackColumns ? '/usersettings/introducersfilter' : ''} setInitial={setInitialUserSettingsParams}>
        <Narrow>
          <Topbar nativeBack sub="Activity statistics" />
        </Narrow>

        <Columns spacing={0}>
          <Column xs={12} md={12}>
            <ActivityStatsWidget
              scope="stack"
              stats={stats}
              stack={stackColumns === 'true'}
              detailsLink={`${Paths._companies}/${id}/activities`}
              isDetailedActivityFromStatsWidgetVisible={isDetailedActivityFromStatsWidgetVisible}
              updatePeriod={updatePeriod}
              toggleStack={toggleStack}
              {...chartData}
            />
          </Column>
          <Column xs={12} md={12} className={classes.contributorsSection}>
            <Wide>{gridEl}</Wide>
            <Narrow>
              <Middle>{gridEl}</Middle>
              <NarrowStrict>
                <DynamicEntity<{ extraProps: { addprops: Pick<ComponentProps<typeof IntrWidget>, 'companyName'> } }>
                  url={sort ? mergeUrlWithParams(`/companies/${id}/introducers`, { teamNumber: `${teamContextValue.teamNumber}` }) : null}
                  addprops={{ companyName }}
                  component={IntrWidget}
                  list
                  keepMounted
                  empty="No results found"
                  id="company_introducers"
                />
              </NarrowStrict>
            </Narrow>
          </Column>
        </Columns>
      </UserSettings>
    </UserSettings>
  )
}

export default CompanyActivityStatisticsPage
