import { ReactNode, createContext, useEffect, useMemo } from 'react'

import { matchPath, useLocation } from 'react-router-dom'

import { useActivitiesAccessData, ActivitiesAccessData } from '_core/hooks/useActivitiesAccess'
import useAdmin from '_core/hooks/useAdmin'
import { useIntroductionsAccessData, IntroductionAccessData } from '_core/hooks/useIntroductionsAccess'
import { useDataVisibility } from '_core/hooks/useUserDataVisibility'

import Paths from 'Paths'

type ShownMenuItems = 'events' | 'activities' | 'meetings' | 'apiConsole' | 'introductions' | 'deals'

type ContextType = {
  hiddenNavbarItemsLoading: boolean
  updateDataVisibility: (data: UserDataVisibilityParams | undefined) => void
  updateUserActivitiesAccess: (activitiesAccessData: ActivitiesAccessData) => void
  updateUserIntroductionsAccess: (introductionAccessData: IntroductionAccessData) => void
  hiddenNavbarItems: ShownMenuItems[]
}

export const NavBarMenuContext = createContext({} as ContextType)

const activitiesFetchesPaths = [Paths._activities, Paths._meetings, Paths._people, Paths._companies, Paths._relationships, Paths._events]
const introductionsFetchesPaths = [Paths._introductions]
const dataVisibilityPaths = [Paths._people, Paths._companies, Paths._events]

const NavBarMenuProvider = (props: { children: ReactNode }) => {
  const { pathname } = useLocation()
  const { dataVisibility, dataVisibilityLoading, updateDataVisibility, checkDataVisibility } = useDataVisibility()

  const { userActivitiesAccess, activitiesAccessResult, updateUserActivitiesAccess, checkAccess: checkActivitiesAccess } = useActivitiesAccessData()
  const {
    isUserIntroductionsVisible,
    introductionsAccessResult,
    updateUserIntroductionsAccess,
    checkAccess: checkIntroductionsAccess
  } = useIntroductionsAccessData()

  const featuresAccessLoading = typeof userActivitiesAccess === 'undefined' || typeof isUserIntroductionsVisible === 'undefined'

  useEffect(() => {
    ;(async () => {
      const matchActivitiesFetchesPathsPaths = matchPath(pathname, { path: activitiesFetchesPaths })
      if (!matchActivitiesFetchesPathsPaths) {
        checkActivitiesAccess()
      }
      const matchIntroductionsFetchesPaths = matchPath(pathname, { path: introductionsFetchesPaths })
      if (!matchIntroductionsFetchesPaths) {
        checkIntroductionsAccess()
      }
      const matchDataVisibilityPaths = matchPath(pathname, { path: dataVisibilityPaths })
      if (!matchDataVisibilityPaths) {
        updateDataVisibility(await checkDataVisibility())
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!featuresAccessLoading) {
      if (activitiesAccessResult) {
        updateUserActivitiesAccess(activitiesAccessResult)
      }
      if (introductionsAccessResult) {
        updateUserIntroductionsAccess(introductionsAccessResult)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresAccessLoading])

  const admin = useAdmin()
  const { showEventsTab, showDealsTab, restrictAPIConsoleAccess } = dataVisibility || {}
  const { isActivityTabVisible, isMeetingsTabVisible } = userActivitiesAccess || {}

  const menuItemsToHide: { itemId: ShownMenuItems; condition: boolean }[] = [
    { itemId: 'activities', condition: !isActivityTabVisible },
    { itemId: 'events', condition: !showEventsTab },
    { itemId: 'meetings', condition: !isMeetingsTabVisible || !admin },
    { itemId: 'deals', condition: !showDealsTab },
    { itemId: 'apiConsole', condition: restrictAPIConsoleAccess === true && !admin },
    { itemId: 'introductions', condition: !isUserIntroductionsVisible }
  ]

  const loading = dataVisibilityLoading || featuresAccessLoading || typeof admin === 'undefined'
  const hiddenNavbarItems = menuItemsToHide.filter(({ condition }) => !!condition).map(({ itemId }) => itemId)

  const ctx = useMemo(
    () => ({
      hiddenNavbarItemsLoading: loading,
      hiddenNavbarItems,
      updateDataVisibility,
      updateUserActivitiesAccess,
      updateUserIntroductionsAccess
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hiddenNavbarItems, loading]
  )

  return <NavBarMenuContext.Provider value={ctx}>{props.children}</NavBarMenuContext.Provider>
}

export default NavBarMenuProvider
