import { ComponentProps, ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Stack } from '@mui/material'

import { isSidepanel } from '_pages/sidebar'

import { Button } from '_shared/buttons'

import Empty from '_core/components/Empty'
import FormLayout, { FormLayoutActions, FormLayoutContent } from '_core/components/FormLayout'
import InternalTag, { TagCategoryHeader } from '_core/components/InternalTag'
import SidepanelLink from '_core/components/SidepanelLink'

import useSidepanelClose from '_core/hooks/useSidepanelClose'

import { getSkeletonSize } from '_core/helpers/skeleton'

type ModifiedGroupedTag = Modify<
  GroupedTag,
  {
    categoryName: ComponentProps<typeof TagCategoryHeader>['categoryName']
    tagNames: Modify<GroupedTag['tagNames'][number], { tagName: string | ReactElement }>[]
  }
>
type TagsGroupedListProps = { loading?: boolean; items?: ModifiedGroupedTag[] }

type TagsListProps = {
  closeLink: string
  addTagLink: string
} & Required<TagsGroupedListProps>

export const TagsGroupedList = (props: TagsGroupedListProps) => {
  const items = props.loading ? getSkeletonSize(2, { categoryName: '', tagNames: getSkeletonSize(3, {}) }) : props.items

  return (
    <>
      {/* refactor repeater to use spacing and use repeater here */}
      {items?.map((tag: ModifiedGroupedTag, i: number) => (
        <Box key={i} mt={i > 0 ? 1 : 0}>
          <TagCategoryHeader categoryName={tag.categoryName} />
          <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
            {tag.tagNames?.map(({ tagName }, i) => (
              <Box my={0.5} key={i}>
                {typeof tagName === 'string' ? <InternalTag label={tagName} /> : tagName}
              </Box>
            ))}
          </Stack>
        </Box>
      ))}
    </>
  )
}

const TagsList = ({ items, closeLink, addTagLink, loading }: TagsListProps) => {
  const handleClose = useSidepanelClose(closeLink)
  const sidepanel = isSidepanel()

  if (!loading && items && !items.length) {
    return (
      <Box height={`calc(100vh - ${sidepanel ? 88 : 60}px)`}>
        <Empty
          icon={<FontAwesomeIcon size="4x" style={{ color: '#A7A7A7' }} icon={['far', 'tags']} />}
          title="No tags have been specified yet."
          action={
            <Box display="flex" justifyContent="center" mt={2}>
              <SidepanelLink linkProps={{ to: addTagLink }}>
                <Button startIcon={<FontAwesomeIcon icon={['far', 'plus']} style={{ fontSize: 14 }} />}>Add tags</Button>
              </SidepanelLink>
            </Box>
          }
        />
      </Box>
    )
  }

  return (
    <FormLayout>
      <FormLayoutContent>
        <TagsGroupedList loading={loading} items={items} />
      </FormLayoutContent>
      <FormLayoutActions>
        <Button variant="text" onClick={handleClose} color="secondary">
          Close
        </Button>
      </FormLayoutActions>
    </FormLayout>
  )
}

export default TagsList
