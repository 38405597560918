import React, { ChangeEvent } from 'react'

import { Box, RadioGroup } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

import useControlsStyles from './controls/styles'

import Filters from './index'

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 270,
      overflow: 'auto',
      height: '100%'
    }
  },
  block: {
    marginBottom: theme.spacing(1.5),
    '& .MuiTypography-h4:not(span), & .MuiTypography-body1:not(span)': {
      marginBottom: theme.spacing(1)
    }
  }
}))

export type PersonActivitiesFiltersType = {
  total?: number
  opened: boolean
  disabled: boolean
  contentLoading: boolean
  prohibitInteractionSwitch: boolean
  anchorEl?: HTMLElement | null
  queryData: EntityActivitiesPageParams
  handleChange: (params: Pick<EntityActivitiesPageParams, 'interaction'>) => void
  toggleOpen: () => void
  reset: () => void
}

const interactionOptions: { value: EntityActivitiesInit['touchpointType']; label: string }[] = [
  { value: 'Inbound', label: 'inbound' },
  { value: 'Outbound', label: 'outbound' },
  { value: 'Meeting', label: 'meeting' },
  { value: 'Any', label: 'any activity' }
]

const PersonActivitiesFilters = (props: PersonActivitiesFiltersType) => {
  const { classes, cx } = useStyles()
  const { classes: controlClasses } = useControlsStyles()
  const { total, opened, disabled, contentLoading, prohibitInteractionSwitch, anchorEl, queryData, handleChange, toggleOpen, reset } = props
  const { interaction } = queryData

  const handleInteractionChange = (e: ChangeEvent<{ name: string; value: unknown }>) => {
    const { name, value } = e.target
    handleChange({ [name]: value === 'Any' ? undefined : value })
  }

  return (
    <Filters
      disabled={disabled}
      opened={opened}
      className={classes.container}
      contentLoading={contentLoading}
      total={total}
      anchorEl={anchorEl || null}
      toggleOpen={toggleOpen}
      reset={reset}
    >
      <Box className={classes.block}>
        <Typography variant="h4" semiBold classes={{ root: cx(controlClasses.label, controlClasses.header) }} component="label">
          Type
        </Typography>
        <RadioGroup name="interaction" value={!disabled ? interaction || 'Any' : ''} onChange={handleInteractionChange}>
          {interactionOptions.map((item) => (
            <Filters.Radio
              key={item.value}
              disabled={disabled || (prohibitInteractionSwitch && interaction !== item.value)}
              value={item.value}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </Box>
    </Filters>
  )
}

export { Controller } from './index'
export default PersonActivitiesFilters
