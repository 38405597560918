import { useEffect, useState } from 'react'

import { useLookUpActiveDirectoryMembers } from '_core/hooks/useLookup'

const initState = {
  displayName: '',
  emailAddress: ''
}

export type PersonType = typeof initState

const useActiveDirectoryPersonData = (email: string) => {
  const [person, setPerson] = useState(initState)
  const [loading, setLoading] = useState(false)
  const { lookUpActiveDirectoryMembers } = useLookUpActiveDirectoryMembers()

  useEffect(() => {
    if (email) {
      ;(async () => {
        setLoading(true)
        const res = await lookUpActiveDirectoryMembers(email)
        if (res) {
          const details = res.users.map((user) => ({
            displayName: user.displayName,
            emailAddress: user.emailAddress
          }))
          setPerson(details[0])
          setLoading(false)
        }
      })()
    }
  }, [email])

  return {
    person,
    loading,
    setPerson
  }
}

export default useActiveDirectoryPersonData
