import React, { useEffect, useState, ReactElement, ChangeEvent, ReactNode, useRef, useMemo, useCallback, memo } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TablePagination, LinearProgress, Box, MenuItem, Divider, TablePaginationProps, SelectChangeEvent, Stack } from '@mui/material'
import {
  DataGridPro,
  GridColumns,
  GridColDef,
  GridRowModel,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridRowParams,
  GridSortModel,
  GridRowId,
  GridSelectionModel,
  HideGridColMenuItem,
  GridColumnsMenuItem,
  GridColumnMenuContainer,
  GridColumnMenuProps,
  useGridApiRef,
  useGridApiContext,
  GridPinnedPosition,
  GridColumnVisibilityModel,
  ElementSize,
  GridSelectedRowCount,
  GridRowCount,
  DataGridProProps
} from '@mui/x-data-grid-pro'
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro'
import { makeStyles } from 'tss-react/mui'

import Badge from '_shared/Badge'
import { Button } from '_shared/buttons'
import Checkbox from '_shared/forms/Checkbox'
import Select from '_shared/forms/Select'
import TextField from '_shared/forms/TextField'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { useWide } from '_core/components/layout'
import { sortMap } from '_core/components/sort'

import useSearchQuery from '_core/hooks/useSearchQuery'

import { activeIcon } from 'AppTheme'

const useStyles = makeStyles()((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(2)
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(2),
    width: '100%',
    boxSizing: 'border-box',
    minHeight: '100%',
    background: theme.palette.background.light,
    borderRadius: 'inherit',
    '& > div:last-child': {
      display: 'flex',
      flexFlow: 'column',
      height: '100%'
    }
  },
  row: {
    '&.highlighted': {
      backgroundColor: theme.palette.background.active
    }
  },
  pagination: {
    '&&': {
      borderBottom: 0,
      marginLeft: 'auto',
      boxSizing: 'content-box'
    }
  },
  actions: {
    marginLeft: `${theme.spacing(1)}!important`,
    color: theme.palette.text.secondary,
    '& .MuiSvgIcon-root': {
      margin: -1,
      width: theme.spacing(2.5),
      height: theme.spacing(2.5)
    }
  },
  pageSelect: {
    '&&': {
      border: `1px solid ${theme.palette.text.secondary}`,
      padding: '3px 11px',
      borderRadius: 4,
      backgroundColor: theme.palette.background.light,
      '&:focus': {
        backgroundColor: theme.palette.background.light,
        borderRadius: 4
      },
      '&:hover': {
        borderColor: theme.palette.secondary.main
      },
      '&.MuiSelect-select': {
        paddingRight: '32px'
      },
      '& ~ .MuiSelect-icon': {
        right: '5px'
      }
    }
  },
  menu: {
    '& .MuiMenuItem-root': {
      '&:hover': {
        backgroundColor: theme.palette.background.darker
      }
    }
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center'
  },
  toolbar: {
    position: 'relative',
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.background.darker,
    height: 52,
    boxSizing: 'border-box',
    '& .MuiTablePagination-toolbar': {
      minHeight: '100%'
    },
    '& .MuiButton-root .MuiSvgIcon-root': {
      fontSize: '23px'
    },
    '& .MuiDataGrid-toolbarContainer': {
      padding: `0 0 0 ${theme.spacing(0.5)}`,
      '& .MuiButton-root': {
        padding: `${theme.spacing(0.75)} ${theme.spacing(1)}`
      }
    }
  },
  topScroller: {
    width: '100%',
    height: scrollBarWidth,
    overflowX: 'scroll',
    overflowY: 'hidden',
    position: 'absolute',
    zIndex: 10
  },
  headerItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '&:first-of-type': {
      flex: 0,
      minWidth: 'fit-content'
    },
    '&:last-of-type': {
      flex: 1,
      justifyContent: 'flex-end'
    }
  },
  icon: {
    ...activeIcon,
    marginRight: theme.spacing(1)
  },
  caption: {
    fontSize: '14px',
    color: theme.palette.text.secondary
  },
  rowCount: {
    marginLeft: 'auto'
  },
  footer: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto 1fr',
    height: 52,
    borderTop: '1px solid #f2f2f2'
  },
  select: {
    width: 60,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2)
  }
}))

const scrollBarWidth = 5
const defaultRowsPerPageOptions: RowPerPageOptionsType[] = [10, 20, 50]

export const LoadingOverlay = memo(() => (
  <GridOverlay>
    <div style={{ position: 'absolute', top: 0, width: '100%' }}>
      <LinearProgress />
    </div>
  </GridOverlay>
))

export const NoRowsOverlay = memo(({ text }: { text: string }) => (
  <Stack height="100%" alignItems="center" justifyContent="center">
    {text}
  </Stack>
))

const transformRowsOptions = (options: RowPerPageOptionsType[]): { label: NumberToString<RowPerPageOptionsType>; value: RowPerPageOptionsType }[] => {
  return options.map((value) => ({
    label: `${value}`,
    value
  }))
}

const PageSizeSelect = (props: {
  loading: boolean
  size: number
  setPageSize?: (val: NumberToString<RowPerPageOptionsType>) => void
  rowsPerLoadOptions?: RowPerPageOptionsType[]
}) => {
  const { classes } = useStyles()
  if (props.loading) return null

  return (
    <>
      <Typography className={classes.caption}>Rows per load</Typography>
      <Select
        size="small"
        className={classes.select}
        options={transformRowsOptions(props.rowsPerLoadOptions || defaultRowsPerPageOptions)}
        onChange={(e: SelectChangeEvent<unknown>) => {
          if (props.setPageSize) {
            props.setPageSize(`${e.target.value}` as NumberToString<RowPerPageOptionsType>)
          }
        }}
        value={props.size}
      />
    </>
  )
}

const Pagination = memo(
  (
    props: { loading: boolean; total: number; size?: number; page?: number } & Pick<
      TablePaginationProps,
      'onPageChange' | 'onRowsPerPageChange' | 'rowsPerPageOptions'
    >
  ) => {
    const { classes } = useStyles()
    const { loading, total, page, size, onPageChange, onRowsPerPageChange, rowsPerPageOptions } = props

    return (
      <TablePagination
        component={Box}
        classes={{
          root: classes.pagination,
          select: classes.pageSelect,
          actions: classes.actions,
          menuItem: classes.menuItem,
          selectLabel: classes.caption,
          displayedRows: classes.caption
        }}
        rowsPerPage={+(size || 0)}
        rowsPerPageOptions={rowsPerPageOptions || (loading ? [] : defaultRowsPerPageOptions)}
        page={(page || 1) - 1}
        count={total || 0}
        labelRowsPerPage="Rows per page"
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    )
  }
)

const TopScrollBar = memo(() => {
  const { classes } = useStyles()
  const topScrollerRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)
  const apiRef = useGridApiContext()

  const gridContainer = apiRef?.current?.windowRef?.current
  const virtualContentWidth = document.querySelector<HTMLDivElement>('.MuiDataGrid-virtualScrollerContent')?.clientWidth
  const topScroller = topScrollerRef.current

  useEffect(() => {
    if (virtualContentWidth) setWidth(virtualContentWidth)
  }, [virtualContentWidth])

  useEffect(() => {
    if (topScroller && gridContainer) {
      const assignVirtualScrollToTop = () => (topScroller.scrollLeft = gridContainer.scrollLeft)
      const assignTopScrollToVirtual = () => (gridContainer.scrollLeft = topScroller.scrollLeft)
      const assignScrollHandler = () => (topScroller.onscroll = assignTopScrollToVirtual)
      const removeScrollHandler = () => (topScroller.onscroll = null)

      gridContainer.onscroll = assignVirtualScrollToTop
      topScroller.onmousedown = assignScrollHandler
      topScroller.onmouseup = removeScrollHandler

      return () => {
        gridContainer.onscroll = null
        topScroller.onscroll = null
        topScroller.onmousedown = null
        topScroller.onmouseup = null
      }
    }
  }, [topScroller, gridContainer])

  return (
    <div className={classes.topScroller} ref={topScrollerRef}>
      <Box height={scrollBarWidth} width={width} />
    </div>
  )
})

const GridCustomColumnMenu = memo((props: GridColumnMenuProps) => {
  const { hideMenu, currentColumn, color, ...other } = props

  const { classes } = useStyles()
  const apiRef = useGridApiContext()
  const pinned = apiRef.current.isColumnPinned(currentColumn.field)
  const { queryParams, updateQuery } = useSearchQuery<GridParams>()
  const { sort, excludeEmpty } = queryParams

  const hasExcludeOption = sortMap[currentColumn.field]?.excludeEmpty

  const toggleExclude = (e: React.SyntheticEvent) => {
    updateQuery({ excludeEmpty: excludeEmpty === 'true' ? 'false' : 'true' })
    hideMenu(e)
  }

  const sortAsc = (e: React.SyntheticEvent) => {
    apiRef.current.sortColumn(currentColumn, 'asc')
    hideMenu(e)
  }

  const sortDesc = (e: React.SyntheticEvent) => {
    apiRef.current.sortColumn(currentColumn, 'desc')
    hideMenu(e)
  }

  const { asc, desc } = sortMap[currentColumn.field] || {}

  return (
    <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} {...other}>
      {currentColumn.sortable && (
        <Box>
          {hasExcludeOption && (
            <MenuItem disabled={sort && ![asc, desc].includes(sort)}>
              <Checkbox checked={excludeEmpty === 'true'} name="exclude_nulls" onChange={toggleExclude} label="Hide where no future meeting" />
            </MenuItem>
          )}
          <MenuItem disabled={sort?.includes('Asc')} onClick={sortAsc}>
            Sort Asc
          </MenuItem>
          <MenuItem disabled={sort?.includes('Desc')} onClick={sortDesc}>
            Sort Desc
          </MenuItem>
        </Box>
      )}
      <Box className={classes.menu}>
        <HideGridColMenuItem onClick={hideMenu} column={currentColumn} />
        <GridColumnsMenuItem onClick={hideMenu} column={currentColumn} />
      </Box>
      {currentColumn.pinnable && (
        <Box>
          <Divider />
          {pinned !== GridPinnedPosition.left && (
            <MenuItem
              onClick={(e: React.SyntheticEvent) => {
                apiRef.current.pinColumn(currentColumn.field, GridPinnedPosition.left)
                hideMenu(e)
              }}
            >
              Pin to left
            </MenuItem>
          )}
          {pinned !== GridPinnedPosition.right && (
            <MenuItem
              onClick={(e: React.SyntheticEvent) => {
                apiRef.current.pinColumn(currentColumn.field, GridPinnedPosition.right)
                hideMenu(e)
              }}
            >
              Pin to right
            </MenuItem>
          )}
          {pinned && (
            <MenuItem
              onClick={(e: React.SyntheticEvent) => {
                apiRef.current.unpinColumn(currentColumn.field)
                hideMenu(e)
              }}
            >
              Unpin
            </MenuItem>
          )}
        </Box>
      )}
    </GridColumnMenuContainer>
  )
})

const GridCustomFooter = memo((props: any) => {
  const apiRef = useGridApiContext()
  const [selectedRowCount, setSelectedRowCount] = useState<number>(0)
  const { classes } = useStyles()

  useEffect(() => {
    const handleSelectionChange = () => {
      const selectedRows = apiRef.current.getSelectedRows()
      setSelectedRowCount(selectedRows.size)
    }
    // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`
    return apiRef.current.subscribeEvent('selectionChange', handleSelectionChange)
  }, [apiRef])

  return (
    <Box className={classes.footer}>
      <Box>{selectedRowCount > 0 && <GridSelectedRowCount selectedRowCount={selectedRowCount} className={classes.caption} />}</Box>
      <Box>{props.footerControls?.map((Control: ReactElement, i: number) => <Box key={i}>{Control}</Box>)}</Box>
      {props.pagination}
    </Box>
  )
})

const GridCustomToolbar = memo(
  (props: { loading: boolean; controls: ReactElement[]; pagination: ReactElement | null; topScrollBar: ReactElement | null }) => {
    const { classes } = useStyles()

    return (
      <>
        <Box className={classes.toolbar}>
          <Box>
            <GridToolbarContainer>
              <GridToolbarColumnsButton disabled={props.loading} />
            </GridToolbarContainer>
          </Box>
          {props.controls?.map((Control: ReactElement, i: number) => <Box key={i}>{Control}</Box>)}
          {props.pagination}
        </Box>
        {props.topScrollBar}
      </>
    )
  }
)

const GridColumnHeaderFilterIconButton = memo(
  ({ counter, loading }: { loading: boolean; field: GridColDef['field']; counter: number | undefined }) => {
    return (
      <>
        {!loading && counter ? (
          <Tooltip title={`${counter} filter${counter > 1 ? 's' : ''} applied`}>
            <Box px={1.5}>
              <Badge badgeContent={counter} max={9999} overlap="circular" size="xs" />
            </Box>
          </Tooltip>
        ) : null}
      </>
    )
  }
)

const defaultColumns: GridColDef[] = [{ field: 'id', headerName: 'Id' }]

export const DataGrid = (props: DataGridProps) => {
  const [highlightedRow, setHighlightedRow] = useState<GridRowId>()
  const [currentGridColumnVisibilityModel, setCurrentGridColumnVisibilityModel] = useState<GridColumnVisibilityModel>({})
  const { classes, cx } = useStyles()
  const wide = useWide()
  const { queryParams, updateQuery } = useSearchQuery<GridParams>()
  const { rowsPerPage, sort, excludeEmpty } = queryParams
  const apiRef = useGridApiRef()

  const setSortModelFunc = (model: GridSortModel) => {
    if (model?.length) {
      const sort = model[0].sort === 'asc' ? 'ScoreAsc' : 'ScoreDesc'
      if (queryParams.sort && queryParams.sort !== sort) {
        updateQuery({ sort: model[0].sort === 'asc' ? 'ScoreAsc' : 'ScoreDesc', excludeEmpty: null, page: null })
      }
    }
  }

  const { setPageSize, paging, onPageChange, rowsPerPageOptions, setSortModel = setSortModelFunc } = props
  const { page, size = rowsPerPage } = paging || {}
  const autoHeight = typeof props.autoHeight === 'boolean' ? props.autoHeight : !props.loading

  const sortModel: GridSortModel = useMemo(
    () =>
      props.sortModel ||
      (props.columns.findIndex((column) => column.field === 'score') > -1 ? [{ field: 'score', sort: sort === 'ScoreAsc' ? 'asc' : 'desc' }] : []),
    [props.columns, props.sortModel, sort]
  )

  const initSort = sortModel?.[0] && sortModel[0].sort

  useEffect(() => {
    if (initSort && !props.loading && !rowsPerPage && !sort) {
      updateQuery({ rowsPerPage: `${size || 10}`, sort: initSort === 'asc' ? 'ScoreAsc' : 'ScoreDesc' })
    }
  }, [initSort, props.loading, size, rowsPerPage, sort, updateQuery])

  useEffect(() => {
    const checkSidepanelUpdate = (e: any) => {
      const { key, newValue: sidepanelUrl } = e
      if (key === 'sidepanel') {
        sidepanelUrl || setHighlightedRow(-1)
      }
    }
    if (wide) {
      window.addEventListener('storage', checkSidepanelUpdate)
      return () => {
        window.removeEventListener('storage', checkSidepanelUpdate)
      }
    }
  }, [wide])

  const handlePageChange = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, p: number) => {
      if (onPageChange) {
        onPageChange(p)
        return
      }

      updateQuery({ page: `${p + 1}` })
    },
    [onPageChange, updateQuery]
  )

  const updatePageSize = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (setPageSize) {
        setPageSize(`${e.target.value}` as NumberToString<RowPerPageOptionsType>)
      }
    },
    [setPageSize]
  )

  const highlightRow = useCallback(
    (id: GridRowId) => {
      setHighlightedRow(id)
    },
    [setHighlightedRow]
  )

  const columns: GridColumns = useMemo(
    () =>
      (props.columns || defaultColumns).map((column: GridColDef) => ({
        ...column,
        sortable: !props.loading && column.sortable
      })),
    [props.columns, props.loading]
  )

  const rows = useMemo(() => props.rows?.map((row) => ({ ...row, onClick: highlightRow })) || [], [props.rows, highlightRow])

  const pagination = useMemo(
    () => ({
      loading: props.loading,
      total: props.total,
      size: +(size || '10'),
      page,
      rowsPerPageOptions,
      onRowsPerPageChange: updatePageSize,
      onPageChange: handlePageChange
    }),
    [handlePageChange, page, props.loading, props.total, size, rowsPerPageOptions, updatePageSize]
  )

  const baseButton = useMemo(() => ({ variant: 'text' }), [])
  const infinitePage = page === 'infinite'

  const toolbar = useMemo(
    () => ({
      controls: props.controls,
      loading: props.loading,
      topScrollBar: !props.loading && rows.length > 8 ? <TopScrollBar /> : null,
      pagination: infinitePage ? (
        <Box display="flex" alignItems="center" ml="auto">
          <PageSizeSelect loading={props.loading} setPageSize={setPageSize} size={+(size || '10')} rowsPerLoadOptions={rowsPerPageOptions} />
          <GridRowCount rowCount={props.total || 0} visibleRowCount={rows.length} className={cx(classes.rowCount, classes.caption)} />
        </Box>
      ) : (
        <Pagination
          loading={props.loading}
          total={props.total || 0}
          size={+(size || '10')}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={updatePageSize}
          onPageChange={handlePageChange}
        />
      )
    }),
    [handlePageChange, page, props.controls, props.loading, rows.length, props.total, size, updatePageSize, rowsPerPageOptions]
  )

  const footer = useMemo(
    () => ({
      footerControls: props.footerControls,
      loading: props.loading,
      pagination: infinitePage ? (
        <GridRowCount rowCount={props.total || 0} visibleRowCount={rows.length} className={cx(classes.rowCount, classes.caption)} />
      ) : (
        <Pagination
          loading={props.loading}
          total={props.total || 0}
          size={+(size || '10')}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={updatePageSize}
          onPageChange={handlePageChange}
        />
      )
    }),
    [handlePageChange, page, props.footerControls, props.loading, rows.length, props.total, size, updatePageSize, rowsPerPageOptions]
  )

  const columnMenu = useMemo(() => ({ loading: props.loading }), [props.loading])
  const columnHeaderFilterIconButton = useMemo(() => ({ loading: props.loading }), [props.loading])

  const componentsProps = useMemo(
    () => ({
      pagination,
      baseButton,
      toolbar,
      columnMenu,
      columnHeaderFilterIconButton,
      footer,
      ...props.componentsProps
    }),
    [baseButton, pagination, toolbar, columnMenu, columnHeaderFilterIconButton, footer, props.componentsProps]
  )

  const components = useMemo(
    () => ({
      Toolbar: GridCustomToolbar,
      Footer: GridCustomFooter,
      BaseButton: Button,
      BaseTextField: TextField,
      ColumnMenu: GridCustomColumnMenu,
      ColumnHeaderFilterIconButton: GridColumnHeaderFilterIconButton,
      LoadingOverlay,
      Pagination,
      ...props.components
    }),
    [props.components]
  )

  const gridClasses = useMemo(() => ({ row: classes.row }), [classes.row])

  const filterModel = useMemo(
    () => ({
      items:
        excludeEmpty === 'true' && (sort === 'NextFutureDesc' || sort === 'NextFutureAsc')
          ? [{ columnField: 'nextFutureMeeting', operatorValue: 'isNotEmpty' }]
          : []
    }),
    [excludeEmpty, sort]
  )

  return (
    <DataGridPro
      apiRef={apiRef}
      columns={columns}
      rows={rows}
      classes={gridClasses}
      className={props.className}
      checkboxSelection={!!props.checkboxSelection}
      loading={props.loading}
      paginationMode="server"
      sortingMode="server"
      filterMode="server"
      sortingOrder={['desc', 'asc']}
      sortModel={!props.loading ? sortModel : []}
      onSortModelChange={setSortModel}
      filterModel={filterModel}
      pagination={!!size}
      onResize={props.onResize}
      components={components}
      componentsProps={componentsProps}
      getRowClassName={(params: GridRowParams) => (params.id === highlightedRow ? 'highlighted' : '')}
      onSelectionModelChange={props.onSelect}
      isRowSelectable={props.isRowSelectable}
      disableSelectionOnClick
      disableMultipleColumnsFiltering
      autoHeight={autoHeight}
      rowHeight={props.rowHeight || 52}
      headerHeight={52}
      rowCount={rows.length}
      initialState={props.initialState}
      columnVisibilityModel={currentGridColumnVisibilityModel}
      onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) => setCurrentGridColumnVisibilityModel(newModel)}
    />
  )
}

const Heading = (props: { title: string; children?: ReactNode; icon?: IconProp }) => {
  const { classes } = useStyles()
  return (
    <Box className={classes.header}>
      <div className={classes.headerItem}>
        {props.icon && <FontAwesomeIcon icon={props.icon} className={classes.icon} />}
        {props.title && (
          <Typography variant="h3" semiBold>
            {props.title}
          </Typography>
        )}
      </div>
      <div className={classes.headerItem}>{props.children}</div>
    </Box>
  )
}

const Grid = (props: { children?: ReactNode; className?: string; padding?: number }) => {
  const { classes, cx } = useStyles()
  return (
    <Box className={cx(classes.wrapper, props.className)} padding={typeof props.padding === 'number' ? props.padding : 2}>
      {props.children}
    </Box>
  )
}

Grid.Heading = Heading
export default Grid

export type DataGridProps =
  | ({ loading: true } & Modify<GridTypes, Partial<Omit<GridTypes, 'rows' | 'columns' | 'controls'>>>)
  | ({
      loading: false
    } & GridTypes)

export type GridTypes = {
  rows: GridRowModel[]
  columns: GridColDef[]
  controls: ReactElement[]
  footerControls?: ReactElement[]
  checkboxSelection?: boolean
  onPageChange?: (val: number) => void
  onSelect?: (selectionModel: GridSelectionModel) => void
  onResize?: (containerSize: ElementSize) => void
  setSortModel?: (model: GridSortModel) => void
  sortModel?: GridSortModel
  rowHeight?: number
  isRowSelectable?: (params: GridRowParams) => boolean
  initialState?: GridInitialStatePro
  className?: string
  rowCount?: number
  autoHeight?: boolean
} & GridPaginationType &
  Pick<DataGridProProps, 'components' | 'componentsProps'>

export type GridPaginationType = {
  paging: {
    page: number | 'infinite'
    size: NumberToString<RowPerPageOptionsType>
  }
  rowsPerPageOptions?: RowPerPageOptionsType[]
  setPageSize: (val: NumberToString<RowPerPageOptionsType>) => void
  total: number
}
