import { ComponentProps } from 'react'

import { CircularProgress } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Button from '_shared/buttons/Button'

const useStyles = makeStyles()(() => ({
  root: {
    position: 'absolute',
    display: 'flex'
  },
  hidden: {
    opacity: 0
  },
  icon: {
    alignItems: 'center'
  }
}))

const LoadingButton = ({ children, loading, disabled, startIcon, endIcon, ...props }: ComponentProps<typeof Button>) => {
  const { classes, cx } = useStyles()

  const hideContent = loading && !startIcon && !endIcon
  const hideStartIcon = startIcon && loading
  const hideEndIcon = endIcon && loading

  const circularProgress = <CircularProgress classes={{ root: classes.root }} size={16} color="inherit" />

  return (
    <Button
      {...props}
      disabled={disabled || loading}
      classes={{ startIcon: classes.icon, endIcon: classes.icon }}
      startIcon={
        <>
          {hideStartIcon && circularProgress}
          <span className={cx({ [classes.hidden]: hideStartIcon })}>{startIcon}</span>
        </>
      }
      endIcon={
        <>
          {hideEndIcon && circularProgress}
          <span className={cx({ [classes.hidden]: hideEndIcon })}>{endIcon}</span>
        </>
      }
    >
      {hideContent && circularProgress}
      <span className={cx({ [classes.hidden]: hideContent })}>{children}</span>
    </Button>
  )
}

export default LoadingButton
