import React, { useContext, useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import { InfoEmailsAddPropsType } from '_pages/people/[id]/info/emails'
import { InfoPhonesAddPropsType } from '_pages/people/[id]/info/phones'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'

import Heading from '_core/components/Heading'
import InfoEmails from '_core/components/InfoEmails'
import InfoPhones from '_core/components/InfoPhones'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import Topbar from '_core/components/Topbar'
import { ContactInfoEmailsWidget, ContactInfoPhonesWidget } from '_core/components/widgets/ContactInformation'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const ContactInfo = (props: { name: string }) => {
  const { teamContextValue } = useContext(TeamContext)
  const { id } = useParams<{ id: string }>()
  const { setSubHeader } = useContext(LayoutContext)
  const [totalEmails, setTotalEmails] = useState(0)
  const [totalNumbers, setTotalNumbers] = useState(0)

  useEffect(() => {
    setSubHeader('Contact information')
    return () => setSubHeader('')
  }, [setSubHeader])

  const wide = useWide()

  return (
    <Page variant="core">
      <Narrow>
        <Topbar nativeBack />
      </Narrow>

      <Wide>
        <Heading title="Contact information" icon={['far', 'address-card']} variant="main" />
      </Wide>

      <Columns spacing={wide ? 1 : 0}>
        <Column two>
          <ContactInfoEmailsWidget total={totalEmails} link={totalEmails > 10 ? `${Paths._people}/${id}/info/emails` : ''}>
            <DynamicEntity<{ extraProps: { addprops: InfoEmailsAddPropsType } }>
              url={`/people/${id}/emailAddresses?TeamNumber=${teamContextValue.teamNumber}&Take=10`}
              component={InfoEmails}
              addprops={{ setTotalEmails: setTotalEmails, name: props.name }}
              list={true}
              id="person_info_email"
            />
          </ContactInfoEmailsWidget>
        </Column>
        <Column two>
          <ContactInfoPhonesWidget total={totalNumbers} link={totalNumbers > 10 ? `${Paths._people}/${id}/info/phones` : ''}>
            <DynamicEntity<{ extraProps: { addprops: InfoPhonesAddPropsType } }>
              url={`/people/${id}/phoneNumbers?TeamNumber=${teamContextValue.teamNumber}&Take=10`}
              component={InfoPhones}
              addprops={{ setTotalNumbers, person: { name: props.name, id: id } }}
              list={true}
              id="person_info_phone"
            />
          </ContactInfoPhonesWidget>
        </Column>
      </Columns>
    </Page>
  )
}

export default ContactInfo
