import { FunctionComponent, ReactElement } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Box, Paper } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Grid from '_core/components/grid'
import { Narrow, Wide } from '_core/components/layout'
import Sticky from '_core/components/layout/sticky'
import SearchInput from '_core/components/SearchInput'

const useStyles = makeStyles<{ loading: boolean; height: number | string; isSticky: boolean }>()((theme, { loading, height, isSticky }) => ({
  heading: {
    display: 'flex'
  },
  paper: {
    display: 'flex',
    position: 'relative',
    minHeight: '100%'
  },
  addButton: {
    position: 'absolute',
    right: 0,
    top: 30,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      position: 'relative',
      top: 0,
      flexShrink: 0
    }
  },
  filter: {
    flexGrow: 0,
    height: isSticky || loading ? `calc(100vh - ${loading ? 90 : 60}px)` : 'inherit',
    minHeight: height,
    borderRadius: '4px 0 0 4px'
  },
  grid: {
    borderLeft: `1px solid #f2f2f2`,
    overflowX: 'scroll'
  }
}))

type GridPageFrameProps = {
  loading: boolean
  filterHeight?: number
  gridTitle: string
  searchPlaceholder?: string
  disabledSearch?: boolean
  filters: ReactElement
  heading: ReactElement
  component: ReactElement
  headingAdornmentStart?: ReactElement
  headingAdornmentEnd?: FunctionComponent
  stickFilters?: boolean
  gridHeadingIcon?: IconProp
  searchClassName?: string
  widgetClassName?: string
}

const GridPageFrame = (props: GridPageFrameProps) => {
  const {
    filters,
    component,
    headingAdornmentStart,
    headingAdornmentEnd: HeadingAdornmentEnd,
    heading,
    gridTitle,
    searchPlaceholder,
    disabledSearch,
    loading,
    filterHeight = '100%',
    stickFilters,
    gridHeadingIcon,
    searchClassName,
    widgetClassName
  } = props
  const { classes, cx } = useStyles({ height: filterHeight, loading, isSticky: !!stickFilters })

  return (
    <>
      <Wide>
        <Paper elevation={0} className={cx(classes.paper, widgetClassName)}>
          <Sticky {...(stickFilters && { sticky: 60 })} className={classes.filter}>
            {filters}
          </Sticky>
          <Grid className={classes.grid}>
            <Grid.Heading title={gridTitle} icon={gridHeadingIcon}>
              <Box display="flex" alignItems="center" gap={2}>
                {headingAdornmentStart}
                {searchPlaceholder && (
                  <SearchInput variant="collapsed" disabled={disabledSearch} placeholder={searchPlaceholder} className={searchClassName} opened />
                )}
                {HeadingAdornmentEnd && <HeadingAdornmentEnd />}
              </Box>
            </Grid.Heading>
            {component}
          </Grid>
        </Paper>
      </Wide>
      <Narrow>
        {heading}
        {headingAdornmentStart}
        {component}
        {HeadingAdornmentEnd && <HeadingAdornmentEnd />}
      </Narrow>
    </>
  )
}

export default GridPageFrame
