import { useEffect, useContext } from 'react'

import { Box, Tab, Tabs } from '@mui/material'
import { Link, Route } from 'react-router-dom'

import Card, { CardContent } from '_shared/Card'
import Page from '_shared/Page'
import Typography from '_shared/Typography'

import { Narrow, Wide } from '_core/components/layout'
import SettingsAccordion from '_core/components/SettingsAccordion'

import useTabs from '_core/hooks/useTabs'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

import DemoSettingsPage from './demo'
import EmailSettingsPage from './email'
import GeneralSettingsPage from './general'

export const UserSettings = () => {
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Settings')
  }, [setMobileHeader])

  const enabledDemoMode = window.localStorage.getItem('demo_mode') === 'true'

  const tabs = [
    {
      label: 'GENERAL',
      component: GeneralSettingsPage,
      routesMatch: [`${Paths._settings}`]
    },
    {
      label: 'EMAIL',
      component: EmailSettingsPage,
      routesMatch: [`${Paths._settings}/email`]
    },
    {
      label: 'DEMO',
      component: DemoSettingsPage,
      routesMatch: [`${Paths._settings}/demo`],
      condition: enabledDemoMode
    }
  ].filter(({ condition }) => condition !== false)

  const { tabIndex } = useTabs(tabs)

  return (
    <Page>
      <Wide>
        <Card elevation={0}>
          <CardContent sx={{ mx: 5, my: 3, minHeight: 'calc(100vh - 215px)' }}>
            <Typography variant="h1" style={{ marginBottom: 16 }}>
              Settings
            </Typography>
            <Tabs value={tabIndex > -1 ? tabIndex : false} variant="scrollable" scrollButtons="auto">
              {tabs.map(({ routesMatch, label }) => (
                <Tab key={routesMatch[0]} label={label} component={Link} to={routesMatch[0]} />
              ))}
            </Tabs>
            {tabs.map(({ routesMatch, component }) => (
              <Route exact key={routesMatch[0]} path={routesMatch[0]} component={component} />
            ))}
          </CardContent>
        </Card>
      </Wide>
      <Narrow>
        <Box p={2}>
          <SettingsAccordion tabs={tabs} />
        </Box>
      </Narrow>
    </Page>
  )
}
