import { ComponentProps } from 'react'

import { Checkbox as MuiCheckbox, FormControlLabel, FormControlLabelProps, CheckboxProps, FormControlLabelClasses } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Tooltip from '_shared/Tooltip'

export const useStyles = makeStyles<{ align?: 'center' | 'top' }>()((theme, { align }) => ({
  root: {
    display: 'flex',
    alignItems: align === 'top' ? 'flex-start' : 'center'
  },
  label: {
    marginTop: align === 'top' ? 9 : 0,
    width: '100%'
  }
}))

type CheckboxType = Pick<CheckboxProps, 'checked' | 'indeterminate' | 'disabled' | 'onChange' | 'name' | 'inputRef' | 'defaultChecked'> &
  Pick<FormControlLabelProps, 'label'> & { align?: 'center' | 'top' } & { classes?: Partial<FormControlLabelClasses> } & {
    tooltipText?: ComponentProps<typeof Tooltip>['title']
  }

const Checkbox = (props: CheckboxType) => {
  const { classes } = useStyles({ align: props.align || 'top' })
  return (
    <FormControlLabel
      inputRef={props.inputRef}
      control={
        <Tooltip title={props.tooltipText}>
          <MuiCheckbox
            indeterminate={props.indeterminate}
            checked={props.checked}
            defaultChecked={props.defaultChecked}
            disabled={props.disabled}
            onChange={props.onChange}
            name={props.name}
          />
        </Tooltip>
      }
      label={props.label}
      classes={props.classes || { root: classes.root, label: classes.label }}
    />
  )
}

export default Checkbox
