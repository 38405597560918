/* eslint-disable react/jsx-key */
import React, { Fragment, useEffect } from 'react'

import { useTheme } from '@mui/material/styles'
import { Moment as MomentType } from 'moment'

import { AlertWidget } from '_pages/people/[id]/touchpoints'

import { ActivitiesListItem } from '_core/components/ActivitiesList'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import ActivityStatsWidget from '_core/components/widgets/ActivityStats'
import AffiliationsWidget from '_core/components/widgets/Affiliations'
import CompaniesWidget from '_core/components/widgets/Companies'
import ContactInformationWidget from '_core/components/widgets/ContactInformation'
import ExternalKeysWidget from '_core/components/widgets/ExternalKeys'
import InteractionsWidget, { InteractionType } from '_core/components/widgets/Interactions'
import IntroducersWidget from '_core/components/widgets/Introducers'
import KeyTouchpointsWidget from '_core/components/widgets/KeyTouchpoints'
import PeopleWidget from '_core/components/widgets/People'
import MarketDataWidget from '_core/components/widgets/PersonMarketData'
import SimilarPeopleWidget from '_core/components/widgets/SimilarPeople'

import useActivitiesStatsUserSettings from '_core/hooks/useActivitiesStatsUserSettings'
import { ActivityStatsPeriod } from '_core/hooks/useActivityStatsPeriod'
import useSearchQuery from '_core/hooks/useSearchQuery'
import { PeopleType as SimilarPeopleType } from '_core/hooks/useSimilarPeople'
import useTouchpointSummary from '_core/hooks/useTouchpointSummary'

import UserSettings from '_core/UserSettings'

import Paths from 'Paths'

export type ProfileAddProps = {
  activityStats: Pick<ActivitiesListItem, 'outbound' | 'inbound' | 'meetings'>[] | undefined
  activityStatsChartData: ActivityStatsPeriod
  isSalesforceEnabled: boolean | undefined
  showInteractionsInProfile: boolean | undefined
  interactions:
    | {
        are_more: boolean
        skip_token: string
        data: InteractionType[]
      }
    | { errorMessage: string }
    | null
    | undefined
  marketData: PersonMarketDataType | null
  similar: SimilarPeopleType | null
  getSimilar: () => Promise<void>
  getPersonDetails: (name: string, email?: string) => PeopleMarketDataType | undefined
  enabledMarketData: MarketDataIntegration['enabled']
  showSimilarPeople: MarketDataIntegration['showSimilarPeople']
  isStatsWidgetVisible: boolean | undefined
  isDetailedActivityFromStatsWidgetVisible: boolean | undefined
  isMeetingsTabVisible: boolean | undefined
  hideColleagueRelationships: boolean | undefined
  UserKey: ProfileType['UserKey'] | undefined
}

export const checkContributor = (loading: boolean, email: string, myUserKeyMd5: string, userEmail: string, hideColleagueRelationships: boolean) =>
  !!((!hideColleagueRelationships || email === userEmail) && !loading && myUserKeyMd5)

const Profile = (props: DynamicEntityChild<PersonType, true, false, ProfileAddProps>) => {
  const wideStrict = useWide('lg')
  const theme = useTheme()

  const { setInitial, handleChange } = useActivitiesStatsUserSettings()
  const { queryParams } = useSearchQuery<ActivityStatsPageParams>()
  const { stackColumns } = queryParams

  useEffect(() => {
    if (!props.loading && props.PersonMd5) window.parent.postMessage('da_open', '*')
  }, [props.loading, props.PersonMd5])

  const statsArr = !props.loading
    ? Object.getOwnPropertyNames(props.Stats).filter(
        (k) =>
          ['FirstInboundMsg', 'FirstMeeting', 'FirstOutboundMsg', 'LastInboundMsg', 'LastMeeting', 'LastOutboundMsg', 'NextFutureMeeting'].indexOf(
            k
          ) > -1
      )
    : []

  const keyTouchpoints = useTouchpointSummary(props.Stats)
  const personName = props.PersonNameText || props.BestEmailAddrText
  const md5 = props.MyUserKeyMd5 || props.PersonMd5

  const updatePeriod = (period: readonly [MomentType, MomentType]) => {
    handleChange({ from: period[0].toISOString(), to: period[1].toISOString() })
  }

  const toggleStack = () => {
    handleChange({ stackColumns: `${!JSON.parse(stackColumns || 'false')}` })
  }

  const IntroducersWidgetEl = (
    <IntroducersWidget
      title="Introducers"
      loading={props.loading}
      total={props.Introducers?.total_item_count}
      empty={`${personName} has no introducers`}
      link={`${Paths._people}/${md5}/introducers`}
      items={
        props.Introducers?.data.slice(0, 5).map((intro) => ({
          name: intro.IntroducerName,
          userKey: intro.IntroducerBestEmailAddressText,
          score: intro.ScorePoints,
          sidepanel: true,
          link: `${Paths._relationships}/${intro.UserKeyMd5 || intro.IntroducerPersonKeyMd5}/people/${props.PersonMd5}`
        })) || []
      }
    />
  )

  const AffiliationsWidgetEl = (
    <AffiliationsWidget
      loading={props.loading}
      items={props.Jobs?.data}
      total={props.Jobs?.total_item_count}
      personName={personName}
      personId={props.PersonMd5}
      link={props.Jobs?.total_item_count ? `${Paths._people}/${md5}/affiliations` : ''}
    />
  )

  const ActivitiesStatsWidgetEl = (
    <UserSettings
      key={md5 && `${!!stackColumns}`}
      endpoint={!stackColumns && props.isStatsWidgetVisible ? '/usersettings/activitiesstats' : ''}
      setInitial={setInitial}
    >
      <ActivityStatsWidget
        isDetailedActivityFromStatsWidgetVisible={!!props.isDetailedActivityFromStatsWidgetVisible}
        stats={props.activityStats}
        stack={stackColumns === 'true'}
        seeAllLink={`${Paths._people}/${md5}/activityStats`}
        detailsLink={`${Paths._people}/${md5}/activities`}
        updatePeriod={updatePeriod}
        toggleStack={toggleStack}
        {...props.activityStatsChartData}
      />
    </UserSettings>
  )

  const TouchpointsWidgetEl = (
    <KeyTouchpointsWidget
      loading={props.loading}
      link={statsArr.length && !props.loading ? `${Paths._people}/${md5}/touchpoints` : ''}
      items={keyTouchpoints || []}
      // check whether stats could be null
      total={props.loading || props.Stats === null ? -1 : statsArr.length}
      entityName={personName}
    />
  )

  const ContactInformationWidgetEl = (
    <ContactInformationWidget
      link={`${Paths._people}/${md5}/info`}
      loading={props.loading}
      phones={
        props.Phones?.data.map((phone) => ({
          phoneType: phone.PhoneType,
          number: phone.StandardizedPhoneNumber,
          person: { name: personName, id: props.PersonMd5 }
        })) || []
      }
      phonesTotal={props.Phones?.total_item_count}
      emails={
        props.Emails?.data.map((email) => ({
          identity: email.AddressText,
          type: 'EmailAddress',
          icon: ['far', 'envelope'],
          name: personName,
          sourceLink: `${Paths._people}/${md5}/emailSources/${email.AddressText}`,
          auditLink: `${Paths._people}/${md5}/audit`
        })) || []
      }
      emailsTotal={props.Emails?.total_item_count}
    />
  )

  const PeopleWidgetEl = (
    <PeopleWidget
      loading={props.loading}
      title="People"
      empty={`${props.PersonNameText} has no people`}
      link={`${Paths._people}/${md5}/people`}
      total={props.UserContacts?.total_item_count}
      items={
        props.UserContacts?.data?.map((contact) => ({
          userKey: contact.ContactUserKeyMd5 ? contact.BestEmailAddrText : '',
          name: contact.PersonNameText,
          score: contact.ScorePoints,
          sidepanel: true,
          link: `${Paths._relationships}/${md5}/people/${contact.PersonMd5}`
        })) || []
      }
    />
  )

  const CompaniesWidgetEl = (
    <CompaniesWidget
      loading={props.loading}
      total={props.UserCompanies?.total_item_count}
      link={`${Paths._people}/${md5}/companies`}
      title="Companies"
      empty={`${personName} has no companies`}
      items={
        props.UserCompanies?.data.map((company) => ({
          name: company.CompanyNameText,
          score: company.ScorePoints,
          logoUrl: company.BestUrlText,
          sidepanel: true,
          link: `${Paths._relationships}/${md5}/companies/${company.CompanyMd5}`
        })) || []
      }
    />
  )

  const InteractionsWidgetEl = (
    <InteractionsWidget
      interactions={props.interactions && 'data' in props.interactions ? props.interactions.data : undefined}
      errorMessage={props.interactions && 'errorMessage' in props.interactions ? props.interactions.errorMessage : undefined}
      loading={props.loading}
      entityName={personName}
      link={props.PersonMd5 ? `${Paths._people}/${props.PersonMd5}/interactions` : ''}
    />
  )

  const MarketDataWidgetEl = (
    <MarketDataWidget
      md5={md5}
      marketData={!!props.marketData}
      name={personName}
      loading={props.loading}
      locationName={props.marketData?.locationName}
      industry={props.marketData?.industry}
      jobCompanyIndustry={props.marketData?.jobCompanyIndustry}
      skills={props.marketData?.skills}
      interests={props.marketData?.interests}
      experience={props.marketData?.experience}
      education={props.marketData?.education}
      workEmail={props.marketData?.workEmail}
      id={props.marketData?.id}
    />
  )

  const SimilarPeopleWidgetEl = (
    <SimilarPeopleWidget name={personName} marketData={!!props.marketData} similar={props.similar} loading={props.loading} />
  )

  const ExternalKeysWidgetEl = props.CrmSourceKeys?.data?.length ? (
    <ExternalKeysWidget
      entity="person"
      loading={props.loading}
      keys={props.CrmSourceKeys.data.map((key) => ({ sourceId: key.SourceId, lastModified: key.LastModifiedDateTime }))}
      total={props.CrmSourceKeys.total_item_count}
      name={personName}
      link={`${Paths._people}/${md5}/keys`}
    />
  ) : (
    <></>
  )

  const contributor = checkContributor(
    props.loading,
    props.BestEmailAddrText,
    props.MyUserKeyMd5 || '',
    props?.UserKey || '',
    !!props.hideColleagueRelationships
  )

  const columnsRows = [
    {
      condition: contributor && props.showInteractionsInProfile && props.enabledMarketData && props.showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, CompaniesWidgetEl], [SimilarPeopleWidgetEl, PeopleWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, SimilarPeopleWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[PeopleWidgetEl], [CompaniesWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: contributor && props.showInteractionsInProfile && props.enabledMarketData && !props.showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, CompaniesWidgetEl], [PeopleWidgetEl, TouchpointsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, CompaniesWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[PeopleWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: contributor && props.showInteractionsInProfile && !props.enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[PeopleWidgetEl, TouchpointsWidgetEl], [CompaniesWidgetEl, AffiliationsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[PeopleWidgetEl, CompaniesWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: contributor && !props.showInteractionsInProfile && props.enabledMarketData && props.showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [SimilarPeopleWidgetEl], [PeopleWidgetEl]] },
            { columns: [[CompaniesWidgetEl], [TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [SimilarPeopleWidgetEl]] },
            { columns: [[PeopleWidgetEl], [CompaniesWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: contributor && !props.showInteractionsInProfile && props.enabledMarketData && !props.showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [CompaniesWidgetEl], [PeopleWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [CompaniesWidgetEl]] },
            { columns: [[PeopleWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: contributor && !props.showInteractionsInProfile && !props.enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[PeopleWidgetEl], [CompaniesWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[PeopleWidgetEl], [CompaniesWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && props.isStatsWidgetVisible && props.showInteractionsInProfile && props.enabledMarketData && props.showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [InteractionsWidgetEl]]
            },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[SimilarPeopleWidgetEl], [TouchpointsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [SimilarPeopleWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && props.isStatsWidgetVisible && props.showInteractionsInProfile && props.enabledMarketData && !props.showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[ActivitiesStatsWidgetEl, []], [InteractionsWidgetEl]] },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && props.isStatsWidgetVisible && props.showInteractionsInProfile && !props.enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[ActivitiesStatsWidgetEl, []], [InteractionsWidgetEl]] },
            { columns: [[IntroducersWidgetEl], [AffiliationsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[IntroducersWidgetEl, TouchpointsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && props.isStatsWidgetVisible && !props.showInteractionsInProfile && props.enabledMarketData && props.showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [MarketDataWidgetEl]]
            },
            { columns: [[IntroducersWidgetEl], [SimilarPeopleWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[SimilarPeopleWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition:
        !contributor && props.isStatsWidgetVisible && !props.showInteractionsInProfile && props.enabledMarketData && !props.showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [MarketDataWidgetEl]]
            },
            { columns: [[IntroducersWidgetEl], [TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && props.isStatsWidgetVisible && !props.showInteractionsInProfile && !props.enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            {
              columns: [[ActivitiesStatsWidgetEl, []], [IntroducersWidgetEl]]
            },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [ActivitiesStatsWidgetEl] },
            { columns: [[IntroducersWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && !props.isStatsWidgetVisible && props.showInteractionsInProfile && props.enabledMarketData && props.showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, AffiliationsWidgetEl], [IntroducersWidgetEl, SimilarPeopleWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[SimilarPeopleWidgetEl, TouchpointsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition:
        !contributor && !props.isStatsWidgetVisible && props.showInteractionsInProfile && props.enabledMarketData && !props.showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, AffiliationsWidgetEl], [IntroducersWidgetEl, TouchpointsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && !props.isStatsWidgetVisible && props.showInteractionsInProfile && !props.enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl, AffiliationsWidgetEl], [TouchpointsWidgetEl, ContactInformationWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl, TouchpointsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition:
        !contributor && !props.isStatsWidgetVisible && !props.showInteractionsInProfile && props.enabledMarketData && props.showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [SimilarPeopleWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[SimilarPeopleWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition:
        !contributor && !props.isStatsWidgetVisible && !props.showInteractionsInProfile && props.enabledMarketData && !props.showSimilarPeople,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !contributor && !props.isStatsWidgetVisible && !props.showInteractionsInProfile && !props.enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl], [TouchpointsWidgetEl], [AffiliationsWidgetEl]] },
            { columns: [[ContactInformationWidgetEl], [ExternalKeysWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[AffiliationsWidgetEl], [ContactInformationWidgetEl]] },
            { columns: [[ExternalKeysWidgetEl]] }
          ]
        }
      ]
    }
  ]

  const colRows = (columnsRows.find(({ condition }) => !!condition) || columnsRows[0]).rows.find(({ condition }) => condition)?.colRows

  return (
    <>
      <Wide>
        <Columns>
          <Column md={12}>
            <AlertWidget {...props} />
          </Column>
        </Columns>
        {colRows?.map(({ columns }, k) => (
          <Columns key={k}>
            {columns.map((column, i) => {
              if (!Array.isArray(column)) {
                return (
                  <Column key={`${k}${i}`} md={12}>
                    {column}
                  </Column>
                )
              }
              return (
                <Column key={`${k}${i}`} two={!wideStrict} double={!!column.find((r) => Array.isArray(r))}>
                  {column.map((row, j) =>
                    Array.isArray(row) ? (
                      <Columns
                        key={`${k}${i}${j}`}
                        md={12}
                        minWidth={`calc(100% + ${theme.spacing(2)})`}
                        {...(!row.length ? { display: 'none' } : {})}
                      >
                        {row.map((r, y) => (
                          <Column md={6} key={`${k}${i}${j}${y}`}>
                            {r}
                          </Column>
                        ))}
                      </Columns>
                    ) : (
                      <Fragment key={`${k}${i}${j}`}>{row}</Fragment>
                    )
                  )}
                </Column>
              )
            })}
          </Columns>
        ))}
      </Wide>
      <Narrow>
        <AlertWidget {...props} />
        {props.enabledMarketData && MarketDataWidgetEl}
        {!contributor && props.isStatsWidgetVisible && ActivitiesStatsWidgetEl}
        {!contributor && IntroducersWidgetEl}
        {props.showSimilarPeople && SimilarPeopleWidgetEl}
        {contributor && (
          <>
            {PeopleWidgetEl}
            {CompaniesWidgetEl}
          </>
        )}
        {props.showInteractionsInProfile && InteractionsWidgetEl}
        {TouchpointsWidgetEl}
        {AffiliationsWidgetEl}
        {ContactInformationWidgetEl}
        {ExternalKeysWidgetEl}
      </Narrow>
    </>
  )
}

export default Profile
