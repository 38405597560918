import { useContext, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'

import AliasNames, { AliasNamesProps } from '_core/components/AliasNames'
import Heading from '_core/components/Heading'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const CompanyAliasesNames = (props: { companyName: string }) => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = useContext(TeamContext)
  const { setSubHeader } = useContext(LayoutContext)
  const [total, setTotal] = useState<number>()

  useEffect(() => {
    setSubHeader('Company aliases - names')
    return () => setSubHeader('')
  }, [setSubHeader])

  return (
    <Page variant="core">
      <Narrow>
        <Topbar nativeBack />
        <Box p={2}>
          <Heading title="Aliases" icon={['far', 'building']} count={total} />
          <DynamicEntity<{ extraProps: { addprops: Pick<AliasNamesProps, 'companyName' | 'setTotalAliases'> } }>
            url={`/companies/${id}/aliases?TeamNumber=${teamContextValue.teamNumber}&Take=10`}
            component={AliasNames}
            addprops={{ companyName: props.companyName, setTotalAliases: setTotal }}
            list={true}
            infinite={true}
            id="company_alias_names"
          />
        </Box>
      </Narrow>
    </Page>
  )
}

export default CompanyAliasesNames
