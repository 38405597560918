import React, { useContext, useEffect } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridRenderCellParams, GridColDef, GridSortModel } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'

import { TeamContext } from '_core/context/TeamContext'

import { Button } from '_shared/buttons'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import DataUploadsListItem from '_core/components/data-uploads/ListItem'
import { FileProps, FileTransformedProps } from '_core/components/data-uploads/types'
import { DataGrid, GridPaginationType } from '_core/components/grid'
import { SidepanelLinkCell, TextCell, DescriptionCell } from '_core/components/grid/columns'
import { Narrow, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import { sortMap } from '_core/components/sort/DataUploads'

import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { getBinary } from 'utils/httpUtils'
import { triggerDownloadXl } from 'utils/Utils'

import Paths from 'Paths'

export type DataUploadsListProps = {
  loading: boolean
  items: FileProps[]
  forceNarrow?: boolean
  columns: GridColDef[]
} & GridPaginationType

const DataUploadsList = (props: DataUploadsListProps) => {
  const { teamContextValue } = useContext(TeamContext)
  const { payloads } = useSidepanelPayloads()
  const { enqueueSnackbar } = useSnackbar()
  const { queryParams, updateQuery } = useSearchQuery<DataUploadsPageParams>()
  const { sort } = queryParams
  const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort) || 'date'

  useEffect(() => {
    if (payloads && payloads.action === 'START_FILE_TRANSFER') {
      enqueueSnackbar(payloads.value.message, {
        autoHideDuration: 4000,
        variant: payloads.value.variant
      })
    }
  }, [payloads])

  const downloadFile = async (storedAt: string, storedAs: string, fileName: string) => {
    if (storedAt && storedAs && fileName) {
      enqueueSnackbar(`Downloading ${fileName}...`, {
        autoHideDuration: 4000
      })
      try {
        const fetchedTemplate = await getBinary('/people/uploadedfile', {
          storedAt,
          storedAs,
          downloadAs: fileName,
          teamNumber: teamContextValue.teamNumber.toString()
        })
        triggerDownloadXl(fetchedTemplate, fileName.substring(0, fileName.lastIndexOf('.xlsx')) + '')
      } catch (error) {
        enqueueSnackbar('Encountered error during downloading', {
          variant: 'error'
        })
        console.log('error during download', error)
      }
    }
  }

  const items: FileTransformedProps[] = props.items?.map((file: FileProps) => {
    const fileTypeMap: { [key: FileProps['uploadedFileType']]: { type: FileTransformedProps['fileType']; icon: IconProp } } = {
      ContactsUpsertion: { type: 'Contacts', icon: ['far', 'address-book'] },
      AccountsUpsertion: { type: 'Companies', icon: ['far', 'building'] },
      OurDealsUpsertion: { type: 'Deals', icon: ['far', 'handshake'] },
      MergeRiskPeople: { type: 'Audit People', icon: ['far', 'list-check'] },
      MergeRiskCompanies: { type: 'Audit Companies', icon: ['far', 'list-check'] },
      LinkedIn: { type: 'LinkedIn', icon: ['fab', 'linkedin'] }
    }

    const { type, icon } = fileTypeMap[file.uploadedFileType]

    const statuses: {
      status: FileTransformedProps['status']
      condition: boolean
    }[] = [
      { status: { name: 'incorporated', title: 'processed' }, condition: file.indexingComplete },
      { status: { name: 'pending' }, condition: !file.indexingComplete && !file.errorMessage },
      { status: { name: 'rejected', title: file.errorMessage || '' }, condition: !file.indexingComplete && !!file.errorMessage }
    ]

    return {
      id: `${file.uploadTimeUtc}`,
      status: statuses.find(({ condition }) => condition)?.status || statuses[0].status,
      fileName: file.fileName,
      date: file.uploadTimeUtc,
      uploader: {
        name: file.uploadedBy,
        link: `${Paths._people}/${file.uploadedBy}`,
        sidepanel: true
      },
      onBehalfOf: {
        name: file.userKey,
        link: file.userKey.includes('http') ? '' : `${Paths._people}/${file.userKey}`,
        sidepanel: true
      },
      fileType: type,
      fileTypeIcon: icon,
      storedAt: file.storedAt,
      storedAs: file.storedAs,
      description: file.description,
      downloadHandler: () => downloadFile(file.storedAt, file.storedAs, file.fileName),
      title: file.label
    }
  })

  const updateSort = (model: GridSortModel) => {
    if (model?.length && queryParams.sort) {
      const { field, sort } = model[0]
      if (sort) {
        const newSort = sortMap[field][sort]
        if (queryParams.sort !== newSort) {
          updateQuery({ sort: newSort })
        }
      }
    }
  }

  const models: GridSortModel = [
    { field: 'date', sort: sort === 'DateAsc' ? 'asc' : 'desc' },
    { field: 'file_name', sort: sort === 'FileNameAsc' ? 'asc' : 'desc' },
    { field: 'file_type', sort: sort === 'FileTypeAsc' ? 'asc' : 'desc' }
  ]

  return (
    <>
      <Wide>
        <DataGrid
          rows={items}
          columns={props.columns}
          controls={[]}
          loading={props.loading}
          setPageSize={props.setPageSize}
          checkboxSelection={false}
          paging={props.paging}
          total={props.items?.[0]?.totalItemCount}
          setSortModel={updateSort}
          sortModel={models.filter((m) => m.field === sortByField)}
        />
      </Wide>

      <Narrow>
        <Repeater direction="vertical" component={DataUploadsListItem} skeleton={{ size: 20, loading: props.loading }} items={items} />
      </Narrow>
    </>
  )
}

export default DataUploadsList

export const fileNameColumn: GridColDef = {
  field: 'file_name',
  headerName: 'File name',
  sortable: true,
  filterable: false,
  type: 'string',
  flex: 1,
  renderCell: (params: GridRenderCellParams) => (
    <>
      {params.row.storedAt && params.row.storedAs && params.row.fileName ? (
        <Button variant="link" onClick={params.row.downloadHandler} style={{ padding: 0 }}>
          <Tooltip title={`download ${params.row.fileName}`}>
            <Typography style={{ maxWidth: '100%' }} noWrap>
              {params.row.fileName}
            </Typography>
          </Tooltip>
        </Button>
      ) : (
        <TextCell value={params.row.fileName} />
      )}
    </>
  )
}

export const fileTypeColumn: GridColDef = {
  field: 'file_type',
  headerName: 'File type',
  width: 130,
  flex: 0.6,
  sortable: true,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => (
    <Typography style={{ maxWidth: '100%' }} noWrap>
      <FontAwesomeIcon icon={params.row.fileTypeIcon} style={{ paddingRight: 8, color: '#979797', width: 15 }} />
      {params.row.fileType}
    </Typography>
  )
}

export const uploaderColumn: GridColDef = {
  field: 'uploader',
  headerName: 'Uploaded by',
  width: 180,
  flex: 1,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { row, id } = params
    const { uploader, onClick } = row
    return <SidepanelLinkCell value={uploader.name} id={id} onClick={onClick} link={uploader.link} sidepanel={uploader.sidepanel} />
  }
}

export const descriptionColumn: GridColDef = {
  field: 'description',
  headerName: 'Description',
  width: 180,
  flex: 1,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <DescriptionCell value={params.row.description} />
}

export const titleColumn: GridColDef = {
  field: 'title',
  headerName: 'Title',
  width: 130,
  flex: 0.6,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => <TextCell value={params.row.title} />
}

export const onBehalfOfColumn: GridColDef = {
  field: 'onBehalfOf',
  headerName: 'Uploaded for',
  width: 130,
  flex: 0.6,
  filterable: false,
  renderCell: (params: GridRenderCellParams) => {
    const { row, id } = params
    const { onBehalfOf, onClick } = row
    return <SidepanelLinkCell value={onBehalfOf.name} id={id} onClick={onClick} link={onBehalfOf.link} sidepanel={onBehalfOf.sidepanel} />
  }
}
