import { useContext, useEffect, useState } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import { transformResponse } from '_core/hooks/useSimilarPeople'

import { mergeUrlWithParams } from 'utils/httpUtils'

import useAbortableFetch from './useAbortableFetch'

export type CSuiteType = { data: PeopleMarketDataType[]; id?: string; website?: string } & PaginationMarketData

const useCSuite = (companyMd5: string, website: string, isConfigured: boolean) => {
  const [cSuite, setCSuite] = useState<CSuiteType | null>(null)
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { fetchWithAbort } = useAbortableFetch<PeopleMarketDataRes>()

  useEffect(() => {
    return () => {
      setCSuite(null)
    }
  }, [companyMd5])

  const getCSuite = async () => {
    if (website && teamNumber) {
      const res = await fetchWithAbort({
        url: mergeUrlWithParams(`/cloudhub/companies/${website}/csuite`, {
          titlecase: 'true',
          teamNumber: teamNumber.toString(),
          ...(cSuite?.scrollToken && { scrollToken: cSuite.scrollToken })
        })
      })
      if (res) {
        const transformedData = transformResponse(res.data)
        setCSuite({
          data: cSuite?.data ? [...cSuite.data, ...transformedData] : transformedData,
          scrollToken: res.scrollToken || '',
          total: res.total || 0
        })
      }
    }
  }

  useEffect(() => {
    if (isConfigured && !cSuite && teamNumber) {
      website
        ? getCSuite()
        : setCSuite({
            data: [],
            scrollToken: '',
            total: 0
          })
    }
  }, [isConfigured, website, cSuite, teamNumber])

  const getPersonDetails = (name: string, email?: string): PeopleMarketDataType | undefined =>
    cSuite?.data?.find((item) => item.fullName === name || (email && item.workEmail === email))

  return {
    cSuite,
    getCSuite,
    getPersonDetails
  }
}

export default useCSuite
