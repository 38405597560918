import { EmailSettings } from '_core/components/settings/user'

import DynamicEntity from '_core/DynamicEntity'

const EmailSettingsPage = () => {
  return (
    <DynamicEntity url={`/usersettings/email`} component={EmailSettings} empty="Email related settings not found" list={true} id="email_settings" />
  )
}

export default EmailSettingsPage
