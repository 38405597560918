import React, { useContext, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, RadioGroup } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import { Button } from '_shared/buttons'
import Radio from '_shared/forms/Radio'
import TextField from '_shared/forms/TextField'

import { FormLayoutActions, FormLayoutContent } from '_core/components/FormLayout'
import { Narrow } from '_core/components/layout'
import Success from '_core/components/Success'
import Topbar from '_core/components/Topbar'

import useSidepanelClose from '_core/hooks/useSidepanelClose'

import { postBinary } from 'utils/httpUtils'
import { getLocal, triggerDownloadXl } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import { DataAdminSubPaths } from 'Paths'

type FormType = { ids: string }
type DownloadOptionsType = 'full' | 'specific'

const useStyles = makeStyles()(() => ({
  wrapper: {
    display: 'flex',
    height: 'calc(100% - 100px)'
  },
  root: {
    height: '100%'
  },
  input: {
    height: '100%',
    '& textarea': {
      minHeight: '100%',
      maxHeight: '100%'
    }
  }
}))

const baseUrl = DataAdminSubPaths.dataQuality
const downloadMap: {
  [key in DataQualityEntities]: {
    url: string
    fileName: string
  }
} = {
  people: {
    url: '/dataquality/peopleriskreportxl',
    fileName: 'people-risk-report'
  },
  companies: {
    url: '/dataquality/companiesriskreportxl',
    fileName: 'companies-risk-report'
  }
}

const DownloadReport = ({ entity }: { entity: DataQualityEntities }) => {
  const history = useHistory()
  const { teamContextValue } = useContext(TeamContext)
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [downloadMode, setDownloadMode] = useState<DownloadOptionsType>('full')
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormType>({ defaultValues: { ids: '' } })
  const handleClose = useSidepanelClose()
  const { classes } = useStyles()

  const { url, fileName } = downloadMap[entity]

  const handleDownload = async (values: FormType) => {
    setLoading(true)
    enqueueSnackbar('The download should begin shortly.', {
      variant: 'default',
      autoHideDuration: 4000
    })

    const specificIdentifiers = values.ids.split('\n').filter((v) => v.trim())
    const teamNumber = teamContextValue.teamNumber.toString()

    try {
      const report = await postBinary(url, { specificIdentifiers }, { teamNumber })
      if (report) {
        const onClickHandler = () => {
          history.push(`${baseUrl}/${entity}/download/success`)
        }
        const currentDateAndTime = getLocal().format('YYYYMMDD-hhmmss')
        triggerDownloadXl(report, `${fileName}-${currentDateAndTime}`, onClickHandler)
      } else {
        setTimeout(() => {
          enqueueSnackbar('No file found', {
            variant: 'default'
          })
        }, 5000)
      }
    } catch (error) {
      enqueueSnackbar('Encountered error during downloading', {
        variant: 'error'
      })
      console.log('error during download', error)
    }
    setLoading(false)
  }

  const handleModeChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setDownloadMode(value as DownloadOptionsType)
    reset({ ids: '' })
  }

  const errorMessage = errors.ids?.message
  const downloadOptions: { value: DownloadOptionsType; label: string }[] = [
    { value: 'full', label: 'Download full report' },
    { value: 'specific', label: `Download report for specific ${entity}` }
  ]

  return (
    <Box>
      <FormLayoutContent>
        <RadioGroup value={downloadMode} onChange={handleModeChange}>
          {downloadOptions.map(({ value, label }) => (
            <Radio key={value} value={value} label={label} />
          ))}
        </RadioGroup>
        {downloadMode === 'specific' && (
          <Box className={classes.wrapper}>
            <TextField
              {...register('ids', {
                required: 'This field is required'
              })}
              multiline
              fullWidth
              errorMessage={errorMessage}
              helperText={!errorMessage && <span>Enter one {entity === 'companies' ? 'company' : 'person'} identity per line</span>}
              InputProps={{ className: classes.input }}
              classes={{ root: classes.root }}
            />
          </Box>
        )}
      </FormLayoutContent>
      <FormLayoutActions>
        <Button variant="text" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="text" onClick={handleSubmit(handleDownload)} disabled={loading || !!Object.keys(errors).length} disablePR>
          Download
        </Button>
      </FormLayoutActions>
    </Box>
  )
}

const DownloadSuccess = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const handleClose = useSidepanelClose()

  const handleDownloadMore = () => {
    history.replace(`${match.url}`.replace('/success', ''))
  }

  return (
    <FormLayoutContent>
      <Success variant="centered" text="Download complete">
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={2}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<FontAwesomeIcon icon={['far', 'cloud-arrow-up']} style={{ fontSize: 14 }} />}
            onClick={handleDownloadMore}
          >
            Download more
          </Button>
          <Button variant="text" onClick={handleClose} color="secondary">
            Close
          </Button>
        </Box>
      </Success>
    </FormLayoutContent>
  )
}

const DownloadDataQualityPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const { entity } = useParams<{ entity: DataQualityEntities }>()

  useEffect(() => {
    setMobileHeader(`Download ${entity} report`)
  }, [setMobileHeader, entity])

  return (
    <Narrow>
      <Topbar nativeBack />
      <Switch>
        <Route path={`${baseUrl}/${entity}/download/success`}>
          <DownloadSuccess />
        </Route>
        <Route>
          <DownloadReport entity={entity} />
        </Route>
      </Switch>
    </Narrow>
  )
}

export default DownloadDataQualityPage
