import { useCallback, useContext, useEffect, useState } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import useAbortableFetch from '_core/hooks/useAbortableFetch'

import { capitalizeWord } from '_core/helpers/string'

import { mergeUrlWithParams } from 'utils/httpUtils'

export const transformCompanyData = (company: CompanyMarketDataRes | undefined): CompanyMarketDataType => ({
  id: company?.id || '',
  name: company?.name || '',
  location: {
    name: company?.location?.name || '',
    locality: company?.location?.locality || '',
    country: company?.location?.country || ''
  },
  founded: company?.founded ? company?.founded.toString() : '',
  website: company?.website || '',
  employeeCount: company?.employee_count ? company?.employee_count.toString() : '',
  facebookUrl: company?.facebook_url || '',
  twitterUrl: company?.twitter_url || '',
  linkedinUrl: company?.linkedin_url || '',
  industry: company?.industry || '',
  type: company?.type?.includes('_') ? '' : capitalizeWord(company?.type),
  summary: company?.summary || '',
  tags: company?.tags || []
})

const useCompanyMarketData = (name: string, website?: string) => {
  const { teamContextValue } = useContext(TeamContext)
  const [marketData, setMarketData] = useState<CompanyMarketDataType | null>(null)
  const { fetchWithAbort } = useAbortableFetch<{ data: CompanyMarketDataRes }>()

  useEffect(() => {
    return () => {
      setMarketData(null)
    }
  }, [name])

  const getMarketData = useCallback(async () => {
    if (name) {
      const res = await fetchWithAbort({
        url: mergeUrlWithParams('/cloudhub/companies/enrich', {
          name,
          website,
          teamNumber: `${teamContextValue.teamNumber}`,
          titlecase: 'true'
        })
      })
      setMarketData(transformCompanyData(res?.data))
    }
  }, [name, website, teamContextValue.teamNumber])

  return {
    marketData,
    setMarketData,
    getMarketData
  }
}

export default useCompanyMarketData
