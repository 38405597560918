import { createContext, ReactNode, useContext } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import useEntityEndpoint from '_core/hooks/useEntityEndpoint'

export type ContextType = {
  emailsFromMonthsAgo: number
  emailsUntilMonthsFromNow: number
  meetingsFromMonthsAgo: number
  meetingsUntilMonthsFromNow: number
} | undefined

export const ActivitiesPeriodSupportContext = createContext({} as ContextType)

type PeriodResponse = {
  uid: string
  id: number
  teamNumber: number
  entityName: 'EmailMessage' | 'EmailMessageParticipant' | 'CalendarMeeting' | 'CalendarMeetingParticipant'
  versionNumber: number
  shouldRun: boolean
  yyMmProperty: string
  byMonth: boolean
  restMinutes: number
  fromMonthsAgo: number
  untilMonthsFromNow: number
  retreadMonthsAgo: number
  personIdVia?: string
  companyIdVia?: string
  yyMmEntity?: string
  yyMmRefPropToYyMmEntity?: string
}

const ActivitiesPeriodSupportProvider = (props: { children: ReactNode }) => {
  const { teamContextValue } = useContext(TeamContext)
  const { result: periodResponse } = useEntityEndpoint<{ results: PeriodResponse[] } | null>(`/teams/${teamContextValue.teamNumber}/flatfactrunplans`)
  const { fromMonthsAgo: emailsFromMonthsAgo = 0, untilMonthsFromNow: emailsUntilMonthsFromNow = 0 } =
    periodResponse?.results.find(({ entityName }) => entityName === 'EmailMessage') || {}
  const { fromMonthsAgo: meetingsFromMonthsAgo = 0, untilMonthsFromNow: meetingsUntilMonthsFromNow = 0 } =
    periodResponse?.results.find(({ entityName }) => entityName === 'CalendarMeeting') || {}

  const ctx = periodResponse
    ? {
        emailsFromMonthsAgo,
        meetingsFromMonthsAgo,
        meetingsUntilMonthsFromNow,
        emailsUntilMonthsFromNow
      }
    : undefined

  return <ActivitiesPeriodSupportContext.Provider value={ctx}>{props.children}</ActivitiesPeriodSupportContext.Provider>
}

export default ActivitiesPeriodSupportProvider
