import React, { isValidElement, ReactNode } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Collapse } from '@mui/material'
import { GridSortItem } from '@mui/x-data-grid-pro'
import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { ellipsis } from 'AppTheme'

const useStyles = makeStyles()((theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%'
  },
  title: {
    whiteSpace: 'nowrap',
    letterSpacing: '0.1px',
    maxWidth: '100%',
    paddingBottom: theme.spacing(0.5),
    ...ellipsis
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    overflow: 'hidden'
  },
  gridWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: theme.spacing(1),
    [theme.breakpoints.up('sidepanel')]: {
      columnGap: theme.spacing(2),
      gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)'
    }
  }
}))

export type ItemsType = { field: string; value?: string | number | null; label?: string; title: string; icon: IconProp; condition?: boolean }[]

type ExpandableContentProps = {
  isExpandedView: boolean
  sortByField?: GridSortItem['field']
}

export type ExpandableDetailsProps = {
  items?: ItemsType
  content?: ReactNode
  contentStart?: ReactNode
  contentEnd?: ReactNode
  collapsedSizes?: {
    content?: number
    contentStart?: number
    contentEnd?: number
  }
} & ExpandableContentProps

export const ExpandableContent = ({ isExpandedView, items, sortByField }: ExpandableContentProps & { items: ItemsType }) => {
  const { classes } = useStyles()

  return (
    <>
      {items
        .filter(({ field: rField }) => isExpandedView || sortByField === rField)
        .map(({ field: rField, title, value, label, icon }, i) => (
          <Box key={rField}>
            {isExpandedView && (
              <Typography className={classes.title} color="text.secondary" semiBold>
                {title}
              </Typography>
            )}
            {value ? (
              <Tooltip title={label || value} placement="bottom-start">
                <Box className={classes.item}>
                  <FontAwesomeIcon color="#979797" size="sm" icon={icon} style={{ paddingRight: 8 }} />
                  <Skeleton condition={value === -1} width={30}>
                    <Typography noWrap>{value}</Typography>
                  </Skeleton>
                </Box>
              </Tooltip>
            ) : (
              <Box className={classes.item}>
                <FontAwesomeIcon color="#979797" size="sm" icon={icon} style={{ paddingRight: 8 }} />
                <Typography>No data</Typography>
              </Box>
            )}
          </Box>
        ))}
    </>
  )
}

const ExpandableDetails = ({ isExpandedView, collapsedSizes, items, content, contentStart, contentEnd, sortByField }: ExpandableDetailsProps) => {
  const { classes } = useStyles()
  const collapsedSize = (collapsedSizes?.content || 0) + (collapsedSizes?.contentStart || 0) + (collapsedSizes?.contentEnd || 0)

  return (
    <Collapse in={isExpandedView} collapsedSize={collapsedSize}>
      <Box className={classes.wrapper}>
        {isValidElement(contentStart) && <Box pb={1}>{contentStart}</Box>}
        <Box className={classes.gridWrapper}>
          {items && <ExpandableContent items={items} sortByField={sortByField} isExpandedView={isExpandedView} />}
          {isValidElement(content) && <>{content}</>}
        </Box>
        {isValidElement(contentEnd) && <Box pt={1}>{contentEnd}</Box>}
      </Box>
    </Collapse>
  )
}

export default ExpandableDetails
