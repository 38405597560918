import { useEffect, useMemo, useContext, ComponentProps, useState, useCallback, ReactNode } from 'react'

import { Box } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { CmpniesList, relationshipColumn } from '_pages/people/[id]/companies'
import { jobTitleColumn, companyColumn, IntrList } from '_pages/people/[id]/introducers'
import { PpleList } from '_pages/people/[id]/people'

import { TeamContext } from '_core/context/TeamContext'

import Grid from '_core/components/grid'
import {
  lastInboundColumn,
  lastMeetingColumn,
  lastOutboundColumn,
  nameColumn,
  scoreColumn,
  nextFutureMeetingColumn,
  inboundCountColumn,
  outboundCountColumn,
  meetingsCountColumn,
  nameWithByLinesColumn,
  bestIntroducerColumn,
  keyContactColumn
} from '_core/components/grid/columns'
import Heading from '_core/components/Heading'
import { Column, Columns, Narrow, NarrowStrict, Middle, useWide } from '_core/components/layout'
import SearchInput from '_core/components/SearchInput'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/widgets'
import ActivityStatsWidget from '_core/components/widgets/ActivityStats'
import CompaniesWidget from '_core/components/widgets/Companies'
import IntroducersWidget from '_core/components/widgets/Introducers'
import PeopleWidget from '_core/components/widgets/People'

import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useActivitiesStats from '_core/hooks/useActivitiesStats'
import useActivitiesStatsUserSettings, { RenamedGridForCompanies, RenamedGridForContacts } from '_core/hooks/useActivitiesStatsUserSettings'
import useActivityStatsPeriod from '_core/hooks/useActivityStatsPeriod'
import useAdmin from '_core/hooks/useAdmin'
import useContactActivitiesPayloads from '_core/hooks/useContactActivitiesPayloads'
import useEnableDownloading from '_core/hooks/useEnableDownloading'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import useIntroducersUserSettings from '_core/hooks/useIntroducersUserSettings'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

import DynamicEntity from '_core/DynamicEntity'
import { stringifyUrl } from '_core/helpers/browser'
import UserSettings from '_core/UserSettings'

import { mergeUrlWithParams } from 'utils/httpUtils'
import { getUTC } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const useStyles = makeStyles()(() => ({
  gridWrapper: {
    minHeight: 350
  },
  grid: {
    padding: 0,
    height: '100%'
  }
}))

const IntrWidget = (props: { items: PersonIntroducerListItem[]; loading: boolean; total: number; personName: string }) => {
  const { id } = useParams<{ id: string }>()
  const { queryParams } = useSearchQuery<ActivityStatsPageParams<GridParams>>()

  const { from, to } = queryParams

  return (
    <IntroducersWidget
      title="Stats by employee"
      loading={props.loading}
      empty={`${props.personName} has no employees`}
      link={stringifyUrl(`${Paths._people}/${id}/introducers`, { from, to })}
      total={props.total}
      items={props.items.slice(0, 5).map((intro) => ({
        name: intro.UserName,
        userKey: intro.UserBestEmailAddressText,
        score: intro.ScorePoints,
        link: `${Paths._relationships}/${intro.UserKeyMd5}/people/${id}`,
        sidepanel: true
      }))}
    />
  )
}

const PpleWidget = (props: { items: UserPeopleListItem[]; loading: boolean; total: number; personName: string }) => {
  const { id } = useParams<{ id: string }>()
  const { queryParams } = useSearchQuery<ActivityStatsPageParams<GridParams>>()

  const { from, to } = queryParams

  return (
    <PeopleWidget
      loading={props.loading}
      title="Stats by contacts"
      empty={`${props.personName} has no contacts`}
      link={stringifyUrl(`${Paths._people}/${id}/people`, { from, to })}
      total={props.total}
      items={
        props.items?.map((contact) => ({
          userKey: contact.ContactUserKeyMd5 ? contact.BestEmailAddrText : '',
          name: contact.PersonNameText || contact.BestEmailAddrText,
          score: contact.Score,
          sidepanel: true,
          link: `${Paths._relationships}/${id}/people/${contact.PersonMd5}`
        })) || []
      }
    />
  )
}

const CmpniesWidget = (props: { items: UserCompaniesListItem[]; loading: boolean; total: number; personName: string }) => {
  const { id } = useParams<{ id: string }>()
  const { queryParams } = useSearchQuery<ActivityStatsPageParams<GridParams>>()

  const { from, to } = queryParams

  return (
    <CompaniesWidget
      loading={props.loading}
      total={props.total}
      link={stringifyUrl(`${Paths._people}/${id}/companies`, { from, to })}
      title="Stats by companies"
      empty={`${props.personName} has no companies`}
      items={
        props.items.map((company) => ({
          name: company.CompanyNameText || company.BestUrlText,
          score: company.Score,
          logoUrl: company.BestUrlText,
          sidepanel: true,
          link: `${Paths._relationships}/${id}/companies/${company.CompanyMd5}`
        })) || []
      }
    />
  )
}

const ContactStats = ({
  personName,
  months,
  interactionsColumnsShown
}: {
  interactionsColumnsShown: boolean
} & Pick<ComponentProps<typeof IntrList>, 'months'> &
  Pick<ComponentProps<typeof IntrWidget>, 'personName'>) => {
  const { id } = useParams<{ id: string }>()
  const { classes } = useStyles()
  const wideStrict = useWide('lg')

  const [userSettingsParams, setUserSettingsParams] = useState<ActivityStatsPageParams<GridParams> | null>(null)

  const { handleChange } = useIntroducersUserSettings()

  const { teamContextValue } = useContext(TeamContext)

  const { queryParams, updateQuery } = useSearchQuery<ActivityStatsPageParams<GridParams>>()
  const { sort, stackColumns } = queryParams

  useEffect(() => {
    if (!stackColumns && userSettingsParams) {
      const { sort, stackColumns } = userSettingsParams || {}
      const isReadyToUpdate = sort && stackColumns
      if (isReadyToUpdate) {
        updateQuery(userSettingsParams)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettingsParams])

  const updateSort = (sort: ScoreType | StatSortType) => {
    handleChange({ sort })
  }

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    updateQuery({ rowsPerPage })
  }

  const setInitialUserSettingsParams = useCallback((params: IntroducersInit | ActivityStatsInit) => {
    if ('sort' in params) {
      setUserSettingsParams((prevState) => ({ ...prevState, sort: params.sort }))
    } else {
      const { fromDate: from, toDate: to, stackColumns } = params
      setUserSettingsParams((prevState) => ({ ...prevState, from, to, stackColumns: `${stackColumns}`, rowsPerPage: `${5}` }))
    }
  }, [])

  const columns = useMemo(
    () =>
      [
        { column: wideStrict ? { ...nameColumn, minWidth: 200, width: 200 } : { ...nameWithByLinesColumn, minWidth: 281, width: 281 } },
        { column: jobTitleColumn, condition: wideStrict },
        { column: companyColumn, condition: wideStrict },
        { column: inboundCountColumn },
        { column: outboundCountColumn },
        { column: meetingsCountColumn },
        { column: lastInboundColumn, condition: !!interactionsColumnsShown },
        { column: lastOutboundColumn, condition: !!interactionsColumnsShown },
        { column: lastMeetingColumn, condition: !!interactionsColumnsShown },
        { column: nextFutureMeetingColumn, condition: !!interactionsColumnsShown },
        { column: scoreColumn }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || !!condition)
        .map(({ column }) => column),
    [interactionsColumnsShown, wideStrict]
  )

  return (
    <UserSettings endpoint={!stackColumns ? '/usersettings/activitiesstats' : ''} setInitial={setInitialUserSettingsParams}>
      <UserSettings endpoint={!stackColumns ? '/usersettings/introducersfilter' : ''} setInitial={setInitialUserSettingsParams}>
        <ContactActivityStatistics>
          <Middle includeWide>
            <Column md={12} className={classes.gridWrapper}>
              <Widget scope="stack">
                <Grid padding={0} className={classes.grid}>
                  <Heading
                    underlined
                    title="Stats by employee"
                    icon={['far', 'address-book']}
                    action={
                      <Box minWidth="220px">
                        <SearchInput variant="collapsed" placeholder="Search for employees" opened />
                      </Box>
                    }
                  />
                  <IntrList
                    columns={columns}
                    months={months}
                    rowsPerPageOptions={[3, 5, 10]}
                    updateSort={updateSort}
                    updatePageSize={onPageSizeChange}
                  />
                </Grid>
              </Widget>
            </Column>
          </Middle>
          <NarrowStrict>
            <Column xs={12}>
              <DynamicEntity<{ extraProps: { addprops: Pick<ComponentProps<typeof IntrWidget>, 'personName'> } }>
                url={sort ? mergeUrlWithParams(`/people/${id}/introducers`, { teamNumber: `${teamContextValue.teamNumber}` }) : null}
                addprops={{ personName }}
                component={IntrWidget}
                list
                keepMounted
                empty="No results found"
                id="company_introducers"
              />
            </Column>
          </NarrowStrict>
        </ContactActivityStatistics>
      </UserSettings>
    </UserSettings>
  )
}

const ContributorStats = ({
  personName,
  months,
  interactionsColumnsShown
}: {
  interactionsColumnsShown: boolean
} & Pick<ComponentProps<typeof IntrList>, 'months'> &
  Pick<ComponentProps<typeof IntrWidget>, 'personName'>) => {
  const { id } = useParams<{ id: string }>()
  const { classes } = useStyles()
  const wideStrict = useWide('lg')

  const { teamContextValue } = useContext(TeamContext)

  const [userSettingsParams, setUserSettingsParams] = useState<Partial<
    ActivityStatsInit & { [K in keyof PeopleInit as `p${K}`]: PeopleInit[K] } & { [K in keyof CompaniesInit as `c${K}`]: CompaniesInit[K] }
  > | null>(null)

  const { dataVisibility } = useUserDataVisibility()
  const { preventNonAdminDownload } = dataVisibility || {}

  const admin = useAdmin()
  const enableDownloading = useEnableDownloading(admin, preventNonAdminDownload)

  const { queryParams, updateQuery } = useSearchQuery<ActivityStatsPageParams<RenamedGridForContacts & RenamedGridForCompanies>>()
  const { stackColumns, from, to, pExcludeEmpty, pSort, pKeyword, pPage, pRowsPerPage, cExcludeEmpty, cSort, cKeyword, cPage, cRowsPerPage } =
    queryParams

  const pQueryParams = useMemo(
    () => ({ stackColumns, from, to, excludeEmpty: pExcludeEmpty, sort: pSort, keyword: pKeyword, page: pPage, rowsPerPage: pRowsPerPage || '5' }),
    [stackColumns, from, to, pExcludeEmpty, pSort, pKeyword, pPage, pRowsPerPage]
  )

  const cQueryParams = useMemo(
    () => ({ stackColumns, from, to, excludeEmpty: cExcludeEmpty, sort: cSort, keyword: cKeyword, page: cPage, rowsPerPage: cRowsPerPage || '5' }),
    [stackColumns, from, to, cExcludeEmpty, cSort, cKeyword, cPage, cRowsPerPage]
  )

  useEffect(() => {
    if (!stackColumns && userSettingsParams) {
      const { psort, csort, stackColumns, fromDate, toDate } = userSettingsParams || {}
      const isReadyToUpdate = psort && csort && typeof stackColumns === 'boolean'
      if (isReadyToUpdate) {
        updateQuery({ pSort: psort, pRowsPerPage: `5`, cSort: csort, cRowsPerPage: `5`, stackColumns: `${stackColumns}`, from: fromDate, to: toDate })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettingsParams])

  const updatePeopleSort = (sort: ScoreType | StatSortType) => {
    updateQuery({ pSort: sort })
  }

  const updateCompaniesSort = (sort: ScoreType | StatSortType) => {
    updateQuery({ cSort: sort })
  }

  const onPeoplePageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    updateQuery({ pRowsPerPage: rowsPerPage })
  }

  const onCompaniesPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    updateQuery({ cRowsPerPage: rowsPerPage })
  }

  const setInitialPeopleUserSettingsParams = useCallback((params: PeopleInit) => {
    const p = (Object.keys(params) as (keyof PeopleInit)[]).reduce(
      (acc, key) => ({ ...acc, [`p${key}`]: params[key] }),
      {} as { [K in keyof PeopleInit as `p${K}`]: PeopleInit[K] }
    )
    setUserSettingsParams((prevState) => ({ ...prevState, ...p }))
  }, [])

  const setInitialCompaniesUserSettingsParams = useCallback((params: CompaniesInit) => {
    const p = (Object.keys(params) as (keyof CompaniesInit)[]).reduce(
      (acc, key) => ({ ...acc, [`c${key}`]: params[key] }),
      {} as { [K in keyof CompaniesInit as `c${K}`]: CompaniesInit[K] }
    )
    setUserSettingsParams((prevState) => ({ ...prevState, ...p }))
  }, [])

  const setInitialUserSettingsParams = useCallback((params: ActivityStatsInit) => {
    setUserSettingsParams((prevState) => ({ ...prevState, ...params }))
  }, [])

  const pColumns = useMemo(
    () =>
      [
        { column: wideStrict ? { ...nameColumn, minWidth: 200, width: 200 } : { ...nameWithByLinesColumn, minWidth: 281, width: 281 } },
        { column: jobTitleColumn, condition: wideStrict },
        { column: companyColumn, condition: wideStrict },
        { column: bestIntroducerColumn },
        { column: inboundCountColumn },
        { column: outboundCountColumn },
        { column: meetingsCountColumn },
        { column: lastInboundColumn, condition: !!interactionsColumnsShown },
        { column: lastOutboundColumn, condition: !!interactionsColumnsShown },
        { column: lastMeetingColumn, condition: !!interactionsColumnsShown },
        { column: nextFutureMeetingColumn, condition: !!interactionsColumnsShown },
        { column: scoreColumn }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || !!condition)
        .map(({ column }) => column),
    [interactionsColumnsShown, wideStrict]
  )

  const cColumns = useMemo(
    () =>
      [
        { column: wideStrict ? { ...nameColumn, minWidth: 200, width: 200 } : { ...nameWithByLinesColumn, minWidth: 281, width: 281 } },
        { column: keyContactColumn, condition: wideStrict },
        { column: bestIntroducerColumn, condition: wideStrict },
        { column: relationshipColumn, condition: !wideStrict },
        { column: inboundCountColumn },
        { column: outboundCountColumn },
        { column: meetingsCountColumn },
        { column: lastInboundColumn, condition: !!interactionsColumnsShown },
        { column: lastOutboundColumn, condition: !!interactionsColumnsShown },
        { column: lastMeetingColumn, condition: !!interactionsColumnsShown },
        { column: nextFutureMeetingColumn, condition: !!interactionsColumnsShown },
        { column: scoreColumn }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || !!condition)
        .map(({ column }) => column),
    [interactionsColumnsShown, wideStrict]
  )

  return (
    <UserSettings endpoint={!stackColumns ? '/usersettings/activitiesstats' : ''} setInitial={setInitialUserSettingsParams}>
      <UserSettings endpoint={!stackColumns ? '/usersettings/peoplesearchfilter' : ''} setInitial={setInitialPeopleUserSettingsParams}>
        <UserSettings endpoint={!stackColumns ? '/usersettings/companiessearchfilter' : ''} setInitial={setInitialCompaniesUserSettingsParams}>
          <ContactActivityStatistics>
            <Middle includeWide>
              <Column md={12} className={classes.gridWrapper}>
                <Widget scope="stack">
                  <Grid padding={0} className={classes.grid}>
                    <Heading
                      underlined
                      title="Stats by contacts"
                      icon={['far', 'user']}
                      action={
                        <Box minWidth="220px">
                          <SearchInput variant="collapsed" placeholder="Search for contacts" searchKey="pKeyword" opened />
                        </Box>
                      }
                    />
                    <PpleList
                      queryParams={pQueryParams}
                      columns={pColumns}
                      months={months}
                      rowsPerPageOptions={[3, 5, 10]}
                      updateSort={updatePeopleSort}
                      updatePageSize={onPeoplePageSizeChange}
                      enableDownloading={enableDownloading}
                    />
                  </Grid>
                </Widget>
              </Column>
              <Column md={12} className={classes.gridWrapper}>
                <Widget scope="stack">
                  <Grid padding={0} className={classes.grid}>
                    <Heading
                      underlined
                      title="Stats by companies"
                      icon={['far', 'user']}
                      action={
                        <Box minWidth="220px">
                          <SearchInput variant="collapsed" placeholder="Search for companies" searchKey="cKeyword" opened />
                        </Box>
                      }
                    />
                    <CmpniesList
                      queryParams={cQueryParams}
                      columns={cColumns}
                      months={months}
                      rowsPerPageOptions={[3, 5, 10]}
                      updateSort={updateCompaniesSort}
                      updatePageSize={onCompaniesPageSizeChange}
                      enableDownloading={enableDownloading}
                    />
                  </Grid>
                </Widget>
              </Column>
            </Middle>

            <NarrowStrict>
              <Column xs={12}>
                <DynamicEntity<{ extraProps: { addprops: Pick<ComponentProps<typeof PpleWidget>, 'personName'> } }>
                  url={pSort ? mergeUrlWithParams(`/users/${id}/people`, { teamNumber: `${teamContextValue.teamNumber}`, take: '5' }) : null}
                  addprops={{ personName }}
                  component={PpleWidget}
                  list
                  keepMounted
                  empty="No results found"
                  id="person_contacts"
                />
              </Column>
              <Column xs={12}>
                <DynamicEntity<{ extraProps: { addprops: Pick<ComponentProps<typeof CmpniesWidget>, 'personName'> } }>
                  url={pSort ? mergeUrlWithParams(`/users/${id}/companies`, { teamNumber: `${teamContextValue.teamNumber}`, take: '5' }) : null}
                  addprops={{ personName }}
                  component={CmpniesWidget}
                  list
                  keepMounted
                  empty="No results found"
                  id="person_companies"
                />
              </Column>
            </NarrowStrict>
          </ContactActivityStatistics>
        </UserSettings>
      </UserSettings>
    </UserSettings>
  )
}

const ContactActivityStatistics = ({ children }: { children: ReactNode }) => {
  const { id } = useParams<{ id: string }>()

  const statsPayloads = useContactActivitiesPayloads(useMemo(() => [id], [id]))
  const { queryParams } = useSearchQuery<ActivityStatsPageParams<RenamedGridForContacts | RenamedGridForCompanies>>()
  const { from, to, stackColumns } = queryParams

  const chartData = useActivityStatsPeriod({
    fromUTC: from ? getUTC(decodeURIComponent(from)) : null,
    toUTC: to ? getUTC(decodeURIComponent(to)) : null
  })

  const { userActivitiesAccess } = useActivitiesAccess([id]) || {}
  const { isDetailedActivityFromStatsWidgetVisible = false } = userActivitiesAccess || {}

  const { stats } = useActivitiesStats(
    id ? statsPayloads : null,
    useMemo(() => [id], [id]),
    useMemo(() => chartData.months.map(({ year, month, minDay, maxDay }) => ({ year, month, minDay, maxDay })), [chartData.months])
  )

  const { handleChange: handleChangeActivities } = useActivitiesStatsUserSettings()

  const updatePeriod = (period: readonly [MomentType, MomentType]) => {
    handleChangeActivities({ from: period[0].toISOString(), to: period[1].toISOString() })
  }

  const toggleStack = () => {
    if (stackColumns) {
      handleChangeActivities({ stackColumns: `${!JSON.parse(stackColumns)}` })
    }
  }

  return (
    <Columns spacing={0}>
      <Column xs={12} md={12}>
        <ActivityStatsWidget
          scope="stack"
          stats={stats}
          stack={stackColumns === 'true'}
          detailsLink={`${Paths._people}/${id}/activities`}
          isDetailedActivityFromStatsWidgetVisible={isDetailedActivityFromStatsWidgetVisible}
          updatePeriod={updatePeriod}
          toggleStack={toggleStack}
          {...chartData}
        />
      </Column>
      {children}
    </Columns>
  )
}

const PersonActivityStatisticsPage = ({ personName, isContributor }: { personName: string; isContributor: boolean }) => {
  const { teamContextValue } = useContext(TeamContext)
  const { setMobileHeader } = useContext(LayoutContext)

  const { queryParams } = useSearchQuery<ActivityStatsPageParams<RenamedGridForContacts | RenamedGridForCompanies>>()
  const { from, to } = queryParams

  const chartData = useActivityStatsPeriod({
    fromUTC: from ? getUTC(decodeURIComponent(from)) : null,
    toUTC: to ? getUTC(decodeURIComponent(to)) : null
  })

  const { result: teamResult } = useEntityEndpoint<{ results: TeamDataRes }>(`/teams/${teamContextValue.teamNumber}`)
  const { shareInteractionStats: isInteractionsEnabled } = teamResult?.results?.defaultSharingOptions || {}
  const interactionsColumnsShown = typeof isInteractionsEnabled === 'boolean' && isInteractionsEnabled

  useEffect(() => {
    setMobileHeader(personName, !personName)
  }, [personName, setMobileHeader])

  return (
    <>
      <Narrow>
        <Topbar nativeBack sub="Activity statistics" />
      </Narrow>
      {!isContributor && <ContactStats interactionsColumnsShown={interactionsColumnsShown} months={chartData.months} personName={personName} />}
      {isContributor && <ContributorStats interactionsColumnsShown={interactionsColumnsShown} months={chartData.months} personName={personName} />}
    </>
  )
}

export default PersonActivityStatisticsPage
