import React from 'react'

import { Box } from '@mui/material'

import Popover from '_shared/popovers/Popover'
import Typography from '_shared/Typography'

const IsInNetworkIcon = ({ name }: { name: string }) => (
  <Popover
    placement="bottom"
    triggerElement={
      <Box display="flex" alignItems="center">
        <img src="/image/network.svg" width="20px" height="20px" />
      </Box>
    }
  >
    <Typography>{name} is in your DotAlign network</Typography>
  </Popover>
)

export default IsInNetworkIcon
