import { ReactNode } from 'react'

import useAutoHideOnScrollStyles from './useStyles'

const AutoHideOnScroll = (props: { children: ReactNode }) => {
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)

  return <div className={autoHideClassName}>{props.children}</div>
}

export { useAutoHideOnScrollStyles }
export default AutoHideOnScroll
