import { MouseEvent, memo } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { getSkeletonSize } from '_core/helpers/skeleton'

const useStyles = makeStyles<{ fullWidth?: EntityType['fullWidth'] }>()((theme, { fullWidth }) => ({
  root: {
    height: '100%',
    position: 'relative',
    flex: 1,
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  content: {
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '100%'
  },
  heading: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: fullWidth ? 'column' : 'row'
  },
  item: {
    height: 40,
    margin: fullWidth ? `${theme.spacing(1)} 0` : theme.spacing(1),
    width: '100%',
    maxWidth: fullWidth ? '100%' : `calc(50% - ${theme.spacing(2)})`,
    position: 'relative',
    transition: 'padding 0.3s'
  },
  spacing: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  },
  icon: {
    boxSizing: 'border-box',
    cursor: 'pointer',
    position: 'absolute',
    top: -8,
    right: -8,
    width: 18,
    height: 18,
    padding: theme.spacing(0.5),
    fontSize: 13,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.light,
    '&: hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.light
    }
  }
}))

type EntityType = {
  id: string
  name: string
  isActive: boolean
  isPrimary: boolean
  startIcon: JSX.Element | null
  fullWidth: boolean
  setActive?: EntitiesListProps['setActive']
  remove?: EntitiesListProps['remove']
  action?: JSX.Element | null
}

const Entity = memo(({ id, name, startIcon, action, isActive, fullWidth, setActive, isPrimary, remove }: EntityType) => {
  const { classes, cx } = useStyles({ fullWidth })

  const setAsActive = () => {
    setActive && setActive(id)
  }
  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    remove && remove(id)
  }

  return (
    <Button<'div'>
      component="div"
      variant="outlined"
      color={isActive ? 'primary' : 'secondary'}
      classes={{ root: cx(classes.item, { [classes.spacing]: !!action }) }}
      onClick={setAsActive}
      startIcon={startIcon}
    >
      <Typography component="span" color="inherit" noWrap semiBold>
        {name}
      </Typography>
      {action}
      {!isPrimary && remove && <IconButton color="primary" icon={['far', 'times']} classes={{ root: classes.icon }} onClick={handleRemove} />}
    </Button>
  )
})

export type EntitiesListProps = {
  items: (Omit<AuditEntityState, 'identifiers'> & Pick<EntityType, 'isActive' | 'isPrimary' | 'action'>)[]
  loading?: boolean
  remove?: (entityId: string) => void
  setActive?: (entityId: string) => void
  fullWidth: boolean
}

export const EntitiesList = (props: EntitiesListProps) => {
  const { classes } = useStyles({ fullWidth: props.fullWidth })

  return (
    <Box className={classes.list}>
      {props.loading &&
        getSkeletonSize(4).map((_, index) => (
          <Skeleton key={index} condition={true} className={classes.item}>
            {null}
          </Skeleton>
        ))}
      {!props.loading &&
        props.items?.map((item) => (
          <Entity
            key={item.id}
            id={item.id}
            name={item.name}
            isActive={item.isActive}
            isPrimary={item.isPrimary}
            setActive={props.setActive}
            remove={props.remove}
            startIcon={item.name === 'Invalid' ? <FontAwesomeIcon icon={['far', 'ban']} /> : null}
            action={item.action}
            fullWidth={props.fullWidth}
          />
        ))}
    </Box>
  )
}

type AuditEntitiesProps = {
  loading: boolean
  openSelect: (identifier?: AuditIdentifier) => void
} & Required<Pick<EntitiesListProps, 'items' | 'setActive' | 'remove' | 'fullWidth'>>

const AuditEntities = memo((props: AuditEntitiesProps) => {
  const { classes } = useStyles({ fullWidth: props.fullWidth })
  const { loading, openSelect, items, setActive, remove, fullWidth } = props

  const open = () => openSelect()

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography classes={{ root: classes.heading }}>
          <Skeleton condition={loading}>
            <span> Entities to which you can assign identifiers. </span>
          </Skeleton>
          <Skeleton condition={loading}>
            <Button disablePT variant="link" onClick={open}>
              Pick or add another
            </Button>
          </Skeleton>
        </Typography>
        <EntitiesList loading={loading} items={items} setActive={setActive} remove={remove} fullWidth={fullWidth} />
      </Box>
    </Box>
  )
})

export default AuditEntities
