import { useState, useEffect, useContext, useMemo, useCallback, ReactElement } from 'react'

import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import { Button } from '_shared/buttons'

import Entities from '_core/components/audit/AuditEntities'
import Identifiers, { Identity } from '_core/components/audit/AuditIdentifiers'
import IdentifierSources from '_core/components/audit/AuditIdentifierSources'
import AuditColumns from '_core/components/audit/AuditLayout'
import EntityMoveCount from '_core/components/audit/EntityMoveCount'
import IdentifierContributors from '_core/components/audit/IdentifierContributors'
import AuditInvalidDialog from '_core/components/dialogs/AuditInvalid'
import MoveDialog from '_core/components/dialogs/AuditMove'
import AuditValidDialog from '_core/components/dialogs/AuditValid'
import { getReason } from '_core/components/dialogs/InformAboutIdentity'
import InnerDialog from '_core/components/InnerDialog'
import { useSidepanelWide } from '_core/components/layout'
import NextStepFrame from '_core/components/NextStepFrame'
import { TopbarHeader } from '_core/components/Topbar'

import useAdminOrCuratorCheck from '_core/hooks/useAdminOrCuratorCheck'
import useAuditEntities from '_core/hooks/useAuditEntities'
import useAuditTuples from '_core/hooks/useAuditTuples'
import useDialog from '_core/hooks/useDialog'
import useIdentifiersSourceViewToggle, { IdentifierSourceType } from '_core/hooks/useIdentifiersSourceViewToggle'
import usePersonForm from '_core/hooks/usePersonForm'
import usePicker from '_core/hooks/usePicker'

import DynamicEntity from '_core/DynamicEntity'
import { getSkeletonSize } from '_core/helpers/skeleton'

import { uuid as uid } from 'utils/demoUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const peopleType: { [key in Extract<AuditIdentifierType, 'PersonCompleteName' | 'PersonTwoPartName' | 'EmailAddress' | 'PersonSourceKey'>]: string } =
  {
    PersonCompleteName: 'person complete name',
    PersonTwoPartName: 'person two part name',
    EmailAddress: 'email address',
    PersonSourceKey: 'person source'
  }

const Select = (props: { add: (data: { id: string } & ({ name: string } | PersonInfo)) => void; close: () => void }) => {
  const [isFormOpened, setFormOpened] = useState<boolean>(false)

  const { setSubHeader } = useContext(LayoutContext)

  const { Form, save, isReady, allowSubmit, ...formProps } = usePersonForm()

  useEffect(() => {
    setSubHeader(isFormOpened ? 'Add person' : 'Pick or add person')
  }, [isFormOpened, setSubHeader])

  const openForm = () => {
    setFormOpened(true)
  }

  const closeForm = () => {
    setFormOpened(false)
  }

  const { Picker, selected, keyword } = usePicker({
    entity: 'people',
    addButton: () => (
      <Box mt={2}>
        <Button onClick={openForm} fullWidth>
          Add person
        </Button>
      </Box>
    )
  })

  useEffect(() => {
    typeof keyword === 'string' && formProps.setName(keyword)
  }, [keyword])

  useEffect(() => {
    if (selected?.md5) {
      const { md5: newEntityId, name } = selected
      props.add({ id: newEntityId, name })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected?.md5])

  const createEntity = (data: PersonInfo & { isNew?: boolean }) => {
    const id = uid()
    props.add({ id, ...data })
  }

  const submit = async () => {
    const { company, companyUrl, email, job, name, phone, phoneType } = formProps
    if (name) {
      createEntity({
        name,
        jobTitle: job,
        emailAddress: email,
        company: { name: company, url: companyUrl },
        phoneNumber: { phoneType: phoneType, phoneText: phone },
        isNew: true
      })
      closeForm()
    }
  }

  return (
    <InnerDialog close={props.close}>
      <Box maxHeight="inherit" overflow="auto">
        {!isFormOpened && Picker}
        {isFormOpened && (
          <>
            <Form {...formProps} newForm />
            {isReady && (
              <Box p={2} flex={1}>
                <Button disabled={!allowSubmit} onClick={submit} fullWidth>
                  Save
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </InnerDialog>
  )
}

type SourcesViewProps = {
  identifierSources: null | {
    text: AuditIdentifier['text']
    type: IdentifierSourceType
  }
  closeSources: () => void
  identifiersList: ReactElement
}

const SourcesView = ({ identifierSources, closeSources, identifiersList }: SourcesViewProps) => {
  const { teamContextValue } = useContext(TeamContext)
  const { isAdminOrCurator, admin } = useAdminOrCuratorCheck()

  return (
    <>
      {!!identifierSources &&
        (isAdminOrCurator ? (
          <IdentifierSources
            enabledOpenFacts={!!admin}
            identifierData={identifierSources}
            entityType="Person"
            close={closeSources}
            searchInput={<IdentifierContributors.Search />}
          />
        ) : (
          <InnerDialog close={closeSources}>
            <AuditColumns.Column heading={<TopbarHeader title={identifierSources?.text} sub="contributors" />}>
              <IdentifierContributors.Search />
              <DynamicEntity
                id="identifier_contributors"
                scrollableTarget="identifier_contributors"
                url={
                  isAdminOrCurator === false
                    ? `/people/idcontributors?teamNumber=${teamContextValue.teamNumber}&identifier=${encodeURIComponent(identifierSources?.text)}`
                    : ''
                }
                pageSize={20}
                search
                list
                infinite
                component={IdentifierContributors}
              />
            </AuditColumns.Column>
          </InnerDialog>
        ))}

      {!identifierSources && identifiersList}
    </>
  )
}

type AuditIdentifiersProps = {
  loading: boolean
  next: () => void
  reset: () => void
}

const PeopleAuditIdentifiers = (props: AuditIdentifiersProps) => {
  const { loading, next, reset } = props

  const { setSubHeader } = useContext(LayoutContext)

  const { id } = useParams<{ id: string }>()

  const {
    setActive,
    addEntity,
    removeEntity,
    moveIdentifiers,
    valid,
    invalid,
    isDirty,
    markIdentifierAsInvalid,
    isInvalidActive,
    undoInvalid,
    entityMoveInfo
  } = useAuditEntities()
  const { identifierSources, openSources, closeSources } = useIdentifiersSourceViewToggle()
  const { clearTuples } = useAuditTuples()
  const {
    dialogContentProps: openedInvalidDialog,
    openDialog: openInvalidDialog,
    closeDialog: closeInvalidDialog
  } = useDialog<AuditIdentifier | null>(null)
  const {
    dialogContentProps: openedValidDialog,
    openDialog: openValidDialog,
    closeDialog: closeValidDialog
  } = useDialog<AuditIdentifier | null>(null)
  const { isDialogOpened: openedMoveDialog, openDialog: openMoveDialog, closeDialog: closeMoveDialog } = useDialog()

  const [selectOpened, setSelectOpened] = useState(false)
  const [checked, setChecked] = useState<AuditIdentifier[]>([])

  const entities = useMemo(() => [...valid, ...invalid], [valid, invalid])
  const entitiesItems = useMemo(
    () =>
      entities.map(({ name, id, isActive, isPrimary }) => ({
        id,
        name,
        isActive,
        isPrimary,
        ...(id === entityMoveInfo.id ? { action: <EntityMoveCount count={entityMoveInfo.count} /> } : {})
      })),
    [entities, entityMoveInfo]
  )

  const sidepanelWide = useSidepanelWide()
  const activeEntity = useMemo(() => entities.find((entity) => entity.isActive), [entities])

  useEffect(() => {
    clearTuples()
  }, [clearTuples])

  useEffect(() => {
    setChecked([])
  }, [activeEntity])

  useEffect(() => {
    if (!selectOpened) {
      setSubHeader('')
    }
  }, [setSubHeader, selectOpened])

  const moveCheckedIdentifiers = useCallback(
    (newEntityId: string) => {
      moveIdentifiers(newEntityId, checked)
      setChecked([])
    },
    [checked, moveIdentifiers]
  )

  const submitInvalid = useCallback(
    (data: AuditIdentifier, reason: string) => {
      markIdentifierAsInvalid(data, reason)
      closeInvalidDialog()
    },
    [closeInvalidDialog, markIdentifierAsInvalid]
  )

  const submitValid = useCallback(
    (identifier: AuditIdentifier) => {
      undoInvalid([identifier])
      closeValidDialog()
    },
    [closeValidDialog, undoInvalid]
  )

  const openInvalid = useCallback(
    (data: AuditIdentifier) => {
      openInvalidDialog(data)
    },
    [openInvalidDialog]
  )

  const openValid = useCallback(
    (data: AuditIdentifier) => {
      openValidDialog(data)
    },
    [openValidDialog]
  )

  const closeValid = () => {
    closeValidDialog()
  }

  const openSelect = useCallback(() => {
    setSelectOpened(true)
  }, [])

  const closeSelect = useCallback(() => {
    setSelectOpened(false)
  }, [])

  const addSelectedEntity = (data: Omit<AuditEntityState, 'identifiers'>) => {
    addEntity(data)
    closeSelect()
  }

  const createEntityFromIdentifier = useCallback(
    (identifier: AuditIdentifier) => {
      const id = uid()
      addEntity({ id, name: identifier.text })

      moveIdentifiers(id, activeEntity?.identifiers.filter(({ distilledKeyMd5 }) => identifier.distilledKeyMd5 === distilledKeyMd5) || [])
    },
    [activeEntity?.identifiers, addEntity, moveIdentifiers]
  )

  const { distilledKeyMd5: anchorDistilledKeyMd5, text: anchorText } = activeEntity?.identifiers.find(({ isAnchor }) => isAnchor) || {}

  const renderIdentity = useCallback(
    (props: AuditIdentifier) => (
      <Identity
        {...props}
        typesMap={peopleType}
        openSources={openSources}
        isInvalidActive={isInvalidActive}
        isRelatedToAnchorGroup={props.distilledKeyMd5 === anchorDistilledKeyMd5}
        loading={loading}
        openValid={openValid}
        openInvalid={openInvalid}
        addFormOpened={selectOpened}
        createEntity={createEntityFromIdentifier}
        tuplesChainLink={anchorText === props.text ? '' : `${Paths._people}/${id}/audit/identifiers/tuples-chain/${anchorText}/${props.text}`}
      />
    ),
    [openSources, isInvalidActive, anchorDistilledKeyMd5, loading, openValid, openInvalid, selectOpened, createEntityFromIdentifier, anchorText]
  )

  const handleInvalidClose = () => {
    closeInvalidDialog()
  }

  if (!sidepanelWide && selectOpened) {
    return <Select add={addSelectedEntity} close={closeSelect} />
  }

  return (
    <NextStepFrame
      next={
        <Button onClick={next} disabled={props.loading || !isDirty} variant="text" color="secondary">
          Next
        </Button>
      }
      back={null}
      disabled={props.loading}
      reset={reset}
      isDirty={isDirty}
    >
      <AuditColumns
        entities={
          <>
            {selectOpened && <Select add={addSelectedEntity} close={closeSelect} />}
            {!selectOpened && (
              <Entities
                items={entitiesItems}
                openSelect={openSelect}
                loading={loading}
                remove={removeEntity}
                setActive={setActive}
                fullWidth={sidepanelWide}
              />
            )}
          </>
        }
      >
        <SourcesView
          identifierSources={identifierSources}
          closeSources={closeSources}
          identifiersList={
            <Identifiers
              anchorDistilledKeyMd5={anchorDistilledKeyMd5 || ''}
              items={activeEntity?.identifiers || getSkeletonSize(10)}
              loading={loading}
              openMove={openMoveDialog}
              checked={checked.map(({ md5 }) => md5)}
              setChecked={setChecked}
              moveTitle="Move selected identifiers to a different person"
              renderIdentity={renderIdentity}
            />
          }
        />
      </AuditColumns>
      <AuditInvalidDialog
        close={handleInvalidClose}
        data={openedInvalidDialog}
        submit={submitInvalid}
        multipleLabel={
          openedInvalidDialog ? (
            <>
              I know multiple people with the {getReason(openedInvalidDialog.type).label} <b>{openedInvalidDialog.text}</b>.
            </>
          ) : (
            <></>
          )
        }
      />
      <AuditValidDialog close={closeValid} data={openedValidDialog} submit={submitValid} />
      <MoveDialog
        open={openedMoveDialog}
        close={closeMoveDialog}
        entities={entities.filter((entity) => entity.id !== 'invalid')}
        move={moveCheckedIdentifiers}
      />
    </NextStepFrame>
  )
}

export default PeopleAuditIdentifiers
